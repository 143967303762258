import {EventEmitter} from 'events';
import {ref} from '@vue/composition-api';

let internalWindowPopupMap;

try {
  if (opener) {
    internalWindowPopupMap = opener.$windowPopupMap;
  } else {
    internalWindowPopupMap = window.$windowPopupMap = window.$windowPopupMap || new Map();
  }
} catch (ex) {
  internalWindowPopupMap = new Map();
}

export const WindowPopupMap = internalWindowPopupMap;

export function useWindowPopup(refName) {
  if (refName) {
    const popupRef = {
      [refName]: ref(null),
    };

    const method = {
      emit: (eventName, args) => {
        popupRef[refName].value.emit(eventName, args);
      },
      // open: options => {
      //   return popupRef[refName].value.show(options);
      // },
      close: result => {
        return popupRef[refName].value.close(result);
      },
    };

    return {
      popupRef,
      ...method,
    };
  }

  const get = uniqueId => {
    return WindowPopupMap.get(uniqueId);
  };

  const open = (
    options = {
      name: undefined,
      componentPath: undefined,
      width: undefined,
      height: undefined,
      top: undefined,
      left: undefined,
      features: '',
      title: undefined,
      data: undefined,
      windowObject: undefined,
      emitter: undefined,
    }
  ) => {
    const uniqueId = Math.random()
      .toString(16)
      .substring(2);

    if (options.componentPath.startsWith('@/')) {
      options.componentPath = './' + options.componentPath.substring(2);
    }

    options.name = options.name || 'windowPopup';
    options.width = options.width || 800;
    options.height = options.height || 600;
    options.top = options.top || (window.screen.availHeight - options.height) / 2;
    options.left = options.left || (window.screen.availWidth - options.width) / 2;
    options.features = options.features || 'resizable=yes, scrollbars=yes, titlebar=no, menubar=no, status=yes';

    const features = `${options.features}, width=${options.width}, height=${options.height}, top=10, left=10`; //, top=${options.top}, left=${options.left}`;

    const popup = {
      ...options,
      uniqueId: uniqueId,
      windowObject: undefined,
      emitter: new EventEmitter(),
      on(eventName, listener) {
        return this.emitter.on(eventName, listener);
      },
      off(eventName, listener) {
        return this.emitter.off(eventName, listener);
      },
      once(eventName, listener) {
        return this.emitter.once(eventName, listener);
      },
    };

    console.log('useWindowPopup > open', features, popup);

    WindowPopupMap.set(uniqueId, popup);

    popup.windowObject = window.open(`/WindowPopup?id=${uniqueId}`, options.name, features);

    popup.windowObject.addEventListener('beforeunload', () => {
      WindowPopupMap.delete(uniqueId);
    });

    return popup;
  };

  const close = popup => {
    if (typeof popup === 'string') {
      popup = WindowPopupMap.get(popup);
    }

    if (popup) {
      popup.windowObject.close();
    }
  };

  return {
    get,
    open,
    close,
  };
}

export default useWindowPopup;

const state = () => ({
    decParams : {
      cnfrmnNo : '',
      tradeNo : '',
      tradeSfx : '',
    },
  });
  
  const getters = {
    decParams: state => state.decParams,
  };
  const mutations = {
    setDecParams(state, payload) {
      for (let key in state.decParams) {
        if (payload && payload.hasOwnProperty(key)) {
          state.decParams[key] = payload[key];
        }
      }
  
    //   let DP = state.decParams;
    },
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  
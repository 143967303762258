export const ItemsProps = {
  items: {
    type: [Array, Object],
    default: undefined,
  },
  excludeItems: {
    type: [Array, Object, String],
    default: undefined,
  },
  itemKeyAlias: {
    type: String,
    default: 'value',
  },
  itemTextAlias: {
    type: String,
    default: 'text',
  },
  itemValueAlias: {
    type: String,
    default: 'value',
  },
  itemEnableAlias: {
    type: String,
    default: 'enable',
  },
  itemDirection: {
    type: String,
    default: 'row',
  },
};

export function useItems(props, context) {
  const itemDirectionClassName = `item-direction-${props.itemDirection}`;

  const itemDirectionClass = {
    [itemDirectionClassName]: true,
  };

  return {
    itemDirectionClass,
  };
}

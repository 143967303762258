<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex" flex-direction="row" height="150px">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              파일정보
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>파일번호</i-col-header>
                <i-col colspan="3">
                  <iui-text type="smallcode" :value.sync="fileUpDown_fileNumber" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>파일명</i-col-header>
                <i-col>
                  <iui-text type="largecode" :value.sync="fileUpDown_files.lclFlNm" :enable="false" />
                </i-col>
                <i-col-header>파일크기</i-col-header>
                <i-col>
                  <iui-text
                    type="amount"
                    :value.sync="fileUpDown_files.flSz"
                    :enable="false"
                    prefix="("
                    suffix="byte)"
                  />
                </i-col>
              </i-row>
            </iui-container>
            <iui-button @click="fileUpDown_search = true">외부조회</iui-button>&nbsp;
            <iui-button @click="fileUpDown_add = true">외부추가</iui-button>&nbsp;
            <iui-button @click="fileUpDown_delete = true">외부삭제</iui-button>&nbsp;
            <iui-button @click="fileUpDown_save = true">외부저장</iui-button>&nbsp;
            <iui-button @click="fileUpDown_download = true">외부다운</iui-button>
          </pmis-content-box>
        </i-col>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              파일 이벤트
            </template>
            fileUpDown_add : {{ fileUpDown_add }} <br />
            fileUpDown_changed : {{ fileUpDown_changed }} <br />
            fileUpDown_delete : {{ fileUpDown_delete }} <br />
            fileUpDown_save : {{ fileUpDown_save }} <br />
            fileUpDown_search : {{ fileUpDown_search }} <br />
            fileUpDown_download : {{ fileUpDown_download }}
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
    <file-up-down
      toolbar-visible
      company-code="201900"
      v-model="fileUpDown_files"
      :file-number.sync="fileUpDown_fileNumber"
      add-button-text="파일추가"
      delete-button-text="파일삭제"
      save-button-text="파일저장"
      download-button-text="파일다운"
      :add.sync="fileUpDown_add"
      :changed.sync="fileUpDown_changed"
      :delete.sync="fileUpDown_delete"
      :save.sync="fileUpDown_save"
      :search.sync="fileUpDown_search"
      :download.sync="fileUpDown_download"
      @save-complete="file_save_complete"
      @save-error="file_save_error"
      @delete-complete="file_delete_complete"
      @delete-error="file_delete_error"
    />
  </pmis-page>
</template>
<script>
import FileUpDown from './FileUpDown.vue';

export default {
  components: {
    FileUpDown,
  },
  data() {
    return {
      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_fileNumber: 1,
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    file_save_complete() {
      this.$alert('파일 저장이 완료되었습니다.');
    },
    file_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      setTimeout(() => {
        if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
          this.fileUpDown_fileNumber = 0;
        }
      }, 300);
    },
    file_delete_error() {
      this.$alert('파일 삭제를 실패했습니다.');
    },
  },
};
</script>

<style scoped></style>

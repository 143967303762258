<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <table border="0" class="tbl_list" style="table-layout:auto">
            <tr>
              <th>거래선번호</th>
              <th>회사명</th>
            </tr>
            <tr v-for="(item, index) in tradeNoList" v-bind:key="index" style="cursor:pointer" @click="rowclick(index)">
              <td style="text-align:center">{{ item.tradeNo }}</td>
              <td align="left">{{ item.tradeName }}</td>
            </tr>
          </table>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
    },
    tradeNoList: {
      type: Array,
      default: () => {},
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    rowclick(index) {
      console.log(JSON.stringify(this.tradeNoList[index]));
      this.callback(null, this.tradeNoList[index].tradeNo);
      this.$modal.hide('selectTradeNo');
    },
  },
};
</script>

<style></style>

<template>
  <pmis-tab-box v-if="target == 'grp'" no-border-box no-padding-box>
    <template v-slot:title>권한목록</template>
    <pmis-content-box>
      <template v-slot:title-right>
        <iui-button value="신규" @click="onAdd" />
        <iui-button value="저장" @click="onSave" />
        <iui-button value="삭제" @click="onDel" />
      </template>
      <iui-container type="jqgrid" :id="elId" />
    </pmis-content-box>
  </pmis-tab-box>
  <iui-container type="jqgrid" :id="elId" v-else />
</template>

<script>
export default {
  props: {
    grpAuthPage: {
      // 그룹권한 페이지
      type: Boolean,
      default: true,
    },
    userAuthPage: {
      // 사용자권한 페이지
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },

  beforeCreate() {
    $mapGetters(this, {
      tabIndex: 'tabIndex',
      target: 'target',
      grpAuthInfo: 'grpAuthInfo',
      userAuthInfo: 'userAuthInfo',
    });
    $mapMutations(this, ['setGrpAuthInfo', 'setUserAuthInfo']);
  },

  created() {
    this.addEvent([
      {name: 'onAdd', func: this.onAdd},
      {name: 'onDel', func: this.onDel},
      {name: 'selectGroupList', func: this.selectGroupList},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet() {
      var me = this;
      const OPT = {
        Cols: [
          {label: '선택', name: 'chk', width: 60, edittype: 'checkbox', editable: true, hidden: !this.grpAuthPage},
          {label: '권한그룹', name: 'grpAuth', width: 100, edittype: 'text', editable: false},
          {label: '그룹명', name: 'grpNm', width: 100, edittype: 'text', editable: this.target == 'grp'},
          {label: '비고', name: 'rmk', width: 150, edittype: 'text', editable: this.target == 'grp'},
          {label: '-', name: 'originGrpAuth', width: 150, hidden: true},
        ],
      };

      var $grid = $('#' + me.elId);

      $grid.jqGrid({
        height: 200,
        width: 200,
        autowidth: true,
        shrinkToFit: true,
        selectionMode: 'singlerow',
        colModel: OPT.Cols,
        onSelectRow: function(rowid) {
          var $grid = $('#' + me.elId);
          //var lastRowId = $grid.lastRowId;
          if (rowid && rowid !== me.lastRowId && me.lastRowId) {
            $grid.saveRow(me.lastRowId);
            $grid.restoreRow(me.lastRowId);
          }
          $grid.editRow(rowid, true);
          me.lastRowId = rowid;
          //var rowdata = $grid.getRowData(rowid);
          //me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
        },
      });
      $gridAutoResize($grid);

      if (!this.grpAuthPage) this.selectGroupList();
      /*
      this.sheet.bind('onFocus', (e) => {
        if (e.row.Kind == 'Data') {
          this.setGrpAuthInfo(e.row);
          if (this.target == 'grp') {
            if (this.tabIndex == 0) {
              this.callEvent({name: 'selectGrpMenuAuthList', param: this.grpAuthInfo.grpAuth});
            } else {
              this.callEvent({name: 'selectGrpAuthUserList', param: this.grpAuthInfo.grpAuth});
            }
          }
        }
      });
      */
    },

    onAdd() {
      this.sheet.addRow();
    },
    onSave() {
      let saveJson = this.sheet.getSaveJson().data;
      let isValid = true;
      saveJson.some((item, index) => {
        item['pgmCd'] = this.userInfo.pgmCd;
        if (!item.grpAuth) {
          this.$alert(`${index + 1}번째 권한그룹값을 입력해주세요.`);
          isValid = false;
          return true;
        }

        if (!item.grpNm) {
          this.$alert(`${index + 1}번째 그룹명을 입력해주세요.`);
          isValid = false;
          return true;
        }
      });

      if (!isValid) return;

      axios.post('/grpAuth/saveGroupAuth', {grpAuthList: $_statusToCud(saveJson)}).then(response => {
        if (response.status == 200) {
          this.selectGroupList();
        }
      });
    },
    onDel() {
      this.$confirm('삭제하시겠습니까?', () => {
        this.sheet.deleteRow(this.sheet.getFocusedRow());
        let saveJson = this.sheet.getSaveJson().data;
        saveJson = $_statusToCud(saveJson).filter(item => item.cud == 3);
        saveJson[0]['pgmCd'] = this.userInfo.pgmCd;
        axios.post('/grpAuth/saveGroupAuth', {grpAuthList: saveJson}).then(response => {
          if (response.status == 200) {
            this.selectGroupList();
          }
        });
      });
    },
    selectGroupList(empNo) {
      const me = this;
      var $grid = $('#' + me.elId);

      $grid.jqGrid('clearGridData');
      axios
        .post('/grpAuth/selectGroupList', {
          pgmCd: this.grpAuthInfo.pgmCd,
          empNo: empNo,
          grpAuthPage: this.grpAuthPage,
        })
        .then(response => {
          if (response.status == 200) {
            var data = response.data;
            $grid.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
          }
        });
    },
  },
};
</script>

<style></style>

<script>
import {defineComponent, getCurrentInstance, reactive} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup';

export default defineComponent({
  name: 'IuiWindowPopup',
  props: {
    ...BaseProps,

    title: {
      type: String,
      default: undefined,
    },
    noTitleBar: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: undefined,
    },
  },
  emits: ['before-open', 'opend', 'before-close', 'closed'],
  setup(props, context) {
    const instance = getCurrentInstance();

    const Base = useBase(props, context, 'iui-window-popup');

    const windowPopup = useWindowPopup();
    const popup = windowPopup.get(instance.proxy.$route.query.id);
    const state = reactive({
      title: popup !== undefined ?popup.title : props.title,
    });

    const method = {
      btnClose_click() {
        method.close();
      },

      emit(eventName, args) {
        if (popup) {
          popup.emitter.emit(eventName, args);
        }
      },
      close() {
        if (popup) {
          windowPopup.close(popup);
        }
      },
    };

    return {
      Base,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div class="popup_modal iui-loading-overlay-parent">
    <div :class="{fg: !noTitleBar}" :style="{width: '100%', height: '100%'}">
      <div v-if="!noTitleBar" class="popup_header">
        <div>{{ state.title }}</div>
        <button v-if="!hideCloseButton" class="btn_close" @click="btnClose_click">
          <span class="blind">닫기</span>
        </button>
      </div>
      <div class="popup_contents" :style="{height: noTitleBar ? 'calc(100% - 50px)' : 'calc(100% - 100px)'}">
        <slot></slot>
      </div>
      <div class="popup_footer">
        <div class="footer-left">
          <slot name="button-left"></slot>
        </div>
        <div class="footer-center">
          <slot name="button-center"></slot>
        </div>
        <div class="footer-right">
          <slot name="button-right"></slot>
          <iui-button v-if="!hideCloseButton" color="btn_pop_neg" @click="btnClose_click">닫기</iui-button>
        </div>
      </div>
    </div>
    <iui-loading-overlay :message="loadingMessage" :visible="loading" />
  </div>
</template>

<template>
  <pmis-content-box style="height:320px">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box no-border-box>
            <template #title-right>
              <iui-button :enable="isCanSave" v-show="isCanSave" @click="saveRm">저장</iui-button>
            </template>
            <iui-container type="table">
              <i-row>
                <th class="col-header"><div style="text-align:center">파일명</div></th>
                <th class="col-header" v-show="isCanSave"><div style="text-align:center">첨부</div></th>
                <th class="col-header"><div style="text-align:center">다운로드</div></th>
                <th class="col-header"><div style="text-align:center">크기(KB)</div></th>
                <th class="col-header"><div style="text-align:center">비고</div></th>
                <!-- <i-col-header width="60px">구분</i-col-header> -->
                <!-- <i-col-header>파일명</i-col-header>
                <i-col-header v-show="isCanSave">첨부</i-col-header>
                <i-col-header>다운로드</i-col-header>
                <i-col-header>용량</i-col-header>
                <i-col-header>비고</i-col-header> -->
              </i-row>
              <i-row v-for="(item, index) in pictureList" :key="index">
                <!-- <i-col>{{ item.divNm }}</i-col> -->
                <i-col><iui-text type="text" :value="item.lclFlNm" :enable="false"/></i-col>
                <i-col v-show="isCanSave"
                  ><iui-button :enable="isCanSave" v-show="isCanSave" @click="attachFile(item)"
                    >찾아보기</iui-button
                  ></i-col
                >
                <i-col
                  ><iui-button :enable="item.flSz > 0" v-show="item.flSz > 0" @click="downloadFile(item)"
                    >다운로드</iui-button
                  ></i-col
                >
                <i-col><iui-text type="fileSize" :value="item.flSz" :enable="false"/></i-col>
                <i-col
                  ><iui-text
                    name="rmCn"
                    :enable="isCanSave"
                    :value="item.rmCn"
                    @change="item.rmCn = $event.target.value"
                /></i-col>
              </i-row>
            </iui-container>
            <div style="font-size:12px;margin-top:3px;">
              * 증빙사진 첨부 도움말<br />
              1) 작업 증빙사진은 PDF로 첨부해주세요.<br />
              2) 작업 증빙사진은 필수는 아니지만 첨부 후 “작업증빙사진 송부(카톡/메일)” 을 클릭하면 고객에게 송부됩니다.
            </div>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
    <iui-modal-old :name="attachFileModalId" :title="'찾아보기'" :btns="modalBtns1" width="400px" height="150px">
      <AttachFile />
    </iui-modal-old>
  </pmis-content-box>
</template>

<script>
import AttachFile from '../popup/AttachFile.vue';
export default {
  components: {
    AttachFile,
  },
  beforeCreate() {
    $mapGetters(this, ['cnfrmnMain', 'pictureList', 'tradeType', 'isChargeEmp']);
    $mapMutations(this, ['setModalAttach']);
  },
  created() {
    this.addEvent({name: 'ConstCmpl_Picture_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      sheetId1: 'sheet1' + this._uid,

      attachFileModalId: 'attachFileModal' + this._uid,

      // modalBtns1: [{name: '저장', callback: this.onAttachFileSave}, {name: '삭제', callback: this.onAttachFileDelete}],
      modalBtns1: [{name: '저장', callback: this.onAttachFileSave}],

      modalNm: '',

      listBoxLoading: false,
      otisPage: false,
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad(param) {
      console.log('ConstCmpl_Picture_Load');
      if (param !== undefined) {
        this.otisPage = param.otisPage;
      }
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {},
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      console.log('key', key);
      console.log('value', value);
      this.pictureList[key] = value;
      this.pictureList.chngPgmId = this.$store.getters['mdi/currentUrls'].url;
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onAttachFileSave() {
      this.callEvent({
        name: 'AttachFile_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'ConstCmplMainList_Search'});
        },
      });
    },
    onAttachFileDelete() {
      this.callEvent({
        name: 'AttachFile_Delete',
        param: data => {
          console.log(data);
          //this.$modal.hide(this.modalNm);
          this.callEvent({name: 'ConstCmplMainList_Search'});
        },
      });
    },
    attachFile(rowData) {
      let modalOnoffline = {};
      modalOnoffline.cnfrmnNo = rowData.cnfrmnNo;
      modalOnoffline.photoSn = rowData.photoSn;
      modalOnoffline.fileNo = rowData.fileNo;
      modalOnoffline.readonly = this.cnfrmnMain.cnfrmnSttusCd == '50' ? true : false;
      modalOnoffline.attachFileModalId = this.attachFileModalId; //20211208 파일삭제 백그라운드 리로드

      this.setModalAttach(modalOnoffline);

      (this.modalBtns1 = [
        {name: '저장', callback: this.onAttachFileSave},
        {
          id: 'fileDelete_' + this.attachFileModalId,
          name: 'fileDelete',
          callback: this.onAttachFileDelete,
          show: false,
        },
      ]), //20211208 fileDelete 추가 백그라운드 리로딩용
        this.onOpenPopup(this.attachFileModalId);
    },
    async downloadFile(rowData) {
      var url = '/file/download?pgmCd=' + rowData.pgmCd + '&flNo=' + rowData.fileNo + '&grpNo=' + rowData.grpNo;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, rowData.lclFlNm);
        } else {
          link.setAttribute('download', rowData.lclFlNm);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('다운로드 받을 파일이 존재하지 않습니다.');
      }
    },
    async saveRm() {
      var me = this;
      if (await this.$confirm('증빙사진 비고 정보를 저장하시겠습니까?')) {
        for (var i = 0; i < this.pictureList.length; i++) {
          this.pictureList[i].chngPgmId = this.$store.getters['mdi/currentUrls'].url;
        }

        var response = await axios
          .post('/confdoc/constcmpl/updateAttachFileRm', this.pictureList)
          .catch(e => console.error('저장에 실패했습니다. ', e));
        if (response.status == 200) {
          this.callEvent({name: 'ConstCmpl_SearchSubDetail'});
        } else {
          me.$alert('저장에 실패하였습니다.');
        }
      }
    },
  },
  computed: {
    isCanSave() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
  },
};
</script>

<style></style>

const PassPlugin = {
  install(Vue) {
    Vue.prototype.$PASSCertification = function(userName, userMobileNo) {
      return new Promise((resolve, reject) => {
        // console.log('userName', userName);
        // console.log('userMobileNo', userMobileNo);        
        // var i = 0;
        // if (i == 0) {
        //   return resolve(true);
        // } else {
        //   return reject(false);
        // }
        var passResult = {
          result:'', 
          data:'', 
          errorMessage:''
        };

        var host = process.env.VUE_APP_BASE_URL;
        var url = host + '/niceid/process_start.do';
        var win =    window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        win.focus();
        window.addEventListener("message", function(ev) {
          // if (ev.origin == host) {
            if (ev.data.errorCode != 0) {
              // 에러 : 에러메시지 표시
              alert(ev.data.errorMessage);
              passResult.result = false;
              passResult.errorMessage = ev.data.errorMessage;              
              reject(passResult);
              win.close();
            } else {
              /* 
              성공
              ev.data.xxxx 로 값 조회하여 사용
                passNo          : 순번( T_RB_PASS_RESULT 패스번호 )
                cipherDatetime  : 암호화시간 YYMMDDHHMISS
                reqSeq          : 요청번호
                authType        : 인증방식
                resSeq          : 응답번호
                name            : 이름
                birthdate       : 생년월일
                gender          : 성별(남자-1, 여자-2)
                nationalinfo    : 외국인여부(0-내국인, 1-외국인)
                di              : DI
                ci              : CI
                mobileCo        : 휴대폰회사(3자리)
                mobileNo        : 휴대폰번호
              */
              if (userName !== undefined && userName != null && userName != "") {
                userName = userName.replace(/(\s*)/g, ""); // 이름에 포함된 모든 공백을 제거
              }
              if (userMobileNo !== undefined && userMobileNo != null && userMobileNo != "") {
                userMobileNo = userMobileNo.replace(/(\s*)/g, ""); // 핸드폰번호에 포함된 모든 공백을 제거
              }
              console.log('계약정보 사용자이름[' + userName + "]");
              console.log('계약정보 사용자폰번호[' + userMobileNo + "]");
              console.log(ev.data);
              console.log('PASS 사용자이름[' + ev.data.data.name + "]");
              console.log('PASS 사용자폰번호[' + ev.data.data.mobileNo + "]");
              console.log('PASS 사용자이름 공백제거[' + ev.data.data.name.replace(/(\s*)/g, "") + "]");
              
              // 테스트용 사용자는 대리인과 비교 없이 바로 통과
              // if ("01023135459,01025049676,01090854243,01044691515,01041690666".indexOf(ev.data.data.mobileNo) > -1 ) {
              //   passResult.result = true;
              //   passResult.data = ev.data.data;
              // } else {
                if (userName.indexOf(ev.data.data.name.replace(/(\s*)/g, "")) > -1 && ev.data.data.mobileNo == userMobileNo){
                  passResult.result = true;
                  passResult.data = ev.data.data;
                } else {
                  passResult.result = false;
                  passResult.errorMessage = "PASS 인증한 사용자와 해당 업무의 사용자와 일치하지 않습니다.";                
                }
              // }
              resolve(passResult);
              win.close();
            }
          // }
        }, {once:true}); // 한번만 호출
      });
    };

    // 21-02-17 이동원 추가 (고객 사이트 login시 PASS인증 / 입력값 없음 )
    Vue.prototype.$PASSCertificationP = function() {
      return new Promise((resolve, reject) => {
        var passResult = {
          result:'', 
          data:'', 
          errorMessage:''
        };

        var host = process.env.VUE_APP_BASE_URL;
        var url = host + '/niceid/process_start.do';
        var win =    window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        win.focus();
        window.addEventListener("message", function(ev) {
          // if (ev.origin == host) {
            if (ev.data.errorCode != 0) {
              // 에러 : 에러메시지 표시
              alert(ev.data.errorMessage);
              passResult.result = false;
              passResult.errorMessage = ev.data.errorMessage;              
              reject(passResult);
              win.close();
            } else {
              /* 
              성공
              ev.data.xxxx 로 값 조회하여 사용
                passNo          : 순번( T_RB_PASS_RESULT 패스번호 )
                cipherDatetime  : 암호화시간 YYMMDDHHMISS
                reqSeq          : 요청번호
                authType        : 인증방식
                resSeq          : 응답번호
                name            : 이름
                birthdate       : 생년월일
                gender          : 성별(남자-1, 여자-2)
                nationalinfo    : 외국인여부(0-내국인, 1-외국인)
                di              : DI
                ci              : CI
                mobileCo        : 휴대폰회사(3자리)
                mobileNo        : 휴대폰번호
              */
              console.log(ev.data);
              console.log('name[' + ev.data.data.name + "]");
              console.log('mobileNo[' + ev.data.data.mobileNo + "]");
              console.log('passNo[' + ev.data.data.passNo + "]");
              passResult.result = true;
              passResult.data = ev.data.data;
              resolve(passResult);
              win.close();
            }
          // }
        }, {once:true}); // 한번만 호출
      });
    };

    // 2021.04.02 개인/법인용 아이디 찾기 PASS (휴대폰번호만 비교)
    Vue.prototype.$PASSCertificationByFindID = function(userMobileNo) {
      return new Promise((resolve, reject) => {
        var passResult = {
          result:'', 
          data:'', 
          errorMessage:''
        };

        var host = process.env.VUE_APP_BASE_URL;
        var url = host + '/niceid/process_start.do';
        var win =    window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        win.focus();
        window.addEventListener("message", function(ev) {
          // if (ev.origin == host) {
            if (ev.data.errorCode != 0) {
              // 에러 : 에러메시지 표시
              alert(ev.data.errorMessage);
              passResult.result = false;
              passResult.errorMessage = ev.data.errorMessage;              
              reject(passResult);
              win.close();
            } else {
              /* 
              성공
              ev.data.xxxx 로 값 조회하여 사용
                passNo          : 순번( T_RB_PASS_RESULT 패스번호 )
                cipherDatetime  : 암호화시간 YYMMDDHHMISS
                reqSeq          : 요청번호
                authType        : 인증방식
                resSeq          : 응답번호
                name            : 이름
                birthdate       : 생년월일
                gender          : 성별(남자-1, 여자-2)
                nationalinfo    : 외국인여부(0-내국인, 1-외국인)
                di              : DI
                ci              : CI
                mobileCo        : 휴대폰회사(3자리)
                mobileNo        : 휴대폰번호
              */
              console.log('userMobileNo[' + userMobileNo + "]");
              console.log(ev.data);
              console.log('name[' + ev.data.data.name + "]");
              console.log('mobileNo[' + ev.data.data.mobileNo + "]");
              
              // 테스트용 사용자는 대리인과 비교 없이 바로 통과
              // if ("01023135459,01025049676,01090854243,01044691515,01041690666".indexOf(ev.data.data.mobileNo) > -1 ) {
              //   passResult.result = true;
              //   passResult.data = ev.data.data;
              // } else {
                // 휴대폰 번호만 비교 (이름은 비교 X)
                if (ev.data.data.mobileNo == userMobileNo){
                  passResult.result = true;
                  passResult.data = ev.data.data;
                } else {
                  passResult.result = false;
                  passResult.errorMessage = "PASS 인증한 사용자의 전화번호와 선택한 전화번호가 일치하지 않습니다.";
                }
              // }
              resolve(passResult);
              win.close();
            }
        }, {once:true}); // 한번만 호출
      });
    };

  },
};

export default PassPlugin;
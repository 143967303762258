import {reactive, toRef, computed} from '@vue/composition-api';

export const PrefixProps = {
  prefix: {
    type: String,
    default: undefined,
  },
  prefixWidth: {
    type: String,
    default: undefined,
  },
};

export function usePrefix(props, context, base) {
  const prefixRef = toRef(props, 'prefix');
  const prefixWidthRef = toRef(props, 'prefixWidth');

  const state = reactive({
    has: computed(() => prefixRef.value !== undefined || context.slots.prefix !== undefined),
    id: computed(() => base.getId('prefix')),
    style: {
      width: prefixWidthRef,
      flex: computed(() => (prefixWidthRef.value === undefined ? undefined : `0 0 ${prefixWidthRef.value} !important`)),
    },
  });

  return {
    state,
  };
}

export default usePrefix;

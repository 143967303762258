<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container type="css-flex">
      <i-row ref="srchList">
        <i-col>
          <!-- 검색조건 -->
          <iui-container type="css-flex" theme="bullet">
            <i-row height="70px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header style="width: 80px">구분</i-col-header>
                    <i-col style="width: 100px">
                      <iui-select-old
                        id="searchCheckStatus"
                        name="searchCheckStatus"
                        :items="checkStatusArray"
                        defaultCd="S"
                        @change="setSearchValue('searchCheckStatus', $event)"
                      ></iui-select-old>
                    </i-col>
                    <i-col-header style="width: 70px">CC/팀</i-col-header>
                    <i-col style="width: 180px">
                      <!-- <iui-select-old
                id="searchCct"
                name="searchCct"
                :items="cctArray"
                defaultCd="S"
                @change="setSearchValue('searchCct', $event)"
              ></iui-select-old> -->
                      <iui-text name="searchCct" :value.sync="searchInfo.searchCct" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>건물명</i-col-header>
                    <i-col>
                      <iui-text name="searchBuldNm" :value.sync="searchInfo.searchBuldNm" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>영업담당자</i-col-header>
                    <i-col>
                      <iui-text name="searchContEmpNm" :value.sync="searchInfo.searchContEmpNm" @enter="onPreSearch" />
                    </i-col>
                    <i-col style="float: right">
                      <iui-button @click="searchClear"> 검색조건 초기화 </iui-button>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>계약일</i-col-header>
                    <i-col
                      ><span style="white-space:nowrap">
                        <iui-datepicker-old
                          name="checkContDateStart"
                          suffix="~"
                          @change="onSetValue($event, 'checkContDateStart')"
                          :value.sync="searchInfo.checkContDateStart"/>
                        &nbsp;<iui-datepicker-old
                          name="checkContDateEnd"
                          @change="onSetValue($event, 'checkContDateEnd')"
                          :value.sync="searchInfo.checkContDateEnd"
                      /></span>
                    </i-col>

                    <i-col-header></i-col-header>
                    <i-col></i-col>

                    <i-col-header>계약번호</i-col-header>
                    <i-col>
                      <iui-text name="searchCtrtNo" :value.sync="searchInfo.searchCtrtNo" @enter="onPreSearch" />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>

            <!-- 신청서 목록 (sheet)-->
            <i-row height="220px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    신청서 목록
                  </template>
                  <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>
            <!-- Elite 계약정보 -->
            <i-row height="65px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    <span v-if="requestInfo.reqstdocDivCd == 'ELITE'">Elite 계약정보</span>
                    <span v-else>OtisOne 계약정보</span>
                  </template>
                  <iui-container type="table">
                    <colgroup>
                      <col width="130px" />
                      <col />
                      <col width="130px" />
                      <col />
                    </colgroup>
                    <i-row>
                      <i-col-header>1.관리번호</i-col-header>
                      <i-col>
                        <iui-text
                          name="inputManageNo"
                          :value.sync="requestInfo.manageNo"
                          :readonly="isOtisPage"
                        ></iui-text>
                      </i-col>
                      <i-col-header>2.건물명</i-col-header>
                      <i-col>
                        <iui-text name="inputBuldNo" :value.sync="requestInfo.buldNm" :readonly="isOtisPage"></iui-text>
                      </i-col>
                    </i-row>
                  </iui-container>
                </pmis-content-box>
              </i-col>
            </i-row>
            <!-- Elite 점검정보 -->
            <i-row height="250px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    <span v-if="requestInfo.reqstdocDivCd == 'ELITE'">Elite 점검정보</span>
                    <span v-else>OtisOne 점검정보</span>
                  </template>

                  <iui-container type="table">
                    <colgroup>
                      <col width="130px" />
                      <col />
                      <col width="130px" />
                      <col />
                    </colgroup>
                    <i-row>
                      <i-col-header>1.점검일시</i-col-header>
                      <i-col colspan="3">
                        <i-row style="width: 99.9%; text-align:left;">
                          매월
                          <iui-text
                            :value.sync="requestInfo.chckDD"
                            name="chckDD"
                            style="width:25px;"
                            maxLength="2"
                            :readonly="isOtisPage"
                          ></iui-text>
                          일
                          <iui-text
                            :value.sync="requestInfo.chckHH"
                            name="chckHH"
                            style="width: 25px;"
                            maxLength="2"
                            :readonly="isOtisPage"
                          ></iui-text>
                          시
                        </i-row>
                        <i-row style="width: 99.9%; text-align:left;">
                          ※ 원활한 점검을 위해 EL 운행량이 적은 시간대로 점검시간을 지정해 주시기바랍니다. ( ※ 작성 예시
                          : 매월 <b>10</b> 일 <b>15</b> 시 ) <br />
                          ※ 고객 사용중에는 (대기층운전, 전용운전 등) <span v-if="requestInfo.reqstdocDivCd == 'ELITE'">Elite</span><span v-else>OtisOne</span> 점검운전을 하지 못하므로 반드시 고객과
                          협의후 <span v-if="requestInfo.reqstdocDivCd == 'ELITE'">Elite</span><span v-else>OtisOne</span> 점검시간을 지정해주십시오.</i-row
                        >
                      </i-col>
                    </i-row>

                    <i-row>
                      <i-col-header>2.<span v-if="requestInfo.reqstdocDivCd == 'ELITE'">리포트 수령자</span><span v-else>계약자</span></i-col-header>
                      <i-col>
                        <i-row>
                          <iui-text
                            :value.sync="requestInfo.recptmanNm"
                            style="width: 100px;"
                            placeholder=" 이름"
                            maxLength="30"
                            :readonly="isOtisPage"
                          ></iui-text>
                        </i-row>
                      </i-col>
                      <i-col-header width="120px; height:300px">3.수령방법</i-col-header>
                      <i-col>
                        <iui-checkbox-group-old
                          name="recptMthEmailYn"
                          :items="checkboxItem1"
                          @change="setCheckBoxControl('recptMthReset', $event)"
                          :enable="!isOtisPage"
                        ></iui-checkbox-group-old>
                        <iui-checkbox-group-old
                          name="recptMthPostYn"
                          :items="checkboxItem2"
                          @change="setCheckBoxControl('recptMthReset', $event)"
                          :enable="!isOtisPage"
                          v-show="requestInfo.reqstdocDivCd == 'ELITE'"
                        ></iui-checkbox-group-old>
                        <iui-checkbox-group-old
                          name="recptMthRejectYn"
                          :items="[{label: '수령하지않음', value: 'N'}]"
                          @change="setCheckBoxControl('recptMthReset', $event)"
                          :enable="!isOtisPage"
                          v-show="requestInfo.reqstdocDivCd == 'ELITE'"
                        ></iui-checkbox-group-old>
                      </i-col>
                    </i-row>

                    <i-row>
                      <i-col-header>4.e-mail주소</i-col-header>
                      <i-col colspan="3">
                        <i-row>
                          ①
                          <iui-text
                            :value.sync="requestInfo.recptEmail1"
                            style="width:200px;"
                            placeholder=" 예) xxx@xxx.xxx"
                            maxLength="50"
                            :readonly="isOtisPage"
                          ></iui-text>
                          ②
                          <iui-text
                            :value.sync="requestInfo.recptEmail2"
                            style="width:200px;"
                            placeholder=" 예) xxx@xxx.xxx"
                            maxLength="50"
                            :readonly="isOtisPage"
                          ></iui-text>
                        </i-row>
                      </i-col>
                    </i-row>

                    <i-row v-show="requestInfo.reqstdocDivCd == 'ELITE'">
                      <i-col-header>5.우편주소</i-col-header>
                      <i-col colspan="3">
                        <i-row>
                          우편번호 :
                          <iui-text
                            :value.sync="requestInfo.recptZip"
                            style="width:110px;"
                            placeholder=" 5자리 또는 6자리"
                            maxLength="6"
                            :readonly="isOtisPage"
                          ></iui-text>
                          <!-- <iui-button @click="onAddrSearch">찾기</iui-button> -->

                          주소 : ①
                          <iui-text
                            :value.sync="requestInfo.recptAdres"
                            style="width:400px;"
                            placeholder=" 주소 1"
                            maxLength="200"
                            :readonly="isOtisPage"
                          ></iui-text>
                          ②
                          <iui-text
                            :value.sync="requestInfo.recptAdresDetail"
                            style="width:200px;"
                            placeholder=" 주소 2(상세)"
                            maxLength="200"
                            :readonly="isOtisPage"
                          ></iui-text>
                        </i-row>
                      </i-col>
                    </i-row>

                    <i-row>
                      <i-col-header>6.SMS수신</i-col-header>
                      <i-col>
                        <iui-radio-group-old
                          name="smsYn"
                          :value="requestInfo.smsYn"
                          :items="radioItem1"
                          :enable="!isOtisPage"
                          @change="setSmsReceiveTelInfo('smsYn', $event)"
                        ></iui-radio-group-old>
                        <iui-text
                          v-if="isSmsReceive"
                          :value.sync="requestInfo.smsNo"
                          name="smsNo"
                          placeholder=" 예) 01012345678 ( - 없이 입력 )"
                          :readonly="isOtisPage"
                        ></iui-text>
                      </i-col>
                      <i-col-header>7.통신명의</i-col-header>
                      <i-col>
                        <iui-radio-group-old
                          name="nmSeCd"
                          :value="requestInfo.nmSeCd"
                          :items="radioItem2"
                          :enable="!isOtisPage"
                          @change="setSmsReceiveTelInfo('nmSeCd', $event)"
                        ></iui-radio-group-old>
                      </i-col>
                    </i-row>
                  </iui-container>
                </pmis-content-box>
              </i-col>
            </i-row>
            <!-- 저장 및 전자서명 -->
            <i-row v-if="isCustomerPage" height="40px">
              <i-col>
                <div style="display: flex;">
                  <p style="margin: 0px auto; margin-top:10px" > 
                    <!--계약서에 포함된상태이여서 변경을 하게 되면 계약서랑 내용이 틀려짐 그래서 막음 20230110 lgksja -->
                    <!--<span style="margin-left: 10px;" v-if="requestInfo.progrsSttusCd != '10'"
                      ><iui-button style="margin: 0px auto;" @click="onSave">변경 및 전자서명</iui-button></span
                    >-->
                    <span style="margin-left: 10px;" v-if="requestInfo.progrsSttusCd == '10'"
                      ><iui-button style="margin: 0px auto;" @click="onSave">저장 및 전자서명</iui-button></span
                    >
                  </p>
                </div>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      cmmBtns: [
        //{name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        //{name: '출력', class: 'p', callback: this.onPrint},
      ],
      // 검색 정보
      searchInfo: {
        searchBuldNm: '', // 건물명
        searchContEmpNm: '', // 영업담당자
        searchCheckStatus: '', // 구분 (신청서 상태 - 신청완료/작성중/미작성 )
        searchCct: '', // CC
        searchCtrtNo: '', // 계약 번호
        checkContDateStart: '', // 계약 시작일
        checkContDateEnd: '', // 계약 종료일
        applyNewYn: '',
        summaryYm: '',
        isOtisPage: false, // 담당자 페이지
        isCustomerPage: true, // 고객 페이지
      },
      // 구분 (신청서 상태)
      checkStatusArray: [
        {text: '작성중', value: '10'},
        {text: '신청완료', value: '20'},
        {text: '오프라인완료', value: '30'}, // 오프라인 완료 추가(21.05.10)
        {text: '미작성', value: '99'}, // 신청서 데이터 X
      ],
      cctArray: [], //CCT 배열

      // checkBoxItem 들
      checkboxItem1: [{label: 'email(기본)', value: 'Y', isChecked: true}],
      checkboxItem2: [{label: '우편', value: 'Y', isChecked: false}],

      // radioItem 들
      radioItem1: [
        {label: '수신', value: 'Y', default: true, isChecked: true},
        {label: '미수신', value: 'N'},
      ],
      radioItem2: [
        {label: 'OTIS', value: '10', isChecked: true}, // OTIS
        {label: '고객', value: '20', isChecked: false}, // CUSTOMER
      ],

      // 신청정보
      requestInfo: {
        reqstdocNo: '', // 신청서 번호 (계약번호)
        reqstdocOdr: -1, // 신청서 차수
        ctrtNo: '', // 계약 번호
        contEmpId: '', // 영업 담당자 ID
        tradeNo: '', // 거래선번호
        tradeSfx: '', // 거래선SUFFIX
        tradeName: '', // 거래선명
        manageNo: '', // 관리번호
        buldNm: '', // 건물명
        chckCycle: '', // 점검일시 (DD+HH)
        chckDD: '', // 점검일시 DD
        chckHH: '', // 점검일시 HH
        recptmanNm: '', // 리포트 수령자
        recptMthEmailYn: '', // 수령 방법 메일 여부
        recptMthPostYn: '', // 수령 방법 우편 여부
        progrsSttusCd: '', // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
        reqstDt: '', // 신청일
        checkContDate: '', // 계약일
        recptEmail1: '', // recptMthemail 주소1
        recptEmail2: '', // recptMthemail 주소2
        recptZip: '', // 수령 우편 번호
        recptAdres: '', // 수령 주소
        recptAdresDetail: '', // 수령 주소 상세
        smsYn: '', // SMS 수신여부
        smsNo: '', // SMS 수신번호
        nmSeCd: '', // 통신명의
        agreYn: 'X', // 동의 여부 (Y / N / X )- 동의 / 미동의 / 선택 X
        applcntNm: '', // 신청인명
        reqstdocDivCd: 'ELITE', // 신청서 구분 코드
        repntName: '', // 거래선 대표자명 (신청인 명으로 들어감)
        isOtisPage: false, // 담당자 페이지
        isCustomerPage: true, // 고객 페이지
        notiType: 'REQALL', // 신청정보 알림
        isReqZero: false, // 신청서 0차수 여부
      },

      // Elite Service 포커싱
      focusKeys: {
        reqstdocNo: '', // 신청서 번호  (계약 번호)
        reqstdocOdr: -1, // 신청서 차수
        ctrtNo: '', // 계약 번호
        ctrtOdr: '', // 계약 차수
        ctrtDivCd: '', // 계약 종류
        contractType: '',
        signType: '',
        checkContOpts: '', //EL/EP ELITE  PL/PR OtisOn
        status : '작성중',
      },

      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId, // JQ시트
      listBoxLoading: false,

      isSmsReceive: false, // SMS 수신 여부
      isFirstSearch: true, // 페이지 첫 로드 OK 여부
      isProcessing: false, // 저장 무한클릭 방지

      OZParam: {},
      isOtisPage: false, // 담당자 페이지
      isCustomerPage: true, // 고객 페이지
      goSign: false, // 저장 후 바로 전자서명 여부
    };
  },
  created() {
    this.addEvent({name: 'EliteList_Search', func: this.onPreSearch});
  },
  mounted() {
    this.onInitRequestInfo();
    // 그리드
    const OPT = {
      Cols: [
        //{name: "reqstdocInfo",        label: "신청서",          width: 80,    align: "center"},
        {name: 'status', label: '구분', width: 90, align: 'center'},
        {name: 'cct', label: 'CCT', width: 70, align: 'center'},
        {name: 'buldNm', label: '건물명', width: 160},
        {name: 'ctrtNo', label: '계약번호', width: 100, align: 'center'},
        {name: 'tradeName', label: '거래선명', width: 180},
        {name: 'contEmpNm', label: '영업담당자', width: 90, align: 'center'},
        {
          name: 'reqstDt',
          label: '신청일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContDate',
          label: '계약일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContStartDate',
          label: '계약시작일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContFinDate',
          label: '계약종료일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {name: 'docCat', label: '표준/<br>비표준', width: 55, align: 'center'},
        //{name: "checkCntAz",          label: "방문점검-매월",   width: 200 },
        //{name: "viewCtrt",            label: "계약서",          width: 80 },
        {name: 'progrsSttusCd', label: '상태코드', width: 0, hidden: true},
        //{name: "docNm",               label: "계약종류",        width: 0,     hidden:true },
        {name: 'ctrtOdr', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약종류2', width: 0, hidden: true}, // OC/TC
        {name: 'reqstdocNo', label: '신청서번호', width: 0, hidden: true}, // 신청서번호
        {name: 'reqstdocOdr', label: '신청서차수', width: 0, hidden: true}, // 신청서차수
        {name: 'contEmpId', label: '영업담당자ID', width: 0, hidden: true}, // 영업담당자ID
        {name: 'tradeNo', label: '거래선번호', width: 0, hidden: true}, // 거래선번호
        {name: 'tradeSfx', label: '거래선SUFFIX', width: 0, hidden: true}, // 거래선 SUFFIX
        {name: 'repntName', label: '거래선대표자명', width: 0, hidden: true}, // 거래선 대표자명
        {name: 'manageNo', label: '관리번호', width: 0, hidden: true}, // 관리번호
        {name: 'signType', label: 'signType', width: 0, hidden: true}, // 서명 ON/ OFF
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
      ],
    };
    OPT.Cols.push(
      // eslint-disable-next-line
      {
        name: '계약서',
        label: '계약서',
        width: 55,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          cellvalue = true;
          return cellvalue == true ? '<button >보기</button>' : '';
        },
        cellattr: $_rowMearge,
      },
      /* ITSR64770 전자계약시스템> Otis ONE 신청서 추가*/
      {
        name: 'checkContOpts',
        label: 'Elite/<br>OtisONE',
        width: 65,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          if (rowObject.checkContOpts.indexOf('EL') > -1 || rowObject.checkContOpts.indexOf('EP') > -1) {
            cellvalue = true;
          }
          return cellvalue == true ? 'Elite' : 'OtisONE';
        },
        cellattr: $_rowMearge,
      }, // EL/EP ELITE  PL/PR OtisOne 
      /* ITSR64770 전자계약시스템> Otis ONE 신청서 추가*/
    );
    OPT.Cols.unshift(
      // eslint-disable-next-line
      {
        name: '신청서',
        label: '신청서',
        width: 55,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          if (rowObject.status == '신청완료' || rowObject.status == '작성중' || rowObject.status == '오프라인완료') {
            cellvalue = true;
          }
          return cellvalue == true ? '<button >보기</button>' : '';
        },
        cellattr: $_rowMearge,
      }
    );
    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 140,
      width: 1115,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,

      // 셀 클릭 이벤트
      onCellSelect: function(rowId, iCol) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        me.onInitRequestInfo(); //requestInfo init()

        // focuskey set
        me.focusKeys.reqstdocNo = rowData.reqstdocNo;
        me.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        me.focusKeys.contractType = rowData.contractType;
        me.focusKeys.signType = rowData.signType;
        me.focusKeys.checkContOpts = rowData.checkContOpts;
        me.focusKeys.status = rowData.status;
        console.log('focus key ', me.focusKeys);

        // 상세조회 START
        me.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
        me.onReqDetailSearch(); // 상세조회

        // 계약서 보기
        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['계약서']) {
          if (rowData.signType == 'ON') {
            me.onCtrtView(rowData);
          } else {
            me.$alert('선택된 계약은 오프라인으로 진행한 계약이라 계약서를 조회할 수 없습니다.');
          }
        }
        // 신청서 보기
        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['신청서']) {
          if (rowData.status == '신청완료' || rowData.status == '작성중') {
            me.onReqView();
          } else if (rowData.status == '오프라인완료') {
            me.$alert('선택된 신청서는 오프라인으로 진행한 신청서로 신청서를 조회할 수 없습니다.');
          }
        }
      },
    });
    // 검색시 날짜 지정 없으면 3달치만 조회
    if (this.searchInfo.summaryYm === undefined || this.searchInfo.summaryYm == '') {
      this.searchInfo.checkContDateStart = $_addDay($_getCurrentDate(), -90);
      this.searchInfo.checkContDateEnd = $_getCurrentDate();
    }

    // 검색조건 - 구분 IE 로딩위해 추가
    setTimeout(() => {
      $('#searchCheckStatus_select').prop('selectedIndex', 0);
    }, 100);

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onDefaultValSet();
    this.onPreSearch();

    // 20210407 검색창 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });
  },

  methods: {
    // 창 리사이즈
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      //$grid.jqGrid("setGridWidth", newWidth-1, true); // true 줄 시에 크기값 비율고정됨

      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, false);

      //header 2칸짜리 height 줄이기
      $('#gview_' + this.sheetId + " .ui-jqgrid-labels  th  div").css("line-height","20px");
    },
    // 페이지 값/변수 초기화
    onDefaultValSet() {
      // 개인정보 운영방침 문구 replace
      // var pStr = document.getElementById("privacyTextarea").value;
      // pStr = pStr.replace(/<br>/gi, "\r\n");
      // pStr = pStr.replace(/<t>/gi, "\t");
      // document.getElementById("privacyTextarea").value = pStr;

      // radio, checkbox 값 설정
      document.getElementsByName('recptMthEmailYn')[0].checked = true;
      document.getElementsByName('recptMthPostYn')[0].checked = false;
      document.getElementsByName('recptMthRejectYn')[0].checked = false;
      document.getElementsByName('smsYn')[0].checked = true;
      document.getElementsByName('nmSeCd')[0].checked = true;
      // document.getElementsByName("agreYn")[0].checked = false;
      // document.getElementsByName("agreYn")[1].checked = false;

      // default value 설정
      this.requestInfo.recptMthPostYn = 'N';
      this.requestInfo.smsYn = 'Y';
      this.requestInfo.nmSeCd = '10';
      this.requestInfo.progrsSttusCd = '10';
      this.requestInfo.reqstdocDivCd = 'ELITE';
      this.requestInfo.notiType = 'REQALL';
      this.requestInfo.isReqZero = false;

      this.isSmsReceive = true;
    },
    // RequestInfo 초기화
    onInitRequestInfo() {
      for (var key in this.requestInfo) {
        if (this.requestInfo.hasOwnProperty(key)) {
          this.requestInfo[key] = '';
        }
      }
      this.requestInfo.reqstdocDivCd = 'ELITE';
      this.requestInfo.notiType = 'REQALL';
      this.requestInfo.isReqZero = false;
    },
    // 페이지 최초 검색
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.searchInfo.applyNewYn = '';
          this.searchInfo.summaryYm = '';

          this.searchInfo.applyNewYn = params.applyNewYn;
          this.searchInfo.summaryYm = params.summaryYm;
        }
      }

      this.focusKeys.reqstdocNo = '';
      this.focusKeys.reqstdocOdr = 0;
      this.onSearch();
    },
    //상단 검색조건 설정 (건물명/계약종류/구분/CC)
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.searchInfo[target] = value;
    },
    // 서치 selectBox 설정
    setSearchValue(target, event) {
      this.searchInfo[target] = event.target.value;
    },
    // 검색조건 초기화
    searchClear() {
      for (var key in this.searchInfo) {
        if (this.searchInfo.hasOwnProperty(key)) {
          this.searchInfo[key] = '';
        }
      }
      for (var key2 in this.focusKeys) {
        if (this.focusKeys.hasOwnProperty(key2)) {
          this.focusKeys[key2] = '';
        }
      }
      this.searchInfo.checkContDateStart = $_addDay($_getCurrentDate(), -90);
      this.searchInfo.checkContDateEnd = $_getCurrentDate();
      this.searchInfo.isOtisPage = false;
      this.searchInfo.isCustomerPage = true;
      $('#searchCheckStatus_select').prop('selectedIndex', 0);
      //$("#searchCct_select").prop("selectedIndex",0);
    },
    // DATA -> RequestInfo 값 셋팅
    onSetRequestInfo(Data) {
      // 검색되는 key insert
      for (var key in Data) {
        if (this.requestInfo.hasOwnProperty(key)) {
          this.requestInfo[key] = Data[key];
          // SMS 수신여부 관련 수신 휴대폰 번호 display처리
          if (key == 'smsYn') {
            if (Data[key] == 'N') {
              this.isSmsReceive = false;
            } else {
              this.isSmsReceive = true;
            }
          }
        }
      }
      //ITSR64770 전자계약시스템> Otis ONE 신청서 추가
      if(typeof(Data.checkContOpts)!="undefined"){
        this.requestInfo.reqstdocDivCd = (Data.checkContOpts =='Elite')?'ELITE':'OTISONE';
        console.log("Data.checkContOpts",Data.checkContOpts);
        console.log("this.requestInfo.reqstdocDivCd",this.requestInfo.reqstdocDivCd);
      }
      //ITSR64770 전자계약시스템> Otis ONE 신청서 추가
      if (this.requestInfo.recptMthEmailYn == 'Y') {
        document.getElementsByName('recptMthEmailYn')[0].checked = true;
      } else {
        document.getElementsByName('recptMthEmailYn')[0].checked = false;
      }
      if (this.requestInfo.recptMthPostYn == 'Y') {
        document.getElementsByName('recptMthPostYn')[0].checked = true;
      } else {
        document.getElementsByName('recptMthPostYn')[0].checked = false;
      }
      if (this.requestInfo.recptMthEmailYn == 'N' && this.requestInfo.recptMthPostYn == 'N') {
        //this.requestInfo.recptMthRejectYn = "N";
        document.getElementsByName('recptMthRejectYn')[0].checked = true;
      } else {
        document.getElementsByName('recptMthRejectYn')[0].checked = false;
      }
    },
    // 수령방법 , 동의여부 checkbox 제어
    setCheckBoxControl(key, e) {
      // 수령방법 checkbox 제어
      if (key == 'recptMthReset') {
        if (e.target.value == 'N') {
          // 수령하지 않음 체크
          // 이메일 / 우편 초기화
          document.getElementsByName('recptMthEmailYn')[0].checked = false;
          document.getElementsByName('recptMthPostYn')[0].checked = false;
          this.requestInfo.recptMthEmailYn = e.target.value;
          this.requestInfo.recptMthPostYn = e.target.value;
        } else if (e.target.value == 'Y') {
          // 이메일 또는 우편 체크
          document.getElementsByName('recptMthRejectYn')[0].checked = false;
          if (e.target.name == 'recptMthEmailYn') {
            if (document.getElementsByName('recptMthEmailYn')[0].checked == true) {
              this.requestInfo.recptMthEmailYn = 'Y';
            } else {
              this.requestInfo.recptMthEmailYn = 'N';
            }
          } else if (e.target.name == 'recptMthPostYn') {
            if (document.getElementsByName('recptMthPostYn')[0].checked == true) {
              this.requestInfo.recptMthPostYn = 'Y';
            } else {
              this.requestInfo.recptMthPostYn = 'N';
            }
          }
        }
      }
    },
    // SMS 수신 여부 / 통신명의 제어
    setSmsReceiveTelInfo(key, e) {
      if (key == 'smsYn') {
        this.requestInfo.smsYn = e.target.value;
        if (e.target.value == 'N') {
          this.isSmsReceive = false;
        } else {
          this.isSmsReceive = true;
        }
      } else if (key == 'nmSeCd') {
        this.requestInfo.nmSeCd = e.target.value;
      }
    },
    // 검색
    async onSearch() {
      let response = '';
      let param = this.searchInfo;
      let yearCheck = 0;
      let monthCheck = 0;
      let dayCheck = 0;

      let startYyyy = this.searchInfo.checkContDateStart.substring(0, 4); // 시작 년도
      let endYyyy = this.searchInfo.checkContDateEnd.substring(0, 4); // 종료 년도

      let startMm = this.searchInfo.checkContDateStart.substring(4, 6); // 시작 달
      let endMm = this.searchInfo.checkContDateEnd.substring(4, 6); // 종료 달

      let startDd = this.searchInfo.checkContDateStart.substring(6, 8); // 시작 일
      let endDd = this.searchInfo.checkContDateEnd.substring(6, 8); // 종료 일

      // 계약기간 비워져 있을시에 alert
      if (!this.searchInfo.checkContDateStart || !this.searchInfo.checkContDateEnd) {
        this.$alert('검색하실 계약기간의 시작일과 종료일을 선택해주세요. (3개월 이내)');
        return;
      }
      // 계약기간 검사 (연,월,일 기준 검사)
      yearCheck = endYyyy - startYyyy;
      monthCheck = endMm - startMm;
      dayCheck = endDd - startDd;

      if (yearCheck == 1) {
        // 작년 10월, 11월, 12월 기준 으로 3개월 검사시
        if (!((startMm == 10 || startMm == 11 || startMm == 12) && monthCheck < -8)) {
          this.$alert('계약 기간을 3개월 이내로 검색해주세요.');
          return;
        }
      } else if (yearCheck == 0) {
        if (monthCheck > 3 && yearCheck == 0) {
          this.$alert('계약 기간을 3개월 이내로 검색해주세요.');
          return;
        }
      } else {
        this.$alert('계약 기간을 3개월 이내로 검색해주세요.');
        return;
      }

      if (yearCheck <= 0 && monthCheck <= 0 && dayCheck < 0) {
        this.$alert('계약 검색기간 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
        this.searchInfo.checkContDateStart = '';
        return;
      }

      this.listBoxLoading = true;
      try {
        response = await axios.post('/cmsReq/selectEliteReqServiceList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var i = 0;
        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs'); // 전체 row id 배열
        if (ids && ids.length > 0) {
          // CCT selectBox 설정
          // for (var i = 0 ; i <= ids.length-1; i++ ){
          //   rowData = $("#"+this.sheetId).jqGrid('getRowData', ids[i]);
          //   // 첫 검색일때만 CC select option 추가
          //   if (this.isFirstSearch){
          //     let canPush = true; // 저장가능 여부 T/F(중복체크)
          //     for (var j = 0 ; j < this.cctArray.length ; j++){
          //       if (rowData["cct"] == this.cctArray[j].value){
          //         canPush = false;
          //         break;
          //       }
          //     }
          //     if (canPush){
          //       this.cctArray.push({text: rowData["cct"] , value: rowData["cct"]});
          //     }
          //   }
          // }
          // if (this.isFirstSearch){
          //   setTimeout(() => {
          //     $("#searchCct_select").prop("selectedIndex",0);
          //   },100);
          // }
          this.isFirstSearch = false;

          // 포커싱 해주는 부분
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr) {
            for (i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              // 포커싱 정보와 일치하는 행이 있을때
              if (rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr) {
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);

                this.focusKeys.reqstdocNo = rowData.reqstdocNo;
                this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
                this.focusKeys.ctrtNo = rowData.ctrtNo;
                this.focusKeys.ctrtOdr = rowData.ctrtOdr;
                this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
                this.focusKeys.contractType = rowData.contractType;
                this.focusKeys.signType = rowData.signType;
                this.focusKeys.checkContOpts = rowData.checkContOpts;
                this.focusKeys.status = rowData.status;

                // 상세조회 START
                this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
                this.onReqDetailSearch();

                // 바로 전자서명 할 시에 oz viewer 띄워줌
                if (this.goSign) {
                  this.onReqView();
                }

                // 해당 행으로 focus 이동
                let rowHeight = 30; // jqgrid 행 기본 높이
                let index = jQuery('#' + this.sheetId).getInd(ids[i]);
                // 검색 행이 중간으로 갈 수 있도록 설정
                jQuery('#' + this.sheetId)
                  .closest('.ui-jqgrid-bdiv')
                  .scrollTop(rowHeight * index - 90);
                break;
              }
              // 포커싱 만 존재하고 일치하는 행이 없을때 -> 첫번째 행 선택
              if (
                i == ids.length - 1 &&
                !(rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr)
              ) {
                $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
                rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);

                // 첫번쨰 행 focuskey set
                this.focusKeys.reqstdocNo = rowData.reqstdocNo;
                this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
                this.focusKeys.ctrtNo = rowData.ctrtNo;
                this.focusKeys.ctrtOdr = rowData.ctrtOdr;
                this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
                this.focusKeys.contractType = rowData.contractType;
                this.focusKeys.signType = rowData.signType;
                this.focusKeys.checkContOpts = rowData.checkContOpts;
                this.focusKeys.status = rowData.status;

                this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
                this.onReqDetailSearch();
              }
            }
            // 포커싱 정보가 없을때 - 첫번째 행 선택
          } else {
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);

            // 첫번쨰 행 focuskey set
            this.focusKeys.reqstdocNo = rowData.reqstdocNo;
            this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
            this.focusKeys.ctrtNo = rowData.ctrtNo;
            this.focusKeys.ctrtOdr = rowData.ctrtOdr;
            this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
            this.focusKeys.contractType = rowData.contractType;
            this.focusKeys.signType = rowData.signType;
            this.focusKeys.checkContOpts = rowData.checkContOpts;
            this.focusKeys.status = rowData.status;

            this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
            this.onReqDetailSearch();
          }
        } else {
          // 검색된 데이터 존재 X
          this.onInitRequestInfo();
          this.onDefaultValSet();
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    // Elite Service 신청서 상세 검색
    async onReqDetailSearch() {
      let param = {
        reqstdocNo: this.focusKeys.reqstdocNo == '' ? this.focusKeys.ctrtNo : this.focusKeys.reqstdocNo,
        reqstdocOdr: parseInt(this.focusKeys.reqstdocOdr),
      };
      // Elite Service 상세 조회
      try {
        const response = await axios.post('/cmsReq/selectEliteReqInfo', param);

        if (response.data == '') {
          this.onDefaultValSet(); //검색 결과 없을시에 default Set
        } else {
          this.onSetRequestInfo(response.data); // 검색값 setting
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    // 주소검색 - 미구현
    onAddrSearch() {
      let win = window.open(
        process.env.VUE_APP_BASE_URL + '/address/jusoPopup',
        'jusoPop',
        'width=570,height=420, scrollbars=yes, resizable=yes'
      );
      var interval = window.setInterval(function() {
        try {
          if (win == null || win.closed) {
            window.clearInterval(interval);
            if (me.callback && Object.keys(me.$store.getters.externalData).length) {
              me.callback(me.$store.getters.externalData);
              me.$store.dispatch('setExternalData', {});
            }
          }
        } catch (e) {
          console.error(e);
        }
      }, 1000);
    },
    // 저장 및 전자서명
    async onSave() {
      // 조회 계약 없을때 저장 하려고 할 시 alert
      var ids = $('#' + this.sheetId).jqGrid('getDataIDs'); // 전체 row id 배열
      if (ids.length < 1) {
        this.$alert('신청서를 저장하기 위한 계약정보가 존재하지 않습니다.<br>목록을 다시 조회해주세요.');
        return;
      }

      // 변경시에 알림
      if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd != '10') {
        if (!(await this.$confirm('변경시 작성된 정보로 새로운 신청서가 작성됩니다.<br>변경신청 하시겠습니까?')))
          return;
      }
      /*  ===============================
       *  onSave() validation CHECK START
       *  =============================== */

      /* ===== 1. 점검일시 CHECK ===== */
      let chckDay = this.requestInfo.chckDD == null ? '' : this.requestInfo.chckDD;
      let chckHour = this.requestInfo.chckHH == null ? '' : this.requestInfo.chckHH;
      if (chckDay == '' || chckHour == '') {
        this.$alert('점검 일자와 시간을 입력해주세요.');
        return;
      }

      if (parseInt(chckDay) > 31 || parseInt(chckHour) < 1) {
        this.$alert('점검 일자를 다시 입력해주세요. (1일 ~ 31일 사이)');
        return;
      }
      if (parseInt(chckHour) > 24 || parseInt(chckHour) < 0) {
        this.$alert('점검 시간을 다시 입력해주세요. (0시 ~ 24시 사이)');
        return;
      }
      // 1자리 입력시 앞에 0 붙여줌 ( DD / HH )
      if (chckDay.length == 1) {
        chckDay = '0' + chckDay;
      }
      if (chckHour.length == 1) {
        chckHour = '0' + chckHour;
      }
      this.requestInfo.chckCycle = chckDay + chckHour;

      /* ===== 2. 리포트 수령자 CHECK ==== */
      if (this.requestInfo.recptmanNm == '' || this.requestInfo.recptmanNm == null) {
        this.$alert('리포트를 수령받으실 분의 이름을 입력해주세요.');
        return;
      }

      /* ===== 3. 수령방법 CHECK ==== */
      let emailYn = document.getElementsByName('recptMthEmailYn')[0].checked;
      let postYn = document.getElementsByName('recptMthPostYn')[0].checked;
      let RejectYn = document.getElementsByName('recptMthRejectYn')[0].checked;
      if (!emailYn && !postYn && !RejectYn) {
        this.$alert('리포트를 수령받을 방법을 선택해주세요.');
        return;
      } else {
        if (emailYn) {
          this.requestInfo.recptMthEmailYn = 'Y';
        } // 이메일
        if (postYn) {
          this.requestInfo.recptMthPostYn = 'Y';
        } // 우편
        //if (RejectYn){  this.requestInfo.recptMthRejectYn = 'Y'; }  // 수령 X
      }

      /* ===== 4. 이메일 CHECK ===== */
      let email1 = this.requestInfo.recptEmail1 == null ? '' : this.requestInfo.recptEmail1;
      let email2 = this.requestInfo.recptEmail2 == null ? '' : this.requestInfo.recptEmail2;
      let RecptEmailPattern = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if (email1 == '' && email2 != '') {
        this.$alert('수령받으실 이메일을 1번부터 입력해주세요.');
        return;
      }
      if (email1 == '') {
        this.$alert('수령받으실 이메일 주소를 입력해주세요.');
        return;
      }
      if (!RecptEmailPattern.test(email1)) {
        this.$alert(
          '① 번 이메일 입력 항목에 올바른 이메일 주소를 입력해주세요. <br><br>예시) xxx@xx.xx 또는 xxx@xx.xxx 등의 형식인 주소'
        );
        return;
      }

      if (email2 != '' && !RecptEmailPattern.test(email2)) {
        this.$alert(
          '② 번 이메일 입력 항목에 올바른 이메일 주소를 입력해주세요. <br><br>예시) xxx@xx.xx 또는 xxx@xx.xxx 등의 형식인 주소'
        );
        return;
      }

      /* ===== 5. 우편번호 CHECK ===== */
      if(this.requestInfo.reqstdocDivCd == 'ELITE'){ //ITSR64770 전자계약시스템> Otis ONE 신청서 추가
        let recptZip = this.requestInfo.recptZip;
        let RecptZipPattern = /^[0-9]*$/;
        if (recptZip == '' || recptZip == null) {
          //this.$alert("우편번호를 조회 및 작성해주세요."); // 이후 우편번호 모듈 작성시에 변경
          this.$alert('우편번호를 작성해주세요. (5자리 또는 6자리 숫자)');
          return;
        }
        if (!RecptZipPattern.test(recptZip)) {
          this.$alert('우편번호는 숫자만 입력가능합니다. (5자리 또는 6자리 숫자)');
          return;
        }
        if (this.requestInfo.recptAdres == '') {
          this.$alert('우편 수령 주소를 입력해주세요.');
          return;
        }
        if (this.requestInfo.recptAdresDetail == '') {
          this.$alert('우편 수령 상세 주소를 입력해주세요.');
          return;
        }
      }

      /* ===== 6. 모바일번호 CHECK ===== */
      let telNoPattern = /^(01+[(0-9)]*$)/; // 휴대폰번호 정규식 (01x 번호만 검색)
      //let chkTelNo = this.requestInfo.smsNo.replace(/ /gi,'').replace(/-/gi,'').replace(/\(/gi,'').replace(/\)/gi,'');
      let chkTelNo = this.requestInfo.smsNo;
      if (this.requestInfo.smsYn == 'Y') {
        if (!telNoPattern.test(chkTelNo)) {
          this.$alert('휴대폰 번호는 숫자만 입력 가능합니다. ( - 없이 입력해 주세요 )');
          return;
        }

        if (!(chkTelNo.length == 10 || chkTelNo.length == 11)) {
          this.$alert('SMS를 수신 가능한 휴대폰 번호를 입력해주세요. (10자 ~ 11자)');
          return;
        }
      }

      /* ===== 7. 통신명의 CHECK ===== */
      if (this.requestInfo.nmSeCd == '' || this.requestInfo.nmSeCd == null) {
        this.$alert('통신 명의를 선택해주세요.');
        return;
      }

      /* ===== 8. 신청인 CHECK ===== */
      if (this.requestInfo.applcntNm == '' || this.requestInfo.applcntNm == null) {
        this.requestInfo.applcntNm = this.requestInfo.repntName; // 신청인 존재 안할시에 거래선 대표자명
      }

      /*  ===============================
       *  onSave() validation CHECK END
       *  =============================== */

      /* DB insert / update 시작 */
      let response = '';
      let param = this.requestInfo;

      console.log('param !! :', param);

      if (this.isProcessing) {
        alert('신청서 정보를 저장 또는 변경처리 중입니다. 잠시만 기다려 주세요.');
        return;
      }

      try {
        this.listBoxLoading = true;
        this.isProcessing = true;

        // 신규(변경)신청
        // 1. 계약서 차수 X , 작성중(10) --> 신규 신청
        // 2. 계약서 차수 O , 작성중(10) --> 수정 신청
        // 3. 계약서 차수 O , 신청완료(20) --> 변경 신청

        // 신규 신청
        if (this.requestInfo.reqstdocOdr === '' && this.requestInfo.progrsSttusCd == '10') {
          response = await axios.post('/cmsReq/insertEliteReq', param); // insert (변경 / 신규 신청)
          // 변경 신청
        } else if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd != '10') {
          response = await axios.post('/cmsReq/insertEliteReq', param); // insert (변경 / 신규 신청)
          // 수정 신청
        } else {
          if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd == '10') {
            response = await axios.post('/cmsReq/updateEliteReq', param); // update (작성중)
          } else {
            console.log('on save() not in case');
          }
        }

        if (response.data == 'success') {
          this.listBoxLoading = false;
          this.isProcessing = false;
          if (
            !(await this.$confirm(
              'Elite 작성 정보가 성공적으로 저장되었습니다. <br>전자서명을 완료해야 Elite 신청이 완료됩니다.<br><br>전자서명을 바로 진행하시겠습니까?<br>( 취소하여도 작성 정보는 저장되어 있습니다. )'
            ))
          ) {
            // 취소 클릭시
            this.onSearch();
          } else {
            // 확인 클릭시 상세조회 완료하면서 oz viewer 시작
            this.goSign = true;
            this.onSearch();
          }
        }
        console.log(response);
      } catch (ex) {
        this.listBoxLoading = false;
        this.isProcessing = false;
        this.$alert('저장에 실패하였습니다.');
        console.log(ex);
      }
    },
    // Elite 신청 정보 조회
    async onReqView() {
      this.goSign = false;
      // eslint-disable-next-line
      var opt = {};
      const btns = [];

      // 신청서 조회자 서명 정보 조회
      let signInfoParam = {
        ctrtNo: this.focusKeys.ctrtNo,
        ctrtOdr: this.focusKeys.ctrtOdr,
        ctrtDivCd: this.focusKeys.ctrtDivCd,
      };
      let response2 = await axios.post('/ocontract/selectSignerInfo', signInfoParam);

      // 최초 작성 후 바로 전자서명 하려고 할 시에 번호/차수 넣어줌
      this.focusKeys.reqstdocNo = this.focusKeys.reqstdocNo === '' ? this.focusKeys.ctrtNo : this.focusKeys.reqstdocNo;
      this.focusKeys.reqstdocOdr = this.focusKeys.reqstdocOdr === '' ? 1 : this.focusKeys.reqstdocOdr;

      let param = {
        manageCd: (this.focusKeys.checkContOpts == 'Elite') ? 'R02':'R05', // 필수(*):공통 (R01: View , R02: Elite , R03: CMS , R05:OtisONE)
        reqstdocNo: this.focusKeys.reqstdocNo, // 필수(*) : cms, view, elite 신청서일 경우
        reqstdocOdr: this.focusKeys.reqstdocOdr, // 필수(*) : cms, view, elite 신청서일 경우
      };

      // 작성중 상태 + 첫번째 고객일때만 전자서명 버튼 나올 수 있도록 설정
      if (
        this.requestInfo.progrsSttusCd == '10' &&
        eval(response2.data.customer) == true &&
        response2.data.tradeOrder == 'A'
      ) {
        btns.push({
          name: '전자서명',
          callback: () => {
            popupInterface[opt.name].vue.callEvent({
              name: 'RequestViewerPopup_Sign',
              param: data => {
                console.log('RequestViewerPopup_Sign Data :', data);
                opt.window.close();
                this.onSignReq(data);
              },
            });
          },
        });
      }

      axios
        .post('/ozrpt/selectOzEtcFormService', param)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);

          var signable = 'N'; // 필수(*):디지털 서명가능여부(Y/N)
          var signerSeCd = '10'; // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
          var tradeNo = this.requestInfo.tradeNo; // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)

          this.OZParam = Object.assign({}, response.data.jsonData);
          this.OZParam.reportname = response.data.reportPathName;
          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
            signMthCd: '', // 서명방법 (21.03.17 추가)
          };

          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/applicationForm/components/popup/RequestViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ctrtNo: this.focusKeys.ctrtNo,
              ctrtOdr: '00', // 계약은 0차수 (당초 만 조회)
              ctrtDivCd: this.focusKeys.ctrtDivCd,
              reqstdocNo: this.focusKeys.reqstdocNo,
              reqstdocOdr: this.focusKeys.reqstdocOdr,
              progrsSttusCd: this.requestInfo.progrsSttusCd, // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
              checkContDate: this.requestInfo.checkContDate, // 계약일
              reqstdocDivCd: this.requestInfo.reqstdocDivCd, // 신청서 구분 코드
              otisPage: this.isOtisPage, // 고객/ OTIS 구분
              OZParam: this.OZParam,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'RequestViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '신청서조회', // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    // 신청서 전자서명 후 검색
    async onSignReq(data) {
      console.log(' Elite onSignReq data :', data);
      //this.requestInfo.notiType = 'REQALL';

      let param = this.requestInfo;
      const response = await axios.post('/cmsReq/notiEliteReq', param);
      if (response.data == 'success') {
        console.log('noti Complete');
      } else {
        console.log('noti Fail');
      }

      this.$alert('전자서명 및 신청이 완료되었습니다.');
      this.onSearch();

      // 추후 해당 신청서 전자서명정보 삭제 필요시 사용
      // this.requestInfo.agreYn = data.ozVal.agreYn;
      // this.requestInfo.reqstdocNo  = this.focusKeys.reqstdocNo;
      // this.requestInfo.reqstdocOdr = this.focusKeys.reqstdocOdr;
      // let param = this.requestInfo;
      // const response = await axios.post('/cmsReq/deleteViewReqSignInfoByFail', param);
      // if (response.data == "success"){
      //   console.log("del Complete");
      // } else {
      //   console.log("del Fail");
      // }
    },
    // 계약서 조회
    async onCtrtView(data) {
      console.log('dataRow : ', data);
      let param2 = {
        ctrtNo: data.ctrtNo,
        ctrtOdr: data.ctrtOdr,
        ctrtDivCd: data.ctrtDivCd,
      };
      let response2 = await axios.post('/ocontract/selectSignerInfo', param2);

      console.log(' onCtrtView response2 :', response2);

      var signable = 'N';
      var signerSeCd =
        eval(response2.data.buyagnt) == true ? '20' : eval(response2.data.customer) == true ? '10' : '30';
      var tradeNo =
        eval(response2.data.buyagnt) == false && eval(response2.data.canSign) == true ? response2.data.tradeNo : '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약서 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      //var opt = {};
      const btns = [];
      if (data.docCat1 == '000') {
        // eslint-disable-next-line
        opt = $open({
          componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
          param: {
            ctrtNo: this.focusKeys.ctrtNo,
            ctrtOdr: this.focusKeys.ctrtOdr,
            ctrtDivCd: this.focusKeys.ctrtDivCd,
            docCat1: data.docCat1,
            OZParam: {},
            otisPage: this.isOtisPage,
          }, // 파라미터 (생략가능)
          btns: btns, // 팝업 버튼 (생략가능)
          name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
          option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
          title: '계약서조회', // 팝업 제목 (생략가능)
        });
      } else {
        axios
          .post('/ozrpt/selectOzOContract', param)
          .then(response => {
            //console.log('조회결과 response >>>', response);
            //console.log('조회결과 response.data >>>', response.data);

            this.OZParam = Object.assign({}, response.data.jsonData);

            this.OZParam.reportname = response.data.reportPathName;
            this.OZParam.OZFormParam = {
              signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
              signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
              tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
              signMthCd: '', // 서명방법 (21.03.17 추가)
            };

            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ctrtNo: this.focusKeys.ctrtNo,
                ctrtOdr: this.focusKeys.ctrtOdr,
                ctrtDivCd: this.focusKeys.ctrtDivCd,
                docCat1: data.docCat1,
                OZParam: this.OZParam,
                otisPage: this.isOtisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          })
          .catch(function(error) {
            console.log('========error====================');
            console.log(error);
          });
      }
    },
  },
};
</script>

<style></style>

<template>
  <pmis-tab-box>
    <template v-slot:title>접속이력</template>
    <pmis-content-box>
      <iui-container type="jqgrid" :id="elId" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchSe: 1,
      searchNm: '',
      searchSeList: [
        {value: 1, text: '성명'},
        {value: 2, text: '아이디'},
      ],
    };
  },

  beforeCreate() {
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([
      {
        name: 'log_selectLoginLogList',
        func: this.selectLoginLogList,
      },
    ]);
  },

  mounted() {
    this.initSheet();
    this.selectLoginLogList();
  },

  methods: {
    // eslint-disable-next-line
    formatterUTCDate(cellvalue, options, rowObject) {
      var dt = new Date(cellvalue);
      if (isNaN(dt)) return '';
      var ymdhms =
        (dt.getFullYear() * 10000 + (dt.getMonth() + 1) * 100 + dt.getDate()).toString() +
        (1000000 + dt.getHours() * 10000 + dt.getMinutes() * 100 + dt.getSeconds()).toString().substring(1);

      return (
        ymdhms.substring(0, 4) +
        '-' +
        ymdhms.substring(4, 6) +
        '-' +
        ymdhms.substring(6, 8) +
        ' ' +
        ymdhms.substring(8, 10) +
        ':' +
        ymdhms.substring(10, 12) +
        ':' +
        ymdhms.substring(12, 14)
      );
    },
    initSheet() {
      var me = this;
      const OPT = {
        Cols: [
          {label: 'No', name: 'rowNumber', align: 'center', width: 30},
          {
            label: '접속시간',
            name: 'lgnDttm',
            align: 'center',
            width: 150,
            formatter: me.formatterUTCDate,
          },
          {
            label: '종료시간',
            name: 'lgtDttm',
            align: 'center',
            width: 150,
            formatter: me.formatterUTCDate,
          },
          {
            label: '접속IP',
            name: 'lgnIp',
            align: 'center',
            width: 110,
          },
        ],
      };

      var $grid = $('#' + me.elId);
      $grid.jqGrid({
        height: 200,
        width: 800,
        autowidth: true,
        shrinkToFit: true,
        selectionMode: 'singlerow',
        colModel: OPT.Cols,
      });
      $gridAutoResize($grid);
    },
    selectLoginLogList(param) {
      const me = this;
      var $grid = $('#' + me.elId);

      if (!param) return;

      $grid.jqGrid('clearGridData');
      axios.post('/loginLog/selectLoginLogList', param).then(response => {
        if (response.status == 200) {
          var data = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i]['rowNumber'] = i + 1;
          }
          $grid.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
        }
      });
    },
  },
};
</script>

<style scoped></style>

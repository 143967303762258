/* NE > 금액확정통보 */
<script>
import {defineComponent, reactive, ref} from '@vue/composition-api';
import ConfirmationList from './components/ConfirmationList.vue';
import ConfirmationView from './components/ConfirmationView.vue';

export default defineComponent({
  components: {
    ConfirmationList,
    ConfirmationView,
  },

  setup() {
    const pageButton = reactive({
      search: false,
      excel: false,
    });

    const oList = ref(null);

    const state = reactive({
      loading: false,
      confirmationKey: undefined,
    });

    const method = {
      search_click() {
        oList.value.getList();
      },
      excel_click() {
        oList.value.downloadExcel();
      },
    };

    return {
      pageButton,
      oList,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page :loading="state.loading" @search-click="search_click" @excel-click="excel_click">
    <iui-container type="css-flex">
      <i-row style="min-height: 330px;">
        <i-col>
          <confirmation-list
            ref="oList"
            otis-page
            :page-loading.sync="state.loading"
            :selected-confirmation-key.sync="state.confirmationKey"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="330px" style="min-height: 330px;">
        <i-col>
          <confirmation-view otis-page :confirmation-key="state.confirmationKey" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

<script>
import {defineComponent, reactive, nextTick} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {ItemsProps, useItems} from '@/components/Iui/composables/useItems';

export default defineComponent({
  name: 'IuiCheckboxGroup',
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...ItemsProps,
    modelValue: {
      type: Array,
      default: () => [],
      required: true,
    },
    groupName: {
      type: String,
      default: undefined,
    },
  },
  emits: ['change', 'update:checkd', 'update:model-value'],
  setup(props, context) {
    const Base = useBase(props, context, 'iui-checkbox-group');
    const Layout = useLayout(props, context, 'iui-checkbox-group');
    const Items = useItems(props, context);

    Layout.state.class = {
      ...Layout.state.class,
      ...Items.itemDirectionClass,
    };

    // state
    const state = reactive({
      groupName: props.groupName === undefined ? Base.state.id : props.groupName,
    });

    // method
    const method = {
      async checked_update(key, e) {
        await nextTick();

        context.emit('update:checked', {key: key, event: e});
      },

      async modelValue_update(key, e) {
        await nextTick();

        context.emit('update:model-value', e);
      },

      async checkbox_change(key, e) {
        await nextTick();

        context.emit('change', {key: key, event: e});
      },
    };

    return {
      Base,
      Layout,
      // ...Items,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div
    v-show="Base.state.visible"
    :class="Layout.state.class"
    :style="Layout.state.style"
    :data-group-name="state.groupName"
  >
    <iui-checkbox
      v-for="item in items"
      :key="item[itemKeyAlias]"
      :model-value="modelValue"
      :group-name="state.groupName"
      :label="item[itemTextAlias]"
      :value="item[itemValueAlias]"
      :enable="item[itemEnableAlias] === undefined ? true : item[itemEnableAlias]"
      @update:checked="
        e => {
          checked_update(item[itemKeyAlias], e);
        }
      "
      @update:model-value="
        e => {
          modelValue_update(item[itemKeyAlias], e);
        }
      "
      @change="
        e => {
          checkbox_change(item[itemKeyAlias], e);
        }
      "
    />
  </div>
</template>

<style></style>

<template>
  <div class="popup_modal">
    <div class="fg" :style="{width: '100%', height: '100%'}">
      <div class="popup_header">
        <div>{{ title }}</div>
        <button class="btn_pop_close" @click="onClose">
          <span class="blind">닫기</span>
        </button>
      </div>
      <div class="popup_contents">
        <component v-bind:is="currentView" :paramObj="param"></component>
      </div>
      <div class="popup_footer">
        <div class="footer-left"></div>
        <div class="footer-center"></div>
        <div class="footer-right">
          <iui-button
            v-for="btn in btns"
            v-bind:key="btn.name"
            :color="btn.name == '닫기' ? 'gray' : undefined"
            normal
            @click="btn.callback"
            >{{ btn.name }}</iui-button
          >
          <iui-button normal color="gray" @click="onClose">닫기</iui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentView: null,
      btns: [],
      title: '',
      param: {},
    };
  },
  beforeCreate() {
    /* Back, F5 키 불가 처리 */
    window.onkeydown = function() {
      var kcode = event.keyCode;
      // console.log('kcode', kcode);
      if( kcode == 116) {
        event.preventDefault();
        event.returnValue = false;
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const opt = opener.popupInterface[window.name];
      console.log('opt', opt);
      opt['vue'] = this;
      const components = require.context(
        // 컴포넌트들이 있는 폴더
        '@',
        // 하위 폴더까지 포함할 지 여부
        true,
        // 기본 컴포넌트를 찾는데 사용할 정규표현식
        /[A-Z-]\w+\.(vue|js)$/
      );
      components.keys().some(fileName => {
        if (fileName == opt.componentPath) {
          const componentConfig = components(fileName);
          this.currentView = componentConfig.default || componentConfig;
          return true;
        }
      });

      this.title = opt.title;
      window.document.title = this.title;
      this.btns = opt.btns;
      this.param = opt.param; 

      // console.log("######################");
      // console.log('Popup.vue', opt.param);
      // console.log("######################");
    },
    onClose() {
      window.close();
    },
  },
};
</script>

<style></style>

const IuiLayoutMixin = {
  props: {
    theme: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    align: {
      type: String,
      default: undefined,
    },
    verticalAlign: {
      type: String,
      default: undefined,
    },

    layoutDebug: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalWidth: undefined,
      internalHeight: undefined,
      internalAlign: undefined,
      internalVerticalAlign: undefined,
    };
  },
  // watch: {
  //   width(newValue) {
  //     this.internalWidth = newValue;
  //   },
  // },
  created() {
    this.internalWidth = this.width;
    this.internalHeight = this.height;
    this.internalAlign = this.align;
    this.internalVerticalAlign = this.verticalAlign;
  },
};

export default IuiLayoutMixin;

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <i-row>
        <i-col-header>계약번호</i-col-header>
        <i-col>
          <iui-text :value="guarantee.ctrtNo" :enable="false" />
        </i-col>
        <i-col-header>구분</i-col-header>
        <i-col>
          <iui-select-old
            name="grntyKndCd"
            :items="arrGrntyKndCd"
            :value="guarantee.grntyKndCd"
            :enable="!this.readonly"
            @change="onSetValue"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>보증기관</i-col-header>
        <i-col colspan="3">
          <iui-text
            name="issuInsttNm"
            :value="guarantee.issuInsttNm"
            :enable="!this.readonly"
            @change="onSetValue"
            required
            width="200px"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>증권번호</i-col-header>
        <i-col colspan="3">
          <iui-text
            name="grntyNo"
            :value="guarantee.grntyNo"
            :enable="!this.readonly"
            @change="onSetValue"
            required
            width="200px"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>보증일자</i-col-header>
        <i-col>
          <iui-datepicker-old
            name="issuDe"
            :value="guarantee.issuDe"
            :enable="!this.readonly"
            @change="onSetValue($event, 'issuDe')"
            required
          />
        </i-col>
        <i-col-header>보험가입금액</i-col-header>
        <i-col>
          <iui-text
            type="amount"
            name="grntyAm"
            :value="guarantee.grntyAm"
            prefix="\"
            :enable="!this.readonly"
            @change="onSetValue"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>보험기간</i-col-header>
        <i-col colspan="3">
          <iui-datepicker-old
            name="grntyBgnDe"
            suffix="~"
            :value="guarantee.grntyBgnDe"
            :enable="!this.readonly"
            @change="onSetValue($event, 'grntyBgnDe')"
            required
          />
          <iui-datepicker-old
            name="grntyEndDe"
            :value="guarantee.grntyEndDe"
            :enable="!this.readonly"
            @change="onSetValue($event, 'grntyEndDe')"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>보증서첨부</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-show="!this.readonly && !fileUpDown_files.lclFlNm"
            >추가</iui-button
          >
          <iui-button
            @click="fileUpDown_delete = true"
            v-show="!this.readonly && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >삭제</iui-button
          >
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.svrFlPth"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            v-model="fileUpDown_files"
            :file-number.sync="guarantee.fileNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
export default {
  components: {
    FileUpDown,
  },
  // props: {
  //   grntySn: {
  //     type: [Number, String],
  //     default: undefined,
  //   },
  //   ctrtNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtOdr: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtDivCd: {
  //     type: String,
  //     default: undefined,
  //   },
  //   empNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   saveMode: {
  //     type: String,
  //     default: undefined,
  //   },
  //   readonly: {
  //     // 업체 페이지
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  beforeCreate() {
    $mapGetters(this, ['modalGuarantee']);
  },
  created() {
    this.addEvent({name: 'Guarantee_Save', func: this.onSave});
    this.addEvent({name: 'Guarantee_Delete', func: this.onDelete});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      callback: '',

      arrGrntyKndCd: [
        {text: '계약이행', value: '002'},
        {text: '선급금이행', value: '001'},
        {text: '하자이행', value: '003'},
      ],

      grntySn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      empNo: '',
      saveMode: 'I',
      readonly: false,

      guarantee: {
        grntySn: this.grntySn,
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        grntyKndCd: '002',
        issuInsttNm: '',
        grntyNo: '',
        grntyAm: '',
        issuDe: $_getCurrentDate(),
        grntyBgnDe: '',
        grntyEndDe: '',
        fileNo: 0,
        scdYn: 'Y',
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },
  mounted() {
    // console.log("###############################");
    // console.log('this.grntySn', this.grntySn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("###############################");

    // console.log('modalGuarantee', this.modalGuarantee);

    this.grntySn = this.modalGuarantee.grntySn;
    this.ctrtNo = this.modalGuarantee.ctrtNo;
    this.ctrtOdr = this.modalGuarantee.ctrtOdr;
    this.ctrtDivCd = this.modalGuarantee.ctrtDivCd;
    this.saveMode = this.modalGuarantee.saveMode;
    this.readonly = this.modalGuarantee.readonly;

    this.guarantee.grntySn = this.grntySn;
    this.guarantee.ctrtNo = this.ctrtNo;
    this.guarantee.ctrtOdr = this.ctrtOdr;
    this.guarantee.ctrtDivCd = this.ctrtDivCd;

    // console.log("==============================");
    // console.log('this.stmptaxSn', this.stmptaxSn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("==============================");

    if (this.guarantee.grntySn > 0) {
      this.onSearch();
    }
  },
  methods: {
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.guarantee[key] = value;
    },
    async onSearch() {
      let param = {
        grntySn: this.guarantee.grntySn,
      };
      try {
        this.listBoxLoading = true;
        let response = await axios.post('/guarantee/selectByGrntySn', param);
        this.listBoxLoading = false;
        this.guarantee = response.data;
        if (this.guarantee.scdYn == 'N') {
          this.readonly = true;
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSave(callback) {
      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      let validation = await this.$validator.validate(this);
      if (!validation.isValid) {
        this.$alert('입력되지 않은 필수항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.');
        return;
      }

      if (this.guarantee.grntyKndCd.length == 0) {
        this.$alert('구분을 선택해야 합니다.');
        return;
      }
      if (this.guarantee.issuInsttNm.length == 0) {
        this.$alert('보증기관을 입력해야 합니다.');
        return;
      }
      if (this.guarantee.grntyNo.length == 0) {
        this.$alert('증권번호를 입력해야 합니다.');
        return;
      }
      if (this.guarantee.issuDe <= 0) {
        this.$alert('보증일자를 입력해야 합니다.');
        return;
      }
      if (this.guarantee.grntyAm.length == 0 || this.guarantee.grntyAm <= 0) {
        this.$alert('보험가입금액을 입력해야 합니다.');
        return;
      }
      var regType1 = /^[0-9+]*$/;
      if (!regType1.test(this.guarantee.grntyAm)) {
        this.$alert('보험가입금액은 숫자만 입력 가능합니다.');
        // document.getElementsByName('grntyAm')[0].focus();
        return;
      }
      if (this.guarantee.grntyBgnDe <= 0) {
        this.$alert('보험기간 시작일자를 입력해야 합니다.');
        return;
      }
      if (this.guarantee.grntyEndDe <= 0) {
        this.$alert('보험기간 종료일자를 입력해야 합니다.');
        return;
      }
      if (this.guarantee.grntyBgnDe > this.guarantee.grntyEndDe) {
        this.$alert('보험기간 시작일자가 종료일자보다 클 수 없습니다.');
        return;
      }
      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.$alert('보증서 파일을 첨부해야 합니다.');
        return;
      }

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.guarantee,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          var uri = this.saveMode == 'I' ? '/guarantee/insertGuarantee' : '/guarantee/updateByGrntySnSelective';

          let response = axios.post(uri, param);
          console.log(response);
          this.listBoxLoading = false;
          callback(response);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onDelete(callback) {
      if (this.saveMode == 'I') {
        this.$alert('저장 후 삭제가 가능합니다.');
        return;
      }

      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      if (!(await this.$confirm('삭제하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;

        let param = {
          ...this.guarantee,
          pgmCd: this.userInfo.pgmCd,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        let response = axios.post('/guarantee/deleteByGrntySn', param);
        console.log(response);
        this.listBoxLoading = false;
        callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.guarantee.fileNo = 0;
      } else {
        this.guarantee.fileNo = this.fileUpDown_files.flNo;
      }

      let param = {
        ...this.guarantee,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      var uri = this.saveMode == 'I' ? '/guarantee/insertGuarantee' : '/guarantee/updateByGrntySnSelective';
      try {
        let response = axios.post(uri, param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_error() {
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      console.log('파일 삭제가 완료되었습니다.');
    },
    file_delete_error() {
      console.log('파일 삭제를 실패했습니다.');
    },
  },
};
</script>

<style></style>

/* 전자계약 서비스 진행이력 */
<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">         
          <iui-container type="jqgrid" :id="sheetId1" style="height:150px; width:99.9%;" />
        </pmis-content-box>
      </i-col>
    </i-row>   
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractHistoryList']);
  },
  created() {
    this.addEvent({name: 'OContractTabHistoryList_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'entryDate', label: '이력 일시', width: 150, align: 'center'},
        {name: 'operatorNm', label: '작업자', width: 200, align: 'left'},
        {name: 'histCn', label: '이력 내용',  width: 800, align: 'left'},
      ],
    };

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 100,
      width: 800,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      loadComplete: function() {

      },
    });
    

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      var bottomLeftObj = $grid
        .parents('.pmis-page')
        .find('.spacer')
        .eq(0)
        .next();
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 77, false);
      
    },
    onLoad() {
      console.log('OContractTabHistoryList_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      console.log("this.contractHistoryList:::::",this.contractHistoryList);
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.contractHistoryList})
        .trigger('reloadGrid');
      this.onResize();
    },
  },
  computed: {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row ref="srchList">
        <i-col>
          <iui-container type="css-flex" theme="bullet">
            <i-row height="40px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <!--<i-col-header>계약완료일</i-col-header>
                    <i-col
                      ><span style="white-space:nowrap">
                        <iui-datepicker-old
                          name="signDateStart"
                          suffix=" ~ "
                          @change="onSetValue($event, 'signDateStart')"
                          :value="search.signDateStart"/>
                        &nbsp;<iui-datepicker-old
                          name="signDateEnd"
                          @change="onSetValue($event, 'signDateEnd')"
                          :value="search.signDateEnd"
                      /></span>
                    </i-col>-->
                    <i-col-header>계약월</i-col-header>
                    <i-col >
                      <!-- <iui-text name="searchCtrtNo" :value.sync="search.period" /> -->
                      <span style="display:flex; margin-right:5px;">
                        <iui-datepicker-old
                          style="margin-right:5px;"
                          name="period"
                          type="month"
                          :value="search.period"
                          :enable="true"
                          @change="onSetValue($event, 'period')"
                        />
                        <!-- <iui-button @click="downLoadYearExcel">선택년도 엑셀다운</iui-button> -->
                      </span>
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    목록
                  </template>
                  <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99%; height: 99%" />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>
<script>
export default {
  name: 'stamptax-rpa-list',
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      //sheetId: 'sheet',// + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,

      search: {
        signDateStart: '',
        signDateEnd: '',
        period:'',
      },

      listBoxLoading: false,
    };
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    // 지난달 26일부터 이번달 25까지 인지세 대상을 조회해서 RPA에 넣기 때문에 기본 검색조건을 똑같이 넣음.
    var tempDate = $_getCurrentDate().substring(0, 6) + '26';
    this.search.signDateStart = $_addMonth(tempDate, -1);
    this.search.signDateEnd = $_addDay(tempDate, -1);
    this.search.period =  $_getCurrentDate().substring(0, 6);
    const OPT = {
      Cols: [
        {
          name: 'signDt',
          // label: '체결완료일',
          label: '계약확정일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {name: 'contType', label: '계약구분', width: 60, align: 'center'},
        {name: 'signType', label: '전자/서면', width: 50, align: 'center'},
        {name: 'combinedCtrtNo', label: '계약번호-차수', width: 100, align: 'center'},
        {name: 'uniqueId', label: '인지세납부번호', width: 150, align: 'left'},
        {name: 'contractName', label: '현장명', width: 170, align: 'left'},
        {name: 'ccNm', label: 'CCT', width: 100, align: 'left'},
        {name: 'branchNm', label: '지사', width: 100, align: 'left'}, 
        {
          name: 'prevIssueAmount',
          label: '이전 계약금액(VAT포함)',
          width: 110,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },{
          name: 'contractAmount',
          label: '계약금액(VAT포함)',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'issueAmount',
          label: '인지세액(1/N)',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'}
        },
        {name: 'statusCd', label: '발급상태코드', width: 0, hidden: true},
        {name: 'statusNm', label: '발급실패', width: 100, align: 'center'},
        {name: 'period', label: 'PERIOD', width: 60, align: 'center'},
      ],
    };

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      //width:1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      // sortable: true,
      sortname: 'signDt',
      sortorder: 'desc',
      rownumbers: true,
      autowidth: true,
      shrinkToFit: true,
      footerrow: true,
      userDataOnFooter: true,
      loadComplete: function() {        
        var issueAmountSum = $('#' + me.sheetId).jqGrid('getCol', 'issueAmount', false, 'sum');
        $('#' + me.sheetId).jqGrid('footerData', 'set', {branchNm: '인지세액(1/N) 합계', issueAmount: issueAmountSum});
        //$('table.ui-jqgrid-ftable tr:first').children("td").css("background-color", "#f6f6f6");
        //$('table.ui-jqgrid-ftable td:eq(7)').css("border-right-color", "transparent");
        //$("table.ui-jqgrid-ftable tr:first td:eq(7)").css("text-align", "right");
        $("table.ui-jqgrid-ftable tr:first td:eq(10)").text($("table.ui-jqgrid-ftable tr:first td:eq(8)").text())
        $("table.ui-jqgrid-ftable tr:first td:eq(8)").text('');
        /*
        var $footRow = $grid.closest(".ui-jqgrid-bdiv").next(".ui-jqgrid-sdiv").find(".footrow");

            var $name = $footRow.find('>td[aria-describedby="'+me.sheetId+'_signDt"]'),
                $invdate = $footRow.find('>td[aria-describedby="'+me.sheetId+'_signType"]'),
                width2 = $name.width() + $invdate.outerWidth();
                console.log("width2",width2);
                $invdate.css("display", "none");
                $name.attr("colspan", "2").width(width2+200);
       */
      },
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    // 20210407 검색란 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });

    //this.onSearch();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, true);
      var newHeight = pobj.height();
      $grid.jqGrid('setGridHeight', newHeight - 80, true);
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    async onSearch() {
      /*
      let yearCheck = 0;
      let monthCheck = 0;
      let dayCheck = 0;

      let startYyyy = this.search.signDateStart.substring(0, 4); // 시작 년도
      let endYyyy = this.search.signDateEnd.substring(0, 4); // 종료 년도

      let startMm = this.search.signDateStart.substring(4, 6); // 시작 달
      let endMm = this.search.signDateEnd.substring(4, 6); // 종료 달

      let startDd = this.search.signDateStart.substring(6, 8); // 시작 일
      let endDd = this.search.signDateEnd.substring(6, 8); // 종료 일

      // 계약기간 비워져 있을시에 alert
      
      if (!this.search.signDateStart || !this.search.signDateEnd) {
        this.$alert('검색하실 계약완료일의 시작일과 종료일을 선택해주세요. (1개월 이내)');
        return;
      }
      // 계약기간 검사 (연,월,일 기준 검사)
      yearCheck = parseInt(endYyyy) - parseInt(startYyyy);
      monthCheck = parseInt(endMm) - parseInt(startMm);
      dayCheck = parseInt(endDd) - parseInt(startDd);

      // 1개월용
      if (yearCheck == 1) {
        // 작년 10월, 11월, 12월 기준 으로 1개월 검사시
        if (!(startMm == 12 && monthCheck < -10)) {
          this.$alert('계약완료일을 1개월 이내로 검색해주세요.');
          return;
        }
      } else if (yearCheck == 0) {
        if (monthCheck > 1 && yearCheck == 0) {
          this.$alert('계약완료일을 1개월 이내로 검색해주세요.');
          return;
        }
      } else {
        this.$alert('계약완료일을 1개월 이내로 검색해주세요.');
        return;
      }

      if (yearCheck <= 0 && monthCheck <= 0 && dayCheck < 0) {
        this.$alert('계약완료일의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
        this.search.signDateStart = '';
        return;
      }
      */

      this.listBoxLoading = true;

      let param = {
        signDateStart: this.search.signDateStart,
        signDateEnd: this.search.signDateEnd,
        period: this.search.period,
        ctrtDivCd: 'SVC',
      };

      let response = null;
      try {
        response = await axios.post('/stamptax/selectStamptaxTargetList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');
        this.onResize();
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
  },
};
</script>

<style></style>

<template>
  <!-- https://www.tiny.cloud/get-tiny/self-hosted/ -->
  <!--38a71hc3k89e0a8czwz8jgzsiweseg7zqresd796a6zekkgd-->
  <!-- 
  <editor
    api-key="your-api-key"
    cloud-channel="5"
    :disabled=false
    id="uuid"
    :init= "{  }"
    initial-value=""
    :inline=true
    model-events= ""
    plugins=""
    tag-name="div"
    toolbar=""
    value=""
  />
    initialValue="<p>초기화 값</p>"
    tinymce-script-src="/ext/tinymce/tinymce.min.js"
 -->
  <editor
    tinymce-script-src="/ext/tinymce/tinymce.min.js"
    v-model="internalValue"
    :init="{
      language: 'ko_KR',
      height: internalHeight,
    }"
    :plugins="internalPlugins"
    :menubar="internalMenubar"
    :toolbar="internalToolbar"
    :disabled="!enable"
    v-on="listeners"
    @onChange="editor_change"
    @onKeyup="editor_keyup"
    @onUndo="editor_undo"
    @onRedo="editor_redo"
  >
  </editor>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

// https://www.tiny.cloud/docs/advanced/editor-control-identifiers/
// https://www.tiny.cloud/docs/configure/editor-appearance/#contextmenu
// https://www.tiny.cloud/docs/general-configuration-guide/upload-images/
// change keyup undo redo

export default {
  components: {
    editor: Editor,
  },
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
    enable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      internalHeight: undefined,
      internalPlugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      // internalMenubar: 'file edit view insert format tools table help',
      internalMenubar: false,
      internalToolbar: [
        'undo redo | formatselect | fontselect fontsizeselect | bold italic underline forecolor backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
      ],
      internalValue: undefined,
    };
  },
  computed: {
    listeners() {
      return this.$listeners;
    },
  },
  watch: {
    // eslint-disable-next-line
    value(newValue, oldValue) {
      this.internalValue = newValue;
    },
  },
  created() {
    this.internalValue = this.value;
    this.internalHeight = this.height;
  },
  methods: {
    // eslint-disable-next-line
    editor_change(e, source) {
      this.$emit('update:value', this.internalValue);
    },
    // eslint-disable-next-line
    editor_keyup(e, source) {
      this.$emit('update:value', this.internalValue);
    },
    // eslint-disable-next-line
    editor_undo(e, source) {
      this.$emit('update:value', this.internalValue);
    },
    // eslint-disable-next-line
    editor_redo(e, source) {
      this.$emit('update:value', this.internalValue);
    },
  },
};
</script>

<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>내역</template>
          <template #title-right>(단위: 원) VAT별도</template>
          <iui-container type="jqgrid" :id="sheetId1" style="height:150px; width:99.9%;" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row v-show="isShowSafetyPackageList">
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>계약대상 승강기 Safety Package item 설치료</template>
          <template #title-right>(단위: 원) VAT별도</template>
          <iui-container type="jqgrid" :id="sheetId2" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row v-show="isDocCat1_040 || isDocCat1_080">
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>계약대상 승강기 월 장기수선충당금 관련 비용. 장기수선계획의 수립기준에 따름</template>
          <template #title-right>(단위: 원) VAT별도</template>
          <iui-container type="jqgrid" :id="sheetId3" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo', 'contractItemList', 'safetyPackageList', 'maintPlanList']);
  },
  created() {
    this.addEvent({name: 'OContractTabItems_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      sheetId3: 'sheet3' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'ctrtNo', label: '계약번호', width: 0, align: 'center', hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, align: 'center', hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, align: 'center', hidden: true},
        {name: 'modelCode', label: '기종', width: 30, align: 'left'},
        {name: 'modelType', label: '규격', width: 100, align: 'left'},
        {
          name: 'itemCnt',
          label: '대수',
          width: 30,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'costAmt',
          label: '단가',
          width: 30,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'costPerMonthAmt',
          label: '월유지관리표',
          width: 30,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'elSubFeeAmt',
          label: 'Elite Service료<br/>직접통화서비스료',
          width: 30,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
      ],
    };

    if (this.isDocCat1_020 || this.isDocCat1_040 || this.isDocCat1_070) {
      OPT.Cols.push({
        name: 'viSubFeeAmt',
        label: 'View Service료<br/>(CCTV 안심구출 서비스료)',
        width: 30,
        align: 'right',
        formatter: 'number',
        formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
      });
    }
    OPT.Cols.push({
      name: 'sumAmt',
      label: '합계',
      width: 30,
      align: 'right',
      formatter: 'number',
      formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
      summaryType: 'sum',
    });
    OPT.Cols.push({name: 'remark', label: '비고', width: 30, align: 'left'});

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 100,
      width: 800,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      footerrow: true,
      userDataOnFooter: true,
      loadComplete: function() {
        var sumAmtSum = $('#' + me.sheetId1).jqGrid('getCol', 'sumAmt', false, 'sum');
        $('#' + me.sheetId1).jqGrid('footerData', 'set', {crateName: '합계', sumAmt: sumAmtSum});
      },
    });

    $('#' + me.sheetId2).jqGrid({
      height: 100,
      width: 800,
      colModel: [
        {name: 'ctrtNo', label: '계약번호', width: 0, align: 'center', hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, align: 'center', hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, align: 'center', hidden: true},
        {name: 'itemsSize', label: '기종/규격', width: 100, align: 'left'},
        {name: 'qty', label: '수량', width: 30, template: 'number'},
        {
          name: 'unitPrice',
          label: '단가',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalAmt',
          label: '합계',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          summaryType: 'sum',
        },
        {name: 'remark', label: '비고', width: 50, align: 'left'},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      footerrow: true,
      userDataOnFooter: true,
      loadComplete: function() {
        var totalAmtSum = $('#' + me.sheetId2).jqGrid('getCol', 'totalAmt', false, 'sum');
        $('#' + me.sheetId2).jqGrid('footerData', 'set', {crateName: '합계', totalAmt: totalAmtSum});
      },
    });

    $('#' + me.sheetId3).jqGrid({
      height: 100,
      width: 800,
      colModel: [
        {name: 'ctrtNo', label: '계약번호', width: 0, align: 'center', hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, align: 'center', hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, align: 'center', hidden: true},
        {name: 'maintPlanDesc', label: '기종/규격', width: 100, align: 'left'},
        {name: 'qty', label: '대수', width: 30, template: 'number'},
        {
          name: 'unitPrice',
          label: '단가',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'maintPlanAmt',
          label: '월 장기수선<br/>충당금 관련 비용(대)',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalAmt',
          label: '합계',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          summaryType: 'sum',
        },
        {name: 'remark', label: '비고', width: 50, align: 'left'},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      footerrow: true,
      userDataOnFooter: true,
      loadComplete: function() {
        var totalAmtSum = $('#' + me.sheetId3).jqGrid('getCol', 'totalAmt', false, 'sum');
        $('#' + me.sheetId3).jqGrid('footerData', 'set', {crateName: '합계', totalAmt: totalAmtSum});
      },
    });

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      if (this.isShowSafetyPackageList) {
        $grid = $('#' + this.sheetId2);
        pobj = $grid.closest('.ui-jqgrid').parent();
        newWidth = pobj.width();
        $grid.jqGrid('setGridWidth', newWidth, true);
        // newHeight = pobj.height();
        // $grid.jqGrid("setGridHeight", newHeight, true);
      }

      if (this.isDocCat1_040 || this.isDocCat1_080) {
        $grid = $('#' + this.sheetId3);
        pobj = $grid.closest('.ui-jqgrid').parent();
        newWidth = pobj.width();
        $grid.jqGrid('setGridWidth', newWidth, true);
        // newHeight = pobj.height();
        // $grid.jqGrid("setGridHeight", newHeight, true);
      }
    },
    onLoad() {
      console.log('OContractTabItems_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.contractItemList})
        .trigger('reloadGrid');
      if (this.isShowSafetyPackageList) {
        $('#' + this.sheetId2)
          .clearGridData(true)
          .setGridParam({data: this.safetyPackageList})
          .trigger('reloadGrid');
      }
      if (this.isDocCat1_040 || this.isDocCat1_080) {
        $('#' + this.sheetId3)
          .clearGridData(true)
          .setGridParam({data: this.maintPlanList})
          .trigger('reloadGrid');
      }
      this.onResize();
    },
  },
  computed: {
    isDocCat1_020() {
      return this.contractInfo.docCat1 == '020';
    },
    isDocCat1_040() {
      return this.contractInfo.docCat1 == '040';
    },
    isDocCat1_070() {
      return this.contractInfo.docCat1 == '070';
    },
    isDocCat1_080() {
      return this.contractInfo.docCat1 == '080';
    },
    isShowSafetyPackageList() {
      return this.safetyPackageList !== undefined && this.safetyPackageList.length > 0;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

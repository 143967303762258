const state = () => ({
    searchInfo: {
      pgmCd: '',
      ctrtNo: '',
    },
    estimateList: [],
    estimateInfo: {
      ctrtNo : '',
      ctrtOdr : '',
      ctrtDivCd : '',
      ctrtSttusCd : '',
      histSn : '',
      ctrtOrginlCn : '',
      ctrtOrginlHashcd : '',
      ctrtOrginlFileNo : 0,
      signDt : '',
      retrnResnCn : '',
      retrnTradeNm : '',
      contEmpMail : '',
      contEmpDeskPhone : '',
      contEmpHandPhone : '',
      srpType : '',
      estDate : '',
      estNo : '',
      revisionNo : '',
      mainNo : '',
      custName : '',
      mskCustName : '',
      estName : '',
      modelType : '',
      machinesNo : '',
      adjEstPrice : 0,
      estEmpId : '',
      estEmpNm : '',
      sttusRecovery : '',
      sttusSend : '',
      sttusSigning : '',
      sttusReject : '',
      docCat1 : '',
      docNm : '',
      docGb1 : '',
      docGb2 : '',
      docGb3 : '',
    },
    estimateItemList: [],
    contractHistoryList: [], //20221202 ITSR62600
    estimateMemberList: [],

    isProgressPage: false,
    isCompletePage: false,
  });
  const getters = {
    searchInfo: state => state.searchInfo,
    estimateList: state => state.estimateList,
    estimateInfo: state => state.estimateInfo,
    estimateItemList: state => state.estimateItemList,
    contractHistoryList: state => state.contractHistoryList, //20221202 ITSR62600
    estimateMemberList: state => state.estimateMemberList,
    isProgressPage: state => state.isProgressPage,
    isCompletePage: state => state.isCompletePage,
    isEstEmp : state => (state.estimateInfo.estEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo),
  };
  const mutations = {
    setSearchInfo(state, payload) {
      for (let key in state.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.searchInfo[key] = payload[key];
        }
      }
    },
    setEstimateList(state, payload) {
      state.estimateList = payload;
    },
    setEstimateInfo(state, payload) {
      for (let key in state.estimateInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.estimateInfo[key] = payload[key];
        }
      }
    },
    setEstimateItemList(state, payload){
      state.estimateItemList = payload;
    },
    setHistoryList(state, payload){ //20221202 ITSR62600
      state.contractHistoryList = payload;
    },
    setEstimateMemberList(state, payload){
      state.estimateMemberList = payload;
    },   
    setProgressPage(state, payload){
      state.isProgressPage = payload;
    },
    setCompletePage(state, payload){
      state.isCompletePage = payload;
    },
    resetAllValues(state) {
      state.estimateInfo.ctrtNo = '',
      state.estimateInfo.ctrtOdr = '',
      state.estimateInfo.ctrtDivCd = '',
      state.estimateInfo.ctrtSttusCd = '',
      state.estimateInfo.histSn = '',
      state.estimateInfo.ctrtOrginlCn = '',
      state.estimateInfo.ctrtOrginlHashcd = '',
      state.estimateInfo.ctrtOrginlFileNo = 0,
      state.estimateInfo.signDt = '',
      state.estimateInfo.retrnResnCn = '',
      state.estimateInfo.retrnTradeNm = '',
      state.estimateInfo.contEmpMail = '',
      state.estimateInfo.contEmpDeskPhone = '',
      state.estimateInfo.contEmpHandPhone = '',
      state.estimateInfo.srpType = '',
      state.estimateInfo.estDate = '',
      state.estimateInfo.estNo = '',
      state.estimateInfo.revisionNo = '',
      state.estimateInfo.mainNo = '',
      state.estimateInfo.custName = '',
      state.estimateInfo.mskCustName = '',
      state.estimateInfo.estName = '',
      state.estimateInfo.modelType = '',
      state.estimateInfo.machinesNo = '',
      state.estimateInfo.adjEstPrice = 0,
      state.estimateInfo.estEmpId = '',
      state.estimateInfo.estEmpNm = '',
      state.estimateInfo.sttusRecovery = '',
      state.estimateInfo.sttusSend = '',
      state.estimateInfo.sttusSigning = '',
      state.estimateInfo.sttusReject = '',
      state.estimateInfo.docCat1 = '',
      state.estimateInfo.docNm = '',
      state.estimateInfo.docGb1 = '',
      state.estimateInfo.docGb2 = '',
      state.estimateInfo.docGb3 = '',

      state.estimateItemList = [];
      state.estimateMemberList = [];
      state.contractHistoryList = []; //20221202 ITSR62600
    }
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  
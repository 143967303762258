<template>
  <div v-show="visible" :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="comboBoxId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <select
      :id="comboBoxId"
      v-model="internalValue"
      :size="size"
      :multiple="multiple"
      :class="comboBoxClass"
      :style="comboBoxStyle"
      :readonly="readonly"
      :disabled="!enable"
      @click="click"
      @change="change"
      :name="name"
    >
      <option v-if="hasPlaceHolder" :value="internalPlaceHolder[internalItemValueAlias]" disabled>
        {{ internalPlaceHolder[internalItemTextAlias] }}
      </option>
      <option
        v-for="item in internalItems"
        :key="item[internalItemKeyAlias]"
        :value="item[internalItemValueAlias]"
        :disabled="item[internalItemEnableAlias] !== undefined && !item[internalItemEnableAlias]"
        >{{ item[internalItemTextAlias] }}</option
      >
    </select>
    <span v-if="suffix" :id="suffixId" class="suffix" :style="suffixStyle">{{ suffix }}</span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  name: 'iui-select-old',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    size: {
      type: [Number, String],
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array],
      default: undefined,
    },
    placeholder: {
      type: [String, Object],
      default: undefined,
    },
    // TODO: 제거
    defaultCd: {
      type: String,
      // validator(value) {
      // 	return value === undefined;
      // },
    },
    // bindArray: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    //   validator(value) {
    //     return value === undefined;
    //   },
    // },
    // TODO: etc
    // type: {
    //   type: String,
    //   default: 'select',
    // },
    // formObj: {
    //   type: String,
    //   default: 'none!',
    // },
  },
  data() {
    return {
      internalValue: undefined,
      internalPlaceHolder: undefined,
    };
  },
  computed: {
    // id
    comboBoxId: {
      get() {
        return this.getId('select');
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-combobox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    comboBoxClass: {
      get() {
        let obj = {
          _target_: true,
          combobox: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          // width: '100%',
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }

        return obj;
      },
    },
    comboBoxStyle: {
      get() {
        let obj = {
          'min-width': undefined,
          height: undefined,
        };

        if (this.width !== undefined) {
          obj['min-width'] = 'unset';
        }

        if (this.size !== undefined) {
          obj['height'] = 'unset';
        }

        return obj;
      },
    },

    // computed
    hasPlaceHolder: {
      get() {
        return this.internalPlaceHolder !== undefined;
      },
    },
  },
  watch: {
    value(newValue) {
      if (this.multiple) {
        if (Array.isArray(newValue)) {
          this.internalValue = newValue;
        } else {
          this.internalValue = [newValue];
        }
      } else {
        if (Array.isArray(newValue)) {
          if (newValue.length > 0) {
            this.internalValue = newValue[0];
          }
        } else {
          this.internalValue = newValue;
        }
      }
    },
  },
  created() {
    if (this.multiple) {
      if (Array.isArray(this.value)) {
        this.internalValue = this.value;
      } else {
        this.internalValue = [this.value];
      }
    } else {
      if (Array.isArray(this.value)) {
        if (this.value.length > 0) {
          this.internalValue = this.value[0];
        }
      } else {
        this.internalValue = this.value;
      }
    }

    if (this.placeholder !== undefined) {
      if (typeof this.placeholder === 'string') {
        this.internalPlaceHolder = {
          [this.internalItemTextAlias]: this.placeholder,
          [this.internalItemValueAlias]: undefined,
        };
      } else {
        this.internalPlaceHolder = this.placeholder;
      }
    }

    // validator 설정
    this.validatorTargetDataProp = 'internalValue';
    this.validationTriggerEventName = ['change'];

    this.$on('update:value', this.setBindArray);
  },
  mounted() {
    // this.setFormData();

    //속성 주입
    // for (let k in this.$attrs) {
    //   this.$el.querySelector('._target_').setAttribute(k, this.$attrs[k]);
    //   this.$el.removeAttribute(k);
    //   if (k == 'id' && this.$el.querySelector('label')) {
    //     this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
    //   }
    // }

    // //이벤트 주입
    // for (let e of this.event) {
    //   this.$el.querySelector('._target_').addEventListener(e.name, e.callback);
    // }

    // //스타일 주입
    // for (let k in this.css) {
    //   this.$el.querySelector('._target_').style[k] = this.css[k];
    // }

    // //기본요소 height와 라벨 height 맞춤
    // if (this.$el.querySelector('label')) {
    //   this.$el.querySelector('label').style.height = this.$el.querySelector('._target_').offsetHeight + 'px';
    //   this.$el.querySelector('label').style.lineHeight = this.$el.querySelector('._target_').offsetHeight + 'px';
    // }

    // //기본요소 구분하기 위한 class명 삭제
    // // this.$el.querySelector("._target_").classList.remove("_target_");

    // if (this.$el.querySelector('._target_') && this.$el.querySelector('._target_').hasOwnProperty('name')) {
    //   this.name = this.$el.querySelector('._target_').name;
    // }

    if (this.defaultCd && this.defaultCd != '') {
      let option = document.createElement('option');
      if (this.defaultCd == 'S') {
        option.text = '선택';
        option.value = '';
      } else if (this.defaultCd == 'A') {
        option.text = '전체';
        option.value = '';
      }
      this.$el.querySelector('._target_').add(option, 0);
    }
  },
  methods: {
    // setFormData(value) {
    //   const me = this;
    //   setTimeout(function() {
    //     me.$store.dispatch('setFormData', {
    //       formObj: me.formObj,
    //       key: me.name,
    //       value: !value ? me.vdata : value,
    //       element: me.$el.querySelector('._target_'),
    //     });
    //   }, 10);
    // },
    change(e) {
      this.emitUpdateValue();
      this.$emit('change', e);
    },
    click(e) {
      this.$emit('click', e);
    },
    emitUpdateValue() {
      this.$emit('update:value', this.internalValue);
    },
    // TODO: 제거
    setBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
  },
};
</script>

<style></style>

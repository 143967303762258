/* 인지세 등록 팝업 * /
<script>
import {defineComponent, reactive, watch, computed, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import FileUpDown from '@/view/sample/FileUpDown';

export default defineComponent({
  props: {
    stmptaxSn: {
      type: Number,
      default: undefined,
    },
    contractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {FileUpDown},

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const stamptaxDialog = useDialog('stamptaxDialog');

    const state = reactive({
      loading: false,

      codeList: {
        // 납부주체
        arrPayMby: [
          {text: 'OTIS', value: '01'},
          {text: '고객', value: '02'},
          {text: 'OSEP', value: '03'},
        ],
      },

      stamptaxInfo: {
        stmptaxSn: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        payMbyCd: '01', // 납부주체
        insttNm: '', // 기관명
        stmptaxAm: 0, // 인지세액
        stmptaxPayDe: $_getCurrentDate(), // 납부일자
        rceptNo: '', // 접수번호
        fileNo: 0,
        stmptaxBeffatPayYn: 'N', // 사전납부
        rpaYn: 'N',
      },

      entryEmpId: undefined,

      canEnable: computed(() => {
        if (props.stmptaxSn === undefined) {
          return true;
        }
        if (sessionStorage.getItem('userInfo') !== null) {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          return userInfo.empNo == state.entryEmpId;
        } else {
          return false;
        }
      }),

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false, // 추가
      fileUpDown_delete: false, // 삭제
      fileUpDown_download: false, // 다운
      fileUpDown_search: false,
      fileUpDown_save: false,
      fileUpDown_changed: false,

      stmptaxBeffatPayYn: [],
    });

    watch(
      () => props.stmptaxSn,
      newValue => {
        if (newValue === undefined) {
          return;
        }
        method.getData();
      }
    );

    watch(
      () => props.contractKey,
      newValue => {
        if (newValue === undefined) {
          return;
        }

        state.stamptaxInfo.stmptaxSn = '';
        state.stamptaxInfo.ctrtNo = props.contractKey.ctrtNo;
        state.stamptaxInfo.ctrtOdr = props.contractKey.ctrtOdr;
        state.stamptaxInfo.ctrtDivCd = props.contractKey.ctrtDivCd;
        state.stamptaxInfo.tradeNo = '';
        state.stamptaxInfo.payMbyCd = '01';
        state.stamptaxInfo.insttNm = '';
        state.stamptaxInfo.stmptaxAm = 0;
        state.stamptaxInfo.stmptaxPayDe = $_getCurrentDate();
        state.stamptaxInfo.rceptNo = '';
        state.stamptaxInfo.fileNo = 0;
        state.stamptaxInfo.stmptaxBeffatPayYn = 'N';
        state.stamptaxInfo.rpaYn = 'N';
      }
    );

    watch(
      () => props.search,
      (newValue, oldValue) => {
        // 상위 페이지의 search 상태가 변경된 경우 처리
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );

    watch(
      () => state.stamptaxInfo.rceptNo,
      newValue => {
        if (newValue === undefined) {
          return;
        }
        let value= newValue;

        value = value.replace(/[^0-9]/g, "");

        if(value.length > 16){
            value = value.substr(0, 16);
        }

        let result = [];
        result.push(value.substr(0, 7));
        result.push(value.substring(7));         

        //this.$nextTick();
        setTimeout(function(){
          state.stamptaxInfo.rceptNo = result.filter((val) => val).join("-");
        },1);
        console.log(state.stamptaxInfo.rceptNo);
        
      }
    );

    const method = {
      changeCheckbox(obj) {
        let value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;

        if (value == 'Y') {
          vm.$alert(
            '전자계약 인지세 사전납부 시 선택 해주시기 바랍니다.<br/>선택한 계약의 인지세는 CR팀 일괄납부대상에서 제외됩니다.'
          );
        }
      },
      // 조회
      async getData() {
        const param = {
          stmptaxSn: props.stmptaxSn,
        };

        try {
          state.loading = true;

          const response = await axios.post('/stamptax/selectByStmptaxSn', param);

          state.stamptaxInfo = response.data;
          state.entryEmpId = response.data.entryEmpId;

          // 사전납부
          state.stmptaxBeffatPayYn = state.stamptaxInfo.stmptaxBeffatPayYn === 'N' ? [] : ['Y'];
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      // 저장
      async btnSave_click() {
        if (!state.canEnable) {
          vm.$alert('읽기모드로 조회 중입니다.');
          return;
        }

        if (state.stamptaxInfo.insttNm.length == 0) {
          vm.$alert('기관명을 입력해야 합니다.');
          return;
        }
        if (state.stamptaxInfo.stmptaxPayDe <= 0) {
          vm.$alert('납부일자를 입력해야 합니다.');
          return;
        }
        if (state.stamptaxInfo.stmptaxAm.length == 0 || state.stamptaxInfo.stmptaxAm <= 0) {
          vm.$alert('인지세액을 입력해야 합니다.');
          return;
        }
        const regType1 = /^[0-9+]*$/;
        if (!regType1.test(state.stamptaxInfo.stmptaxAm)) {
          vm.$alert('인지세액은 숫자만 입력 가능합니다.');
          return;
        }
        if (state.stamptaxInfo.rceptNo.length == 0) {
          vm.$alert('접수번호를 입력해야 합니다.');
          return;
        }
        if (state.fileUpDown_files.lclFlNm === undefined || state.fileUpDown_files.lclFlNm == '') {
          vm.$alert('증명서 파일을 첨부해야 합니다.');
          return;
        }

        if (!(await vm.$confirm('저장하시겠습니까?'))) {
          return;
        }

        if (state.fileUpDown_files.lclFlNm !== undefined && state.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            state.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          method.saveStamptax();
        }
      },

      // 삭제
      async btnDelete_click() {
        if (props.stmptaxSn === undefined) {
          vm.$alert('저장 후 삭제가 가능합니다.');
          return;
        }

        if (!state.canEnable) {
          vm.$alert('읽기모드로 조회 중입니다.');
          return;
        }

        if (!(await vm.$confirm('삭제하시겠습니까?'))) {
          return;
        }

        let pgmCd = undefined;
        if (sessionStorage.getItem('userInfo') !== null) {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          pgmCd = userInfo.pgmCd;
        }

        const data = {
          ...state.stamptaxInfo,
          pgmCd: pgmCd,
          chngPgmId: undefined,
        };

        if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
          data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
        }

        try {
          state.loading = true; // 로딩 상태 변경

          await axios.post('/stamptax/deleteByStmptaxSn', data);

          await vm.$alert('삭제되었습니다.');

          stamptaxDialog.hide(true);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false; // 로딩 상태 변경
        }
      },

      async saveStamptax() {
        // 사전납부
        state.stamptaxInfo.stmptaxBeffatPayYn = state.stmptaxBeffatPayYn.length > 0 ? 'Y' : 'N';

        const data = {
          ...state.stamptaxInfo,
          chngPgmId: undefined,
        };

        if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
          data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
        }

        try {
          state.loading = true; // 로딩 상태 변경

          const uri =
            props.stmptaxSn === undefined ? '/stamptax/insertStamptax' : '/stamptax/updateByStmptaxSnSelective';

          await axios.post(uri, data);

          await vm.$alert('저장되었습니다.');

          stamptaxDialog.hide(true);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false; // 로딩 상태 변경
        }
      },

      file_save_complete() {
        console.log('파일 저장이 완료되었습니다.');

        if (state.fileUpDown_files.lclFlNm === undefined || state.fileUpDown_files.lclFlNm == '') {
          state.stamptaxInfo.fileNo = 0;
        } else {
          state.stamptaxInfo.fileNo = state.fileUpDown_files.flNo;
        }

        method.saveStamptax();
      },
      file_save_error() {
        console.log('파일 저장을 실패했습니다.');
      },
      file_delete_complete() {
        console.log('파일 삭제가 완료되었습니다.');
      },
      file_delete_error() {
        console.log('파일 삭제를 실패했습니다.');
      },

    };

    return {
      state,
      ...method,
      ...stamptaxDialog.dialogRef,
      ...stamptaxDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog ref="stamptaxDialog" :loading="state.loading" width="700px" height="300px" title="인지세 정보">
    <template #button-center>
      <iui-button @click="btnSave_click" :enable="state.canEnable">저장</iui-button>
      <iui-button @click="btnDelete_click" :enable="state.canEnable">삭제</iui-button>
    </template>

    <iui-content-box>
      <iui-container type="table" header-width="100px">
        <i-row>
          <i-col-header>계약번호</i-col-header>
          <i-col>
            <iui-textbox v-model="state.stamptaxInfo.ctrtNo" width="200px" disable />
          </i-col>
          <i-col-header>납부주체</i-col-header>
          <i-col>
            <iui-select
              v-model="state.stamptaxInfo.payMbyCd"
              :items="state.codeList.arrPayMby"
              :enable="state.canEnable"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>기관명</i-col-header>
          <i-col>
            <iui-textbox v-model="state.stamptaxInfo.insttNm" width="200px" :enable="state.canEnable" />
          </i-col>
          <i-col-header>인지세 사전납부</i-col-header>
          <i-col>
            <iui-checkbox
              v-model="state.stmptaxBeffatPayYn"
              label="사전납부"
              value="Y"
              :enable="state.canEnable"
              @change="changeCheckbox"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>납부일자</i-col-header>
          <i-col>
            <iui-datepicker v-model="state.stamptaxInfo.stmptaxPayDe" :enable="state.canEnable" />
          </i-col>
          <i-col-header>인지세액</i-col-header>
          <i-col>
            <iui-textbox type="number" v-model="state.stamptaxInfo.stmptaxAm" prefix="\" :enable="state.canEnable" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>접수번호</i-col-header>
          <i-col colspan="3">
            <iui-textbox v-model="state.stamptaxInfo.rceptNo" width="200px" :enable="state.canEnable" :value.sync="state.stamptaxInfo.rceptNo" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>증명서첨부</i-col-header>
          <i-col colspan="3">
            {{ state.fileUpDown_files.lclFlNm }}
            <iui-button @click="state.fileUpDown_add = true" v-show="state.canEnable && !state.fileUpDown_files.lclFlNm"
              >추가</iui-button
            >
            <iui-button
              @click="state.fileUpDown_delete = true"
              v-show="state.canEnable && state.fileUpDown_files.flNo > 0 && state.fileUpDown_files.lclFlNm"
              >삭제</iui-button
            >
            <iui-button
              @click="state.fileUpDown_download = true"
              v-if="state.fileUpDown_files.flNo > 0 && state.fileUpDown_files.svrFlPth"
              >다운</iui-button
            >
            <iui-button @click="state.fileUpDown_search = true" v-if="false">조회</iui-button>
            <iui-button @click="state.fileUpDown_save = true" v-if="false">저장</iui-button>
            <file-up-down
              company-code="201900"
              v-model="state.fileUpDown_files"
              :file-number.sync="state.stamptaxInfo.fileNo"
              add-button-text="파일추가"
              delete-button-text="파일삭제"
              save-button-text="파일저장"
              download-button-text="파일다운"
              :add.sync="state.fileUpDown_add"
              :delete.sync="state.fileUpDown_delete"
              :download.sync="state.fileUpDown_download"
              :search.sync="state.fileUpDown_search"
              :save.sync="state.fileUpDown_save"
              :changed.sync="state.fileUpDown_changed"
              @save-complete="file_save_complete"
              @save-error="file_save_error"
              @delete-complete="file_delete_complete"
              @delete-error="file_delete_error"
            />
          </i-col>
        </i-row>
      </iui-container>
    </iui-content-box>
  </iui-dialog>
</template>

<style></style>

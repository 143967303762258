<template>
  <pmis-content-box :loading="listBoxLoading">
	<template v-slot:title>
    CMS
    </template>
    <template #title-right>
      <iui-button color="blue" v-show="isShowSign" @click="onSign">서명</iui-button>
    </template>

    <iui-container type="table" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            >
            </iframe>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowSign" height="50px">
        <i-col-header>서명방법</i-col-header>
        <i-col>
          <iui-select-old
            name="signMthCd"
            :items="arrSignMthCd"
            :value="signInfo.signMthCd"
            :enable="true"
            required
            @change="onSetSignMthCd"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
	name: 'cms-view',
	created() {
		this.addEvent({name: 'Cms_Search', func: this.onSearch});
		console.log('addEvent', 'Cms_Search');
		window.addEventListener('message', this.reqSetOzParam, false);
	},
	data() {
		return {
			theme: 'bullet',
			width60: '60px',
			width80: '80px',
			width100: '100px',
			width120: '120px',
			width170: '170px',
			width200: '200px',

			listBoxLoading: false,

			urlParam: '',
			OZParam: {},

			arrSignMthCd: [
				{text: '선택', value: ''},
				//{text: '공동인증서', value: '10'},
				// {text: '모바일인증서', value: '20'},
				//{text: '디지털서명', value: '30'},
			],

			isReject: false,
			isSign: false,
			otisPage: false,

			contractInfo: {
				ctrtNo: '',
				ctrtOdr: '',
				ctrtDivCd: '',
				ctrtSttusCd: '',
				histSn: '',
				ctrtOrginlCn: '',
				ctrtOrginlHashcd: '',
				ctrtOrginlFileNo: '',
			},

			signerInfoVo: {
				tradeNo: '',
				tradeSuffix: '',
				personId: '',
				businessNo: '',
				mphone: '',
				userNm: '',
				canSign: false,
				corporate: false,
				customer: false,
				buyagnt: false,
				personal: false,
			},

			signInfo: {
				signSn: 0,
				ctrtNo: '',
				ctrtOdr: '',
				ctrtDivCd: '',
				tradeNo: '',
				tradeSuffix: '',
				estmtNo: '',
				estmtOdr: 0,
				reqstdocNo: '',
				reqstdocOdr: 0,
				reqstdocDivCd: '',
				signMthCd: '',
				signerSeCd: '',
				retrnResnCn: '',
				//signDt: '',
				signoneCn: '',
				signtwoCn: '',
				signthreeCn: '',
				signfourCn: '',
				signfiveCn: '',
				agentIdntyImageCn: '',
				fileNo: 0,
				accesIp: '',
				accesEnvrnCn: '',
				pkcs7SignedMessage: '',
				signerDn: '',
				vidRandom: '',
				personId: '',
				businessNo: '',
				passNo: 0,
				corporate: false,
				customer: false,
				//osep: false,
				buyagnt: false,
				personal: false,

				//ITSR62600
				tradeType:'',
				tradeName:'',
			},

			requestInfo: {
				reqstdocDivCd: 'CMS', // 신청서 구분 코드
				reqstdocNo: '',
				reqstdocOdr: 0,
				requestOrginlHashcd: '',
				progrsSttusCd: '',
				requestOriginFileNo: '',
				checkContDate: '', // 계약일
				agreYn: '',
				infoUseAgreYn: '', // CMS - 정보 이용 동의 여부
				infoProvdAgreYn: '', // CMS - 정보 제공 동의 여부

				tradeNo : '', //로그인상태가 아니므로 사인정보에서 가져와서 넣기
			},

			isVerifyPASS: false,
			isReqUpdate: false, // 신청서 상태업데이트 여부
			isProcessing: false, // 전자서명 무한클릭 방지
		};
	},
	mounted() {
		this.$secuKit.init();
		this.$secuKit.checkReady();
		console.log('checkReady = ' + this.$secuKit.secuKitReady);
		//this.requestInfo.reqstdocDivCd = this.decParams.reqstdocDivCd;
		this.requestInfo.reqstdocNo = this.decParams.reqstdocNo;
		this.requestInfo.reqstdocOdr = this.decParams.reqstdocOdr;
		//this.requestInfo.progrsSttusCd = this.decParams.progrsSttusCd;
		//this.requestInfo.checkContDate = this.decParams.checkContDate;

		//$("#signMthCd")
		//$("select #signMthCd option[value*='']").prop('disabled',true);		
	},
	methods: {
		...mapMutations('CmsStore', {
			setCmsInfo: 'setContractInfo',
		}),
		onSetSignMthCd(e, t) {
			let target = typeof e == 'string' ? t : e.target.name;
			let value = typeof e == 'string' ? e : e.target.value;
			
			console.log("target",target);
			this.signInfo[target] = value;
			
			if (value == '30' || value == '10') {
				// 디지털 서명 + 공동인증서
				this.onViewReport(true, 'Y');
			} else {
				this.onViewReport(true, 'N');
			}
			
		},
		onViewReport(isReload, signable) {
			// var signable    = (eval(this.signerInfoVo.canSign) == true ? 'Y' : 'N')
			var signerSeCd =  "10"; //cms는 무조건 고객// eval(this.signerInfoVo.corporate) == true ? '10' : '30';
			//var signerSeCd = '30';
			var tradeNo = this.decParams.tradeNo;
			var signMthCd = this.signInfo.signMthCd;

			console.log('signable', signable);
			console.log('signerSeCd', signerSeCd);
			console.log('tradeNo', tradeNo);
			console.log('signMthCd', signMthCd);

			let param = {
				reqstdocNo: this.decParams.reqstdocNo,
				reqstdocOdr: this.decParams.reqstdocOdr,
				manageCd: 'R03',
			};

			axios
				.post('/ozrpt/selectOzEtcFormService', param)
				.then(response => {
					console.log('조회결과 response >>>', response);
					// console.log('조회결과 response.data >>>', response.data);
					this.requestInfo.progrsSttusCd = response.data.jsonData.cms.progrsSttusCd;
					//this.requestInfo.checkContDate = response.data.jsonData.cms.checkContDate;
					this.requestInfo.reqstdocNo = response.data.jsonData.cms.reqstdocNo;
					this.requestInfo.reqstdocOdr = response.data.jsonData.cms.reqstdocOdr+""; //문자열로 만든다

					this.OZParam = Object.assign({}, response.data.jsonData);

					this.OZParam.reportname = response.data.reportPathName;
					this.OZParam.OZFormParam = {
						signable: signable, // 필수(*):서명가능여부(Y/N)
						signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
						tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
						signMthCd: signMthCd,
					};
					console.log("this.OZParam.OZFormParam",this.OZParam.OZFormParam);

					this.urlParam = process.env.VUE_APP_OZ_VIEWER_URL;

					if (isReload) {
						//OZ Viewer 에서 message 를 호출할 Event 등록.
						window.addEventListener('message', this.reqSetOzParam, false);

						// Internet Explorer 6-11
						var isIE = /*@cc_on!@*/ false || !!document.documentMode;

						if (isIE) {
							document.getElementById('ozFrame').src = process.env.VUE_APP_OZ_VIEWER_URL;
						} else {
							//OZ Reload
							document.getElementById('ozFrame').contentWindow.location.reload(true);
							document.getElementById('ozFrame').contentWindow.location.reload(true);
						}
					}

					//$("select[name=signMthCd] option[value='']").attr("disabled", true);
					$("select[name=signMthCd] option[value='']").prop("disabled", true);
				})
				.catch(function(error) {
					console.log('========error====================');
					console.log(error);
				});
		},
		reqSetOzParam(e) {
			if (e.data.msg == 'requestOzParam') {
				console.log('Parent:::reqSetOzParam:::', e.data);
				console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);

				//OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
				document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

				//Event Listener 를 제거.
				window.removeEventListener('message', this.reqSetOzParam, false);
			}
		},
		async onSearch(isReload) {
			console.log('Cms_Search', 'onSearch');
			console.log('decParams.ctrtNo', this.decParams.ctrtNo);
			console.log('decParams.ctrtOdr', this.decParams.ctrtOdr);
			console.log('decParams.ctrtDivCd', this.decParams.ctrtDivCd);
			console.log('decParams.tradeNo', this.decParams.tradeNo);
			console.log('decParams.tradeSfx', this.decParams.tradeSfx);

			this.isReject = false;
			this.isSign = false;

			let param = {
				ctrtNo: this.decParams.ctrtNo,
				ctrtOdr: this.decParams.ctrtOdr,
				ctrtDivCd: this.decParams.ctrtDivCd,
				tradeNo: this.decParams.tradeNo,
			};

			console.log('param', param);
			if (param.ctrtNo === undefined || param.ctrtNo == '') return;

			try {
				this.listBoxLoading = true;
				let response = '';
				if (this.decParams.ctrtDivCd == 'TC') {
					response = await axios.post('/urlLink/selectTContractInfo', param);
				} else {
					response = await axios.post('/urlLink/selectOContractInfo', param);
				}
				this.listBoxLoading = false;
				console.log(response.data);

				this.setCmsInfo(response.data.contractInfo);
				this.requestInfo.checkContDate = response.data.contractInfo.checkContDate;
				
				console.log("this.requestInfo.checkContDate",this.requestInfo.checkContDate);

				let paramF = {
					reqstdocNo: this.decParams.ctrtNo,
					reqstdocDivCd: "CMS",
				};
				let response3 = '';
				response3 = await axios.post('/urlLink/selectReqFileInfo', paramF);
				this.requestInfo.requestOriginFileNo = response3.data.requestOriginFileNo;
				this.requestInfo.requestOrginlHashcd = response3.data.requestOrginlHashcd;
				// $.ajax({
				// 	url: '/urlLink/selectReqFileInfo',
				// 	async:false,
				// 	data: JSON.stringify(paramF),
				// 	success: function(data) {
				// 		this.requestInfo.requestOriginFileNo = data.requestOriginFileNo;
				// 		this.requestInfo.requestOrginlHashcd = data.requestOrginlHashcd;
				// 	}
				// });

				let param2 = {
					ctrtNo: this.decParams.ctrtNo,
					ctrtOdr: this.decParams.ctrtOdr,
					ctrtDivCd: this.decParams.ctrtDivCd,
					tradeNo: this.decParams.tradeNo,
					tradeSfx: this.decParams.tradeSfx,
					jobDivCd : "CMS",
				};
				let response2 = '';
				if (this.decParams.ctrtDivCd == 'TC') {
					response2 = await axios.post('/urlLink/selectTContractSignerInfo', param2);
				} else {
					response2 = await axios.post('/urlLink/selectOContractSignerInfo', param2);
				}
				

				console.log(response2);
				this.signerInfoVo = response2.data;

				this.signInfo.ctrtNo = this.cmsInfo.ctrtNo;
				this.signInfo.ctrtOdr = this.cmsInfo.ctrtOdr;
				this.signInfo.ctrtDivCd = this.cmsInfo.ctrtDivCd;
				this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
				this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
				this.signInfo.canSign = this.signerInfoVo.canSign;
				this.signInfo.corporate = this.signerInfoVo.corporate;
				this.signInfo.customer = this.signerInfoVo.customer;
				//this.signInfo.osep = this.signerInfoVo.osep;
				//this.signInfo.buyagnt = this.signerInfoVo.buyagnt;
				this.signInfo.personal = this.signerInfoVo.personal;
				this.signInfo.personId = this.signerInfoVo.personId;
				this.signInfo.businessNo = this.signerInfoVo.businessNo;
				this.signInfo.signerSeCd = '';

				//ITSR62600
				this.signInfo.tradeType = (this.signerInfoVo.customer)?"CUST":"CCPY";
				this.signInfo.tradeName = this.signerInfoVo.tradeName;

				this.signInfo.retrnResnCn = '';
				this.signInfo.agentIdntyImageCn = '';
				
				// 신청서가 작성중 상태 , 첫번째 고객일 경우에만 서명 가능 할 수 있도록 설정
				console.log("this.signerInfoVo.canSign'",this.signerInfoVo.canSign);
				console.log("this.signerInfoVo.customer",this.signerInfoVo.customer);
				console.log("this.signerInfoVo.tradeOrder",this.signerInfoVo.tradeOrder);
				if (
					this.signerInfoVo.canSign == true
					&&	eval(this.signerInfoVo.customer) == true 
					// &&	this.signerInfoVo.tradeOrder == 'A'
				) {
					//this.onViewReport(isReload, 'N'); // 서명 가능 여부 설정 (기본 default 인자값 => N)

					if(eval(this.signInfo.corporate) == false){
						this.arrSignMthCd.push({text: '디지털서명', value: '30'});
					}else{
						this.arrSignMthCd.push({text: '공동인증서', value: '10'});
					}

					console.log("this.arrSignMthCd",this.arrSignMthCd);
					/*
					// 대리인은 로그인 할 수 없으므로 공동인증서 서명 가능.
					this.arrSignMthCd.push({text: '공동인증서', value: '10'});
					// 비표준 계약이 아니면서 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능.
					if (this.cmsInfo.docCat1 != '000' && eval(this.signInfo.corporate) == false) {
						this.arrSignMthCd.push({text: '디지털서명', value: '30'});
					}
					*/


					if (this.signInfo.signMthCd == '') {
						// 디지털 서명 또는 공동인증서 서명만 가능 할 때 자동으로 선택
						if (this.arrSignMthCd.length < 3) {
							this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
						}
					}
				}else{
					this.onViewReport(true, 'N');
				}



				/*
				if (eval(this.signInfo.canSign) == true && eval(this.signInfo.corporate) == false) {
					this.arrSignMthCd.push({text: '디지털서명', value: '30'});
				}
				*/
				/*
				if (this.signInfo.signMthCd == '') {
					this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
				} else {
					this.onViewReport(isReload, 'N');
				}
				*/
			} catch (ex) {
				this.listBoxLoading = false;
				console.log("ex",ex);
			}
		},
		async onSign() {
			
			if (this.signInfo.signMthCd == '10') {
				console.log('this.cmsInfo.ctrtOrginlHashcd', this.cmsInfo.ctrtOrginlHashcd);
				console.log('this.requestInfo.requestOrginlHashcd', this.requestInfo.requestOrginlHashcd);

				let checkReady = this.$secuKit.secuKitReady;
				console.log('checkReady = ' + checkReady);

				if (!checkReady) {
					this.$alert('공동인증서 툴킷 로딩이 완료되지 못했습니다.');
					return;
				}

				if (!(await this.$confirm('전자서명 하시겠습니까?'))) return;

				const r1 = await this.$secuKit.showDialog();
				console.log(r1);

				const certID = this.$secuKit.certListInfo.getCertID();
				console.log('certID', certID);

				let data = {
					certType: 'SignCert',
					sourceString: this.requestInfo.requestOrginlHashcd,
					certID: certID,
				};

				const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
				console.log(r2);

				this.signInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

				console.log('PKCS7SignedMessage', this.signInfo.pkcs7SignedMessage);

				let data2 = {
					certType: 'SignCert',
					certID: certID,
					isViewVID: '1',
				};
				const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
				console.log(r3);

				this.signInfo.vidRandom = r3.vidRandom;
				this.signInfo.signerDn = r3.userDN;

				console.log('vidRandom', this.signInfo.vidRandom);
				console.log('signerDn', this.signInfo.signerDn);

				let data3 = {
					certType: 'SignCert',
					sourceString: r3.vidRandom,
					certID: certID,
				};

				const r4 = await this.$secuKit.invoke('generatePKCS7SignedData', data3);
				console.log(r4);

				this.signInfo.vidRandom = r4.generatePKCS7SignedData;

				if (this.signInfo.pkcs7SignedMessage == '') {
					this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
						this.signInfo.pkcs7SignedMessage = '';
						this.signInfo.signerDn = '';
					});
					return;
				}

				let data4 = {
					signedType: '1',
					signedString: removePEMHeader(removeCRLF(this.signInfo.pkcs7SignedMessage)),
				};

				const r5 = await this.$secuKit.verifyPKCS7SignedData(data4);
				console.log(r5);

				console.log('VerifyPKCS7SignedData', r5.verifyPKCS7SignedData);

				if (this.requestInfo.requestOrginlHashcd != r5.verifyPKCS7SignedData) {
					this.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.', () => {
						this.signInfo.pkcs7SignedMessage = '';
						this.signInfo.signerDn = '';
					});
					return;
				}
			} else if (this.signInfo.signMthCd == '20') {
				// 모바일 인증서 서명은 웹에서는 해당 없음.
			} else if (this.signInfo.signMthCd == '30') {
				// 디지털서명은 PASS 인증을 수행하고 통과 해야 디지털 서명이 가능하다.
				if (!this.isVerifyPASS) {
					this.signInfo.passNo = 0;
					console.log('this.signerInfoVo.userNm', this.signerInfoVo.userNm);
					console.log('this.signerInfoVo.mphone', this.signerInfoVo.mphone);
					var passResult = await this.$PASSCertification(this.signerInfoVo.userNm, this.signerInfoVo.mphone);
					console.log(passResult);
					if (passResult.result) {
						this.isVerifyPASS = passResult.result;
						this.signInfo.passNo = passResult.data.passNo;
					} else {
						if(passResult.errorMessage !=''){
							this.$alert(passResult.errorMessage);
						}else{
							this.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다.');
						}						
						return;
					}
				}

				//OZ Viewer 의 입력값들를 리턴받음.
				ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
				console.log('OZ Viewer input ', ozVal);
				if (ozVal == null || ozVal == '') {
					// this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
					return;
				}
				this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];

				if (this.signInfo.agentIdntyImageCn == undefined) {
					this.signInfo.agentIdntyImageCn = '';
					this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
					return;
				}

				if (!this.signInfo.agentIdntyImageCn.trim()) {
					this.$alert('CMS신청서의 서명란에 디지털서명을 해야합니다.');
					return;
				}
			}

			console.log('this.contractInfo.ctrtOrginlHashcd', this.contractInfo.ctrtOrginlHashcd);
			console.log('this.requestInfo.requestOrginlHashcd', this.requestInfo.requestOrginlHashcd);
			console.log("this.requestInfo.checkContDate",this.requestInfo.checkContDate);
			if (this.isReject) this.isReject = false;
			if (!this.isSign) this.isSign = true;

			if (!this.signInfo.signMthCd.trim()) {
				this.$alert('서명방법을 선택해야합니다.');
				return;
			}

			if (eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
				this.$alert('법인 사업자는 서명방법을 디지털서명을 선택할 수 없습니다.');
				this.signInfo.signMthCd = '';
				return;
			}

			var ozVal = '';
			ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
			console.log('onPopupConfirmContractViewSign input ', ozVal);
			if (ozVal == null || ozVal == '') {
				//this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
				return;
			}
			if(ozVal.infoUseAgreYn == 'N'){
				this.$alert('"개인정보 및 고유식별정보 수집 및 이용동의"에 동의 하셔야 CMS출금 이체 신청서 접수가 가능합니다.');
				return;
			}
			if(ozVal.infoProvdAgreYn == 'N'){
				this.$alert('"개인정보 제3자 제공동의"에 동의 하셔야 CMS출금 이체 신청서 접수가 가능합니다.');
				return;
			}

			this.signInfo.signerSeCd = '10'; // 본인 : 10, 대리인 : 20.

			// 전자서명시에 pdf 동의 정보 표시위해 상태 먼저 update
			this.requestInfo.agreYn = ozVal.agreYn;
			this.requestInfo.infoUseAgreYn = ozVal.infoUseAgreYn;
			this.requestInfo.infoProvdAgreYn = ozVal.infoProvdAgreYn;

			//로그인이 안된 상태이므로 tradeNo 넣는다
			this.requestInfo.tradeNo = this.signInfo.tradeNo;

			let param = {
				signVo: this.signInfo,
				requestInfo: this.requestInfo,
				pgmCd: '201900',
				chngPgmId: 'LinkView',
			};
			console.log("paramaaaa",param);
			const me = this;
			me.listBoxLoading = true;
			axios
				.post('/urlLink/requestVerifyPKCS7SignedData', param)
				.then(response => {
					me.listBoxLoading = false;
					console.log(response);
					me.$alert('서명이 완료되었습니다.');
					me.onSearch(true);
				})
				.catch(function(error) {
					me.listBoxLoading = false;
					me.$alert('서명 중 오류가 발생하였습니다.');
					console.log('========error====================');
					console.log(error);
				});
		},
		error(err) {
			console.log(err);
		},
	},
	computed: {
		...mapGetters('LinkViewStore', {
			decParams: 'decParams',
		}),
		...mapGetters('CmsStore', {
			cmsInfo: 'contractInfo',
		}),
		/*
		isShowReject() {
			return this.isReject;
		},
		*/
		isShowSign() {
			if (this.requestInfo.progrsSttusCd == '10' && !this.otisPage) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('iui-content-box',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"header-right",fn:function(){return [(_vm.allowAdd)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnAdd_click}},[_vm._v(_vm._s(_vm.addButtonText))]):_vm._e(),(_vm.allowDelete)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnDelete_click}},[_vm._v(_vm._s(_vm.deleteButtonText))]):_vm._e(),(_vm.saveButtonVisible)?_c('iui-button',{attrs:{"enable":_vm.buttonEnable},on:{"click":_vm.btnSave_click}},[_vm._v(" "+_vm._s(_vm.saveButtonText)+" ")]):_vm._e()]},proxy:true}])},[_c('file-upload',{ref:"upload",staticStyle:{"display":"none"},attrs:{"headers":_vm.postHeader,"post-action":_vm.internalPostAction,"name":"files","drop":_vm.allowAdd,"multiple":false},on:{"input":_vm.fileUpload_input,"input-filter":_vm.fileUpload_inputFilter,"input-file":_vm.fileUpload_inputFile},model:{value:(_vm.internalFiles),callback:function ($$v) {_vm.internalFiles=$$v},expression:"internalFiles"}}),_c('img',{style:({
      display: 'block',
      margin: '1px auto',
      width: _vm.imageWidth,
      height: _vm.imageHeight,
      maxWidth: _vm.imageMaxWidth,
      maxHeight: _vm.imageMaxHeight,
    }),attrs:{"src":_vm.imageSource}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <pmis-page ref="page" :search-project-visible="false">
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-button value="INIT" @click="onInit" />
        <iui-button value="SET DATA" @click="onSetData" />
        <iui-button value="VALID" @click="onValid" />
        &nbsp;|| IS-VALID :: {{ isValid }}
      </template>
      <iui-container type="table" headerWidth="120px">
        <i-row>
          <i-col-header>searchable-single</i-col-header>
          <i-col>
            <iui-multiselect
              :multiple="false"
              :options="projectList1"
              :value="projectSelectedList1"
              :searchable="true"
              @change="onChange1"
            />
          </i-col>
          <i-col-header>required-single</i-col-header>
          <i-col>
            <iui-multiselect
              :multiple="false"
              :options="projectList2"
              :value="projectSelectedList2"
              @change="onChange2"
              required
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>searchable-multi</i-col-header>
          <i-col>
            <iui-multiselect
              :options="projectList3"
              :value="projectSelectedList3"
              :selectLabel="'선택'"
              :selectedLabel="'선택됨'"
              :deselectLabel="'해제'"
              :searchable="true"
              @change="onChange3"
            />
          </i-col>
          <i-col-header>required-multi</i-col-header>
          <i-col>
            <iui-multiselect
              :options="projectList4"
              :value="projectSelectedList4"
              :selectLabel="'선택'"
              :selectedLabel="'선택됨'"
              :deselectLabel="'해제'"
              @change="onChange4"
              required
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>등록자</i-col-header>
          <i-col>AAAAA</i-col>
          <i-col-header>조회수</i-col-header>
          <i-col>BBBBB</i-col>
        </i-row>
        <i-row height="300px">
          <i-col colspan="2" align="center">
            <code>{{ projectSelectedList1 }}</code>
          </i-col>
          <i-col colspan="2" align="center">
            <code>{{ projectSelectedList2 }}</code>
          </i-col>
        </i-row>
        <i-row height="300px">
          <i-col colspan="2" align="center">
            <code>{{ projectSelectedList3 }}</code>
          </i-col>
          <i-col colspan="2" align="center">
            <code>{{ projectSelectedList4 }}</code>
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      projectSelectedList1: {},
      projectSelectedList2: {},
      projectSelectedList3: {},
      projectSelectedList4: {},
      projectList1: [
        {text: '현장 00', value: '202000', $isDisabled: true},
        {text: '현장 01', value: '202001'},
        {text: '현장 02', value: '202002'},
        {text: '현장 03', value: '202003'},
        {text: '현장 04', value: '202004'},
      ],
      projectList2: [
        {text: '현장 00', value: '202000', $isDisabled: true},
        {text: '현장 01', value: '202001'},
        {text: '현장 02', value: '202002'},
        {text: '현장 03', value: '202003'},
        {text: '현장 04', value: '202004'},
      ],
      projectList3: [
        {text: '현장 00', value: '202000', $isDisabled: true},
        {text: '현장 01', value: '202001'},
        {text: '현장 02', value: '202002'},
        {text: '현장 03', value: '202003'},
        {text: '현장 04', value: '202004'},
      ],
      projectList4: [
        {text: '현장 00', value: '202000', $isDisabled: true},
        {text: '현장 01', value: '202001'},
        {text: '현장 02', value: '202002'},
        {text: '현장 03', value: '202003'},
        {text: '현장 04', value: '202004'},
      ],
      isValid: false,
    };
  },
  methods: {
    onInit() {
      this.projectSelectedList1 = {};
      this.projectSelectedList2 = {};
      this.projectSelectedList3 = {};
      this.projectSelectedList4 = {};

      this.projectSelectedList1 = [];
      this.projectSelectedList2 = [];
      this.projectSelectedList3 = [];
      this.projectSelectedList4 = [];
    },
    onSetData() {
      this.projectSelectedList1 = {text: '현장 04', value: '202004'};
      this.projectSelectedList2 = {text: '현장 03', value: '202003'};
      this.projectSelectedList3 = [
        {text: '현장 00', value: '202000', $isDisabled: true},
        {text: '현장 03', value: '202003', $isDisabled: true},
      ];
      this.projectSelectedList4 = [
        {text: '현장 01', value: '202001', $isDisabled: true},
        {text: '현장 04', value: '202004', $isDisabled: true},
      ];
    },
    async onValid() {
      let validation = await this.$validator.validate(this);
      this.isValid = validation.isValid;
    },
    onChange1(e) {
      this.projectSelectedList1 = e;
    },
    onChange2(e) {
      this.projectSelectedList2 = e;
    },
    onChange3(e) {
      this.projectSelectedList3 = e;
    },
    onChange4(e) {
      this.projectSelectedList4 = e;
    },
  },
};
</script>

<style></style>

<template>
  <div v-show="visible">
    <span v-for="(item, index) in internalItems" v-bind:key="index" class="_chkWrap">
      <input
        v-if="type === 'checkbox'"
        type="checkbox"
        v-model="vItem"
        :id="`${checkboxId}_${index}`"
        :name="name"
        :value="item[internalItemValueAlias]"
        @click="click"
        @change="change"
        :class="{_chk_target_: true}"
        :disabled="!enable"
        :readonly="readonly"
      />
      <label
        :id="`${labelId}_${index}`"
        :for="`${checkboxId}_${index}`"
        :class="{required: required, 'is-valid-fail': !isValid}"
      >
        <!-- 2020.11.09 선영욱 HTML의 Checkbox를 그대로 사용하기 위해 아래 <span></span> 주석 -->
        <span></span>
        {{ item[internalItemTextAlias] ? item[internalItemTextAlias] : '' }}
      </label>
    </span>
  </div>
</template>

<script>
// import {setTimeout} from 'timers';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  name: 'iui-checkbox-group-old',
  mixins: [IuiBaseMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    label: {
      type: String,
    },
    formObj: {
      type: String,
      default: 'none!',
    },
    checkedValues: {
      type: Array,
    },
    value: {
      type: [Array, Number, String],
    },
    // bindArray: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   },
    // },
  },
  data: function() {
    return {
      vItem: [],
    };
  },
  computed: {
    checkboxId: {
      get() {
        return this.getId('checkbox');
      },
    },
    labelId: {
      get() {
        return this.getId('label');
      },
    },
  },
  watch: {
    vItem: function(item) {
      this.setBindArray(Array.from(item));
      // this.setFormData(Array.from(item));
      this.$emit('update:value', item);
    },
    // value: function(newValue, oldValue) {
    //   this.vItem = newValue;
    // },
    checkedValues: function(values) {
      this.vItem = [];
      for (let v of Array.from(values)) {
        for (let e of Array.from(this.$el.querySelectorAll('input'))) {
          if (v == e.value) {
            this.vItem = [...this.vItem, v];
          }
        }
      }
    },
  },
  created() {
    if (this.itemKeyAlias === undefined && this.internalItemTextAlias === 'text') {
      this.internalItemTextAlias = 'label';
    }

    // this.vData = this.value;

    this.setBindArray();

    // validator 설정
    this.validatorTargetDataProp = 'vItem.length';
    this.validationTriggerEventName = ['change'];
  },
  async mounted() {
    // this.setFormData();

    if (this.validator !== null) {
      this.validator.addCustomValidator(function() {
        const checkedCount = this.targetComponent.$el.querySelectorAll('input[type=checkbox]:checked').length;
        return checkedCount > 0;
      });
    }

    await this.$nextTick();

    //속성 주입
    // let cnt = 0;
    // for (let k in this.$attrs) {
    //   for (let e of Array.from(this.$el.querySelectorAll('span._chkWrap'))) {
    //     e.querySelector('input[type=checkbox]').setAttribute(k, this.$attrs[k]);
    //     // if (k == 'name' && e.querySelector('label')) {
    //     //   e.querySelector('input[type=checkbox]').setAttribute('id', this.$attrs[k] + '_' + cnt);
    //     //   e.querySelector('label').setAttribute('for', this.$attrs[k] + '_' + cnt);
    //     //   cnt++;
    //     // }
    //   }

    //   this.$el.querySelector('._chk_target_').setAttribute(k, this.$attrs[k]);
    //   this.$el.removeAttribute(k);
    // }

    //이벤트 주입
    for (let e of this.event) {
      for (let el of Array.from(this.$el.querySelectorAll('span'))) {
        el.querySelector('input[type=checkbox]').addEventListener(e.name, e.callback);
      }
    }

    //스타일 주입
    // for (let k in this.css) {
    //   for (let e of Array.from(this.$el.querySelectorAll('span'))) {
    //     e.querySelector('input[type=checkbox]').style[k] = this.css[k];
    //   }
    // }

    // if (
    //   this.$el.querySelectorAll('._chk_target_') &&
    //   this.$el.querySelectorAll('._chk_target_')[0] &&
    //   this.$el.querySelectorAll('._chk_target_')[0].hasOwnProperty('name')
    // ) {
    //   this.name = this.$el.querySelectorAll('._chk_target_')[0].name;
    // }

    //기본요소 구분하기 위한 class명 삭제
    // this.$el.querySelector('._chk_target_').classList.remove('_chk_target_');

    if (this.checkedValues && !this.vItem.length) {
      for (let v of this.checkedValues) {
        for (let e of Array.from(this.$el.querySelectorAll('input'))) {
          if (v == e.value) {
            this.vItem = [...this.vItem, v];
          }
        }
      }
    }
  },
  methods: {
    // setFormData(value) {
    //   const me = this;
    //   setTimeout(function() {
    //     me.$store.dispatch('setFormData', {
    //       formObj: me.formObj,
    //       key: me.name,
    //       value: value,
    //       element: Array.from(me.$el.querySelectorAll('._chk_target_')),
    //     });
    //   }, 10);
    // },
    click: function(e) {
      this.$emit('click', e);
    },
    change: function(e) {
      this.$emit('change', e);
    },
    setBindArray(values) {
      const me = this;
      if (typeof values != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = values.join(',');
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
  },
};
</script>

<style scoped>
.required {
  border-bottom: 1px solid pink;
}

.is-valid-fail {
  border-bottom: 1px solid red;
}
</style>

<template>
  <pmis-content-box style="height:300px">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header colspan="2">고객명</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.tradeName" :enable="false" />
              </i-col>
              <i-col-header colspan="2">현장명(건물명)</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mainName" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약일자</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.contDate" :enable="false" />
              </i-col>
              <i-col-header colspan="2">소재지</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.addr" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약기간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.contDate" suffix="~" :enable="false" />
                <iui-datepicker-old :value="contractInfo.buildEndDate" :enable="false" />
              </i-col>
              <i-col-header colspan="2">지급방식</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.suFlagA7" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">준공일자</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.buildEndDate" :enable="false" />
              </i-col>
              <i-col-header rowspan="3">통지방식</i-col-header>
              <i-col-header>우편</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.tradeAddr" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="3">계약금액</i-col-header>
              <i-col-header>공급가액</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.biddingAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.biddingAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
              <i-col-header>Email</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.tradeEmail" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>부가가치세</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.biddingVatAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.biddingVatAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
              <i-col-header>SMS</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.tradeMphone" :enable="false" width="100px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>합계금액</i-col-header>
              <i-col colspan="4">
                <span>
                  <iui-text :value="contractInfo.totalBiddingAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.totalBiddingAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'TContractTabContractInfo_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      console.log('TContractTabContractInfo_Load');
    },
  },
  computed: {},
};
</script>

<style></style>

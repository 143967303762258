<template>
  <pmis-content-box>
    <iui-container type="table" :headerWidth="width100" style="height:100%">
      <i-row>
        <i-col>
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            >
            </iframe>
          </div>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    //OZ Viewer 에서 message 를 호출.
    window.addEventListener('message', this.reqSetOzParam, false);

    //OZ Reload
    //document.getElementById('ozFrame').contentWindow.location.reload(true);
  },
  data() {
    return {
      listBoxLoading: false,
      urlParam: `${process.env.VUE_APP_OZ_VIEWER_URL}`,
    };
  },
  methods: {
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.paramObj.OZParam);

        //console.log('--->', document.getElementById('ozFrame').contentWindow);

        //document.getElementById('ozFrame').receiveOzParam( this.OZParam );
        // setTimeout(() => {

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.paramObj.OZParam);

        //Event Listener 를 제거.
        // //document.getElementById('ozFrame').contentWindow.postMessage({msg: 'receiveMessage', ozdata: this.OZParam}, '*');
        window.removeEventListener('message', this.reqSetOzParam, false);
        // }, 3000);
      }
    },
  },
};
</script>

<style></style>

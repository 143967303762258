<template>
  <iui-content-box
    :title-type="titleType"
    :border-box="borderBox"
    :no-border-box="noBorderBox"
    :no-padding-box="noPaddingBox"
    :loading="loading"
  >
    <template v-slot:title>
      <slot name="title" />
    </template>
    <template v-slot:title-left>
      <slot name="title-left" />
    </template>
    <template v-slot:title-right>
      <slot name="title-right" />
    </template>
    <slot />
  </iui-content-box>
</template>

<script>
export default {
  props: {
    borderBox: {
      type: Boolean,
      default: true,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    titleType: {
      type: String,
      default: 'tab',
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

<template>
  <pmis-tab-box>
    <template v-slot:title>리스트</template>
    <iui-container type="jqgrid" style="1px solid red" :id="sheetId" />
  </pmis-tab-box>
</template>

<script>
export default {
  data: function() {
    return {
      sheetId: 'sheet' + this._uid,
      searchInfo: {
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
        status: '000001',
        prjNm: '',
      },
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },

  created() {
    this.addEvent([{name: 'onSearch', func: this.onSearch}]);
  },
  mounted: function() {
    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 200,
      width: 800,
      autowidth: false,
      shrinkToFit: false,
      selectionMode: 'singlerow',
      colModel: [
        {name: 'teamNode', label: 'teamNode', width: 200},
        {name: 'teamNodeString', label: 'teamNodeString', width: 200},
        {name: 'teamNodeLevel', label: 'teamNodeLevel', width: 200},
        {name: 'sortCode', label: 'sortCode', width: 200},
        {name: 'teamNo', label: 'teamNo', width: 200},
        {name: 'teamNoDesc', label: 'teamNoDesc', width: 200},
        {name: 'departNo', label: 'departNo', width: 200},
        {name: 'humanDepartCode', label: 'humanDepartCode', width: 200},
        {name: 'hostTeam', label: 'hostTeam', width: 200},
        {name: 'bsTeamCode', label: 'bsTeamCode', width: 200},
        {name: 'instFlag', label: 'instFlag', width: 200},
        {name: 'marketFlag', label: 'marketFlag', width: 200},
        {name: 'departFlag', label: 'departFlag', width: 200},
        {name: 'deptCat', label: 'deptCat', width: 200},
        {name: 'leaderEmpId', label: 'leaderEmpId', width: 200},
        {name: 'useFlag', label: 'useFlag', width: 200},
        {name: 'applydate', label: 'applydate', width: 200},
        {name: 'deptZipCode', label: 'deptZipCode', width: 200},
        {name: 'deptAddr', label: 'deptAddr', width: 200},
        {name: 'deptTel', label: 'deptTel', width: 200},
        {name: 'deptFax', label: 'deptFax', width: 200},
        {name: 'remark', label: 'remark', width: 200},
        {name: 'transTeamNo', label: 'transTeamNo', width: 200},
        {name: 'inputdate', label: 'inputdate', width: 200},
        {name: 'updatedate', label: 'updatedate', width: 200},
        {name: 'unitTeamNo', label: 'unitTeamNo', width: 200},
        {name: 'coworkGroup', label: 'coworkGroup', width: 200},
        {name: 'osTradeNo', label: 'osTradeNo', width: 200},
        {name: 'subleaderEmpId', label: 'subleaderEmpId', width: 200},
        {name: 'businessNo', label: 'businessNo', width: 200},
        {name: 'licenseNo', label: 'licenseNo', width: 200},
        {name: 'cnstrcNo', label: 'cnstrcNo', width: 200},
        {name: 'govregNo', label: 'govregNo', width: 200},
        {name: 'useheadqteNo', label: 'useheadqteNo', width: 200},
      ],
    });
    $gridAutoResize($grid);
    me.onSearch();
  },
  methods: {
    async onSearch() {
      const me = this;
      axios.post('/common/popup/selectDeptList', this.searchInfo).then(function(response) {
        var grid = $('#' + me.sheetId);
        grid.jqGrid('clearGridData');
        grid.jqGrid('setGridParam', {data: response.data}).trigger('reloadGrid');
      });
    },
    onDataLoad(/*e*/) {
      // Grid Col 속성 설정
    },
  },
};
</script>

<template>
  <pmis-page
    ref="page"
    :searchProjectVisible="false"
    :searchCompanyVisible="false"
    @search-click="onSearch"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <pmis-content-box>
      <template v-slot:title>
        상세정보
      </template>
      <template v-slot:title-right>
        <iui-button value="목록" @click="onGoList" />
      </template>
      <iui-container type="css-flex">
        <i-row height="180px">
          <i-col>
            <iui-container type="table">
              <colgroup>
                <col width="90px" />
                <col width="310px" />
                <col width="90px" />
                <col width="310px" />
              </colgroup>
              <i-row>
                <i-col-header>
                  구분
                </i-col-header>
                <i-col colspan="3">
                  <iui-text width="100px" :enable="false" :value="natDsName" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  제목
                </i-col-header>
                <i-col colspan="3">
                  <iui-text :value.sync="detailInfo.tl" :enable="$route.params.empNo == userInfo.empNo" />
                </i-col>
              </i-row>
              <i-row v-if="this.params.natDs == 'NOTI'">
                <i-col-header>
                  열람대상
                </i-col-header>
                <i-col>
                  <iui-radio-group-old
                    name="rngDs"
                    p-code="BOARD_NOTI_TGT"
                    :value="detailInfo.rngDs"
                    :enable="$route.params.empNo == userInfo.empNo"
                    @change="onSetValue($event, 'rngDs')"
                  />
                </i-col>
                <i-col-header width="90px">
                  조회수
                </i-col-header>
                <i-col width="90px">
                  {{ detailInfo.inqCnt }}
                </i-col>
              </i-row>
              <i-row v-if="this.params.natDs == 'NOTI'">
                <i-col-header>
                  게시기간
                </i-col-header>
                <i-col>
                  <iui-datepicker-old
                    :value.sync="detailInfo.natStDate"
                    :enable="$route.params.empNo == userInfo.empNo"
                  />
                  <label>-</label>
                  <iui-datepicker-old
                    :value.sync="detailInfo.natEnDate"
                    :enable="$route.params.empNo == userInfo.empNo"
                  />
                </i-col>
                <i-col-header width="90px">
                  첨부파일
                </i-col-header>
                <i-col width="90px">
                  {{ fileUpDown_files.lclFlNm }}
                  <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
                  <iui-button
                    @click="fileUpDown_add = true"
                    v-if="$route.params.empNo == userInfo.empNo && !fileUpDown_files.lclFlNm"
                    >추가</iui-button
                  >
                  <!--20211201 lgksja !fileUpDown_files.lclFlNm 추가 어짜피 한줄뿐이 안나옴 업로드는 되나-->
                  <iui-button
                    @click="fileUpDown_delete = true"
                    v-if="
                      $route.params.empNo == userInfo.empNo && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm
                    "
                    >삭제</iui-button
                  >
                  <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
                  <iui-button
                    @click="fileUpDown_download = true"
                    v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
                    >다운</iui-button
                  >
                  <file-up-down
                    company-code="201900"
                    v-model="fileUpDown_files"
                    :file-number.sync="detailInfo.flNo"
                    add-button-text="파일추가"
                    delete-button-text="파일삭제"
                    save-button-text="파일저장"
                    download-button-text="파일다운"
                    :add.sync="fileUpDown_add"
                    :changed.sync="fileUpDown_changed"
                    :delete.sync="fileUpDown_delete"
                    :save.sync="fileUpDown_save"
                    :search.sync="fileUpDown_search"
                    :download.sync="fileUpDown_download"
                    @save-complete="file_save_complete"
                    @save-error="file_save_error"
                    @delete-complete="file_delete_complete"
                    @delete-error="file_delete_error"
                  />
                </i-col>
              </i-row>
              <i-row v-if="this.params.natDs == 'NOTI'">
                <i-col-header>
                  등록자
                </i-col-header>
                <i-col>
                  <iui-text width="100px" :value.sync="detailInfo.rgprNm" :enable="false" />
                </i-col>
                <i-col-header width="90px">
                  등록일시
                </i-col-header>
                <i-col width="90px">
                  <iui-text width="120px" :value.sync="detailInfo.rgsDttm" :enable="false" />
                </i-col>
              </i-row>
              <i-row v-if="this.params.natDs != 'NOTI'">
                <i-col-header>
                  등록자
                </i-col-header>
                <i-col>
                  <iui-text width="100px" :value.sync="detailInfo.rgprNm" :enable="false" />
                </i-col>
                <i-col-header width="90px">
                  조회수
                </i-col-header>
                <i-col width="90px">
                  {{ detailInfo.inqCnt }}
                </i-col>
              </i-row>
              <i-row v-if="this.params.natDs != 'NOTI'">
                <i-col-header>
                  등록일시
                </i-col-header>
                <i-col>
                  <iui-text width="120px" :value.sync="detailInfo.rgsDttm" :enable="false" />
                </i-col>
                <i-col-header width="90px">
                  첨부파일
                </i-col-header>
                <i-col width="90px">
                  {{ fileUpDown_files.lclFlNm }}
                  <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
                  <iui-button @click="fileUpDown_add = true" v-if="$route.params.empNo == userInfo.empNo"
                    >추가</iui-button
                  >
                  <iui-button
                    @click="fileUpDown_delete = true"
                    v-if="
                      $route.params.empNo == userInfo.empNo && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm
                    "
                    >삭제</iui-button
                  >
                  <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
                  <iui-button
                    @click="fileUpDown_download = true"
                    v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
                    >다운</iui-button
                  >
                  <file-up-down
                    company-code="201900"
                    v-model="fileUpDown_files"
                    :file-number.sync="detailInfo.flNo"
                    add-button-text="파일추가"
                    delete-button-text="파일삭제"
                    save-button-text="파일저장"
                    download-button-text="파일다운"
                    :add.sync="fileUpDown_add"
                    :changed.sync="fileUpDown_changed"
                    :delete.sync="fileUpDown_delete"
                    :save.sync="fileUpDown_save"
                    :search.sync="fileUpDown_search"
                    :download.sync="fileUpDown_download"
                    @save-complete="file_save_complete"
                    @save-error="file_save_error"
                    @delete-complete="file_delete_complete"
                    @delete-error="file_delete_error"
                  />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
        <i-row height="300px" style="overflow: auto;">
          <i-col style="text-align:left;">
            <Editor
              v-if="$route.params.empNo == userInfo.empNo"
              initialEditType="wysiwyg"
              :initialValue="initialValue"
              :key="editorKey"
              height="290px"
              ref="Editor"
              :isViewer="true"
            />
            <Viewer v-else :initialValue="initialValue" ref="Viewer" height="100%" :key="editorKey" />
          </i-col>
        </i-row>
        <i-spacer />
        <i-row height="300px" style="overflow: auto;" v-if="this.boardStatus == 'E'">
          <i-col>
            <BoardReply :paramObj="paramObj" />
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';
import BoardReply from './BoardReply';
import FileUpDown from '@/view/sample/FileUpDown';

export default {
  components: {
    Editor,
    Viewer,
    BoardReply,
    FileUpDown,
  },

  data() {
    return {
      detailInfo: {
        natNo: 0,
        natDs: this.$route.params.natDs,
        tl: '',
        rngDs: 'ALL',
        files: 0,
        natStDate: '',
        natEnDate: '',
        cts: '',
        rgprNm: '',
        rgsDttm: '',
        flNo: 0,
        inqCnt: 0,
        tskDs: '',
        cfmDs: '',
        cfmDsNm: '',
        prcDs: '',
        prcDsNm: '',
        svLctNo: 0,
      },
      keyInfo: {},
      routeName: this.$route.params.routeName,
      paramObj: {},
      boardStatus: '',
      initialValue: '',
      editorKey: 0,

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },

  computed: {
    natDsName() {
      let value;
      switch (this.params.natDs) {
        case 'NOTI':
          value = '공지사항';
          break;
        case 'FAQ':
          value = 'FAQ';
          break;
        case 'FILE':
          value = '자료실';
          break;

        default:
          value = '공지사항';
          break;
      }

      return value;
    },

    ...mapGetters('menu', {
      menu: 'menu',
    }),
  },

  created() {
    this.params = {...this.$route.params};
  },

  activated() {
    this.initData();
    this.boardStatus = this.params.boardStatus;

    if (this.boardStatus == 'E') {
      this.onSearch();
    }
  },

  mounted() {
    //20211201 lgksja  $route.params.empNo->params.empNo 11개변경 해야 함 나중에 안된다고 하면 오류인데 왜 아무말들이 없지 ㅋㅋ
    //console.log('this.params.empNo', this.params.empNo);
    //console.log('this.$route.params.empNo', this.$route.params.empNo);
    //console.log('this.userInfo.empNo', this.userInfo.empNo);
  },

  methods: {
    initData() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.keyInfo.pgmCd = this.params.pgmCd || this.userInfo.pgmCd;
      this.keyInfo.natNo = this.params.natNo;
    },
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.detailInfo[key] = value;
    },
    async onSave() {
      if (this.params.empNo == this.userInfo.empNo) {
        this.detailInfo.cts = this.$refs.Editor.invoke('getHtml');
      }

      if (this.detailInfo.tl.trim().length == 0) {
        this.$alert('게시물의 제목을 입력해야 합니다.');
        return;
      }

      if (this.detailInfo.cts.trim().length == 0) {
        this.$alert('게시물의 내용을 입력해야 합니다.');
        return;
      }

      // console.log('this.detailInfo.cts before', this.detailInfo.cts);
      this.detailInfo.cts = this.detailInfo.cts.replace('<br data-tomark-pass="">', '');
      // console.log('this.detailInfo.cts after', this.detailInfo.cts);

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
        setTimeout(() => {
          this.fileUpDown_save = true;
        }, 300);
        return;
      } else {
        let param = {
          ...this.detailInfo,
          pgmCd: this.userInfo.pgmCd,
          cud: this.boardStatus == 'E' ? 2 : 1,
        };

        axios.post('/board/saveBoardDetailInfo', param).then(response => {
          this.boardStatus = 'E';
          this.keyInfo.natNo = response.data;
          this.$alert('저장되었습니다.');
          this.onSearch();
        });
      }
    },
    async onSearch() {
      let response = await axios.post('/board/selectBoardDetailInfo', this.keyInfo);
      for (let key in this.detailInfo) {
        if (
          response.data.boardInfo !== undefined &&
          response.data.boardInfo != null &&
          response.data.boardInfo.hasOwnProperty(key)
        ) {
          this.detailInfo[key] = response.data.boardInfo[key];
        }
      }

      this.initialValue = this.detailInfo['cts'];
      this.editorKey += 1;

      if (response.data.boardInfo !== undefined && response.data.boardInfo != null) {
        this.callEvent({
          name: 'BoardReplyPopup_search',
          param: {pgmCd: this.keyInfo.pgmCd, uppNatNo: this.keyInfo.natNo, rgprId: response.data.boardInfo['rgprId']},
        });
      }
    },
    async onDelete() {
      if (this.boardStatus != 'E') {
        this.$alert('조회 상태에서만 게시물을 삭제할 수 있습니다.');
        return;
      }

      if (!$isAdmin()) {
        if (this.params.empNo != this.userInfo.empNo) {
          this.$alert('작성자만 게시물을 삭제할 수 있습니다.');
          return;
        }
      }

      if (!(await this.$confirm('게시물을 삭제 하시겠습니까?'))) return;

      let param = {
        ...this.detailInfo,
        pgmCd: this.userInfo.pgmCd,
      };

      let response = await axios.post('/board/deleteBoardDetailInfo', param).catch(e => {
        console.error('삭제를 실패했습니다 ::', e);
      });
      if (response.status == 200) {
        this.onGoList();
      } else {
        this.$alert('삭제를 실패했습니다.');
      }
    },
    onGoList() {
      this.$router.go(-1);
    },
    file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.detailInfo.flNo = 0;
      } else {
        this.detailInfo.flNo = this.fileUpDown_files.flNo;
      }

      let param = {
        ...this.detailInfo,
        pgmCd: this.userInfo.pgmCd,
        cud: this.boardStatus == 'E' ? 2 : 1,
      };

      axios.post('/board/saveBoardDetailInfo', param).then(response => {
        this.boardStatus = 'E';
        this.keyInfo.natNo = response.data;
        this.$alert('저장되었습니다.');
        this.onSearch();
      });
    },
    file_save_error() {
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      console.log('파일 삭제가 완료되었습니다.');
    },
    file_delete_error() {
      console.log('파일 삭제를 실패했습니다.');
    },
  },
};
</script>

<style></style>

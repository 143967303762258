<template>
  <div v-show="visible">
    <!-- <button @click="onClick" :disabled="!enable">사진등록</button>
    <input
      id="file"
      type="file"
      name="name"
      style="display: none;"
      v-on:change="onChange"
      :disabled="!enable"
      :readonly="readonly"
    /> -->
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
// import rootStore from '@/store';

export default {
  name: 'iui-file',
  mixins: [IuiBaseMixin],
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    // bindArray: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   },
    // },
  },
  // created: function() {
  //   this.setBindArray();
  // },
  // mounted: function() {},
  // data: function() {
  //   return {
  //     vdata: this.value || '',
  //   };
  // },
  // methods: {
  //   onClick: function() {
  //     document.getElementById('file').click();
  //   },
  //   onChange: function(e) {
  //     this.setBindArray(e.target.value);
  //   },
  //   setBindArray: function(value) {
  //     const me = this;
  //     if (typeof value != 'undefined') {
  //       console.log(1);
  //       me.bindArray.forEach(function(o) {
  //         if (me[o] && me.name) {
  //           me[o][me.name] = value;
  //         }
  //       });
  //     } else {
  //       console.log(2);
  //       me.bindArray.forEach(function(o) {
  //         me[o] = rootStore.getters[rootStore.getters.currentUrl + '/' + o];
  //       });
  //     }
  //   },
  // },
  // watch: {
  //   value: function(value) {
  //     console.log(value);
  //   },
  // },
};
</script>

<style></style>

<script>
import {defineComponent, reactive, computed, watch} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {PrefixProps, usePrefix} from '@/components/Iui/composables/usePrefix';
import {SuffixProps, useSuffix} from '@/components/Iui/composables/useSuffix';
import {ItemsProps, useItems} from '@/components/Iui/composables/useItems';

// import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
// import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
// import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
// import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
// import { IuiValidatorMixin } from '@/plugins/IuiValidator';

export default defineComponent({
  name: 'IuiSelect',
  // mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...PrefixProps,
    ...SuffixProps,
    ...ItemsProps,

    modelValue: {
      type: [String, Number, Array],
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: undefined,
    },
    placeholder: {
      type: [String, Object],
      default: undefined,
    },
  },
  // data() {
  //   return {
  //     internalValue: undefined,
  //     internalPlaceHolder: undefined,
  //   };
  // },
  // computed: {
  // id
  // comboBoxId: {
  //   get() {
  //     return this.getId('select');
  //   },
  // },

  // class
  // rootClass: {
  //   get() {
  //     let obj = {
  //       'iui-combobox': true,
  //       'iui-layout-debug': this.layoutDebug,
  //     };

  //     return obj;
  //   },
  // },
  // comboBoxClass: {
  //   get() {
  //     let obj = {
  //       // _target_: true,
  //       combobox: true,
  //       required: this.required,
  //       'is-valid-fail': !this.isValid,
  //     };

  //     return obj;
  //   },
  // },

  // style
  // rootStyle: {
  //   get() {
  //     let obj = {
  //       // width: '100%',
  //       width: undefined,
  //       height: undefined,
  //       flex: undefined,
  //     };

  //     if (this.width !== undefined) {
  //       obj.width = this.width;
  //       obj.flex = `0 0 this.width`;
  //     }
  //     if (this.height !== undefined) {
  //       obj.height = this.height;
  //     }

  //     return obj;
  //   },
  // },
  // comboBoxStyle: {
  //   get() {
  //     let obj = {
  //       'min-width': undefined,
  //       height: undefined,
  //     };

  //     if (this.width !== undefined) {
  //       obj['min-width'] = 'unset';
  //     }

  //     if (this.size !== undefined) {
  //       obj['height'] = 'unset';
  //     }

  //     return obj;
  //   },
  // },

  // computed
  // hasPlaceHolder: {
  //   get() {
  //     return this.internalPlaceHolder !== undefined;
  //   },
  // },
  // },
  // watch: {
  //   value(newValue) {
  //     if (this.multiple) {
  //       if (Array.isArray(newValue)) {
  //         this.internalValue = newValue;
  //       } else {
  //         this.internalValue = [newValue];
  //       }
  //     } else {
  //       if (Array.isArray(newValue)) {
  //         if (newValue.length > 0) {
  //           this.internalValue = newValue[0];
  //         }
  //       } else {
  //         this.internalValue = newValue;
  //       }
  //     }
  //   },
  // },
  // created() {
  //   if (this.multiple) {
  //     if (Array.isArray(this.value)) {
  //       this.internalValue = this.value;
  //     } else {
  //       this.internalValue = [this.value];
  //     }
  //   } else {
  //     if (Array.isArray(this.value)) {
  //       if (this.value.length > 0) {
  //         this.internalValue = this.value[0];
  //       }
  //     } else {
  //       this.internalValue = this.value;
  //     }
  //   }

  //   if (this.placeholder !== undefined) {
  //     if (typeof this.placeholder === 'string') {
  //       this.internalPlaceHolder = {
  //         [this.internalItemTextAlias]: this.placeholder,
  //         [this.internalItemValueAlias]: undefined,
  //       };
  //     } else {
  //       this.internalPlaceHolder = this.placeholder;
  //     }
  //   }

  //   // validator 설정
  //   this.validatorTargetDataProp = 'internalValue';
  //   this.validationTriggerEventName = ['change'];
  // },
  // methods: {
  //   change(e) {
  //     this.emitUpdateValue();
  //     this.$emit('change', e);
  //   },
  //   click(e) {
  //     this.$emit('click', e);
  //   },
  //   emitUpdateValue() {
  //     this.$emit('update:value', this.internalValue);
  //   },
  // },

  setup(props, context) {
    const Base = useBase(props, context, 'iui-select');
    const Layout = useLayout(props, context, 'iui-select');
    const Label = useLabel(props, context, Base);
    const Prefix = usePrefix(props, context, Base);
    const Suffix = useSuffix(props, context, Base);
    const Items = useItems(props, context);

    const selectId = computed(() => Base.getId('select'));
    const selectClass = computed(() => {
      return {
        select: true,
      };
    });
    const selectStyle = computed(() => {
      return {};
    });

    const hasPlaceHolder = computed(() => state.placeHolder !== undefined);

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        console.log(newValue, oldValue);
        if (props.multiple) {
          if (Array.isArray(newValue)) {
            state.modelValue = newValue;
          } else {
            state.modelValue = [newValue];
          }
        } else {
          if (Array.isArray(newValue)) {
            if (newValue.length > 0) {
              state.modelValue = newValue[0];
            }
          } else {
            state.modelValue = newValue;
          }
        }
      }
    );

    const state = reactive({
      modelValue: undefined,
      placeHolder: undefined,
    });

    const method = {
      select_change(e) {
        context.emit('update:model-value', state.modelValue);
        context.emit('change', e);
      },

      select_click(e) {
        context.emit('click', e);
      },
    };

    if (props.multiple) {
      if (Array.isArray(props.modelValue)) {
        state.modelValue = props.modelValue;
      } else {
        state.modelValue = [props.modelValue];
      }
    } else {
      if (Array.isArray(props.modelValue)) {
        if (props.modelValue.length > 0) {
          state.modelValue = props.modelValue[0];
        }
      } else {
        state.modelValue = props.modelValue;
      }
    }

    if (props.placeholder !== undefined) {
      if (typeof props.placeholder === 'string') {
        state.placeHolder = {
          [props.itemTextAlias]: props.placeholder,
          [props.itemValueAlias]: undefined,
        };
      } else {
        state.placeHolder = props.placeholder;
      }
    }

    // validator 설정
    // this.validatorTargetDataProp = 'internalValue';
    // this.validationTriggerEventName = ['change'];

    return {
      Base,
      Layout,
      // ...Items,
      Label,
      Prefix,
      Suffix,
      selectId,
      selectClass,
      selectStyle,
      hasPlaceHolder,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style">
    <label
      v-if="Label.state.has"
      :id="Label.state.id"
      ref="label"
      :for="selectId"
      class="label"
      :style="Label.state.style"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <label
      v-if="Prefix.state.has"
      :id="Prefix.state.id"
      ref="prefix"
      :for="selectId"
      class="prefix"
      :style="Prefix.state.style"
    >
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </label>
    <select
      :id="selectId"
      ref="select"
      v-model="state.modelValue"
      :size="size"
      :multiple="multiple"
      :class="selectClass"
      :style="selectStyle"
      :readonly="Base.state.readonly"
      :disabled="!Base.state.enable"
      :name="name"
      @click="select_click"
      @change="select_change"
    >
      <option v-if="hasPlaceHolder" :value="state.placeHolder[itemValueAlias]">
        {{ state.placeHolder[itemTextAlias] }}
      </option>
      <option
        v-for="item in items"
        :key="item[itemKeyAlias]"
        :value="item[itemValueAlias]"
        :disabled="item[itemEnableAlias] !== undefined && !item[itemEnableAlias]"
      >
        {{ item[itemTextAlias] }}
      </option>
    </select>
    <label
      v-if="Suffix.state.has"
      :id="Suffix.state.id"
      ref="suffix"
      :for="selectId"
      class="suffix"
      :style="Suffix.state.style"
    >
      <slot name="suffix">
        {{ suffix }}
      </slot>
    </label>
  </div>
</template>

<style></style>

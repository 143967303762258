<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @search-company-changed="onCompanyChanged"
    @search-click="onSearch"
    @new-click="onNew"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col width="550px">
          <GrpAuthList :grp-auth-page="false" :user-auth-page="true" />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <iui-tab-old :comps="comps" :keepAlive="true" @click="setTabIndexS"> </iui-tab-old>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import GrpAuthList from './components/GrpAuthList.vue';
import store from './store/grpAuthRegist.js';
export default {
  components: {GrpAuthList},

  data() {
    return {
      comps: [
        {
          tabNm: '메뉴정보',
          compNm: 'menuInfo',
          path: '@/view/sysManage/authManage/grpAuthRegist/components/GrpMenuAuthList.vue',
        },
        {
          tabNm: '사용자정보',
          compNm: 'userInfo',
          path: '@/view/sysManage/authManage/grpAuthRegist/components/GrpUserList.vue',
        },
      ],
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, {
      target: 'target',
      tabIndex: 'tabIndex',
      grpAuthInfo: 'grpAuthInfo',
    });
    $mapMutations(this, ['setTabIndex', 'setGrpAuthInfo']);
  },

  created() {
    this.setGrpAuthInfo({pgmCd: this.userInfo.pgmCd});
  },

  methods: {
    onNew() {
      this.callEvent({name: 'onAdd'});
    },
    onSearch() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'selectGrpMenuAuthList'});
      } else {
        this.callEvent({name: 'selectGrpAuthUserList'});
      }
    },
    onSave() {
      if (this.tabIndex == 0) {
        this.callEvent({
          name: 'grpMenuAuthSaveJson',
          param: data => {
            let grpMenuAuthList = [];
            data.forEach(item => {
              grpMenuAuthList.push({
                pgmCd: this.grpAuthInfo.pgmCd,
                grpAuth: this.grpAuthInfo.grpAuth,
                mnuCd: item.mnuCd,
                vwYn: item.vwYn,
                svYn: item.svYn,
                delYn: item.delYn,
                prnYn: item.prnYn,
              });
            });

            axios.post('/grpAuth/saveSystemGroupMenuAuth', {grpMenuAuthList: grpMenuAuthList}).then(response => {
              if (response.status == 200) {
                this.callEvent({name: 'selectGrpMenuAuthList'});
              }
            });
          },
        });
      } else {
        this.callEvent({
          name: 'GrpUserList_getSaveJson',
          param: data => {
            data.forEach(el => {
              el.auth = el.auth == 1 ? true : false;
              el.grpAuth = this.grpAuthInfo.grpAuth;
            });
            axios.post('/userAuth/saveUserGroupR', {userAuthList: data}).then(response => {
              if (response.status == 200) {
                this.callEvent({name: 'selectGrpAuthUserList', param: this.grpAuthInfo.grpAuth});
              }
            });
          },
        });
      }
    },

    onDelete() {
      this.callEvent({name: 'onDel'});
    },

    onCompanyChanged(e) {
      this.setGrpAuthInfo({pgmCd: e.pgmCd});
      this.callEvent({name: 'selectGroupList'});
    },
    setTabIndexS(index) {
      this.setTabIndex(index);
      if (this.target == 'grp') {
        if (this.tabIndex == 0) {
          this.callEvent({name: 'selectGrpMenuAuthList', param: this.grpAuthInfo.grpAuth});
        } else {
          setTimeout(() => {
            this.callEvent({name: 'selectGrpAuthUserList', param: this.grpAuthInfo.grpAuth});
          }, 100);
        }
      }
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header required>상위메뉴</i-col-header>
        <i-col>
          <iui-text name="uppMnuCd" :value="system.uppMnuCd" type="code" required></iui-text>
          <iui-text name="uppMnuNm" :value="system.uppMnuNm"></iui-text>
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>메뉴</i-col-header>
        <i-col>
          <iui-text name="mnuCd" :value="system.mnuCd" type="code" required></iui-text>
          <iui-text name="mnuNm" :value="system.mnuNm" required></iui-text>
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>영문메뉴명</i-col-header>
        <i-col>
          <iui-text name="mnuNmEng" :value="system.mnuNmEng"></iui-text>
        </i-col>
      </i-row>
      <i-row v-if="system.lvl === '4'">
        <i-col-header required>메뉴경로</i-col-header>
        <i-col>
          <iui-text name="mnuPth" id="mnuPth" v-if="system.lvl === '4'" :value="system.mnuPth" required></iui-text>
        </i-col>
      </i-row>
      <i-row v-if="system.lvl === '4'">
        <i-col-header>파라미터</i-col-header>
        <i-col>
          <iui-text name="para" :value="system.para"></iui-text>
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>정렬순서</i-col-header>
        <i-col>
          <iui-text name="mnuSqn" :value="system.mnuSqn" required></iui-text>
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>사용여부</i-col-header>
        <i-col>
          <!-- <iui-checkbox-group-old name="useDs" :items="checkboxItem5" :checkedValues="[system.useDs]"></iui-checkbox-group-old> -->
          <iui-select-old name="useDs" width="90px" :items="useDsItems" :value="system.useDs" />
        </i-col>
      </i-row>
    </iui-container>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col style="text-align: center" required>
          <iui-checkbox-group-old
            name="vwYn"
            :items="checkboxItem1"
            :checkedValues="[system.vwYn]"
          ></iui-checkbox-group-old>
        </i-col>
        <i-col style="text-align: center" required>
          <iui-checkbox-group-old
            name="svYn"
            :items="checkboxItem2"
            :checkedValues="[system.svYn]"
          ></iui-checkbox-group-old>
        </i-col>
        <i-col style="text-align: center" required>
          <iui-checkbox-group-old
            name="delYn"
            :items="checkboxItem3"
            :checkedValues="[system.delYn]"
          ></iui-checkbox-group-old>
        </i-col>
        <i-col style="text-align: center" required>
          <iui-checkbox-group-old
            name="prnYn"
            :items="checkboxItem4"
            :checkedValues="[system.prnYn]"
          ></iui-checkbox-group-old>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      checkboxItem1: [{label: '조회', value: 'Y', isChecked: false}],
      checkboxItem2: [{label: '저장', value: 'Y', isChecked: false}],
      checkboxItem3: [{label: '삭제', value: 'Y', isChecked: false}],
      checkboxItem4: [{label: '출력', value: 'Y', isChecked: false}],
      checkboxItem5: [{label: '사용', value: 'null', isChecked: false}],
      useDsItems: [
        {value: 'Y', text: '사용'},
        {value: 'N', text: '미사용'},
        {value: 'X', text: '개발중'},
      ],
    };
  },
  mounted() {
    this.mnuPth = this.system.mnuPth;
  },
  computed: {
    /** 스타일 정의 */
    css1() {
      return {
        width: '100px',
        'margin-right': '3px',
      };
    },
    css2() {
      return {
        width: '300px',
      };
    },
    css3() {
      return {
        width: '400px',
      };
    },
    ...mapGetters('menu', {
      system: 'system',
      pgmCd: 'pgmCd',
      prjCd: 'prjCd',
    }),
  },

  watch: {},
  methods: {},
};
</script>

<style scoped></style>

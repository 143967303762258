<template>
  <iui-container type="ibsheet" :id="elId" />
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
  },
  created() {
    this.addEvent([{name: 'User_search', func: this.onSearch}]);
    this.addEvent([{name: 'User_setData', func: this.onSetData}]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },
  mounted() {
    const OPT = {
      Cfg: {
        CanEdit: false,
      },
      Cols: [
        {
          Header: 'ID',
          Name: 'empNo',
          Type: 'Text',
          Width: 80,
          Align: 'center',
        },
        {
          Header: '성명',
          Name: 'name',
          Type: 'Text',
          Width: 80,
          Align: 'center',
        },
        {
          Header: '직급',
          Name: 'dtsNm',
          Type: 'Text',
          Width: 80,
          Align: 'center',
        },
        {
          Header: '분야',
          Name: 'jkdNm',
          Type: 'Text',
          Width: 100,
          Align: 'center',
        },
        {
          Header: '부서',
          Name: 'depNm',
          Type: 'Text',
          RelWidth: 1,
          Align: 'center',
        },
        {
          Header: '일반전화',
          Name: 'tlno',
          Type: 'Text',
          Width: 110,
          Align: 'center',
        },
        {
          Header: '이동전화',
          Name: 'mobileTlno',
          Type: 'Text',
          Width: 110,
          Align: 'center',
        },
        {Name: 'pgmCd', Visible: false},
        {Name: 'dtsNo', Visible: false},
        {Name: 'jkdNo', Visible: false},
        {Name: 'depNo', Visible: false},
      ],
      Events: {
        onDblClick: this.grid_onDblClick,
      },
    };

    this.sheet = IBSheet.create({id: this.sheetId, el: this.elId, options: OPT});
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let param = {pgmCd: this.userInfo.pgmCd};
      let response = await axios.post('/user/selectUserPopupList', param);
      this.sheet.loadSearchData(response.data);
    },
    onSetData(callback) {
      let row = this.sheet.getFocusedRow();
      if (row == null) {
        this.$alert('선택된 사용자가 없습니다.');
        return;
      }
      callback({
        pgmCd: row.pgmCd,
        empNo: row.empNo,
        name: row.name,
        dtsNo: row.dtsNo,
        dtsNm: row.dtsNm,
        jkdNo: row.jkdNo,
        jkdNm: row.jkdNm,
        depNo: row.depNo,
        depNm: row.depNm,
        tlno: row.tlno,
        mobileTlno: row.mobileTlno,
      });
    },
    grid_onDblClick() {
      this.callEvent({name: `userSearchOnConfirm${this.uid}`}); // iuiSearchbox.vue
    },
  },
};
</script>

<style scoped></style>

<script>
import {defineComponent, ref, reactive, computed} from '@vue/composition-api';

import ReportMain from './components/ReportMain.vue';
import ReportHist from './components/ReportHist.vue';

export default defineComponent({
  components: {ReportMain, ReportHist},
  setup() {
    const state = reactive({
      search: false,
      mainRowData: undefined, //(Main)현재 선택된 Row
      dataChanged: false, //(Main)데이터 변경여부
    });

    const refs = {
      mainRpt: ref(null),
      histRpt: ref(null),
    };

    const method = {
      search_click() {
        // state.search = true;

        refs.mainRpt.value.search_main();
      },
    };
    const testData = computed(() => {
      console.log('computed....', state.mainRowData);
    });

    return {
      state,
      testData,
      ...refs,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page @search-click="search_click">
    <iui-container type="css-flex">
      <i-spacer />
      <span style="color:red">
        ※ [계약서 정보]탭에서 신규 저장 후 [계약서 변경이력 정보]탭에서 상세정보를 입력하세요.</span
      >
      <i-spacer />
      <i-row>
        <i-col width="550px">
          <report-main
            ref="mainRpt"
            :search="state.search"
            :selected-row-data.sync="state.mainRowData"
            :data-changed.sync="state.dataChanged"
          />
        </i-col>
        <i-spacer />
        <i-col>
          <report-hist ref="histRpt" :selected-row-data="state.mainRowData" :data-changed.sync="state.dataChanged" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

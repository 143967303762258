<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col width="350px">
        <pmis-tab-box no-border-box no-padding-box>
          <template v-slot:title>자재분류</template>

          <pmis-content-box>
            <template v-slot:header-left>
              <iui-text name="searchText1" @change="setValue" @enter="onFocusGrid" />
              <iui-button value="검색" @click="onFocusGrid" />
            </template>
            <iui-container type="ibsheet" :id="elId1" />
          </pmis-content-box>
        </pmis-tab-box>
      </i-col>
      <i-spacer />
      <i-col>
        <iui-container type="css-flex">
          <i-row>
            <i-col>
              <pmis-tab-box no-border-box no-padding-box>
                <template v-slot:title>자재코드</template>
                <template v-slot:title-right>
                  <iui-button value="요청내역추가" @click="appendRequestDtls" />
                </template>
                <pmis-content-box>
                  <template v-slot:header-left>
                    <iui-text name="searchText2" @change="setValue" @enter="onSearchCodeList" />
                    <iui-button value="검색" @click="onSearchCodeList" />
                  </template>
                  <iui-container type="ibsheet" :id="elId2" />
                </pmis-content-box>
              </pmis-tab-box>
            </i-col>
          </i-row>
          <i-spacer />
          <i-row>
            <i-col>
              <pmis-tab-box no-border-box no-padding-box>
                <template v-slot:title>요청내역</template>
                <template v-slot:title-right>
                  <iui-button value="삭제" @click="removeRequestDtls" />
                </template>
                <pmis-content-box>
                  <iui-container type="ibsheet" :id="elId3" />
                </pmis-content-box>
              </pmis-tab-box>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'mat',
    },
    list: {
      type: Array,
      default: () => [],
    },
    isStandard: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.addEvent({name: 'matCodePopup_confirm', func: this.onConfirm});
  },
  data() {
    return {
      elId1: 'sheetDiv1' + this._uid,
      elId2: 'sheetDiv2' + this._uid,
      elId3: 'sheetDiv3' + this._uid,
      sheetId1: 'sheet1' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      sheetId3: 'sheet3' + this._uid,
      searchText1: '',
      searchText2: '',
      keyInfo: {
        level: '',
        lmCode: '', // 자재 - 대분류
        mmCode: '', // 자재 - 중분류
        smCode: '', // 자재 - 소분류
      },
    };
  },
  mounted() {
    const OPT1 = {
      Cfg: {
        MainCol: 'name', // 트리가 표시될 열이름
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1: 전체 편집 가능 (default)
        NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
        FitWidth: 1, //더미 헤더를 추가하여 스크롤바를 우측 끝에 붙인다.
      },
      Def: {
        Header: {
          Hidden: true,
        },
      },
      Cols: [
        {
          Header: '명칭',
          Name: 'name',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '코드',
          Name: 'code',
          Type: 'Text',
          Width: 65,
          Align: 'center',
        },
      ],
      Events: {
        onClick: this.grid1_onClick,
      },
    };

    const OPT2 = {
      Cfg: {
        CanEdit: true,
        CanSort: false,
      },
      Cols: [
        {
          Header: {Value: '선택', TextColor: '#000000', Align: 'center', HeaderCheck: 1},
          Name: 'cbx',
          Type: 'Bool',
          CanEdit: true,
          Width: 55,
        },
        {
          Header: `자재코드`,
          Name: 'matCode',
          Type: 'Text',
          CanEdit: false,
          Width: 120,
          Align: 'center',
        },
        {
          Header: `명칭`,
          Name: 'matName',
          Type: 'Text',
          CanEdit: false,
          RelWidth: 1,
        },
        {
          Header: '규격',
          Name: 'matSize',
          Type: 'Text',
          CanEdit: false,
          Width: 160,
          Align: 'center',
        },
        {
          Header: '단위',
          Name: 'matUnitName',
          Type: 'Text',
          CanEdit: false,
          Width: 70,
          Align: 'center',
        },
        {Name: 'matStyle', Visible: false}, // 형식
        {Name: 'matUnit', Visible: false}, // 자재단위
      ],
    };

    const OPT3 = {
      Cfg: {
        CanEdit: true,
        CanSort: false,
      },
      Cols: [
        {
          Header: {Value: '선택', TextColor: '#000000', Align: 'center', HeaderCheck: 1},
          Name: 'cbx',
          Type: 'Bool',
          CanEdit: true,
          Width: 55,
        },
        {
          Header: `자재코드`,
          Name: 'matCode',
          Type: 'Text',
          CanEdit: false,
          Width: 120,
          Align: 'center',
        },
        {
          Header: `명칭`,
          Name: 'matName',
          Type: 'Text',
          CanEdit: false,
          RelWidth: 1,
        },
        {
          Header: '규격',
          Name: 'matSize',
          Type: 'Text',
          CanEdit: false,
          Width: 160,
          Align: 'center',
        },
        {
          Header: '단위',
          Name: 'matUnitName',
          Type: 'Text',
          CanEdit: false,
          Width: 70,
          Align: 'center',
        },
        {Name: 'matStyle', Visible: false}, // 형식
        {Name: 'matUnit', Visible: false}, // 자재단위
        {Name: 'unit', Visible: false}, // 장비단위
      ],
    };

    this.sheet1 = IBSheet.create({id: this.sheetId1, el: this.elId1, options: OPT1});
    this.sheet2 = IBSheet.create({id: this.sheetId2, el: this.elId2, options: OPT2});
    this.sheet3 = IBSheet.create({id: this.sheetId3, el: this.elId3, options: OPT3});
    if (this.list.length) {
      this.sheet3.loadSearchData(this.list);
    }
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let url = ''; // 자재
      let param = {};
      if (this.isStandard) {
        url = '/matCodeRegist/selectMaterialStandardCodeList'; // 자재
      } else {
        url = '/matCodeRegist/selectMaterialClList'; // 자재
        param = {
          pgmCd: this.userInfo.pgmCd,
        };
      }
      let response = await axios.post(url, param);
      let treeList = Array.from(response.data)[0];
      let rootCode = !this.isStandard ? Array.from(response.data)[1].upCode : Array.from(response.data)[0].code;

      // console.log(response.data);
      console.log(treeList.code);
      treeList.Items = await $_treeModel(
        Array.from(response.data).filter((e, i) => i != 0),
        rootCode,
        'code',
        'upCode'
      );
      this.sheet1.loadSearchData(new Array(treeList));
    },
    setValue(e) {
      this[e.target.name] = e.target.value;
      if (!e.target.value) {
        this.onFocusGrid();
      }
    },
    onFocusGrid() {
      if (this.searchText1) {
        this.sheet1.getDataRows().forEach(row => {
          let textColor = row.name.indexOf(this.searchText1) > -1 ? '#FF0000' : '#000000';
          this.sheet1.setAttribute({row: row, attr: 'TextColor', val: textColor});
        });
        let focusRow = this.sheet1.getDataRows().find(row => row.name.indexOf(this.searchText1) > -1);
        setTimeout(() => this.sheet1.focus(focusRow), 10);

        this.setKeyInfo(focusRow);
        this.onSearchCodeList(focusRow);
      } else {
        this.sheet1.getDataRows().forEach(row => {
          this.sheet1.setAttribute({row: row, attr: 'TextColor', val: '#000000'});
        });
      }
    },
    grid1_onClick(e) {
      if (this.grid_checkClickPosition(e)) {
        if (e.row.level > 1) {
          this.setKeyInfo(e.row);
          this.onSearchCodeList(e.row);
        }
      }
    },
    grid_checkClickPosition(e) {
      // 트리 버튼 클릭 시 하위항목 확장만 되게 하기 위한 계산
      let x = Number(e.x);
      let y = Number(e.y);
      let LEVEL = Number(e.row.Level); // IBSheet Tree LEVEL
      let leftStart = 20 * LEVEL + 5;
      let leftEnd = 20 * (LEVEL + 1);
      let topStart = 7;
      let topEnd = 22;

      let isExpandButton = leftStart <= x && x <= leftEnd && topStart <= y && y <= topEnd;
      return !isExpandButton;
    },
    setKeyInfo(row) {
      this.keyInfo.level = row.level;
      this.keyInfo.lmCode = row.lmCode;
      this.keyInfo.mmCode = row.mmCode;
      this.keyInfo.smCode = row.smCode;
    },
    async onSearchCodeList() {
      let url = '';
      let param = {};
      if (this.isStandard) {
        url = '/matCodeRegist/selectStandardMaterialList'; // 자재
        param = {
          ...this.keyInfo,
          pgmCd: this.userInfo.pgmCd,
          searchText: this.searchText2,
        };
      } else {
        url = '/matCodeRegist/selectMaterialCodeList'; // 자재
        param = {
          pgmCd: this.userInfo.pgmCd,
          searchNm: this.searchText2,
          code: this.keyInfo.smCode || this.keyInfo.mmCode || this.keyInfo.lmCode,
        };
      }

      let response = await axios.post(url, param);
      this.sheet2.loadSearchData(response.data);
    },
    async onConfirm(callback) {
      let dataRows = this.sheet3.getSaveJson(0);
      if (0 == dataRows.data.length) {
        this.$alert(`요청내역이 없습니다.`);
        return;
      }
      callback(dataRows.data);
    },
    appendRequestDtls() {
      let checkedRows = this.sheet2.getRowsByChecked('cbx');
      this.sheet3.getDataRows().forEach(el => {
        checkedRows.some((el2, index) => {
          if (el.matCode == el2.matCode) {
            checkedRows.splice(index, 1);
            return true;
          }
        });
      });

      this.sheet3.copyRows({rows: checkedRows});
    },
    removeRequestDtls() {
      let checkedRows = this.sheet3.getRowsByChecked('cbx');
      checkedRows.some(el => {
        this.sheet3.removeRow({row: el});
      });
    },
  },
};
</script>

<style></style>

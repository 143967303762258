<script>
import {defineComponent, reactive, computed, watch} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {PrefixProps, usePrefix} from '@/components/Iui/composables/usePrefix';
import {SuffixProps, useSuffix} from '@/components/Iui/composables/useSuffix';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default defineComponent({
  name: 'iui-datepicker',
  components: {DatePicker},
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...PrefixProps,
    ...SuffixProps,

    modelValue: {
      type: [String, Date],
      default: undefined,
    },
    type: {
      type: String,
      default: 'date', // date|datetime|year|month|time|week
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  emits: ['blur', 'update:model-value'],
  setup(props, context) {
    const Base = useBase(props, context, 'iui-datepicker');
    const Layout = useLayout(props, context, 'iui-datepicker');
    const Label = useLabel(props, context, Base);
    const Prefix = usePrefix(props, context, Base);
    const Suffix = useSuffix(props, context, Base);

    const datePickerConfig = {
      lang: {
        //months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        //20230105 lgksja 날짜 아래로 변경
        days:["일", "월", "화", "수", "목", "금", "토", "일"],
        daysShort: ["일", "월", "화", "수", "목", "금", "토", "일"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토", "일"],
        months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        monthsShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        yearFormat: "YYYY년",
        monthFormat: "M월",
        monthBeforeYear: false,
      },
      format: 'YYYY-MM-DD',
      valueType: 'date',
    };

    if (props.type === 'date') {
      datePickerConfig.format = 'YYYY-MM-DD';
      datePickerConfig.valueType = 'YYYYMMDD';
    }
    if (props.type === 'month') {
      datePickerConfig.format = 'YYYY-MM';
      datePickerConfig.valueType = 'YYYYMM';
    }
    if (props.type === 'year') {
      datePickerConfig.format = 'YYYY';
      datePickerConfig.valueType = 'YYYY';
    }

    const state = reactive({
      modelValue: props.modelValue,
      placeholder: props.placeholder,

      id: computed(() => Base.getId('datePicker')),
      class: computed(() => {
        return {
          'date-picker': true,
          'mx-input': true,
        };
      }),
      style: computed(() => {
        return {};
      }),
    });

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        state.modelValue = newValue;
      }
    );

    const method = {
      datePicker_input(e) {
        context.emit('update:model-value', e);
      },
      datePicker_blur(e) {
        context.emit('blur', e);
      },
    };

    return {
      Base,
      Layout,
      Label,
      Prefix,
      Suffix,
      datePickerConfig,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style">
    <label
      v-if="Label.state.has"
      :id="Label.state.id"
      ref="label"
      :for="state.id"
      class="label"
      :style="Label.state.style"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <label
      v-if="Prefix.state.has"
      :id="Prefix.state.id"
      ref="prefix"
      :for="state.id"
      class="prefix"
      :style="Prefix.state.style"
    >
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </label>
    <date-picker
      :id="state.id"
      ref="datePicker"
      v-model="state.modelValue"
      :class="{
        datepicker: true,
      }"
      :type="type"
      :format="datePickerConfig.format"
      :value-type="datePickerConfig.valueType"
      :lang="datePickerConfig.lang"
      :placeholder="state.placeholder"
      :editable="!Base.state.readonly && Base.state.enable"
      :clearable="!Base.state.readonly && Base.state.enable"
      :disabled="!Base.state.enable"
      :popup-style="{visibility: Base.state.readonly ? 'hidden' : ''}"
      :input-attr="{autocomplete: 'off'}"
      @input="datePicker_input"
      @blur="datePicker_blur"
      style="border: 0px;"
    />
    <label
      v-if="Suffix.state.has"
      :id="Suffix.state.id"
      ref="suffix"
      :for="state.id"
      class="suffix"
      :style="Suffix.state.style"
    >
      <slot name="suffix">
        {{ suffix }}
      </slot>
    </label>
  </div>
  <!--
      :default-value="state.modelValue"
    :input-class="datePickerInputClass"
      :input-attr="datePickerInputAttr" -->
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  computed,
  watch,
  nextTick,
  onMounted,
} from '@vue/composition-api';
import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup.js';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import IuiButton from '../../../components/Iui/components/IuiButton/IuiButton.vue';

// 계약 정보 조회
// 서명자 정보 조회
//   대리인이 아니면 공동인증서 서명이 가능
//   법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능

// 반송 - /urlLink/reject
// 반송(고객) - /urlLink/rejectByCust
// 서명 -

// const apiUrlMap = new Map();

// apiUrlMap.set('NE_ET1', {
//   contractInfo: '/urlLink/ne/contractInfo',
//   signerInfo: '/urlLink/ne/signerInfo',
//   reject: '/urlLink/ne/reject',
//   rejectByCust: '/urlLink/ne/rejectByCust',
//   sign: '/urlLink/ne/sign',
// });

// apiUrlMap.set('FOD_CT1', {
//   contractInfo: '/urlLink/fod/contractInfo',
//   signerInfo: '/urlLink/fod/signerInfo',
//   reject: '/urlLink/fod/reject',
//   rejectByCust: '/urlLink/fod/rejectByCust',
//   sign: '/urlLink/fod/sign',
// });

const apiUrl = {
  contractInfo: '/urlLink/contractInfo',
  signerInfo: '/urlLink/signerInfo',
  reject: '/urlLink/reject',
  rejectByCust: '/urlLink/rejectByCust',
  save: '/urlLink/save',
  agree: '/urlLink/agree',
  verify: '/urlLink/verify',
  //FOD_CT1 미완료시 FOD_CT2서명불가 작업
  consortiumContractInfo: '/urlLink/consortiumContractInfo',
};

export default defineComponent({
  components: {IuiButton},
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: [String, Number],
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
    tradeNo: {
      type: String,
      default: undefined,
    },
    tradeSfx: {
      type: String,
      default: undefined,
    },
  },
  emits: ['reject'],
  setup(props, context) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;
    const routerQuery = vm.$route.query;

    const oPopup = useWindowPopup('oPopup');

    let tradeNo = props.tradeNo;
    let tradeSfx = props.tradeSfx;

    if (tradeNo === undefined && sessionStorage.getItem('userInfo') !== null) {
      try {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

        tradeNo = userInfo.tradeNo;
        tradeSfx = undefined;
      } catch (ex) {
        console.log(ex);
      }
    }

    const state = reactive({
      ctrtNo: props.ctrtNo,
      ctrtOdr: props.ctrtOdr,
      ctrtDivCd: props.ctrtDivCd,
      tradeNo: tradeNo,
      tradeSfx: tradeSfx,

      codeList: {
        signMethod: [
          // {text: '공동인증서', value: '10'},
          // {text: '디지털서명', value: '30'},
        ],
      },

      contractInfo: {
        ctrtSttusCd: undefined,
        pdfImageYn: undefined,
        passSkipYn: undefined,
        approvalNo: undefined,
      },

      signerInfo: {
        // ctrtNo: undefined,
        // ctrtOdr: undefined,
        // ctrtDivCd: undefined,
        // tradeNo: undefined,
        // tradeSfx: undefined,
        canSign: false,

        pkcs7SignedMessage: undefined,
        vidRandom: undefined,
        signerDn: undefined,

        passVerified: undefined,
        passNo: undefined,
        agentIdntyImageCn: undefined,
      },

      pdfImageListUrl: computed(() => {
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
          return `http://${location.hostname}:8101/contract/pdfImageList/${state.ctrtNo}/${state.ctrtOdr}/${state.ctrtDivCd}`;
        }

        return `${location.origin}/contract/pdfImageList/${state.ctrtNo}/${state.ctrtOdr}/${state.ctrtDivCd}`;
      }),

      consortiumContractInfo : {
        fodCt2canSign : undefined
      },

      ozConfig: {
        // viewerUrl: process.env.VUE_APP_OZ_VIEWER_URL,
        viewerUrl: process.env.VUE_APP_OZ_QRY_VIEWER_URL,
        viewerReady: false,
        path: undefined,
        odiName: undefined,
        bind: false,
      },

      pdfViewerConfig: {
        src: './ext/pdfjs/v2.14.305-legacy/web/viewer.html',
        url: undefined,
        viewerUrl: computed(() => {
          if (
            state.pdfViewerConfig.url === undefined ||
            state.pdfViewerConfig.url === null ||
            state.pdfViewerConfig.url.length === 0
          ) {
            return '';
          }

          return state.pdfViewerConfig.src + '?file=' + encodeURIComponent(state.pdfViewerConfig.url);
        }),
      },

      viewOz: computed(() => {
        return !state.viewPdf;
      }),

      viewPdf: computed(() => {
        // NE 견적, MOD 견적
        if (
          ['NE_ET1', 'MOD_ET1', 'MOD_ET2'].includes(state.ctrtDivCd) &&
          ['10'].includes(state.contractInfo.ctrtSttusCd) &&
          state.contractInfo.dwldUrl !== undefined &&
          state.contractInfo.dwldUrl !== null &&
          state.contractInfo.dwldUrl.length !== 0
        ) {
          return true;
        }

        // 그 외
        if (
          ['30', '40', '41'].includes(state.contractInfo.ctrtSttusCd) &&
          state.contractInfo.dwldUrl !== undefined &&
          state.contractInfo.dwldUrl !== null &&
          state.contractInfo.dwldUrl.length !== 0
        ) {
          return true;
        }

        return false;
      }),

      canSave: computed(() => {
        return (
          ['10', '20'].includes(state.contractInfo.ctrtSttusCd) && state.contractInfo.saveYn === 'Y' && state.canSign
        );
      }),

      canAgree: computed(() => {
        return (
          ['10', '20'].includes(state.contractInfo.ctrtSttusCd) &&
          state.contractInfo.agreYn === 'Y' &&
          state.signerInfo.canAgree &&
          state.canSign
        );
      }),

      canSign: computed(() => {
        return ['10', '20'].includes(state.contractInfo.ctrtSttusCd) && state.signerInfo.canSign;
        // return true;
      }),

      canReject: computed(() => {
        return (
          ['10', '20'].includes(state.contractInfo.ctrtSttusCd) &&
          !state.signerInfo.customer &&
          state.signerInfo.canSign
        );
      }),

      canRejectByCust: computed(() => {
        return (
          ['10', '20'].includes(state.contractInfo.ctrtSttusCd) && state.signerInfo.customer && state.signerInfo.canSign
        );
      }),

      loading: true,
      loadingMessageTemplate: '문서를 가져오고 있습니다.',
      loadingMessage: '문서를 가져오고 있습니다.',

      showDebug: false,

      isAdminOzView :false,
    });

    let shortUrlLinkType = undefined;
    let encryptedParameter = undefined;

    // Url Link로 접근했을 경우 처리
    if (routerQuery.hasOwnProperty('shortUrlLinkType')) {
      shortUrlLinkType = routerQuery.shortUrlLinkType;
    }

    if (routerQuery.hasOwnProperty('encParam')) {
      encryptedParameter = routerQuery.encParam;
    }

    const checkIE = () => {
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;

      if (isIE) {
        instance.proxy.$alert(
          '현재 인터넷 익스플로어(IE)로 접속하셨습니다.<br/>크롬(Chrome)브라우저 사용을 권고 드립니다.'
        );
      }
    };

    const decryptParameter = async encryptedParameter => {
      const data = {
        encParam: encryptedParameter,
      };

      const response = await axios.post('/urlLink/decAndParseParam', data);

      console.log('Viewer > decryptParameter', response.data);

      state.ctrtNo = response.data.ctrtNo;
      state.ctrtOdr = response.data.ctrtOdr;
      state.ctrtDivCd = response.data.ctrtDivCd;
      state.tradeNo = response.data.tradeNo;
      state.tradeSfx = response.data.tradeSfx === 'null' ? undefined : response.data.tradeSfx;
    };

    const api = {
      async getContractInfo() {
        const data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSuffix: state.tradeSfx,
        };

        try {
          const response = await axios.post(apiUrl.contractInfo, data);

          state.contractInfo = response.data;

          console.log('Viewer > api.getContractInfo', response.data);
        } catch (ex) {
          console.log(ex);
        }
      },

      async getSignerInfo() {
        const data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSuffix: state.tradeSfx,
        };

        try {
          const response = await axios.post(apiUrl.signerInfo, data);

          state.signerInfo = response.data;

          // 대리인이 아니면 공동인증서 서명이 가능
          if (!state.signerInfo.buyagnt) {
            state.codeList.signMethod.push({text: '공동인증서', value: '10'});
          }

          if ( ['NPP_CT6', 'NPP_CT7', 'NPP_CT8'].includes(state.ctrtDivCd)) { //HR 파견계약서는 패스 추가
            state.signerInfo.userNm = state.contractInfo.instAddress; // instAddress에 pass인증용 이름이 들어가 있다. 
            state.codeList.signMethod.push({text: '디지털서명', value: '30'});
          }

          // 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능
          //20231130 lgksja 오책임님이 열으라고함
          //다시 바로 막으라고함
          // if (!state.signerInfo.corporate || state.signerInfo.buyagnt) {
          //    state.codeList.signMethod.push({text: '디지털서명', value: '30'});
          // }

          // 인수증은 디지털서명만 지원
          if (state.ctrtDivCd === 'FOD_RC1') {
            state.codeList.signMethod.length = 0;
            state.codeList.signMethod.push({text: '디지털서명', value: '30'});
          }

          console.log('Viewer > api.getSignerInfo', response.data);
        } catch (ex) {
          console.log(ex);
        }
      },

      async save() {
        let data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSuffix: state.tradeSfx,
          ...state.signerInfo,
          pgmCd: '201900',
          chngEmpId: state.signerInfo.tradeNo,
          chngPgmId: 'Viewer',
        };

        if (state.contractInfo.estnDataYn !== undefined && state.contractInfo.estnDataYn === 'Y') {
          const ozInputData = oz.getInputData(false);

          data = {
            ...data,
            mainEstnData: ozInputData.signedEstnData,
          };
        }

        if (state.contractInfo.signEstnDataYn !== undefined && state.contractInfo.signEstnDataYn === 'Y') {
          const ozInputData = oz.getInputData(false);

          data = {
            ...data,
            estnData: ozInputData.signedSignEstnData,
          };
        }
        console.log('Viewer > api.save', data);

        const response = await axios.post(apiUrl.save, data);

        console.log('Viewer > api.save', response);
      },

      async agree() {
        const data = {
          agreSn: state.signerInfo.agreSn,
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          agreSttusCd: '20',
          tradeType: state.signerInfo.tradeType,
          tradeNo: state.signerInfo.tradeNo,
          tradeName: state.signerInfo.tradeName,
          pgmCd: '201900',
          chngEmpId: state.signerInfo.tradeNo,
          chngPgmId: 'Viewer',
        };
        console.log('Viewer > api.agree', data);

        const response = await axios.post(apiUrl.agree, data);
        console.log('Viewer > api.agree', response);
      },

      async verify() {
        console.log('Viewer > verify');

        let data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSuffix: state.tradeSfx,
          ...state.signerInfo,
          pgmCd: '201900',
          chngEmpId: state.signerInfo.tradeNo,
          chngPgmId: 'Viewer',
        };

        if (state.contractInfo.infoProvdAgreYn !== undefined && state.contractInfo.infoProvdAgreYn === 'Y') {
          data = {
            ...data,
            infoProvdAgreYn: 'Y',
          };
        }

        if (state.contractInfo.estnDataYn !== undefined && state.contractInfo.estnDataYn === 'Y') {
          const ozInputData = oz.getInputData();

          data = {
            ...data,
            mainEstnData: ozInputData.signedEstnData,
          };
        }

        if (state.contractInfo.signEstnDataYn !== undefined && state.contractInfo.signEstnDataYn === 'Y') {
          const ozInputData = oz.getInputData();

          data = {
            ...data,
            estnData: ozInputData.signedSignEstnData,
          };
        }

        if( oz.getInputData()['signPad1'] !== undefined && oz.getInputData()['signPad1'] !== ''){
          data = {
            ...data,
            agentIdntyImageCn: oz.getInputData()['signPad1'],
          }; 
        }

        console.log('Viewer > api.verify', data);

        const response = await axios.post(apiUrl.verify, data);

        console.log('Viewer > api.verify', response);
      },

      //
      async getConsortiumContractInfo() {
        const data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSuffix: state.tradeSfx,
        };

        try {
          const response = await axios.post(apiUrl.consortiumContractInfo, data);

          state.consortiumContractInfo = response.data;

          console.log('Viewer > api.getConsortiumContractInfo', response.data);
        } catch (ex) {
          console.log(ex);
        }
      }
    };

    // OZ e-Form
    const oz = {
      bindPromise: undefined,
      bindPromiseResolve: undefined,
      bindPromiseReject: undefined,

      signMthCd: undefined,

      async setConfig() {
        const data = {
          manageCd: state.ctrtDivCd,
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
        };

        const response = await axios.post('/ozrpt/selectOzQryReportFileName', data);

        console.log('Viewer > oz.setOzConfig', data, response.data);

        state.ozConfig.path = response.data.reportPathName;
        state.ozConfig.odiName = response.data.odiNm;
      },

      async bind() {
        oz.signMthCd = '';

        const data = {
          reportname: state.ozConfig.path,
          OZFormParam: {
            manageCd: state.ctrtDivCd,
            ctrtNo: state.ctrtNo,
            ctrtOdr: state.ctrtOdr,
            ctrtDivCd: state.ctrtDivCd,
            tradeNo: state.tradeNo,
            tradeSfx: state.tradeSfx,
            signable: 'Y', // state.signerInfo.canSign ? 'Y' : 'N',
            signerSeCd: '',
            signMthCd: '', // state.signerInfo.signMthCd,
            pdfImageListUrl: state.pdfImageListUrl,
          },
        };

        if (oz.bindPromise !== undefined) {
          state.loadingMessageTemplate = undefined;
          oz.bindPromise = undefined;
          oz.bindPromiseResolve = undefined;
          oz.bindPromiseReject = undefined;

          oz.bindPromiseReject();
        }

        state.loadingMessageTemplate = '문서를 가져오고 있습니다.';
        state.loadingMessage = state.loadingMessageTemplate;
        state.loading = true;

        oz.bindPromise = new Promise((resolve, reject) => {
          oz.bindPromiseResolve = resolve;
          oz.bindPromiseReject = reject;
        });

        window.document.getElementById('ozFrame').contentWindow.receiveOzParam(data);

        return oz.bindPromise;
      },

      async reBind(force) {
        if (!force) {
          console.log("oz.signMthCd",oz.signMthCd);
          console.log("state.signerInfo.signMthCd",state.signerInfo.signMthCd);
          if (oz.signMthCd === state.signerInfo.signMthCd) {
            return;
          }
        }


        oz.signMthCd = state.signerInfo.signMthCd;

        const data = {
          reportname: state.ozConfig.path,
          OZFormParam: {
            manageCd: state.ctrtDivCd,
            ctrtNo: state.ctrtNo,
            ctrtOdr: state.ctrtOdr,
            ctrtDivCd: state.ctrtDivCd,
            tradeNo: state.tradeNo,
            tradeSfx: state.tradeSfx,
            signable: 'Y', // state.signerInfo.canSign ? 'Y' : 'N',
            signerSeCd: state.signerInfo.signerSeCd,
            signMthCd: state.signerInfo.signMthCd,
            pdfImageListUrl: state.pdfImageListUrl,
          },
        };

        console.log("oz.bindPromise",oz.bindPromise);

        if (oz.bindPromise !== undefined) {
          state.loadingMessageTemplate = undefined;
          oz.bindPromise = undefined;
          oz.bindPromiseResolve = undefined;
          oz.bindPromiseReject = undefined;

          oz.bindPromiseReject();
        }

        state.loading = true;

        oz.bindPromise = new Promise((resolve, reject) => {
          oz.bindPromiseResolve = resolve;
          oz.bindPromiseReject = reject;
        });

        window.document.getElementById('ozFrame').contentWindow.setOzReBindData(data);

        console.log("oz.bindPromise2",oz.bindPromise);

        return oz.bindPromise;
      },

      getInputData(checkValid = true) {
        return window.document.getElementById('ozFrame').contentWindow.getOzInputData(checkValid);
      },

      checkInputData() {
        const ozInputData = oz.getInputData();

        if (ozInputData === undefined || ozInputData === null) {
          return false;
        }

        return true;
      },
    };

    // 공동 인증서
    const pki = {
      async sign() {
        if (!vm.$secuKit.secuKitReady) {
          await vm.$alert('공동인증서 툴킷 로딩이 완료되지 못 했습니다.');
          return;
        }

        if (!(await vm.$confirm('전자서명 하시겠습니까?'))) {
          return false;
        }

        try {
          await vm.$secuKit.showDialog();
        } catch (ex) {
          return;
        }

        state.loadingMessage = '문서를 전자서명하고 있습니다.';
        state.loading = true;

        const certID = vm.$secuKit.certListInfo.getCertID();

        state.loadingMessage = '문서를 전자서명하고 있습니다. (1/4)';

        const data1 = {
          certType: 'SignCert',
          sourceString: state.contractInfo.ctrtOrginlHashcd,
          certID: certID,
        };

        const r2 = await vm.$secuKit.generatePKCS7SignedData(data1);
        console.log('generatePKCS7SignedData', r2);

        state.signerInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

        state.loadingMessage = '문서를 전자서명하고 있습니다. (2/4)';

        const data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };

        const r3 = await vm.$secuKit.viewCertInfomationWithVID(data2);
        console.log('viewCertInfomationWithVID', r3);

        state.signerInfo.vidRandom = r3.vidRandom;
        state.signerInfo.signerDn = r3.userDN;

        state.loadingMessage = '문서를 전자서명하고 있습니다. (3/4)';

        const data3 = {
          certType: 'SignCert',
          sourceString: r3.vidRandom,
          certID: certID,
        };

        const r4 = await vm.$secuKit.generatePKCS7SignedData(data3);
        console.log('generatePKCS7SignedData', r4);

        state.signerInfo.vidRandom = r4.generatePKCS7SignedData;

        if (state.signerInfo.pkcs7SignedMessage === '') {
          vm.$alert('전자서명한 값이 반드시 필요합니다.');
          state.signerInfo.pkcs7SignedMessage = '';
          state.signerInfo.signerDn = '';
          return false;
        }

        state.loadingMessage = '문서를 전자서명하고 있습니다. (4/4)';

        const data4 = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(state.signerInfo.pkcs7SignedMessage)),
        };

        const r5 = await vm.$secuKit.verifyPKCS7SignedData(data4);
        console.log('verifyPKCS7SignedData', r5);

        if (state.contractInfo.ctrtOrginlHashcd !== r5.verifyPKCS7SignedData) {
          vm.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.');
          state.signerInfo.pkcs7SignedMessage = '';
          state.signerInfo.signerDn = '';
          return false;
        }

        state.loading = false;

        return true;
      },
    };

    // 디지털 서명
    const digitalSign = {
      async sign() {
        if (
          (state.contractInfo.passSkipYn === undefined ||
            (state.contractInfo.passSkipYn !== null && state.contractInfo.passSkipYn === 'N')) &&
          !state.signerInfo.passVerified
        ) {
          state.signerInfo.passNo = 0;

          let passResult = await vm.$PASSCertification(state.signerInfo.userNm, state.signerInfo.mphone);

          if (!passResult.result) {
            passResult = await vm.$PASSCertification(state.signerInfo.tradeName, state.signerInfo.mphone);
          }

          console.log('passResult', passResult);

          

          if (passResult.result) {
            state.signerInfo.passVerified = passResult.result;
            state.signerInfo.passNo = passResult.data.passNo;
          } else {
            vm.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다.');
            return false;
          }
        }

        const ozInputData = oz.getInputData();

        if (
          ozInputData === undefined ||
          ozInputData === null ||
          ozInputData.signedFormId === undefined ||
          ozInputData.signedFormId === null
        ) {
          return false;
        }
        state.signerInfo.agentIdntyImageCn = ozInputData[ozInputData.signedFormId];

        if (state.signerInfo.agentIdntyImageCn === undefined) {
          state.signerInfo.agentIdntyImageCn = '';
          vm.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
          return false;
        }

        if (!state.signerInfo.agentIdntyImageCn.trim()) {
          // vm.$alert('계약서의 서명란에 디지털서명을 해야합니다.');
          return false;
        }

        return true;
        
      },
    };

    // 서명
    const oSignMethodDialog = useDialog('oSignMethodDialog');
    const signDialogSetup = {
      async btnSign_click() {
        if (!oz.checkInputData()) {
          return;
        }

        if (state.codeList.signMethod.length === 1) {
          await signDialogSetup.signDialog.btnSignMethod_click(state.codeList.signMethod[0].value);
        } else {
          if (!oz.checkInputData()) {
            return;
          }
          
          console.log("oz.getInputData()",oz.getInputData());
          console.log("state.signerInfo",oz.getInputData()['signPad1']);
          if(oz.getInputData()['signPad1'] != null && oz.getInputData()['signPad1'] !=''){
            await signDialogSetup.signDialog.btnSignMethod_click('30');
          }else{
            await oSignMethodDialog.show();
          }
          
        }
      },
      ...oSignMethodDialog.dialogRef,
      signDialog: {
        async btnSignMethod_click(signMethod) {
          //20240308 추가 이넘들 만들다 말았음
          if (state.codeList.signMethod.length > 1) {
            try{
              oSignMethodDialog.hide();
            }catch(e){
              console.log("e",e);
            }
          }

          //20230524 일단 막음 6월1주에 적용
          if(method.consortiumMemberChangeInfoCheck() != true){
            await vm.$alert('<span style="color:red;font-weight: 600;">공동수급 구성원 변경 통보서 에 미서명한 전자계약이 존재합니다.</span>');
            return;
          }
          
          console.log('btnSignMethod_click', signMethod);

          state.signerInfo.signMthCd = signMethod;
          state.signerInfo.pkcs7SignedMessage = undefined;
          state.signerInfo.vidRandom = undefined;
          state.signerInfo.signerDn = undefined;
          state.signerInfo.passNo = undefined;
          state.signerInfo.agentIdntyImageCn = undefined;

          state.loadingMessageTemplate = '문서를 전자서명을 하기 위해 준비 중입니다.';
          state.loadingMessage = state.loadingMessageTemplate;

          await oz.reBind();

          let signed = false;

          if (signMethod === '10') {
            signed = await pki.sign();
          } else if (signMethod === '30') {
            signed = await digitalSign.sign();
            // 디지털 서명
            // if (
            //   (state.contractInfo.passSkipYn === undefined ||
            //     (state.contractInfo.passSkipYn !== null && state.contractInfo.passSkipYn === 'N')) &&
            //   !state.signerInfo.passVerified
            // ) {
            //   state.signerInfo.passNo = 0;

            //   const passResult = await vm.$PASSCertification(state.signerInfo.userNm, state.signerInfo.mphone);
            //   console.log('passResult', passResult);

            //   if (passResult.result) {
            //     state.signerInfo.passVerified = passResult.result;
            //     state.signerInfo.passNo = passResult.data.passNo;
            //   } else {
            //     vm.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다.');
            //     return;
            //   }
            // }

            // state.signerInfo.agentIdntyImageCn = ozInputData[ozInputData.signedFormId];

            // if (state.signerInfo.agentIdntyImageCn === undefined) {
            //   state.signerInfo.agentIdntyImageCn = '';
            //   vm.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
            //   return;
            // }

            // if (!state.signerInfo.agentIdntyImageCn.trim()) {
            //   vm.$alert('계약서의 서명란에 디지털서명을 해야합니다.');
            //   return;
            // }
          } else {
            await vm.$alert('PC 환경에서 지원하지 않는 서명방식입니다. 모바일을 통해 서명을 진행해 주십시오.');
            return;
          }

          if (!signed) {
            state.loading = false;
            return;
          }

          if (!(await vm.$confirm('서명을 완료하시겠습니까?'))) {
            return;
          }

          state.loadingMessage = '서명을 완료하고 있습니다.';
          //state.loading = true;
          
          try {
            state.loading = true;

            await api.verify();

            state.loadingMessageTemplate = '서명이 완료된 문서를 가져오고 있습니다.';
            state.loadingMessage = state.loadingMessageTemplate;

            const p1 = api.getContractInfo();
            const p2 = api.getSignerInfo();

            await Promise.all([p1, p2]);

            if (state.viewOz) {
              await oz.reBind(true);
            }

            if (state.viewPdf) {
              await method.viewPdf();
            }

          } catch (ex) {
            console.log("ex",ex)
            //vm.$alert('계약서 서명에 실패하였습니다.');
          } finally {
            state.loading = false;
          }

        },
      },
    };

    // 반송
    const oRejectDialog = useDialog('oRejectDialog');
    const rejectDialogSetup = {
      async btnReject_click() {
        rejectDialogSetup.rejectDialog.state.reason = undefined;
        //console.log("state.contractInfo",state.contractInfo.approvalNo);
        const reason = await oRejectDialog.show();

        if (!reason) {
          return;
        }

        const data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSfx: state.tradeSfx,
          tradeName: state.signerInfo.tradeName,
          tradeType: state.signerInfo.tradeType,
          retrnResnCn: reason,
          pgmCd: '201900',
          chngPgmId: 'Viewer_Reject',

          approvalNo : (state.contractInfo.approvalNo == "") ? undefined : state.contractInfo.approvalNo, //품의번호 0230203
        };

        try {
          state.loadingMessage = '반송 중 입니다.';
          state.loading = true;

          // const p0 = new Promise((resolve, reject) => {
          //   setTimeout(() => {
          //     resolve();
          //   }, 3000);
          // });

          // await Promise.all([p0]);
          const r5 = await axios.post(apiUrl.reject, data);
          //console.log("r5",r5);
          await vm.$alert('반송이 완료되었습니다.');
          

          oPopup.emit('reject', {
            ctrtNo: data.ctrtNo,
            ctrtOdr: data.ctrtOdr,
            ctrtDivCd: data.ctrtDivCd,
            tradeNo: data.tradeNo,
            retrnResnCn: reason,
          });
          context.emit('reject', {
            ctrtNo: data.ctrtNo,
            ctrtOdr: data.ctrtOdr,
            ctrtDivCd: data.ctrtDivCd,
            tradeNo: data.tradeNo,
            retrnResnCn: reason,
          });

          state.loadingMessage = '정보를 가져오고 있습니다.';

          const p1 = api.getContractInfo();
          const p2 = api.getSignerInfo();

          await Promise.all([p1, p2]);

          oRejectDialog.hide();
        } catch (ex) {
          console.log('reject', ex);
        } finally {
          state.loading = false;
        }
      },
      async btnRejectByCust_click() {
        rejectDialogSetup.rejectDialog.state.reason = undefined;

        const reason = await oRejectDialog.show();

        if (!reason) {
          return;
        }

        const data = {
          ctrtNo: state.ctrtNo,
          ctrtOdr: state.ctrtOdr,
          ctrtDivCd: state.ctrtDivCd,
          tradeNo: state.tradeNo,
          tradeSfx: state.tradeSfx,
          tradeName: state.signerInfo.tradeName,
          tradeType: state.signerInfo.tradeType,
          retrnResnCn: reason,
          pgmCd: '201900',
          chngPgmId: 'Viewer_Reject',

          approvalNo : state.contractInfo.approvalNo, //품의번호 0230203
        };

        try {
          state.loadingMessage = '반송 중 입니다.';
          state.loading = true;

          await axios.post(apiUrl.rejectByCust, data);

          await vm.$alert('반송이 완료되었습니다.');

          oPopup.emit('reject', {
            ctrtNo: data.ctrtNo,
            ctrtOdr: data.ctrtOdr,
            ctrtDivCd: data.ctrtDivCd,
            tradeNo: data.tradeNo,
            retrnResnCn: reason,
          });
          context.emit('reject', {
            ctrtNo: data.ctrtNo,
            ctrtOdr: data.ctrtOdr,
            ctrtDivCd: data.ctrtDivCd,
            tradeNo: data.tradeNo,
            retrnResnCn: reason,
          });

          state.loadingMessage = '정보를 가져오고 있습니다.';

          const p1 = api.getContractInfo();
          const p2 = api.getSignerInfo();

          await Promise.all([p1, p2]);

          oRejectDialog.hide();
        } catch (ex) {
          console.log('rejectByCust', ex);
        } finally {
          state.loading = false;
        }
      },
      ...oRejectDialog.dialogRef,
      oRejectDialog_txtReason: ref(null),
      rejectDialog: {
        state: reactive({
          reason: undefined,
        }),
        dialog_opened() {
          rejectDialogSetup.rejectDialog.state.reason = '';
          rejectDialogSetup.oRejectDialog_txtReason.value.$refs.textbox.focus();
        },
        async btnReject_click() {
          if (
            !rejectDialogSetup.rejectDialog.state.reason ||
            rejectDialogSetup.rejectDialog.state.reason.length === 0
          ) {
            await vm.$alert('반송 사유를 입력해 주십시오.');

            rejectDialogSetup.oRejectDialog_txtReason.value.$refs.textbox.focus();

            return;
          }

          oRejectDialog.hide(rejectDialogSetup.rejectDialog.state.reason);
        },
      },
    };

    const method = {
      async btnSave_click() {
        try {
          state.loading = true;

          await api.save();

          await vm.$alert('임시 저장 되었습니다.');
        } catch (ex) {
          vm.$alert('임시 저장에 실패하였습니다.');
        } finally {
          state.loading = false;
        }
      },

      async btnAgree_click() {
        if(method.consortiumMemberChangeInfoCheck() != true){
          await vm.$alert('<span style="color:red;font-weight: 600;">공동수급 구성원 변경 통보서에 미서명한 전자계약이 존재합니다.</span>');
          return;
        }

        const message = '계약서를 확인하시겠습니까?';

        if (await vm.$confirm(message)) {
          try {
            state.loading = true;

            await api.agree();

            if (await vm.$confirm('계약서를 확인하였습니다. 계약서를 닫으시겠습니까?')) {
              oPopup.close();
              return;
            }

            document.location.reload();
          } catch (ex) {
            vm.$alert('계약서 확인 상태 저장에 실패하였습니다.');
          } finally {
            state.loading = false;
          }
        }
      },

      async viewPdf() {
        const hostUrl = new URL(window.location.href);
        let origin = '';

        if (hostUrl.host.indexOf('localhost') !== -1) {
          origin = 'http://localhost:8101';
        }
        if (hostUrl.host.indexOf('127.0.0.1') !== -1) {
          origin = 'http://127.0.0.1:8101';
        }

        const downloadUrl = new URL(state.contractInfo.dwldUrl);
        const url = `${origin}${downloadUrl.pathname}${downloadUrl.search}`;

        console.log('url', url);

        state.pdfViewerConfig.url = url;

        await nextTick();
      },
      consortiumMemberChangeInfoCheck() {
        //return true;
        //20230524 일단 막음 6월1주에 적용
        if(state.ctrtDivCd =='FOD_CT2' && state.consortiumContractInfo.fodCt2canSign == 'N'){              
          return false;
        }else{
          return true;
        }
      }
    };

    watch(
      () => state.loading,
      async newValue => {
        if (!newValue) {
          state.loadingMessageTemplate = undefined;
          state.loadingMessage = undefined;
        }
      }
    );

    watch(
      () => state.ozConfig.viewerReady,
      async (newValue, oldValue) => {
        if (newValue && !state.ozConfig.bind && newValue !== oldValue) {
          await oz.bind();
        }
      }
    );

    window.addEventListener('keyup', e => {
      if (e.ctrlKey && e.altKey && (e.key === 't' || e.key === 'T')) {
        state.showDebug = !state.showDebug;
      }
    });

    window.addEventListener('message', e => {
      console.log('message', e);

      if (e.data.msg !== undefined) {
        if (e.data.msg === 'requestOzParam') {
          // requestOzParam -> state.ozConfig.viewerReady = true -> watch -> oz.bind
          state.ozConfig.viewerReady = true;

          // state.loading = true;
          return;
        }

        if (e.data.msg === 'bind_start' && !state.ozConfig.bind) {
          // state.loading = false;
        }

        if (e.data.msg === 'bind_progress' && state.loading) {
          console.log('bind_progress', e.data.progress);
          if (
            state.loadingMessageTemplate !== undefined &&
            state.loadingMessageTemplate !== null &&
            state.loadingMessageTemplate.length > 0
          ) {
            state.loadingMessage = `${state.loadingMessageTemplate} (${e.data.progress}% / 100%)`;
          }
        }

        if (e.data.msg === 'bind_end') {
          state.ozConfig.bind = true;

          if (oz.bindPromise !== undefined) {
            oz.bindPromiseResolve();

            oz.bindPromise = undefined;
            oz.bindPromiseResolve = undefined;
            oz.bindPromiseReject = undefined;
          }

          state.loading = false;
        }
      }
    });

    onMounted(async () => {
      checkIE();
     
      if (shortUrlLinkType) {
        await decryptParameter(encryptedParameter);
      }

      const p1 = api.getContractInfo();
      const p2 = api.getSignerInfo();
      console.log("state.ctrtDivCd",state.ctrtDivCd);
      const p3 = (state.ctrtDivCd =='FOD_CT2') ? api.getConsortiumContractInfo() : null;
      await Promise.all([p1, p2, p3]);

      //관리자용임 20230113 lgksja
      if (sessionStorage.getItem('userInfo') !== null) {
        try {
          if ($isAdmin() && state.viewPdf){
            if(!(await vm.$confirm('pdf뷰어로 보시겠습니까?<br>(아니오 면 오즈뷰어)'))){
              state.isAdminOzView = true;
            }
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      

      // OZ
      if (state.viewOz || state.isAdminOzView ) {
        await oz.setConfig();

        if (state.canSign) {
          vm.$secuKit.init();
          vm.$secuKit.checkReady();
        }
        document.getElementById('ozFrame').src = state.ozConfig.viewerUrl;
      }

      // PDF
      if (state.viewPdf && !state.isAdminOzView) {
        await method.viewPdf();

        state.loading = false;
      }
    });

    return {
      state,
      ...oPopup.popupRef,
      ...method,
      ...signDialogSetup,
      ...rejectDialogSetup,
    };
  },
});
</script>

<template>
  <iui-window-popup
    ref="oPopup"
    title="제목"
    no-title-bar
    hide-close-button
    :loading="state.loading"
    :loading-message="state.loadingMessage"
  >
    <template #button-center>
      <iui-button v-if="state.canSign" @click="btnSign_click">서명</iui-button>
      <iui-button v-if="state.canReject" @click="btnReject_click">반송</iui-button>
      <iui-button v-if="state.canRejectByCust" @click="btnRejectByCust_click">반송</iui-button>
    </template>
    <template #button-right>
      <iui-button v-if="state.canSave" @click="btnSave_click">임시 저장</iui-button>
      <iui-button v-if="state.canAgree" @click="btnAgree_click">계약서 확인</iui-button>
    </template>

    <iui-content-box>
      <template #title v-if="state.shortUrlLinkType !== undefined">VIEWER</template>
      <template #title-left v-if="state.shortUrlLinkType !== undefined">
        <font style="color:#FF6666; font-weight: bold;">(더 많은 정보는 로그인 후 사용 바랍니다.)</font>
      </template>

      <iui-container type="css-flex">
        <i-row>
          <i-col v-if="!state.viewPdf || state.isAdminOzView" style="overflow: hidden;">
            <iframe
              id="ozFrame"
              name="ozFrame"
              title="OZ Viewer"
              style="width: 100%; height: 100%; border: 0px solid red; overflow: hidden;"
            ></iframe>
          </i-col>
          <i-col v-if="state.viewPdf && !state.isAdminOzView" style="overflow: hidden;">
            <iframe
              id="pdfViewer"
              name="pdfViewer"
              :src="state.pdfViewerConfig.viewerUrl"
              title="PDF Viewer"
              style="width: 100%; height: 100%; border: 0px solid red; overflow: hidden;"
            ></iframe>
          </i-col>
        </i-row>
        <i-row v-show="state.showDebug" height="180px">
          <i-col>
            <iui-container type="table">
              <i-row>
                <i-col-header>
                  호출값
                </i-col-header>
                <i-col>
                  <iui-textbox v-model="state.ctrtNo" label="ctrtNo" width="150px" label-width="70px" readonly />
                  <iui-textbox v-model="state.ctrtOdr" label="ctrtOdr" width="150px" label-width="70px" readonly />
                  <iui-textbox v-model="state.ctrtDivCd" label="ctrtDivCd" width="150px" label-width="70px" readonly />
                  <iui-textbox v-model="state.tradeNo" label="tradeNo" width="150px" label-width="70px" readonly />
                  <iui-textbox v-model="state.tradeSfx" label="tradeSfx" width="150px" label-width="70px" readonly />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  contractInfo
                </i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.ctrtSttusCd"
                    label="ctrtSttusCd"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.contractInfo.ctrtOrginlHashcd"
                    label="ctrtOrginlHashcd"
                    label-width="70px"
                    readonly
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="3">
                  signerInfo
                </i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.signerInfo.tradeNo"
                    label="tradeNo"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.agreSttusCd"
                    label="agreSttusCd"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.buyagnt"
                    label="buyagnt"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.customer"
                    label="customer"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <iui-textbox
                    v-model="state.signerInfo.signOrReject"
                    label="signOrReject"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.signDt"
                    label="signDt"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.signerSeCd"
                    label="signerSeCd"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.signMthCd"
                    label="signMthCd"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <iui-textbox
                    v-model="state.signerInfo.canAgree"
                    label="canAgree"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                  <iui-textbox
                    v-model="state.signerInfo.canSign"
                    label="canSign"
                    width="150px"
                    label-width="70px"
                    readonly
                  />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-content-box>

    <iui-dialog ref="oSignMethodDialog" title="서명 방식 선택" width="200px" height="50%">
      <!--<iui-select v-model="state.signerInfo.signMtdCd" :items="state.codeList.signMethod" placeholder="선택" />-->

      <iui-button
        v-for="signMethod in state.codeList.signMethod"
        :key="signMethod.value"
        @click="signDialog.btnSignMethod_click(signMethod.value)"
        >{{ signMethod.text }}</iui-button
      >
    </iui-dialog>

    <iui-dialog ref="oRejectDialog" title="반송" width="50%" height="50%" @opened="rejectDialog.dialog_opened">
      <template #button-center>
        <iui-button @click="rejectDialog.btnReject_click">반송</iui-button>
      </template>

      <iui-textbox
        ref="oRejectDialog_txtReason"
        v-model="rejectDialog.state.reason"
        type="multi"
        placeholder="반송 사유를 입력해주세요."
        height="100%"
      />
    </iui-dialog>
  </iui-window-popup>
</template>

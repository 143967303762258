<template>
  <pmis-page :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>목록</template>
            <ProgHedofcMenu />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <ProgDetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import {mapGetters} from 'vuex';
import ProgHedofcMenu from './components/progHedofcMenu.vue';
import ProgDetailInfo from './components/progDetailInfo.vue';
export default {
  components: {ProgDetailInfo, ProgHedofcMenu},
  data: function() {
    return {
      cmmBtns: [
        {name: '조회', class: '', callback: this.onSearch},
        {name: '신규', class: '', callback: this.onAdd},
        {name: '저장', class: 'b', callback: this.onSave},
        {name: '삭제', class: '', callback: this.onDel},
      ],
    };
  },
  computed: {
    ...mapGetters('menu', {
      system: 'system',
    }),
  },

  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'onSearch'});
    },
    onAdd: function() {
      if (this.system.lvl != '4') {
        this.system.uppMnuNm = this.system.mnuNm;
        this.system.uppMnuCd = this.system.mnuCd;
      }
      if (this.system.lvl == '3' || this.system.lvl == '4') {
        this.system.lvl = '4';
      }

      this.system.mnuCd = '';
      this.system.mnuNm = '';
      this.system.mnuNmEng = '';
      this.system.mnuSqn = '';
      this.system.svYn = 'Y';
      this.system.vwYn = 'Y';
      this.system.delYn = 'Y';
      this.system.prnYn = 'Y';
      this.system.useDs = 'null';
      this.system.mnuPth = '';
      this.system.para = '';
      this.system.cud = 1;
    },
    onSave() {
      if (this.system.cud == 0) this.system.cud = 2;
      let param = {
        cud: this.system.cud,
        beforeMnuCd: this.system.beforeMnuCd,
        beforeUppMnuCd: this.system.beforeUppMnuCd,
        uppMnuCd: document.getElementsByName('uppMnuCd')[0].value,
        uppMnuNm: document.getElementsByName('uppMnuNm')[0].value,
        mnuCd: document.getElementsByName('mnuCd')[0].value,
        mnuNm: document.getElementsByName('mnuNm')[0].value,
        mnuNmEng: document.getElementsByName('mnuNmEng')[0].value,
        mnuPth: document.getElementsByName('mnuPth')[0] ? document.getElementsByName('mnuPth')[0].value : null,
        para: document.getElementsByName('para')[0] ? document.getElementsByName('para')[0].value : null,
        mnuSqn: document.getElementsByName('mnuSqn')[0].value,
        vwYn: document.getElementsByName('vwYn')[0].checked ? 'Y' : 'N',
        svYn: document.getElementsByName('svYn')[0].checked ? 'Y' : 'N',
        delYn: document.getElementsByName('delYn')[0].checked ? 'Y' : 'N',
        prnYn: document.getElementsByName('prnYn')[0].checked ? 'Y' : 'N',
        useDs:
          document.getElementsByName('useDs')[0].value == 'null' ? '' : document.getElementsByName('useDs')[0].value,
      };

      axios
        .post('/baseMenu/saveMenu', param)
        .then(response => {
          if (response.status == 200) {
            if (response.data.cud != 3) {
              this.callEvent({name: 'onSearch', param: response.data.mnuCd});
            } else {
              this.callEvent({name: 'onSearch'});
            }
          }
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },
    async onDel() {
      if (await this.$confirm('삭제 하시겠습니까?')) {
        this.system.cud = 3;
        this.onSave();
      }
    },
  },
};
</script>

<style scoped></style>

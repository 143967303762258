<template>
	<div id="wrap">
		<iui-header></iui-header>
		<iui-side></iui-side>
		<iui-main></iui-main>
	</div>
</template>

<script>
import IuiHeader from './IuiHeader.vue';
import IuiSide from './IuiSide.vue';
import IuiMain from './IuiMain.vue';
import {mapGetters, mapActions} from 'vuex';
export default {
	name: 'Home',
	async created() {
		let param = {pgmCd: this.userInfo.pgmCd};


		/*
		let response = await axios
			.post('/menu/selectMenuWithAuthList', param)
			.catch((e) => console.error('ERR!!! : ', e));
		var tdata = response.data;
		console.log(JSON.stringify(tdata));
		let menuList = await $_treeModel(Array.from(tdata), 'PGM000', 'mnuCd', 'uppMnuCd');
		this.setMenu(menuList);
		*/
		let response = await axios
			.post('/scdUser/selectMenuWithAuthList', param)
			.catch((e) => console.error('ERR!!! : ', e));
		var tdata = response.data;

		// isSelected 값 설정 + 루트메뉴 구하기
		var finder = {};
		var row;
		var i = 0;
		for(i=0;i<tdata.length;i++) {
			row = tdata[i];

			// 버튼 권한 넣기
			// start

			// eslint-disable-next-line
			var buttonId = row.buttonId+"NNNNNNNNN";
			// eslint-disable-next-line
			var ynC = row.buttonId.charAt(1)=='Y'?'Y':'N';
			var ynR = row.buttonId.charAt(2)=='Y'?'Y':'N';
			var ynU = row.buttonId.charAt(3)=='Y'?'Y':'N';
			var ynD = row.buttonId.charAt(4)=='Y'?'Y':'N';
			var ynP = row.buttonId.charAt(5)=='Y'?'Y':'N';
			var ynX = row.buttonId.charAt(6)=='Y'?'Y':'N';

			row.vwYn = ynR;
			row.userVwYn = ynR;

			row.svYn = ynU;
			row.userSvYn = ynU;

			row.delYn = ynD;
			row.userDelYn = ynD;

			row.prnYn = ynP;
			row.userPrnYn = ynP;

			row.excelYn = ynX;
			row.userExcelYn = ynX;

			// end

			row.isSelected = false;
			var menuId = row.menuId;
			var parentMenuId = row.parentMenuId;
			finder[menuId] = { parentMenuId:parentMenuId, row:row };
		}
		for(i=0;i<tdata.length;i++) {
			row = tdata[i];
			var frow = row;
			var whileVal = 1;
			do {
				if(frow==null) {
					row.rootMenuId = ""; break;
				}
				if(frow.parentMenuId=='MNU00000') {
					row.rootMenuId = frow.menuId;
					break;
				}

				frow = finder[frow.parentMenuId].row;
			} while( whileVal == 1 );
		}

		let menuList = await $_treeModel(Array.from(tdata), 'MNU00000', 'menuId', 'parentMenuId');
		this.setMenu( menuList );

		// let response = await axios.post('/menu/selectMenuList', param).catch((e) => console.error('ERR!!! : ', e));
		// this.setMenu(Array.from(response.data[0].Items));
		this.addEvent([
			{name: 'srchBtn_show', func: this.onSrchBtn_Show}
		]);
	},
	components: {
		'iui-header': IuiHeader,
		'iui-side': IuiSide,
		'iui-main': IuiMain,
	},
	computed: {
		...mapGetters('layout', {
			isSideOpen: 'isSideOpen',
		}),
	},
	watch: {
		isSideOpen(sideOpen) {
			if (sideOpen) {
				this.$el.querySelector('#cheader').classList.remove('side_close');
				this.$el.querySelector('#contents').classList.remove('side_close');
				this.$el.querySelector('#gnb').classList.remove('side_close');
				this.$el.querySelector('#wrap .nav').querySelector('a').classList.remove('btn_slide_r');
				this.$el.querySelector('#wrap .nav').querySelector('a').classList.add('btn_slide_l');
				this.$el.querySelector('#wrap .nav').classList.remove('side_close');
			} else {
				this.$el.querySelector('#cheader').classList.add('side_close');
				this.$el.querySelector('#contents').classList.add('side_close');
				this.$el.querySelector('#gnb').classList.add('side_close');
				this.$el.querySelector('#wrap .nav').querySelector('a').classList.remove('btn_slide_l');
				this.$el.querySelector('#wrap .nav').querySelector('a').classList.add('btn_slide_r');
				this.$el.querySelector('#wrap .nav').classList.add('side_close');
			}
		},
	},
	beforeMount() {},
	mounted() {
		if (this.isSideOpen) {
			this.$el.classList.add('nav_c');
		} else {
			this.$el.classList.remove('nav_c');
		}


	},

	methods: {
		...mapActions('menu', ['setMenu']),
		onSrchBtn_Show() {
			alert("onSrchBtn_Show");
		}
	},
};
</script>

<template>
  <pmis-content-box>
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              복제원본
            </template>
            <template v-slot:header-left>
              <label>회사구분</label
              ><iui-select-old p-code="0279" :value.sync="frmGb" @change="onFrmGbChange('origin')" />
            </template>

            <iui-container type="ibsheet" :id="elId" />
          </pmis-content-box>
        </i-col>
        <i-col width="50px" style="display: flex; justify-content: center; align-items: center">
          <iui-button value="→" @click="onCopy" />
          <i-spacer />
          <iui-button value="←" @click="onDelete" />
        </i-col>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              복제대상
            </template>
            <template v-slot:header-left v-if="isBase">
              <label>회사구분</label
              ><iui-select-old
                p-code="0279"
                exclude-items="000000"
                :value.sync="frmGb2"
                defaultCd="S"
                @change="onFrmGbChange('target')"
              />
            </template>
            <template v-slot:header-left v-else>
              <label>[{{ searchInfo.pgmCd }}] {{ searchInfo.frmNm }}</label>
            </template>

            <iui-container type="ibsheet" :id="elId2" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    isBase: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      elId2: 'sheetDiv2' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      frmGb: '000000',
      frmGb2: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OTP = {
        Cfg: {
          SearchMode: 2,
          MainCol: 'mnuNm',
        },
        Cols: [
          {
            Header: '선택',
            Type: 'Bool',
            Name: 'chk',
            Width: '50',
            Align: 'Center',
            CanEdit: 1,
            OnClick: this.onSelectChk,
          },
          {
            Header: '프로그램명',
            Type: 'Text',
            Name: 'mnuNm',
            MinWidth: '200',
            Align: 'Left',
            RelWidth: 1,
            CanEdit: 0,
          },
          {
            Header: '조회',
            Type: 'Text',
            Name: 'vwYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '저장',
            Type: 'Text',
            Name: 'svYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '삭제',
            Type: 'Text',
            Name: 'delYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '출력',
            Type: 'Text',
            Name: 'prnYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OTP, // 초기화 구문 변수
      });

      const OTP2 = {
        Cfg: {
          SearchMode: 2,
          MainCol: 'mnuNm',
        },
        Cols: [
          {
            Header: '선택',
            Type: 'Bool',
            Name: 'chk',
            Width: '50',
            Align: 'Center',
            CanEdit: 1,
            OnClick: this.onSelectChk2,
          },
          {
            Header: '프로그램명',
            Type: 'Text',
            Name: 'mnuNm',
            MinWidth: '200',
            Align: 'Left',
            RelWidth: 1,
            CanEdit: 0,
          },
          {
            Header: '조회',
            Type: 'Text',
            Name: 'vwYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '저장',
            Type: 'Text',
            Name: 'svYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '삭제',
            Type: 'Text',
            Name: 'delYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
          {
            Header: '출력',
            Type: 'Text',
            Name: 'prnYn',
            Width: '70',
            Align: 'Center',
            CanEdit: 0,
            Format: await $getCodeIBSheetFormat(this, 'YESNO'),
          },
        ],
      };

      this.sheet2 = IBSheet.create({
        id: this.sheetId2, // 시트 객체 ID
        el: this.elId2, // 시트를 생성할 DIV객체 ID
        options: OTP2, // 초기화 구문 변수
      });

      this.onSearch('origin');
      if (!this.isBase) this.onSearch('target');
    },
    onSearch(target) {
      let url;
      let param;

      if (target == 'origin') {
        url = '/baseMenu/selectMenuList';
        param = {
          frmGb: this.frmGb,
        };
      } else {
        if (this.isBase) {
          url = '/baseMenu/selectMenuList';
          param = {
            frmGb: this.frmGb2,
          };
        } else {
          url = '/menu/selectMenuList';
          param = {
            pgmCd: this.searchInfo.pgmCd,
          };
        }
      }

      axios
        .post(url, param)
        .then(response => {
          this.menuList = Array.from(response.data);
          if (target == 'origin') {
            this.sheet.loadSearchData(this.menuList, 0);
          } else {
            this.sheet2.loadSearchData(this.menuList, 0);
          }
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },
    onFrmGbChange(target) {
      this.onSearch(target);
    },
    onSelectChk(e) {
      setTimeout(() => {
        //1. 하위 모든항목 체크
        let nextRow = e.sheet.getNextRow(e.row);
        while (nextRow) {
          if (e.row.Level < nextRow.Level) {
            e.sheet.setCheck(nextRow, e.col, e.row[e.col]);
            nextRow = e.sheet.getNextRow(nextRow);
          } else {
            break;
          }
        }

        //2. 자식노드 체크시 부모노드 체크
        if (e.row.Level > 0) {
          if (e.row[e.col]) {
            //Check
            let parentNode = e.row.parentNode;
            while (parentNode) {
              if (parentNode.Level < 0) break;

              e.sheet.setCheck(parentNode, e.col, 1);
              parentNode = parentNode.parentNode;
            }
          } else {
            //UnCheck 보무노드 체크 해지하기전 다른 자식노드 체크여부 확인후 UnCheck한다.
            let nextNode = e.row.nextSibling;
            let isChecked = false;
            while (nextNode) {
              if (nextNode[e.col]) {
                isChecked = true;
                break;
              }
              nextNode = nextNode.nextSibling;
            }

            let prevNode = e.row.previousSibling;
            while (prevNode) {
              if (prevNode[e.col]) {
                isChecked = true;
                break;
              }
              prevNode = prevNode.previousSibling;
            }

            if (!isChecked) {
              let parentNode = e.row.parentNode;
              while (parentNode) {
                if (parentNode.Level < 0) break;

                e.sheet.setCheck(parentNode, e.col, 0);
                let nextNodeInParent = parentNode.nextSibling;
                let isCheckedInParent = false;
                while (nextNodeInParent) {
                  if (nextNodeInParent[e.col]) {
                    isCheckedInParent = true;
                    break;
                  }
                  nextNodeInParent = nextNodeInParent.nextSibling;
                }

                let prevNodeInParent = parentNode.previousSibling;
                while (prevNodeInParent) {
                  if (prevNodeInParent[e.col]) {
                    isCheckedInParent = true;
                    break;
                  }
                  prevNodeInParent = prevNodeInParent.previousSibling;
                }

                if (isCheckedInParent) break;

                parentNode = parentNode.parentNode;
              }
            }
          }
        }
      }, 10);
    },
    onSelectChk2(e) {
      setTimeout(() => {
        //1. 하위 모든항목 체크
        let nextRow = e.sheet.getNextRow(e.row);
        while (nextRow) {
          if (e.row.Level < nextRow.Level) {
            e.sheet.setCheck(nextRow, e.col, e.row[e.col]);
            nextRow = e.sheet.getNextRow(nextRow);
          } else {
            break;
          }
        }

        //2. 하위항목 체크해지
        if (e.row.Level > 0) {
          if (!e.row[e.col]) {
            //UnCheck 보무노드 체크 해지하기전 다른 자식노드 체크여부 확인후 UnCheck한다.
            let nextNode = e.row.nextSibling;
            let isChecked = false;
            while (nextNode) {
              if (nextNode[e.col]) {
                isChecked = true;
                break;
              }
              nextNode = nextNode.nextSibling;
            }

            let prevNode = e.row.previousSibling;
            while (prevNode) {
              if (prevNode[e.col]) {
                isChecked = true;
                break;
              }
              prevNode = prevNode.previousSibling;
            }

            if (!isChecked) {
              let parentNode = e.row.parentNode;
              while (parentNode) {
                if (parentNode.Level < 0) break;

                e.sheet.setCheck(parentNode, e.col, 0);
                let nextNodeInParent = parentNode.nextSibling;
                let isCheckedInParent = false;
                while (nextNodeInParent) {
                  if (nextNodeInParent[e.col]) {
                    isCheckedInParent = true;
                    break;
                  }
                  nextNodeInParent = nextNodeInParent.nextSibling;
                }

                let prevNodeInParent = parentNode.previousSibling;
                while (prevNodeInParent) {
                  if (prevNodeInParent[e.col]) {
                    isCheckedInParent = true;
                    break;
                  }
                  prevNodeInParent = prevNodeInParent.previousSibling;
                }

                if (isCheckedInParent) break;

                parentNode = parentNode.parentNode;
              }
            }
          }
        }
      }, 10);
    },
    onCopy() {
      if (this.isBase && !this.frmGb2) {
        this.$alert('복제대상 회사구분을 선택해 주세요.');
        return;
      }
      let rows = this.sheet.getRowsByChecked('chk');
      if (!rows.length) {
        this.$alert('복제할 프로그램을 선택해 주세요.');
        return;
      }
      let items = [];
      rows.forEach(el => {
        items.push({
          mnuCd: el.mnuCd,
          mnuNm: el.mnuNm,
          mnuNmEng: el.mnuNmEng,
          mnuPth: el.mnuPth,
          para: el.para,
          malPth: el.malPth,
          vwYn: el.vwYn,
          svYn: el.svYn,
          delYn: el.delYn,
          prnYn: el.prnYn,
          mnuSqn: el.mnuSqn,
          uppMnuCd: el.uppMnuCd,
          useDs: el.useDs,
          ...(this.isBase ? {frmGb: this.frmGb2} : {pgmCd: this.searchInfo.pgmCd}),
        });
      });

      let url = this.isBase ? '/baseMenu/copyMenu' : '/menu/copyMenu';
      let param = {Items: items};
      axios
        .post(url, param)
        .then(response => {
          if (response.data) {
            this.onSearch('target');
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    },

    onDelete() {
      let rows = this.sheet2.getRowsByChecked('chk');
      if (!rows.length) {
        this.$alert('삭제할 프로그램을 선택해 주세요.');
        return;
      }
      let items = [];
      rows.forEach(el => {
        items.push({
          beforeMnuCd: el.mnuCd,
          ...(this.isBase ? {frmGb: this.frmGb2} : {pgmCd: this.searchInfo.pgmCd}),
        });
      });

      let url = this.isBase ? '/baseMenu/deleteMenu' : '/menu/deleteMenu';
      let param = {Items: items};
      axios
        .post(url, param)
        .then(response => {
          if (response.data) {
            this.onSearch('target');
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    },
  },
};
</script>

<style></style>

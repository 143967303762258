<template>
  <pmis-content-box>
    <template v-slot:title>투입현장정보</template>
    <template v-slot:title-right>
      <div v-if="detailInfo.laborDs != '000004'">
        <iui-button value="신규" @click="onAdd" />
        <iui-button v-if="!status.isNew" value="저장" @click="onSave" />
        <iui-button value="삭제" @click="onDelete" />
      </div>
    </template>
    <iui-container type="ibsheet" :id="elId" />
    <iui-modal-old name="prjModal" :btns="modalBtns" :width="'1000'" :height="'500'">
      <iui-searchmodal :type="'prj'"></iui-searchmodal>
    </iui-modal-old>
    <iui-modal-old name="subCodeModal" :btns="modalBtns" :width="'1000'" :height="'500'">
      <iui-searchmodal :type="'workGroup'"></iui-searchmodal>
    </iui-modal-old>
  </pmis-content-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['keyInfo', 'detailInfo', 'status', 'subConInfo']);
    $mapMutations(this, ['setStatus']);
  },
  created() {
    this.addEvent([
      {name: 'InputPjt_search', func: this.onSearch},
      {name: 'InputPjt_initSheet', func: this.initSheet},
      {name: 'inputPrj_getSaveJson', func: this.getSaveJson},
      {name: 'addRowInputPrj', func: this.addRowInputPrj},
    ]);
  },
  data: function() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      modalNm: '',
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      focusKeyCode: '',
    };
  },
  mounted() {
    const me = this;
    const OPT = {
      Cfg: {
        CanEdit: 1, // 1 : 전체 편집 가능 (default),
        UseButton: 1,
        ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
        FitWidth: true,
      },
      Cols: [
        {
          Header: '선택',
          Type: 'Bool',
          Name: 'cbx',
          Width: 50,
          CanEdit: true,
        },
        {
          Header: '현장코드',
          Name: 'prjCd',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1.2,
          CanEdit: false,
          Button: '',
          ButtonText: '<input type="button" value="검색"/>',
          WidthPad: 37,
          OnClickSide: () => me.onShowModal('prj'),
        },
        {
          Header: '현장명',
          Name: 'prjNm',
          Type: 'Text',
          RelWidth: 2,
          CanEdit: false,
        },
        {
          Header: '작업반코드',
          Name: 'subCode',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1.2,
          CanEdit: false,
          Button: '',
          ButtonText: '<input type="button" value="검색"/>',
          WidthPad: 37,
          OnClickSide: () => me.onShowModal('subCode'),
        },
        {
          Header: '작업반명',
          Name: 'subCodeName',
          Type: 'Text',
          RelWidth: 2,
          CanEdit: false,
        },
        {
          Header: '투입일자',
          Name: 'rlseDate',
          Extend: this.$ibsheetHelper.preset.date,
          CanEdit: true,
        },
        {
          Header: '철수(예정)일자',
          Name: 'outDate',
          Extend: this.$ibsheetHelper.preset.date,
          CanEdit: true,
        },
        {Name: 'pgmCd', Visible: 0},
        {Name: 'empNo', Visible: 0},
        {Name: 'labtDailyReportCount', Visible: 0},
      ],
      Events: {
        onSearchFinish: me.grid_searchFinish,
      },
    };

    me.sheet = IBSheet.create({
      id: me.sheetId,
      el: me.elId,
      options: OPT,
    });

    me.$ibsheetHelper.bindDefaultDeleteEvent(me.sheet); // 삭제 이벤트
  },
  methods: {
    async onSearch() {
      let response = await axios.post('/worker/selectInputPrjList', this.keyInfo);
      this.sheet.loadSearchData(response.data);
    },
    grid_searchFinish() {
      this.setStatus({isEnableLaborDs: 0 < this.sheet.getTotalRowCount() ? false : true});
    },
    onAdd() {
      if (!this.checkStatus()) return;

      this.setStatus({isEnableLaborDs: false});

      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.detailInfo.pgmCd, 1);
      this.sheet.setValue(addRow, 'empNo', this.detailInfo.empNo, 1);
      this.sheet.setAttribute(addRow, 'prjCd', 'Button', this.status.isPrjModal ? 'Html' : '', 1);
      this.sheet.setAttribute(addRow, 'subCode', 'Button', this.status.isSubcodeModal ? 'Html' : '', 1);
    },
    async onSave() {
      // if (!this.checkStatus()) return;

      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다');
        return;
      }

      if (await this.$confirm('저장하시겠습니까?')) {
        let inputPrjSaveList = this.sheet.getSaveJson().data;
        let response = await axios
          .post('/worker/saveInputPrjList', {inputPrjSaveList})
          .catch(e => console.error('저장을 실패하였습니다. : ', e));

        if (response.status == 200) {
          this.focusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert('저장을 실패하였습니다.');
        }
      }
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },
    onDelete() {
      if (!this.checkStatus()) return;

      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다');
        return;
      }

      for (let row of checkedRows) {
        if (row.labtDailyReportCount > 0) {
          this.$alert(`해당 근로자의 출역정보가 존재합니다. 삭제할 수 없습니다<br/> - 현장코드: ${row.prjCd}`);
          return;
        }
      }

      checkedRows.forEach(row => this.sheet.deleteRow(row));
      this.setStatus({isEnableLaborDs: 0 < this.sheet.getTotalRowCount() ? false : true});
    },
    checkStatus() {
      // if (this.status.isNew) {
      //   this.$alert('신규 입력인 경우 근로자 정보를 먼저 입력해야합니다.');
      //   return false;
      // } else
      if (this.status.isChangedLaborDs) {
        this.$alert('근로자구분이 변경되었습니다.<br/>변경된 값을 저장한 후에 진행하시기 바랍니다.');
        return false;
      } else {
        return true;
      }
    },
    initSheet() {
      this.sheet.removeAll();
    },
    onShowModal(modalNm) {
      this.modalNm = modalNm + 'Modal';
      this.$store.commit('setModalParam', {prjCd: this.sheet.getFocusedRow().prjCd});
      this.$modal.show(this.modalNm);
    },
    onConfirm() {
      let focusedRow = this.sheet.getFocusedRow();
      if (this.modalNm == 'prjModal') {
        this.callEvent({
          name: 'onFocusedRowDataOnContract',
          param: data => {
            this.sheet.setValue(focusedRow, 'prjCd', data.prjCd, 1);
            this.sheet.setValue(focusedRow, 'prjNm', data.prjNm, 1);
            let duplicateRows = this.sheet.getRowsByDup('prjCd', false, false, false);
            if (duplicateRows.length > 0) {
              this.sheet.setValue(focusedRow, 'prjCd', '', 1);
              this.sheet.setValue(focusedRow, 'prjNm', '', 1);
              this.$alert('이미 존재하는 현장입니다.');
              return;
            }
            this.$modal.hide(this.modalNm);
          },
        });
      } else if (this.modalNm == 'subCodeModal') {
        this.callEvent({
          name: 'WorkGroup_ContractList_confirm',
          param: data => {
            this.sheet.setValue(focusedRow, 'prjCd', data.prjCd, 1);
            let duplicateRows = this.sheet.getRowsByDup('prjCd', false, false, false);
            if (duplicateRows.length > 0) {
              this.sheet.setValue(focusedRow, 'prjCd', '', 1);
              this.$alert('이미 존재하는 현장입니다.');
              return;
            }
            this.sheet.setValue(focusedRow, 'prjNm', data.prjNm, 1);
            this.sheet.setValue(focusedRow, 'subCode', data.subCode, 1);
            this.sheet.setValue(focusedRow, 'subCodeName', data.subCodeName, 1);
            this.$modal.hide(this.modalNm);
          },
        });
      }
    },
    async addRowInputPrj() {
      let delRow = this.sheet.getRowByIndex(this.sheet.getTotalRowCount());
      await this.sheet.deleteRow(delRow);
      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.detailInfo.pgmCd, 1);
      this.sheet.setValue(addRow, 'empNo', this.detailInfo.empNo, 1);
      this.sheet.setValue(addRow, 'prjCd', this.subConInfo.prjCd, 1);
      this.sheet.setValue(addRow, 'prjNm', this.subConInfo.prjNm, 1);
      this.sheet.setValue(addRow, 'subCode', this.subConInfo.subCode, 1);
      this.sheet.setValue(addRow, 'subCodeName', this.subConInfo.subCodeName, 1);
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select-old p-code="0279" exclude-items="000004,000009" :value.sync="searchFrmGb" defaultCd="A" />
      <iui-select-old :items="searchSeItems" :value.sync="searchSe" defaultCd="S" />
      <iui-text :value.sync="searchNm" />
      <iui-checkbox-group-old
        name="curUseDs"
        width="120px"
        :value.sync="closeAt"
        :items="[{value: true, label: '종료포함'}]"
        v-if="!isApproval"
      />
    </template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchFrmGb: '',
      searchSe: '',
      searchNm: '',
      searchSeItems: [
        {text: '사업자번호', value: 'bsnNo'},
        {text: '업체명', value: 'frmNm'},
        {text: '대표자명', value: 'rpsvNm'},
      ],
      isApproval: this.$store.getters['mdi/currentUrls'].para == 'regist',
      closeAt: [],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo']);
    $mapMutations(this, ['setCompanyInfo']);
  },

  created() {
    this.addEvent([{name: 'cmpList_selectCompanyNotConfirmList', func: this.selectCompanyNotConfirmList}]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 0, // 1: 전체 편집 가능 (default)
        },
        Cols: [
          {
            Header: '선택',
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            CanEdit: true,
            Visible: this.isApproval,
          },
          {
            Header: '회사구분',
            Name: 'frmGb',
            Type: 'Text',
            Width: 90,
            Format: await $getCodeIBSheetFormat(this, '0279'),
          },
          {
            Header: '회사명',
            Name: 'frmNm',
            Type: 'Text',
            RelWidth: 1,
          },
          {
            Header: '대표자명',
            Name: 'rpsvNm',
            Type: 'Text',
            Width: 90,
            Align: 'center',
          },
          {
            Header: '요청일시',
            Name: 'rgDt',
            Type: 'Text',
            Width: 110,
            Align: 'center',
            Visible: this.isApproval,
          },
          {
            Header: '종료여부',
            Name: 'clostAt',
            Type: 'Text',
            Width: 110,
            Align: 'center',
            Visible: !this.isApproval,
          },
        ],
      };

      this.sheet = IBSheet.create({id: this.sheetId, el: this.elId, options: OPT});

      this.sheet.bind('onDataLoad', e => {
        if (!e.result) {
          this.callEvent({name: 'companyRegist_onDetailLoading', param: false});
        }
      });

      this.sheet.bind('onFocus', e => {
        if (e.row.Kind == 'Data') {
          this.callEvent({name: 'selectCompanyInfo', param: {pgmCd: e.row.pgmCd}});
        }
      });

      this.selectCompanyNotConfirmList();
    },
    selectCompanyNotConfirmList() {
      let param = {
        frmGb: this.searchFrmGb,
        searchSe: this.searchSe,
        searchNm: this.searchNm,
        closeAt: this.closeAt[0],
      };

      let url = this.isApproval ? '/companyRegist/selectCompanyNotConfirmList' : '/companyRegist/selectCompanyList';
      axios.post(url, param).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  },
};
</script>

<style scoped></style>

/* NE > 전자계약 > 목록 * /
<script>
import {reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js';
import moment from 'moment';

var tmpId = "";
// prettier-ignore
const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  LeftCols: [
    // { Header: ['보기', '보기'], Name: '보기', Type: 'Button', Width: 50, ButtonText: '보기', },
    { Header: ['보기', '보기'], Name: '보기', Type: 'Button', Width: 50,ButtonText: '',  Formula: useFormula2 , },
    { Header: ['보기', '보기'], Name: 'contEmpId', Type: 'Text', Width: 80, Align: 'Center' , Visible: 0},
    { Header: ['계약서\n형태', '계약서\n형태'], Name: 'contDocTypeName', Type: 'Text', Width: 60, Align: 'Center', },
    { Header: ['서명\n타입', '서명\n타입'], Name: 'contSignTypeName', Type: 'Text', Width: 50, Align: 'Center', },
    { Header: ['구분', '구분'], Name: 'ctrtDivNm', Type: 'Text', Width: 100, Align: 'Left', Visible: 0 },
    { Header: ['계약번호', '계약번호'], Name: 'ctrtNo', Type: 'Text', Width: 150, Align: 'Center', },
    { Header: ['계약번호', '차수'], Name: 'ctrtOdr', Type: 'Text', Width: 50, Align: 'Center', },
  ],
  Cols: [
    { Header: ['프로젝트', '프로젝트'], Name: 'projectNo', Type: 'Text', Width: 100, Align: 'Center',FormulaRow: fr => {return useDialog().progressCount(fr);}, },
    { Header: ['프로젝트', '프로젝트명'], Name: 'projectName', Type: 'Text', Width: 250, Align: 'Left', },
    { Header: ['고객', '고객번호'], Name: 'custTradeNo', Type: 'Text', Width: 80, Align: 'Center', Formula: 'Row.custTradeNo == "0000000" ? "" : Row.custTradeNo', },
    { Header: ['고객', '고객명'], Name: 'custTradeName', Type: 'Text', Width: 200, Align: 'Left', },

    { Header: ['3PI', '거래선번호'], Name: 'trade3piNo', Type: 'Text', Width: 80, Align: 'Center', Visible: 0 },
    { Header: ['3PI', '업체명'], Name: 'trade3piName', Type: 'Text', Width: 250, Align: 'Left', },

    { Header: ['계약일자', '계약일자'], Name: 'contDate', Type: 'Date', Width: 100, Align: 'Center', Format: 'yyyy-MM-dd', },
    { Header: ['계약납기', '계약납기'], Name: 'deliveryDate', Type: 'Date', Width: 100, Align: 'Center', Format: 'yyyy-MM-dd', },
    { Header: ['계약금액', '계약금액'], Name: 'contTotAmt', Type: 'Int', Width: 100, Format: '#,###', Align: 'Right',},
  ],
  RightCols: [
    { Header: ['계약담당자', '계약담당자'], Name: 'contEmpName', Type: 'Text', Width: 90, Align: 'Center', },
    { Header: ['계약상태코드', '계약상태코드'], Name: 'ctrtSttusCd', Type: 'Text', Width: 90, Align: 'Center', Visible: 0, },
    { Header: ['ctrtDivCd', 'ctrtDivCd'], Name: 'ctrtDivCd', Type: 'Text', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canSend', 'canSend'], Name: 'canSend', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canRejectRecoveryManage', 'canRejectRecoveryManage'], Name: 'canRejectRecoveryManage', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canRecovery', 'canRecovery'], Name: 'canRecovery', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
  ],
};

export default {
  props: {
    otisPage: {
      type: Boolean,
      default: false,
    },

    pageLoading: {
      type: Boolean,
      default: false,
    },

    selectedContractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {},

  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props, context) {
    // console.log('setup > context', context);
    const instance = getCurrentInstance();
    const vm = instance.proxy;
    tmpId = vm.$root.userInfo.empNo;
    const state = reactive({
      loading: false,

      grid: undefined,
      gridOptions: ibsheetOptions,

      searchData: {
        projectName: undefined, // 프로젝트
        tradeName: undefined, // 고객
        trade3piName: undefined, // 계약담당
        ctrtNo: undefined, // 계약번호
        checkContDateStart: moment()
          .startOf('month')
          .add(-1, 'month')
          .format('YYYYMMDD'), // 계약일
        checkContDateEnd: moment()
          .endOf('month')
          .add(1, 'month')
          .format('YYYYMMDD'), // 계약일
        statusCode: undefined, // 진행상태
        ctrtSttusCd : '30', //계약상태가완료인것만조회
        loginEmpNo : 'ADMIN',
      },

      list: undefined,
      selectedRow: undefined,
      contractKey: undefined,

      //폐기 추가
      dispose : computed(() => {
        return  (
          //console.log("tate.memberInfo.signDt",state.memberInfo.signDt),
          $isAdmin()  &&
          props.otisPage && 
          (vm.$root.userInfo.empNo =='T00480' || vm.$root.userInfo.empNo == 'T05330')
        )
      }),
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        // 로딩 상태 완료로 변경
        state.loading = false;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col === '보기') {
          state.selectedRow = e.row;
          //console.log("vm.$root.userInfo.empNo",vm.$root.userInfo.empNo);
          //console.log("state.selectedRow.contEmpId",state.selectedRow.contEmpId);
          if(vm.$root.userInfo.empNo == state.selectedRow.contEmpId){ //20230105 자기것만 보기
             openViewer();
          }
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        state.selectedRow = e.row;
        // console.log('onClick', state.selectedRow, computedSelectedContractKey.value);
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);
      },
    };

    const computedSelectedContractKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
    });

    onMounted(() => {
      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: context.refs.searchBox,
        srchList: context.parent.$parent,
      });

      // console.log('onMounted', context);
    });

    // 계약서 보기
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    watch(
      () => props.search,
      (newValue, oldValue) => {
        // 상위 페이지의 search 상태가 변경된 경우 처리
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );
    const method = {
      // 목록 조회
      async getList() {
        if (state.loading) {
          return;
        }

        if (
          (state.searchData.projectName === undefined || state.searchData.projectName == '') &&
          (state.searchData.tradeName === undefined || state.searchData.tradeName == '') &&
          (state.searchData.trade3piName === undefined || state.searchData.trade3piName == '') &&
          (state.searchData.ctrtNo === undefined || state.searchData.ctrtNo == '')
        ) {
          // 청구일자 기간 비워져 있을시에 alert
          if (!state.searchData.checkContDateStart || !state.searchData.checkContDateEnd) {
            await vm.$alert('계약일을 입력해주세요.');
            return;
          }

          if (moment(state.searchData.checkContDateStart).isAfter(state.searchData.checkContDateEnd)) {
            await vm.$alert('계약일자의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          const dateB = moment(state.searchData.checkContDateStart);
          const dateC = moment(state.searchData.checkContDateEnd);
          //console.log("dateB.diff(dateC, 'months') ",dateC.diff(dateB, 'months') );
          if(dateC.diff(dateB, 'months') > 12){
            await vm.$alert('계약일자의 기간을 1년 이내로 검색해주세요.');
            return;
          }
        }

        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedContractKey 변경 이벤트 호출 -> 상위 페이지의 contractKey 변경
        state.selectedRow = undefined;
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);

        state.grid.removeAll();

        let param = {
          otisPage: props.otisPage,
          ...state.searchData,
          // checkContDateStart: '20210101',
        };

        try {
          const response = await axios.post('/mod/contract/selectContractList', param);
          state.list = response.data;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        } finally {
          // state.loading = false;
        }
      },

      downloadExcel() {
        state.grid.down2Excel();
      },

      grid_created() {
        method.getList();
      },

      //폐기
      async btnDispose_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm('정말 계약번호 '+state.selectedRow.ctrtNo+'를 폐기 하시겠습니까?<br>(폐기 후 복구 불가합니다.)')) {
          const data = {
            ctrtNo: state.selectedRow.ctrtNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/dispose', data);

            await vm.$alert('폐기가 완료되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            console.log(ex);
          } finally {
            context.emit('update:page-loading', false);
          }
        }
      },

    };

    return {
      state,
      computedSelectedContractKey,
      ...method
    };
  },
};
function useFormula2(obj) {
  console.log("obj.Row",obj.Row);
  var Value = obj.Row['contEmpId'];        
  return (Value == tmpId) ? "보기":"";
}
</script>

<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="searchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>프로젝트</i-col-header>
            <i-col>
              <iui-textbox
                v-model="state.searchData.projectName"
                placeholder=" 코드 또는 명칭"
                @keyup.enter="getList"
              />
            </i-col>
            <i-col-header>고객</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.tradeName" placeholder=" 고객명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>3PI</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.trade3piName" placeholder=" 업체명" @keyup.enter="getList" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.ctrtNo" placeholder=" 계약번호" @keyup.enter="getList" />
            </i-col>
            <i-col-header>계약일</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.checkContDateStart" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.checkContDateEnd" />
              </span>
            </i-col>
            <!--
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select v-model="state.searchData.ctrtSttusCd" :items="state.codeList.status" placeholder="전체" />
            </i-col>
            -->
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row style="min-height: 260px;">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            목록
          </template>
          <template #title-right v-if="state.dispose">
            <iui-button  @click="btnDispose_click">폐기</iui-button>
          </template>
          <!--<template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
            <iui-button :enable="state.canRejectManage" @click="btnReject_click">반송/회수 관리</iui-button>
            <iui-button :enable="state.canRecovery" @click="btnRecovery_click">회수</iui-button>
          </template>-->

          <iui-container
            type="ibsheet"
            :loading="state.loading"
            :ibsheet.sync="state.grid"
            :ibsheet-options="state.gridOptions"
            :ibsheet-data="state.list"
            @ibsheet-created="grid_created"
          />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<style></style>

<script>
import {defineComponent, ref, reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';
import gridOpt from './upperCodeOpt.js';

export default defineComponent({
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    dataChanged: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const state = reactive({
      loading: false,
      grid: undefined,
      gridOpt: gridOpt,

      searchKey: {
        pgmCd: context.parent.userInfo.pgmCd,
        cmnUCd: 'ROOT',
        cmnDNm: undefined,
      },
      list: undefined,
      selectedRowData: undefined, //현재선택한 Row
    });

    state.gridOpt.Events = {
      ...state.gridOpt.Events,
      onDataLoad(e) {
        console.log('grid', 'onDataLoad', e);

        // 로딩 상태 완료로 변경
        state.loading = false;

        // search 완료 이벤트 호출 -> 상위 페이지의 search 상태 변경
        context.emit('update:search', false);
      },
      /*********************** 
      // [[[[[[  오류사항 확인요 ]]]]]
      // 1. 신규추가시 포커스 이동 오류가 발생하여 주석처리함. 22.04.06 
      //   - 신규 추가된 로우의 셀이 활성화 되지 않음(Editing 안됨.)   onBeforeFocus async 처리시 발생하는듯.....
      // 2. right 그리드 활성화 되어 입력하고 있을때 마우스를 left 그리드로 이동(포커스이동) 시 변경상태 체크안됨.
      */

      // async onBeforeFocus(e) {
      //   console.log('onBeforeFocus', e);
      //   // confirm 사용(async/await) 시 ibsheet 미지원으로 무조건 focus를 주지 않도록 동작하기 때문에
      //   // 강제로 focus 처리
      //   // true | false가 리턴되는 것이 아닌 promise가 리턴되어 ibsheet에서는 focus를 주지 않는 것으로 동작
      //   if (e.orow && e.row !== e.orow) {
      //     if (props.dataChanged) {
      //       context.emit('update:data-changed', false);
      //       const b = await instance.proxy.$confirm('데이터가 수정되었습니다!<br />무시하고 조회하시겠습니까?');
      //       if (!b) {
      //         e.sheet.focus({row: e.orow, col: e.ocol, ignoreEvent: true});
      //         return;
      //       }
      //     }else{
      //       e.sheet.focus({row: e.row, col: e.col, ignoreEvent: true});
      //       // state.selectedRowData = state.grid.getRowValue(e.row);
      //       state.selectedRowData = e.row;
      //       // selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
      //       //context.emit('update:selectedRowData', selectedDataKey.value);
      //       context.emit('update:selected-row-data', e.row);
      //     }
      //   }
      // },

      onBeforeFocus(e) {
        // console.log('onBeforeFocus', e);
        if ((e.orow && e.row !== e.orow) || state.grid.hasChangedData()) {
          if (props.dataChanged) {
            if (
              !confirm('수정된 데이터가 존재합니다.\n이동하시겠습니까?\n\n(이동 시 수정된 데이터는 저장되지 않습니다.)')
            ) {
              return true; //true 리턴시 이동하지않는다.
            } else {
              context.emit('update:data-changed', false); //이동할경우 변경여부초기화.
            }
          }
        }
      },

      onFocus(e) {
        // console.log('onFocus', e);

        state.selectedRowData = e.row;
        context.emit('update:selected-row-data', e.row);
      },

      // onClick(e) {
      //   //console.log('grid', 'onClick', e);
      // },
      // onBeforeRowDelete(e) {
      //   //console.log('grid', 'onBeforeRowDelete', e);
      // },
    };

    const selectedRowData = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRowData) {
        return undefined;
      }
      return state.selectedRowData;
    });

    // watch(
    //   () => props.search,
    //   (newValue, oldValue) => {
    //     if (newValue && newValue !== oldValue) {
    //       console.log('watch:::search_main');
    //       method.search_main();
    //     }
    //   }
    // );

    // const refs = {
    //   searchBox: ref(null),
    // };

    // onMounted(() => {
    //   // 검색란 보이기/감추기
    //   $initSrchShowButton({
    //     srchBox: refs.searchBox.value,
    //     srchList: context.parent.$parent,
    //   });
    // });

    const method = {
      //22.05.25 추가 page loading
      grid_created() {
        method.search_click();
      },
      async search_click(e) {
        //22.05.25 추가 page loading
        if (state.loading) {
          return;
        }
        if (props.dataChanged) {
          context.emit('update:data-changed', false);
          const b = await instance.proxy.$confirm('데이터가 수정되었습니다!<br />무시하고 조회하시겠습니까?');

          if (!b) {
            e.sheet.focus({row: e.orow, col: e.ocol, ignoreEvent: true});
            return;
          }
        }

        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
        state.selectedRowData = undefined;
        //context.emit('update:selectedDataKey', selectedDataKey.value);
        //state.grid.removeAll();

        try {
          // state.searchKey.pgmCd = this.userInfo.pgmCd;

          // console.log('upper.userInfo', this.userInfo);

          const response = await axios.post('/baseCmmnCodeRegist/selectCodeList', state.searchKey);
          //const response = await axios.post('/report/selectReportFile', data);
          //console.log('response', response);

          state.list = response.data;

          //Cgf:IgnoreFocused:0 으로처리. 22.05.25
          //state.grid.selectRow(state.grid.getRowByIndex(1), 1);
          //state.selectedRowData = state.grid.getRowByIndex(1);

          state.selectedRowData = state.list[0];

          //console.log('state.selectedRowData', state.selectedRowData);

          context.emit('update:selected-row-data', state.selectedRowData);
        } catch (ex) {
          console.log('========error====================');
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      async new_click(e) {
        //console.log('new_click', state.grid.getSaveJson(0).data);
        let addRow = state.grid.addRow({init: {cmnUCd: 'ROOT', useDs: 'N', pgmCd: state.searchKey.pgmCd}});
        //state.grid.setAttribute(addRow, 'cbx', 'CanEdit', 1, 1);
        //state.grid.setAttribute(addRow, 'manageCd', 'CanEdit', 1, 1);
        //state.grid.setAttribute(addRow, 'rptNm', 'CanEdit', 1, 1);

        state.grid.focus(addRow, 'cmnDCd');
      },
      async save_click(e) {
        // 유효성 체크
        if (!state.grid.hasChangedData()) {
          this.$alert(`수정된 내용이 없습니다.`);
          return;
        }

        for (let row of state.grid.getRowsByStatus('Added,Changed')) {
          if (String(row.cmnDCd).trim() == '') {
            this.$alert('코드는 필수입력 항목입니다.');
            state.grid.focus(row, 'cmnDCd');
            return;
          }
          if (String(row.cmnDNm).trim() == '') {
            this.$alert('코드명은 필수입력 항목입니다.');
            state.grid.focus(row, 'cmnDNm');
            return;
          }
        }

        if (await this.$confirm('저장하시겠습니까?')) {
          try {
            state.loading = true;
            const response = await axios.post('/baseCmmnCodeRegist/save', state.grid.getSaveJson().data);
            if (response.status == 200) {
              method.search_click(e);
            } else {
              this.$alert('저장을 실패했습니다.');
            }
          } catch (ex) {
            console.log('========error====================');
            console.log(ex);
            // 로딩 상태 변경
            state.loading = true;
          }
        }
      },
      async delete_click(e) {
        console.log('delete_click', e);

        let checkedRows = state.grid.getRowsByChecked('cbx');

        if (0 == checkedRows.length) {
          this.$alert('선택된 항목이 없습니다.');
          return;
        }
        checkedRows.forEach(row => {
          if (!row.Added) {
            state.grid.deleteRow(row);
          } else {
            state.grid.removeRow(row);
          }
        });
      },
    };

    return {
      state,
      selectedRowData,
      // ...refs,
      ...method,
    };
  },
});
</script>

<style scoped></style>

<template>
  <pmis-tab-box>
    <template #title>상위코드</template>
    <template #title-right>
      <iui-button value="신규" @click="new_click" />
      <iui-button value="저장" @click="save_click" />
      <iui-button value="삭제" @click="delete_click" />
    </template>
    <iui-content-box>
      <template #header-left>
        <!-- <iui-textbox placeholder="검색어" label="코드명" v-model="state.searchKey.cmnDNm" @enter="search_click" /> -->
        <iui-text
          type="text"
          name="cmnDNm"
          label="코드명"
          placeholder="검색어"
          :value.sync="state.searchKey.cmnDNm"
          @enter="search_click"
        ></iui-text>
      </template>
      <template #header-right>
        <iui-button value="검색" class="btn" @click="search_click" />
      </template>
      <!-- 22.05.25 추가 page loading -->
      <iui-container
        type="ibsheet"
        width="100%"
        :ibsheet.sync="state.grid"
        :loading="state.loading"
        :ibsheet-options="state.gridOpt"
        :ibsheet-data="state.list"
        @ibsheet-created="grid_created"
      />
    </iui-content-box>
  </pmis-tab-box>
</template>
<style scoped></style>

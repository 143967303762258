<template>
  <pmis-page :loading="pageLoading">
    <template v-slot:searchbox>
      <iui-button value="pmis-page 로딩" @click="page_loading_test" />
      <a href="#">앵커</a>
    </template>

    <pmis-content-box v-if="true">
      <template v-slot:header-left> </template>
      <template v-slot:header-right>
        <iui-button @click="SecuKit_PKCS7SignMessage">PKCS7SignMessage</iui-button>
        <iui-button @click="SecuKit_PKCS7AddSignMessage">PKCS7AddSignMessage</iui-button>
        <iui-button @click="SecuKit_PKCS7VerifyMessage">PKCS7VerifyMessage</iui-button>
      </template>

      <iui-container type="table" theme="bullet">
        <i-row height="100px">
          <i-col-header>
            원문
          </i-col-header>
          <i-col>
            <iui-text type="multi" :value.sync="SecuKit_OriginalMessage" />
          </i-col>
        </i-row>
        <i-row height="100px">
          <i-col-header>
            PKCS#7 Signed Message
          </i-col-header>
          <i-col>
            <iui-text type="multi" :value.sync="SecuKit_PKCS7SignedMessage" />
          </i-col>
        </i-row>
        <i-row height="100px">
          <i-col-header>
            PKCS#7 Verified Message
          </i-col-header>
          <i-col>
            <iui-text type="multi" :value.sync="SecuKit_PKCS7VerifiedMessage" />
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>

    <iui-button @click="save1 = true">외부저장</iui-button>

    <iui-container type="css-flex" height="1000px">
      <!-- <i-row height="300px">
        <i-col>
          <pmis-image-file
            company-code="201900"
            :file-number.sync="fileList_fileNumber"
            fbs="A123456"
            :save.sync="save1"
            thumbnail
          />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-file-list company-code="201900" :file-number.sync="fileList_fileNumber" />
        </i-col>
      </i-row> -->
      <i-row height="300px">
        <i-col colspan="2">
          <iui-text :value.sync="fileList_fileNumber" />
          <iui-button @click="fileList_show = !fileList_show">토글</iui-button>
          <iui-button
            @click="
              () => {
                fileList_fileNumber = 57;
                //fileList_search = true;
              }
            "
            >외부조회1</iui-button
          >
          <iui-button
            @click="
              () => {
                fileList_fileNumber = 3324;
                //fileList_search = true;
              }
            "
            >외부조회2</iui-button
          >
          <iui-button @click="fileList_add = true">외부추가</iui-button>
          <iui-button @click="fileList_delete = true">외부삭제</iui-button>
          <iui-button @click="fileList_save = true">외부저장</iui-button>
          <iui-button @click="fileList_deleteAll = true">외부모두삭제</iui-button>
          <!-- fileList_changed: {{ fileList_changed }} , fileList_search: {{ fileList_search }} , fileList_add:
          {{ fileList_add }} , fileList_delete: {{ fileList_delete }} , fileList_save: {{ fileList_save }} ,
          fileList_deleteAll: {{ fileList_deleteAll }}<br />
          files: {{ fileList_files }} -->
          <br />
          <pmis-file-list
            v-show="!fileList_show"
            v-model="fileList_files"
            company-code="201900"
            :file-number.sync="fileList_fileNumber"
            :changed.sync="fileList_changed"
            :search.sync="fileList_search"
            :add.sync="fileList_add"
            :delete.sync="fileList_delete"
            :save.sync="fileList_save"
            :deleteAll.sync="fileList_deleteAll"
            @save-complete="저장성공;"
            @save-error="저장실패;"
            add-button-text="변경추가"
            toolbar-visible
            :columns="fileList_columns"
            :search-data="{test1: 'v', test2: 'v'}"
            :default-data-rows="[
              {lclFlNm: '갑지1', flSz: '자동생성'},
              {lclFlNm: '갑지2', flSz: '자동생성'},
            ]"
          />
        </i-col>
      </i-row>
    </iui-container>

    <!-- <pmis-file-list company-code="201900" file-number="56" :allow-delete="false" /> -->

    <!-- <iui-editor height="500px" v-model="editorContent" :enable="editorEnable" @onUndo="editor_undo" /> -->

    <!-- v-code: <iui-checkbox-group-old v-code="'0165'" /><br />
    v-code: <iui-checkbox-group-old v-code="'0165'" exclude-items="M,L" /><br /> -->

    <!-- <iui-radio-group-old :items="items" itemKeyAlias="code" itemTextAlias="codeNm" itemValueAlias="code" /><br />
    <iui-radio-group-old p-code="0165" /><br />
    <iui-radio-group-old v-code="'0165'" /><br /> -->

    <iui-container type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <pmis-content-box :loading="contentBoxLoading">
            <template v-slot:title>
              iui-text
            </template>
            <template #title-right>
              <!-- <iui-radio-group-old ref="select4" /> -->
              <iui-button @click="content_box_loading_test">content-box 로딩</iui-button>
            </template>
            <template v-slot:header-left>
              iui-text type="code | largecode | smallcode"
              <iui-button>버튼</iui-button>
            </template>
            <template v-slot:header-right>
              <iui-text />
              <iui-button>버튼</iui-button>
            </template>

            <iui-container type="css-flex">
              <i-row>
                <i-col>
                  <iui-container type="table" theme="bullet">
                    <i-row>
                      <i-col-header>smallcode</i-col-header>
                      <i-col>
                        <iui-text type="smallcode" value="123456" />
                        <iui-text />
                      </i-col>
                      <i-col-header>코드</i-col-header>
                      <i-col>
                        <iui-text type="code" />
                        <iui-button value="버튼"></iui-button>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>code</i-col-header>
                      <i-col>
                        <iui-text type="code" value="1234567890" />
                        <iui-text />
                      </i-col>
                      <i-col-header>코드</i-col-header>
                      <i-col>
                        <iui-text type="code" />
                        <iui-button normal value="버튼">
                          <!-- <img src="~/assets/img/bu_tbl_01.png" /> -->
                        </iui-button>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>largecode</i-col-header>
                      <i-col>
                        <iui-text type="largecode" value="123456789012345" />
                        <iui-text />
                      </i-col>
                      <i-col-header>코드</i-col-header>
                      <i-col>
                        <iui-text type="code" width="20px" value="1" />
                        <iui-text type="code" />
                      </i-col>
                    </i-row>
                  </iui-container>
                </i-col>
                <i-spacer />
                <i-col>
                  <iui-container type="table" theme="bullet">
                    <i-row>
                      <i-col-header>normal</i-col-header>
                      <i-col>
                        <iui-button normal>normal 버튼</iui-button>
                        <iui-button normal color="gray">normal 버튼</iui-button>
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>small</i-col-header>
                      <i-col>
                        <iui-button small color="blue">small 버튼</iui-button>
                        <iui-button small>small 버튼</iui-button>
                      </i-col>
                    </i-row>
                  </iui-container>
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-row>
        <i-spacer />
        <i-row>
          <pmis-content-box title-type="tab">
            <template v-slot:title>tab</template>
            <template v-slot:title-left>
              <iui-text label="왼쪽" />
              <iui-select-old label="왼쪽" :items="items" />
              <iui-button value="버튼" />
              <a href="#">앵커</a>
            </template>
            <template v-slot:title-right>
              <iui-text label="오른쪽" />
              <iui-select-old label="오른쪽" :items="items" />
              <iui-button value="버튼" />
              <a href="#">앵커</a>
            </template>
            <template v-slot:header-left>
              <iui-text label="왼쪽" />
              <iui-select-old label="왼쪽" :items="items" />
              <iui-button value="버튼" />
              <a href="#">앵커</a>
            </template>
            <template v-slot:header-right>
              <iui-text label="오른쪽" />
              <iui-select-old label="오른쪽" :items="items" />
              <iui-button value="버튼" />
              <a href="#">앵커</a>
            </template>

            <iui-container type="css-flex">
              <i-row>
                <i-col>
                  <iui-container type="table" theme="bullet">
                    <i-row>
                      <i-col-header>하자보수보증금</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>하자보수기간</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                    </i-row>
                  </iui-container>
                </i-col>
                <i-spacer />
                <i-col>
                  <iui-container type="table" theme="bullet">
                    <i-row>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                      <i-col-header>하자보수보증금율</i-col-header>
                      <i-col>
                        <iui-datepicker-old suffix="~" />
                        <iui-datepicker-old />
                      </i-col>
                    </i-row>
                  </iui-container>
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-row>
        <i-spacer />
        <i-row>
          <pmis-content-box>
            <template v-slot:title>
              tab-box, content-box
            </template>

            <iui-container type="css-flex" flex-direction="row">
              <i-col>
                <i-row flex="0"><b>pmis-content-box</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + header</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:header-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:header-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + title</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:title>
                      title
                    </template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + title + header</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:title>
                      title
                    </template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>
                    <template v-slot:header-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:header-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
              </i-col>
              <i-spacer />
              <i-col>
                <i-row flex="0"><b>pmis-content-box + border</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + border + header</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:header-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:header-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + border + title</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:title>
                      title
                    </template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-content-box + border + title + header</b></i-row>
                <i-row style="height: 150px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-content-box>
                    <template v-slot:title>
                      title
                    </template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>
                    <template v-slot:header-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:header-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-content-box>
                </i-row>
              </i-col>
              <i-spacer />
              <i-col>
                <i-row flex="0"><b>pmis-tab-box</b></i-row>
                <i-row flex="1" style="border: 1px dashed #aaa; padding: 5px;">
                  <pmis-tab-box>
                    <template v-slot:title>title</template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                    content content content content content content content content content<br />
                  </pmis-tab-box>
                </i-row>
              </i-col>
              <i-spacer />
              <i-col>
                <i-row flex="0"><b>pmis-tab-box + pmis-content-box</b></i-row>
                <i-row flex="1" style="height: 330px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-tab-box>
                    <template v-slot:title>title</template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    <pmis-content-box>
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                    </pmis-content-box>
                  </pmis-tab-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-tab-box + pmis-content-box + header</b></i-row>
                <i-row flex="1" style="height: 330px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-tab-box>
                    <template v-slot:title>title</template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    <pmis-content-box>
                      <template v-slot:header-left>
                        <label>label</label>
                        <iui-text width="50px" />
                      </template>
                      <template v-slot:header-right>
                        <iui-button>right iui-button</iui-button>
                      </template>

                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                    </pmis-content-box>
                  </pmis-tab-box>
                </i-row>
              </i-col>
              <i-spacer />
              <i-col>
                <i-row flex="0"><b>pmis-tab-box + pmis-content-box + title</b></i-row>
                <i-row flex="1" style="height: 330px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-tab-box>
                    <template v-slot:title>title</template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    <pmis-content-box>
                      <template v-slot:title>
                        title
                      </template>
                      <template v-slot:title-left>
                        <label>label</label>
                        <iui-text width="50px" />
                      </template>
                      <template v-slot:title-right>
                        <iui-button>right iui-button</iui-button>
                      </template>

                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                    </pmis-content-box>
                  </pmis-tab-box>
                </i-row>
                <i-spacer />
                <i-row flex="0"><b>pmis-tab-box + pmis-content-box + title + header</b></i-row>
                <i-row flex="1" style="height: 330px; border: 1px dashed #aaa; padding: 5px;">
                  <pmis-tab-box>
                    <template v-slot:title>title</template>
                    <template v-slot:title-left>
                      <label>label</label>
                      <iui-text width="50px" />
                    </template>
                    <template v-slot:title-right>
                      <iui-button>right iui-button</iui-button>
                    </template>

                    <pmis-content-box>
                      <template v-slot:title>
                        title
                      </template>
                      <template v-slot:title-left>
                        <label>label</label>
                        <iui-text width="50px" />
                      </template>
                      <template v-slot:title-right>
                        <iui-button>right iui-button</iui-button>
                      </template>
                      <template v-slot:header-left>
                        <label>label</label>
                        <iui-text width="50px" />
                      </template>
                      <template v-slot:header-right>
                        <iui-button>right iui-button</iui-button>
                      </template>

                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                      content content content content content content content content content<br />
                    </pmis-content-box>
                  </pmis-tab-box>
                </i-row>
              </i-col>
            </iui-container>
          </pmis-content-box>
        </i-row>
      </i-col>
    </iui-container>
  </pmis-page>
</template>

<script>
// import Editor from '@tinymce/tinymce-vue';
import {PMIS_FILE_LIST_DEFAULT_COLUMNS} from '@/components/PmisFileList.vue';

export default {
  // components: {
  //   editor: Editor,
  // },
  data() {
    return {
      items: undefined,
      pageLoading: false,
      contentBoxLoading: false,
      modelData: undefined,
      editorContent:
        '<p contenteditable="false"><table contenteditable="false" style="border-collapse: collapse; width: 100%;" border="1"> <tbody> <tr> <td style="width: 25%;" contenteditable="false">abc</td> <td style="width: 25%;" contenteditable="true">&nbsp;</td> <td style="width: 25%;" contenteditable="false">def</td> <td style="width: 25%;">&nbsp;</td> </tr> <tr> <td style="width: 25%;">&nbsp;</td> <td style="width: 25%;">&nbsp;</td> <td style="width: 25%;">&nbsp;</td> <td style="width: 25%;">&nbsp;</td> </tr> </tbody> </table> <p style="-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;" contenteditable="false">수정불가</p> <p contenteditable="true">수정가능</p> <p>과연?</p></p>',
      editorEnable: false,
      fileList_show: false,
      fileList_fileNumber: 1,
      fileList_files: [],
      fileList_changed: false,
      fileList_search: false,
      fileList_add: false,
      fileList_delete: false,
      fileList_save: false,
      fileList_deleteAll: false,
      fileList_columns: [
        {
          Name: 'test1',
          Header: '추가컬럼1',
          CanEdit: false,
        },
        {
          Name: 'test2',
          Header: '추가컬럼2',
          CanEdit: false,
        },
        ...PMIS_FILE_LIST_DEFAULT_COLUMNS,
      ],
      save1: false,
      SecuKit_OriginalMessage: '원문',
      SecuKit_PKCS7SignedMessage: '',
      SecuKit_PKCS7VerifiedMessage: '',
    };
  },
  computed: {
    test2() {
      // return this.$store.getters['code2/getCodeByAlias']('FIX_Y');
      return this.$store.state.code2.codeAlias.USE_CLOSE_Y;
    },
  },
  async created() {
    console.group(`[Sample1.vue] created`);
    console.log(this);
    console.groupEnd();

    // this.items = await $getCode(this, '0260');

    this.items = [
      {key: '1', value: 'v1', text: 't1t1t1t1t1t1t1t1t1'},
      {key: '2', value: 'v2', text: 't2t2t2t2t2t2t2t2t2', enable: false},
      {key: '3', value: 'v3', text: 't3t3t3t3t3t3t3t3t3'},
      {key: '4', value: 'v4', text: 't4t4t4t4t4t4t4t4t4', enable: false},
      {key: '5', value: 'v5', text: 't5t5t5t5t5t5t5t5t5'},
    ];
  },
  mounted() {
    this.$secuKit.checkReady();
    // console.log(this.CODE_ALIAS);
    // console.log(this.test2);
    // console.log(this.test2.code);
    // console.log(this.test2.codeNm);
    // console.log(IBSheet.version());
  },
  methods: {
    page_loading_test() {
      const me = this;

      me.pageLoading = true;

      setTimeout(() => {
        me.pageLoading = false;
      }, 3000);
    },
    content_box_loading_test() {
      const me = this;

      me.contentBoxLoading = true;

      setTimeout(() => {
        me.contentBoxLoading = false;
      }, 3000);
    },
    editor_undo() {
      console.log('editor_undo');
    },
    // txt_input(e) {
    //   console.log(e);
    // },
    // txt2_validate() {
    //   this.$validator.validate(this, 'txt2');
    // },
    // group1_validate() {
    //   this.$validator.validate(this, 'group1', '*');
    // },
    // group2_validate() {
    //   this.$validator.validateGroup(this, 'group2');
    // },
    // async page_validate() {
    //   const result = await this.validate();
    //   console.log(result);
    // },

    async SecuKit_PKCS7SignMessage() {
      try {
        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.SecuKit_OriginalMessage,
          certID: certID,
        };

        // const r2 = await this.$secuKit.generatePKCS7SignedData(data);
        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.SecuKit_PKCS7SignedMessage = r2.generatePKCS7SignedData;
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_PKCS7AddSignMessage() {
      try {
        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: removePEMHeader(removeCRLF(this.SecuKit_PKCS7SignedMessage)),
          certID: certID,
        };

        const r2 = await this.$secuKit.addPKCS7SignedData(data);
        console.log(r2);

        this.SecuKit_PKCS7SignedMessage = r2.addPKCS7SignedData;
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_PKCS7VerifyMessage() {
      try {
        let data = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.SecuKit_PKCS7SignedMessage)),
        };

        const r1 = await this.$secuKit.verifyPKCS7SignedData(data);
        console.log(r1);

        this.SecuKit_PKCS7VerifiedMessage = r1.verifyPKCS7SignedData;
      } catch (ex) {
        console.log(ex);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width170">
      <i-row v-if="isNotEmptyRtrvlResnCn">
        <i-col-header>회수 일시<br /></i-col-header>
        <i-col>
          <iui-text :value="contractMain.rtrvlDtStr" :enable="false" />
        </i-col>
      </i-row>
      <i-row v-if="isNotEmptyRtrvlResnCn">
        <i-col-header>회수 사유<br /></i-col-header>
        <i-col>
          <iui-text type="multi" :value="contractMain.rtrvlResnCn" :enable="false" height="100px" :css="style" />
        </i-col>
      </i-row>
      <i-row v-if="isNotEmptyRetrnResnCn">
        <i-col-header>반송 회사명<br /></i-col-header>
        <i-col>
          <iui-text :value="retrnResn.tradeName" :enable="false" />
        </i-col>
      </i-row>
      <i-row v-if="isNotEmptyRetrnResnCn">
        <i-col-header>반송 일시<br /></i-col-header>
        <i-col>
          <iui-text :value="retrnResn.entryDateStr" :enable="false" />
        </i-col>
      </i-row>
      <i-row v-if="isNotEmptyRetrnResnCn">
        <i-col-header>반송 사유<br /></i-col-header>
        <i-col>
          <iui-text type="multi" :value="retrnResn.retrnResnCn" :enable="false" height="100px" :css="style" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>OKSS 전송<br />사유/재전송 사유<br /></i-col-header>
        <i-col>
          <iui-text
            type="multi"
            name="resnCn"
            :value="resnCn"
            :enable="true"
            height="100px"
            required
            @change="resnCn = $event.target.value"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: String,
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.addEvent({name: 'Reject_SendToOkss', func: this.onSendToOkss});
    this.addEvent({name: 'Reject_ReSend', func: this.onReSend});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      resnCn: '',
      contractMain: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        ctrtSttusCd: '',
        tmstmpOrginlCn: '',
        tmstmpTknCn: '',
        tmstmpIssuDt: '',
        retransmResnCn: '',
        retransmDt: '',
        rtrvlResnCn: '',
        rtrvlDt: '',
        rtrvlDtStr: '',

        frstSendngDt: '',
        scdSendngDt: '',
        ofldcRcverNm: '',
        ofldcRcverMoblphonNo: '',
        ofldcRcverEmail: '',
        trmnatMthCd: '',
        trmnatResnCn: '',
        trmnatComptDe: '',
        entryEmpId: '',
        entryDate: '',
        chngEmpId: '',
        chngDate: '',
        chngPgmId: '',
      },

      retrnResn: {
        signSn: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        retrnResnCn: '',
        accesIp: '',
        accesEnvrnCn: '',
        entryEmpId: '',
        entryDate: '',
        chngEmpId: '',
        chngDate: '',
        tradeName: '',
        entryDateStr: '',
      },

      style: {
        'white-space': 'pre-wrap',
        'word-wrap': 'break-word',
      },
    };
  },
  mounted() {
    console.log('Reject');
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
      };

      let response1 = await axios.post('/contract/selectRtrvlResnCn', param);
      this.contractMain = response1.data;
      console.log('contractMain', this.contractMain);

      let response2 = await axios.post('/contract/selectRetrnResnCn', param);
      this.retrnResn = response2.data;
      console.log('retrnResn', this.retrnResn);
    },
    async onSendToOkss(callback) {
      if (!this.resnCn.trim()) {
        this.$alert('OKSS 전송 사유를 입력해야합니다.');
        return;
      }

      var msg = 'OKSS로 반송 시 재승인을 받아야 합니다. OKSS로 반송 하시겠습니까?<br/>';
      msg += '반송된 문서는 전자계약시스템에서 조회되지 않습니다.';

      if (await this.$confirm(msg)) {
        let param = {
          ctrtNo: this.ctrtNo,
          ctrtOdr: this.ctrtOdr,
          ctrtDivCd: this.ctrtDivCd,
          reason: this.resnCn,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        const me = this;
        try {
          me.listBoxLoading = true;
          await axios.post('/contract/sendToOkss', param);
          me.listBoxLoading = false;
          me.$alert('OKSS로 전송이 완료되었습니다.');
          callback({action: 'onSendToOkss'});
        } catch (ex) {
          me.listBoxLoading = false;
          me.$alert('OKSS로 전송에 실패하였습니다.' + e);
        }
      }
    },
    async onReSend(callback) {
      if (!this.resnCn.trim()) {
        this.$alert('재전송 사유를 입력해야합니다.');
        return;
      }

      if (await this.$confirm('현재 문서를 구성원들에게 재전송 하시겠습니까?')) {
        let param = {
          ctrtNo: this.ctrtNo,
          ctrtOdr: this.ctrtOdr,
          ctrtDivCd: this.ctrtDivCd,
          reason: this.resnCn,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        const me = this;
        try {
          me.listBoxLoading = true;
          await axios.post('/contract/reSend', param);
          me.listBoxLoading = false;
          me.$alert('재전송이 완료되었습니다.');
          callback({action: 'onReSend'});
        } catch (ex) {
          me.listBoxLoading = false;
          me.$alert('재전송이 실패하였습니다.' + e);
        }
      }
    },
  },
  computed: {
    isNotEmptyRtrvlResnCn() {
      return this.contractMain.rtrvlResnCn != undefined && this.contractMain.rtrvlResnCn != '';
    },
    isNotEmptyRetrnResnCn() {
      return this.retrnResn.retrnResnCn !== undefined && this.retrnResn.retrnResnCn != '';
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog />
</template>

<script>
import Alert from '@/plugins/alert';
export default {
	data() {
		return {
			msg: '',
			name: 'dialog',
		};
	},
	created() {
		Alert.event.$on('show', this.handleShowEvent);
	},
	beforeMount() {
		window.addEventListener('keyup', this.onEscapeKeyUp);
	},
	methods: {
		handleShowEvent(msg, resolve, reject, callback) {
			this.msg = msg;
			this.show(resolve, reject, callback);
		},
		onEscapeKeyUp(event) {
			if (event.which === 27) {
				this.callback;
			}
		},
		show(resolve, reject, callback) {
			if (!callback) callback = () => {};
			this.$modal.show(this.name, {
				title: 'Alert!',
				text: this.msg,
				buttons: [
					{
						title: '확인',
						handler: () => {
							try {
								setTimeout(() => {
									callback();
								}, 50);

								resolve();
							} catch (error) {
								reject(error);
							}

							this.$modal.hide(this.name);
						},
					},
				],
			});
		},
		hide() {
			this.$modal.hide(this.name);
		},
	},
};
</script>

<style></style>

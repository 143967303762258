<template>
  <iui-content-box :loading="internalAction.search">
    <template v-slot:title v-if="titleVisible && title">{{ title }}</template>
    <template v-slot:title-left v-if="titleVisible && title"></template>
    <template v-slot:[dynamicSlotName]>
      <template v-if="toolbarVisible">
        <iui-button v-if="allowAdd" :enable="buttonEnable" @click="btnAdd_click">{{ addButtonText }}</iui-button>
        <iui-button v-if="allowDelete" :enable="buttonEnable" @click="btnDelete_click">{{
          deleteButtonText
        }}</iui-button>
        <iui-button v-show="saveButtonVisible" :enable="buttonEnable" @click="btnSave_click">{{
          saveButtonText
        }}</iui-button>
      </template>
    </template>

    <file-upload
      :headers="postHeader"
      :post-action="internalPostAction"
      ref="upload"
      v-model="internalFiles"
      name="files"
      :drop="allowAdd && allowDrop"
      :multiple="true"
      @input="fileUpload_input"
      @input-filter="fileUpload_inputFilter"
      @input-file="fileUpload_inputFile"
      style="display: none"
    ></file-upload>

    <iui-container type="ibsheet" :id="grdListId" />
  </iui-content-box>
</template>

<script>
import VueCookies from 'vue-cookies';
import FileUpload from 'vue-upload-component';

export const PMIS_FILE_LIST_DEFAULT_COLUMNS = [
  {
    Name: 'lclFlNm',
    Header: '파일명',
    Type: 'Text',
    CanEdit: false,
    RelWidth: 1,
  },
  {
    Name: 'flSz',
    Header: '크기',
    Type: 'Text',
    CanEdit: false,
    Width: 100,
    Align: 'right',
  },
];

export const ADD_MODE = {
  ADD_ROW: 'ADD_ROW',
  UPDATE_ROW: 'UPDATE_ROW',
};

export default {
  name: 'pmis-file-list',
  components: {
    FileUpload: FileUpload,
  },
  model: {
    prop: 'files',
    event: 'update:files',
  },
  props: {
    companyCode: {
      type: String,
      default: undefined,
    },
    fileNumber: {
      type: [Number, String],
      default: 0,
    },
    projectCode: {
      type: String,
      default: undefined,
    },
    fbs: {
      type: String,
      default: undefined,
    },
    files: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: undefined,
    },
    titleVisible: {
      type: Boolean,
      default: false,
    },
    toolbarVisible: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    allowDrop: {
      type: Boolean,
      default: true,
    },

    // button
    addButtonText: {
      type: String,
      default: '추가',
    },
    deleteButtonText: {
      type: String,
      default: '삭제',
    },
    saveButtonText: {
      type: String,
      default: '저장',
    },
    saveButtonVisible: {
      type: Boolean,
      default: true,
    },

    // action
    changed: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    delete: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    deleteAll: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      default: () => {
        return PMIS_FILE_LIST_DEFAULT_COLUMNS;
      },
    },

    searchUrl: {
      type: String,
      default: '/file/selectFileList',
    },
    searchData: {
      type: [Array, Object],
      default: undefined,
    },

    defaultDataRows: {
      type: [Array, Object],
      default: undefined,
    },
  },
  data() {
    return {
      internalFileNumber: 0,
      internalName: `file${this._uid}`,
      internalPostAction: `${process.env.VUE_APP_BASE_URL}/file/saveFile`,
      internalFiles: [],
      internalStatus: '',

      internalAction: {
        search: false,
        add: false,
        delete: false,
        save: false,
        deleteAll: false,
        clear: false,
      },

      loadingg: false,

      addMode: undefined,
      updateTargetRow: undefined,
      dynamicSlotName: '',
    };
  },
  computed: {
    grdListId() {
      return `divList${this._uid}`;
    },
    buttonEnable() {
      return (
        !this.internalAction.search &&
        !this.internalAction.add &&
        !this.internalAction.delete &&
        !this.internalAction.save &&
        !this.internalAction.deleteAll &&
        !this.internalAction.clear
      );
    },
    postHeader() {
      let authorization = '';

      if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
        authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD' + '_accessToken');
      }

      return {Authorization: authorization};
    },
  },
  watch: {
    fileNumber(newValue, oldValue) {
      console.log('watch > fileNumber', newValue);
      if (newValue !== oldValue) {
        if (newValue !== this.internalFileNumber) {
          this.internalFileNumber = newValue || 0;
          // if (!this.internalAction.search) {
          this.internalAction.search = true;
          this._search();
          // }
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.search = newValue;

        if (newValue) {
          this._search();
        }
      }
    },
    add(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.add = newValue;

        if (newValue) {
          this.btnAdd_click();
        }
      }
    },
    delete(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.delete = newValue;

        if (newValue) {
          this.btnDelete_click();
        }
      }
    },
    save(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.save = newValue;

        if (newValue) {
          this.btnSave_click();
        }
      }
    },
    deleteAll(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.deleteAll = newValue;

        if (newValue) {
          this._deleteAll();
        }
      }
    },
    clear(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalAction.clear = newValue;

        if (newValue) {
          this._clear();
        }
      }
    },
    toolbarVisible(newValue, oldValue) {
      if (newValue !== oldValue) {
        newValue ? this.grdList.showCol('cbx') : this.grdList.hideCol('cbx');
        newValue ? this.grdList.showCol('uploadStatus') : this.grdList.hideCol('uploadStatus');
        newValue ? this.grdList.showCol('btnAdd') : this.grdList.hideCol('btnAdd');
        newValue ? this.grdList.showCol('btnDelete') : this.grdList.hideCol('btnDelete');
      }
    },
  },
  created() {
    const vm = this;
    console.log('created > fileNumber', this.fileNumber);
    this.internalFileNumber = this.fileNumber;
    this.$on('update:search', e => {
      vm.internalAction.search = e;
    });
    this.$on('update:add', e => {
      vm.internalAction.add = e;
    });
    this.$on('update:delete', e => {
      vm.internalAction.delete = e;
    });
    this.$on('update:save', e => {
      vm.internalAction.save = e;
    });
    this.$on('update:deleteAll', e => {
      vm.internalAction.deleteAll = e;
    });
    this.$on('update:clear', e => {
      vm.internalAction.clear = e;
    });
    this.dynamicSlotName = this.titleVisible ? 'title-right' : 'header-right';

    this.addEvent([{name: 'PmisFileList_research', func: this.research}]);
  },
  async mounted() {
    const vm = this;
    const sheetOptions = {
      Cfg: {
        ReqStatusName: 'status',
        CanSort: false,
      },
      Def: {
        Row: {
          CanFormula: 1,
          CalcOrder: 'cbxCanEdit,btnAddVisible,btnAddType,btnAddButton,btnDeleteVisible,btnDeleteType,btnDeleteButton',
        },
      },
      Cols: [
        {
          Name: 'cbx',
          Header: '선택',
          Align: 'center',
          Width: 70,
          MinWidth: 70,
          MaxWidth: 70,
          Type: 'Bool',
          Visible: this.allowDelete,
          CanEditFormula: fr => fr.Row['allowAdd'] && fr.Row['allowAdd'],
        },
        {
          Name: 'allowAdd',
          Visible: false,
          CanEdit: false,
        },
        {
          Name: 'allowDelete',
          Visible: false,
          CanEdit: false,
        },
        {
          Name: 'pgmCd',
          Header: 'pgmCd',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'flNo',
          Header: 'flNo',
          Type: 'Number',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'grpNo',
          Header: 'grpNo',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'flDs',
          Header: 'flDs',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'revId',
          Header: 'revId',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'verNo',
          Header: 'verNo',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'prjCd',
          Header: 'prjCd',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'fbsNo',
          Header: 'fbsNo',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'rmk',
          Header: 'rmk',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'verNo',
          Header: 'verNo',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'svrFlPth',
          Header: 'svrFlPth',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'svrFlNm',
          Header: 'svrFlNm',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        {
          Name: 'flTp',
          Header: 'flTp',
          Type: 'Text',
          CanEdit: false,
          Visible: false,
        },
        ...this.columns,
        {
          Name: 'uploadStatus',
          Header: '상태',
          Type: 'Text',
          CanEdit: false,
          Visible: true,
          Width: 80,
        },
        {
          Name: 'btnAdd',
          Header: '선택',
          CanEdit: false,
          VisibleFormula: fr => fr.Row['allowAdd'],
          TypeFormula: fr => (fr.Row['allowAdd'] ? 'Button' : 'Text'),
          ButtonFormula: fr => (fr.Row['allowAdd'] ? 'Button' : ' '),
          ButtonText: '선택',
        },
        {
          Name: 'btnDelete',
          Header: '삭제',
          CanEdit: false,
          VisibleFormula: fr => fr.Row['allowDelete'],
          TypeFormula: fr => (fr.Row['allowDelete'] ? 'Button' : 'Text'),
          ButtonFormula: fr => (fr.Row['allowDelete'] ? 'Button' : ''),
          ButtonText: '삭제',
        },
      ],
      Events: {
        onDataLoad: function(e) {
          if (!vm.toolbarVisible) {
            e.sheet.hideCol('cbx');
            e.sheet.hideCol('uploadStatus');
            e.sheet.hideCol('btnAdd');
            e.sheet.hideCol('btnDelete');
          }
        },
        onSearchFinish: function(e) {
          if (vm.defaultDataRows !== undefined) {
            const firstRow = e.sheet.getFirstRow();
            vm.defaultDataRows.forEach(dataRow => {
              const newRow = e.sheet.addRow(firstRow, true, false, null, dataRow);
              e.sheet.acceptChangedData(newRow);
              e.sheet.refreshRow(newRow);
            });
          }

          e.sheet.getDataRows().forEach(row => {
            e.sheet.setValue(row, 'allowAdd', row.allowAdd === undefined ? 1 : row.allowAdd, 1);
            e.sheet.setValue(row, 'allowDelete', row.allowDelete === undefined ? 1 : row.allowDelete, 1);
            e.sheet.acceptChangedData();
          });
        },
        onClick: function(e) {
          if (e.row.Kind == 'Data' && e.col == 'btnAdd' && e.row.allowAdd) {
            vm.addMode = ADD_MODE.UPDATE_ROW;
            vm.updateTargetRow = e.row;
            vm.$refs.upload.$children[0].$el.click();
            return true;
          }

          if (e.row.Kind == 'Data' && e.col == 'btnDelete' && e.row.allowDelete) {
            vm.grdList.deleteRow(e.row);
            vm._delete();
            return true;
          }

          return false;
        },
        onAfterClick: function(e) {
          // 삭제 처리된 Row의 선택 체크박스를 해제 시 Row 삭제 취소 처리
          if (e.row.Kind == 'Data' && e.col == 'cbx' && e.row.Deleted) {
            if (!e.row['cbx']) {
              e.sheet.deleteRow({row: e.row, del: 0});
              let changedRows = e.sheet.getRowsByStatus('Added,Deleted');
              vm.$emit('update:changed', changedRows.length > 0);
            }
          }
        },
        onBeforeRowDelete: function(e) {
          // Row 삭제(e.type == 0) 시
          // 신규로 추가된 Row일 경우 remove 처리 후 이벤트를 멈춤
          // 기존 Row일 경우 삭제 상태 처리
          if (e.type == 0 && e.row.Added) {
            console.log(e.row.file);
            vm.$refs.upload.remove(e.row.file);
            e.sheet.removeRow({row: e.row});

            let changedRows = e.sheet.getRowsByStatus('Added,Deleted');
            vm.$emit('update:changed', changedRows.length > 0);

            return false;
          }

          // Row 삭제 취소(e.type == 1) 시 취소선 제거
          if (e.type == 1) {
            e.sheet.setAttribute(e.row, '', 'TextStyle', '', 1);
          }

          let changedRows = e.sheet.getRowsByStatus('Added,Deleted');
          vm.$emit('update:changed', changedRows.length > 0);
        },
        onAfterRowDelete: function(e) {
          // Row 삭제 시 취소선 처리
          e.sheet.setAttribute(e.row, '', 'TextStyle', '9', 1);

          let changedRows = e.sheet.getRowsByStatus('Added,Deleted');
          vm.$emit('update:changed', changedRows.length > 0);
        },
      },
    };

    // vm.$ibsheetHelper.insertStatusColumn(sheetOptions);

    vm.grdList = IBSheet.create({
      id: vm.grdListId,
      el: vm.grdListId,
      options: sheetOptions,
    });

    this.internalAction.search = true;

    await this.$nextTick();

    this._search();
  },
  methods: {
    async research(param) {
      if (param) {
        let url = param.url;
        let response = await axios.post(url, param);
        setTimeout(() => {
          this.grdList.loadSearchData(response.data);
          this.internalFileNumber = param.flNo ? param.flNo : this.internalFileNumber;
        }, 500);
      }
    },
    btnAdd_click(e) {
      if (e !== undefined) {
        this.internalAction.add = true;
      }

      this.addMode = ADD_MODE.ADD_ROW;
      this.$refs.upload.$children[0].$el.click();

      this.$emit('update:add', false);
    },
    async btnDelete_click(e) {
      if (e !== undefined) {
        this.internalAction.delete = true;
      }

      let rows = this.grdList.getRowsByChecked('cbx');

      if (rows.length == 0) {
        await this.$alert('선택된 항목이 없습니다.');
        this.$emit('update:delete', false);
        return;
      }

      rows.forEach(row => {
        this.grdList.deleteRow(row);
      });

      this._delete();
    },
    async btnSave_click(e) {
      if (e !== undefined) {
        this.internalAction.save = true;
      }

      this.$emit('save-start');
      let newRows = this.grdList.getRowsByStatus('Added');
      if (newRows.length > 0) {
        this.$emit('upload-start', newRows[0].file.name);
        this.$refs.upload.update(newRows[0].file, {active: true, error: '', progress: '0.00'});
      } else {
        let b = await this.deleteFile();
        this.$emit('update:save', false);
        if (b) {
          this.$emit('update:changed', false);
          this.$emit('update:files', this.grdList.getSaveJson(0).data);
          // this.$emit('update:fileNumber', this.internalFileNumber);
          this.$emit('save-complete');
        } else {
          this.$emit('save-error');
        }
      }
    },
    async _search() {
      console.log(`_search`, this.internalFileNumber);
      await this.$nextTick();

      this.grdList.removeAll();
      this.$refs.upload.clear();

      if (this.internalFileNumber === 0) {
        this.grdList.loadSearchData([]);
      } else {
        const data = {
          pgmCd: this.companyCode,
          flNo: this.internalFileNumber,
          ...this.searchData,
        };

        try {
          const response = await axios.post(this.searchUrl, data);

          console.log(`loadSearchData`, this.internalFileNumber, data, response.data);
          this.grdList.loadSearchData(response.data);
        } catch (ex) {
          console.log(ex);
        }
      }

      this.$emit('update:changed', false);
      this.$emit('update:files', this.grdList.getSaveJson(0).data);
      this.$emit('update:search', false);
    },
    async deleteFile() {
      let r = true;
      let deleteRows = this.grdList.getRowsByStatus('Deleted');

      if (deleteRows.length > 0) {
        let jsonData = this.grdList.getSaveJson().data;
        this.$emit('delete-start');

        try {
          await axios.post('/file/deleteFile', jsonData);
          this.grdList.acceptChangedData();
          this.$emit('delete-complete');
        } catch (ex) {
          this.$emit('delete-error');
          r = false;
        }
      }

      return r;
    },
    _delete() {
      this.$emit('update:delete', false);
    },
    async _deleteAll() {
      let jsonData = this.grdList.getSaveJson(0).data;
      this.$emit('delete-all-start');

      try {
        await axios.post('/file/deleteFile', jsonData);
        this.grdList.removeAll();
        this.$emit('update:deleteAll', false);
        this.$emit('update:files', []);
        this.$emit('delete-all-complete');
      } catch (ex) {
        this.$emit('update:deleteAll', false);
        this.$emit('delete-all-error');
      }
    },
    _clear() {
      this.grdList.removeAll();

      this.$emit('update:files', []);
      this.$emit('update:fileNumber', 0);
      this.$emit('update:clear', false);
    },
    fileUpload_input() {
      // console.group(`fileUpload_input`);
      // console.log(files);
      // console.groupEnd();
    },
    fileUpload_inputFilter(newFile, oldFile) {
      // console.group(`fileUpload_inputFilter`);
      // console.log(newFile);
      // console.log(oldFile);
      // console.log(prevent);
      // console.groupEnd();

      if (newFile && !oldFile) {
        // Add file
      }

      if (newFile && oldFile) {
        // Update file
      }

      if (!newFile && oldFile) {
        // Remove file
        // Refused to remove the file
        // return prevent()
      }
    },
    async fileUpload_inputFile(newFile, oldFile) {
      const vm = this;
      // console.group(`fileUpload_inputFile`);
      // console.log(newFile);
      // console.log(oldFile);
      // console.groupEnd();

      if (newFile && !oldFile) {
        // Add file
        if (this.addMode === ADD_MODE.UPDATE_ROW) {
          this.grdList.setValue(this.updateTargetRow, 'lclFlNm', newFile.name);
          this.grdList.setValue(this.updateTargetRow, 'flSz', newFile.size);
          this.grdList.setValue(this.updateTargetRow, 'file', newFile);

          newFile.rowIndex = this.grdList.getRowIndex(this.updateTargetRow);
          newFile.rowId = this.updateTargetRow.id;
        } else {
          const data = {
            pgmCd: this.companyCode,
            flNo: this.internalFileNumber,
            flDs: this.$store.state.code2.codeAlias.FLDS_NORMAL.code,
            prjCd: this.projectCode,
            fbs: this.fbs,
            lclFlNm: newFile.name,
            // svrFlNm: newFile.name,
            flSz: newFile.size,
            allowAdd: 1,
            allowDelete: 1,
          };

          newFile.data = data;

          let row = this.grdList.addRow({
            // next: this.grdList.getLastRow(),
            init: data,
          });

          row.file = newFile;
          newFile.rowIndex = this.grdList.getRowIndex(row);
          newFile.rowId = row.id;
        }

        this.addMode = undefined;
        this.updateTargetRow = undefined;

        this.$emit('update:changed', true);
        this.$emit('update:files', this.grdList.getSaveJson(0).data);
      }

      if (newFile && oldFile) {
        // Update file

        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, {error: 'size'});
          // }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          let row = this.grdList.getRowById(newFile.rowId);
          this.grdList.setValue(row, 'uploadStatus', newFile.progress, false);
          this.grdList.refreshRow(row);
        }
        if (newFile.error && !oldFile.error) {
          // error
          let row = this.grdList.getRowById(newFile.rowId);
          this.grdList.setValue(row, 'uploadStatus', `실패: ${newFile.error}`, false);
          this.grdList.refreshRow(row);

          this.$emit('update:save', false);
          this.$emit('upload-error', newFile.name);
          this.$emit('update:fileNumber', this.internalFileNumber);
          this.$emit('save-error');
        }
        if (newFile.success && !oldFile.success) {
          // success
          let row = this.grdList.getRowById(newFile.rowId);
          let oldFileNumber = this.grdList.getValue(row, 'flNo');
          if (oldFileNumber === 0) {
            this.grdList.setValue(row, 'flNo', newFile.response.flNo, false);
          }
          this.grdList.setValue(row, 'grpNo', newFile.response.grpNo, false);
          this.grdList.setValue(row, 'flDs', newFile.response.flDs, false);
          this.grdList.setValue(row, 'revId', newFile.response.revId, false);
          this.grdList.setValue(row, 'verNo', newFile.response.verNo, false);
          this.grdList.setValue(row, 'uploadStatus', '완료', false);
          this.grdList.acceptChangedData(row);
          this.grdList.refreshRow(row);

          let newRows = this.grdList.getRowsByStatus('Added');

          // 파일번호가 초기값이면 업로드 후 생성된 파일번호를 신규 데이터에 설정한다.
          if (this.internalFileNumber === 0) {
            this.internalFileNumber = newFile.response.flNo;
            newRows.forEach(row => {
              vm.grdList.setValue(row, 'flNo', vm.internalFileNumber, false);
              row.file.data.flNo = vm.internalFileNumber;
              vm.grdList.refreshRow(row);
            });
          }

          this.$emit('upload-complete', newFile.name);
          this.$refs.upload.remove(newFile);

          if (newRows.length > 0) {
            this.$emit('upload-start', newRows[0].file.name);
            this.$refs.upload.update(newRows[0].file, {active: true, error: '', progress: '0.00'});
          } else {
            this.$emit('upload-all-complete');

            let b = await this.deleteFile();

            this.$emit('update:save', false);
            if (b) {
              this.$emit('update:changed', false);
              this.$emit('update:files', this.grdList.getSaveJson(0).data);
              this.$emit('update:fileNumber', this.internalFileNumber);
              this.$emit('save-complete', this.internalFileNumber || 0);
            } else {
              this.$emit('update:fileNumber', this.internalFileNumber);
              this.$emit('save-error');
            }
          }
        }
      }

      if (!newFile && oldFile) {
        // Remove file
      }
    },
  },
};
</script>

<style></style>

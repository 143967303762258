<template>
  <div class="section_con">
    <div class="tab_box">
      <ul class="tab_menu">
        <li
          v-for="(c, i) in comps"
          :key="i"
          @click="tabClick(i)"
          :class="[activetab === i ? 'select' : '']"
        >
          <a href="#" style="all: unset; cursor: pointer;">{{ c.tabNm }}</a>
        </li>
      </ul>
      <div class="add_work_end" >
        <!-- <a href="#" class="btn_small">버튼자리</a> -->
        <slot name="tab-right" />
      </div>
    </div>

    <div
      v-if="lazeLoad && tabItemCount > 0"
      class="grid_box grid_inner_scroll"
      style="height: calc(100% - 30px);border-top:2px solid #225eac"
    >
      <keep-alive v-if="keepAlive">
        <component :is="comp" :paramObj="paramObj"></component>
      </keep-alive>
      <component :is="comp" v-else></component>
    </div>
    <div v-if="!lazeLoad && tabItemCount > 0" class="grid_box">
      <div v-for="(c, i) in comps" :key="i" :class="{show: activetab === i}">
        <component :is="c.name"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comps: {
      type: Array,
    },
    lazeLoad: {
      type: Boolean,
      default: true,
    },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '1',
    },
    isAuto: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      activetab: 0,
      comp: '',
      activeCls: this.mode == '1' ? 'on ' : 'open',
      paramObj: {},
      tabItemCount: 0,
    };
  },
  watch: {
    activetab: function (active) {
      this.comp = this.comps[active].compNm;
      this.paramObj = this.comps[active].paramObj;
      // this.$emit('click', active);
    },
  },
  created: function () {
    const me = this;

    this.tabItemCount = this.comps.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.path === undefined || currentValue.path === '' ? 0 : 1);
    }, 0);

    me.comp = me.comps[0].compNm;
    me.paramObj = me.comps[0].paramObj;
    for (let c of me.comps) {
      if (c.path !== undefined) {
        me.addComponent(c.compNm, c.path);
      }
    }

    me.addEvent([
      {name: 'currentIndex', func: me.currentIndex},
      {name: 'setActiveTab', func: me.setActiveTab},
    ]);
  },
  mounted() {
    this.tabClick(0);
  },
  methods: {
    currentIndex(param) {
      let index = Array.from(this.$el.querySelectorAll('.tab > a')).findIndex((a) => a.classList.contains('on'));

      if (typeof param == 'function') {
        param(index);
      }
    },
    tabClick(index) {
      this.$emit('click', index);
      if (this.isAuto) {
        this.setActiveTab(index);
      }
    },
    async setActiveTab(index) {
      this.$emit('before-active-tab', index, this.activetab);
      this.activetab = index;
      await this.$nextTick();
      this.$emit('after-active-tab', index);
    },
  },
};
</script>

<style scoped>
.tab_container {
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}
</style>

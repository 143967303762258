<script>
import {mapGetters} from 'vuex';

import helpPop from '@/view/common/popup/HelpPop.vue';

export default {
  components: {
    helpPop,
  },
  props: {
    excelid: {
      type: String,
      default: 'abc',
    },

    buttonVwVisible: {
      type: Boolean,
      default: true,
    },
    buttonSvVisible: {
      type: Boolean,
      default: true,
    },
    buttonDelVisible: {
      type: Boolean,
      default: true,
    },
    buttonPrnVisible: {
      type: Boolean,
      default: true,
    },
    buttonExcelVisible: {
      type: Boolean,
      default: true,
    },

    // 회사 검색
    searchCompanyVisible: {
      type: Boolean,
      default: false,
    },
    searchCompanyCode: {
      type: [String, Number],
      default: '',
    },
    searchCompanyName: {
      type: String,
      default: '',
    },
    // 견적현장 검색
    searchEstimateProjectVisible: {
      type: Boolean,
      default: false,
    },
    searchEstimateProjectCode: {
      type: [String, Number],
      default: '',
    },
    searchEstimateProjectName: {
      type: String,
      default: '',
    },
    // 현장 검색
    searchProjectVisible: {
      type: Boolean,
      default: false,
    },
    searchProjectCode: {
      type: [String, Number],
      default: '',
    },
    searchProjectName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('menu', {
      currentViewName: 'selectedViewNm',
      currentViewPath: 'selectedViewPath',
    }),

    ...mapGetters('mdi', {
      currentMenu: 'currentUrls',
    }),

    ...mapGetters('layout', {
      commonButtons: 'cmmBtns',
    }),
    ...mapGetters({modules: 'modules'}),
    hasSearchboxSlot: {
      get() {
        return (
          this.searchCompanyVisible ||
          this.searchEstimateProjectVisible ||
          this.searchProjectVisible ||
          this.$slots['searchbox'] !== undefined
        );
      },
    },

    searchButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '조회') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
    newButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '신규') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
    saveButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '저장') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
    deleteButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '삭제') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
    printButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '인쇄') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
    excelButton: {
      get() {
        let b = this.commonButtons.find(button => button.name === '엑셀') || {};

        if (b !== undefined) {
          if (b.disabled === undefined) {
            b.disabled = false;
          }
        }

        return b;
      },
    },
  },
  created() {
    // let eventsCount1 = Object.keys(this._events).length;

    if (this.searchButton.callback !== undefined) {
      this.$on('search-click', this.searchButton.callback);
    }
    if (this.newButton.callback !== undefined) {
      this.$on('new-click', this.newButton.callback);
    }
    if (this.saveButton.callback !== undefined) {
      this.$on('save-click', this.saveButton.callback);
    }
    if (this.deleteButton.callback !== undefined) {
      this.$on('delete-click', this.deleteButton.callback);
    }
    if (this.printButton.callback !== undefined) {
      this.$on('print-click', this.printButton.callback);
    }
    if (this.excelButton.callback !== undefined) {
      this.$on('excel-click', this.excelButton.callback);
    }

    // this.$on('excel-click', function() {
    //   var jqid = 'sheet' + this.$store.getters['mdi/currentUrls'].menuId;
    //   var jqobj = document.getElementById(jqid);
    //   //alert(jqid+"/"+jqobj);
    //   if (!jqobj) return;
    //   var gridObj = $(jqobj);
    //   var filename = 'data.xls';
    //   var colModel = gridObj.jqGrid('getGridParam', 'colModel');
    //   var colNames = gridObj.jqGrid('getGridParam', 'colNames');
    //   var rowData = gridObj.jqGrid('getRowData');

    //   var groupHeader = gridObj.jqGrid('getGridParam', 'groupHeader');
    //   // 컬럼명이 colNames 에 정의되는 경우도 있어서 처리
    //   for (var i = 0; i < Math.min(colNames.length, colModel.length); i++) {
    //     /*lgksja 20210825 Elite 정보추가 S~*/
    //     if (colModel[i].excelView != null && colModel[i].excelView == true) {
    //       //hidden true 일경우 excel true 이면 엑셀다운로드 일때 보이게
    //       colModel[i].hidden = false;
    //     }
    //     /*lgksja 20210825 Elite 정보추가 E~*/
    //     if (!colModel[i].label || colModel[i].label == '') {
    //       colModel[i].label = colNames[i];
    //     }
    //     // 필요없는 항목 제거
    //     if (colModel[i].excludeExcel) {
    //       var colName = colModel[i].name;
    //       for (var j = 0; j < rowData.length; j++) {
    //         rowData[j][colName] = '';
    //       }
    //     }
    //   }
    //   var data = {
    //     colModel: colModel,
    //     data: rowData,
    //   };

    //   if (groupHeader != null && groupHeader.groupHeaders) data['groupHeaders'] = groupHeader.groupHeaders;
    //   var dataString = JSON.stringify(data);

    //   var fm = document.forms['excelExportForm'];
    //   fm.action = process.env.VUE_APP_BASE_URL + '/common/excel/excel_export.do';
    //   fm.subject.value = filename ? filename : '';
    //   fm.data.value = dataString;
    //   fm.submit();
    // });

    // let eventsCount2 = Object.keys(this._events).length;

    // if (eventsCount1 !== eventsCount2) {
    //   console.warn(`EVENT`);
    // }
  },
  methods: {
    /**
     * 회사 검색 이벤트 핸들러
     */
    sbxCompany_selected(e) {
      this.$emit('search-company-changed', {pgmCd: e.pgmCd, frmNm: e.frmNm});
    },

    txtTempCompanyCode_change(e) {
      this.$emit('search-company-changed', {
        code: e.target.value,
        name: e.target.value,
      });
    },

    /**
     * 견적현장 검색 이벤트 핸들러
     */
    sbxEstimateProject_selected(e) {
      this.$emit('search-estimate-project-changed', e);
    },

    /**
     * 현장 검색 이벤트 핸들러
     */
    sbxProject_selected(e) {
      this.$emit('search-project-changed', e);
    },

    /**
     * 닫기 버튼 이벤트 핸들러
     */
    btnClose_click() {
      let url;
      this.$store.getters['mdi/urls'].some(el => {
        if (el.isSelected) {
          url = el.url;
          return true;
        }
      });

      if (url == this.$store.getters.currentUrl) {
        this.$store.dispatch('mdi/removeMdi', url);
      } else {
        url = this.$store.getters.currentUrl;
        this.location.go(-1);
      }

      this.$store.commit('removePgmCd');

      if (this.$store.getters['loadVue'][url]) {
        this.$store.getters['loadVue'][url].$destroy();
        // this.$store.commit('removeLoadVue', url);
      }
      if (this.$store.getters.modules.indexOf(url.replace(/\//, '')) != -1) {
        this.$store.commit('removeModules', url.replace(/\//, ''));
        if (this.$store.state[url] !== undefined) {
          this.$store.unregisterModule(url);
        }
      }
    },
    btnHelp_click() {
      this.$modal.show('helpPop');
    },
  },
};
</script>

<template>
  <div :class="{'otis-page': true, 'iui-loading-overlay-parent': true}">
    <iui-loading-overlay :message="loadingMessage" :visible="loading" />
    <section
      :class="{
        /*n_search_wrap: hasSearchboxSlot,
				no_search_wrap: !hasSearchboxSlot, 
				middle_h: true,
				'header-box': true,*/
        heading_area: true,
      }"
    >
      <h2 class="btitle" style="white-space:nowrap">{{ currentViewName }}</h2>
      <div class="breadcrumbs" v-html="currentViewPath"></div>
      <div class="button_box" style="">
        <div v-if="searchCompanyVisible" style="display: inline-flex">
          <iui-searchbox
            v-if="searchCompanyVisible"
            label="회사"
            :value="searchCompanyCode"
            :nameValue="searchCompanyName"
            :callback="sbxCompany_selected"
            :type="'companyCode'"
          />
        </div>
        <iui-searchbox
          v-if="searchEstimateProjectVisible"
          label="현장"
          :value="searchEstimateProjectCode"
          :nameValue="searchEstimateProjectName"
          :callback="sbxEstimateProject_selected"
          :type="'prjEst'"
        />
        <iui-searchbox
          v-if="searchProjectVisible"
          label="현장"
          :value="searchProjectCode"
          :nameValue="searchProjectName"
          :callback="sbxProject_selected"
          :type="'prj'"
        />
        <slot name="searchbox" />
      </div>
      <div class="button_box" style="white-space:nowrap">
        <button
          v-if="buttonVwVisible && currentMenu.vwYn === 'Y'"
          class="btn_top_link01 ml3"
          :style="{'text-decoration-line': currentMenu.userVwYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userVwYn != 'Y'"
          @click="
            e => {
              $emit('search-click', e);
            }
          "
        >
          조회
        </button>

        <button
          v-if="buttonSvVisible && currentMenu.svYn === 'Y'"
          class="btn_top_link02 ml3"
          :style="{'text-decoration-line': currentMenu.userSvYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userSvYn != 'Y'"
          @click="
            e => {
              $emit('new-click', e);
            }
          "
        >
          신규
        </button>
        <button
          v-if="buttonSvVisible && currentMenu.svYn === 'Y'"
          class="btn_top_link03 ml3"
          :style="{'text-decoration-line': currentMenu.userSvYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userSvYn != 'Y'"
          @click="
            e => {
              $emit('save-click', e);
            }
          "
        >
          저장
        </button>
        <button
          v-if="buttonDelVisible && currentMenu.delYn === 'Y'"
          class="btn_top_link04 ml3"
          :style="{'text-decoration-line': currentMenu.userDelYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userDelYn != 'Y'"
          @click="
            e => {
              $emit('delete-click', e);
            }
          "
        >
          삭제
        </button>
        <button
          v-if="buttonPrnVisible && currentMenu.prnYn === 'Y'"
          class="btn_top_link05 ml3"
          :style="{'text-decoration-line': currentMenu.userPrnYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userPrnYn != 'Y'"
          @click="
            e => {
              $emit('print-click', e);
            }
          "
        >
          출력
        </button>
        <button
          v-if="buttonExcelVisible && currentMenu.excelYn === 'Y'"
          class="btn_top_link05 ml3"
          :style="{'text-decoration-line': currentMenu.userExcelYn != 'Y' ? 'line-through' : ''}"
          :disabled="currentMenu.userExcelYn != 'Y'"
          @click="
            e => {
              $emit('excel-click', e);
            }
          "
        >
          엑셀
        </button>
        <!-- <a
          href="#"
          v-for="btn in commonButtons"
          :key="btn.name"
          :class="[
            btn.name == '조회'
              ? 'btn_01 ml5'
              : btn.name == '신규'
              ? 'btn_02 ml5'
              : btn.name == '저장'
              ? 'btn_03 ml5'
              : btn.name == '삭제'
              ? 'btn_04 ml5'
              : btn.name == '출력'
              ? 'btn_05 ml5'
              : '',
          ]"
          @click="btn.callback"
          :disabled="btn.disabled"
          >{{ btn.name }}</a
        >-->
        <button class="btn_top_link06 ml5" v-if="$store.getters.currentUrl != '/Home'" @click="btnHelp_click">
          도움말
        </button>
      </div>
    </section>

    <div class="scroll_wrap" style="margin-top:0px;">
      <!-- contents 영역 scroll bar 시작 :  style="height: calc(100vh - 195px - 15px - 15px);" -->
      <div class="data_container h_100">
        <slot />
      </div>
      <!-- div data_container -->
    </div>

    <iui-modal-old name="helpPop" title="도움말" width="600px" height="350px">
      <helpPop :programId="currentMenu.programId" :helpTip="currentMenu.helpTip" :helpUrl="currentMenu.helpUrl" />
    </iui-modal-old>

    <form name="excelExportForm" target="ifdownload" method="POST">
      <input type="hidden" name="subject" />
      <input type="hidden" name="data" />
    </form>
    <iframe name="ifdownload" style="width:0px;height:0px;border:0;visibility:hidden;"></iframe>
  </div>
</template>

<style lang="scss">
@mixin box {
  align-items: center;
  display: flex;
  // align-content: space-between;
}

@mixin box-item-margin {
  > * {
    margin-right: 15px;
  }

  > *:last-child {
    margin-right: unset;
  }

  > label {
    margin-right: 5px;
  }
}

@mixin box-items {
  a {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    // font-family: 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
    // font-size: 12px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  button {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: #666;
    font-weight: bold;
    // font-family: 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
    // font-size: 12px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

.otis-page {
  width: 100%;
  height: 100%;

  .header-box {
    .search-box {
      @include box;
      @include box-item-margin;
      // @include box-items;

      flex-wrap: wrap;
    }
  }

  .debug {
    border: 1px dashed #aaa;
    padding: 5px;
  }

  .debug > * {
    border: 1px dashed #aaa;
    padding: 5px;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>

import Vue from 'vue';
const Plugin = {
  install(Vue) {
    this.event = new Vue();

    Vue.prototype.$loading = (msg, callback) => {
      let p1 = new Promise((resolve, reject) => {
        Plugin.event.$emit('show', msg, resolve, reject, callback);
      });
      return p1;
    };
  },
};

Vue.use(Plugin);

export default Plugin;

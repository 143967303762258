<template>
  <pmis-page
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
  >
    <!-- <iui-container type="css-flex">
      <i-row>
        <i-col flex="1">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <CompanyList />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col flex="2">
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <CompanyInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container>-->
    <pmis-tab-box>
      <template v-slot:title>상세정보</template>
      <CompanyInfo />
    </pmis-tab-box>
  </pmis-page>
</template>

<script>
// import CompanyList from './components/CompanyList.vue';
import CompanyInfo from './components/CompanyInfo.vue';
export default {
  components: {
    // CompanyList,
    CompanyInfo,
  },
  beforeCreate() {
    $mapGetters(this, ['']);
    $mapMutations(this, ['']);
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  data: function() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
    };
  },
  methods: {
    onSearch() {
      // this.callEvent({name: 'CompanyList_search'});
      this.callEvent({name: 'CompanyInfo_search', param: this.userInfo.pgmCd});
    },
    onAdd() {
      this.callEvent({name: 'CompanyInfo_add'});
    },
    onSave() {
      this.callEvent({name: 'CompanyInfo_save'});
    },
    onDelete() {
      this.callEvent({name: 'CompanyInfo_delete'});
    },
    onPrint() {},
  },
};
</script>

<style></style>

<template>
  <!--pmis-page-->
  <pmis-content-box v-if="true">
    <template v-slot:header-right>
      <iui-button @click="onReqToken">오픈뱅킹 토큰발급</iui-button>
    </template>
    <iui-container type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <iui-container type="table" theme="bullet" header-width="100px">
            <!--i-row>
            <i-col-header>자원구분</i-col-header>
            <i-col>
              <iui-select-old
                name="costType"
                p-code="0051"
                exclude-items="C,A"
                :defaultCd="'S'"
                @change="onSetState('costType', $event)"
                :value="isFormula ? (detailInfoDtls ? detailInfoDtls.costType : detailInfo.costType) : ''"
                width="120px"
              />
            </i-col>
          </i-row-->
            <i-row>
              <i-col-header>API Key</i-col-header>
              <i-col>
                <iui-text name="clientId" :value="inputData.clientId" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>API Secret</i-col-header>
              <i-col>
                <iui-text name="clientSecret" :value="inputData.clientSecret" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>Access Token</i-col-header>
              <i-col>
                <iui-text name="accessToken" :value.sync="inputData.accessToken" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>만료기간(초)</i-col-header>
              <i-col>
                <iui-text name="expiresIn" :value.sync="inputData.expiresIn" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>scope</i-col-header>
              <i-col>
                <iui-text name="scope" :value.sync="inputData.scope" />
              </i-col>
            </i-row>
          </iui-container>
        </i-row>
        <i-spacer />
        <!--i-row>
        <pmis-content-box>
          <template v-slot:title>생성방법</template>
          <p>1...... </p>
        </pmis-content-box>
      </i-row-->
      </i-col>
    </iui-container>
  </pmis-content-box>
  <!--/pmis-page-->
</template>

<script>
export default {
  props: {
    cmsData: {
      type: Object,
    },
  },
  beforeCreate() {
    $mapGetters(this, {detailInfoDtls: 'detailInfoDtls', isFormula: 'isFormula', detailInfo: 'detailInfo'});
  },
  created() {
    this.addEvent({name: 'OpenBankingTokenPop_popConfirm', func: this.onPopConfirm});
    if (this.cmsData) {
      this.setForm(this.cmsData);
    }
  },
  data() {
    return {
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      inputData: {
        inputDivCd: '',
        clientUseCode: '',
        clientId: '',
        clientSecret: '',
        accessToken: '',
        refreshToken: '',
        expiresIn: '',
        tknIssuDt: '',
        scope: '',
        bankTranId: '',
        currentDt: '',
      },
    };
  },
  mounted() {},
  methods: {
    setForm(param) {
      this.inputData.clientUseCode = param.clientUseCode;
      this.inputData.clientId = param.clientId;
      this.inputData.clientSecret = param.clientSecret;
      this.inputData.scope = param.scope;
    },
    onPopConfirm(param) {
      console.log('@@@@pop confirm', this.inputData);
      if (!this.inputData.accessToken) {
        this.$alert('토큰발급을 수행하세요.');
        return;
      }
      param(this.inputData);
    },
    onReqToken() {
      console.log('onReqToken 토큰발급 시작~~');

      console.log('axios pop1==' + axios.defaults.headers.common.Authorization);

      var me = this;
      //var axios = require('axios');
      var qs = require('qs'); //querystring
      var data = qs.stringify({
        client_id: this.inputData.clientId,
        client_secret: this.inputData.clientSecret,
        scope: 'oob',
        grant_type: 'client_credentials',
      });
      var config = {
        method: 'post',
        //url: '/openbanking/oauth/2.0/token', //프록시서버로 크로스도메인처리
        url: 'https://testapi.openbanking.or.kr/oauth/2.0/token',
        // headers: {
        //   'Content-Type': 'application/x-www-form-urlencoded',
        //   'Authorization' : 'Bearer '
        // },
        data: data,
      };
      axios(config)
        .then(function(response) {
          console.log('받은해더:' + response.headers); //XMLHttpRequest.getResponseHeader('Authorization'));
          console.log('토큰데이터 수신 1==', response.data);
          //console.log('토큰데이터 수신 2==', JSON.stringify(response.data));

          me.inputData.accessToken = response.data.access_token;
          me.inputData.expiresIn = response.data.expires_in;
          me.inputData.scope = response.data.scope;

          axios.post('/cms/updateCmsToken', me.inputData);

          me.$alert('신규 토큰이 발급되었습니다.');

          //console.log('axios pop2=='+axios.defaults.headers.common.Authorization);

          //콜백함수 호출
          //this.callEvent({name: 'callbackToken', param: JSON.stringify(response.data) });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>성명</i-col-header>
            <i-col>
              <iui-text :value="myInfo.name" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>담당업무</i-col-header>
            <i-col>
              <iui-text :value="myInfo.wbusinessName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>직책</i-col-header>
            <i-col>
              <iui-text :value="myInfo.dtsNoName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>일반전화</i-col-header>
            <i-col>
              <iui-text :value="myInfo.tlno" @change="setValue('tlno', $event)" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>휴대폰</i-col-header>
            <i-col>
              <iui-text :value="myInfo.mobileTlno" @change="setValue('mobileTlno', $event)" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>아이디</i-col-header>
            <i-col>
              <iui-text :value="myInfo.empNo" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>신규비밀번호</i-col-header>
            <i-col>
              <iui-text :value="myInfo.pwd1" @change="setValue('pwd1', $event)" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>비밀번호확인</i-col-header>
            <i-col>
              <iui-text :value="myInfo.pwd2" @change="setValue('pwd2', $event)" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="250px">
      <i-col>
        <iui-container type="css-flex">
          <i-row>
            <i-col>
              <pmis-content-box>
                <template v-slot:title>사진파일 정보</template>
                <pmis-image-file
                  :company-code="myInfo.pgmCd"
                  :file-number.sync="myInfo.phoFlNo"
                  :changed.sync="photofileChanged"
                  @save-complete="image_save_complete"
                  @save-error="image_save_error"
                  @delete-complete="image_delete_complete('phoFlNo')"
                  @delete-error="image_delete_error"
                  style="border: 1px solid #ccc;"
                />
              </pmis-content-box>
            </i-col>
            <i-spacer />
            <i-col>
              <pmis-content-box>
                <template v-slot:title>싸인파일 정보</template>
                <pmis-image-file
                  :company-code="myInfo.pgmCd"
                  :file-number.sync="myInfo.sgntFlNo"
                  :changed.sync="signfileChanged"
                  @save-complete="image_save_complete"
                  @save-error="image_save_error"
                  @delete-complete="image_delete_complete('sgntFlNo')"
                  @delete-error="image_delete_error"
                  style="border: 1px solid #ccc;"
                />
              </pmis-content-box>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  created() {
    this.addEvent({name: 'MyPage_confirm', func: this.onConfirm});
    this.onSearch();
  },
  data() {
    return {
      myInfo: {
        pgmCd: '',
        empNo: '',
        name: '',
        wbusiness: '',
        wbusinessName: '',
        dtsNo: '',
        dtsNoName: '',
        tlno: '',
        mobileTlno: '',
        pwd1: '',
        pwd2: '',
        pwd: '',
        phoFlNo: '',
        sgntFlNo: '',
      },
      isChangePwd: false,
      photofileChanged: false,
      signfileChanged: false,
    };
  },
  methods: {
    async onSearch() {
      let pgmCd = this.userInfo.pgmCd;
      let empNo = this.userInfo.empNo;
      let response = await axios.post('/myPage/selectMyInfo', {pgmCd, empNo});

      for (let key in this.myInfo) {
        this.myInfo[key] = response.data[key];
      }
    },
    setValue(key, event) {
      this.myInfo[key] = event.target.value;
      this.isChangePwd = this.myInfo.pwd1 || this.myInfo.pwd2 ? true : false;
    },
    async onConfirm() {
      if (this.isChangePwd) {
        if (!this.myInfo.pwd1) {
          this.$alert('비밀번호를 입력해야 합니다.');
          return;
        }
        if (!this.myInfo.pwd2) {
          this.$alert('비밀번호 확인을 입력해야 합니다.');
          return;
        }
        if (this.myInfo.pwd1 != this.myInfo.pwd2) {
          this.$alert('비밀번호가 다릅니다. 다시 한 번 확인하시기 바랍니다.');
          return;
        }
        this.myInfo.pwd = this.myInfo.pwd1;
      }

      if (this.photofileChanged || this.signfileChanged) {
        let target = this.photofileChanged ? '사진' : '싸인';
        this.$alert(`${target}파일 정보가 변경되었습니다.<br/>파일을 먼저 저장하시기 바랍니다.`);
        return;
      }

      if (await this.$confirm('저장하시겠습니까?')) {
        this.onSave();
      }
    },
    async onSave() {
      let response = await axios
        .post('/myPage/updateMyInfo', this.myInfo)
        .catch(e => console.error('저장을 실패했습니다. :', e));

      if (response.status == 200) {
        this.$alert('저장되었습니다.');
        this.onSearch();
      } else {
        this.$alert('저장을 실패했습니다.');
      }
    },
    image_save_complete() {
      this.onSave();
    },
    image_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
    image_delete_complete(target) {
      this.myInfo[target] = 0;
      this.onSave();
    },
    image_delete_error() {
      this.$alert('파일 삭제를 실패했습니다.');
    },
  },
};
</script>

<style></style>

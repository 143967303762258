<template>
  <!-- <div class="contents"> -->
  <iui-container type="css-flex" theme="bullet">
    <i-row v-if="isOContract">
      <i-col>
        <OContractProving />
      </i-col>
    </i-row>
    <i-row v-if="isOffcialDocument">
      <i-col>
        <OffcialDocumentProving />
      </i-col>
    </i-row>
    <i-row v-if="isTContract">
      <i-col>
        <TContractProving />
      </i-col>
    </i-row>
    <i-row v-if="isChangeContract">
      <i-col>
        <ChangeContractProving />
      </i-col>
    </i-row>
  </iui-container>

  <!-- <div class="btn_area">
      <button type="button" class="btn_default" @click="onLogin">로그인</button>
    </div>
  </div> -->
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import LinkViewStore from './store/LinkView.js';
import OContractStore from './oContract/store/oContract.js';
import TContractStore from './tContract/store/tContract.js';
import OContractProving from '@/view/contract/components/linkproving/OContractProving.vue';
import OffcialDocumentProving from '@/view/contract/components/linkproving/OffcialDocumentProving.vue';
import TContractProving from '@/view/contract/components/linkproving/TContractProving.vue';
import ChangeContractProving from '@/view/contract/components/linkproving/ChangeContractProving.vue';
export default {
  name: 'LinkProving',
  components: {
    // eslint-disable-next-line
    OContractProving,
    // eslint-disable-next-line
    OffcialDocumentProving,
    // eslint-disable-next-line
    TContractProving,
    // eslint-disable-next-line
    ChangeContractProving,
  },
  beforeCreate() {
    this.$store.dispatch('setStoreNm', 'LinkViewStore');
    $init(this, LinkViewStore);
    this.$store.dispatch('setStoreNm', 'OContractStore');
    $init(this, OContractStore);
    this.$store.dispatch('setStoreNm', 'TContractStore');
    $init(this, TContractStore);
  },
  data() {
    return {};
  },
  mounted() {
    this.checkAccessDeviceType();
  },
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapMutations('LinkViewStore', {
      setDecParams: 'setDecParams',
    }),
    async checkAccessDeviceType() {
      console.log('###############');
      console.log(window.params);

      let param = {
        encParam: window.params.encParam,
      };

      let response = await axios.post('/urlLink/decAndParseParam', param);
      console.log(response);
      console.log('###############');

      this.setDecParams(response.data);

      console.log('decParams', this.decParams);

      if (this.isOContract) {
        console.log('isOContract');
        this.callEvent({name: 'OContract_Search', param: undefined});
      } else if (this.isOffcialDocument) {
        console.log('isOffcialDocument');
        this.callEvent({name: 'OffcialDocument_Search', param: undefined});
      } else if (this.isTContract) {
        console.log('isTContract');
        this.callEvent({name: 'TContract_Search', param: undefined});
      } else if (this.isChangeContract) {
        console.log('isChangeContract');
        this.callEvent({name: 'ChangeContract_Search', param: undefined});
      }
    },

    onLogin() {
      this.setPage('Login');
    },
  },
  computed: {
    ...mapGetters('LinkViewStore', {
      decParams: 'decParams',
    }),
    isOContract() {
      return window.params.shortUrlLinkType == 'OC_PC';
    },
    isOffcialDocument() {
      return window.params.shortUrlLinkType == 'OD_PC';
    },
    isTContract() {
      return window.params.shortUrlLinkType == 'TC_PC';
    },
    isChangeContract() {
      return window.params.shortUrlLinkType == 'TCA_PC';
    },
  },
};
</script>

<style scoped></style>

<script>
import {defineComponent, reactive, ref, computed, onMounted, nextTick} from '@vue/composition-api';
import moment from 'moment';

const CHART = {
  XAXIS: {
    labels: {
      allowOverlap: true,
      rotation: 0,
      style: {
        fontSize: '12px',
      },
      overflow: 'allow',
    },
    lineColor: '#000000',
    lineWidth: 1,
  },
  YAXIS_COLUMN: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    lineWidth: 0,
    min: 0,
    max: 150,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  YAXIS_LINE: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    min: -150,
    max: 100,
    lineWidth: 0,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  SERIES_COLUMN: {
    type: 'column',
    color: '#0033a0',
    dataLabels: {
      enabled: true,
      align: 'center',
      color: '#000000',
      // format: '{point.y:.0f}%',
      shadow: false,
      style: {
        fontSize: '12px',
        // textOutline: '1px contrast',
      },
      verticalAlign: 'top',
      y: -22,
    },
    // minPointLength: 3,
    shadow: false,
    yAxis: 0,
  },
  SERIES_LINE: {
    type: 'line',
    color: '#f65275',
    dataLabels: {
      enabled: true,
      align: 'center',
      format: '{point.y:.0f}%',
      style: {
        fontSize: '12px',
      },
    },
    tooltip: {
      valueSuffix: '%',
    },
    yAxis: 1,
  },
};

export default defineComponent({
  setup(props, context) {
    const divList = [];
    const parentTeamList = [];
    const teamList = [];
    const useRateTemplate = {
      period: undefined,
      onlineCount: 0,
      offlineCount: 0,
      totalCount: 0,
      onlineRate: 0,
      offlineRate: 0,
    };

    // prettier-ignore
    // eslint-disable-next-line no-constant-condition
    if (true) {
      divList.push({divCd: 'NE', pageList: [1, 2], teamNoList: ['NE'], teamNo: 'NE', teamName: 'NE'});
      divList.push({divCd: 'MOD', pageList: [1], teamNoList: ['MOD'], teamNo: 'MOD', teamName: 'MOD/SRP'});

      // NE
      parentTeamList.push({divCd: 'NE', pageList: [], teamNo: '1S0', teamName: 'NE > Metro1'});
      parentTeamList.push({divCd: 'NE', pageList: [], teamNo: '1C0', teamName: 'NE > Metro2'});
      parentTeamList.push({divCd: 'NE', pageList: [], teamNo: '1Q0', teamName: 'NE > QTO'});
      parentTeamList.push({divCd: 'NE', pageList: [], teamNo: '1T0', teamName: 'NE > Regional'});

      // NE > Metro1, Metro2
      teamList.push({divCd: 'NE', pageList: [2], parentTeamNo: '1S0', parentTeamName: 'Metro1', teamNoList: ['1S2'], teamNo: '1S2', teamName: 'NE Sales1'});
      teamList.push({divCd: 'NE', pageList: [2], parentTeamNo: '1S0', parentTeamName: 'Metro1', teamNoList: ['1S3'], teamNo: '1S3', teamName: 'NE Sales2'});
      teamList.push({divCd: 'NE', pageList: [2], parentTeamNo: '1C0', parentTeamName: 'Metro2', teamNoList: ['1C6'], teamNo: '1C6', teamName: 'NE Sales3'});
      teamList.push({divCd: 'NE', pageList: [2], parentTeamNo: '1C0', parentTeamName: 'Metro2', teamNoList: ['1C7'], teamNo: '1C7', teamName: 'NE Sales4'});
      // NE > Metro1 > Major
      teamList.push({divCd: 'NE', pageList: [2], parentTeamNo: '1S0', parentTeamName: 'Metro1', teamNoList: ['1UA'], teamNo: '1UA', teamName: 'NE Major Project'});
      // NE > QTO
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1Q0', parentTeamName: 'QTO', teamNoList: ['1Q2'], teamNo: '1Q2', teamName: 'NE QTO SR1'});
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1Q0', parentTeamName: 'QTO', teamNoList: ['1Q3'], teamNo: '1Q3', teamName: 'NE QTO SR2'});
      // teamList.push({pageList: [1], divCd: 'NE', parentTeamNo: '1Q0', parentTeamName: 'QTO', teamNo: '1Q4', teamName: 'QTO SR3'});
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1Q0', parentTeamName: 'QTO', teamNoList: ['1Q6'], teamNo: '1Q6', teamName: 'NE SA Mgt.'});
      // teamList.push({divCd: 'NE', parentTeamNo: '1Q0', parentTeamName: 'QTO', teamNo: '1QA', teamName: 'QTO QTO Agent'});

      // NE > Regional
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1T0', parentTeamName: 'Regional', teamNoList: ['1T1'], teamNo: '1T1', teamName: 'NE 경남영업'});
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1T0', parentTeamName: 'Regional', teamNoList: ['1T2'], teamNo: '1T2', teamName: 'NE 경북영업'});
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1T0', parentTeamName: 'Regional', teamNoList: ['1T3'], teamNo: '1T3', teamName: 'NE 서부영업'});
      teamList.push({divCd: 'NE', pageList: [1], parentTeamNo: '1T0', parentTeamName: 'Regional', teamNoList: ['1T4'], teamNo: '1T4', teamName: 'NE 중부영업'});

      // MOD
      parentTeamList.push({divCd: 'MOD', pageList: [], teamNo: '5C1', teamName: 'MOD > Metro1'});
      parentTeamList.push({divCd: 'MOD', pageList: [], teamNo: '5C2', teamName: 'MOD > Metro2'});
      parentTeamList.push({divCd: 'MOD', pageList: [], teamNo: '5C6', teamName: 'MOD > QTO'});
      parentTeamList.push({divCd: 'MOD', pageList: [], teamNo: 'DC0', teamName: 'MOD > Regional'});

      // MOD > Metro1, 2, QTO
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: '5C1', parentTeamName: 'Metro1', teamNoList: ['5C1'], teamNo: '5C1', teamName: 'MOD Metro1'});
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: '5C2', parentTeamName: 'Metro1', teamNoList: ['5C2'], teamNo: '5C2', teamName: 'MOD Metro2'});
      // {divCd: 'MOD', teamNo: '5C4', teamName: 'Excellence'},
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: '5C6', parentTeamName: 'QTO', teamNoList: ['5C6'], teamNo: '5C6', teamName: 'MOD QTO'});
      // MOD > Regional
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: 'DC0', parentTeamName: 'Regional', teamNoList: ['5UZ'], teamNo: '5UZ', teamName: 'MOD 경남영업'});
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: 'DC0', parentTeamName: 'Regional', teamNoList: ['5UY'], teamNo: '5UY', teamName: 'MOD 경북영업'});
      teamList.push({divCd: 'MOD', pageList: [1], parentTeamNo: 'DC0', parentTeamName: 'Regional', teamNoList: ['561'], teamNo: '561', teamName: 'MOD 중부영업'});
    }

    const startDate = '20220901';
    let fromDate = moment()
      .startOf('month')
      .format('YYYYMMDD');
    let toDate = moment(startDate)
      .endOf('month')
      .add(6, 'month')
      .format('YYYYMMDD');

    if (toDate < fromDate) {
      toDate = moment(fromDate)
        .endOf('month')
        .format('YYYYMMDD');
      fromDate = moment(toDate)
        .startOf('month')
        .add(-6, 'month')
        .format('YYYYMMDD');
    } else {
      fromDate = startDate;
    }

    const state = reactive({
      searchData: {
        divCd: undefined,
        fromDate: fromDate,
        toDate: toDate,
        fromPeriod: undefined,
        toPeriod: undefined,
        useRateBaseDate: 'ENTRY_DATE',
      },

      codeList: {
        columnSizeList: [
          {key: '2', value: '2개'},
          {key: '3', value: '3개'},
          {key: '4', value: '4개'},
        ],
        useRateBaseDate: [
          {key: 'ENTRY_DATE', value: '수주등록일'},
          {key: 'CONT_DATE', value: '계약일'},
        ],
      },

      periodList: undefined,
      // parentTeamList: {all: undefined, ne: undefined, mod: undefined},
      // teamList: {all: undefined, ne: undefined, mod: undefined},
      // seriesByPeriod: {all: undefined, ne: undefined, mod: undefined},
      // seriesByParentTeam: {all: undefined, ne: undefined, mod: undefined},
      // seriesByTeam: {all: undefined, ne: undefined, mod: undefined},

      nePage1ColumnSize: 4,
      nePage2ColumnSize: 4,
      modPage1ColumnSize: 4,

      nePage1ColumnWidth: computed(() => {
        const width = 100 / state.nePage1ColumnSize;

        return `${width}%`;
      }),

      nePage2ColumnWidth: computed(() => {
        const width = 100 / state.nePage2ColumnSize;

        return `${width}%`;
      }),

      modPage1ColumnWidth: computed(() => {
        const width = 100 / state.modPage1ColumnSize;

        return `${width}%`;
      }),

      neTeamList: computed(() => {
        return teamList.filter(team => team.divCd === 'NE');
      }),

      nePage1TeamList: computed(() => {
        return [
          ...divList.filter(div => div.divCd === 'NE' && div.pageList.includes(1)),
          ...teamList.filter(team => team.divCd === 'NE' && team.pageList.includes(1)),
        ];
      }),

      nePage2TeamList: computed(() => {
        return [
          ...divList.filter(div => div.divCd === 'NE' && div.pageList.includes(2)),
          ...teamList.filter(team => team.divCd === 'NE' && team.pageList.includes(2)),
        ];
      }),

      modTeamList: computed(() => {
        return teamList.filter(team => team.divCd === 'MOD');
      }),

      modPage1TeamList: computed(() => {
        return [
          ...divList.filter(div => div.divCd === 'MOD' && div.pageList.includes(1)),
          ...teamList.filter(team => team.divCd === 'MOD' && team.pageList.includes(1)),
        ];
      }),

      loading: false,
      loadingMessage: undefined,
    });

    const privateMethod = {
      getChartId(team, page) {
        return `divChart_${team.divCd}_${page}_${team.teamNo}`;
      },
      calcurateUseRate(useRate) {
        useRate.totalCount = useRate.onlineCount + useRate.offlineCount;
        useRate.onlineRate = Math.round((useRate.onlineCount / useRate.totalCount) * 100);
        useRate.offlineRate = Math.round((useRate.offlineCount / useRate.totalCount) * 100);
      },
      columnDataLabelsFormatter(data, team) {
        return `${team.useRateList[data.point.x].onlineCount} / ${team.useRateList[data.point.x].totalCount}`;
      },
      tooltipFormatter(data, team) {
        return (
          `<b><i>${team.useRateList[data.point.x].tooltipPeriod}</i></b>` +
          `<hr size="1" />` +
          `<b>${data.series.name}: ${data.point.y}</b>%` +
          `<hr size="1" />` +
          `전자계약: <b>${team.useRateList[data.point.x].onlineCount}건</b><br />` +
          `서면계약: <b>${team.useRateList[data.point.x].offlineCount}건</b><br />` +
          `전체계약: <b>${team.useRateList[data.point.x].totalCount}건</b>`
        );
      },
      createChart(teamList, page) {
        teamList.forEach(team => {
          const id = privateMethod.getChartId(team, page);
          // const parentElement = document.getElementById(id).parentElement;
          // const width = parentElement.offsetWidth - 2;
          // const height = width * 0.8;

          createIBChart(id, id, {
            width: '100%',
            height: '300px',
          });
        });
      },
      resizeChart(teamList, page) {
        teamList.forEach(team => {
          const id = privateMethod.getChartId(team, page);
          const parentElement = document.getElementById(id).parentElement;
          const width = parentElement.offsetWidth - 2;
          const height = width * 0.8;

          window[id].setSize(width, height);
        });
      },
    };

    const method = {
      async search_click() {
        state.loadingMessage = '데이터를 가져오고 있습니다.';
        state.loading = true;

        try {
          const response = await axios.post('/statistics/useRate', state.searchData);

          const useRateList = response.data;
          const periodList = [];

          state.loadingMessage = '데이터를 분석하고 있습니다... (1/5)';
          await nextTick();

          // period
          useRateList
            .reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.period) === -1) {
                a.push(c.period);
              }

              return a;
            }, [])
            .sort()
            .forEach(period => {
              let displayPeriod = `${period.substring(2, 4)}P${period.substring(4, 6)}`;
              const tooltipPeriod = displayPeriod;
              if (period.substring(4, 6) !== '01') {
                displayPeriod = `P${period.substring(4, 6)}`;
              }

              periodList.push({
                period: period,
                displayPeriod: displayPeriod,
                tooltipPeriod: tooltipPeriod,
                useRate: undefined,
              });
            });

          if (periodList.length > 0) {
            periodList[0].displayPeriod = `${periodList[0].period.substring(2, 4)}P${periodList[0].period.substring(
              4,
              6
            )}`;
          }

          state.loadingMessage = '데이터를 분석하고 있습니다... (2/5)';
          await nextTick();

          // 기간/팀 별
          teamList.forEach(team => {
            team.useRateList = [];

            periodList.forEach(period => {
              const useRateListByPeriodAndTeam = useRateList.filter(
                useRate => team.teamNoList.includes(useRate.teamNo) && useRate.period === period.period.toString()
              );

              const useRate = useRateListByPeriodAndTeam.reduce(
                (a, c) => {
                  a.onlineCount += c[`${c.divCd.toLowerCase()}ContractOnlineCount`];
                  a.offlineCount += c[`${c.divCd.toLowerCase()}ContractOfflineCount`];

                  return a;
                },
                {
                  ...useRateTemplate,
                  period: period.period.toString(),
                  displayPeriod: period.displayPeriod,
                  tooltipPeriod: period.tooltipPeriod,
                }
              );

              privateMethod.calcurateUseRate(useRate);

              team.useRateList.push(useRate);
            });
          });

          state.loadingMessage = '데이터를 분석하고 있습니다... (3/5)';
          await nextTick();

          // 기간/상위 팀 별
          parentTeamList.forEach(parentTeam => {
            parentTeam.useRateList = [];

            periodList.forEach(period => {
              parentTeam.useRateList.push({
                ...useRateTemplate,
                period: period.period.toString(),
                displayPeriod: period.displayPeriod,
                tooltipPeriod: period.tooltipPeriod,
              });
            });

            const teamListByParentTeam = teamList.filter(team => team.parentTeamNo === parentTeam.teamNo);

            teamListByParentTeam.forEach(team => {
              periodList.forEach(period => {
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const t = team.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                parentTeam.useRateList[p].onlineCount += team.useRateList[t].onlineCount;
                parentTeam.useRateList[p].offlineCount += team.useRateList[t].offlineCount;

                privateMethod.calcurateUseRate(parentTeam.useRateList[p]);
              });
            });
          });

          state.loadingMessage = '데이터를 분석하고 있습니다... (4/5)';
          await nextTick();

          // 사업부 별
          divList.forEach(div => {
            div.useRateList = [];

            periodList.forEach(period => {
              div.useRateList.push({
                ...useRateTemplate,
                period: period.period.toString(),
                displayPeriod: period.displayPeriod,
                tooltipPeriod: period.tooltipPeriod,
              });
            });

            const parentTeamByDiv = parentTeamList.filter(parentTeam => parentTeam.divCd === div.divCd);

            parentTeamByDiv.forEach(parentTeam => {
              periodList.forEach(period => {
                const d = div.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                div.useRateList[d].onlineCount += parentTeam.useRateList[p].onlineCount;
                div.useRateList[d].offlineCount += parentTeam.useRateList[p].offlineCount;

                privateMethod.calcurateUseRate(div.useRateList[d]);
              });
            });
          });

          state.periodList = periodList;

          state.loadingMessage = '데이터를 분석하고 있습니다... (5/5)';
          await nextTick();

          state.nePage1TeamList.forEach(team => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);

            window[`divChart_${team.divCd}_1_${team.teamNo}`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: `${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter(this, team);
                  },
                },
                series: [
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                      dataLabels: {
                        ...CHART.SERIES_COLUMN.dataLabels,
                        formatter: function() {
                          return privateMethod.columnDataLabelsFormatter(this, team);
                        },
                      },
                    },
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });

          state.nePage2TeamList.forEach(team => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);

            window[`divChart_${team.divCd}_2_${team.teamNo}`].removeAll();
            window[`divChart_${team.divCd}_2_${team.teamNo}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: `${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter(this, team);
                  },
                },
                series: [
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                      dataLabels: {
                        ...CHART.SERIES_COLUMN.dataLabels,
                        formatter: function() {
                          return privateMethod.columnDataLabelsFormatter(this, team);
                        },
                      },
                    },
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });

          state.modPage1TeamList.forEach(team => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);

            window[`divChart_${team.divCd}_1_${team.teamNo}`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: `${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter(this, team);
                  },
                },
                series: [
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                      dataLabels: {
                        ...CHART.SERIES_COLUMN.dataLabels,
                        formatter: function() {
                          return privateMethod.columnDataLabelsFormatter(this, team);
                        },
                      },
                    },
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
          state.loadingMessage = undefined;
        }
      },

      async nePage1ColumnSize_change(e) {
        await nextTick();

        privateMethod.resizeChart(state.nePage1TeamList, 1);
      },

      async nePage2ColumnSize_change(e) {
        await nextTick();

        privateMethod.resizeChart(state.nePage2TeamList, 2);
      },

      async modPage1ColumnSize_change(e) {
        await nextTick();

        privateMethod.resizeChart(state.modPage1TeamList, 1);
      },
    };

    onMounted(async () => {
      await nextTick();

      privateMethod.createChart(state.nePage1TeamList, 1);
      privateMethod.createChart(state.nePage2TeamList, 2);
      privateMethod.createChart(state.modPage1TeamList, 1);

      method.search_click();
    });

    return {
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page :loading="state.loading" :loading-message="state.loadingMessage" @search-click="search_click">
    <iui-container type="css-flex" theme="bullet">
      <i-row height="70px" ref="searchBox">
        <i-col>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>기간</i-col-header>
              <i-col>
                <!-- <span style="white-space:nowrap"> -->
                <iui-select
                  v-model="state.searchData.useRateBaseDate"
                  :items="state.codeList.useRateBaseDate"
                  item-key-alias="key"
                  item-text-alias="value"
                  item-value-alias="key"
                  placeholder="선택"
                />
                <iui-datepicker v-model="state.searchData.fromDate" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.toDate" />
                <!-- </span> -->
              </i-col>
              <!-- <i-col-header>기준일자</i-col-header>
              <i-col>
                <span style="white-space:nowrap">
                </span>
              </i-col> -->
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <iui-tab-group>
            <iui-tab title="NE QTO, 지방영업">
              <iui-content-box>
                <template #title-right>
                  <iui-select
                    v-model="state.nePage1ColumnSize"
                    :items="state.codeList.columnSizeList"
                    item-key-alias="key"
                    item-text-alias="value"
                    item-value-alias="key"
                    label="한 줄 표시:"
                    @change="nePage1ColumnSize_change"
                  />
                </template>
                <iui-container type="css-flex" no-border>
                  <i-row style="flex-wrap: wrap;">
                    <i-col v-for="team in state.nePage1TeamList" :key="team.teamNo" :width="state.nePage1ColumnWidth">
                      <div :id="`divChart_${team.divCd}_1_${team.teamNo}`"></div>
                    </i-col>
                  </i-row>
                </iui-container>
              </iui-content-box>
            </iui-tab>
            <iui-tab title="NE Metro">
              <iui-content-box>
                <template #title-right>
                  <iui-select
                    v-model="state.nePage2ColumnSize"
                    :items="state.codeList.columnSizeList"
                    item-key-alias="key"
                    item-text-alias="value"
                    item-value-alias="key"
                    label="한 줄 표시:"
                    @change="nePage2ColumnSize_change"
                  />
                </template>
                <iui-container type="css-flex" no-border>
                  <i-row style="flex-wrap: wrap;">
                    <i-col v-for="team in state.nePage2TeamList" :key="team.teamNo" :width="state.nePage2ColumnWidth">
                      <div :id="`divChart_${team.divCd}_2_${team.teamNo}`"></div>
                    </i-col>
                  </i-row>
                </iui-container>
              </iui-content-box>
            </iui-tab>
            <iui-tab title="MOD">
              <iui-content-box>
                <template #title-right>
                  <iui-select
                    v-model="state.modPage1ColumnSize"
                    :items="state.codeList.columnSizeList"
                    item-key-alias="key"
                    item-text-alias="value"
                    item-value-alias="key"
                    label="한 줄 표시:"
                    @change="modPage1ColumnSize_change"
                  />
                </template>
                <iui-container type="css-flex">
                  <i-row style="flex-wrap: wrap;">
                    <i-col v-for="team in state.modPage1TeamList" :key="team.teamNo" :width="state.modPage1ColumnWidth">
                      <div :id="`divChart_${team.divCd}_1_${team.teamNo}`" style="width: 100%; height: 300px;"></div>
                    </i-col>
                  </i-row>
                </iui-container>
              </iui-content-box>
            </iui-tab>
          </iui-tab-group>
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style scoped>
/* .iui-container,
.iui-container .row,
.iui-container .col {
  border: 1px solid gray;
  padding: 5px;
} */
</style>

<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="text"
        name="frmNm"
        :label="'회사명'"
        @change="setSearchInfo('frmNm', $event)"
        @enter="setSearchInfo('frmNm', $event, 'Y')"
      />
    </template>
    <template v-slot:header-right>
      <iui-checkbox-group-old name="closeAt" :items="checkboxItems" @change="setSearchInfo('closeAt', $event)" />
    </template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['']);
    $mapMutations(this, ['setPgmCd']);
  },
  created() {
    this.addEvent([{name: 'CompanyList_search', func: this.onSearch}]);
    this.addEvent([{name: 'CompanyList_setFocusKeyCode', func: this.setFocusKeyCode}]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      checkboxItems: [{value: false, label: '종료포함'}],
      searchInfo: {
        frmNm: '',
        closeAt: false,
      },
      focusKeyCode: '',
    };
  },
  mounted() {
    const OPT = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1: 전체 편집 가능 (default)
      },
      Cols: [
        {
          Header: '코드',
          Name: 'pgmCd',
          Type: 'Text',
          Width: 100,
          Align: 'center',
        },
        {
          Header: '회사명',
          Name: 'frmNm',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '종료여부',
          Name: 'useEnddate',
          Type: 'Text',
          Width: 100,
          Align: 'center',
          CustomFormat: v => (v == '' ? '' : '종료'),
        },
      ],
      Events: {
        onDataLoad: this.grid_onDataLoad,
        onclick: this.grid_onclick,
      },
    };
    this.sheet = IBSheet.create({id: this.sheetId, el: this.elId, options: OPT});
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let response = await axios.post('/companyRegist/selectCompanyList', this.searchInfo);
      this.sheet.loadSearchData(response.data);
    },
    grid_onDataLoad() {
      if (0 < this.sheet.getTotalRowCount()) {
        if (this.focusKeyCode) {
          let focusRow = this.sheet.getDataRows().find(row => row.pgmCd == this.focusKeyCode);
          if (focusRow !== undefined) {
            setTimeout(() => {
              this.sheet.focus(focusRow);
              this.onEmit(focusRow.pgmCd);
            }, 100);
          }
        } else {
          this.onEmit(this.sheet.getFirstRow().pgmCd);
        }
      } else {
        this.onEmit(null);
      }
    },
    grid_onclick(e) {
      this.onEmit(e.row.pgmCd);
    },
    onEmit(pgmCd) {
      this.setPgmCd(pgmCd);
      this.$emit('emitCompanyInfo');
    },
    setSearchInfo(key, e, searchYn) {
      let value = '';
      if (e.target.type == 'text') {
        value = e.target.value;
      } else if (e.target.type == 'checkbox') {
        value = e.target.checked;
      }
      this.searchInfo[key] = value;

      if (searchYn == 'Y') {
        this.onSearch();
      }
    },
    setFocusKeyCode(param) {
      this.focusKeyCode = param.pgmCd;
      if (param.isDelete) {
        this.searchInfo.closeAt = true;
        document.getElementsByName('closeAt')[0].checked = true;
      }
      this.onSearch();
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select-old
        name="boardType"
        :items="arrBoardType"
        :value="boardType"
        :enable="true"
        @change="setBoardType"
        required
      />
      <iui-select-old
        :items="searchItems"
        :value="searchInfo.searchFlag"
        @change="setSearchValue('searchFlag', $event)"
      />
      <iui-text :value="searchInfo.searchText" @change="setSearchValue('searchText', $event)" @enter="onSearch" />
    </template>

    <pmis-content-box>
      <iui-container type="jqgrid" :id="sheetId" style="width:99.5%" />
    </pmis-content-box>
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  props: {
    boardType: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.addEvent([
      {name: 'BoardList_search', func: this.onSearch},
      {name: 'BoardList_create', func: this.onCreate},
    ]);
    this.natDs = this.boardType;
    this.pgmCd = this.userInfo.pgmCd;

    this.objTskDs = {};
    this.menu.forEach(el => {
      this.objTskDs[el.mnuCd] = el.mnuNm;
    });
  },
  data() {
    return {
      arrBoardType: [
        {text: '공지사항', value: 'NOTI'},
        {text: '자료실', value: 'FILE'},
      ],

      sheetId: 'sheet' + this._uid,
      searchItems: [
        {text: '제목', value: '1'},
        {text: '등록자', value: '2'},
      ],
      searchInfo: {
        searchFlag: '1',
        searchText: '',
      },
      subVue: [
        {
          name: `boardView${this._uid}`,
          url: `/boardView${this._uid}`,
          path: './view/common/board/components/BoardView.vue',
        },
      ],
      natDs: 'NOTI',
      pgmCd: '',
      research: false,
      objTskDs: {},
    };
  },
  activated() {
    if (this.$route.params.research !== undefined) {
      this.research = true;
      this.onSearch();
    }
  },
  mounted() {
    let attachFileImagePath = require(`@/assets/img/old/icon_zip.gif`);
    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 390,
      width: 800,
      colModel: [
        {name: 'no', label: '순번', width: 30, align: 'center', hidden: true},
        {name: 'natNo', label: '글번호', width: 0, align: 'center', hidden: true},
        {
          name: 'natDs',
          label: '구분',
          width: 30,
          align: 'center',
          editable: false,
          edittype: 'select',
          editoptions: {value: 'NOTI:공지사항;FAQ:FAQ;FILE:자료실;'},
        },
        {
          name: 'rngDs',
          label: '열람대상',
          width: 30,
          align: 'center',
          editable: false,
          edittype: 'select',
          editoptions: {value: 'ALL:전체;CUSTOMER:CUSTOMER;OTIS:OTIS'},
        },
        {
          name: 'files',
          label: '첨부',
          width: 30,
          align: 'center',
          formatter: function(val) {
            return val > 0 ? "<img src='" + attachFileImagePath + "' />" : '';
          },
        },
        {name: 'tl', label: '제목', width: 200, align: 'left'},
        {name: 'rgprId', label: '등록자ID', width: 0, align: 'center', hidden: true},
        {name: 'rgprNm', label: '등록자', width: 30, align: 'center'},
        {
          name: 'rgsDttm',
          label: '등록일',
          width: 50,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {name: 'inqCnt', label: '조회', width: 30, align: 'center'},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      ondblClickRow: function(rowId, iRow, iCol) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        me.onGoVue({
          pgmCd: this.pgmCd,
          natNo: rowData.natNo,
          boardStatus: 'E',
          natDs: me.natDs,
          empNo: rowData.rgprId,
        });
      },
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onSearch();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
     // var newHeight = pobj.height();
      //console.log('newHeight', newHeight);
      //$grid.jqGrid('setGridHeight', newHeight, true);
      //20220706 lgksja   
      var newHeight = pobj.parent().height() - 50;
      $grid.jqGrid('setGridHeight', newHeight, false);
    },
    async onSearch() {
      let response = await axios.post('/board/selectBoardList', {
        pgmCd: this.pgmCd,
        natDs: this.natDs,
        frmNm: this.searchInfo.frmNm,
      });
      $('#' + this.sheetId)
        .clearGridData(true)
        .setGridParam({data: response.data})
        .trigger('reloadGrid');
      this.onResize();
    },
    setBoardType(event) {
      this.boardType = event.target.value;
      this.natDs = event.target.value;
      this.onSearch();
    },
    setSearchValue(target, event) {
      this.searchInfo[target] = event.target.value;
    },
    onCreate() {
      this.onGoVue({
        boardStatus: 'I',
        natDs: this.natDs,
        empNo: this.userInfo.empNo,
      });
    },
    onGoVue(param) {
      let research = this.research;
      let routeName = this.$route.name;
      this.goVue(`/boardView${this._uid}`, {...param, research, routeName});
    },
  },
  computed: {
    ...mapGetters('mdi', {currentMenu: 'currentUrls'}),
    ...mapGetters('menu', {
      menu: 'menu',
    }),
  },
};
</script>

<style></style>

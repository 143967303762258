<template>
  <pmis-page
    ref="page"
    @search-click="onSearch"
    @search-project-changed="onPrjChange"
    @save-click="onSave"
    @new-click="onNew"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <jqgrid />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import jqgrid from './components/jqgrid';
import store from './store/jqgrid.js';
export default {
  components: {
    jqgrid,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, {searchInfo: 'searchInfo', detailInfo: 'detailInfo'});
    $mapMutations(this, ['setSearchInfo', 'initDetailInfo']);
  },
  created() {},
  methods: {
    onSearch() {
      this.callEvent({
        name: 'onSearch',
      });
    },

    onPrjChange(e) {
      console.log(e);
      this.setSearchInfo(e);
    },
    onSave() {
      alert('onSave');
    },
    onNew() {
      alert('onNew');
    },
  },
};
</script>

<style></style>

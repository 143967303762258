import qs from 'qs';
import VueCookies from 'vue-cookies';
import {Base64} from 'js-base64';
const state = {
  id: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
  accessTokenKey: sessionStorage.getItem('id') ? /*sessionStorage.getItem('id') + */ 'SCD' + '_accessToken' : '',
  accessToKenExpires: '1d',
  refreshTokenKey: sessionStorage.getItem('id') ? /*sessionStorage.getItem('id') + */ 'SCD' + '_refreshToken' : '',
  refreshTokenExpires: '30d',
  isLogin: false,
  page: sessionStorage.getItem('isLogin')
    ? location.pathname.indexOf('/Popup') != -1
      ? 'Popup'
      : location.pathname.indexOf('/WindowPopup') != -1
      ? 'WindowPopup'
      : 'Home'
    : 'Login',
};

const getters = {
  isLogin: state => state.isLogin,
  isPage: state => state.page,
  accessTokenKey: state => state.accessTokenKey,
  accessToKenExpires: state => state.accessToKenExpires,
  refreshTokenKey: state => state.refreshTokenKey,
  refreshTokenExpires: state => state.refreshTokenExpires,
};

const mutations = {
  setPage(state, payload) {
    state.page = payload;
  },
};

const actions = {
  async doLogin({state}, formData) {
    const pgmCd = formData.pgmCd;
    const id = formData.id;
    const password = formData.password;
    const page = formData.page;
    const gjde = formData.gjdeInit;
    const tradeNo = formData.tradeNo;
    
    
    console.log("gjde",gjde);
    console.log("formData.gjdeInit",formData.gjdeInit);
    console.log("formData",formData);
    if(gjde === 'Y'){
      //8자리 거래선으로 새로운 거래선을 생성한다.
      console.log("aaaaaaaaaaaaaaaa");
      const paramT = JSON.stringify({
        tradeNo: tradeNo,
        userId: id,
      });

      try {
        console.log("vvvvvvvvvvvvvvvvvv");
        const responseT = await axios.post('/user/gjdeInitTradeNo', paramT, {
          headers: {
            'content-type': 'application/json',
          },
        });
        console.log("responseT",responseT);
        
      } catch (ex) {
        console.log("ex",ex);
        return;
      }
    }

    const saveId = document.getElementById('c1').checked;
    if (saveId) {
      VueCookies.set('saveId', id, state.refreshTokenExpires);
    } else {
      VueCookies.remove('saveId');
    }

    state.accessTokenKey = /*id + */ 'SCD' + '_accessToken';
    state.refreshTokenKey = /*id + */ 'SCD' + '_refreshToken';

    var isPass = password.indexOf('(!pass!)') == 0;
    var isPki = password.indexOf('(!pki!)') == 0;

    const data = qs.stringify({
      // 'username': 'T00480',
      // 'password': '1234',
      // 'grant_type': 'password',
      username: pgmCd + '.' + id,
      password: password,
      grant_type: 'password',
    });

    try {
      const response = await axios.post('/oauth/token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: process.env.VUE_APP_AUTH_USER_NAME,
          password: process.env.VUE_APP_AUTH_PASSWORD,
        },
      });
      console.log("response",response);
      if(!isPass && !isPki && response.data.userid.length == '7'){
        //alert('거래선 번호가 7자리에서 8자리로 변경되었습니다. 8자리 거래선 번호인 ' + response.data.tradeNo + ' 로 다시 로그인 해주세요.');
        if(response.data.listTradeItem.length > 0){
          $("#divLoadingImage").hide();
          return response.data.listTradeItem;
        }else{
          $("#divLoadingImage").hide();
          alert('이미 8자리 거래선 번호로 로그인을 하신 이력이 있습니다. 8자리 거래선 번호로 로그인해주세요.');
          return;
        }
      }

      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      const tokenType = response.data.token_type;

      VueCookies.set(state.accessTokenKey, tokenType + ' ' + accessToken, state.accessToKenExpires);
      VueCookies.set(state.refreshTokenKey, refreshToken, state.refreshTokenExpires);
      state.isLogin = true;
      sessionStorage.setItem('id', id);
      sessionStorage.setItem('isLogin', state.isLogin);
      sessionStorage.setItem('userInfo', Base64.decode(accessToken.split('.')[1]));

      window.temp_id = id;
      window.temp_isLogin = state.isLogin;
      
      $("#divLoadingImage").hide();
     
      if (page) {
        //location.href="/"+page;
        state.page = page;
      }
      return;
     
      
      //로그인 이력 남기기
      // let LoginLogVo = {
      //   pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      //   userid: JSON.parse(sessionStorage.getItem('userInfo')).userid,
      // };
      // axios.post('/loginLog/updateLoginHistory', LoginLogVo);
    } catch (ex) {
      $("#divLoadingImage").hide();
      if (ex.response.status === 401 && ex.response.data.error == 'unauthorized') {
        alert('로그인실패:' + ex.response.data.error_description);
      }
      if (ex.response.status === 400 && ex.response.data.error == 'invalid_grant') {
        if (isPass) {
          alert('패스 로그인에 실패하였습니다.');
        } else if (isPki) {
          alert('인증서 로그인에 실패하였습니다.');
        } else {
          //alert('잘못된 비밀번호 입니다.');
          alert('아이디 또는 비밀번호가 올바르지 않습니다.');
        }
      }
    }
  },
  async doLogout({state}) {
    //로그아웃 이력 남기기
    // eslint-disable-next-line
    let LoginLogVo = {
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      userid: JSON.parse(sessionStorage.getItem('userInfo')).userid,
    };

    // 20210513 로그
    try {
      await axios.post('/scdUser/logAccessLogout', {progrmId: 'LOGOUT'});
    } catch (ex) {
      alert(ex);
    }

    state.isLogin = false;
    VueCookies.remove(state.accessTokenKey);
    VueCookies.remove(state.refreshTokenKey);
    sessionStorage.clear();
    // sessionStorage.removeItem('id');
    // sessionStorage.removeItem('isLogin');
    location.replace('/');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

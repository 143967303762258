<script>
import {defineComponent, ref, reactive, computed} from '@vue/composition-api';

import UpperCode from './components/UpperCode.vue';
import LowerCode from './components/LowerCode.vue';

export default defineComponent({
  components: {UpperCode, LowerCode},
  setup(props, context) {
    const state = reactive({
      search: false,
      mainRowData: undefined, //(Main)현재 선택된 Row
      dataChanged: false, //(Main)데이터 변경여부
    });

    const refs = {
      upperCode: ref(null),
      lowerCode: ref(null),
    };

    const method = {
      search_click() {
        // state.search = true;
        refs.upperCode.value.search_click();
      },
    };

    // const testData = computed(() => {
    //   console.log('computed....', state.mainRowData);
    // });

    return {
      state,
      // testData,
      ...refs,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page @search-click="search_click">
    <iui-container type="css-flex">
      <i-row>
        <i-col width="560px">
          <upper-code
            ref="upperCode"
            :search="state.search"
            :selected-row-data.sync="state.mainRowData"
            :data-changed.sync="state.dataChanged"
          />
        </i-col>
        <i-spacer />
        <i-col>
          <lower-code ref="lowerCode" :selected-row-data="state.mainRowData" :data-changed.sync="state.dataChanged" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

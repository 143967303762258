<template>
  <ul v-show="false">
    <li v-for="item in items" :key="item.mappedBy">
      {{item.upload}}
      <file-upload
        class="btn btn-primary dropdown-toggle"
        name="files"
        :headers="headers"
        :post-action="postAction"
        v-model="item.files"
        @input-file="inputFile"
        :ref="item.upload"
        :data="{mappedBy: item.mappedBy, pgmCd: pgmCd}"
      ></file-upload>
    </li>
  </ul>
</template>

<script>
import FileUpload from 'vue-upload-component';
import VueCookies from 'vue-cookies';
export default {
  components: {
    FileUpload: FileUpload,
  },

  props: {
    sheet: {
      type: Object,
      default: () => {},
    },
    mappedBy: {
      type: [String, Number],
    },
    pgmCd: {
      type: [String, Number],
    },
    search: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    enableUpload: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: false,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      name: 'file',
      postAction: `${process.env.VUE_APP_BASE_URL}/file/saveFile`,
      headers: {
        Authorization: VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD'+ '_accessToken'),
      },
      data: {
        pgmCd: '201900',
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      },
      updateTargetRow: undefined,
      flNos: [],
      uploads: [],
      uploadIndex: 0,
    };
  },

  created() {
    this.$nextTick(() => {
      if (this.sheet.getDataRows().length) {
        this.init();
      } else {
        this.sheet.bind('onDataLoad', () => {
          this.init();
        });
      }
    });

    this.$emit('handler', {upload: this.onUpload});
    // this.sheet.bind('onDataLoad', () => {
    //   this.onSearchFile();
    // });
  },

  methods: {
    init() {
      this.sheet.addCol(
        'flNo',
        1,
        -1,
        {
          Header: '파일번호',
          Type: 'Text',
          Width: 80,
          Visible: false,
        },
        false
      );
      this.sheet.addCol(
        'lclFlNm',
        1,
        -1,
        {
          Header: '파일명',
          Type: 'Text',
          Width: 200,
          CanEdit: false,
        },
        this.enable
      );
      this.sheet.addCol(
        'flSz',
        1,
        -1,
        {
          Header: '크기',
          Name: 'flSz',
          CanEdit: false,
          Width: 80,
          Align: 'right',
          Type: 'Int',
          Format: '#,##0',
          EditFormat: '#,##0',
        },
        this.enable
      );
      this.sheet.addCol(
        'fileUpload',
        1,
        -1,
        {
          Header: '업로드',
          Type: 'Button',
          Name: 'conf_btn',
          Button: 'Button',
          Width: 120,
          ButtonText: '업로드',
          OnClick: this.buttonClick,
        },
        this.enableUpload
      );

      this.onSearchFile();
    },
    onSearchFile() {
      this.items = this.sheet.getDataRows();
      this.items.forEach((el) => {
        el.files = [];
        el.upload = `upload${el[this.mappedBy] || el.HasIndex}`;
        el.success = false;
        el.mappedBy = el[this.mappedBy] || el.HasIndex;
        if (el.flNo) this.flNos.push(el.flNo);
      });

      this.flNos.forEach((el) => {
        axios.post('/file/selectFileList', {pgmCd: this.pgmCd, flNo: el}).then((response) => {
          if (response.status == 200) {
            if (!response.data.length) return;
            this.items.some((el) => {
              if (el.flNo == response.data[0].flNo) {
                el.fileInfo = response.data[0];
                el.lclFlNm = el.fileInfo.lclFlNm;
                el.flSz = el.fileInfo.flSz;
                this.sheet.refreshRow(el);
              }
            });
          }
        });
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        // 自动压缩
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing';
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {error: '', file, size: file.size, type: file.type});
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {error: err.message || 'compress'});
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.sheet.setValue(this.updateTargetRow, 'lclFlNm', newFile.name, 1);
        this.sheet.setValue(this.updateTargetRow, 'flSz', newFile.size, 1);
      }
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, {error: 'size'});
          // }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
          this.fileUploadComplate(newFile);
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = {...file, show: true};
      this.$refs.upload.update(file, {error: 'edit'});
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name,
      };
      if (this.editFile.cropper) {
        let binStr = atob(this.editFile.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1]);
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, {type: this.editFile.type});
        data.size = data.file.size;
      }
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = '';
      this.editFile.show = false;
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support');
        return;
      }
      let input = this.$refs.upload.$el.querySelector('input');
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;
      input.onclick = null;
      input.click();
      input.onclick = () => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type,
      });
      this.$refs.upload.add(file);
    },

    buttonClick(e) {
      this.$refs[`upload${e.row.mappedBy}`][0].$children[0].$el.click();
      this.updateTargetRow = e.row;
    },

    onUpload() {
      let delFiles = [];
      this.items.forEach((el) => {
        this.uploads.push(el.mappedBy);
        if (el.fileInfo)
          delFiles.push({
            pgmCd: el.fileInfo.pgmCd,
            flNo: el.fileInfo.flNo,
            flDs: el.fileInfo.flDs,
          });
        delete el.fileInfo;
      });

      if (delFiles.length) {
        axios.post('/file/deleteFile', delFiles);
      }

      this.upload();
    },

    upload() {
      this.$refs[`upload${this.uploads[this.uploadIndex]}`][0].active = true;
    },

    fileUploadComplate(file) {
      this.items.forEach((el) => {
        if (el.mappedBy == file.data.mappedBy) {
          el.success = file.success;
          el.flNo = file.response.flNo;
        }
      });

      let uploadComplete = true;

      this.items.forEach((el) => {
        if (el.files.length && !el.success) {
          uploadComplete = false;
        }
      });

      if (this.uploads.length != this.uploadIndex + 1) {
        this.uploadIndex++;
      }

      if (uploadComplete) {
        this.uploads = [];
        this.uploadIndex = 0;
        this.$emit('complete');
      }
    },
  },
  watch: {
    uploadIndex() {
      this.upload();
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.onSearchFile();
          this.$emit('update:search', false);
        }
      }
    },
    enable(newValue, oldValue) {
      if (newValue !== oldValue) {
        newValue ? this.sheet.showCol('lclFlNm') : this.sheet.hideCol('lclFlNm');
        newValue ? this.sheet.showCol('flSz') : this.sheet.hideCol('flSz');
      }
    },
    enableUpload(newValue, oldValue) {
      if (newValue !== oldValue) {
        newValue ? this.sheet.showCol('fileUpload') : this.sheet.hideCol('fileUpload');
      }
    },
  },
};
</script>

<style></style>

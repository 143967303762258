<script>
import {defineComponent, reactive, toRef, inject, onBeforeMount, onBeforeUnmount} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase.js';

export default defineComponent({
  name: 'IuiTab',
  props: {
    ...BaseProps,
    title: {
      type: String,
      default: undefined,
    },
  },
  setup(props, context) {
    const Base = useBase(props, context, 'iui-tab');

    const addTab = inject('addTab');
    const removeTab = inject('removeTab');

    const state = reactive({
      active: false,
    });

    onBeforeMount(() => {
      const tab = reactive({
        tabId: Base.state.id,
        title: props.title,
        enable: toRef(Base.state, 'enable'),
        active: toRef(state, 'active'),
      });

      addTab(tab);
    });

    onBeforeUnmount(() => {
      removeTab(Base.state.id);
    });

    return {
      Base,
      state,
    };
  },
});
</script>

<template>
  <div :id="Base.state.id" v-show="state.active" style="width: 100%; height: 100%;">
    <slot />
  </div>
</template>

<style></style>

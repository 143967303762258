import {numToKr} from 'vue-number-to-kor';

const state = () => ({
    searchInfo: {
      pgmCd: '',
      ctrtNo: '',
    },
    contractList: [],
    contractInfo: {
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      ctrtSttusCd: '',
      suriNo: '',
      suriSeq: '',
      tradeNo: '',
      tradeSfx: '',
      tradeName: '',
      mskTradeName: '',
      mainNo: '',
      contDate: '',
      buildStartDate: '',
      buildEndDate: '',
      deliveryDate: '',
      biddingAmt: 0,
      biddingAmtKor: '',
      vatTypeTl: '',
      vatType: '',
      biddingVatAmt: 0,
      biddingVatAmtKor: '',
      totalBiddingAmt: 0,
      totalBiddingAmtKor: '',
      mainName: '',
      addr: '',
      suFlagA7: '',
      suFlag: '',
      tradeAddr: '',
      tradeEmail: '',
      tradeMphone: '',
      teamNoDesc: '',
      salesTeamNo: '',
      srpType: '',
      subcontractQh: '',
      subcontractQhNm: '',
      subcontract: '',
      chkPayCondition: '',
      contractAmount: 0,
      contractVatAmount: 0,
      totalContractAmount: 0,
      midAmount: 0,
      midVatAmount: 0,
      totalMidAmount: 0,
      stdRemainderAmount: 0,
      stdRemainderVatAmount: 0,
      stdTotalRemainderAmount: 0,
      nonstdRemainderAmount: 0,
      nonstdRemainderVatAmount: 0,
      nonstdTotalRemainderAmount: 0,
      collectionCond11: '',
      signType: 'ON',
      contractType: '',
      docCat1: '',
      docNm: '',
      docGb1: '',
      docGb2: '',
      docGb3: '',
      histSn: '',
      ctrtOrginlCn: '',
      ctrtOrginlHashcd: '',
      ctrtOrginlFileNo: '',
      signDt: '',
      retrnResnCn: '',
      contEmpId: '',
      contEmpNm: '',
      repntName: '',
      businessNo: '',
      beforeTradeName: '',
      beforeRepntName: '',
      beforeBusinessNo: '',
      beforeContDate: '',
      diffContDate: '',
      beforeTotalBiddingAmt: 0,
      diffTotalBiddingAmt: 0,
      beforeOespNameA: '',
      beforeBillRateA: 0,
      oespNameA: '',
      billRateA: 0,
      beforeOespNameB: '',
      beforeBillRateB: 0,
      oespNameB: '',
      billRateB: 0,
      beforeOespNameC: '',
      beforeBillRateC: 0,
      oespNameC: '',
      billRateC: 0,
      renewItems: '',
      renewReasonTrade: '',
    },
    contractItemList: [],
    paymentCondForStdList: [],
    paymentCondForNonStdList: [],
    memberList: [],
    stamptaxList: [],
    guaranteeList: [],
    etcDocList: [],

    contractHistoryList: [], //20221202 ITSR62600

    isProgressPage: false,
    isCompletePage: false,

    modalStamptax: {
      stmptaxSn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },

    modalGuarantee: {
      grntySn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },

    modalEtcDoc: {
      etcdocsn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },
  });
  const getters = {
    searchInfo: state => state.searchInfo,
    contractList: state => state.contractList,
    contractInfo: state => state.contractInfo,
    contractItemList: state => state.contractItemList,
    contractHistoryList: state => state.contractHistoryList, //20221202 ITSR62600
    paymentCondForStdList: state => state.paymentCondForStdList,
    paymentCondForNonStdList: state => state.paymentCondForNonStdList,
    memberList: state => state.memberList,
    stamptaxList: state => state.stamptaxList,
    guaranteeList: state => state.guaranteeList,
    etcDocList: state => state.etcDocList,
    isProgressPage: state => state.isProgressPage,
    isCompletePage: state => state.isCompletePage,
    isOfflineComplete: state => (state.contractInfo.signType == "OFF"),
    modalStamptax : state => state.modalStamptax,
    modalGuarantee : state => state.modalGuarantee,
    modalEtcDoc : state => state.modalEtcDoc,
    isContEmp : state => (state.contractInfo.contEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo),
  };
  const mutations = {
    setSearchInfo(state, payload) {
      for (let key in state.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.searchInfo[key] = payload[key];
        }
      }
    },
    setContractList(state, payload) {
      state.contractList = payload;
    },
    setContractInfo(state, payload) {
      for (let key in state.contractInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.contractInfo[key] = payload[key];
        }
      }

      let CI = state.contractInfo;

      CI.biddingAmtKor      = numToKr(CI.biddingAmt);
      CI.biddingVatAmtKor   = numToKr(CI.biddingVatAmt);
      CI.totalBiddingAmtKor = numToKr(CI.totalBiddingAmt);
    },
    setItemList(state, payload){
      state.contractItemList = payload;
    },
    setHistoryList(state, payload){ //20221202 ITSR62600
      state.contractHistoryList = payload;
    },
    setPaymentCondForStdList(state, payload){
      state.paymentCondForStdList = payload;
    },
    setPaymentCondForNonStdList(state, payload){
      state.paymentCondForNonStdList = payload;
    },
    setMemberList(state, payload){
      state.memberList = payload;
    },
    setStamptaxList(state, payload){
      state.stamptaxList = payload;
    },
    setGuaranteeList(state, payload){
      state.guaranteeList = payload;
    },
    setEtcDocList(state, payload){
      state.etcDocList = payload;
    },
    setProgressPage(state, payload){
      state.isProgressPage = payload;
    },
    setCompletePage(state, payload){
      state.isCompletePage = payload;
    },
    setModalStamptax(state, payload) {
      for (let key in state.modalStamptax) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalStamptax[key] = payload[key];
        }
      }
    },
    setModalGuarantee(state, payload) {
      for (let key in state.modalGuarantee) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalGuarantee[key] = payload[key];
        }
      }
    },
    setModalEtcDoc(state, payload) {
      for (let key in state.modalEtcDoc) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalEtcDoc[key] = payload[key];
        }
      }
    },
    resetAllValues(state) {
      state.contractInfo.ctrtNo = '';
      state.contractInfo.ctrtOdr = '';
      state.contractInfo.ctrtDivCd = '';
      state.contractInfo.ctrtSttusCd = '';
      state.contractInfo.suriNo = '';
      state.contractInfo.suriSeq = '';
      state.contractInfo.tradeNo = '';
      state.contractInfo.tradeSfx = '';
      state.contractInfo.tradeName = '';
      state.contractInfo.mskTradeName = '';
      state.contractInfo.mainNo = '';
      state.contractInfo.contDate = '';
      state.contractInfo.buildStartDate = '';
      state.contractInfo.buildEndDate = '';
      state.contractInfo.deliveryDate = '';
      state.contractInfo.biddingAmt = 0;
      state.contractInfo.biddingAmtKor = '';
      state.contractInfo.vatTypeTl = '';
      state.contractInfo.vatType = '';
      state.contractInfo.biddingVatAmt = 0;
      state.contractInfo.biddingVatAmtKor = '';
      state.contractInfo.totalBiddingAmt = 0;
      state.contractInfo.totalBiddingAmtKor = '';
      state.contractInfo.mainName = '';
      state.contractInfo.addr = '';
      state.contractInfo.suFlagA7 = '';
      state.contractInfo.suFlag = '';
      state.contractInfo.tradeAddr = '';
      state.contractInfo.tradeEmail = '';
      state.contractInfo.tradeMphone = '';
      state.contractInfo.teamNoDesc = '';
      state.contractInfo.salesTeamNo = '';
      state.contractInfo.srpType = '';
      state.contractInfo.subcontractQh = '';
      state.contractInfo.subcontractQhNm = '';
      state.contractInfo.subcontract = '';
      state.contractInfo.chkPayCondition = '';
      state.contractInfo.contractAmount = 0;
      state.contractInfo.contractVatAmount = 0;
      state.contractInfo.totalContractAmount = 0;
      state.contractInfo.midAmount = 0;
      state.contractInfo.midVatAmount = 0;
      state.contractInfo.totalMidAmount = 0;
      state.contractInfo.stdRemainderAmount = 0;
      state.contractInfo.stdRemainderVatAmount = 0;
      state.contractInfo.stdTotalRemainderAmount = 0;
      state.contractInfo.nonstdRemainderAmount = 0;
      state.contractInfo.nonstdRemainderVatAmount = 0;
      state.contractInfo.nonstdTotalRemainderAmount = 0;
      state.contractInfo.collectionCond11 = '';
      state.contractInfo.signType	= 'ON';
      state.contractInfo.contractType = '';
      state.contractInfo.docCat1	= '';
      state.contractInfo.docNm	= '';
      state.contractInfo.docGb1	= '';
      state.contractInfo.docGb2	= '';
      state.contractInfo.docGb3	= '';
      state.contractInfo.histSn	= '';
      state.contractInfo.ctrtOrginlCn	= '';
      state.contractInfo.ctrtOrginlHashcd	= '';
      state.contractInfo.ctrtOrginlFileNo	= '';
      state.contractInfo.signDt	= '';
      state.contractInfo.retrnResnCn = '';
      state.contractInfo.contEmpId = '';
      state.contractInfo.contEmpNm = '';
      state.contractInfo.repntName = '';
      state.contractInfo.businessNo = '';
      state.contractInfo.beforeTradeName = '';
      state.contractInfo.beforeRepntName = '';
      state.contractInfo.beforeBusinessNo = '';
      state.contractInfo.beforeContDate = '';
      state.contractInfo.diffContDate = '';
      state.contractInfo.beforeTotalBiddingAmt = 0;
      state.contractInfo.diffTotalBiddingAmt = 0;
      state.contractInfo.beforeOespNameA = '';
      state.contractInfo.beforeBillRateA = 0;
      state.contractInfo.oespNameA = '';
      state.contractInfo.billRateA = 0;
      state.contractInfo.beforeOespNameB = '';
      state.contractInfo.beforeBillRateB = 0;
      state.contractInfo.oespNameB = '';
      state.contractInfo.billRateB = 0;
      state.contractInfo.beforeOespNameC = '';
      state.contractInfo.beforeBillRateC = 0;
      state.contractInfo.oespNameC = '';
      state.contractInfo.billRateC = 0;
      state.contractInfo.renewItems = '';
      state.contractInfo.renewReasonTrade = '';

      state.contractItemList = [];
      state.contractHistoryList = []; //20221202 ITSR62600
      state.safetyPackageList = [];
      state.maintPlanList = [];
      state.memberList = [];
      state.paymentList = [];
      state.stamptaxList = [];
      state.guaranteeList = [];
      state.etcDocList = [];

      state.modalStamptax.stmptaxSn	= 0;
      state.modalStamptax.ctrtNo	= '';
      state.modalStamptax.ctrtOdr	= '';
      state.modalStamptax.ctrtDivCd	= '';
      state.modalStamptax.saveMode	= '';
      state.modalStamptax.readonly	= false;

      state.modalGuarantee.grntySn	= 0;
      state.modalGuarantee.ctrtNo	= '';
      state.modalGuarantee.ctrtOdr	= '';
      state.modalGuarantee.ctrtDivCd	= '';
      state.modalGuarantee.saveMode	= '';
      state.modalGuarantee.readonly	= false;

      state.modalEtcDoc.etcdocsn	= 0;
      state.modalEtcDoc.ctrtNo	= '';
      state.modalEtcDoc.ctrtOdr	= '';
      state.modalEtcDoc.ctrtDivCd	= '';
      state.modalEtcDoc.saveMode	= '';
      state.modalEtcDoc.readonly	= false;
    }
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  
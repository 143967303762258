/* 회수 팝업 * /
<script>
import {defineComponent, getCurrentInstance, reactive, computed, watch} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: undefined,
    },
    contractKey: {
      type: Object,
      default: undefined,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const recoveryDialog = useDialog('recoveryDialog');

    const state = reactive({
      loading: false,

      ctrtNo: undefined,
      ctrtOdr: undefined,
      ctrtDivCd: undefined,
      approvalNo : undefined, // 품의번호 20230203
      reason: '', // 회수사유
    });

    const confirmMessage = computed(() => {
      switch (props.type) {
        case 'ne':
        case 'mod':
          return `대리인/계약일자 변경 시 ”계약진행 목록”에서 수정 후 재전송 바랍니다.<br /><br />
계약 주요 정보 변경 시 [반송/회수관리]에서 [${
            props.contractKey.ctrtDivCd === 'MOD_CT12' ? 'OKBS+' : 'OKESS'
          }전송]을 클릭하시고 ${
            props.contractKey.ctrtDivCd === 'MOD_CT12' ? 'OKBS+' : 'OKESS'
          }에서 진행하셔야 합니다.<br /><br />
회수 하시겠습니까?`;
        case 'fod':
          return `대리인/계약일자 변경 시 ”계약진행 목록”에서 수정 후 재전송 바랍니다.<br /><br />
계약 주요 정보 변경 시 [반송/회수관리]에서 [OKIS전송]을 클릭하시고 OKIS에서 진행하셔야 합니다.<br /><br />
          ${
            props.contractKey.approvalNo !== undefined && props.contractKey.approvalNo.trim() !== '' ? '품의번호 '+props.contractKey.approvalNo+'와 관련된 모든 계약을 회수 진행합니다.<br /><br />' : ''
          }

회수 하시겠습니까?`;
        case 'svc':
          return `대리인/계약일자 변경 시 ”계약진행 목록”에서 수정 후 재전송 바랍니다.<br /><br />
계약 주요 정보 변경 시 [반송/회수관리]에서 [OKSS전송]을 클릭하시고 OKSS에서 진행하셔야 합니다.<br /><br />
회수 하시겠습니까?`;
        case 'npp':
          return `대리인/계약일자 변경 시 ”계약진행 목록”에서 수정 후 재전송 바랍니다.<br /><br />
계약 주요 정보 변경 시 [반송/회수관리]에서 [OKBS+전송]을 클릭하시고 OKBS+에서 진행하셔야 합니다.<br /><br />
회수 하시겠습니까?`;
        case 'jsa':
          return ` 
계약 주요 정보 변경 시 [반송/회수관리]에서 [OKBS+전송]을 클릭하시고 OKBS+에서 진행하셔야 합니다.<br /><br />
회수 하시겠습니까?`;
        default:
          return '';
      }
    });

    watch(
      () => props.contractKey,
      newValue => {
        if (newValue === undefined) {
          return;
        }

        state.ctrtNo = props.contractKey.ctrtNo;
        state.ctrtOdr = props.contractKey.ctrtOdr;
        state.ctrtDivCd = props.contractKey.ctrtDivCd;
        state.approvalNo = props.contractKey.approvalNo; // 품의번호 20230203
      }
    );

    const method = {
      dialog_before_open() {
        state.reason = '';
      },

      async btnRecovery_click() {
        //console.log("props.contractKey.approvalNo",props.contractKey.approvalNo);
        if (!state.reason || state.reason.trim().length === 0) {
          await vm.$alert('회수사유를 입력해 주십시오.');
          return;
        }

        if (await vm.$confirm(confirmMessage.value)) {
          const data = {
            ctrtNo: state.ctrtNo,
            ctrtOdr: state.ctrtOdr,
            ctrtDivCd: state.ctrtDivCd,
            reason: state.reason,
            chngPgmId: undefined,
            approvalNo: state.approvalNo, // 품의번호 20230203
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            state.loading = true;
            //console.log("data",data);
            await axios.post('/contract/recovery', data);

            await vm.$alert('회수가 완료되었습니다.');

            recoveryDialog.hide(true);
          } catch (ex) {
            console.log(ex);
          } finally {
            state.loading = false;
          }
        }
      },
    };

    return {
      state,
      ...method,
      ...recoveryDialog.dialogRef,
      ...recoveryDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog ref="recoveryDialog" width="600px" height="350px" title="회수" @before-open="dialog_before_open">
    <template #button-center>
      <iui-button @click="btnRecovery_click">회수</iui-button>
    </template>

    <iui-content-box :loading="state.loading">
      <iui-container type="table">
        <i-row>
          <i-col-header>회수 사유</i-col-header>
          <i-col>
            <iui-textbox type="multi" v-model="state.reason" height="200px" />
          </i-col>
        </i-row>
      </iui-container>
    </iui-content-box>
  </iui-dialog>
</template>

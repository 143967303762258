<template>
  <div class v-show="visible">
    <span v-for="(item, index) in internalItems" v-bind:key="index" class="_rdoWrap">
      <input
        v-if="type === 'radio'"
        type="radio"
        v-model="vItem"
        :id="`${radioId}_${index}`"
        :name="name"
        :value="item[internalItemValueAlias]"
        @click="click"
        @change="change"
        :class="{_target_: true}"
        :disabled="!enable"
        :readonly="readonly"
      />
      <label
        v-if="item[internalItemTextAlias]"
        :id="`${labelId}_${index}`"
        :for="`${radioId}_${index}`"
        :class="{required: required, 'is-valid-fail': !isValid}"
      >
        <span></span>
        {{ item[internalItemTextAlias] ? item[internalItemTextAlias] : '' }}
      </label>
    </span>
  </div>
</template>

<script>
// import {setTimeout} from 'timers';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiItemsMixin from '@/components/Iui/mixins/IuiItemsMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  name: 'iui-radio-group-old',
  mixins: [IuiBaseMixin, IuiItemsMixin, IuiValidatorMixin],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    type: {
      type: String,
      default: 'radio',
    },
    label: {
      type: String,
    },
    formObj: {
      type: String,
      default: 'none!',
    },
    value: {
      type: [String, Number],
    },
    // bindArray: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   },
    // },
  },
  data: function() {
    return {
      vItem: this.value || '',
    };
  },
  computed: {
    radioId: {
      get() {
        return this.getId('radio');
      },
    },
    labelId: {
      get() {
        return this.getId('label');
      },
    },
  },
  watch: {
    vItem: function(item) {
      this.setBindArray(item);
      // this.setFormData(item);
    },
    value: function(value) {
      this.vItem = value;
    },
  },
  created() {
    if (this.itemKeyAlias === undefined && this.internalItemTextAlias === 'text') {
      this.internalItemTextAlias = 'label';
    }

    this.setBindArray();
    // this.vItem = [...new Set(this.items.map(item => {item.value}))];
    // this.vItem = [];

    // validator 설정
    this.validatorTargetDataProp = 'vItem';
    this.validationTriggerEventName = ['change'];
    // this.validator.customValidator = () => {
    //   return false;
    // };
  },
  mounted() {
    // this.setFormData();

    if (this.validator !== null) {
      this.validator.addCustomValidator(function() {
        const checkedCount = this.targetComponent.$el.querySelectorAll('input[type=radio]:checked').length;
        return checkedCount > 0;
      });
    }

    //속성 주입
    // let cnt = 0;
    // for (let k in this.$attrs) {
    //   for (let e of Array.from(this.$el.querySelectorAll('span._rdoWrap'))) {
    //     e.querySelector('input[type=radio]').setAttribute(k, this.$attrs[k]);
    //     // if (k == 'name' && e.querySelector('label')) {
    //     //   e.querySelector('input[type=radio]').setAttribute('id', this.$attrs[k] + '_' + cnt);
    //     //   e.querySelector('label').setAttribute('for', this.$attrs[k] + '_' + cnt);
    //     //   cnt++;
    //     // }
    //   }
    //   this.$el.querySelector('._target_').setAttribute(k, this.$attrs[k]);
    //   this.$el.removeAttribute(k);
    // }

    //이벤트 주입
    for (let e of this.event) {
      for (let el of Array.from(this.$el.querySelectorAll('span'))) {
        el.querySelector('input[type=radio]').addEventListener(e.name, e.callback);
      }
    }

    //스타일 주입
    // for (let k in this.css) {
    //   for (let e of Array.from(this.$el.querySelectorAll('span'))) {
    //     e.querySelector('input[type=radio]').style[k] = this.css[k];
    //   }
    // }

    // if (this.$el.querySelector('._target_') && this.$el.querySelector('._target_').hasOwnProperty('name')) {
    //   this.name = this.$el.querySelector('._target_').name;
    // }

    //기본요소 구분하기 위한 class명 삭제
    // this.$el.querySelector("._target_").classList.remove("_target_");
  },
  methods: {
    // setFormData(value) {
    //   const me = this;
    //   setTimeout(function() {
    //     me.$store.dispatch('setFormData', {
    //       formObj: me.formObj,
    //       key: me.name,
    //       value: value,
    //       element: me.$el.querySelector('._target_'),
    //     });
    //   }, 10);
    // },
    click: function(e) {
      this.$emit('click', e);
    },
    change: function(e) {
      this.$emit('change', e);
      this.$emit('update:value', e.target.value);
    },
    setBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
  },
};
</script>

<style scoped>
.required {
  border-bottom: 1px solid pink;
}

.is-valid-fail {
  border-bottom: 1px solid red;
}
</style>

<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col v-if="!identifyComplete">
        <div v-if="!fromIdFind" style="position:absolute;right:10px;top:55px;z-index:99999">
          <a
            href="#"
            onclick="javascript:openHelp('/m/help/help_pc_pwd.html')"
            style="border:1px solid #e0e0e0;background-color:#f0f0f0;padding:5px 10px 5px 10px;border-radius:5px;color:black !important;cursor:pointer;z-index:999"
            >도움말</a
          >
        </div>
        <pmis-content-box>
          <template v-slot:title>비밀번호찾기</template>
          <iui-container type="table">
            <colgroup>
              <col width="150px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>아이디</i-col-header>
              <i-col>
                <iui-text
                  name="searchId"
                  :value.sync="searchData.searchId"
                  @keydown.enter="onSearchUserInfo"
                  :readonly="doReadonly"
                ></iui-text>
              </i-col>
            </i-row>

            <i-row v-if="isDoSearch">
              <i-col-header>등록된 전화번호</i-col-header>
              <i-col>
                <!-- 데이터  -->
                <div v-if="maskTelNoData != ''">
                  <iui-select-old
                    style="width: 190px;"
                    id="resultTelNo"
                    name="resultTelNo"
                    :items="maskTelNoData"
                    defaultCd="S"
                  >
                  </iui-select-old>
                </div>
                <div v-else>등록된 휴대폰 번호가 존재하지 않습니다.</div>
              </i-col>
            </i-row>

            <i-row v-if="isDoSearch">
              <i-col-header>등록된 email정보</i-col-header>
              <i-col>
                <!-- 데이터  -->
                <div v-if="maskEmailData != ''">
                  <iui-select-old
                    style="width:190px;"
                    id="resultEmail"
                    name="resultEmail"
                    :items="maskEmailData"
                    defaultCd="S"
                  >
                  </iui-select-old>
                </div>
                <div v-else>등록된 이메일이 없습니다.</div>
              </i-col>
            </i-row>

            <i-row v-if="isDoSearch">
              <i-col-header>인증번호 발급/입력</i-col-header>
              <i-col>
                <div>
                  <iui-text
                    name="verifyNumber"
                    :value.sync="verifyNumber"
                    style="width: 120px; padding-right:10px;"
                  ></iui-text>
                </div>
                <iui-button @click="sendVerifyNumber">인증번호전송</iui-button>
                <span>* 인증번호전송 클릭 후 수신한 인증번호를 입력해주시기 바랍니다. <br /></span>
              </i-col>
            </i-row>
          </iui-container>

          <i-row v-if="!identifyComplete" style="justify-content: center; margin-top:20px;">
            <iui-button style="padding: 0 10px 0 10px;" v-if="!isDoSearch" @click="onSearchUserInfo"> 다음</iui-button>
            <iui-button style="padding: 0 10px 0 10px;" v-if="isDoSearch" @click="goBeforePage"> 이전</iui-button>
            <iui-button style="padding: 0 10px 0 10px;" v-if="isDoSearch" @click="doConfirm">확인</iui-button>
            <!-- 테스트용 버튼 -->
            <!-- <iui-button style="padding: 0 10px 0 10px;" v-if="isDoSearch"  @click="doTest">테스트계정생성</iui-button> -->
            <!-- <iui-button style="padding: 0 10px 0 10px;" v-if="isDoSearch"  @click="doConfirm2">값확인</iui-button> -->
          </i-row>

          <i-row v-if="isDoSearch && !identifyComplete" style="margin: 20px 0 0 0px;">
            <ul>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                * 선택한 전화번호 또는 이메일로 인증번호가 발송됩니다.<br />
              </li>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                * 전화번호와 이메일을 모두 선택시 전화번호로만 인증번호가 발송됩니다.
              </li>
            </ul>
          </i-row>
        </pmis-content-box>
      </i-col>
      <i-col v-if="identifyComplete">
        <ResetUserPw
          :userId="searchData.searchId"
          :identifyComplete="identifyComplete"
          :fromIdFind="searchData.fromIdFind"
        />
      </i-col>
    </i-row>
  </iui-container>
</template>
<script>
import ResetUserPw from '@/view/user/find/components/ResetUserPw.vue';

export default {
  props: {
    fromIdFind: Boolean, // ID찾기에서 넘어왔는지 체크
  },
  components: {
    ResetUserPw,
  },
  data() {
    return {
      searchData: {
        searchId: '',
        fromIdFind: false, // ID찾기에서 넘어왔는지 체크
      },
      resultData: [],
      resultTelNoData: [], // 검색결과 TelNo 배열
      resultEmailData: [], // 검색결과 email 배열
      maskTelNoData: [], // 검색결과 TelNo *** 처리 배열
      maskEmailData: [], // 검색결과 email *** 처리 배열

      verifyNumber: '',
      doReadonly: false, // ID 입력갑 readonly 처리
      isDoSearch: false, // 기본정보 조회 여부

      identifyComplete: false, // 본인인증 완료

      encodeRString: '', // 서버에서 받아온 encode password String 값
    };
  },
  mounted() {
    this.searchData.fromIdFind = this.fromIdFind ? this.fromIdFind : false;
  },
  methods: {
    // 정보 조회
    async onSearchUserInfo() {
      let telNoPattern = /^(01+[(0-9)])/; // 휴대폰번호 정규식 (01x 번호만 검색)

      //console.log("onSearchUserInfo() searchId  ? ",this.searchData.searchId );
      if (this.searchData.searchId == '') {
        this.$alert('검색하실 아이디를 입력해주세요.');
        return;
      }
      let searchData = this.searchData;
      const response = await axios.post('/userInfo/selectUserInfoList', searchData);
      // 검색정보 초기화
      this.resultTelNoData = [];
      this.resultEmailData = [];
      this.maskTelNoData = [];
      this.maskEmailData = [];

      this.resultData = response.data; // 검색 결과값 저장
      // eslint-disable-next-line
      this.resultData.forEach((element, index) => {
        var i = 0;
        // 전화번호 검사
        if (element.telNo) {
          /* eslint-disable */
          let chkTelNo = element.telNo
            .replace(/\ /gi, '')
            .replace(/\-/gi, '')
            .replace(/\(/gi, '')
            .replace(/\)/gi, '');
          /* eslint-enable */
          // 01X 로 시작하는 번호들만 추출(010- ~ 019-)
          if (telNoPattern.test(chkTelNo) && (chkTelNo.length == 10 || chkTelNo.length == 11)) {
            // chkTelNo masking
            let maskTelNo = chkTelNo.substring(0, chkTelNo.length - 3) + '***';

            if (this.resultTelNoData.length == 0) {
              /* eslint-disable */
              this.resultTelNoData.push({
                telNo: chkTelNo,
                userId: element.userId,
                userNm: element.repntName.replace(/\ /gi, ''),
              }); // *** 처리 전 항목 push
              /* eslint-enable */

              this.maskTelNoData.push({text: maskTelNo, value: this.resultTelNoData.length - 1}); // *** 처리된 항목 push
            } else if (this.resultTelNoData.length > 0) {
              let canPush = true; // 저장가능 여부 T/F(중복체크)
              for (i = 0; i < this.resultTelNoData.length; i++) {
                if (chkTelNo == this.resultTelNoData[i].telNo && element.userId == this.resultTelNoData[i].userId) {
                  canPush = false;
                  break;
                }
              }
              if (canPush) {
                /* eslint-disable */
                this.resultTelNoData.push({
                  telNo: chkTelNo,
                  userId: element.userId,
                  userNm: element.repntName.replace(/\ /gi, ''),
                }); // *** 처리 전 항목 push
                /* eslint-enable */
                this.maskTelNoData.push({text: maskTelNo, value: this.resultTelNoData.length - 1}); // *** 처리된 항목 push
              }
            }
          }
        }
        // 이메일 검사
        if (element.email) {
          let checkEmail = element.email.replace(/ /gi, '');
          let EmailPattern = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // 이메일 패턴
          // xxx@xx.xx 등으로 맵핑되는 항목들 추출
          if (EmailPattern.test(checkEmail)) {
            let maskEmail = '***' + element.email.substring(3, element.email.length);

            if (this.resultEmailData.length == 0) {
              this.resultEmailData.push({
                email: element.email,
                userId: element.userId,
                userNm: element.repntName.replace(/ /gi, ''),
              }); // *** 처리 전 항목 push
              this.maskEmailData.push({text: maskEmail, value: this.resultEmailData.length - 1}); // *** 처리된 항목 push
            } else if (this.resultEmailData.length > 0) {
              let canPush2 = true; // 저장가능 여부 T/F(중복체크)
              for (i = 0; i < this.resultEmailData.length; i++) {
                if (checkEmail == this.resultEmailData[i].email && element.userId == this.resultEmailData[i].userId) {
                  canPush2 = false;
                  break;
                }
              }
              if (canPush2) {
                this.resultEmailData.push({
                  email: element.email,
                  userId: element.userId,
                  userNm: element.repntName.replace(/ /gi, ''),
                }); // *** 처리 전 항목 push
                this.maskEmailData.push({text: maskEmail, value: this.resultEmailData.length - 1}); // *** 처리된 항목 push
              }
            }
          }
        }
      });

      this.doReadonly = true; // 입력한 id값 readonly처리
      this.isDoSearch = true;

      // selectBoxIndex 초기화시에 시점 차이 떄문에 setTimeOut 설정
      setTimeout(() => {
        this.setDefaultSelect();
      }, 100);
    },

    // 검색시 selectBoxIndex 초기화
    setDefaultSelect() {
      $('#resultTelNo_select').prop('selectedIndex', 0);
      $('#resultEmail_select').prop('selectedIndex', 0);
    },

    // 인증번호 전송
    async sendVerifyNumber() {
      console.log('===sendVerifyNumber===');

      let telNoSelect = $('#resultTelNo_select option:selected').val();
      let emailSelect = $('#resultEmail_select option:selected').val();

      // 선택 하지 않았을때 처리 (default value)
      telNoSelect = telNoSelect == '' ? -1 : telNoSelect;
      emailSelect = emailSelect == '' ? -1 : emailSelect;

      // 전화번호 or 전화번호,이메일 둘다 선택시에 전화번호쪽으로만 전송
      if (telNoSelect || emailSelect) {
        if (telNoSelect >= 0 || (telNoSelect >= 0 && emailSelect >= 0)) {
          this.$alert('선택하신 전화번호로 인증번호를 전송하였습니다.');

          //console.log("telNo :",this.resultTelNoData);
          //console.log("telNo :",this.resultTelNoData[telNoSelect].telNo); // 휴대폰번호 필요

          const response = await axios.post('/userInfo/sendPwFindPassNumber', {
            telNo: this.resultTelNoData[telNoSelect].telNo,
            email: '',
            findType: 'telNoFind',
          });
          this.encodeRString = response.data; // 서버 encodeing password 값 저장
        } else if (emailSelect >= 0) {
          this.$alert('선택하신 email로 인증번호를 전송하였습니다.');

          //console.log("email :",this.resultEmailData);
          //console.log("email :",this.resultEmailData[emailSelect].email); // 이메일 필요

          const response = await axios.post('/userInfo/sendPwFindPassNumber', {
            telNo: '',
            email: this.resultEmailData[emailSelect].email,
            findType: 'emailFind',
          });
          this.encodeRString = response.data; // 서버 encodeing password 값 저장
        } else {
          this.$alert('인증번호를 전송받을 번호 혹은 email을 선택해주세요.');
          return;
        }
      } else {
        this.$alert('인증번호를 발송할 전화번호 또는 email이 존재하지 않습니다.');
        return;
      }
    },
    // 확인 (인증번호 <-> 입력번호 비교)
    async doConfirm() {
      console.log(' encodeRString : ', this.encodeRString);
      const response = await axios.post('/userInfo/checkVerifyNumber', {
        verifyNumber: this.verifyNumber,
        encodeRString: this.encodeRString,
      });
      console.log('response :', response.data);
      if (response.data) {
        this.identifyComplete = true;
        this.$alert('인증이 완료 되었습니다. 비밀번호를 재설정 해주세요.');
      } else {
        this.$alert('인증번호가 일치하지 않습니다. 인증번호를 다시 확인해주세요.');
      }
    },
    // 이전페이지로 돌아가기
    goBeforePage() {
      // 입력, 검색값들 초기화
      this.searchData.searchId = '';
      this.encodeRString = '';

      this.resultTelNoData = [];
      this.resultEmailData = [];
      this.maskTelNoData = [];
      this.maskEmailData = [];

      this.doReadonly = false;
      this.isDoSearch = false;
    },
    // 테스트 - 값확인 (랜덤값 확인)
    // async doConfirm2(){
    //   const response = await axios.post('/userInfo/randomIntVal');
    //   console.log("response.data :",response.data);
    //   // 임시 해쉬값 저장
    //   this.encodeRString = response.data;
    // },

    // 테스트 - 거래선번호 로그인시 정보 존재 x -> DB추가
    // async doTest(){
    //   let tradeNo = this.searchData.searchId;
    //   alert("tradeNo:"+tradeNo);

    //   if (tradeNo == ""){
    //     console.log("값 x ");
    //     return ;
    //   }
    //   const response = await axios.post('/userInfo/checkLoginUserInfo', {tradeNo});
    //   console.log(response);
    // },
  },
};
</script>

<style></style>

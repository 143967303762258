/* 인지세 및 부속서류 TAB * /
<script>
import {reactive, watch, computed, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import StamptaxGuidePopup from '@/view/contract/components/popup/StamptaxGuide.vue'; // 인지세 안내
import StamptaxPopup from '@/view/common/components/StamptaxPopup.vue'; // 인지세 등록
import GuaranteePopup from '@/view/common/components/GuaranteePopup.vue'; // 보증보험 등록

const ibsheetOptions1 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ' ',
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
    },
    {
      Header: '순번',
      Name: 'stmptaxSn',
      Type: 'Int',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '기관명',
      Name: 'insttNm',
      Type: 'Text',
      Width: 220,
      Align: 'Left',
    },
    {
      Header: '납부일자',
      Name: 'stmptaxPayDe',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: '인지세액',
      Name: 'stmptaxAm',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
    },
    {
      Header: '접수번호',
      Name: 'rceptNo',
      Type: 'Text',
      Width: 160,
      Align: 'Center',
    },
    {
      Header: '사업자번호',
      Name: 'businessNo',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
    },
    {
      Header: '첨부파일',
      Name: 'atchFile',
      Type: 'Button',
      Width: 80,
      ButtonText: '첨부파일',
      ButtonWidth: 60,
      Align: 'Center',
    },
    {
      Header: '계약번호',
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '차수',
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '계약구분',
      Name: 'ctrtDivCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '담당자',
      Name: 'entryEmpId',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'RPA여부',
      Name: 'rpaYn',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '파일번호',
      Name: 'fileNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'pgmCd',
      Name: 'pgmCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'grpNo',
      Name: 'grpNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '파일명',
      Name: 'lclFlNm',
      Type: 'Text',
      Width: 140,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '사전납부',
      Name: 'stmptaxBeffatPayYn',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

const ibsheetOptions2 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ' ',
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
    },
    {
      Header: '순번',
      Name: 'grntySn',
      Type: 'Int',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '종류',
      Name: 'grntyKndNm',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: '보증기관',
      Name: 'issuInsttNm',
      Type: 'Text',
      Width: 120,
      Align: 'Left',
    },
    {
      Header: '보증시작일',
      Name: 'grntyBgnDe',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: '보증종료일',
      Name: 'grntyEndDe',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: '증권번호',
      Name: 'grntyNo',
      Type: 'Text',
      Width: 180,
      Align: 'Center',
    },
    {
      Header: '발행일자',
      Name: 'issuDe',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: '보증금액',
      Name: 'grntyAm',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
    },
    {
      Header: '첨부파일',
      Name: 'atchFile',
      Type: 'Button',
      Width: 80,
      ButtonText: '첨부파일',
      ButtonWidth: 60,
      Align: 'Center',
    },
    {
      Header: '계약번호',
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '차수',
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '계약구분',
      Name: 'ctrtDivCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '담당자',
      Name: 'entryEmpId',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'scdYn',
      Name: 'scdYn',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '파일번호',
      Name: 'fileNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'pgmCd',
      Name: 'pgmCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'grpNo',
      Name: 'grpNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '파일명',
      Name: 'lclFlNm',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
  ],
};

export default {
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {StamptaxGuidePopup, StamptaxPopup, GuaranteePopup},

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading1: false,
      loading2: false,

      grid1: undefined,
      gridOptions1: ibsheetOptions1,

      grid2: undefined,
      gridOptions2: ibsheetOptions2,

      list1: undefined,
      list2: undefined,

      stmptaxSn: undefined, // 인지세 순번
      grntySn: undefined, // 보증보험 순번

      stamptaxKey: undefined,
      guaranteeKey: undefined,

      isShowButton: computed(() => {
        //isContEmp : state => (state.contractInfo.contEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo),
        //return (this.userInfo.userType == 'U' && ($isAdmin() || this.isContEmp)) || this.userInfo.userType != 'U'; // TODO 확인
        return true;
      }),

      isOfflineComplete: computed(() => {
        // isOfflineComplete: state => (state.contractInfo.signType == "OFF"),
        return false; // TODO 확인
      }),

      // 인지세 등록
      canStamptax: computed(() => {
        if (props.contractKey == undefined) {
          return false;
        } else {
          return true;
        }
      }),

      // 보증보험 등록
      canGuarantee: computed(() => {
        if (props.contractKey == undefined) {
          return false;
        } else {
          return true;
        }
      }),
    });

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      onDataLoad() {
        state.loading1 = false;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);

        if (rowValue.rpaYn === 'N') {
          if (e.col === 'atchFile') {
            if (rowValue.fileNo !== undefined && rowValue.fileNo > 0 && rowValue.lclFlNm != '') {
              // 첨부파일 다운로드
              method.downloadFile(rowValue.pgmCd, rowValue.fileNo, rowValue.grpNo, rowValue.lclFlNm);
            }
          } else {
            // 인지세정보 팝업
            stamptaxPopupSetup.btnSaveStamptax_click(rowValue.stmptaxSn);
          }
        } else {
          vm.$alert('RPA로 발급받은 인지세정보는 상세정보를 조회할 수 없습니다.');
        }
      },
    };

    state.gridOptions2.Events = {
      ...state.gridOptions2.Events,
      onDataLoad() {
        state.loading2 = false;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);

        if (rowValue.scdYn === 'Y') {
          if (e.col === 'atchFile') {
            if (rowValue.fileNo !== undefined && rowValue.fileNo > 0 && rowValue.lclFlNm != '') {
              // 첨부파일 다운로드
              method.downloadFile(rowValue.pgmCd, rowValue.fileNo, rowValue.grpNo, rowValue.lclFlNm);
            }
          } else {
            // 보증정보 팝업
            guaranteePopupSetup.btnSaveGuarantee_click(rowValue.grntySn);
          }
        } else {
          vm.$alert('OKSS에서 입력한 보증서 정보는 상세정보를 조회할 수 없습니다.');
        }
      },
    };

    // 인지세 등록
    const stamptaxPopup = useDialog('stamptaxPopup');
    const stamptaxPopupSetup = {
      ...stamptaxPopup.dialogRef,

      async btnSaveStamptax_click(stmptaxSn) {
        if (!stmptaxSn) {
          state.stamptaxKey = props.contractKey;
        } else {
          state.stmptaxSn = stmptaxSn;
        }

        const result = await stamptaxPopup.show();

        state.stmptaxSn = undefined;
        state.stamptaxKey = undefined;

        if (result == true) {
          method.getData();
        }
      },
    };

    // 보증보험 등록
    const guaranteePopup = useDialog('guaranteePopup');
    const guaranteePopupSetup = {
      ...guaranteePopup.dialogRef,

      async btnSaveGuarantee_click(grntySn) {
        if (!grntySn) {
          state.guaranteeKey = props.contractKey;
        } else {
          state.grntySn = grntySn;
        }

        const result = await guaranteePopup.show();

        state.grntySn = undefined;
        state.guaranteeKey = undefined;

        if (result == true) {
          method.getData();
        }
      },
    };

    watch(
      () => props.contractKey,
      newValue => {
        // 초기화
        state.grid1.removeAll();
        state.grid2.removeAll();

        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.contractKey) {
        method.getData();
      }
    });

    const method = {
      async getData() {
        const param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          // 인지세
          state.loading1 = true;
          const response1 = await axios.post('/stamptax/selectStamptaxList', param);
          state.list1 = response1.data.stamptaxList;

          // 보증보험
          state.loading2 = true;
          const response2 = await axios.post('/guarantee/selectGuaranteeList', param);
          state.list2 = response2.data.guaranteeList;
        } catch (ex) {
          state.loading1 = false;
          state.loading2 = false;
          console.log(ex);
        } finally {
          //state.loading = false;
        }
      },

      // 첨부파일 다운로드
      async downloadFile(pgmCd, fileNo, grpNo, lclFlNm) {
        var url = '/file/download?pgmCd=' + pgmCd + '&flNo=' + fileNo + '&grpNo=' + grpNo;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, lclFlNm);
          } else {
            link.setAttribute('download', lclFlNm);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('다운로드 받을 파일이 존재하지 않습니다.');
        }
      },

      // 인지세 안내
      async btnGuideStamptax_click() {
        this.$modal.show('stamptaxGuideModal');
      },
    };

    return {
      state,
      ...method,
      ...stamptaxPopupSetup,
      ...guaranteePopupSetup,
    };
  },
};
</script>

<template>
  <iui-container type="css-flex">
    <i-row height="156px">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            인지세
          </template>
          <template #title-left>
            (전체 납부해야할 인지세 금액)<i-spacer />
            <iui-button @click="btnGuideStamptax_click">인지세 안내</iui-button>
          </template>
          <template #title-right>
            <!-- OTIS인지세는 CR팀에서 일괄 납부합니다. 고객 및 OSEP 인지세 발급 여부 확인 필요 -->
            <iui-button @click="btnSaveStamptax_click(undefined)" :enable="state.canStamptax" v-if="state.isShowButton"
              >인지세 등록</iui-button
            >
          </template>
          <iui-container
            type="ibsheet"
            :ibsheet.sync="state.grid1"
            :ibsheet-options="state.gridOptions1"
            :ibsheet-data="state.list1"
            :loading="state.loading1"
          />
          <iui-modal-old name="stamptaxGuideModal" :title="'인지세 안내'" width="600px" height="430px">
            <stamptax-guide-popup />
          </iui-modal-old>
        </iui-content-box>
      </i-col>
    </i-row>
    <i-row height="156px">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            보증보험
          </template>
          <template #title-left>OTIS담당자께서는 증권첨부는 Legacy 시스템에서 진행해주시기 바랍니다.</template>
          <template #title-right>
            <iui-button
              @click="btnSaveGuarantee_click(undefined)"
              :enable="state.canGuarantee"
              v-if="!state.isOfflineComplete && state.isShowButton"
              >보증보험 등록</iui-button
            >
          </template>
          <iui-container
            type="ibsheet"
            :ibsheet.sync="state.grid2"
            :ibsheet-options="state.gridOptions2"
            :ibsheet-data="state.list2"
            :loading="state.loading2"
          />
        </iui-content-box>
      </i-col>
    </i-row>
    <stamptax-popup ref="stamptaxPopup" :stmptax-sn="state.stmptaxSn" :contract-key="state.stamptaxKey" />
    <guarantee-popup ref="guaranteePopup" :grnty-sn="state.grntySn" :contract-key="state.guaranteeKey" />
  </iui-container>
</template>

<style></style>

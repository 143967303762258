<template>
  <pmis-content-box>
    <template v-if="identifyComplete" v-slot:title>비밀번호찾기2</template>
    <iui-container type="table">
      <colgroup>
        <col width="150px" />
        <col />
      </colgroup>
      <i-row>
        <i-col-header>아이디</i-col-header>
        <i-col>
          {{ this.searchData.searchId }}
        </i-col>
      </i-row>

      <i-row>
        <i-col-header>새 비밀번호</i-col-header>
        <i-col>
          <iui-text type="password" name="inputNewPw" :value.sync="inputNewPw" maxLength="16"></iui-text>
        </i-col>
      </i-row>

      <i-row>
        <i-col-header>새 비밀번호 확인</i-col-header>
        <i-col>
          <iui-text type="password" name="inputNewPwConfirm" :value.sync="inputNewPwConfirm" maxLength="16"></iui-text>
        </i-col>
      </i-row>
    </iui-container>
    <i-row style="margin: 20px 0 0 10px;">
      * 영문, 숫자 , 특수문자를 함께 사용하면 (8자 이상 16자 이하) 보다 안전합니다.<br />
      * 영문, 숫자를 함께 사용하면 (10자이상 16자 이하) 보다 안전합니다.<br />
    </i-row>
    <i-row style="justify-content: center; margin-top:20px;">
      <iui-button style="padding: 0 10px 0 10px;" @click="onChagneUserPw"> 변경완료</iui-button>
      <iui-button v-if="isLogin" style="padding: 0 10px 0 10px;" @click="changeNext">다음에변경하기</iui-button>
    </i-row>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    userId: String,
    identifyComplete: Boolean,
    fromIdFind: Boolean,
  },
  data() {
    return {
      searchData: {
        searchId: '',
        identifyComplete: false, // 컴포넌트 호출시에 template 두번 출력되는 현상 방지
        fromIdFind: false, // ID찾기에서 넘어왔는지 체크
      },

      inputNewPw: '', // 새 비밀번호
      inputNewPwConfirm: '', // 새 비밀번호 확인

      isLogin: false, // 로그인 여부
      isEngCase: false, // 영어 포함 여부
      isNumberCase: false, // 숫자 포함 여부
      isSpecialCase: false, // 특수문자 포함 여부
    };
  },
  mounted() {
    this.searchData.searchId = this.userId;
    this.searchData.identifyComplete = this.identifyComplete;
    this.searchData.fromIdFind = this.fromIdFind ? this.fromIdFind : false;
  },
  methods: {
    async onChagneUserPw() {
      /* ================ 비밀번호 validation===================
       * 1. 두 비밀번호 일치 확인
       * 2. 영문 / 숫자 혼합 여부 확인 (isEngCase && isNumberCase)
       * 3. 비밀번호 특수문자 여부 확인 (isSpecialCase)
       * 4. 10자~16자(특문 X) , 8자~ 16자(특문 O) 문자길이검사
       * ======================================================= */

      // 영문/숫자/특문 정규식
      var engExp = /[a-zA-z]/gi;
      var numberExp = /[0-9]/gi;
      var specialExp = /[{}[\]/?.,;:|)*~`!^\-_+┼<>@#$%&'"\\(=]/gi;

      if (!this.inputNewPw) {
        this.$alert('사용하실 새 비밀번호를 입력해주세요.');
        return;
      }
      if (!this.inputNewPwConfirm) {
        this.$alert('새 비밀번호 확인을 입력해주세요.');
        return;
      }
      if (this.inputNewPw != this.inputNewPwConfirm) {
        this.$alert('입력하신 비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해주세요.');
        return;
      }

      // 새비밀번호 <-> 새비밀번호확인 문자열이 일치하기 때문에 하나만 검사
      if (engExp.test(this.inputNewPw)) {
        this.isEngCase = true;
      }
      if (numberExp.test(this.inputNewPw)) {
        this.isNumberCase = true;
      }
      if (specialExp.test(this.inputNewPw)) {
        this.isSpecialCase = true;
      }

      if (!(this.isEngCase && this.isNumberCase)) {
        this.$alert('영어와 숫자를 포함하여 비밀번호를 입력해주세요.');
        return;
      }

      if (this.isSpecialCase) {
        if (this.inputNewPw.length < 8) {
          this.$alert('특수문자 포함시 비밀번호는 8자 이상 16자 이하로 입력해주세요.');
          return;
        }
      } else {
        if (this.inputNewPw.length < 10) {
          this.$alert('특수문자 미포함시 비밀번호는 10자 이상 16자 이하로 입력해주세요.');
          return;
        }
      }

      // param값 set
      let userId = this.searchData.searchId;
      let userPw = this.inputNewPw;

      const response = await axios.post('/userInfo/changePw', {userId, userPw});
      console.log(response);
      if (response.data > 0) {
        await this.$alert('비밀번호가 재설정되었습니다.');
        //열린 modal창 hide
        if (this.fromIdFind) {
          this.$modal.hide('idFind');
        } else {
          this.$modal.hide('pwFind');
        }
      } else {
        this.$alert('비밀번호 재설정에 실패하였습니다. 다시 시도해 주십시오.');
      }
    },
  },
};
</script>

<style></style>

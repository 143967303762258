<template>
  <v-dialog :clickToClose="false" >
	
  </v-dialog>
  
</template>

<script>
import Confirm from '@/plugins/confirm';
export default {
	data() {
		return {
			msg: '',
			name: 'dialog',
		};
	},
	created() { 
		Confirm.event.$on('show', this.handleShowEvent);
		Confirm.event.$on('show2', this.handleShowEvent2);
	},
	methods: {
		handleShowEvent(msg, resolve, reject, confirmCallback, cancelCallback) {
			this.msg = msg;
			this.show(msg, resolve, reject, confirmCallback, cancelCallback);
		},

		show(msg, resolve, reject, confirmCallback, cancelCallback) {
			if (!confirmCallback) confirmCallback = () => {};
			if (!cancelCallback) cancelCallback = () => {};
			this.$modal.show(this.name, {
				title: 'Confirm!',
				text: msg,
				buttons: [
					{
						title: '확인',
						handler: () => {
							try {
								setTimeout(() => {
									confirmCallback();
								}, 50);

								resolve(true);
							} catch (error) {
								reject(error);
							}

							this.$modal.hide(this.name);
						},
					},
					{
						title: '취소',
						handler: () => {
							try {
								setTimeout(() => {
									cancelCallback();
								}, 50);
								resolve(false);
							} catch (error) {
								reject(error);
							}

							this.$modal.hide(this.name);
						},
					},
				],
			});
		},
		handleShowEvent2(msg, resolve, reject, confirmCallback, cancelCallback) {
			this.msg = msg;
			this.show2(msg, resolve, reject, confirmCallback, cancelCallback);
		},
		show2(msg, resolve, reject, confirmCallback, cancelCallback) {
			if (!confirmCallback) confirmCallback = () => {};
			if (!cancelCallback) cancelCallback = () => {};
			this.$modal.show(this.name, {
				title: 'Confirm!',
				text: msg,
				buttons: [
					{
						title: '확인',
						handler: () => {
							try {
								var obj_length = document.getElementsByName("isCustNotCheck").length;
								var ischeck = false;
								for (var i=0; i<obj_length; i++) {
									if (document.getElementsByName("isCustNotCheck")[i].checked == true) {
										ischeck = true;
									}
								}
								setTimeout(() => {
									confirmCallback(ischeck);
								}, 50);

								resolve(true);
							} catch (error) {
								reject(error);
							}

							this.$modal.hide(this.name);
						},
					},
					{
						title: '취소',
						handler: () => {
							try {								
								setTimeout(() => {
									cancelCallback();
								}, 50);
								resolve(false);
							} catch (error) {
								reject(error);
							}
							this.$modal.hide(this.name);							
						},
					},
				],
			})
		},
		hide() {
			this.$modal.hide(this.name);
		},		
	},
};
</script>

<style></style>

<template>
  <pmis-page :searchProjectVisible="false" @search-click="onSearch" @save-click="onSave">
    <iui-container type="css-flex">
      <i-row>
        <i-col width="550px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <cmpList />
          </pmis-tab-box>
        </i-col>
        <i-col>
          <iui-tab-old :comps="tabList" :keepAlive="true"></iui-tab-old>
          <!-- <pmis-tab-box :loading="loading">
            <template v-slot:title>상세정보</template>
            <member />
          </pmis-tab-box>-->
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import cmpList from '@/view/sysManage/userManage/member/components/cmpList';
import store from '@/view/sysManage/userManage/member/store/member.js';
export default {
  components: {
    cmpList,
  },

  data() {
    return {
      loading: false,
      tabList: [
        {
          tabNm: '상세정보',
          compNm: 'member',
          path: '@/view/sysManage/userManage/member/join',
        },
        {
          tabNm: '기초정보',
          compNm: 'baseInfo',
          path: '@/view/sysManage/userManage/member/components/BaseInfo',
        },
      ],
    };
  },

  beforeCreate() {
    if (!this.$store.getters.getStoreNm) {
      this.$store.commit('setCurrentUrl', {path: 'member'});
    }

    $init(this, store);
    $mapGetters(this, ['companyInfo']);
  },

  created() {
    this.addEvent([{name: 'companyRegist_onDetailLoading', func: this.onDetailLoading}]);
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'cmpList_selectCompanyNotConfirmList'});
    },

    onSave() {
      this.$confirm('저장하시겠습니까?').then(() => {
        let param = {
          pgmCd: this.companyInfo.pgmCd,
          useStrdate: this.companyInfo.useStrdate,
          useEnddate: this.companyInfo.useEnddate,
          cud: 2,
        };
        axios.post('/companyRegist/saveCompanyInfo', param);
      });
    },

    onDetailLoading(isLoading) {
      this.loading = isLoading;
    },
  },
};
</script>

<style scoped></style>

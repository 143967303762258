<template>
	<div style="width:100%; height:100%" id="iOzViewer">

		<iframe :src="urlParam" id="ozFrame" name="ozFrame" 
				style="width:100%; height:100%; border:0px solid red;" 
				scrolling="no"
				title="Iframe Example"></iframe>
		<div class="btn_area" id='aa'>
			<button type="button" class="btn_default" @click="onReloadOzViewer">Reload</button>
			<button type="button" class="btn_default" @click="onReBindOzViewer">ReBind</button>
		</div> 	
	</div>

</template>

<!--template>
	<div class="btn_area" id='aa'>
		<button type="button" class="btn_default" @click="onReloadOzViewer">Reload</button>
	</div> 
</template-->


<script>
export default { 
	props: {
		OZParam: {
			type: Object,
		}
	},
	beforeCreate() {
		//console.log('@beforeCreate~');
	},
	created() {
		//console.log('@created~');
		console.log('OZParam >>>', this.OZParam);
		
		this.addEvent({name: 'eFormViewerPop_popConfirm', func: this.onPopConfirm});


		var me = this;

		// var f = function(e){
		// 	console.log('hi~~~~~~');
		// 	window.removeEventListener("message", f, false);
		// }
		// window.addEventListener('message', function(e) {
		// 	if (e.data.msg == 'requestOzParam') {
		// 		//me.callOzParam(e.data);

		// 		console.log('PP:::addEventListener:::::::::', me.OZParam);

		// 		document.getElementById('ozFrame').contentWindow.receiveOzParam( me.OZParam );
				
		// 		window.removeEventListener("message", f, false);
		// 		//console.log('addEventListener:::::::::', e.data);
		// 		//document.getElementById('ozFrame').contentWindow.postMessage({msg: 'receiveMessage', ozdata: JSON.stringify(this.OZParam) }, '*');
		// 	}
		// },false);

		//OZ Viewer 에서 message 를 호출.
		window.addEventListener('message', me.reqSetOzParam, false);

		// window.transferParam = (e) => {
		// 	document.getElementById('ozFrame').contentWindow.receiveOzParam( me.OZParam );
		// }

	},
	data() {
		return {
			//pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
			urlParam : `${process.env.VUE_APP_OZ_VIEWER_URL}`
			//urlParam : '/valid_chk.html'
			//urlParam : `${process.env.VUE_APP_BASE_URL}/oz/OzViewer.do`
		};
	},
	//async mounted() {
	mounted() {
		console.log('@mounted~');

		//frame 전달
		//document.getElementById('ozFrame').contentWindow.postMessage({ message: JSON.stringify(this.OZParam) }, '*');//target IP
		//document.getElementById('ozFrame').contentWindow.postMessage({msg: 'receiveMessage', ozdata: this.OZParam}, '*');
		//document.getElementById('ozFrame').source.postMessage({msg: 'receiveMessage', ozdata: this.OZParam}, '*');

		//parent.
		//window.parent.postMessage({ message: this.state.message }, '*');
		//iframe
		// window.addEventListener("message", function(e) {
        // 	document.getElementById("내가 지정할 요소 아이디").innerText =
        //   	e.data.vreviewxProductReviewCount;
		// });		

	},
	methods: {
		reqSetOzParam(e){
			if (e.data.msg == 'requestOzParam') {
				console.log('Parent:::reqSetOzParam:::', e.data);
				console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);
				
				//console.log('--->', document.getElementById('ozFrame').contentWindow);


				//document.getElementById('ozFrame').receiveOzParam( this.OZParam );
				// setTimeout(() => {
				
				//OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
				document.getElementById('ozFrame').contentWindow.receiveOzParam( this.OZParam );

				//Event Listener 를 제거.
				// //document.getElementById('ozFrame').contentWindow.postMessage({msg: 'receiveMessage', ozdata: this.OZParam}, '*');
				window.removeEventListener("message", this.reqSetOzParam, false);
				// }, 3000);

			}
		},
		onPopConfirm(param) {
			
			//OZ Viewer 의 입력값들를 리턴받음.
			var ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();

			//var ozVal = document.getElementById('ozFrame').contentWindow.aa();

			console.log( 'input_value>>>>>>', ozVal);
			//console.log( 'input_value>>>>>>sgnCustomer=',ozVal.sgnCustomer );
			//console.log( 'input_value>>>>>>imgIdCard=',ozVal.imgIdCard );
			//console.log( 'input_value>>>>>>rdoAgreeYn=',ozVal.rdoAgreeYn );
			//console.log( 'input_value>>>>>>txtBigo=',ozVal.txtBigo );
			if(ozVal != undefined && ozVal !== null){
				param( ozVal );
			}else{
				return;
			}
			
		},
		onReloadOzViewer(){
			let me = this;

			console.log("reload==",this.OZParam);

			let tmpOZFormParam = {
				signable : 'N'
				, signMthCd : '30'
				, signerSeCd : this.OZParam.OZFormParam.signerSeCd
				, tradeNo : this.OZParam.OZFormParam.tradeNo
			}
			//console.log("reload tmpOZFormParam1==", tmpOZFormParam);			

			let param = { 'checkContNo': this.OZParam.ctrt.checkContNo  //필수(*) 
						, 'checkSeq' : this.OZParam.ctrt.checkSeq       //필수(*)
						, 'manageCd' : '020'       //필수(*)
			};
			axios.post('/ozrpt/selectOzOContract', param).then((response) => {
				console.log('조회결과 response.data.jsonData >>>', response.data.jsonData);
				console.log('조회결과 response.data.reportPathName >>>', response.data.reportPathName);

				this.OZParam = Object.assign({}, response.data.jsonData);
				this.OZParam.reportname = response.data.reportPathName; //필수(*)리포트경로

				/**** 오즈파라미터설정  ******/
				this.OZParam.OZFormParam = {
					signable : 'N'   /* 조회용으로처리 */
					, signerSeCd : tmpOZFormParam.signerSeCd
					, tradeNo : tmpOZFormParam.tradeNo
				};

				//OZ Viewer 에서 message 를 호출할 Event 등록.
				window.addEventListener('message', me.reqSetOzParam, false);

				//OZ Reload
				document.getElementById('ozFrame').contentWindow.location.reload(true);

			}).catch(function (error) {
				console.log('========error====================');
				console.log(error);
			});			
		},
		// 21.03.09 작성.
		//오즈리포트 리바인딩 처리함수
		onReBindOzViewer(){
			/**** 오즈파라미터설정  ******/
			this.OZParam.OZFormParam = {
				signable : 'Y'    /*X:도장이미지 미출력, Y:서명, N:조회용으로처리 */
				, signMthCd : '30'  
				, signerSeCd : '10'
				, tradeNo : this.OZParam.ctrt.tradeNo
			};

			//OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
			document.getElementById('ozFrame').contentWindow.setOzReBindData( this.OZParam );
		}
	},
};

</script>

<style scoped></style>

window.$printExcel=function(menuId){
  var jqid = 'sheetExcel' + menuId;
  var jqobj = document.getElementById(jqid);
  //alert(jqid+"/"+jqobj);
  if(!jqobj) return;
  var gridObj = $(jqobj);
  var filename = "data.xls";
  var colModel = gridObj.jqGrid('getGridParam', 'colModel');
  var colNames = gridObj.jqGrid('getGridParam', 'colNames');
  var rowData = gridObj.jqGrid("getRowData");

  var groupHeader = gridObj.jqGrid('getGridParam', 'groupHeader');
  // 컬럼명이 colNames 에 정의되는 경우도 있어서 처리
  for (var i = 0; i < Math.min(colNames.length, colModel.length); i++) {
    /*lgksja 20210825 Elite 정보추가 S~*/
    if(colModel[i].excelView !=null && colModel[i].excelView == true){  //hidden true 일경우 excel true 이면 엑셀다운로드 일때 보이게
      colModel[i].hidden = false;
    }
    /*lgksja 20210825 Elite 정보추가 E~*/
    if (!colModel[i].label || colModel[i].label == '') {
    colModel[i].label = colNames[i];
    }
    // 필요없는 항목 제거
    if (colModel[i].excludeExcel) {
    var colName = colModel[i].name;
    for (var j = 0; j < rowData.length; j++) {
      rowData[j][colName] = "";
    }
    }
  }
  var data = {
    "colModel": colModel,
    "data": rowData
  };

  if (groupHeader != null && groupHeader.groupHeaders)
    data["groupHeaders"] = groupHeader.groupHeaders;
  var dataString = JSON.stringify(data);
  console.log("Excel donwload 시작");
  var fm = document.forms['excelExportForm'];
  fm.action = process.env.VUE_APP_BASE_URL + "/common/excel/excel_export.do";
  fm.subject.value = filename ? filename : "";
  fm.data.value = dataString;
  fm.submit();
}
<script>
import {defineComponent, reactive, computed, watch, toRef, getCurrentInstance} from '@vue/composition-api';
import gridOpt from './lowerCodeOpt.js';

export default defineComponent({
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    dataChanged: {
      type: Boolean,
      default: false,
    },
    selectedRowData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    // const instance = getCurrentInstance();

    const mainRef = toRef(props, 'selectedRowData');

    const state = reactive({
      mainRowData: mainRef,
      loading: false,
      grid: undefined,
      gridOpt: gridOpt,

      searchKey: {
        cmnUCd: undefined,
        cmnDNm: undefined,
        pgmCd: context.parent.userInfo.pgmCd,
      },
      list: undefined,
      // selectedRow: undefined,
    });

    // 조회 호출 계약서정보 row change(main)
    watch(
      () => props.selectedRowData,
      (newValue, oldValue) => {
        console.log('watch:hist:', props.selectedRowData, newValue, oldValue);

        if (newValue === oldValue) return;

        // state.searchKey.pgmCd = props.selectedRowData.pgmCd;
        state.searchKey.cmnUCd = props.selectedRowData.cmnDCd;

        if (state.searchKey.cmnUCd) {
          method.search_click();
        } else {
          state.grid.removeAll();
        }
      }
    );

    state.gridOpt.Events = {
      ...state.gridOpt.Events,
      onDataLoad(e) {
        console.log('Hist:grid', 'onDataLoad', e);

        // 로딩 상태 완료로 변경
        state.loading = false;

        // search 완료 이벤트 호출 -> 상위 페이지의 search 상태 변경
        context.emit('update:search', false);
      },
      // async onBeforeFocus(e) {
      //   console.log('onBeforeFocus', e);

      //   // confirm 사용(async/await) 시 ibsheet 미지원으로 무조건 focus를 주지 않도록 동작하기 때문에
      //   // 강제로 focus 처리
      //   // true | false가 리턴되는 것이 아닌 promise가 리턴되어 ibsheet에서는 focus를 주지 않는 것으로 동작

      //   // if (e.row !== e.orow) {
      //   //   if (props.dataChanged) {
      //   //     const b = await instance.proxy.$confirm('데이터가 수정되었습니다.<br />무시하고 조회하시겠습니까?');

      //   //     if (!b) {
      //   //       e.sheet.focus({row: e.orow, col: e.ocol, ignoreEvent: true});
      //   //       return;
      //   //     }
      //   //   }

      //   e.sheet.focus({row: e.row, col: e.col, ignoreEvent: true});

      //   //state.selectedRowData = e.row;

      //   // selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
      //   //context.emit('update:selectedRowData', e.row);
      //   // }
      // },
      onClick(e) {
        // console.log('grid', 'onClick', e);
      },
      onBeforeRowDelete(e) {
        // console.log('grid', 'onClick', e);
      },
      async onAfterChange(e) {
        // console.log('Hist:onAfterChange', e);
        context.emit('update:data-changed', true);
      },
    };

    // const selectedDataKey = computed(() => {
    //   // 선택된 row 값 중 key 값만 리턴
    //   if (!state.selectedRow) {
    //     return undefined;
    //   }

    //   return {
    //     rptNm: state.selectedRow,
    //   };
    // });

    // const refs = {
    //   searchBox: ref(null),
    // };
    const method = {
      async search_click() {
        // if (props.dataChanged) {
        //   const b = await instance.proxy.$confirm('데이터가 수정되었습니다.<br />무시하고 조회하시겠습니까?');

        //   if (!b) {
        //     e.sheet.focus({row: e.orow, col: e.ocol, ignoreEvent: true});
        //     return;
        //   }
        // }

        if (!state.searchKey.cmnUCd) return;

        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
        state.selectedRowData = undefined;
        //context.emit('update:selectedDataKey', selectedDataKey.value);
        //state.grid.removeAll();

        try {
          const response = await axios.post('/baseCmmnCodeRegist/selectCodeList', state.searchKey);
          // console.log('response', response);
          state.list = response.data;

          state.loading = false;
          context.emit('update:data-changed', false);
        } catch (ex) {
          console.log('========error====================');
          console.log(ex);
        }
      },
      async new_click(e) {
        //console.log('new_click', state.grid.getSaveJson(0).data);
        let nextId = state.grid.getTotalRowCount() + 1;
        let addRow = state.grid.addRow({
          init: {cmnUCd: state.searchKey.cmnUCd, useDs: 'Y', pgmCd: state.searchKey.pgmCd, cdSqn: nextId},
        });

        //console.log('addRow', addRow);
      },
      async save_click(e) {
        // 유효성 체크
        if (!state.grid.hasChangedData()) {
          this.$alert(`수정된 내용이 없습니다.`);
          return;
        }

        for (let row of state.grid.getRowsByStatus('Added,Changed,!Deleted')) {
          if (String(row.cmnDCd).trim() == '') {
            this.$alert('코드는 필수입력 항목입니다.');
            state.grid.focus(row, 'applcYn');
            return;
          }
          if (String(row.cmnDCd).trim() == '') {
            this.$alert('코드명은 필수입력 항목입니다.');
            state.grid.focus(row, 'cmnDCd');
            return;
          }
          if (String(row.cdSqn).trim() == '') {
            this.$alert('정렬순서는 필수입력 항목입니다.');
            state.grid.focus(row, 'cdSqn');
            return;
          }
        }

        if (await this.$confirm('저장하시겠습니까?')) {
          try {
            state.loading = true;
            const response = await axios.post('/baseCmmnCodeRegist/save', state.grid.getSaveJson().data);
            if (response.status == 200) {
              method.search_click(e);
            } else {
              this.$alert('저장을 실패했습니다.');
            }
          } catch (ex) {
            console.log('========error====================');
            console.log(ex);
          }
        }
      },
      async delete_click(e) {
        // console.log('delete_click', e);

        let checkedRows = state.grid.getRowsByChecked('cbx');

        if (0 == checkedRows.length) {
          this.$alert('선택된 항목이 없습니다.');
          return;
        }
        checkedRows.forEach(row => {
          if (!row.Added) {
            state.grid.deleteRow(row);
          } else {
            state.grid.removeRow(row);
          }
        });
      },
    };

    return {
      state,
      // selectedDataKey,
      // ...refs,
      ...method,
    };
  },
});
</script>
<style scoped></style>

<template>
  <pmis-tab-box>
    <template #title>하위코드</template>
    <template #title-right>
      <iui-button @click="new_click" v-show="state.searchKey.cmnUCd">신규</iui-button>
      <iui-button @click="save_click" v-show="state.searchKey.cmnUCd">저장</iui-button>
      <iui-button @click="delete_click" v-show="state.searchKey.cmnUCd">삭제</iui-button>
    </template>
    <iui-content-box>
      <template #header-left>
        <!-- <iui-textbox placeholder="검색어" label="코드명" v-model="state.searchKey.cmnDNm" @enter="search_click" /> -->
        <iui-text
          type="text"
          name="cmnDNm"
          label="코드명"
          placeholder="검색어"
          :value.sync="state.searchKey.cmnDNm"
          @enter="search_click"
        ></iui-text>
      </template>
      <template #header-right>
        <iui-button value="검색" class="btn" @click="search_click" v-show="state.searchKey.cmnUCd" />
      </template>

      <iui-container
        type="ibsheet"
        width="100%"
        :loading="state.loading"
        :ibsheet.sync="state.grid"
        :ibsheet-options="state.gridOpt"
        :ibsheet-data="state.list"
      />
    </iui-content-box>
  </pmis-tab-box>
</template>

<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <div style="position:absolute;right:10px;top:55px;z-index:99999">
          <a
            href="#"
            onclick="javascript:openHelp('/m/help/help_pc_id.html')"
            style="border:1px solid #e0e0e0;background-color:#f0f0f0;padding:5px 10px 5px 10px;border-radius:5px;color:black !important;cursor:pointer;z-index:999"
            >도움말</a
          >
        </div>

        <pmis-content-box>
          <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import LawUserIdFind from '@/view/user/find/components/LawUserIdFind.vue';
import PrsnUserIdFind from '@/view/user/find/components/PrsnUserIdFind.vue';
export default {
  components: {
    // eslint-disable-next-line
    LawUserIdFind,
    // eslint-disable-next-line
    PrsnUserIdFind,
  },
  data() {
    return {
      tabComps: [
        {
          tabNm: '법인사용자',
          compNm: 'RawUserIdFind',
          path: '@/view/user/find/components/LawUserIdFind.vue',
        },
        {
          tabNm: '개인사업자/개인',
          compNm: 'PrsnUserIdFind',
          path: '@/view/user/find/components/PrsnUserIdFind.vue',
        },
      ],
      tabIndex: 0,
    };
  },
  methods: {
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      //this.onCallEvent();
    },
  },
};
</script>

<style></style>

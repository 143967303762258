<!-- 
	https://vue-multiselect.js.org/
 -->
<template>
  <div v-show="visible" :class="rootClass" :style="rootStyle">
    <multiselect
      v-if="multiple"
      ref="vueMultiselect"
      label="text"
      track-by="value"
      v-model="internalValue"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="internalPlaceHolder"
      :selectLabel="selectLabel"
      :selectedLabel="selectedLabel"
      :deselectLabel="deselectLabel"
      :disabled="!enable"
      :searchable="searchable"
      @input="onInput"
    >
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span
          v-if="values.length && !isOpen"
          style="font-size: 12px"
        >{{ values[0].text }} {{values.length > 1 ? `외 ${values.length - 1}개` : ''}}</span>
      </template>
      <template v-slot:option="{option}">
        <span style="font-size: 12px">{{ option.text }}</span>
      </template>
      <template slot="noResult">
        <span>{{internalNoResultMessage}}</span>
      </template>
    </multiselect>

    <Multiselect
      v-else
      ref="vueMultiselect"
      label="text"
      track-by="value"
      v-model="internalValue"
      :name="name"
      :options="options"
      :placeholder="internalPlaceHolder"
      :selectLabel="selectLabel"
      :deselectLabel="deselectLabel"
      :disabled="!enable"
      :searchable="searchable"
      @select="onSelect"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <span style="font-size: 12px">{{ option.text }}</span>
      </template>
      <template v-slot:option="{option}">
        <span style="font-size: 12px">{{ option.text }}</span>
      </template>
      <template slot="noResult">
        <span>{{internalNoResultMessage}}</span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiValidatorMixin],
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    value: [String, Object, Number, Array],
    options: {
      type: Array,
      default: () => [],
    },
    name: String,
    selectLabel: {
      type: String,
      default: '',
    },
    selectedLabel: {
      type: String,
      default: '',
    },
    deselectLabel: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(v) {
      this.internalValue = v;
    },
  },
  data() {
    return {
      internalValue: undefined,
      internalPlaceHolder: '선택',
      internalNoResultMessage: '검색된 내용이 존재하지 않습니다.',
    };
  },
  created() {
    // if (this.defaultCd && this.options && this.options[0].value) {
    //   this.options.unshift({text: this.defaultCd == 'S' ? '선택' : '전체', value: ''});
    // }

    // validator 설정
    this.validatorTargetDataProp = 'internalValue';
    this.validationTriggerEventName = ['change'];

    this.$on('validated', e => {
      const multiselect = this.$refs.vueMultiselect;
      const tags = multiselect.$el.querySelector('div.multiselect__tags');

      tags.classList.toggle('is-valid-fail', !e.isValid);
    });
  },
  mounted() {
    const multiselect = this.$refs.vueMultiselect;
    const tags = multiselect.$el.querySelector('div.multiselect__tags');

    console.log('>> multiselect.$el', multiselect.$el);
    console.log('>> multiselect__select', multiselect.$el.querySelector('.multiselect__select'));
    console.log('>> multiselect__tags', multiselect.$el.querySelector('.multiselect__tags'));

    multiselect.$el.style.height = '26px';
    multiselect.$el.style.minHeight = '26px';
    multiselect.$el.querySelector('.multiselect__select').style.width = '30px';
    multiselect.$el.querySelector('.multiselect__select').style.height = '26px';
    multiselect.$el.querySelector('.multiselect__tags').style.height = '26px';
    multiselect.$el.querySelector('.multiselect__tags').style.minHeight = '26px';
    // multiselect.$el.querySelector('.multiselect__tags').style.padding = '2px 40px 0 8px';
    multiselect.$el.querySelector('.multiselect__tags').style.border = '1px solid #ccc';
    multiselect.$el.querySelector('.multiselect__tags').style.borderRadius = '3px';

    // multiselect.$el.querySelector('.multiselect__tags input').style.border = 'none';

    // multiselect__input
    // multiselect.$el.querySelector('.multiselect__tags').querySelector("input[type='text']").style.border = '';

    if (this.required) {
      tags.classList.add('required');
      tags.setAttribute(this.$options._scopeId, '');
    }
  },
  methods: {
    onInput(items) {
      let selectedArray = [];
      items.forEach(item => selectedArray.push({text: item.text, value: item.value}));
      this.$emit('change', selectedArray);
    },
    onSelect(item) {
      this.$emit('change', {text: item.text, value: item.value});
    },
  },
  computed: {
    rootClass: {
      get() {
        let obj = {
          'iui-combobox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }
        return obj;
      },
    },
  },
};
</script>

<style scoped>
/* .multiselect__single {
  font-size: 12px !important;
}
.multiselect__element > span > span {
  font-size: 12px !important;
} */

/* .multiselect__select {
  height: 26px !important;
}
.multiselect__tags {
  height: 26px !important;
  min-height: 26px !important;
} */

.required {
  background-color: pink !important;
}
.is-valid-fail {
  border-color: red;
}
</style>

/* FOD > 공동수급 구성원 변경 통보서 > 상세 TAB */
<script>
import {reactive, watch, onMounted, computed, getCurrentInstance} from '@vue/composition-api';
import HistoryList from '@/view/common/components/HistoryList.vue';

const ibsheetOptions1 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: '구분',
      Name: 'tradeTypeNm',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
    },
    {
      Header: '사업자번호',
      Name: 'businessNo',
      Type: 'Text',
      Width: 110,
      Align: 'Center',
    },
    {
      Header: '상호(법인) / 이름(개인/개인사업자)',
      Name: 'tradeName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: '대표자',
      Name: 'repntName',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: '서명/반송',
      Name: 'signOrReject',
      Type: 'Text',
      Width: 90,
      Align: 'Center',
    },
    {
      Header: '서명일시',
      Name: 'signDt',
      Type: 'Text',
      Width: 140,
      Align: 'Left',
    },
    {
      Header: '서명 방법',
      Name: 'signMthNm',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
    },
    {
      Header: '서명 IP',
      Name: 'accesIp',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: '업체번호',
      Name: 'tradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'urlLink',
      Name: 'urlLink',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    
  ],
};

const ibsheetOptions2 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: '구분',
      Name: 'tradeTypeNm',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
    },
    {
      Header: '사업자번호',
      Name: 'businessNo',
      Type: 'Text',
      Width: 110,
      Align: 'Center',
    },
    {
      Header: '상호(법인) / 이름(개인/개인사업자)',
      Name: 'tradeName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: '대표자',
      Name: 'repntName',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: '서명/반송',
      Name: 'signOrReject',
      Type: 'Text',
      Width: 90,
      Align: 'Center',
    },
    {
      Header: '서명일시',
      Name: 'signDt',
      Type: 'Text',
      Width: 140,
      Align: 'Left',
    },
    {
      Header: '서명 방법',
      Name: 'signMthNm',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
    },
    {
      Header: '서명 IP',
      Name: 'accesIp',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: '업체번호',
      Name: 'tradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'urlLink',
      Name: 'urlLink',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

export default {
  components: {HistoryList},
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy; 
    const state = reactive({
      loading: false,

      grid1: undefined,
      gridOptions1: ibsheetOptions1,

      grid2: undefined,
      gridOptions2: ibsheetOptions2,

      //selectedRow1: undefined,
      //selectedRow2: undefined,
      // 계약 대상자
      consortiumInfo: {},     

      //개별 알림재송부  lgksja 20230111
      isCanResendNoti : computed(() => {

        
        return  (
          console.log("props.contractKey.ctrtSttusCd",props.contractKey),
          $isAdmin() && props.otisPage 
         // && (props.contractKey.ctrtSttusCd =='10' || props.contractKey.ctrtSttusCd =='20')
          && (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1) 
        )
      }),
      isCanUrlCopy : computed(() => {
        
        return  (
          //console.log("state.memberInfo.urlLink",state.memberInfo.urlLink),
          $isAdmin() && props.otisPage 
          && (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1) 
        )
      }),
      
    });

    state.gridOptions1.Cols.push(
      {        
        Header: 'URL복사',
        Name: 'urlCopy',
        Type: 'Button',
        Width: 75,
        Align: 'Center',
        Visible: state.isCanUrlCopy,   
        Formula: '(Row.urlLink != undefined && Row.urlLink !="") ? "URL복사" : ""',
      },
      {
        Header: '알림재송부',
        Name: 'notiResend',
        Type: 'Button',
        Width: 90,
        Align: 'Center',
        Visible: state.isCanResendNoti, 
        Formula: '(Row.signDt == "" || Row.signDt == null) ? "알림재송부" : ""',
      }
    );


    state.gridOptions2.Cols.push(
      {        
        Header: 'URL복사',
        Name: 'urlCopy',
        Type: 'Button',
        Width: 75,
        Align: 'Center',
        Visible: state.isCanUrlCopy,   
        Formula: '(Row.urlLink != undefined && Row.urlLink !="") ? "URL복사" : ""',
      },
      {
        Header: '알림재송부',
        Name: 'notiResend',
        Type: 'Button',
        Width: 90,
        Align: 'Center',
        Visible: state.isCanResendNoti, 
        Formula: '(Row.signDt == "" || Row.signDt == null) ? "알림재송부" : ""',
      }
    );

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      onDataLoad() {       
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }
        if (e.col === 'urlCopy' && e.row.urlCopy !="") {
          onCopy(e.row);
        }
        if (e.col === 'notiResend' && e.row.notiResend !=""){
          resendNoti(e.row);
        }
      },
      onFocus(e) {
        
      },
    };

    state.gridOptions2.Events = {
      ...state.gridOptions2.Events,
      onDataLoad() {       
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }
        if (e.col === 'urlCopy' && e.row.urlCopy !="") {
          onCopy(e.row);
        }
        if (e.col === 'notiResend' && e.row.notiResend !=""){
          resendNoti(e.row);
        }
      },
      onFocus(e) {
        
      },
    };

    const onCopy = (d) => {
      var url = '';    // <a>태그에서 호출한 함수인 clip 생성
      var textarea = document.createElement("textarea");          
      document.body.appendChild(textarea); //</body> 바로 위에 textarea를 추가(임시 공간이라 위치는 상관 없음)
      url = d.urlLink;
      textarea.value = url;  // textarea 값에 url를 넣어줌
      textarea.select();  //textarea를 설정
      document.execCommand("copy");   // 복사
      document.body.removeChild(textarea); //extarea 요소를 없애줌
      vm.$alert(
          '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
        );
    };
    
    //개별 알림재송부  lgksja 20230111
    const resendNoti = async (d) => {
      console.log(d);
      if(!(state.consortiumInfo.ctrtSttusCd =='10' ||state.consortiumInfo.ctrtSttusCd =='20')){
        vm.$alert('알림재송부를 할수 있는 상태가 아닙니다.');
        return;
      }
      if (!(await vm.$confirm(d.tradeName + '에게 알림재송부 하시겠습니까?'))) return;

      const param = {
        ctrtNo: props.contractKey.ctrtNo,
        ctrtOdr: props.contractKey.ctrtOdr,
        ctrtDivCd: props.contractKey.ctrtDivCd,
        tradeNo: d.tradeNo,
        tradeSfx: d.tradeSfx,
      };
      //console.log("state.memberInfo",state.contractInfo);
      state.loading = true;
      try {
        await axios.post('/contract/reSendNoti', param);
        await vm.$alert('알림재송부가 완료되었습니다.');
        //state.loading = false;
        //state.grid1.removeAll();
        await method.getData();
      } catch (ex) {
        console.log(ex);
        //state.loading = false;
        await vm.$alert('알림재송부 중 오류가 발생하였습니다.');
      } finally {
        state.loading = false;
      }
      
    };

    watch(
      () => props.contractKey,
      newValue => {
        // 초기화
        state.grid1.removeAll();
        state.grid2.removeAll();

        state.consortiumInfo = {};

        // contractKey가 변경된 경우 처리
        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.contractKey) {
        method.getData();
      }
    });

    const method = {
      async getData() {
        state.loading = true;

        const param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/fod/consortium/selectMemberChangeView', param);

          state.consortiumInfo = response.data;

        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      
      
    };

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <iui-tab-group :loading="state.loading">
    <iui-tab title="계약 대상자">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                변경 전
              </template>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.consortiumInfo.memberList1"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                변경 후
              </template>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid2"
                :ibsheet-options="state.gridOptions2"
                :ibsheet-data="state.consortiumInfo.memberList2"
              />
            </iui-content-box>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :document-key="contractKey" />
    </iui-tab>
  </iui-tab-group>
</template>

<style></style>

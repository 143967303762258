<template>
  <pmis-content-box>
    <template v-slot:title>관리자 정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header>이름</i-col-header>
        <i-col>
          <iui-text
            width="300px"
            max-length="16"
            :value="worker.name"
            @change="setWorker({name: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>아이디(사번)</i-col-header>
        <i-col>
          <iui-text
            width="300px"
            max-length="8"
            :value="worker.empNo"
            @change="setWorker({empNo: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>전화번호</i-col-header>
        <i-col>
          <iui-text
            width="200px"
            max-length="20"
            :value="worker.tlno"
            @change="setWorker({tlno: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>휴대폰번호</i-col-header>
        <i-col>
          <iui-text
            width="200px"
            max-length="20"
            :value="worker.mobileTlno"
            @change="setWorker({mobileTlno: $event.target.value})"
            :enable="enable"
          />
          <iui-checkbox-group-old
            :items="[{label: 'SMS수신동의', value: 'Y'}]"
            @change="setWorker({useSms: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>팩스번호</i-col-header>
        <i-col>
          <iui-text
            width="200px"
            max-length="20"
            :value="worker.rpsvFaxno"
            @change="setWorker({rpsvFaxno: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>이메일</i-col-header>
        <i-col>
          <iui-text
            width="180px"
            max-length="50"
            :value="worker.email1"
            @change="setWorker({email1: $event.target.value})"
            :enable="enable"
          />
          <div class="mr5">@</div>
          <iui-select-old
            p-code="0275"
            :value="email2"
            @change="setWorker({email2: $event.target.value})"
            :enable="enable"
          />
          <iui-text
            width="180px"
            max-length="50"
            :value="worker.email2 == '000099' ? '' : worker.email2"
            @change="setWorker({email2: $event.target.value})"
            v-if="worker.email2.indexOf('0000') == -1 || worker.email2 == '000099'"
            :enable="enable"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      email2: '000099',
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
    };
  },

  watch: {
    worker: {
      handler(val) {
        if (val.email2) this.email2 = val.email2;
      },
      deep: true,
    },
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'worker']);
    $mapMutations(this, ['setWorker']);
  },

  created() {},
};
</script>

<style scoped></style>

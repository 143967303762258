<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <table border="0" class="tbl_list" style="table-layout:auto">
            <tr>
              <td colspan="4">
                거래선번호가 8자리로 변경이 되었습니다.<br>구 거래선 번호와 고객/밴더 확인후 아래 리스트 맞는 거래선을 선택해주세요.<br>
                추후에는 8자리 거래선 번호로 로그인 해주시기 바랍니다.<br>
                (거래선 정보가 없다면 관리자에게 문의 바랍니다.)
              </td>
            </tr>
            <tr>
              <th>신 거래선번호(8자리)</th>
              <th>회사명</th>
              <th>구 거래선번호(7자리)</th>
              <th>고객/밴더</th>
            </tr>
            <tr v-for="(item, index) in gjdeTradeNoList" v-bind:key="index" style="cursor:pointer" @click="rowclick(index)">
              <td style="text-align:center">{{ item.tradeNo }}</td>
              <td align="left">{{ item.tradeName }}</td>
              <td style="text-align:center">{{ item.oldTradeNo }}</td>
              <!-- <td style="text-align:center">{{ item.oldTradeSuffix }}</td> -->
              <td style="text-align:center"><span v-if="item.flag=='V'">밴더</span><span v-else>고객</span></td>
            </tr>
          </table>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
    },
    gjdeTradeNoList: {
      type: Array,
      default: () => {},
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    rowclick(index) {
      console.log(JSON.stringify(this.gjdeTradeNoList[index]));
      this.callback(null, this.gjdeTradeNoList[index].tradeNo);
      this.$modal.hide('selectGjdeTradeNo');
    },
  },
};
</script>

<style></style>

import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup.js';

export function useViewerWindowPopup() {
  const windowPopup = useWindowPopup();

  const open = options => {
    let data = undefined;

    if (options.hasOwnProperty('data')) {
      data = options.data;
    } else {
      data = options;
    }

    console.log(options, data);

    const popup = windowPopup.open({
      name: 'ViewerWindowPopup',
      componentPath: '@/view/common/components/Viewer.vue',
      width: 1000,
      height: 700,
      title: '문서 뷰어',
      data: data,
    });

    return popup;
  };

  return {
    open,
  };
}

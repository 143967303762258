<script>
import {defineComponent, getCurrentInstance, reactive, watch, onMounted} from '@vue/composition-api';

const gridOptions = {
  Cfg: {
    CanEdit: 3,
  },
  // prettier-ignore
  Cols: [
    { Header: '이력 일시', Name: 'entryDate', Width: 150 },
    { Header: '작업자', Name: 'operatorNm', Align: 'Left', Width: 200 },
    { Header: '이력 내용', Name: 'histCn', Align: 'Left', RelWidth: 1 },
  ]
};

export default defineComponent({
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
    documentKey: {
      type: Object,
      default: undefined,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading: false,

      grid: undefined,
      gridOptions: gridOptions,

      list: undefined,
    });

    const method = {
      async getData() {
        state.loading = true;

        const data = {
          ctrtNo: props.documentKey.ctrtNo,
          ctrtOdr: props.documentKey.ctrtOdr,
          ctrtDivCd: props.documentKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/contract/historyList', data);

          state.list = response.data;
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },
    };

    watch(
      () => props.documentKey,
      newValue => {
        state.grid.removeAll();

        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.documentKey) {
        method.getData();
      }
    });

    return {
      state,
      ...method,
    };
  },
});
</script>

<template>
  <iui-container
    type="ibsheet"
    :ibsheet.sync="state.grid"
    :ibsheet-options="state.gridOptions"
    :ibsheet-data="state.list"
  />
</template>

<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <iui-container type="table" v-if="isCONS" :headerWidth="width200">
          <i-row>
            <i-col-header>(컨소시엄)특기사항</i-col-header>
            <i-col>
              <iui-text
                type="multi"
                name="estimateMemo"
                height="200px"
                :value="consMemo"
                :enable="false"
                :css="style"
              />
            </i-col>
          </i-row>
        </iui-container>
        <iui-container type="table" v-else-if="isDIRC" :headerWidth="width200">
          <i-row>
            <i-col-header>(직영)특기사항</i-col-header>
            <i-col>
              <iui-text
                type="multi"
                name="estimateMemo"
                height="200px"
                :value="dircMemo"
                :enable="false"
                :css="style"
              />
            </i-col>
          </i-row>
        </iui-container>
        <iui-container type="table" v-else-if="isSUBC" :headerWidth="width200">
          <i-row>
            <i-col-header>(하도급)특기사항</i-col-header>
            <i-col>
              <iui-text
                type="multi"
                name="estimateMemo"
                height="200px"
                :value="subcMemo"
                :enable="false"
                :css="style"
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>
<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['estimateInfo']);
  },
  created() {
    this.addEvent({name: 'EstimateCondition_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      consMemo: '',
      dircMemo: '',
      subcMemo: '',

      style: {
        'white-space': 'pre-wrap',
        'word-wrap': 'break-word',
      },
    };
  },
  mounted() {
    this.consMemo +=
      '1)위 견적서는 귀하와 상호간에 계약을 체결하기 전까지는 법적으로 유효하지 않으며, 견적 유효기간은 발행일 로부터 30일입니다.\n';
    this.consMemo += '2)작업은 통상근무시간에 실시하며, 통상근무시간외 작업 요청시에는 인건비 50%가 가산됩니다.\n';
    this.consMemo +=
      '3)부품은 승강기 제조사인 ‘OTIS’의 신뢰성 검사와 형식승인을 거친 순정품을 사용하며 품질은 보증합니다.\n';
    this.consMemo += '4)일반관리비 내역 : 공구손료, 잡자재,운반비,산재보험료,안전관리비,하자보증,폐자재처리비 등\n';
    this.consMemo +=
      '5)상기의 원활한 공사를 위해 공동수급으로 공사를 진행하는 것과 ‘OTIS’가 공동수급체 대표자로서 역활을 수행함에 대해 발주자(관리주체)는 동의합니다.\n';
    this.consMemo += '6)공동수급체 구성원은 아래 업무에 따라 각자 업무를 수행하며, 각자 안전관리업무를 수행합니다.\n';
    this.consMemo +=
      '7)공동 승강로에서 작업이 이루어지는 경우, 작업자 및 고객을 위험 요인으로부터 보호하고 공사를 안전 하게 진행하기 위해 공동수급체 구성원들이 작업호기에 인접한 호기의 운행을 중지시키는 것에 대해 발주자(관리 주체)는 동의합니다.';

    this.dircMemo +=
      '1)위 견적서는 귀하와 상호간에 계약을 체결하기 전까지는 법적으로 유효하지 않으며, 견적 유효기간은 발행일 로부터 30일입니다.\n';
    this.dircMemo += '2)작업은 통상근무시간에 실시하며, 통상근무시간외 작업 요청시에는 인건비 50%가 가산됩니다.\n';
    this.dircMemo +=
      '3)부품은 승강기 제조사인 당사의 신뢰성 검사와 형식승인을 거친 순정품을 사용하며 품질을 보증합니다.\n';
    this.dircMemo += '4)일반관리비 내역 : 공구손료, 잡자재,운반비,산재보험료,안전관리비,하자보증,폐자재처리비 등\n';
    this.dircMemo +=
      '5)공동 승강로에서 작업이 이루어지는 경우, 작업자 및 고객을 위험 요인으로부터 보호하고 공사를 안전 하게 진행하기 위해 공동수급체 구성원들이 작업호기에 인접한 호기의 운행을 중지시키는 것에 대해 발주자(관리 주체)는 동의합니다.';

    this.subcMemo +=
      '1)위 견적서는 귀하와 상호간에 계약을 체결하기 전까지는 법적으로 유효하지 않으며, 견적 유효기간은 발행일 로부터 30일입니다.\n';
    this.subcMemo += '2)작업은 통상근무시간에 실시하며, 통상근무시간외 작업 요청시에는 인건비 50%가 가산됩니다.\n';
    this.subcMemo +=
      '3)부품은 승강기 제조사인 당사의 신뢰성 검사와 형식승인을 거친 순정품을 사용하며 품질을 보증합니다.\n';
    this.subcMemo += '4)일반관리비 내역 : 공구손료, 잡자재,운반비,산재보험료,안전관리비,하자보증,폐자재처리비 등\n';
    this.subcMemo +=
      '5)상기의 원활한 공사진행을 위해 오티스엘리베이터의 협력업체에게 하도급하는 것에 대해 발주자(관리주체)는 동의합니다.\n';
    this.subcMemo +=
      '6)공동 승강로에서 작업이 이루어지는 경우, 작업자 및 고객을 위험 요인으로부터 보호하고 공사를 안전 하게 진행하기 위해 공동수급체 구성원들이 작업호기에 인접한 호기의 운행을 중지시키는 것에 대해 발주자(관리 주체)는 동의합니다.';
  },
  methods: {
    onLoad() {
      console.log('EstimateCondition_Load');
    },
  },
  computed: {
    isCONS() {
      return this.estimateInfo.docGb2 == 'CONS';
    },
    isDIRC() {
      return this.estimateInfo.docGb2 == 'DIRC';
    },
    isSUBC() {
      return this.estimateInfo.docGb2 == 'SUBC';
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box>
    <template v-slot:title>노무비관리정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header>외국인 체류자격에 의한 고용보험 산출</i-col-header>
        <i-col>
          <!-- <iui-checkbox-group-old
            p-code="0125"
            :value="companyInfo.femployInsurDs"
            @change="setCompanyInfo({femployInsurDs: $event.target.value})"
            :enable="enable"
          /> -->
          <iui-radio-group-old
            :items="items"
            :value="companyInfo.femployInsurDs"
            @change="setCompanyInfo({femployInsurDs: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>일 노무비 소숫점 관리</i-col-header>
        <i-col>
          <iui-radio-group-old
            p-code="0150"
            :value="companyInfo.decimalDs"
            @change="setCompanyInfo({decimalDs: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>소득세 산출방식</i-col-header>
        <i-col colspan="3">
          <iui-radio-group-old
            p-code="0115"
            :value="companyInfo.taxDs"
            @change="setCompanyInfo({taxDs: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
      items: [
        {label: '아니오', value: ''},
        {label: '예', value: 'Y'},
      ],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo']);
    $mapMutations(this, ['setCompanyInfo']);
  },

  created() {},
};
</script>

<style scoped>
.iui-container.table tr > th {
  width: 200px;
  min-width: 200px;
}
</style>

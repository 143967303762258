<template>
  <iui-content-box>
    <template v-slot:header-left>{{title}}</template>
    <template v-slot:header-right>
      <iui-button v-if="allowAdd" :enable="buttonEnable" @click="btnAdd_click">{{ addButtonText }}</iui-button>
      <iui-button
        v-if="allowDelete"
        :enable="buttonEnable"
        @click="btnDelete_click"
      >{{ deleteButtonText }}</iui-button>
      <iui-button v-if="saveButtonVisible" :enable="buttonEnable" @click="btnSave_click">
        {{
        saveButtonText
        }}
      </iui-button>
    </template>

    <file-upload
      :headers="postHeader"
      :post-action="internalPostAction"
      ref="upload"
      v-model="internalFiles"
      name="files"
      :drop="allowAdd"
      :multiple="false"
      @input="fileUpload_input"
      @input-filter="fileUpload_inputFilter"
      @input-file="fileUpload_inputFile"
      style="display: none;"
    ></file-upload>

    <!-- <img :src="imageSource" style="width: 190px; height: auto; display: block; margin: 1px auto;" /> -->
    <img
      :src="imageSource"
      :style="{
        display: 'block',
        margin: '1px auto',
        width: imageWidth,
        height: imageHeight,
        maxWidth: imageMaxWidth,
        maxHeight: imageMaxHeight,
      }"
    />
  </iui-content-box>
</template>

<script>
import VueCookies from 'vue-cookies';
import FileUpload from 'vue-upload-component';

export default {
	name: 'pmis-image-file',
	components: {
		FileUpload: FileUpload,
	},
	props: {
		title: {
			type: [Number, String],
		},
		companyCode: {
			type: [Number, String],
			default: undefined,
		},
		fileNumber: {
			type: [Number, String],
			default: 0,
		},
		groupNumber: {
			type: [Number, String],
			default: 0,
		},
		projectCode: {
			type: String,
			default: undefined,
		},
		fbs: {
			type: String,
			default: undefined,
		},
		thumbnail: {
			type: Boolean,
			default: false,
		},
		allowAdd: {
			type: Boolean,
			default: true,
		},
		allowDelete: {
			type: Boolean,
			default: true,
		},
		addButtonText: {
			type: String,
			default: '추가',
		},
		deleteButtonText: {
			type: String,
			default: '삭제',
		},
		saveButtonText: {
			type: String,
			default: '저장',
		},
		saveButtonVisible: {
			type: Boolean,
			default: true,
		},

		save: {
			type: Boolean,
			default: false,
		},
		autoSave: {
			type: Boolean,
			default: false,
		},
		imageWidth: {
			type: String,
			default: '95%',
		},
		imageHeight: {
			type: String,
			default: 'auto',
		},
	},
	data() {
		return {
			internalFileNumber: 0,
			internalGroupNumber: 0,
			internalTicks: new Date().getTime(),
			internalImageSource: undefined,
			internalName: `file${this._uid}`,
			internalPostAction: `${process.env.VUE_APP_BASE_URL}/file/saveFile`,
			internalFiles: [],
			internalStatus: '',

			internalAction: {
				search: false,
				add: false,
				delete: false,
				save: false,
				deleteAll: false,
				clear: false,
			},

			imageMaxWidth: '',
			imageMaxHeight: '',
		};
	},
	computed: {
		buttonEnable() {
			return (
				!this.internalAction.search &&
				!this.internalAction.add &&
				!this.internalAction.delete &&
				!this.internalAction.save &&
				!this.internalAction.deleteAll &&
				!this.internalAction.clear
			);
		},
		postHeader() {
			let authorization = '';

			if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
				authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD'+ '_accessToken');
			}

			return {Authorization: authorization};
		},
		imageSource() {
			let src = undefined;
			if (!this.companyCode) return;

			if (this.internalImageSource === undefined) {
				let flDs = this.thumbnail
					? this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code
					: this.$store.state.code2.codeAlias.FLDS_IMAGE.code;

				src = `${process.env.VUE_APP_BASE_URL}/image/getImage?pgmCd=${this.companyCode}&flNo=${this.internalFileNumber}&grpNo=${this.internalGroupNumber}&flDs=${flDs}&ticks=${this.internalTicks}`;

				if (this.internalFileNumber == 0 || !this.internalFileNumber) {
					src = require('@/assets/img/old/img_no_image.png');
				} else {
					src = `${process.env.VUE_APP_BASE_URL}/image/getImage?pgmCd=${this.companyCode}&flNo=${this.internalFileNumber}&grpNo=${this.internalGroupNumber}&flDs=${flDs}&ticks=${this.internalTicks}`;
				}
			} else {
				src = this.internalImageSource;
			}

			return src;
		},
	},
	watch: {
		fileNumber(newValue, oldValue) {
			if (newValue !== oldValue) {
				if (newValue !== this.internalFileNumber) {
					this.internalFileNumber = newValue;
					if (!this.internalAction.search) {
						this.internalAction.search = true;
						this._search();
					}
				}
			}
		},
		search(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.search = newValue;

				if (newValue) {
					this._search();
				}
			}
		},
		add(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.add = newValue;

				if (newValue) {
					this.btnAdd_click();
				}
			}
		},
		delete(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.delete = newValue;

				if (newValue) {
					this.btnDelete_click();
				}
			}
		},
		save(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.save = newValue;

				if (newValue) {
					this.btnSave_click();
				}
			}
		},
		deleteAll(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.deleteAll = newValue;

				if (newValue) {
					this._deleteAll();
				}
			}
		},
		clear(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.internalAction.clear = newValue;

				if (newValue) {
					this._clear();
				}
			}
		},
	},
	created() {
		const vm = this;

		this.internalFileNumber = this.fileNumber;
		this.internalGroupNumber = this.groupNumber;

		this.$on('update:search', (e) => {
			vm.internalAction.search = e;
		});
		this.$on('update:add', (e) => {
			vm.internalAction.add = e;
		});
		this.$on('update:delete', (e) => {
			vm.internalAction.delete = e;
		});
		this.$on('update:save', (e) => {
			vm.internalAction.save = e;
		});
		this.$on('update:deleteAll', (e) => {
			vm.internalAction.deleteAll = e;
		});
		this.$on('update:clear', (e) => {
			vm.internalAction.clear = e;
		});

		this._search();
	},
	mounted() {
		// const vm = this;

		// console.log('1', this.$parent.$el.scrollWidth, this.$parent.$el.scrollHeight);
		// console.log('2', this.$parent.$el.clientWidth, this.$parent.$el.clientHeight);
		// console.log('3', this.$parent.$el.getBoundingClientRect().width, this.$parent.$el.getBoundingClientRect().height);

		this.imageMaxWidth = `${this.$parent.$el.getBoundingClientRect().width - 5}px`;
		this.imageMaxHeight = `${this.$parent.$el.getBoundingClientRect().height - 5}px`;
		console.log('>> MAX Width Height', this.imageMaxWidth, this.imageMaxHeight);
	},
	methods: {
		btnAdd_click(e) {
			if (e !== undefined) {
				this.internalAction.add = true;
			}

			this.$refs.upload.$children[0].$el.click();

			this.$emit('update:add', false);
		},
		async btnDelete_click(e) {
			if (e !== undefined) {
				this.internalAction.delete = true;
			}

			await this.deleteFile();

			this.$emit('update:delete', false);
		},
		async btnSave_click(e) {
			if (e !== undefined) {
				this.internalAction.save = true;
			}
			if (this.internalFiles.length === 0) {
				this.$emit('update:save', false);
				return;
			}

			// 비로그인시(회원가입) 회사코드 없어서 설정해줌.
			this.$refs.upload.files.forEach((el) => {
				if (!el.data.pgmCd && this.companyCode) {
					el.data.pgmCd = this.companyCode;
				}
			});
			this.$emit('save-start');
			this.$emit('upload-start');
			this.$refs.upload.update(this.internalFiles[0], {active: true, error: '', progress: '0.00'});
		},
		async _search() {
			if (this.internalFileNumber !== 0) {
				const data = {
					pgmCd: this.companyCode,
					flNo: this.internalFileNumber,
					flDs: this.thumbnail
						? this.$store.state.code2.codeAlias.FLDS_IMAGE_THUMBNAIL.code
						: this.$store.state.code2.codeAlias.FLDS_IMAGE.code,
				};

				try {
					const response = await axios.post('/file/selectFileList', data);

					// console.log(response);

					this.internalGroupNumber = response.data[0].grpNo;
					this.$emit('update:groupNumber', this.internalGroupNumber);
				} catch (ex) {
					// error
				}
			}

			this.$emit('update:changed', false);
			this.$emit('update:search', false);
		},
		async deleteFile() {
			const data = [
				{
					pgmCd: this.companyCode,
					flNo: this.internalFileNumber,
					grpNo: this.internalGroupNumber,
				},
			];

			this.$emit('delete-start');

			try {
				await axios.post('/file/deleteFile', data);
				this.internalImageSource = undefined;
				// console.log(response);
				this.$emit('delete-complete');
			} catch (ex) {
				this.$emit('delete-error');
			}
		},
		fileUpload_input() {
			// console.group(`fileUpload_input`);
			// console.log(files);
			// console.groupEnd();
		},
		fileUpload_inputFilter(newFile, oldFile) {
			// console.group(`fileUpload_inputFilter`);
			// console.log(newFile);
			// console.log(oldFile);
			// console.groupEnd();

			if (newFile && !oldFile) {
				// Add file
				this.$emit('update:changed', true);
			}

			if (newFile && oldFile) {
				// Update file
			}

			if (!newFile && oldFile) {
				// Remove file
				// Refused to remove the file
				// return prevent()
			}
		},
		async fileUpload_inputFile(newFile, oldFile) {
			// const vm = this;
			// console.group(`fileUpload_inputFile`);
			// console.log(newFile);
			// console.log(oldFile);
			// console.groupEnd();

			if (newFile && !oldFile) {
				// Add file
				const data = {
					pgmCd: this.companyCode,
					flNo: this.internalFileNumber,
					flDs: this.$store.state.code2.codeAlias.FLDS_IMAGE.code,
					prjCd: this.projectCode,
					fbs: this.fbs,
					lclFlNm: newFile.name,
					// svrFlNm: newFile.name,
					flSz: newFile.size,
				};

				newFile.data = data;

				// 미리 보기
				let URL = window.URL || window.webkitURL;
				if (URL && URL.createObjectURL) {
					this.internalImageSource = URL.createObjectURL(newFile.file);
				}

				if (this.autoSave) {
					this.internalAction.save = true;
					this.btnSave_click();
				}
			}

			if (newFile && oldFile) {
				// Update file

				if (newFile.active && !oldFile.active) {
					// beforeSend
					// min size
					// if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
					//   this.$refs.upload.update(newFile, {error: 'size'});
					// }
				}
				if (newFile.progress !== oldFile.progress) {
					// progress
					// console.log(
					//   `${newFile.name} progress - progress: ${newFile.progress}, error: ${newFile.error}, rowIndex: ${
					//     newFile.rowIndex
					//   }`
					// );
				}
				if (newFile.error && !oldFile.error) {
					// error

					this.$emit('update:save', false);
					this.$emit('upload-error', newFile.name);
					this.$emit('save-error');
				}
				if (newFile.success && !oldFile.success) {
					// success

					// 파일번호가 초기값이면 업로드 후 생성된 파일번호를 신규 데이터에 설정한다.
					if (this.internalFileNumber === 0) {
						this.internalFileNumber = newFile.response.flNo;
						this.$emit('update:fileNumber', this.internalFileNumber);
					} else {
						await this.deleteFile();
						this.internalFileNumber = newFile.response.flNo;
						this.$emit('update:fileNumber', this.internalFileNumber);
					}

					this.internalGroupNumber = newFile.response.grpNo;
					this.$emit('update:groupNumber', this.internalGroupNumber);

					// this.internalStatus = '업로드 완료';
					this.internalImageSource = undefined;
					this.$emit('upload-complete', newFile.name);
					this.$refs.upload.remove(newFile);

					this.$emit('update:save', false);
					this.$emit('update:changed', false);
					this.$emit('save-complete');
					this.updateTicks();
				}
			}

			if (!newFile && oldFile) {
				// Remove file
			}
		},
		updateTicks() {
			this.internalTicks = new Date().getTime();
		},
	},
};
</script>

<style></style>

<template>
  <!--pmis-page-->
  <pmis-content-box v-if="true">
    <iui-container type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <iui-container type="table" theme="bullet" header-width="100px">
            <!--i-row>
            <i-col-header>자원구분</i-col-header>
            <i-col>
              <iui-select-old
                name="costType"
                p-code="0051"
                exclude-items="C,A"
                :defaultCd="'S'"
                @change="onSetState('costType', $event)"
                :value="isFormula ? (detailInfoDtls ? detailInfoDtls.costType : detailInfo.costType) : ''"
                width="120px"
              />
            </i-col>
          </i-row-->
            <i-row>
              <i-col colspan="4">
                <textarea
                  name="helpTip"
                  readonly="true"
                  style="width:100%;height:210px;overflow-y:auto;"
                  v-model="helpTip"
                ></textarea>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>상세 도움말 Link :</i-col-header>
              <i-col colspan="3">
                <a href="#" @click="link_click">{{ helpUrl }}</a>
              </i-col>
            </i-row>
          </iui-container>
        </i-row>
        <i-spacer />

        <!--i-row>
        <pmis-content-box>
          <template v-slot:title>생성방법</template>
          <p>1...... </p>
        </pmis-content-box>
      </i-row-->
      </i-col>
    </iui-container>
  </pmis-content-box>
  <!--/pmis-page-->
</template>

<script>
export default {
  props: {
    programId: {
      type: String,
      default: '',
    },
    helpTip: {
      type: String,
      default: '',
    },
    helpUrl: {
      type: String,
      default: '',
    },
  },
  beforeCreate() {
    //$mapGetters(this, {detailInfoDtls: 'detailInfoDtls', isFormula: 'isFormula', detailInfo: 'detailInfo'});
  },
  created() {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // eslint-disable-next-line
    setForm(param) {},
    // eslint-disable-next-line
    onPopConfirm(param) {},
    link_click() {
      var url = this.helpUrl;
      if (url && $.trim(url) != '') {
        window.open(url);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="contents">
    <div class="join_step01">
      <span class="blind">step01 이용약관 동의</span>
    </div>

    <div class="agree_area">
      <div class="title">
        <input type="checkbox" id="c1" v-model="agree1" />
        <label for="c1">
          <span></span>개인정보 취급방침 동의
        </label>
      </div>
    </div>
    <div class="terms_box"></div>

    <div class="agree_area">
      <div class="title">
        <input type="checkbox" id="c2" v-model="agree2" />
        <label for="c2">
          <span></span>서비스 이용약관 동의
        </label>
      </div>
    </div>
    <div class="terms_box"></div>

    <div class="btn_area">
      <button type="button" class="btn_default" @click="onCancel">취소</button>
      <button type="button" class="btn_confirm ml10" @click="onNextStep">확인</button>
    </div>
  </div>
  <!-- contents end -->
</template>

<script>
import {mapMutations} from 'vuex';
export default {
	data() {
		return {
			agree1: false,
			agree2: false,
		};
	},

	methods: {
		...mapMutations('login', ['setPage']),
		onCancel() {
			this.setPage('Login');
		},

		onNextStep() {
			if (!this.agree1) {
				this.$alert('개인정보 취급방침을 동의해주세요.');
				return;
			}
			if (!this.agree2) {
				this.$alert('서비스 이용약관을 동의해주세요.');
				return;
			}
			this.$parent.nextStep('02');
		},
	},
};
</script>

<style scoped>
</style>
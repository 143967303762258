<template>
  <pmis-content-box>
    <template v-slot:title>공급물품정보</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd()" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave()" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete()" v-show="enable" />
    </template>
    <iui-container type="css-flex">
      <i-row height="100px">
        <i-col>
          <iui-container type="ibsheet" :id="elId" />
        </i-col>
      </i-row>
    </iui-container>
    <iui-modal-old name="matList" title="자재분류선택" width="400px" height="500px" :btns="modalBtns">
      <matList />
    </iui-modal-old>
  </pmis-content-box>
</template>

<script>
import matList from '@/view/sysManage/userManage/member/components/popup/matList';
export default {
  components: {
    matList,
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'matList']);
    $mapMutations(this, ['setCompanyInfo']);
  },

  created() {
    this.addEvent([{name: 'mtrilInfo_getSaveJson', func: this.getSaveJson}]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/matList`],
      value => {
        if (value && value.length) {
          let load = setInterval(() => {
            if (this.sheet) {
              clearInterval(load);
              this.sheet.loadSearchData(value);
            }
          }, 10);
        } else {
          if (this.sheet) {
            this.sheet.removeAll();
          }
        }
      }
    );
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
          ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
          IgnoreFocused: true,
        },
        Cols: [
          {
            Header: '선택',
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            CanEdit: true,
            Visible: this.enable,
          },
          {
            Header: '순번',
            Name: 'seq',
            Type: 'Text',
            Width: 50,
            CanEdit: false,
          },
          {
            Header: '대분류',
            Name: 'lmName',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '중분류',
            Name: 'mmName',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '소분류',
            Name: 'smName',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '비고',
            Name: 'rmk',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
          },
          {
            Name: 'lmCode',
            Type: 'Text',
            Visible: 0,
          },
          {
            Name: 'mmCode',
            Type: 'Text',
            Visible: 0,
          },
          {
            Name: 'smCode',
            Type: 'Text',
            Visible: 0,
          },
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.sheet.bind('onRenderFirstFinish', () => {
        if (this.matList.length) {
          this.sheet.loadSearchData(this.matList);
        }
      });
    },

    onAdd() {
      this.$modal.show('matList');
    },
    onConfirm() {
      this.callEvent({
        name: 'matList_onFocusedRowData',
        param: data => {
          console.log(data);
          if (data.Level != 3) {
            this.$alert('3레벨 분류를 선택해주세요.');
            return;
          }
          let row = this.sheet.addRow();
          this.sheet.setValue(row, 'lmCode', data.lmCode, 1);
          this.sheet.setValue(row, 'mmCode', data.mmCode, 1);
          this.sheet.setValue(row, 'smCode', data.smCode, 1);
          this.sheet.setValue(row, 'lmName', data.lmName, 1);
          this.sheet.setValue(row, 'mmName', data.mmName, 1);
          this.sheet.setValue(row, 'smName', data.smName, 1);
          this.$modal.hide('matList');
        },
      });
    },
    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert('저장할 공급물품정보가 존재하지 않습니다.');
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }

      let saveJson = this.sheet.getSaveJson().data;
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });

      if (await this.$confirm('저장하시겠습니까?')) {
        axios
          .post('/companyRegist/saveMatList', {matList: $_statusToCud(saveJson)})
          .then(response => {
            if (response.status == 200) {
              this.onSearchMatList(this.companyInfo.pgmCd);
              this.$alert('저장을 완료하였습니다.');
            } else {
              this.$alert('저장을 실패하였습니다.');
            }
          })
          .catch(ex => {
            console.log('저장을 실패하였습니다. : ', ex);
          });
      }
    },
    onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      checkedRows.forEach(row => this.sheet.deleteRow(row));
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },

    async onSearchMatList(pgmCd) {
      await axios.post('/companyRegist/selectMatList', {pgmCd: pgmCd}).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  },

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style></style>

<template>
  <pmis-content-box>
    <template v-slot:title>시스템사용정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header width="100px">시작(계약)일자</i-col-header>
        <i-col>
          <iui-datepicker-old
            :value="companyInfo.useStrdate"
            @change="setCompanyInfo({useStrdate: $event})"
            :enable="enable"
          />
        </i-col>
        <i-col-header>종료일자</i-col-header>
        <i-col>
          <iui-datepicker-old
            :value="companyInfo.useEnddate"
            @change="setCompanyInfo({useEnddate: $event})"
            :enable="enable"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) != -1,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo']);
    $mapMutations(this, ['setCompanyInfo']);
  },
};
</script>

<style scoped></style>

const IuiValidatorMixin = {
  props: {
    validationGroup: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
    },
    max: {
      type: [String, Number],
    },
    minLength: {
      type: [String, Number],
    },
    maxLength: {
      type: [String, Number],
    },
    pattern: {
      type: String,
    },
    customValidator: {
      type: Function,
    },
    errorMessage: {
      type: [String, Object],
    },
    lazyValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * 생성된 validator
       */
      validator: null,
      /**
       * validator가 참조할 data 명칭
       */
      validatorTargetDataProp: null,
      /**
       * validator가 리스닝할 이벤트명
       */
      validationTriggerEventName: [],
    };
  },
  computed: {
    isValid: {
      get: function() {
        if (typeof this.validator === undefined || this.validator === null) {
          return true;
        } else {
          return this.validator.isValid;
        }
      },
    },
  },
  beforeMount() {
    const me = this;

    if (this.required) {
      this.validator = this.$validator.createValidator(
        me.$options._VPath,
        me.$options.propsData.id,
        me.validationGroup,
        me,
        me.validatorTargetDataProp,
        {
          required: me.required,
          min: me.min,
          max: me.max,
          minLength: me.minLength,
          maxLength: me.maxLength,
          pattern: me.pattern,
        },
        me.customValidator,
        me.errorMessage
      );

      if (!this.lazyValidation) {
        if (Array.isArray(this.validationTriggerEventName)) {
          this.validationTriggerEventName.forEach(eventName => {
            me.$on(eventName, () => {
              me.validator.validate();
            });
          });
        }
        if (typeof this.validationTriggerEventName === 'string') {
          this.$on(this.validationTriggerEventName, () => {
            me.validator.validate();
          });
        }
      }
    }
  },
  beforeDestroy() {
    // beforeDestory
  },
};

export default IuiValidatorMixin;

/* 반송/회수 관리 팝업 * /
<script>
import {defineComponent, reactive, watch, computed, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: undefined,
    },
    contractKey: {
      type: Object,
      default: undefined,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const rejectManageDialog = useDialog('rejectManageDialog');

    const Layout = reactive({
      title: computed(() => {
        return `${state.legacyName} 전송/재전송`;
      }),

      width: computed(() => {
        return '800px';
      }),

      height: computed(() => {
        // if (state.isRecovery) return '350px';
        // if (state.isReject) return '390px';

        // return '210px';
        return '390px';
      }),

      reasonTitle: computed(() => {
        let s = `${state.legacyName} 전송 사유`;

        if (state.canResend) {
          s += ' /<br />재전송 사유';
        }

        return s;
      }),
    });

    const state = reactive({
      loading: false,

      legacyName: computed(() => {
        if (props.contractKey === undefined || props.contractKey === null) {
          return '';
        }
        if (
          props.contractKey.ctrtDivCd.startsWith('NE_') ||
          (props.contractKey.ctrtDivCd.startsWith('MOD_') && props.contractKey.ctrtDivCd !== 'MOD_CT12')
        ) {
          return 'OKESS';
        }
        if (props.contractKey.ctrtDivCd.startsWith('FOD_')) {
          return 'OKIS';
        }
        if (props.contractKey.ctrtDivCd === 'MOD_CT12' || props.contractKey.ctrtDivCd.startsWith('NPP_') || props.contractKey.ctrtDivCd.startsWith('JSA_')) {
          return 'OKBS+';
        }
      }),

      ccpyName: computed(() => {
        if (props.contractKey === undefined || props.contractKey === null) {
          return '';
        }
        if (
          props.contractKey.ctrtDivCd.startsWith('NE_') ||
          (props.contractKey.ctrtDivCd.startsWith('MOD_') && props.contractKey.ctrtDivCd !== 'MOD_CT12')
        ) {
          return '3PI';
        }
        if (['FOD_CT1', 'FOD_CT2'].includes(props.contractKey.ctrtDivCd)) {
          return '3PI';
        }
        if (props.contractKey.ctrtDivCd.startsWith('FOD_')) {
          return '업체';
        }
        if (props.contractKey.ctrtDivCd === 'MOD_CT12' || props.contractKey.ctrtDivCd.startsWith('NPP_') || props.contractKey.ctrtDivCd.startsWith('JSA_')) {
          return '업체';
        }
      }),

      // 회수
      rtrvlResn: {
        ctrtNo: undefined,
        ctrtOdr: undefined,
        ctrtDivCd: undefined,
        rtrvlDtStr: undefined, // 회수 일시
        rtrvlResnCn: undefined, // 회수 사유
      },

      // 반송
      retrnResn: {
        ctrtNo: undefined,
        ctrtOdr: undefined,
        ctrtDivCd: undefined,
        tradeName: undefined, // 반송 회사명
        entryDateStr: undefined, // 반송 일시
        retrnResnCn: undefined, // 반송 사유
      },

      // 전송 사유
      reason: '',

      // 회수
      isRecovery: computed(() => {
        if (!props.contractKey) {
          return false;
        }

        return (
          props.contractKey.ctrtSttusCd !== undefined &&
          props.contractKey.ctrtSttusCd !== null &&
          ['12'].includes(props.contractKey.ctrtSttusCd.toString())
        );
      }),

      // 반송
      isReject: computed(() => {
        if (!props.contractKey) {
          return false;
        }

        return (
          props.contractKey.ctrtSttusCd !== undefined &&
          props.contractKey.ctrtSttusCd !== null &&
          ['11', '14'].includes(props.contractKey.ctrtSttusCd.toString())
        );
      }),

      // 재전송
      canResend: computed(() => {
        if (!props.contractKey) {
          return false;
        }

        return (
          props.contractKey.ctrtSttusCd !== undefined &&
          props.contractKey.ctrtSttusCd !== null &&
          props.contractKey.ctrtSttusCd.toString() !== ''
        );
      }),
    });

    const method = {
      dialog_before_open() {
        state.reason = undefined;
      },

      // 조회
      async getData() {
        state.loading = true;

        const data = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          const p1 = axios.post('/contract/selectRtrvlResnCn', data);
          const p2 = axios.post('/contract/selectRetrnResnCn', data);

          const responseList = await Promise.all([p1, p2]);

          state.rtrvlResn = responseList[0].data;
          state.retrnResn = responseList[1].data;
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      // Legacy 전송
      async btnSendToLegacy_click() {
        if (state.reason.trim().length === 0) {
          await vm.$alert(`${state.legacyName} 전송 사유를 입력해야 합니다.`);
          return;
        }

        const message = `${state.legacyName}로 반송 시 재승인을 받아야 합니다.<br /><br />${state.legacyName}로 반송 하시겠습니까?<br /><br />
반송된 문서는 전자계약시스템에서 조회되지 않습니다.<br /><br />`;

        if (await vm.$confirm(message)) {
          const data = {
            //...props.contractKey,
            ctrtNo: props.contractKey.ctrtNo,
            ctrtOdr: props.contractKey.ctrtOdr,
            ctrtDivCd: props.contractKey.ctrtDivCd,
            reason: state.reason,
            chngPgmId: undefined,
            approvalNo: props.contractKey.approvalNo, // 품의번호 20230203
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            state.loading = true;
            await axios.post('/contract/sendToOkss', data);

            await vm.$alert(`${state.legacyName}로 전송이 완료되었습니다.`);

            rejectManageDialog.hide(true);
          } catch (ex) {
            await vm.$alert(`${state.legacyName}로 전송에 실패하였습니다.` + ex);
          } finally {
            state.loading = false;
          }
        }
      },

      // 고객/OSEP, 고객/3PI 재전송
      async btnReSend_click() {
        if (!state.reason || state.reason.trim().length === 0) {
          await vm.$alert('재전송 사유를 입력해야 합니다.');
          return;
        }

        if (await vm.$confirm('현재 문서를 구성원들에게 재전송 하시겠습니까?')) {
          const data = {
            ctrtNo: props.contractKey.ctrtNo,
            ctrtOdr: props.contractKey.ctrtOdr,
            ctrtDivCd: props.contractKey.ctrtDivCd,
            reason: state.reason,
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            state.loading = true;

            await axios.post('/contract/reSend', data);

            await vm.$alert('재전송이 완료되었습니다.');

            rejectManageDialog.hide(true);
          } catch (ex) {
            console.log(ex);
          } finally {
            state.loading = false;
          }
        }
      },
    };

    watch(
      () => props.contractKey,
      newValue => {
        if (newValue === undefined) {
          state.rtrvlResn = {};
          state.retrnResn = {};

          return;
        }

        method.getData();
      }
    );

    return {
      Layout,
      state,
      ...method,
      ...rejectManageDialog.dialogRef,
      ...rejectManageDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog
    ref="rejectManageDialog"
    :title="Layout.title"
    :width="Layout.width"
    :height="Layout.height"
    @before-open="dialog_before_open"
  >
    <template #button-center>
      <iui-button @click="btnSendToLegacy_click">{{ state.legacyName }} 전송</iui-button>
      <iui-button v-if="state.canResend" @click="btnReSend_click">고객/{{ state.ccpyName }} 재전송</iui-button>
    </template>

    <!-- {{ Layout.height }} | {{ state.isRecovery }} || {{ state.isReject }} -->

    <iui-content-box :loading="state.loading">
      <iui-container type="table" header-width="120px">
        <i-row v-if="state.isRecovery">
          <i-col-header>회수 일시<br /></i-col-header>
          <i-col>
            <iui-textbox v-model="state.rtrvlResn.rtrvlDtStr" disable />
          </i-col>
        </i-row>
        <i-row v-if="state.isRecovery">
          <i-col-header>회수 사유</i-col-header>
          <i-col>
            <iui-textbox type="multi" v-model="state.rtrvlResn.rtrvlResnCn" height="100px" disable />
          </i-col>
        </i-row>
        <i-row v-if="state.isReject">
          <i-col-header>반송 회사명<br /></i-col-header>
          <i-col>
            <iui-textbox v-model="state.retrnResn.tradeName" disable />
          </i-col>
        </i-row>
        <i-row v-if="state.isReject">
          <i-col-header>반송 일시<br /></i-col-header>
          <i-col>
            <iui-textbox v-model="state.retrnResn.entryDateStr" disable />
          </i-col>
        </i-row>
        <i-row v-if="state.isReject">
          <i-col-header>반송 사유<br /></i-col-header>
          <i-col>
            <iui-textbox type="multi" v-model="state.retrnResn.retrnResnCn" disable height="100px" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header v-html="Layout.reasonTitle"></i-col-header>
          <i-col>
            <iui-textbox type="multi" v-model="state.reason" height="100px" />
          </i-col>
        </i-row>
      </iui-container>
    </iui-content-box>
  </iui-dialog>
</template>

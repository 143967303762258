<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <i-row>
        <i-col-header>회수 사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            name="rtrvlResnCn"
            :value="rtrvlResnCn"
            :enable="true"
            height="200px"
            required
            @change="rtrvlResnCn = $event.target.value"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: String,
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.addEvent({name: 'Recovery_onSave', func: this.onSave});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      rtrvlResnCn: '',
    };
  },
  mounted() {
    console.log('Recovery');
    console.log('ctrtNo', this.ctrtNo);
    console.log('ctrtOdr', this.ctrtOdr);
    console.log('ctrtDivCd', this.ctrtDivCd);
  },
  methods: {
    async onSave(callback) {
      console.log('onSave');
      if (!this.rtrvlResnCn.trim()) {
        this.$alert('회수사유를 입력해야합니다.');
        return;
      }

      var msg = '대리인/계약일자 변경 시 OKSS ”계약진행 목록”에서 수정 후 재전송 바랍니다.<br/>';
      msg += '계약 주요 정보 변경 시 [반송/회수관리]에서 [OKSS전송]을 클릭하시고 OKSS에서 진행하셔야 합니다.<br/>';
      msg += '회수 하시겠습니까?';

      if (await this.$confirm(msg)) {
        let param = {
          ctrtNo: this.ctrtNo,
          ctrtOdr: this.ctrtOdr,
          ctrtDivCd: this.ctrtDivCd,
          reason: this.rtrvlResnCn,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        const me = this;
        try {
          me.listBoxLoading = true;
          await axios.post('/contract/recovery', param);
          me.listBoxLoading = false;
          me.$alert('회수가 완료되었습니다.');
          callback({action: 'onSave'});
        } catch (ex) {
          me.listBoxLoading = false;
          me.$alert('회수에 실패하였습니다.' + e);
        }
      }
    },
  },
};
</script>

<style></style>

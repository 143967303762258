<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select-old :items="searchSeItems" :value.sync="searchSe" defaultCd="S"></iui-select-old>
      <iui-text :value.sync="searchUser" />
      <iui-button @click="selectGrpAuthUserList()">검색</iui-button>
    </template>
    <template v-slot:header-right></template>

    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    grpAuthPage: {
      // 그룹권한 페이지
      type: Boolean,
      default: true,
    },
    userAuthPage: {
      // 사용자권한 페이지
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchSeItems: [
        {text: '사용자ID', value: 'id'},
        {text: '이름', value: 'name'},
      ],
      searchUser: '',
      searchSe: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, {tabIndex: 'tabIndex', grpAuthInfo: 'grpAuthInfo', userAuthInfo: 'userAuthInfo'});
    $mapMutations(this, ['setUserAuthInfo']);
  },

  created() {
    this.addEvent([
      {name: 'selectGrpAuthUserList', func: this.selectGrpAuthUserList},
      {name: 'GrpUserList_getSaveJson', func: this.getSaveJson},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet() {
      const me = this;
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '선택',
            Type: 'Bool',
            Name: 'auth',
            Width: 60,
            CanEdit: 1,
            Visible: this.userAuthPage,
          },
          {
            Header: 'ID',
            Name: 'empNo',
            Type: 'Text',
            Align: 'Center',
            Width: 130,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '성명',
            Name: 'name',
            Type: 'Text',
            Align: 'Center',
            Width: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '부서명',
            Name: 'deptNm',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Name: 'pgmCd',
            Type: 'Text',
            Visible: 0,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      me.sheet = IBSheet.create({
        id: me.sheetId, // 시트 객체 ID
        el: me.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      me.sheet.bind('onFocus', e => {
        if (e.row.Kind == 'Data') {
          this.setUserAuthInfo(e.row);

          if (this.userAuthPage) return;
          if (this.tabIndex === 0) {
            this.callEvent({name: 'selectUserMenuAuthList', param: this.userAuthInfo.empNo});
          } else {
            this.callEvent({name: 'selectGroupList', param: this.userAuthInfo.empNo});
          }
        }
      });

      if (!this.userAuthPage) this.selectGrpAuthUserList();
    },

    selectGrpAuthUserList(grpAuth) {
      if (this.searchUser != '' && this.searchSe == '') {
        this.$alert('검색항목을 선택하세요');
        return;
      }
      if (!grpAuth) grpAuth = this.grpAuthInfo.grpAuth;
      axios
        .post('/grpAuth/selectGrpAuthUserList', {
          pgmCd: this.grpAuthInfo.pgmCd,
          grpAuth: grpAuth,
          searchSe: this.searchSe,
          name: this.searchUser,
        })
        .then(response => {
          if (response.status == 200) {
            this.sheet.loadSearchData(response.data, 0);
          }
        });
    },

    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>

/* MOD > 견적목록 > 상세 TAB */
<script>
import {reactive, watch, onMounted} from '@vue/composition-api';
import HistoryList from '@/view/common/components/HistoryList.vue';

const ibsheetOptions1 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ['구분', '구분'],
      Name: 'tradeTypeNm',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: 'tradeType',
      Name: 'tradeType',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'tradeNo',
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 90,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['상호(법인) / 이름(개인/개인사업자)', '상호(법인) / 이름(개인/개인사업자)'],
      Name: 'tradeName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: ['주소', '주소'],
      Name: 'address',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: ['담당자 연락처', '휴대폰'],
      Name: 'mphone',
      Type: 'Text',
      Width: 150,
      Align: 'Center',
    },
    {
      Header: ['담당자 연락처', '메일'],
      Name: 'email',
      Type: 'Text',
      Width: 180,
      Align: 'Center',
    },
  ],
};

const ibsheetOptions2 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: '용도/기종',
      Name: 'productName',
      Type: 'Text',
      Width: 150,
      Align: 'Center',
    },
    {
      Header: '규격',
      Name: 'modelType',
      Type: 'Text',
      Width: 200,
      Align: 'Center',
      FormulaRow: '합계',
    },
    {
      Header: '인승',
      Name: 'capacity',
      Type: 'Text',
      Width: 90,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: 'Door Type',
      Name: 'doorType',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '속도',
      Name: 'speed',
      Type: 'Text',
      Width: 100,
      Align: 'Right',
      Visible: 0,
    },
    {
      Header: '층수',
      Name: 'floor',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '대수',
      Name: 'qty',
      Type: 'Text',
      Width: 100,
      Align: 'Right',
    },
    {
      Header: '단가',
      Name: 'unitAmount',
      Type: 'Int',
      Width: 100,
      Format: '#,###',
      Align: 'Right',
    },
    {
      Header: '금액',
      Name: 'amount',
      FormulaRow: 'Sum',
      Type: 'Int',
      Width: 100,
      Format: '#,###',
      Align: 'Right',
    },
  ],
};

const ibsheetOptions3 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermName',
      Type: 'text',
      Width: 100,
      Align: 'Center',
      MinWidth: 100,
    },
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermDesc',
      Type: 'text',
      Width: 100,
      Align: 'Left',
      MinWidth: 100,
    },
    {
      Header: ['지급율', '지급율'],
      Name: 'paymentTermRate',
      Type: 'Float',
      Width: 100,
      Format: '#,###\\%',
      Align: 'Center',
    },
    {
      Header: ['지급금액', '지급금액'],
      Name: 'paymentTermTotAmt',
      Type: 'Int',
      Width: 150,
      ormat: '#,###',
      Align: 'Right',
    },
    {
      Header: ['지급방법', '지급방법'],
      Name: 'paymentTermMtd',
      Type: 'text',
      Width: 100,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

export default {
  components: {HistoryList},
  props: {
    estimateKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },
  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props, context) {
    const state = reactive({
      // internalContractKey: undefined,
      loading: false,

      // 1. 고객
      grid1: undefined,
      gridOptions1: ibsheetOptions1,
      //memberList: undefined,

      // memberList: [],
      memberInfo: {
        // tradeType: '고객', // 구분
        tradeTypeNm: '고객', // 구분
        businessNo: undefined, // 사업자(주민)번호
        tradeName: undefined, // 상호(이름)
        repntName: undefined, // 대표자
        address: undefined, // 주소
        mphone: undefined,
        email: undefined,
      },

      // 2. 견적 정보
      grid2: undefined,
      gridOptions2: ibsheetOptions2,
      //itemList: undefined,

      //3. 견적조건/특기사항
      estimateInfo: {
        defectInsurancePeriod: undefined, //하자보수
        freeRepairPeriod: undefined, //무상보수
        displayDeliveryDate: undefined, //납기
        remark: undefined, //비고,
        paymentTermCodeS1: undefined, // 계약금명
        paymentTermNameS1: undefined, // 계약금명
        paymentTermRateS1: undefined, // 계약금 지불조건%
        paymentTermDescS1: undefined, // 계약금 설명
        paymentTermNameS2: undefined, // 중도금명
        paymentTermRateS2: undefined, // 중도금 지불조건%
        paymentTermDescS2: undefined, // 중도금 설명
        paymentTermNameSs: undefined, // 잔금명
        paymentTermRateSs: undefined, // 잔금 지불조건%
        paymentTermDescSs: undefined, // 잔금 설명
      },

      // 4. 지급조건
      grid3: undefined,
      gridOptions3: ibsheetOptions3,
    });

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      onDataLoad() {
        //console.log('grid_onDataLoad');
      },
      onClick(e) {
        // Header 클릭
        if (e.row.id == 'Header') {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);
        state.memberInfo = rowValue;

        // state.estimateInfo = rowValue;
      },
    };

    state.gridOptions3.Events = {
      ...state.gridOptions3.Events,
      onDataLoad(e) {
        e.sheet.fitSize('paymentTermName');
        e.sheet.fitSize('paymentTermDesc');
      },
    };

    const method = {
      btn_click() {
        context.emit('change', true);
      },

      async getData() {
        state.loading = true;

        let param = {
          ctrtNo: props.estimateKey.ctrtNo,
          ctrtOdr: props.estimateKey.ctrtOdr,
          ctrtDivCd: props.estimateKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/mod/estimate/selectEstimateView', param);

          state.estimateInfo = response.data;

          context.emit('change', false);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },
    };

    watch(
      () => props.estimateKey,
      newValue => {
        state.grid1.removeAll();
        state.grid2.removeAll();

        state.memberInfo = {};
        state.estimateInfo = {}; // 견적조건/특기사항

        state.memberInfo.tradeTypeNm = '고객';
        // estimateKey 변경된 경우 처리
        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.estimateKey) {
        method.getData();
      }
    });

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <!-- <iui-content-box :loading="state.loading"> -->
  <iui-tab-group :loading="state.loading">
    <iui-tab title="고객">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.estimateInfo.memberList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="4" width="80px">{{ state.memberInfo.tradeTypeNm }}</i-col-header>
                <i-col-header>상호(이름)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.address" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="2">담당자 연락처</i-col-header>
                <!-- <i-col-header>휴대폰</i-col-header> -->
                <i-col>
                  <iui-textbox v-model="state.memberInfo.mphone" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.email" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="견적 정보">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid2"
                :ibsheet-options="state.gridOptions2"
                :ibsheet-data="state.estimateInfo.itemList"
              />
            </iui-content-box>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>

    <iui-tab title="지급 조건">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid3"
                :ibsheet-options="state.gridOptions3"
                :ibsheet-data="state.estimateInfo.paymentList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
        </i-row>
      </iui-container>
    </iui-tab>

    <iui-tab title="견적조건/특기사항">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header colspan="2">하자보수</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.estimateInfo.defectInsurancePeriod"
                    type="number"
                    prefix="설치완료 인도일로부터"
                    suffix="개월"
                    width="200px"
                    disable
                  />
                </i-col>
                <i-col-header colspan="2">무상보수</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.estimateInfo.freeRepairPeriod"
                    type="number"
                    prefix="설치완료 인도일로부터"
                    suffix="개월"
                    width="200px"
                    disable
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">납기</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.estimateInfo.displayDeliveryDate" disable />
                </i-col>
                <i-col colspan="3"> </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">비고</i-col-header>
                <i-col colspan="4">
                  <iui-textbox v-model="state.estimateInfo.remark" type="multi" height="200px" />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
          <i-spacer />
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :document-key="estimateKey" />
    </iui-tab>
  </iui-tab-group>
</template>

<style></style>

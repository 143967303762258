export const gridOption = {
  Cfg: {
    ReqStatusName: 'STATUS',
    FitWidth: true,
    IgnoreFocused: 1,
    //SearchMode: 0,
    //CustomScroll: 1,
    //Style: 'IBMT',
  },
  Def: {
    Row: {
      CanFormula: true,
      CalcOrder: 'cbxCanEdit,manageCdCanEdit', //필드명의 Formula 속성명 명시
    },
  },
  //틀고정 좌측 컬럼 설정
  LeftCols: [{Header: 'No.', Type: 'Int', Width: 60, Align: 'Center', Name: 'SEQ'}],
  Cols: [
    {
      Header: '선택',
      Type: 'Bool',
      Name: 'cbx',
      Align: 'Center',
      Width: 60,
      CanEditFormula: fr => !((fr.Row['manageCd'] == '1000' || fr.Row['manageCd'] == '2000') && fr.Row['verNo'] == '0'),
    },

    {
      Header: ['관리번호'],
      Type: 'Text',
      Name: 'manageCd',
      Align: 'Center',
      Format: '####',
      Size: '4',
      Width: 100,
      Required: 1,
      ResultMask: '^[A-Z0-9]{0,4}$' /* 영문(대/소문자), 숫자 4자리 */,
      ResultMessage: '영문(대문자), 숫자 조합으로 4자리까지만 입력가능합니다.',
      // CanEdit: 1,
      CanEditFormula: fr => !((fr.Row['manageCd'] == '1000' || fr.Row['manageCd'] == '2000') && fr.Row['verNo'] == '0'),
    },
    {
      Header: ['이력순번'],
      Type: 'Text',
      Name: 'verNo',
      CanEdit: 0,
      Width: 80,
    },
    {
      Header: ['사용여부'],
      Type: 'Enum',
      Name: 'applcYn',
      Enum: '|사용|미사용',
      EnumKeys: '|Y|N',
      Width: 100,
    },
    {
      Header: ['사용시작일자'],
      Type: 'Date',
      Name: 'applcDate',
      DataFormat: 'yyyyMMdd',
      Format: 'yyyy-MM-dd',
      Width: 120,
    },
    {
      Header: ['본부장성명'],
      Type: 'Text',
      Name: 'mngrNm',
      Align: 'Left',
      Size: '30',
      Required: 1,
      Width: 180,
    },
    {
      Header: ['사인이미지경로'],
      Type: 'Text',
      Name: 'mngrSignUrl',
      Align: 'Left',
      Size: '200',
      Required: 1,
      Width: 280,
    },
    {
      Header: ['변경사유'],
      Type: 'Text',
      Name: 'rmCn',
      Align: 'Left',
      Size: '50',
      Width: 200,
    },
    {
      Header: ['변경일자'],
      Type: 'Date',
      Name: 'chngDate',
      DataFormat: 'yyyyMMdd',
      Format: 'yyyy-MM-dd',
      CanEdit: 4,
      //RelWidth: 1,
      Width: 120,
    },
    {
      Header: ['변경자'],
      Type: 'Text',
      Name: 'chngEmpName',
      //RelWidth: 1,
      CanEdit: 0,
      Width: 100,
    },
  ],
  Events: {
    onDataLoad(e) {
      console.log('onDataLoad', e);
    },
  },
};

export default gridOption;

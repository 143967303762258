<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex" theme="bullet">
      <i-row height="370px" ref="srchList">
        <i-col>
          <iui-container type="css-flex" theme="bullet">
            <i-row height="37px" style="overflow:hidden" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>기간</i-col-header>
                    <i-col
                      ><span style="white-space:nowrap">
                        <iui-datepicker-old
                          name="entryDateStart"
                          suffix=" ~ "
                          @change="onSetSearchValue($event, 'entryDateStart')"
                          :value="search.entryDateStart"/>
                        &nbsp;<iui-datepicker-old
                          name="entryDateEnd"
                          @change="onSetSearchValue($event, 'entryDateEnd')"
                          :value="search.entryDateEnd"
                      /></span>
                    </i-col>
                    <i-col-header>서버</i-col-header>
                    <i-col>
                      <iui-text name="host" @change="onSetSearchValue($event, 'host')" @enter="onPreListSearch" />
                    </i-col>
                    <i-col-header>메시지</i-col-header>
                    <i-col>
                      <iui-text
                        name="logMssage"
                        @change="onSetSearchValue($event, 'logMssage')"
                        @enter="onPreListSearch"
                      />
                    </i-col>
                    <i-col-header>내용</i-col-header>
                    <i-col>
                      <iui-text name="logCn" @change="onSetSearchValue($event, 'logCn')" @enter="onPreListSearch" />
                    </i-col>
                    <i-col-header>처리여부</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="delYn"
                        :value="search.delYn"
                        :items="delYnArray"
                        @change="onSetSearchValue($event, 'delYn')"
                      ></iui-select-old>
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <i-row height="333px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    에러 로그 조회
                  </template>
                  <template v-slot:title-right>
                    <iui-button value="전체선택" @click="onSelectAll()" />
                    <iui-button value="완료처리" @click="onSave('Y')" />
                    <iui-button value="미완료처리" @click="onSave('N')" />
                  </template>
                  <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <iui-container type="table" :headerWidth="width200" style="height:100%">
            <i-row>
              <i-col-header>로그순번</i-col-header>
              <i-col>
                <iui-text :value="form.logSn" :readonly="true" width="100px" />
                <span style="text-align:left">{{ form.delYn }}</span>
              </i-col>
              <i-col-header>로그인ID</i-col-header>
              <i-col>
                <iui-text :value="form.entryEmpId" :readonly="true" width="100px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>클래스</i-col-header>
              <i-col>
                <iui-text :value="form.logClass" :readonly="true" width="100%" />
              </i-col>
              <i-col-header>일시</i-col-header>
              <i-col>
                <iui-text :value="form.entryDate" :readonly="true" width="100%" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>서버</i-col-header>
              <i-col>
                <iui-text :value="form.host" :readonly="true" width="100%" />
              </i-col>
              <i-col-header>경로</i-col-header>
              <i-col>
                <iui-text :value="form.path" :readonly="true" width="100%" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>메시지</i-col-header>
              <i-col colspan="3">
                <iui-text name="logMssage" :readonly="true" :value="form.logMssage" />
              </i-col>
            </i-row>
            <i-row style="height:100%">
              <i-col-header>내용</i-col-header>
              <i-col colspan="3">
                <iui-text name="logCn" type="multi" :value="form.logCn" />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  beforeCreate() {},
  async created() {
    this.setCmmBtns(this.cmmBtns);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        {name: '신규', class: 'b', callback: this.onAdd},
        //{name: '저장', class: 'b', callback: this.onSave},
        {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],

      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,

      search: {
        logMssage: '',
        host: '',
        logCn: '',
        entryDateStart: '',
        entryDateEnd: '',
        delYn: 'N',
      },

      delYnArray: [
        {text: '전체', value: ''},
        {text: '미처리', value: 'N'},
        {text: '처리완료', value: 'Y'},
      ],
      listBoxLoading: false,

      focusKeys: {},

      form: {
        logSn: 0,
        seCd: '',
        logClass: '',
        host: '',
        logMssage: '',
        logCn: '',
        entryEmpId: '',
        entryDate: null,
        delYn: 'Y',
      },
    };
  },
  mounted() {
    // 오늘자만 조회
    var d = new Date();
    d.setDate(d.getDate() - 7); // 일주일 전부터 조회
    var nowd = d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate() + '';
    this.search.entryDateStart = nowd;

    const OPT = {
      Cols: [
        {
          name: 'chk',
          label: '선택',
          width: 30,
          align: 'center',
          formatter: function() {
            return '<input type="checkBox" name="chkLog" style="display:block !important; margin: 0px auto;">';
          },
        },
        {name: 'logSn', label: '로그순번', width: 50, align: 'center'},
        {name: 'host', label: '서버', width: 70, align: 'center'},
        {name: 'logClass', label: '클래스', width: 100, align: 'left'},
        {name: 'path', label: '경로', width: 100, align: 'left'},
        {name: 'logMssage', label: '메시지', width: 250, align: 'left'},
        {
          name: 'delYn',
          label: '완료',
          formatter: function(v) {
            return v == 'N' ? '-' : '완료';
          },
          width: 50,
          align: 'center',
        },
        {name: 'entryEmpId', label: '사용자ID', width: 50, align: 'center'},
        {
          name: 'entryDate',
          label: '일시',
          width: 100,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: true, srcformat: 'ISO8601Long', newformat: 'Y-m-d H:i:s A'},
        },
        {name: 'logCn', label: 'logCn', width: 0, hidden: true},
      ],
    };

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 'auto',
      // width:800,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      autowidth: true,
      shrinkToFit: true,
      sortable: false,

      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        if (iCol != 1) {
          var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
          me.onSearchDetail(rowData);
        }
      },
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);

    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });

    this.onPreListSearch();
  },
  methods: {
    async onSearch() {
      console.log('onSearch');
      this.onPreListSearch();
    },
    async onAdd() {
      console.log('onAdd');
    },

    onSelectAll() {
      var objs = document.getElementsByName('chkLog');
      console.log('objs=' + objs);
      var sz = objs.length;
      if (sz < 1) return;
      var cnt = 0;
      for (var i = 0; i < sz; i++) {
        if (!objs[i].checked) cnt++;
      }
      var isChecked = cnt > 0;
      for (var j = 0; j < sz; j++) {
        objs[j].checked = isChecked;
      }
    },

    async onSave(delYn) {
      const me = this;
      var $grid = $('#' + me.sheetId);

      var ids = $grid.getDataIDs();

      var objs = document.getElementsByName('chkLog');
      var sz = objs.length;
      var logSns = [];
      for (var i = 0; i < sz; i++) {
        if (objs[i].checked) {
          var row = $grid.getRowData(ids[i]);
          logSns.push(row.logSn);
        }
      }
      if (logSns.length < 1) {
        alert('항목을 선택하세요');
        return;
      }

      var nm = delYn == 'Y' ? '완료처리' : '미완료처리';
      if (!confirm(nm + ' 하시겠습니까 ?')) {
        return;
      }

      var param = {
        chngPgmId: me.$store.getters['mdi/currentUrls'].url,
        delYn: delYn,
        logSns: logSns,
      };

      await axios.post('/common/systemLog/saveSystemLog', param);
      this.onListSearch();
    },

    onReset() {
      this.form.logSn = 0;

      this.form.seCd = '';
      this.form.logClass = '';
      this.form.logMssage = '';
      this.form.host = '';
      this.form.logCn = '';
      this.form.path = '';
      this.form.ntcnCntcNo = '';
      this.form.entryEmpId = '';
      this.form.delYn = 'N';
      this.form.entryDate = null;

      console.log(this.form);
    },
    async onDelete() {},
    async onPrint() {
      console.log('onPrint');
      this.$store.dispatch('mdi/doPrint');
    },
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, true);
      //this.showSrchShowBtn();
    },
    onSetSearchValue(obj, name) {
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : 'N') : obj.target.value;
      }
      this.search[key] = value;
    },
    onSetValue(obj, name) {
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : 'N') : obj.target.value;
      }
      this.ntcnForm[key] = value;
    },
    onPreListSearch() {
      this.focusKeys.ntcnFormsn = '0';
      this.onListSearch();
    },
    async onListSearch() {
      this.listBoxLoading = true;

      console.log(this.search);

      let param = {
        ...this.search,
      };

      let response = null;
      try {
        response = await axios.post('/common/systemLog/selectSystemLogList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');
        this.onResize();
        if (response.data.length == 0) {
          this.onReset();
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },

    async onSearchDetail(rowData) {
      let param = {
        logSn: rowData.logSn,
      };
      let response = await axios.post('/common/systemLog/selectSystemLog', param);

      this.form.logSn = response.data.logSn;
      this.form.seCd = response.data.seCd;
      this.form.logClass = response.data.logClass;
      this.form.host = response.data.host;
      this.form.logMssage = response.data.logMssage;
      this.form.logCn = response.data.logCn;
      this.form.path = response.data.path;
      this.form.entryEmpId = response.data.entryEmpId;
      this.form.entryDate = rowData.entryDate;
      this.form.delYn = rowData.delYn;
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            >
            </iframe>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowSign" height="50px">
        <i-col-header>서명방법</i-col-header>
        <i-col>
          <iui-select-old
            name="signMthCd"
            :items="arrSignMthCd"
            :value="signInfo.signMthCd"
            :enable="true"
            required
            @change="onSetSignMthCd"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    // OZParam: {
    //     type: Object,
    // },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // callBack 필요 할 경우 set
    this.addEvent({name: 'RequestViewerPopup_Sign', func: this.onPopupConfirmRequestViewSign});
    var me = this;

    //OZ Viewer 에서 message 를 호출.
    window.addEventListener('message', me.reqSetOzParam, false);
  },
  data() {
    return {
      listBoxLoading: false,
      urlParam: `${process.env.VUE_APP_OZ_VIEWER_URL}`,

      isReject: false,
      isSign: false,
      otisPage: false,

      arrSignMthCd: [
        {text: '선택', value: ''},
        // {text: '공동인증서', value: '10'},
        //{text: '디지털서명', value: '30'},
      ],

      contractInfo: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        ctrtSttusCd: '',
        histSn: '',
        ctrtOrginlCn: '',
        ctrtOrginlHashcd: '',
        ctrtOrginlFileNo: '',
      },

      signerInfoVo: {
        tradeNo: '',
        tradeSuffix: '',
        personId: '',
        businessNo: '',
        mphone: '',
        userNm: '',
        canSign: false,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: '',
        reqstdocDivCd: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        //signDt: '', // 서버에서 reflect 할때 오류나서 주석처리
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
      },

      requestInfo: {
        reqstdocDivCd: '', // 신청서 구분 코드
        reqstdocNo: '',
        reqstdocOdr: 0,
        requestOrginlHashcd: '',
        progrsSttusCd: '',
        requestOriginFileNo: '',
        checkContDate: '', // 계약일
        agreYn: '',
        infoUseAgreYn: '', // CMS - 정보 이용 동의 여부
        infoProvdAgreYn: '', // CMS - 정보 제공 동의 여부
      },

      isVerifyPASS: false,
      isReqUpdate: false, // 신청서 상태업데이트 여부
      isProcessing: false, // 전자서명 무한클릭 방지
    };
  },
  mounted() {
    this.$secuKit.init();
    this.$secuKit.checkReady();
    console.log('checkReady = ' + this.$secuKit.secuKitReady);

    console.log('this.paramObj : ', this.paramObj);

    this.requestInfo.reqstdocDivCd = this.paramObj.reqstdocDivCd;
    this.requestInfo.reqstdocNo = this.paramObj.reqstdocNo;
    this.requestInfo.reqstdocOdr = this.paramObj.reqstdocOdr;
    this.requestInfo.progrsSttusCd = this.paramObj.progrsSttusCd;
    this.requestInfo.checkContDate = this.paramObj.checkContDate;
    this.otisPage = this.paramObj.otisPage;

    this.onSearch();
  },
  methods: {
    onSetSignMthCd(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;

      console.log('mth target: ', target);
      console.log('mth value: ', value);

      if (this.paramObj.docCat1 != '000') {
        if (value == '30' || value == '10') {
          // 디지털 서명 + 공동인증서
          this.paramObj.OZParam.OZFormParam.signable = 'Y';
        } else {
          this.paramObj.OZParam.OZFormParam.signable = 'N';
        }
        //OZ Viewer 에서 message 를 호출할 Event 등록.
        window.addEventListener('message', this.reqSetOzParam, false);
        //OZ Reload
        document.getElementById('ozFrame').contentWindow.location.reload(true);
      }
      this.signInfo[target] = value;
      this.paramObj.OZParam.OZFormParam[target] = value; // 21.03.18 추가 (서명방법에 따른 서명패드 활성화)
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.paramObj.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.paramObj.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    async onSearch() {
      // 계약 정보 조회
      let param = {
        ctrtNo: this.paramObj.ctrtNo,
        ctrtOdr: '00',
        ctrtDivCd: this.paramObj.ctrtDivCd,
      };

      let response = '';
      if (this.paramObj.ctrtDivCd == 'TC') {
        response = await axios.post('/tcontract/selectContractInfo', param);
      } else {
        response = await axios.post('/ocontract/selectContractInfo', param);
      }

      console.log(response);
      this.contractInfo = response.data;

      // 신청서 번호로 파일번호 , 해쉬값 가져오기
      let paramF = {
        reqstdocNo: this.requestInfo.reqstdocNo,
        reqstdocDivCd: this.requestInfo.reqstdocDivCd,
      };

      let responseF = await axios.post('/cmsReq/selectReqFileInfo', paramF);

      console.log('responseF : ', responseF);

      this.requestInfo.requestOriginFileNo = responseF.data.requestOriginFileNo;
      this.requestInfo.requestOrginlHashcd = responseF.data.requestOrginlHashcd;

      let param2 = {
        ctrtNo: this.paramObj.ctrtNo,
        ctrtOdr: '00',
        ctrtDivCd: this.paramObj.ctrtDivCd,
        tradeNo: this.userInfo.tradeNo,
      };

      console.log('param2 :', param2);

      let response2 = '';
      if (this.paramObj.ctrtDivCd == 'TC') {
        response2 = await axios.post('/tcontract/selectSignerInfo', param);
      } else {
        response2 = await axios.post('/ocontract/selectSignerInfo', param);
      }

      console.log('response2 : ', response2);

      console.log('selectSignerInfo=' + JSON.stringify(response2.data));
      this.signerInfoVo = response2.data;

      console.log('this.signerInfoVo.tradeOrder', this.signerInfoVo.tradeOrder);

      this.signInfo.ctrtNo = this.contractInfo.ctrtNo;
      this.signInfo.ctrtOdr = this.contractInfo.ctrtOdr;
      this.signInfo.ctrtDivCd = this.contractInfo.ctrtDivCd;
      this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
      this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
      this.signInfo.canSign = this.signerInfoVo.canSign;
      this.signInfo.corporate = this.signerInfoVo.corporate;
      this.signInfo.customer = this.signerInfoVo.customer;
      //this.signInfo.buyagnt     = this.signerInfoVo.buyagnt; // 대리인 정보 필요없음 (고객만 로그인 가능)
      this.signInfo.personal = this.signerInfoVo.personal;
      this.signInfo.personId = this.signerInfoVo.personId;
      this.signInfo.businessNo = this.signerInfoVo.businessNo;
      //this.signInfo.reqstdocOdr = this.requestInfo.reqstdocOdr; // 신청서차수 반영

      console.log('before', this.signInfo.agentIdntyImageCn);
      this.signInfo.retrnResnCn = '';
      this.signInfo.agentIdntyImageCn = '';
      console.log('after', this.signInfo.agentIdntyImageCn);

      console.log('signInfo ?? ', this.signInfo);

      // 신청서가 작성중 상태 , 첫번째 고객일 경우에만 서명 가능 할 수 있도록 설정
      if (
        this.requestInfo.progrsSttusCd == '10' &&
        eval(this.signerInfoVo.customer) == true &&
        this.signerInfoVo.tradeOrder == 'A'
      ) {
        this.paramObj.OZParam.OZFormParam.signable = 'Y'; // 서명 가능 여부 설정 (기본 default 인자값 => N)

        // 대리인은 로그인 할 수 없으므로 공동인증서 서명 가능.
        this.arrSignMthCd.push({text: '공동인증서', value: '10'});
        // 비표준 계약이 아니면서 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능.
        if (this.paramObj.docCat1 != '000' && eval(this.signInfo.corporate) == false) {
          this.arrSignMthCd.push({text: '디지털서명', value: '30'});
        }
        if (this.signInfo.signMthCd == '') {
          // 디지털 서명 또는 공동인증서 서명만 가능 할 때 자동으로 선택
          if (this.arrSignMthCd.length < 3) {
            this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
          }
        }
      }
    },
    // ozForm 전자서명 클릭
    async onPopupConfirmRequestViewSign(callback) {
      if (this.isReject) this.isReject = false;
      if (!this.isSign) this.isSign = true;

      if (!this.signInfo.signMthCd.trim()) {
        this.$alert('서명방법을 선택해야합니다.');
        return;
      }

      if (eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
        this.$alert('법인 사업자는 서명방법을 디지털서명을 선택할 수 없습니다.');
        this.signInfo.signMthCd = '';
        return;
      }

      // 전자서명 버튼생성 처리를 index.vue 에서 처리하여 ozVal 리턴시에는 조건 넣지않음
      var ozVal = '';
      //OZ Viewer 의 입력값들를 리턴받음.
      ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
      console.log('onPopupConfirmContractViewSign input ', ozVal);
      //this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];
      //console.log("formId :",ozVal[ozVal.signedFormId]);

      if (ozVal == null || ozVal == '') {
        //this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
        return;
      }

      if (this.isProcessing) {
        alert('신청서의 전자서명을 진행하는 중입니다. 잠시만 기다려 주세요.');
        return;
      }

      this.isProcessing = true;
      if (this.signInfo.signMthCd == '10') {
        console.log('this.contractInfo.ctrtOrginlHashcd', this.contractInfo.ctrtOrginlHashcd);
        console.log('this.requestInfo.requestOrginlHashcd', this.requestInfo.requestOrginlHashcd);

        let checkReady = this.$secuKit.secuKitReady;
        console.log('checkReady = ' + checkReady);

        if (!checkReady) {
          this.isProcessing = false;
          this.$alert('공동인증서 툴킷 로딩이 완료되지 못했습니다.');
          return;
        }

        if (!(await this.$confirm('공동인증서로 전자서명을 하시겠습니까?'))) return;

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.requestInfo.requestOrginlHashcd, // 해시값 설정 필요
          certID: certID,
        };

        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.signInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

        console.log('PKCS7SignedMessage', this.signInfo.pkcs7SignedMessage);

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.signInfo.vidRandom = r3.vidRandom;
        this.signInfo.signerDn = r3.userDN;

        console.log('vidRandom', this.signInfo.vidRandom);
        console.log('signerDn', this.signInfo.signerDn);

        let data3 = {
          certType: 'SignCert',
          sourceString: r3.vidRandom,
          certID: certID,
        };

        const r4 = await this.$secuKit.invoke('generatePKCS7SignedData', data3);
        console.log(r4);

        this.signInfo.vidRandom = r4.generatePKCS7SignedData;

        if (this.signInfo.pkcs7SignedMessage == '') {
          this.isProcessing = false;
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }

        let data4 = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.signInfo.pkcs7SignedMessage)),
        };

        const r5 = await this.$secuKit.verifyPKCS7SignedData(data4);
        console.log(r5);

        console.log('VerifyPKCS7SignedData', r5.verifyPKCS7SignedData);

        if (this.requestInfo.requestOrginlHashcd != r5.verifyPKCS7SignedData) {
          this.isProcessing = false;
          this.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }
      } else if (this.signInfo.signMthCd == '20') {
        // 모바일 인증서 서명은 웹에서는 해당 없음.
      } else if (this.signInfo.signMthCd == '30') {
        // 디지털서명은 PASS 인증을 수행하고 통과 해야 디지털 서명이 가능하다.
        if (!this.isVerifyPASS) {
          this.signInfo.passNo = 0;
          console.log('this.signerInfoVo.userNm', this.signerInfoVo.userNm);
          console.log('this.signerInfoVo.mphone', this.signerInfoVo.mphone);
          this.isProcessing = false;
          var passResult = await this.$PASSCertification(this.signerInfoVo.userNm, this.signerInfoVo.mphone);
          console.log(passResult);
          if (passResult.result) {
            this.isVerifyPASS = passResult.result;
            this.signInfo.passNo = passResult.data.passNo;
          } else {
            this.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다.');
            return;
          }
        }

        this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];

        if (this.signInfo.agentIdntyImageCn == undefined) {
          this.signInfo.agentIdntyImageCn = '';
          this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
          return;
        }

        if (!this.signInfo.agentIdntyImageCn.trim()) {
          this.$alert('신청서의 서명란에 디지털서명을 해야합니다.');
          return;
        }
      }

      this.signInfo.signerSeCd = '10'; // 본인 : 10, 대리인 : 20.

      // 전자서명시에 pdf 동의 정보 표시위해 상태 먼저 update
      this.requestInfo.agreYn = ozVal.agreYn;
      this.requestInfo.infoUseAgreYn = ozVal.infoUseAgreYn;
      this.requestInfo.infoProvdAgreYn = ozVal.infoProvdAgreYn;

      let param = {
        signVo: this.signInfo,
        requestInfo: this.requestInfo,
        pgmCd: this.userInfo.pgmCd,
        chngPgmId: 'RequestViewer',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/cmsReq/requestVerifyPKCS7SignedData', param)
        .then(response => {
          me.listBoxLoading = false;
          me.isProcessing = false;
          console.log('서명완료 : ', response);
          //me.$alert('전자서명 및 신청이 완료되었습니다.');
          callback({action: 'onPopupConfirmRequestViewSign', ozVal});
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.isProcessing = false;
          me.$alert('서명 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
  },
  computed: {
    isShowSign() {
      if (this.requestInfo.progrsSttusCd == '10' && !this.otisPage) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>

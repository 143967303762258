<template>
  <iui-container type="css-flex">
    <i-row height="320px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>사원정보</template>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header required>id</i-col-header>
              <i-col>
                <iui-text
                  type="text"
                  :value="detailInfo.empNo"
                  @change="setDetailInfo({empNo: $event.target.value})"
                  :enable="isNew"
                  width="150px"
                  max-length="8"
                />
                <iui-button value="중복확인" @click="onDupEmpNo" v-if="isNew" />
                <label>영문숫자(5~8)</label>
              </i-col>
              <i-col-header v-if="userInfo.frmGb == '000001'">근무종료여부</i-col-header>
              <i-col v-if="userInfo.frmGb == '000001'">
                <iui-checkbox-group-old
                  p-code="0050"
                  @change="setDetailInfo({labtClose: $event.target.checked ? $event.target.value : ''})"
                  :checkedValues="[detailInfo.labtClose]"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header required>성명</i-col-header>
              <i-col>
                <iui-text
                  type="text"
                  :value="detailInfo.name"
                  @change="setDetailInfo({name: $event.target.value})"
                  max-length="50"
                  required
                  error-message="필수항목"
                  width="150px"
                />
              </i-col>
              <i-col-header v-if="userInfo.frmGb == '000001'" required>주민번호</i-col-header>
              <i-col v-if="userInfo.frmGb == '000001'">
                <iui-text
                  :value="detailInfo.regiNo1"
                  @change="setDetailInfo({regiNo1: $event.target.value})"
                  max-length="6"
                  required
                  error-message="필수항목"
                  width="70px"
                />
                <iui-text
                  :value="detailInfo.regiNo2"
                  @change="setDetailInfo({regiNo2: $event.target.value})"
                  :prefix="'-'"
                  max-length="7"
                  required
                  error-message="필수항목"
                  width="80px"
                />
              </i-col>
            </i-row>
            <i-row v-if="userInfo.frmGb == '000001'">
              <i-col-header required>근무처</i-col-header>
              <i-col>
                <iui-select-old
                  p-code="0273"
                  :value="detailInfo.laborLoc"
                  @change="setDetailInfo({laborLoc: $event.target.value})"
                  defaultCd="S"
                  required
                />
              </i-col>
              <i-col-header required>근로자구분</i-col-header>
              <i-col>
                <iui-select-old p-code="0045" :value="detailInfo.laborDs" @change="onLaborDs" required defaultCd="S" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header v-if="userInfo.frmGb == '000001'">소속</i-col-header>
              <i-col-header v-else>부서</i-col-header>
              <i-col>
                <iui-text width="150px" :value="detailInfo.blnDepNm" :readonly="true" />
                <button
                  class="search-button"
                  v-if="detailInfo.laborDs != '000002' && detailInfo.laborDs != '000003'"
                  @click="onBlnDepNo"
                >
                  검색
                </button>
              </i-col>
              <i-col-header v-if="userInfo.frmGb == '000001'">담당업무</i-col-header>
              <i-col v-if="userInfo.frmGb == '000001'">
                <iui-select-old
                  p-code="0075"
                  :value="detailInfo.wbusiness"
                  @change="setDetailInfo({wbusiness: $event.target.value})"
                  defaultCd="S"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>직책</i-col-header>
              <i-col>
                <iui-select-old
                  p-code="0278"
                  :value="detailInfo.dtsNo"
                  @change="setDetailInfo({dtsNo: $event.target.value})"
                  defaultCd="S"
                />
              </i-col>
              <i-col-header v-if="userInfo.frmGb == '000001'">직급</i-col-header>
              <i-col v-if="userInfo.frmGb == '000001'">
                <iui-select-old
                  p-code="0389"
                  :value="detailInfo.rollUp"
                  @change="setDetailInfo({rollUp: $event.target.value})"
                  defaultCd="S"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header v-if="userInfo.frmGb != '000001'">직급</i-col-header>
              <i-col v-if="userInfo.frmGb != '000001'">
                <iui-select-old
                  p-code="0389"
                  :value="detailInfo.rollUp"
                  @change="setDetailInfo({rollUp: $event.target.value})"
                  defaultCd="S"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>전화번호</i-col-header>
              <i-col>
                <iui-text
                  :value="detailInfo.tlno"
                  @change="setDetailInfo({tlno: $event.target.value})"
                  width="150px"
                  max-length="20"
                />
              </i-col>
              <i-col-header v-if="userInfo.frmGb == '000001'">팩스번호</i-col-header>
              <i-col v-if="userInfo.frmGb == '000001'">
                <iui-text
                  :value="detailInfo.rpsvFaxno"
                  @change="setDetailInfo({rpsvFaxno: $event.target.value})"
                  width="150px"
                  max-length="20"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>휴대폰번호</i-col-header>
              <i-col>
                <iui-text
                  max-length="20"
                  :value="detailInfo.mobileTlno"
                  @change="setDetailInfo({mobileTlno: $event.target.value})"
                  width="150px"
                />
                <iui-checkbox-group-old
                  :items="[{label: 'SMS수신동의', value: '000001'}]"
                  @change="setDetailInfo({useSms: $event.target.checked ? $event.target.value : ''})"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header v-if="userInfo.frmGb != '000001'">팩스번호</i-col-header>
              <i-col v-if="userInfo.frmGb != '000001'">
                <iui-text
                  :value="detailInfo.rpsvFaxno"
                  @change="setDetailInfo({rpsvFaxno: $event.target.value})"
                  width="150px"
                  max-length="20"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>이메일</i-col-header>
              <i-col colspan="2">
                <iui-text
                  width="150px"
                  max-length="50"
                  :value="detailInfo.email1"
                  @change="setDetailInfo({email1: $event.target.value})"
                />
                <div class="mr5">@</div>
                <iui-select-old p-code="0275" :value="email2" @change="setDetailInfo({email2: $event.target.value})" />
                <iui-text
                  width="150px"
                  max-length="50"
                  :value="detailInfo.email2 == '000099' ? '' : detailInfo.email2"
                  @change="setDetailInfo({email2: $event.target.value})"
                  v-if="!detailInfo.email2 || detailInfo.email2.indexOf('0000') == -1 || detailInfo.email2 == '000099'"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col colspan="2">
                <iui-searchbox
                  :type="'addr'"
                  name="postNo"
                  :idValue="detailInfo.postNo"
                  :nameValue="detailInfo.adr1"
                  nameEleName="adr1"
                  :callback="getAddr"
                ></iui-searchbox>
                <iui-text :value="detailInfo.adr2" @change="setDetailInfo({adr2: $event.target.value})" width="200px" />
              </i-col>
            </i-row>
            <i-row v-if="userInfo.frmGb == '000001'">
              <i-col colspan="4">
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>거래은행</i-col-header>
                    <i-col>
                      <iui-select-old
                        p-code="0145"
                        :value="detailInfo.bankCode"
                        @change="setDetailInfo({bankCode: $event.target.value})"
                        defaultCd="S"
                      />
                    </i-col>
                    <i-col-header>계좌번호</i-col-header>
                    <i-col>
                      <iui-text
                        max-length="20"
                        :value="detailInfo.depositNo"
                        @change="setDetailInfo({depositNo: $event.target.value})"
                        width="150px"
                      />
                    </i-col>
                    <i-col-header>예금주</i-col-header>
                    <i-col>
                      <iui-text
                        max-length="16"
                        :value="detailInfo.depositor"
                        @change="setDetailInfo({depositor: $event.target.value})"
                        width="150px"
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="100px" v-if="userInfo.frmGb == '000001'">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>노무정보</template>
          <iui-container type="css-flex">
            <i-row>
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>단가구분</i-col-header>
                    <i-col>
                      <iui-select-old
                        p-code="0060"
                        :value="detailInfo.priceLGb"
                        @change="setDetailInfo({priceLGb: $event.target.value})"
                        defaultCd="S"
                      />
                    </i-col>
                    <i-col-header>노무단가</i-col-header>
                    <i-col>
                      <iui-text
                        max-length="10"
                        :value="detailInfo.priceLAmt"
                        @change="setDetailInfo({priceLAmt: $event.target.value})"
                        width="150px"
                      />
                    </i-col>
                    <i-col-header>노무직종</i-col-header>
                    <i-col>
                      <iui-select-old
                        p-code="0065"
                        :value="detailInfo.jkdNo"
                        @change="setDetailInfo({jkdNo: $event.target.value})"
                        defaultCd="S"
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>국민연금제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0035"
                        @change="setDetailInfo({pensionDs: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.pensionDs]"
                      />
                    </i-col>
                    <i-col-header>건강보험제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0020"
                        @change="setDetailInfo({healthInsurDs: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.healthInsurDs]"
                      />
                    </i-col>
                    <i-col-header width="137px">장기요양30%경감대상</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0175"
                        @change="setDetailInfo({healthInsurMDs: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.healthInsurMDs]"
                      />
                    </i-col>
                    <i-col-header>고용보험제외</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0025"
                        @change="setDetailInfo({employInsurDs: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.employInsurDs]"
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="150px" v-if="userInfo.frmGb == '000001' && detailInfo.laborLoc == '000002'">
      <i-col>
        <InputPrj />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>시스템정보</template>
          <iui-container type="css-flex">
            <i-row>
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>시스템사용여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0100"
                        @change="setDetailInfo({useDs: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.useDs]"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>관리자여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        p-code="0100"
                        @change="setDetailInfo({useMst: $event.target.checked ? $event.target.value : ''})"
                        :checkedValues="[detailInfo.useMst]"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>비밀번호</i-col-header>
                    <i-col>
                      <iui-text
                        type="password"
                        max-length="12"
                        :value="detailInfo.pwd"
                        @change="setDetailInfo({pwd: $event.target.value})"
                        width="150px"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>비밀번호확인</i-col-header>
                    <i-col>
                      <iui-text
                        type="password"
                        max-length="12"
                        :value="detailInfo.pwd2"
                        @change="setDetailInfo({pwd2: $event.target.value})"
                        width="150px"
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>사진 및 싸인정보</template>
          <iui-container type="css-flex">
            <i-row>
              <i-col>
                <pmis-image-file
                  title="사진"
                  :allowAdd="true"
                  :allowDelete="true"
                  :saveButtonVisible="!status.isNew"
                  :company-code="detailInfo.pgmCd"
                  :file-number.sync="phoFlNo"
                  :save.sync="imageSave1"
                  @save-complete="setPhoFlNo"
                  @delete-complete="delPhoFlNo"
                />
              </i-col>
              <i-col>
                <pmis-image-file
                  title="싸인"
                  :allowAdd="true"
                  :allowDelete="true"
                  :saveButtonVisible="!status.isNew"
                  :company-code="detailInfo.pgmCd"
                  :file-number.sync="sgntFlNo"
                  :save.sync="imageSave2"
                  @save-complete="setSgntFlNo"
                  @delete-complete="delSgntFlNo"
                />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>

    <iui-modal-old name="deptListPopup" title="부서선택" :btns="modalBtn" width="800px" height="600px">
      <deptList :isPopup="true" />
    </iui-modal-old>
  </iui-container>
</template>

<script>
import InputPrj from './InputPrj.vue';
import deptList from '@/view/sysManage/userManage/deptRegist/components/deptList';
export default {
  components: {InputPrj, deptList},

  data() {
    return {
      email2: '',
      modalBtn: [{name: '확인', callback: this.onConfirm}],
      phoFlNo: 0,
      sgntFlNo: 0,
      imageSave1: false,
      imageSave2: false,
    };
  },

  watch: {
    detailInfo: {
      handler(val, oldVal) {
        if (val.email2) this.email2 = val.email2;
        else this.email2 = '000099';
        if (val.laborDs && val.laborDs != oldVal.laborDs) {
          this.setDetailInfo({blnDepNo: '', blnDepNm: ''});
        }
        this.phoFlNo = val.phoFlNo;
        this.sgntFlNo = val.sgntFlNo;
      },
      deep: true,
    },
    imageSave: {
      handler(val) {
        this.imageSave1 = val;
      },
    },
    imageSave1: {
      handler(val) {
        if (!val) {
          this.imageSave2 = !val;
        }
        this.setFileFlag({phoFl: val});
      },
    },
    imageSave2: {
      handler(val) {
        this.setFileFlag({sgntFl: val});
      },
    },
  },

  created() {
    this.addEvent([{name: 'WorkerInfo_search', func: this.onSearch}]);
  },
  beforeCreate() {
    $mapGetters(this, ['keyInfo', 'detailInfo', 'status', 'dupEmpNoStatus', 'searchInfo', 'imageSave', 'subConInfo']);
    $mapMutations(this, ['setDetailInfo', 'setFocusKeyCode', 'setDupEmpNoStatus', 'setFileFlag', 'setSubConInfo']);
  },
  methods: {
    async onSearch() {
      let response = await axios.post('/worker/selectWorkerInfo', this.keyInfo);
      this.setDetailInfo({...response.data, laborDsOrg: response.data.laborDs, cud: 2});
      this.callEvent({name: 'InputPjt_search'});
    },
    setValue(key, e) {
      let payload = {};
      payload[key] = e.target.type == 'checkbox' ? (e.target.checked ? e.target.value : '') : e.target.value;
      this.setDetailInfo(payload);
    },
    getAddr(data) {
      this.setDetailInfo({
        postNo: data.zipNo,
        adr1: `${data.roadAddrPart1} ${data.roadAddrPart2}`,
        adr2: data.addrDetail,
      });
    },
    image_save_complete() {
      this.onSavePhotoFileNo();
    },
    image_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
    image_delete_complete() {
      this.setDetailInfo({phoFlNo: 0});
      this.onSavePhotoFileNo();
    },
    image_delete_error() {
      this.$alert('파일 삭제를 실패했습니다.');
    },
    async onSavePhotoFileNo() {
      let pgmCd = this.detailInfo.pgmCd;
      let empNo = this.detailInfo.empNo;
      let phoFlNo = this.detailInfo.phoFlNo;
      let sgntFlNo = this.detailInfo.sgntFlNo;
      let response = await axios
        .post('/worker/savePhotoFileNo', {pgmCd, empNo, phoFlNo, sgntFlNo})
        .catch(e => console.error('저장을 실패하였습니다. : ', e));

      if (response.status == 200) {
        this.setFocusKeyCode(response.data);
        this.callEvent({name: 'selectWorkerList'});
      } else {
        this.$alert('저장을 실패하였습니다.');
      }
    },
    onLaborDs(e) {
      this.setDetailInfo({laborDs: e.target.value, blnDepNo: '', blnDepNm: ''});
    },

    onBlnDepNo() {
      if (this.detailInfo.laborDs == '000001' || this.userInfo.frmGb != '000001') {
        //정직/부서
        this.$modal.show('deptListPopup');
      } else if (this.detailInfo.laborDs == '000004') {
        // if (this.status.isChangedLaborDs) {
        //   this.$alert('근로자구분이 변경되었습니다.<br/>변경된 값을 저장한 후에 진행하시기 바랍니다.');
        //   return false;
        // }
        // 작업반
        this.$modal.show('workGroupModal');
      } else if (this.detailInfo.laborDs == '000002' || this.detailInfo.laborDs == '000003') {
        this.$alert('입력할 수 없습니다.');
      }
    },
    onConfirm() {
      if (this.detailInfo.laborDs == '000001' || this.userInfo.frmGb != '000001') {
        //정직/부서
        this.callEvent({
          name: 'onFocusedDeptListRowData',
          param: data => {
            this.setDetailInfo({blnDepNo: data.fldrNo, blnDepNm: data.fldrNm});
            this.$modal.hide('deptListPopup');
          },
        });
      } else if (this.detailInfo.laborDs == '000004') {
        // 작업반
        this.callEvent({
          name: 'Sub_ContractList_confirm_popup',
          param: data => {
            this.setDetailInfo({blnDepNo: data[0].poNo, blnDepNm: data[0].cusNm});
            this.setSubConInfo({
              prjCd: data[0].spotDeptcd,
              prjNm: data[0].spotDeptnm,
              subCode: data[0].cusCd,
              subCodeName: data[0].cusNm,
            });
            this.$modal.hide('workGroupModal');
            console.log('onPopupConfirm > data', data);
          },
        });
        this.callEvent({name: 'addRowInputPrj'});
      } else if (this.detailInfo.laborDs == '000005') {
        // 노무팀
        this.callEvent({
          name: 'onFocusedLbrcRowData',
          param: data => {
            this.setDetailInfo({blnDepNo: data.labCode, blnDepNm: data.labName});
            this.$modal.hide('lbrcTeamListPopup');
          },
        });
      }
    },

    onDupEmpNo() {
      if (!(this.detailInfo.empNo.length >= 5 && this.detailInfo.empNo.length <= 8)) {
        this.$alert('ID는 5 ~ 8 자리로 입력해주세요.');
        return;
      }

      var regType1 = /^[A-Za-z0-9+]*$/;
      if (!regType1.test(this.detailInfo.empNo)) {
        this.$alert('영문 또는 숫자만 입력 가능합니다.');
        return;
      }

      axios
        .post('/worker/selectWorkerExistEmpNo', {pgmCd: this.searchInfo.pgmCd, empNo: this.detailInfo.empNo})
        .then(response => {
          if (response.status == 200) {
            if (response.data) {
              this.$alert('사용중인 ID입니다.');
              this.setDupEmpNoStatus(2);
            } else {
              this.$alert('사용할 수 있는 ID 입니다.');
              this.setDupEmpNoStatus(1);
            }
          }
        });
    },
    setPhoFlNo() {
      if (this.phoFlNo) {
        this.setDetailInfo({phoFlNo: this.phoFlNo});
        this.onSavePhotoFileNo();
      }
    },
    setSgntFlNo() {
      if (this.sgntFlNo) {
        this.setDetailInfo({sgntFlNo: this.sgntFlNo});
        this.onSavePhotoFileNo();
      }
    },
    delSgntFlNo() {
      this.setDetailInfo({sgntFlNo: 0});
      if (!this.isNew) {
        this.onSavePhotoFileNo();
      }
    },
    delPhoFlNo() {
      this.setDetailInfo({phoFlNo: 0});
      if (!this.isNew) {
        this.onSavePhotoFileNo();
      }
    },
  },
  computed: {
    isNew() {
      return this.detailInfo.cud == 1;
    },
  },
};
</script>

<style scoped></style>

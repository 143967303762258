import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import './param';
import './plugins/axios';
import PassPlugin from './plugins/pass';
import VueClipboard from 'vue-clipboard2';
import './plugins/popup';

import App from '@/App.vue';
import router from '@/config/router';
import store from '@/store';
import {mapGetters, mapActions} from 'vuex';
import 'vue-slim-tabs/themes/default.css';
import * as Tabs from 'vue-slim-tabs';
import kebabCase from 'lodash/kebabCase';
import cloneDeep from 'lodash/cloneDeep';
import VueCookies from 'vue-cookies';
// import '@/assets/css/common/layout.scss';
// import '@/assets/css/common/sheet.scss';
import '@/assets/css/style.scss';
import elementClosest from 'element-closest';
import VModal from 'vue-js-modal';

import IuiVPathPlugin from './plugins/IuiVPath';
import IuiValidatorPlugin from './plugins/IuiValidator';
import VueSecuKitNXHelper from '@inogard-dev/vue-secukitnx-helper';
import Viewer from 'v-viewer';

// directive
import {Code as IuiCodeDirective} from './components/Iui/diriectives/code.js';

// filters
import {number as numberFilter, currency as currencyFilter} from './components/Iui/filters';

elementClosest(window);
const bus = new Vue();
Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(IuiVPathPlugin);
Vue.use(IuiValidatorPlugin);
Vue.use(VueSecuKitNXHelper, {useSecuTax: false}); // 2020-10-26 선영욱 세금계산서 툴킷은 사용하지 않도록 옵션 처리
Vue.use(Tabs);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true, dialog: true, dynamicDefaults: {clickToClose: false}});
Vue.use(VueCookies);
Vue.use(Viewer);
Vue.use(PassPlugin);
Vue.use(VueClipboard);

Vue.directive('code', IuiCodeDirective);

Vue.filter('number', numberFilter);
Vue.filter('quantity', numberFilter);
Vue.filter('rate', numberFilter);
Vue.filter('currency', currencyFilter);
Vue.filter('amount', currencyFilter);
Vue.filter('unitAmount', currencyFilter);

/**
 * Object.keys Polyfill
 *
 * @see https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
 */
if (!Object.keys) {
  Object.keys = (function() {
    'use strict';
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !{toString: null}.propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor',
      ],
      dontEnumsLength = dontEnums.length;

    return function(obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [],
        prop,
        i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  })();
}

/**
 * Object.entries Polyfill
 *
 * @see https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
 */
if (!Object.entries) {
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

window.addEventListener('DOMContentLoaded', () => {
  if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
    if (
      (params && params.isPopup) ||
      location.pathname.indexOf('/Popup') != -1 ||
      location.pathname.indexOf('/WindowPopup') != -1
    )
      return;
    // 20210122 router.replace({path: '/Home', params: {}});
  } else {
    // 20210122 router.replace({path: '/', params: {}});
  }
});

window.onbeforeunload = () => {
  router.replace({path: '/', params: {}});
};

window.$init = (that, store) => {
  const namespace = that.$store.getters.getStoreNm;
  if (store) {
    if (that.$store.getters.modules.indexOf(namespace.replace(/\//, '')) == -1) {
      const _store = cloneDeep(store);
      that.$store.registerModule(namespace, _store);
      that.$store.commit('addModules', namespace.replace(/\//, ''));
      that.$store.commit('setLoadVue', that);

      // that.$options.__proto__.name = namespace.replace(/\//, '');
      //TODO component name을 지정하면 lifecycle 한번 더 실행됨 다른방법 검토해봐야함
    }
  }
};

window.$mapActions = (that, array) => {
  const namespace = that.$store.getters.getStoreNm;
  array.forEach(e => {
    if (!that.$store._actions[namespace + '/' + e]) {
      that.$options.methods[e] = () => {};
    } else {
      that.$store._actions[namespace + '/' + e].forEach(action => (that.$options.methods[e] = action));
    }
  });
};

window.$mapMutations = (that, array) => {
  const namespace = that.$store.getters.getStoreNm;
  array.forEach(e => {
    if (!that.$store._mutations[namespace + '/' + e]) {
      that.$options.methods[e] = () => {};
    } else {
      that.$store._mutations[namespace + '/' + e].forEach(mutation => (that.$options.methods[e] = mutation));
    }
  });
};

window.$mapGetters = (that, obj) => {
  const namespace = that.$store.getters.getStoreNm;
  let temp = {};

  if (Object.prototype.toString.call(obj).indexOf('Array') != -1) {
    for (let i = 0; i < obj.length; i++) {
      if (typeof that.$store.getters[namespace + '/' + obj[i]] == 'undefined') {
        temp[obj[i]] = () => {};
        // obj.splice(i, 1);
      }
    }
  } else {
    for (let key in obj) {
      if (typeof that.$store.getters[namespace + '/' + obj[key]] == 'undefined') {
        temp[obj[key]] = () => {};
        // delete obj[key];
      }
    }
  }

  that.$options.computed = {
    ...that.$options.computed,
    ...mapGetters(namespace, obj),
  };

  for (let key in temp) {
    that.$options.computed[key] = temp[key];
  }
};

window.$getCode = async (that, code, excludeKeys) => {
  let codes = that.$store.getters['code2/getCode'](code, excludeKeys);
  if (codes) {
    return codes;
  } else {
    await that.$store.dispatch('code2/searchCode', code);
    return $getCode(that, code, excludeKeys);
  }
};

window.$getCodeIBSheetFormat = async (that, code, excludeKeys) => {
  let codes = await $getCode(that, code, excludeKeys);
  if (0 < codes.length) {
    let obj = {};
    codes.forEach(arr => (obj[arr.code] = arr.codeNm));
    return obj;
  } else {
    return {};
  }
};

window.$getCodeIBSheetEnum = async (that, type, code, options) => {
  let excludeKeys = undefined;
  let defaultKey = '';
  if (options) {
    if (options.excludeKeys) {
      excludeKeys = options.excludeKeys;
    }
    if (options.defaultKey !== undefined) {
      defaultKey = '|' + options.defaultKey;
    } else if (options.defaultKey === '') {
      defaultKey = '|' + options.defaultKey;
    }
  }

  let codes = await $getCode(that, code, excludeKeys);
  if (0 < codes.length) {
    if (type == 'KEY') return codes.reduce((prev, curr) => prev + '|' + curr.code, defaultKey);
    if (type == 'VALUE') return codes.reduce((prev, curr) => prev + '|' + curr.codeNm, defaultKey);
    if (type != 'KEY' && type != 'VALUE') return '';
  } else {
    return '';
  }
};

window.$openMenu = (that, mnuCd) => {
  that.$store.dispatch('menu/onOpenMenu', mnuCd);
};

window.$externalData = data => {
  store.dispatch('setExternalData', data);
};

Vue.mixin({
  props: {
    storeNm: {
      type: String,
      default: store.getters.getStoreNm,
    },
    eventNms: {
      type: Object,
      default: function _default() {
        return {};
      },
    },
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('userInfo'));
    },
    pgmCd() {
      return (
        this.$store.getters['pgmCd'][this.$store.getters['currentUrl']] ||
        JSON.parse(sessionStorage.getItem('userInfo')).pgmCd
      );
    },
  },
  created() {
    const recomputed = Object.create(null);
    const watchers = this._computedWatchers; // Warning: Vue internal
    if (!watchers) return;

    for (const key in watchers) this.makeRecomputable(watchers[key], key, recomputed);

    this.$recompute = key => (recomputed[key] = !recomputed[key]);
    Vue.observable(recomputed);
  },
  activated: function() {
    if (this.cmmBtns) {
      this.setCmmBtns(this.cmmBtns);
    }
  },

  methods: {
    // init: function(store) {
    //   if (this.storeNm) {
    //     this.$store.dispatch('setStoreNm', this.storeNm);
    //     if (this.$store.getters.modules.indexOf(this.storeNm) == -1) {
    //       this.$store.registerModule(this.storeNm, store);
    //       this.$store.dispatch('addModules', this.storeNm);
    //     }
    //   } else {
    //     if (this.$store.getters.modules.indexOf(this.$store.getters.currentUrl) == -1) {
    //       this.$store.registerModule(this.$store.getters.currentUrl, store);
    //       this.$store.dispatch('addModules', this.$store.getters.currentUrl);
    //     }
    //   }
    // },

    makeRecomputable(watcher, key, recomputed) {
      const original = watcher.getter;
      recomputed[key] = true;
      watcher.getter = vm => (recomputed[key], original.call(vm, vm));
    },

    addEvent: function(event) {
      const me = this;
      if (Object.prototype.toString.call(event).slice(8, -1) == 'Array') {
        for (let key in event) {
          if (Object.keys(me.eventNms).length) {
            for (let eKey in me.eventNms) {
              if (eKey == event[key].name) {
                event[key].name = me.eventNms[eKey];
              }
            }
          }

          bus.$off(event[key].name + me.$store.getters.currentUrl);
          bus.$on(event[key].name + me.$store.getters.currentUrl, event[key].func);
        }
      } else {
        if (Object.keys(me.eventNms).length) {
          for (let eKey in me.eventNms) {
            if (eKey == event.name) {
              event.name = me.eventNms[eKey];
            }
          }
        }

        bus.$off(event.name + me.$store.getters.currentUrl);
        bus.$on(event.name + me.$store.getters.currentUrl, event.func);
      }
    },

    callEvent: function(event) {
      bus.$emit(event.name + this.$store.getters.currentUrl, event.param);
    },

    goVue: function(url, paramObj) {
      if (this.subVue) {
        const me = this;
        const urls = me.subVue.find(e => e.url == url);

        router.afterEach(to => {
          if (url == to.path) {
            window.subVue = to.matched[0].instances.default;
          }
        });

        let isDup = false;
        for (let key in this.$store.getters['mdi/history']) {
          this.$store.getters['mdi/history'][key].forEach(el => {
            if (el.name == urls.name) isDup = true;
          });
        }

        if (!isDup) {
          if (!this.$store.getters['mdi/router'].filter(e => e.url === urls.url).length) {
            me.$router.matcher.addRoutes([{name: urls.name, path: urls.url, component: getComponent(urls.path + '')}]);
            this.$store.commit('mdi/ADD_ROUTER', urls);
          }
        }

        router.push({name: urls.name, path: urls.url, params: {...paramObj}}, function() {
          me.$store.dispatch('mdi/setLocation', urls);
        });
      }
    },

    getFormData() {
      let formData = this.deepCopy(this.$store.getters.formData);
      // if (!this.isValid) {
      //   this.isValid = true;
      //   return false;
      // } else {
      if (formData && formData['none!']) {
        for (let key in formData['none!']) {
          formData = {...formData, [key]: formData['none!'][key]};
        }
        delete formData['none!'];
      }
      return formData;
      // }
    },

    deepCopy(obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj;
      }

      let copy = {};

      for (let key in obj) {
        copy[key] = this.deepCopy(obj[key]);
        if (copy[key] && copy[key].isVal) {
          if (
            copy[key].name &&
            document.querySelector('[name=' + copy[key].name + ']') &&
            copy[key].required &&
            !copy[key].value
          ) {
            // this.isValid = false;
            alert('valid 오류');
            if (obj[key].id) {
              document.getElementById(obj[key].id).focus();
            } else if (obj[key].name) {
              document.getElementsByName(obj[key].name)[0].focus();
            }
            break;
          }

          if (copy[key].isArray) {
            copy[key] = Object.values(copy[key].value);
          } else {
            copy[key] = copy[key].value;
          }
        }
      }
      return copy;
    },

    addComponent: function(name, path) {
      if (path.indexOf('@') == 0) {
        path = path.replace('@', '.');
      }

      const component = getComponent(path);

      Vue.component(name, component);
    },

    ...mapActions('layout', ['setCmmBtns']),
  },
});

Vue.config.errorHandler = function(err, vm, info) {
  // 에러 핸들링
  // err;
  // vm;
  // info;
  // `type`은 Vue의 에러 타입입니다. 예: 라이프사이클 훅
  // 2.2.0+ 이상에서 사용할 수 있습니다
  console.group(`Vue Error Handler`);
  console.error(err);
  console.error(vm);
  console.error(info);
  console.groupEnd();
  // store.dispatch("mdi/removeMdi", router.currentRoute.path);
  // store.dispatch("mdi/removeMdi", router.currentRoute.path);
  // store.commit("removeModules", router.currentRoute.path);
  // store.unregisterModule(router.currentRoute.path);
};

Vue.config.warnHandler = function(msg, vm, trace) {
  // trace는 컴포넌트 계층 구조를 추적합니다.
  console.group(`Vue Warn Handler`);
  console.warn(msg);
  console.warn(vm);
  console.warn(trace);
  console.groupEnd();
  // console.error(router.currentRoute.path);
  // try {
  //   const {detect} = require('detect-browser');
  //   const browser = detect();
  //   if (browser) {
  //     console.log(browser.name);
  //     console.log(browser.version);
  //     console.log(browser.os);
  //   }

  //   vm.$alert('오류가 발생하여 화면을 열 수 없습니다.');
  //   store.dispatch('mdi/removeMdi', router.currentRoute.path);
  //   store.commit('removeModules', router.currentRoute.path);
  //   store.unregisterModule(router.currentRoute.path);
  // } catch (error) {
  //   console.log(error);
  // }
};

new Vue({
  router,
  store,
  created() {
    this.doBackControl();
    this.doCommonComponentReg();
    this.initFormData();
    window.addEventListener('beforeunload', this.beforeunload);
  },
  computed: {
    ...mapGetters('mdi', {
      urls: 'urls',
      history: 'history',
    }),
  },
  methods: {
    beforeunload() {
      if (sessionStorage.getItem('userInfo')) {
        let LoginLogVo = {
          pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          userid: JSON.parse(sessionStorage.getItem('userInfo')).userid,
        };
        axios.post('/loginLog/updateLogoutHistory', LoginLogVo);
      }
    },

    /** 공통컴포넌트등록 */
    doCommonComponentReg() {
      const requireComponent = require.context(
        // 컴포넌트들이 있는 폴더
        './components',
        // 하위 폴더까지 포함할 지 여부
        true,
        // 기본 컴포넌트를 찾는데 사용할 정규표현식
        /[A-Z-]\w+\.(vue|js)$/
      );

      requireComponent.keys().forEach(fileName => {
        // 컴포넌트 설정 가져오기
        const componentConfig = requireComponent(fileName);

        // 컴포넌트의 파스칼표기법 이름 가져오기
        const componentName = kebabCase(
          // 폴더 위치와 무관하게 파일이름 추출
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        );

        // 컴포넌트를 전역적으로 등록
        Vue.component(
          componentName,
          // `export default`를 이용한 컴포넌트는 `.default`로 컴포넌트
          // 옵션을 추출하고 그렇지 않은 컴포넌트는 모듈의 루트를 호출
          componentConfig.default || componentConfig
        );
      });
    },
    /** 뒤로가기 제어 */
    doBackControl() {
      const me = this;
      window.onpopstate = function() {
        history.go(1);

        if (window.subVue) {
          window.subVue.$destroy();
          window.subVue = null;
        }

        if (!me.urls.length) return;
        const rootUrl = me.urls.filter(url => url.isSelected)[0].url;
        const historyLength = me.history[rootUrl].length;

        if (historyLength > 1) {
          const url = me.history[rootUrl][historyLength - 1].url;
          router.replace({path: url, params: {}}, function() {
            me.$store.dispatch('mdi/popHistory', rootUrl);
          });
        } else {
          router.replace({path: rootUrl, params: {}}, function() {
            me.$store.dispatch('mdi/popHistory', rootUrl);
          });
        }
      };
    },
    initFormData() {
      const me = this;
      router.beforeEach((to, from, next) => {
        me.$store.dispatch('initFormData', to);
        next();
      });
    },
  },
  render: h => h(App),
}).$mount('#app');

//라우터 추가 (컴포넌트 경로를 못찾아서 함수로 생성함)
const addRouter = urls => {
  let component = getComponent(urls.webUrl);

  router.matcher.addRoutes([
    {
      name: urls.url.replace(/\//g, ''),
      path: urls.url,
      component: component,
      // import( urls.webUrl + ''),
      props: true,
    },
  ]);
};

const getComponent = componentPath => {
  console.log('componentPath=' + componentPath);
  let component;
  const menuComponent = require.context(
    // 컴포넌트들이 있는 폴더
    '@',
    // 하위 폴더까지 포함할 지 여부
    true,
    // 기본 컴포넌트를 찾는데 사용할 정규표현식
    /.*\/view\/.+(\.vue)$/
  );

  menuComponent.keys().some(fileName => {
    // 컴포넌트 설정 가져오기
    if (componentPath == fileName) {
      const componentConfig = menuComponent(fileName);
      //alert("HHH:"+fileName);
      component = componentConfig.default || componentConfig;
      return true;
    }
  });

  return component;
};

export {addRouter};

// 20201123
window.$gridAutoResize = function(grid) {
  grid.resizeGrid = function() {
    var me = this;
    var pobj = me
      .closest('.ui-jqgrid')
      .parent()
      .parent();

    var newWidth = pobj.width();
    me.jqGrid('setGridWidth', newWidth - 2, false);
    var newHeight = pobj.height() - 70;
    me.jqGrid('setGridHeight', newHeight, false);
  };
  grid.resizeGrid();
};

// 20210406
window.$initSrchShowButton = function(prms) {
  var srchBox = prms.srchBox;
  var srchList = prms.srchList;

  if (srchBox.$el) {
    srchBox = srchBox.$el;
  }
  if (srchList.$el) {
    srchList = srchList.$el;
  }

  var srchShowBtn = document.createElement('span');
  srchShowBtn.className = 'btn_srch_show up';
  srchShowBtn.style.display = 'none';

  $(srchList).css('position', 'relative');
  //$(srchList).css("border", "1px solid red");

  srchList.appendChild(srchShowBtn);

  srchShowBtn.srchBox = srchBox;
  srchShowBtn.srchList = srchList;

  srchShowBtn.srchBoxHeight = $(srchBox).height();
  srchShowBtn.srchListHeight = $(srchList).height();
  srchShowBtn.show = function() {
    var isShow = this.srchBox.showYn != 'N';
    var w = $(this.srchBox).width();
    if (!w || w == 0) return;
    this.style.left = w / 2 - 7 + 'px';
    this.style.top = (isShow ? 28 + this.srchBoxHeight : 28 + 20) - 42 + 'px';
    this.style.display = 'block';
  };

  srchShowBtn.show();

  window.addEventListener('resize', function() {
    srchShowBtn.show();
  });

  $(srchShowBtn).on('click', function() {
    var isShow = this.srchBox.showYn == 'N';
    this.srchBox.showYn = isShow ? 'Y' : 'N';
    this.srchBox.style.display = isShow ? 'block' : 'none';

    var h = this.srchListHeight;
    if (!isShow) {
      h -= this.srchBoxHeight;
    }

    this.srchList.style.flex = '0 0 ' + h + 'px';

    this.show();

    if (isShow) {
      $(this)
        .removeClass('down')
        .addClass('up');
    } else {
      $(this)
        .removeClass('up')
        .addClass('down');
    }
  });
};

window.$isAdmin = function() {
  try{
    return (
      JSON.parse(sessionStorage.getItem('userInfo')).authorities.filter(
        data => data.indexOf('ROLE_SCD_ADMIN') > -1
      )[0] !== undefined   
    );
  }catch(e){
    return false;
  }
};

// 20220120
window.$gridWidthResize = function(grid, adj, fBoolon) {
  grid.resizeGrid = function(adjN, fBoolonN) {
    var newWidth = this.closest('.ui-jqgrid')
      .parent()
      .width();
    this.jqGrid('setGridWidth', newWidth + adjN, fBoolonN);
  };
  var adjN = adj == undefined ? 0 : adj;
  var fBoolonN = fBoolon == undefined ? true : fBoolon;
  grid.resizeGrid(adjN, fBoolonN);
};


// 20221228
window.$initContViewShowButton = function(prms) {
  var srchBox = prms.srchBox;
  var srchList = prms.srchList;
  var srchParent = prms.srchParent;

  if (srchBox.$el) {
    srchBox = srchBox.$el;
  }
  if (srchList.$el) {
    srchList = srchList.$el;
  }
  if (srchParent.$el) {
    srchParent = srchParent.$el;
  }

  var srchShowBtn = document.createElement('span');
  srchShowBtn.className = 'btn_srch_show up';
  srchShowBtn.style.display = 'none';

  $(srchList).css('position', 'relative');
  //$(srchList).css("border", "1px solid red");

  srchList.appendChild(srchShowBtn);

  srchShowBtn.srchBox = srchBox;
  srchShowBtn.srchList = srchList;
  srchShowBtn.srchParent = srchParent;

  srchShowBtn.srchBoxHeight = $(srchBox).height();
  srchShowBtn.srchListHeight = $(srchList).height();
  srchShowBtn.srchParentHeight = $(srchParent).height();
  //console.log("$(srchBox).height()",$(srchBox).height());
  //console.log("$(srchList).height()",$(srchList).height());
  //console.log("$(srchParent).height()",$(srchParent).height());
  //console.log("$(srchList).css('flex')",$(srchList).css('flex'));
  srchShowBtn.show = function() {
    var isShow = this.srchBox.showYn != 'N';
    var w = $(this.srchBox).width();
    if (!w || w == 0) return;
    this.style.left = w / 2 - 7 + 'px';
    //console.log("this.style.left",this.style.left);
    this.style.bottom = '10px';
    //console.log("this.style.top",this.style.top);
    this.style.display = 'block';
  };

  srchShowBtn.show();

  window.addEventListener('resize', function() {
    srchShowBtn.show();
  });

  $(srchShowBtn).on('click', function() {
    var isShow = this.srchBox.showYn == 'N';
    this.srchBox.showYn = isShow ? 'Y' : 'N';
    this.srchBox.style.display = isShow ? 'block' : 'none';

    var h = 0;
    this.srchList.style.flex = '1 1 0%';
    if (!isShow) {
      h = this.srchParentHeight-150;
      this.srchList.style.flex = '0 0 ' + h + 'px';
    }

    this.srchList.style.flex = '0 0 ' + h + 'px';
    //console.log("this.srchList.style.flex",this.srchList.style.flex);

    this.show();

    if (isShow) {
      $(this)
        .removeClass('down')
        .addClass('up');
    } else {
      $(this)
        .removeClass('up')
        .addClass('down');
    }
  });
  setTimeout(function(){
    $(srchShowBtn).trigger("click");
  },500)
  
};

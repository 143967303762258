<template>
  <pmis-tab-box>
    <pmis-content-box>
      <template v-slot:title>부서상세정보</template>

      <iui-container type="table" theme="bullet">
        <i-row>
          <i-col-header required>코드</i-col-header>
          <i-col>
            <iui-text name="fldrCd" :value="deptInfo.fldrCd" :bindArray="['setDeptInfo']" required />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>명칭</i-col-header>
          <i-col>
            <iui-text name="fldrNm" :value="deptInfo.fldrNm" :bindArray="['setDeptInfo']" required max-length="33" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>상위코드</i-col-header>
          <i-col>
            <iui-text name="uppFldrCd" :value="deptInfo.uppFldrCd" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header required>상위명칭</i-col-header>
          <i-col>
            <iui-text name="uppFldrNm" :value="deptInfo.uppFldrNm" :enable="false" />
          </i-col>
        </i-row>
      </iui-container>
      <hr />
      <iui-container type="table" theme="bullet">
        <i-row>
          <i-col-header>메모</i-col-header>
          <i-col>
            <iui-text
              type="multi"
              name="rmk"
              :value="deptInfo.rmk"
              :bindArray="['setDeptInfo']"
              height="200px"
              max-length="133"
            />
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  beforeCreate() {
    $mapGetters(this, {deptInfo: 'deptInfo', workerInfo: 'workerInfo', searchInfo: 'searchInfo'});
    $mapMutations(this, ['setDeptInfo', 'setWorkerInfo']);
  },
  created() {
    this.addEvent([{name: 'saveDeptInfo', func: this.saveDeptInfo}]);
  },
  mounted() {},
  methods: {
    async saveDeptInfo() {
      let validate = await this.validate();
      if (!validate.isValid) {
        this.$alert('필수입력값이 입력되지 않았습니다.');
        return;
      }

      if (await this.$confirm('저장하시겠습니까?')) {
        let param = {
          ...this.searchInfo,
          ...this.deptInfo,
        };
        console.log(param);
        console.log(this.deptInfo.cud);
        let response = await axios
          .post('/deptRegist/saveDept', param)
          .catch(e => console.error('저장을 실패하였습니다. : ', e));
        setTimeout(() => {
          if (response.status == 200) {
            this.$alert('저장하였습니다.');
            this.callEvent({name: 'selectDeptList', param: response.data.fldrNo});
          } else this.$alert('저장을 실패하였습니다.');
        }, 100);
      }
    },
  }, //methods
};
</script>

<style></style>

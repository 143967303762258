<template>
  <iui-container type="table" theme="bullet">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>미동의 정보</template>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header>미동의 고객사 이름</i-col-header>
              <i-col>
                <iui-text :value="contractAgreement.tradeNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공문서 수신자 이름</i-col-header>
              <i-col>
                <iui-text :value="contractAgreement.ofldcRcverNm" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공문서 수신자 모바일</i-col-header>
              <i-col>
                <iui-text :value="contractAgreement.ofldcRcverMoblphonNo" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>공문서 수신자 Email</i-col-header>
              <i-col>
                <iui-text :value="contractAgreement.ofldcRcverEmail" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>미동의 사유</i-col-header>
              <i-col>
                <iui-text :value="contractAgreement.rmCn" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>미동의 시간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractAgreement.chngDate" :enable="false" />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: String,
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
    agreSn: {
      type: String,
      default: undefined,
    },
  },
  created() {},
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      contractAgreement: {
        agreSn: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        agreSttusCd: '',
        agreSttusNm: '',
        processResultCd: '',
        processResultNm: '',
        ofldcRcverNm: '',
        ofldcRcverMoblphonNo: '',
        ofldcRcverEmail: '',
        rmCn: '',
        visitDe: '',
        cstmrNm: '',
        cstmrTelno: '',
      },
    };
  },
  mounted() {
    console.log('Unacceptable');
    this.onSearch();
  },
  methods: {
    async onSearch() {
      let param = {
        agreSn: this.agreSn,
      };

      let response = await axios.post('/contract/selectContractAgreement', param);
      this.contractAgreement = response.data;
    },
  },
};
</script>

<style></style>

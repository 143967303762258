/* MOD > 전자계약 > 상세 TAB */
<script>
import {reactive, watch, computed, onMounted, getCurrentInstance} from '@vue/composition-api';
import {numToKr} from 'vue-number-to-kor';
import EtcDocuments from '@/view/common/components/EtcDocuments.vue';
import HistoryList from '@/view/common/components/HistoryList.vue';

const ibsheetOptions1 = {
  Cfg: {
    CanEdit: 0,
    DataMerge: 1,
  },
  Cols: [
    {
      Header: ['변경전/후'],
      Name: 'befYn',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Formula: 'Row.befYn == "Y" ? "변경전" : "변경후"',
    },
    {
      Header: '구분',
      Name: 'tradeTypeNm',
      Type: 'Text',
      Width: 100,
      ColMerge: false,
      Align: 'Center',
    },
    {
      Header: 'tradeType',
      Name: 'tradeType',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'displayTradeNo',
      Name: 'displayTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'tradeNo',
      Name: 'tradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '대표사여부',
      Name: 'mainTradeYn',
      Type: 'Text',
      Width: 90,
      ColMerge: false,
      Align: 'Center',
      Formula: 'Row.mainTradeYn == "Y" ? "○" : ""',
    },
    {
      Header: '상호(법인) / 이름(개인/개인사업자)',
      Name: 'tradeName',
      Type: 'Text',
      MinWidth: 200,
      ColMerge: false,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: '대표자',
      Name: 'repntName',
      Type: 'Text',
      Width: 80,
      ColMerge: false,
      Align: 'Center',
    },
    {
      Header: '대리인',
      Name: 'buyagntNm',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },

    {
      Header: '서명/반송',
      Name: 'signOrReject',
      Type: 'Text',
      Width: 80,
      ColMerge: false,
      Align: 'Center',
    },
    {
      Header: '사업자번호',
      Name: 'businessNo',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '주소',
      Name: 'address',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '서명일시',
      Name: 'signDt',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '방법',
      Name: 'signMthNm',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },

    {
      Header: 'IP',
      Name: 'accesIp',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    
    {
      Header: '연락처',
      Name: 'mphone',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    }, //연락처 추가 20221221 lgksja

    {
      Header: 'URL',
      Name: 'urlLink',
      Type: 'Text',
      MinWidth: 200,
      ColMerge: false,
      RelWidth: 1,
      Align: 'Left',
      Visible: 0,
    }, //개별 알림재송부  lgksja 20230111
  ],
};

const ibsheetOptions2 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermName',
      Type: 'text',
      Width: 100,
      Align: 'Center',
      MinWidth: 100,
    },
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermDesc',
      Type: 'text',
      Width: 100,
      Align: 'Left',
      MinWidth: 100,
    },
    {
      Header: ['지급율', '지급율'],
      Name: 'paymentTermRate',
      Type: 'Float',
      Width: 100,
      Format: '#,###\\%',
      Align: 'Center',
    },
    {
      Header: ['지급금액', '지급금액'],
      Name: 'paymentTermTotAmt',
      Type: 'Int',
      Width: 150,
      ormat: '#,###',
      Align: 'Right',
    },
    {
      Header: ['지급방법', '지급방법'],
      Name: 'paymentTermMtd',
      Type: 'text',
      Width: 100,
      Align: 'Center',
    },
  ],
};
const ibsheetOptions3 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermName',
      Type: 'text',
      Width: 100,
      Align: 'Center',
      MinWidth: 100,
    },
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermDesc',
      Type: 'text',
      Width: 100,
      Align: 'Left',
      MinWidth: 100,
    },
    {
      Header: ['지급율', '지급율'],
      Name: 'paymentTermRate',
      Type: 'Float',
      Width: 100,
      Format: '#,###\\%',
      Align: 'Center',
    },
    {
      Header: ['지급금액', '지급금액'],
      Name: 'paymentTermTotAmt',
      Type: 'Int',
      Width: 150,
      ormat: '#,###',
      Align: 'Right',
    },
    {
      Header: ['지급방법', '지급방법'],
      Name: 'paymentTermMtd',
      Type: 'text',
      Width: 100,
      Align: 'Center',
    },
  ],
};
const ibsheetOptions4 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermName',
      Type: 'text',
      Width: 100,
      Align: 'Center',
      MinWidth: 100,
    },
    {
      Header: ['지급조건', '지급조건'],
      Name: 'paymentTermDesc',
      Type: 'text',
      Width: 100,
      Align: 'Left',
      MinWidth: 100,
    },
    {
      Header: ['지급율', '지급율'],
      Name: 'paymentTermRate',
      Type: 'Float',
      Width: 100,
      Format: '#,###\\%',
      Align: 'Center',
    },
    {
      Header: ['지급금액', '지급금액'],
      Name: 'paymentTermTotAmt',
      Type: 'Int',
      Width: 150,
      ormat: '#,###',
      Align: 'Right',
    },
    {
      Header: ['지급방법', '지급방법'],
      Name: 'paymentTermMtd',
      Type: 'text',
      Width: 100,
      Align: 'Center',
    },
  ],
};

export default {
  components: {
    EtcDocuments,
    HistoryList,
  },
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },

  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;
    const state = reactive({
      loading: false,

      // 1. 고객
      grid1: undefined,
      gridOptions1: ibsheetOptions1,

      memberInfo: {
        tradeTypeNm: '구분',
        businessNo: undefined, // 사업자(주민)번호
        tradeName: undefined, // 상호(이름)
        repntName: undefined, // 대표자
        address: undefined, // 주소
        signDt: undefined, // 서명일시
        accesIp: undefined,
        mphone:undefined, //연락처 추가 20221221 lgksja
        urlLink:undefined, //개별 알림재송부  lgksja 20230111
      },

      // 2. 계약 정보
      contractInfo: {
        projectNo: undefined, // 프로젝트번호
        projectName: undefined, //프로젝트 명
        custTradeNo: undefined, //고객번호
        custTradeName: undefined, //고객명
        contDate: undefined, // 계약일자
        deliveryDate: undefined, //납기일, 변경 후 > 납기일
        contTotAmt: undefined, //총금액, 변경 후 > 총 계약 금액
        contTotAmtKor: undefined, //총금액
        contSupplyAmt: undefined, // 공급금액
        contSupplyAmtKor: undefined, // 공급금액
        contVatAmt: undefined, //부가세액
        contVatAmtKor: undefined, //부가세액

        befReason: undefined, // 변경 전 > SPEC변경 등 증액사유
        befChngAmt: undefined, // 변경 전 > 변경계약금액
        befContTotAmt: undefined, // 변경 전 > 총 계약 금액
        befContTotAmtKor: undefined,
        befDeliveryDate: undefined, // 변경 전 > 납기일

        aftReason: undefined, // 변경 후 > SPEC변경 등 증액사유
        chngAmt: undefined, // 변경 후 > 변경계약금액
        chngAmtKor: undefined,
        instAddress: undefined, //설치장소
      },

      // 3. 지급조건(MOD_CT1||MOD_CT2)
      grid2: undefined,
      gridOptions2: ibsheetOptions2,

      // 4. 지급조건(변경전/변경후)
      grid3: undefined,
      gridOptions3: ibsheetOptions3,

      grid4: undefined,
      gridOptions4: ibsheetOptions4,

      isChangeContract: computed(() => {
        return (
          // (props.contractKey !== undefined && props.contractKey.ctrtDivCd === 'MOD_CT3') ||
          // (props.contractKey !== undefined && props.contractKey.ctrtDivCd === 'MOD_CT4')
          props.contractKey !== undefined && ['MOD_CT3', 'MOD_CT4', 'MOD_CT5', 'MOD_CT21', 'MOD_CT22', 'MOD_CT23', 'MOD_CT24'].includes(props.contractKey.ctrtDivCd)
        );
      }),
      //개별 알림재송부  lgksja 20230111
      isCanResendNoti : computed(() => {
        return  (
          //console.log("tate.memberInfo.signDt",state.memberInfo.signDt),
          ( $isAdmin() || state.contractInfo.contEmpId == vm.$root.userInfo.empNo ) &&
          props.otisPage && 
          state.memberInfo.signDt != undefined && 
          state.memberInfo.signDt =='' && 
          ((state.memberInfo.tradeType != 'OTIS' && state.memberInfo.tradeType != 'CCPY' && state.contractInfo.memberList.filter(data => data.tradeType == 'CCPY' && data.signOrReject !='서명').length == 0) || state.memberInfo.tradeType == 'CCPY' ) &&
          (state.contractInfo.ctrtSttusCd =='10' || state.contractInfo.ctrtSttusCd =='20') 
        )
      }),
      isCanUrlCopy : computed(() => {
        
        return  (
          //console.log("state.memberInfo.urlLink",state.memberInfo.urlLink),
          ( $isAdmin() || state.contractInfo.contEmpId == vm.$root.userInfo.empNo ) &&
          props.otisPage && 
          state.memberInfo.urlLink != undefined && 
          state.memberInfo.urlLink !='' && 
          state.memberInfo.tradeType !='OTIS'
        )
      }),
      contractKey: undefined,
    });

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      onDataLoad(e) {
        //console.log("eeee",e.row);
        const rowValue = e.sheet.getRowValue(e.sheet.getFirstRow());        
        state.memberInfo = rowValue;
        //console.log('onDataLoad state.memberInfo', rowValue);
      },
      onClick(e) {
        // Header 클릭
        if (e.row.id == 'Header') {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);
        state.memberInfo = rowValue;
         
      },
    };

    const paymentTermGrid_onDataLoad = e => {
      e.sheet.fitSize('paymentTermName');
      e.sheet.fitSize('paymentTermDesc');
    };

    state.gridOptions2.Events = {
      ...state.gridOptions2.Events,
      onDataLoad(e) {
        paymentTermGrid_onDataLoad(e);
      },
    };
    state.gridOptions3.Events = {
      ...state.gridOptions3.Events,
      onDataLoad(e) {
        paymentTermGrid_onDataLoad(e);
      },
    };
    state.gridOptions4.Events = {
      ...state.gridOptions4.Events,
      onDataLoad(e) {
        paymentTermGrid_onDataLoad(e);
      },
    };

    const method = {
      btn_click() {
        context.emit('change', true);
      },

      async getData() {
        state.loading = true;

        const param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/mod/contract/selectContractView', param);

          // 1. 계약 대상자
          state.memberList = response.data.memberList;

          console.log('view > memberInfo', state, state.memberInfo);

          // 2. 계약정보
          state.contractInfo = response.data;
          const contractInfo = state.contractInfo;

          console.log('view > state', state, contractInfo);

          state.contractInfo.contTotAmtKor = numToKr(contractInfo.contTotAmt); // 총계약금액
          if (state.isChangeContract) {
            state.contractInfo.befContTotAmtKor = numToKr(contractInfo.befContTotAmt); // 변경전 총계약금액
            state.contractInfo.chngAmtKor = numToKr(contractInfo.chngAmt); // 변경후 > 변경 계약금액
          }
          if (!state.isChangeContract) {
            state.contractInfo.contSupplyAmtKor = numToKr(contractInfo.contSupplyAmt); // 공급금액
            state.contractInfo.contVatAmtKor = numToKr(contractInfo.contVatAmt); // 부가세액
          }
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      //개별 알림재송부  lgksja 20230111
      async resendNoti() {
        if (!(await vm.$confirm(state.memberInfo.tradeName + '에게 알림재송부 하시겠습니까?'))) return;

        const param = {
          ctrtNo: state.contractInfo.ctrtNo,
          ctrtOdr: state.contractInfo.ctrtOdr,
          ctrtDivCd: state.contractInfo.ctrtDivCd,
          tradeNo: state.memberInfo.tradeNo,
          tradeSfx: (state.memberInfo.displayTradeNo.length > 8) ? state.memberInfo.displayTradeNo.slice(-2) : "",
        };
        console.log("state.memberInfo.displayTradeNo.length",state.memberInfo.displayTradeNo.length);
        state.loading = true;
        try {
          await axios.post('/contract/reSendNoti', param);
          await vm.$alert('알림재송부가 완료되었습니다.');
          state.loading = false;
          state.grid1.removeAll();
          await method.getData();
        } catch (ex) {
          console.log(ex);
          state.loading = false;
          await vm.$alert('알림재송부 중 오류가 발생하였습니다.');
        } finally {
          state.loading = false;
        }        
      },      
      onCopy() {
        vm.$alert(
          '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
        );
      },
      onError(e) {
        vm.$alert('클립보드 복사에 실패하였습니다.');
        console.log(e);
      },

    };

    watch(
      () => props.contractKey,
      newValue => {
        state.grid1.removeAll();

        state.memberInfo = {}; // 고객정보
        state.contractInfo = {}; //계약정보
        state.memberInfo.tradeTypeNm = '구분';
        state.contractKey = undefined;

        // estimateKey 변경된 경우 처리
        if (newValue === undefined) {
          return;
        }
        if (
          props.contractKey !== undefined &&
          ['MOD_CT3', 'MOD_CT4', 'MOD_CT5', 'MOD_CT21', 'MOD_CT22', 'MOD_CT23', 'MOD_CT24'].includes(props.contractKey.ctrtDivCd)
        ) {
          state.grid1.showCol('befYn');
        } else {
          state.grid1.hideCol('befYn');
        }

        state.contractKey = props.contractKey;
        method.getData();
      }
    );

    onMounted(() => {
      if (props.contractKey) {
        // state.contractInfo.tradeType = {};
        method.getData();
      }
    });

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <!-- <iui-content-box :loading="state.loading"> -->
  <iui-tab-group :loading="state.loading">
    <iui-tab title="계약 대상자">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.contractInfo.memberList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="7" width="80px">{{ state.memberInfo.tradeTypeNm }}</i-col-header>
                <i-col-header width="100px">사업자(주민)번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호(이름)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.repntName" disable />
                  <iui-button
                    color="blue"
                    v-if="state.isCanUrlCopy"
                    v-clipboard:copy="state.memberInfo.urlLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >URL복사</iui-button
                  >
                  <iui-button iui-button color="blue" v-if="state.isCanResendNoti" @click="resendNoti">알림재송부</iui-button>
                </i-col>
              </i-row>
              <i-row v-show="state.memberInfo.tradeTypeNm !='OTIS'"><!--연락처 추가 20221221 lgksja-->
                <i-col-header>연락처</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.mphone" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.address" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명일시</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.signDt" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>방법 / IP</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.signMthNm" disable />
                  <iui-textbox v-model="state.memberInfo.accesIp" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="계약 정보">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container v-show="!state.isChangeContract" type="table" header-width="200px">
              <i-row>
                <i-col-header colspan="2">계약번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtNo" disable />
                </i-col>
                <i-col-header colspan="2">차수</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtOdr" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">프로젝트</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.projectName" disable />
                </i-col>
                <i-col-header colspan="2">고객명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.custTradeNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.custTradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">계약 일자</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.contDate" disable />
                </i-col>
                <i-col-header colspan="2">계약 납기</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.deliveryDate" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="3">계약금액</i-col-header>
                <i-col-header>총금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contTotAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contTotAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2">설치장소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.instAddress" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공급금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contSupplyAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contSupplyAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col colspan="3"> </i-col>
              </i-row>
              <i-row>
                <i-col-header>부가세액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contVatAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contVatAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col colspan="3"> </i-col>
              </i-row>
            </iui-container>
            <iui-container v-show="state.isChangeContract" type="table" header-width="200px">
              <i-row>
                <i-col-header colspan="3">계약번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtNo" disable />
                </i-col>
                <i-col-header colspan="3">차수</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtOdr" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="3">프로젝트</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.projectName" disable />
                </i-col>
                <i-col-header colspan="3">고객명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.custTradeNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.custTradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="3">계약 일자</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.contDate" disable />
                </i-col>
                <i-col-header colspan="3">설치장소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.instAddress" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="4">변경전</i-col-header>
                <i-col-header colspan="2">SPEC변경 등 증액 사유</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.befReason" disable />
                  <!-- <iui-textbox v-model="state.contractInfo.contTotAmtKor" prefix="(일금" suffix="원 정)" disable /> -->
                </i-col>
                <i-col-header rowspan="4">변경후</i-col-header>
                <i-col-header colspan="2">변경 사유</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.aftReason" disable />
                  <!-- <iui-textbox v-model="state.contractInfo.paymentTermDescS1" disable /> -->
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">변경 계약 금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.befChngAmt" prefix="\" disable width="160px" />
                  <!-- <iui-textbox v-model="state.contractInfo.contSupplyAmtKor" prefix="(일금" suffix="원 정)" disable /> -->
                </i-col>
                <i-col-header colspan="2">변경 계약 금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.chngAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.chngAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">총 계약 금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.befContTotAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.befContTotAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2">총 계약 금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contTotAmt" type="number" prefix="\" width="160px" disable />
                  <iui-textbox v-model="state.contractInfo.contTotAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
              </i-row>

              <i-row>
                <i-col-header colspan="2">납기일</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.befDeliveryDate" disable />
                </i-col>
                <i-col-header colspan="2">납기일</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.deliveryDate" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="지급 조건">
      <iui-container v-show="!state.isChangeContract" type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid2"
                :ibsheet-options="state.gridOptions2"
                :ibsheet-data="state.contractInfo.paymentList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
        </i-row>
      </iui-container>
      <iui-container v-show="state.isChangeContract" type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                변경 전
              </template>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid3"
                :ibsheet-options="state.gridOptions3"
                :ibsheet-data="state.contractInfo.paymentList1"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                변경 후
              </template>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid4"
                :ibsheet-options="state.gridOptions4"
                :ibsheet-data="state.contractInfo.paymentList2"
              />
            </iui-content-box>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="인지세 및 부속서류">
      <EtcDocuments :contract-key="state.contractKey" />
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :document-key="contractKey" />
    </iui-tab>
  </iui-tab-group>
  <!-- </iui-content-box> -->
</template>

<style></style>

<script>
import {defineComponent, reactive, toRef, watch} from '@vue/composition-api';

export default defineComponent({
  props: {
    dataKey: {
      type: Object,
      default: () => {},
    },
    dataChanged: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const dataKeyRef = toRef(props, 'dataKey');

    const state = reactive({
      dataKey: dataKeyRef,
    });

    watch(
      () => props.dataKey,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        context.emit('update:data-changed', false);
      }
    );

    const method = {
      btn1_click() {
        context.emit('update:data-changed', true);
      },
    };

    return {
      state,
      ...method,
    };
  },
});
</script>

<template>
  <iui-container type="table">
    <i-row>
      <i-col-header>키값1</i-col-header>
      <i-col>
        <iui-textbox v-model="state.dataKey.key1" readonly disable />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>키값2</i-col-header>
      <i-col>
        <iui-textbox v-model="state.dataKey.key2" readonly disable />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>키값3</i-col-header>
      <i-col>
        <iui-textbox v-model="state.dataKey.key3" readonly disable />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>dataChanged</i-col-header>
      <i-col>
        <iui-textbox v-model="dataChanged" readonly disable />
      </i-col>
    </i-row>
    <i-row>
      <i-col-header>데이터 변경 처리</i-col-header>
      <i-col>
        <iui-button @click="btn1_click">데이터 변경 처리</iui-button>
      </i-col>
    </i-row>
  </iui-container>
</template>

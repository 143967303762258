<template>
  <iui-container type="css-flex">
    <i-row height="30px">
      <i-col>
        <div style="float: right;">
          <iui-button value="저장" @click="onSave('insert')" />
        </div>
      </i-col>
    </i-row>
    <i-row height="110px">
      <i-col>
        <iui-container type="table">
          <colgroup>
            <col width="10%" />
            <col />
            <col width="10%" />
            <col />
          </colgroup>
          <i-row>
            <i-col-header>등록자</i-col-header>
            <i-col>{{ replyInfo.rgprNm }}</i-col>
            <i-col-header>등록일자</i-col-header>
            <i-col>{{ replyInfo.rgsDttm }}</i-col>
          </i-row>
          <i-row>
            <i-col-header>내용</i-col-header>
            <i-col colspan="3">
              <iui-text type="multi" :value="replyInfo.cts" @change="setValue" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row v-if="replyList.length == 0">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col align="center">
              <font style="color:#FF6666; font-weight: bold;">등록된 내용이 없습니다.</font>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row v-else style="overflow-y: scroll">
      <i-col>
        <table style="border-collapse: collapse;" :key="updateList">
          <tr v-for="(item, index) in replyList" :key="index">
            <td>
              <iui-container type="css-flex" v-if="empNo == item.rgprId">
                <i-row height="30px">
                  <i-col>
                    <div style="float: right;">
                      <iui-button value="저장" @click="onSave('update', item)" v-if="item.isUpdate" />
                      <iui-button value="수정" @click="onUpdate(index)" v-else />
                      <iui-button value="취소" @click="onCancel(index)" v-if="item.isUpdate" />
                      <iui-button value="삭제" @click="onDelete(item)" v-else />
                    </div>
                  </i-col>
                </i-row>
              </iui-container>
              <table class="iui-container table default">
                <colgroup>
                  <col width="10%" />
                  <col />
                  <col width="10%" />
                  <col />
                </colgroup>
                <tr class="row">
                  <th class="col-header"><div class="cell-box">등록자</div></th>
                  <td class="col">
                    <div class="cell-box"><iui-text :value="item.rgprNm" :enable="false" /></div>
                  </td>
                  <th class="col-header"><div class="cell-box">등록일자</div></th>
                  <td class="col" style="padding: 5px;">
                    {{ item.rgsDttm }}
                    <img v-if="item.isNewer" src="@/assets/img/old/icon_new.gif" />
                  </td>
                </tr>
                <tr class="row">
                  <th class="col-header"><div class="cell-box">내용</div></th>
                  <td colspan="3" class="col">
                    <div class="cell-box">
                      <iui-text
                        type="multi"
                        :value="item.cts"
                        @change="setItemValue(item, $event)"
                        :enable="empNo == item.rgprId"
                        v-show="item.isUpdate"
                      />
                      <iui-text
                        type="multi"
                        name="cts"
                        :value="item.cts"
                        :enable="false"
                        :css="style"
                        v-show="!item.isUpdate"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  created() {
    this.addEvent({name: 'BoardReplyPopup_close', func: this.onClose});
    this.addEvent({name: 'BoardReplyPopup_search', func: this.onSearch});
  },

  activated() {
    this.replyList = [];
  },

  data() {
    return {
      replyInfo: {
        rgprNm: '',
        rgsDttm: '',
        cts: '',
      },
      replyList: [],
      empNo: JSON.parse(sessionStorage.getItem('userInfo')).empNo,
      paramObj: {},
      updateList: 0,

      style: {
        'white-space': 'pre-wrap',
        'word-wrap': 'break-word',
      },
    };
  },
  methods: {
    async onSearch(param) {
      if (!param)
        param = {
          pgmCd: this.paramObj.pgmCd,
          uppNatNo: this.paramObj.uppNatNo,
          rgprId: this.paramObj.rgprId,
        };
      else {
        this.paramObj = param;
      }

      let response = await axios.post('/board/selectBoardReplyInfos', param);

      for (let key in this.replyInfo) this.replyInfo[key] = response.data.info[key];
      this.replyList = response.data.list;
    },
    setValue() {
      this.replyInfo.cts = event.target.value;
    },
    setItemValue(item, event) {
      item.cts = event.target.value;
    },
    async onSave(type, item) {
      let txt = type == 'insert' ? this.replyInfo.cts.trim() : item.cts.trim();
      if (!txt) {
        this.$alert('답변 내용을 입력해야 합니다.');
        return;
      }

      let param = {};
      if (type == 'insert') {
        let pgmCd = this.paramObj.pgmCd;
        let uppNatNo = this.paramObj.uppNatNo;
        let cts = this.replyInfo.cts;
        param = {pgmCd, uppNatNo, cts};
      } else {
        let pgmCd = item.pgmCd;
        let cmtNo = item.cmtNo;
        let uppNatNo = item.uppNatNo;
        let cts = item.cts;
        param = {pgmCd, cmtNo, uppNatNo, cts};
      }

      let msg = type == 'insert' ? '저장' : '수정';
      let response = await axios
        .post(`/board/${type}BoardReplyInfo`, param)
        .catch(e => console.error(`${msg}을 실패했습니다. :`, e));

      if (response.status == 200) {
        this.onSearch();
      } else {
        this.$alert(`${msg}을 실패했습니다.`);
      }
    },
    async onDelete(item) {
      if (await this.$confirm('삭제하시겠습니까?')) {
        let pgmCd = item.pgmCd;
        let cmtNo = item.cmtNo;
        let uppNatNo = item.uppNatNo;
        let param = {pgmCd, cmtNo, uppNatNo};

        let response = await axios
          .post('/board/deleteBoardReplyInfo', param)
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          this.onSearch();
        } else {
          this.$alert('저장을 실패했습니다.');
        }
      }
    },
    onClose(callback) {
      callback(this.replyList.length);
    },
    onUpdate(index) {
      this.replyList[index].isUpdate = true;
      // console.log(this.replyList[index]);
      this.updateList += 1;
    },
    onCancel(index) {
      this.replyList[index].isUpdate = false;
      this.updateList += 1;
    },
  },
};
</script>

<style></style>

import Vue from 'vue';
import Vuex from 'vuex';
import mdi from './modules/mdi';
import login from './modules/login';
import layout from './modules/layout';
import menu from './modules/menu';
import code from './modules/code';
import code2 from './modules/code2';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mdi,
    login,
    layout,
    menu,
    code,
    code2,
  },
  state: {
    currentUrl: '/',
    formData: {},
    modules: [],
    storeNm: '',
    bus: new Vue(),
    externalData: {},
    modalParam: {},
    pgmCd: {},
    loadVue: {},
  },
  mutations: {
    setCurrentUrl(state, payload) {
      state.currentUrl = payload.path;
      state.storeNm = state.currentUrl;
    },
    setModalParam(state, payload) {
      state.modalParam = payload;
    },
    addModules(state, module) {
      console.log("addModules:"+module)
      state.modules.push(module);
    },
    removeModules(state, name) {
      let index = state.modules.findIndex(item => item === name);
      state.modules.splice(index, 1);
    },
    allRemoveModules(state) {
      state.modules = [];
    },
    setPgmCd(state, payload) {
      state.pgmCd[state.currentUrl] = payload;
    },
    removePgmCd(state) {
      delete state.pgmCd[state.currentUrl];
    },
    removeAllPgmCd(state) {
      state.pgmCd = {};
    },
    setLoadVue(state, payload) {
      state.loadVue[state.currentUrl] = payload;
    },
  },
  actions: {
    setFormData({state}, payload) {
      if (!state.formData[state.currentUrl]) return false;

      const key = payload.key;
      const value = payload.value;

      let name;
      let required;
      let id;
      if (Array.isArray(payload.element)) {
        name = payload.element[0].name;
        required = payload.element[0].required;
        id = payload.element[0].id;
      } else {
        name = payload.element.name;
        required = payload.element.required;
        id = payload.element.id;
      }

      let objArray = [];
      const formObjLength = payload.formObj.split('/').length;
      payload.formObj.split('/').forEach(function(o, i) {
        switch (i) {
          case 0:
            objArray.push(o);
            if (!state.formData[state.currentUrl][objArray[0]]) {
              state.formData[state.currentUrl][objArray[0]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]] = {
                  ...state.formData[state.currentUrl][objArray[0]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 1:
            objArray.push(o);
            if (!state.formData[state.currentUrl][objArray[0]][objArray[1]]) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 2:
            objArray.push(o);
            if (!state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]]) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 3:
            objArray.push(o);
            if (!state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]]) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 4:
            objArray.push(o);
            if (!state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]]) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 5:
            objArray.push(o);
            if (
              !state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ]
            ) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                  objArray[5]
                ] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                    objArray[5]
                  ],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 6:
            objArray.push(o);
            if (
              !state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]]
            ) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                  objArray[5]
                ][objArray[6]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                    objArray[5]
                  ][objArray[6]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 7:
            objArray.push(o);
            if (
              !state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]]
            ) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                  objArray[5]
                ][objArray[6]][objArray[7]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                    objArray[5]
                  ][objArray[6]][objArray[7]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 8:
            objArray.push(o);
            if (
              !state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]][objArray[8]]
            ) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]][objArray[8]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                  objArray[5]
                ][objArray[6]][objArray[7]][objArray[8]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                    objArray[5]
                  ][objArray[6]][objArray[7]][objArray[8]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
          case 9:
            objArray.push(o);
            if (
              !state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]][objArray[8]][objArray[9]]
            ) {
              state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                objArray[5]
              ][objArray[6]][objArray[7]][objArray[8]][objArray[9]] = {};
            } else {
              if (i + 1 == formObjLength) {
                state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                  objArray[5]
                ][objArray[6]][objArray[7]][objArray[8]][objArray[9]] = {
                  ...state.formData[state.currentUrl][objArray[0]][objArray[1]][objArray[2]][objArray[3]][objArray[4]][
                    objArray[5]
                  ][objArray[6]][objArray[7]][objArray[8]][objArray[9]],
                  [key]: {
                    id: id,
                    name: name,
                    value: value,
                    required: required,
                    isVal: true,
                    isArray: Array.isArray(value),
                  },
                };
              }
            }
            break;
        }
      });
    },
    initFormData({state, commit}, payload) {
      if (!state.formData[payload.path]) {
        state.formData[payload.path] = {};
      }

      commit('setCurrentUrl', payload);
    },
    setStoreNm({state}, payload) {
      state.storeNm = payload;
      window.storeNm = state.currentUrl;
    },
    addEvent({state}, payload) {
      if (Object.prototype.toString.call(payload).slice(8, -1) == 'Array') {
        for (let key in payload) {
          state.bus.$off(payload[key].name + state.currentUrl);
          state.bus.$on(payload[key].name + state.currentUrl, payload[key].func);
        }
      } else {
        state.bus.$off(payload.name + state.currentUrl);
        state.bus.$on(payload.name + state.currentUrl, payload.func);
      }
    },
    callEvent({state}, payload) {
      state.bus.$emit(payload.name + state.currentUrl, payload.param);
    },
    setExternalData({state}, payload) {
      state.externalData = payload;
    },
  },
  getters: {
    formData: state => {
      return state.formData[state.currentUrl];
    },
    currentState: state => {
      return state;
    },
    currentUrl: state => {
      return state.currentUrl;
    },
    modules: state => state.modules,
    getStoreNm: state => state.storeNm,
    externalData: state => state.externalData,
    modalParam: state => state.modalParam,
    cachedPage: state => state.cachedPage,
    pgmCd: state => state.pgmCd,
    loadVue: state => state.loadVue,
  },
});

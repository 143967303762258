<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false">
    <iui-container type="table" header-width="150px" width="50%">
      <i-row>
        <i-col-header colspan="2">BUTTON</i-col-header>
        <i-col>
          <file-upload
            class="btn btn-primary"
            post-action="/upload/post"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            :multiple="true"
            :size="1024 * 1024 * 10"
            :value="files"
            @input="inputUpdate"
            ref="upload"
          >
            <button>SELECT FILE</button>
          </file-upload>
          <button @click="onSubmit" style="width: 90px">SUBMIT</button>
          <button @click="onDelete" style="width: 90px">DELETE</button>
        </i-col>
        <i-col rowspan="5">
          <img class="popupImageItem" :src="loadImageFile" width="180px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header colspan="2">STATUS</i-col-header>
        <i-col>{{ message }}</i-col>
      </i-row>
      <i-row>
        <i-col-header rowspan="3">FILE INFO</i-col-header>
        <i-col-header>FILE NAME</i-col-header>
        <i-col>{{ this.fileInfo.fileName }}</i-col>
      </i-row>
      <i-row>
        <i-col-header>FILE PATH</i-col-header>
        <i-col>{{ this.fileInfo.filePath }}</i-col>
      </i-row>
      <i-row>
        <i-col-header>FILE SIZE</i-col-header>
        <i-col>{{ this.fileInfo.fileSize ? this.fileInfo.fileSize + ' byte' : '' }}</i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import FileUpload from 'vue-upload-component';
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
export default {
  name: 'iui-file',
  mixins: [IuiBaseMixin, IuiLayoutMixin],
  props: {
    type: {
      type: String,
      default: 'image', // image, inputbox
    },
  },
  components: {FileUpload},
  created() {
    this.internalWidth = '300px';
    this.internalHeight = '300px';
  },
  data() {
    return {
      files: [],
      loadImageFile: '',
      message: '',
      fileInfo: {
        fileName: '',
        fileSize: '',
        filePath: '',
      },
    };
  },
  methods: {
    inputUpdate(files) {
      this.files = files;
      if (this.type == 'image' && files) {
        this.message = '';
        this.loadImage(files[0].file);
      }
    },
    loadImage(imageFile) {
      let reader = new FileReader();
      reader.onload = e => (this.loadImageFile = e.target.result);
      reader.readAsDataURL(imageFile);
    },
    async onSubmit() {
      const me = this;

      if (this.files.length == 0) {
        this.$alert('파일을 선택해야 합니다.');
        return;
      }
      let formData = new FormData();
      formData.append('files', this.files[0].file);

      let headers = {headers: {'Content-Type': 'multipart/form-data'}};
      let response = await axios.post('/sample/fileUpload', formData, headers).catch(e => {
        console.error('파일 업로드 실패 ::', e);
        me.message = '실패';
      });

      if (response.status == 200) {
        me.message = '성공';
        me.fileInfo.fileName = response.data.fileName;
        me.fileInfo.fileSize = response.data.fileSize;
        me.fileInfo.filePath = response.data.filePath;
      } else {
        me.message = '실패';
      }
    },
    onDelete() {
      this.files = [];
      this.loadImageFile = '';
      this.message = '';
      this.fileInfo.fileName = '';
      this.fileInfo.fileSize = '';
      this.fileInfo.filePath = '';
    },
  },
};
</script>

<style></style>

<template>
  <pmis-tab-box>
    <template v-slot:title>리스트</template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },

  beforeCreate() {
    $mapMutations(this, ['setDetailInfo']);
  },

  created() {
    this.addEvent([{name: 'selectSample2', func: this.selectSample2}]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '번호',
            Name: 'no',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '이름',
            Name: 'name',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '나이',
            Name: 'age',
            Type: 'Text',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.sheet.bind('onFocus', e => {
        if (e.row.Kind == 'Data') {
          this.setDetailInfo(e.row);
        }
      });
    },

    selectSample2() {
      axios.post('/sample2/selectSample2List').then(response => {
        if (response.status == 200) {
          console.log(response.data);
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  },
};
</script>

<style></style>

<template>
  <div :class="rootClass" :style="rootStyle">
    <label v-if="label">{{ label }}</label>
    <input
      type="text"
      v-model="idVdata"
      :name="name"
      :class="keyTextBoxClass"
      :disabled="!(idEditable != idEnable)"
      :readonly="idReadonly"
      :style="idCss"
    />
    <!-- style="width: 90px;" -->
    <button v-if="isSearchBtn" class="search-button" @click="onSearch"></button>
    <input
      type="text"
      v-model="nameVdata"
      :name="nameEleName"
      :class="valueTextBoxClass"
      :disabled="!(!nameEditable != !nameEnable)"
      :readonly="nameReadonly"
    />
    <iui-button v-if="isInitBtn" @click="initSearch" value="x" />

    <iui-modal-old
      :name="searchBoxNm"
      v-if="!isExternal"
      :title="internalTitle"
      :btns="modalBtns"
      width="700px"
      height="600px"
    >
      <iui-searchmodal :type="type" :uid="_uid" :paramObj="paramObj"></iui-searchmodal>
    </iui-modal-old>
    <iui-modal-old v-else :name="comp.compNm" :title="comp.title" :btns="modalBtns" :width="'1100px'">
      <component :is="comp.compNm"></component>
    </iui-modal-old>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

export default {
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  props: {
    type: {
      type: String,
    },
    idEvent: {
      type: Array,
      default: function() {
        return [];
      },
    },
    // idCss: {
    //   type: Object,
    // },
    nameEvent: {
      type: Array,
      default: function() {
        return [];
      },
    },
    nameCss: {
      type: Object,
    },
    value: {
      type: [String, Number],
    },
    idValue: {
      type: [String, Number],
    },
    nameValue: {
      type: [String, Number],
    },
    formObj: {
      type: String,
      default: 'none!',
    },
    idEditable: {
      type: Boolean,
      default: false,
    },
    nameEditable: {
      type: Boolean,
      default: false,
    },
    idEnable: {
      type: Boolean,
      default: false,
    },
    nameEnable: {
      type: Boolean,
      default: false,
    },
    idReadonly: {
      type: Boolean,
      default: false,
    },
    nameReadonly: {
      type: Boolean,
      default: false,
    },
    nameEleId: {
      type: String,
    },
    nameEleName: {
      type: String,
    },
    idBindArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    nameBindArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    comp: {
      type: Object,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    isSearchBtn: {
      type: Boolean,
      default: true,
    },
    isInitBtn: {
      type: Boolean,
      default: false,
    },
    initCallback: {
      type: Function,
      default: () => {},
    },
    confirmEventNm: {
      type: String,
    },
    paramBind: {
      type: String,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
    init: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      idVdata: this.value || this.idValue || '',
      nameVdata: this.nameValue || '',
      searchBoxNm: 'searchBox' + this._uid,
      isExternal: false,
      modalBtns: [{name: '확인', callback: this.onConfirm}],
      mappingKeys: [],
      internalTitle: undefined,
    };
  },
  computed: {
    // class
    rootClass: {
      get() {
        let obj = {
          'iui-searchbox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    keyTextBoxClass: {
      get() {
        let obj = {
          'key-textbox': true,
          _idTarget_: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },
    valueTextBoxClass: {
      get() {
        let obj = {
          'value-textbox': true,
          _nameTarget_: true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.internalWidth !== undefined) {
          obj.width = this.internalWidth;
          obj.flex = `0 0 ${this.internalWidth}`;
        }
        if (this.internalHeight !== undefined) {
          obj.height = this.internalHeight;
        }

        return obj;
      },
    },

    idCss: {
      get() {
        return this.type == 'matCode' ? 'width: 120px;' : 'width: 90px;';
      },
    },
  },
  created: function() {
    this.addEvent([
      {
        name: `${this.type}SearchOnConfirm${this._uid}`,
        func: this.onConfirm,
      },
      {
        name: `${this.confirmEventNm}SearchOnConfirm`,
        func: this.onConfirm,
      },
    ]);
    this.setIdBindArray();
    this.setNameBindArray();
    if (this.comp) {
      this.isExternal = true;
      this.addComponent(this.comp.compNm, this.comp.path);
      if (this.comp.mappingKeys) {
        this.mappingKeys = this.comp.mappingKeys;
      }
    } else {
      // sheet에서 받을 id/name값 지정
      //alert(this.customerYn)
      switch (this.type) {
        case 'customer':
          this.mappingKeys = ['tradeNo', 'tradeName'];
          this.internalTitle = this.paramObj.customerYn == 'Y' ? '고객' : '협력회사';
          break;
        case 'emp':
          this.mappingKeys = ['empId', 'empName'];
          this.internalTitle = '사용자';
          break;
        case 'dept':
          this.mappingKeys = ['teamNo', 'teamNoDesc'];
          this.internalTitle = '부서';
          break;
        /*
        case 'prj':
          this.mappingKeys = ['prjCd', 'prjNm'];
          this.internalTitle = '현장';
          break;
        case 'prjEst':
          this.mappingKeys = ['prjEstCd', 'prjEstNm'];
          this.internalTitle = '현장';
          break;
        case 'matCode':
          this.mappingKeys = ['matCode', 'matName'];
          this.internalTitle = '자재코드';
          break;
        case 'workGroup':
          this.mappingKeys = ['subCode', 'subCodeName'];
          this.internalTitle = '작업반 계약내역';
          break;
        case 'equt':
          this.mappingKeys = ['equipCode', 'itemName'];
          this.internalTitle = '장비코드';
          break;
        case 'worker':
          this.mappingKeys = ['empNo', 'name'];
          this.internalTitle = '근로자';
          break;
        case 'accountGb':
          this.mappingKeys = ['accountGb', 'accountGbName'];
          this.internalTitle = '거래구분';
          break;
        case 'commonCode':
          this.mappingKeys = ['code', 'codeNm'];
          this.internalTitle = '공통코드';
          break;
        case 'companyCode':
          this.mappingKeys = ['pgmCd', 'frmNm'];
          this.internalTitle = '회사';
          break;
        case 'dataCl':
          this.mappingKeys = ['code', 'name'];
          this.internalTitle = '자료분류';
          break;
        case 'user':
          this.mappingKeys = ['empNo', 'name'];
          this.internalTitle = '사용자';
          break;
        */
      }
    }

    // validator 설정
    this.validatorTargetDataProp = 'idVdata';
    this.validationTriggerEventName = ['change'];
  },
  mounted: function() {
    const me = this;
    setTimeout(function() {
      me.setFormData();
      me.setFormData2();
    }, 500);
    //속성 주입
    for (let k in this.$attrs) {
      this.$el.querySelector('._idTarget_').setAttribute(k, this.$attrs[k]);
      if (k == 'id') {
        if (this.$el.querySelector('label')) {
          this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
        }
        if (!this.nameEleName) {
          this.$el.querySelector('._nameTarget_').setAttribute(k, this.$attrs[k] + 'Name');
        }
      }
      if (k == 'name' && !this.nameEleName) {
        this.$el.querySelector('._nameTarget_').setAttribute(k, this.$attrs[k] + 'Name');
      }
      this.$el.removeAttribute(k);
    }
    if (this.nameEleId) {
      this.$el.querySelector('._nameTarget_').setAttribute('id', this.nameEleId);
    }
    if (this.nameEleName) {
      this.$el.querySelector('._nameTarget_').setAttribute('name', this.nameEleName);
    }
    //ID 이벤트 주입
    for (let e of this.idEvent) {
      this.$el.querySelector('._idTarget_').addEventListener(e.name, e.callback);
    }
    // //ID 스타일 주입
    // for (let k in this.idCss) {
    //   this.$el.querySelector('._idTarget_').style[k] = this.idCss[k];
    // }
    //NAME 이벤트 주입
    for (let e of this.nameEvent) {
      this.$el.querySelector('._nameTarget_').addEventListener(e.name, e.callback);
    }
    // //NAME 스타일 주입
    // for (let k in this.nameCss) {
    //   this.$el.querySelector('._nameTarget_').style[k] = this.nameCss[k];
    // }
    // //기본요소 height와 라벨 height 맞춤
    // if (this.$el.querySelector('label')) {
    //   this.$el.querySelector('label').style.height = this.$el.querySelector('._idTarget_').offsetHeight + 'px';
    //   this.$el.querySelector('label').style.lineHeight = this.$el.querySelector('._idTarget_').offsetHeight + 'px';
    // }
    this.idName = this.$el.querySelector('._idTarget_').name;
    this.nmName = this.$el.querySelector('._nameTarget_').name;
    //기본요소 구분하기 위한 class명 삭제
    // this.$el.querySelector("._idTarget_").classList.remove("_idTarget_");
    // this.$el.querySelector("._nameTarget_").classList.remove("_nameTarget_");
  },
  methods: {
    setFormData(value) {
      const me = this;
      setTimeout(function() {
        me.$store.dispatch('setFormData', {
          formObj: me.formObj,
          key: me.idName,
          value: !value ? me.idVdata : value,
          element: me.$el.querySelector('._idTarget_'),
        });
      }, 10);
    },
    setFormData2(value) {
      const me = this;
      setTimeout(function() {
        me.$store.dispatch('setFormData', {
          formObj: me.formObj,
          key: me.nmName,
          value: !value ? me.nameVdata : value,
          element: me.$el.querySelector('._nameTarget_'),
        });
      }, 10);
    },
    onSearch: function() {
      const me = this;
      if (!me.isExternal) {
        switch (me.type) {
          //주소검색
          case 'addr': {
            let win = window.open(
              process.env.VUE_APP_BASE_URL + '/address/jusoPopup',
              'jusoPop',
              'width=570,height=420, scrollbars=yes, resizable=yes'
            );
            var interval = window.setInterval(function() {
              try {
                if (win == null || win.closed) {
                  window.clearInterval(interval);
                  if (me.callback && Object.keys(me.$store.getters.externalData).length) {
                    me.callback(me.$store.getters.externalData);
                    me.$store.dispatch('setExternalData', {});
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }, 1000);
            break;
          }
          default: {
            this.$store.commit(
              'setModalParam',
              this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind]
            );
            me.$modal.show(this.searchBoxNm);
            break;
          }
        }
      } else {
        // this.$store.getters["modalParam"] = this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind];
        this.$store.commit('setModalParam', this.$store.getters[this.$store.getters.currentUrl + '/' + this.paramBind]);
        this.$modal.show(this.comp.compNm);
      }
    },
    setIdBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.idBindArray.forEach(function(o) {
          let payload = {};
          payload[me.idName] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    setNameBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.nameBindArray.forEach(function(o) {
          let payload = {};
          payload[me.nmName] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    onConfirm() {
      const me = this;
      let name;
      switch (me.type) {
        case 'customer':
          name = 'CustomerList_setData';
          break;
        case 'emp':
          name = 'EmpList_setData';
          break;
        case 'dept':
          name = 'DeptList_setData';
          break;
        /*
        case 'prj':
          name = 'onFocusedRowDataOnContract';
          break;
        case 'prjEst':
          name = 'onFocusedRowDataOnPrjEst';
          break;
        case 'matCode':
          name = 'MatCode_DetailList_setData';
          break;
        case 'workGroup':
          // name = 'WorkGroup_ContractList_confirm';
          name = 'Sub_ContractList_confirm_popup';
          break;
        case 'equt':
          name = 'onFocusedRowDataOnEqut';
          break;
        case 'worker':
          name = 'onFocusedRowDataOnWorker';
          break;
        case 'accountGb':
          name = 'AccountGb_data';
          break;
        case 'commonCode':
          name = 'CommonCode_setData';
          break;
        case 'companyCode':
          name = 'companyCode_setData';
          break;
        case 'dataCl':
          name = 'dataCl_setData';
          break;
        case 'user':
          name = 'User_setData';
          break;
        */
        default:
          name = me.confirmEventNm;
          break;
      }
      this.callEvent({
        name: name,
        param: data => {
          if (this.mappingKeys.length) {
            for (const [i, v] of this.mappingKeys.entries()) {
              if (i == 0) {
                this.idVdata = String(data[v]);
                let evt = document.createEvent('HTMLEvents');
                evt.initEvent('onChange', false, true);
                this.$el.querySelector('._idTarget_').dispatchEvent(evt);
              } else {
                this.nameVdata = String(data[v]);
              }
            }
          }
          if (this.callback) {
            this.callback(data);
          }
          if (this.isExternal) {
            this.$modal.hide(this.comp.compNm);
          } else {
            this.$modal.hide(this.searchBoxNm);
          }
        },
      });
    },
    initSearch() {
      for (let el of this.$el.childNodes) {
        if (el.localName == 'input') el.value = '';
      }
      this.idVdata = '';
      this.nameVdata = '';
      this.initCallback();
    },
  },
  watch: {
    value: function(value) {
      this.idVdata = value;
    },
    idVdata: function(value) {
      this.setIdBindArray(value);
      this.setFormData(value);

      this.$emit('change', value);
    },
    idValue: function(value) {
      this.idVdata = value;

      this.$emit('change', value);
    },
    nameVdata: function(value) {
      this.setNameBindArray(value);
      this.setFormData2(value);
    },
    nameValue: function(value) {
      this.nameVdata = value;
    },
    init(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.initSearch();
        this.$emit('update:init', false);
      }
    },
  },
};
</script>

<style></style>

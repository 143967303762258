<template>
  <pmis-content-box>
    <template v-slot:title>입찰정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header>현장</i-col-header>
        <i-col colspan="8">
          <iui-searchbox :type="'prj'" :isSearchBtn="false" :idValue="spotDeptcd" :nameValue="spotDeptnm" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>입찰공고번호</i-col-header>
        <i-col colspan="8">
          <iui-text :enable="false" width="90px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>입찰제목</i-col-header>
        <i-col colspan="8">
          <iui-text :value.sync="ttl" max-length="166" required />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>입찰담당</i-col-header>
        <i-col colspan="8">
          <iui-searchbox
            :idValue.sync="this.userInfo.empNo"
            :nameValue.sync="this.userInfo.name"
            :type="'worker'"
            :idEditable="false"
            :nameEditable="false"
            :paramBind="'searchInfo'"
            :isSearchBtn="false"
          />
        </i-col>
        <i-spacer />
        <i-col-header>계약구분</i-col-header>
        <i-col colspan="8">
          <iui-text value="일반계약" :enable="false" width="100px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>현장설명유무</i-col-header>
        <i-col colspan="8">
          <iui-radio-group-old :value.sync="spotDscrYn" p-code="0380" @change="spotDscrYn = $event.target.value" />
        </i-col>
      </i-row>
      <i-row v-if="spotDscrYn == '000002'">
        <i-spacer />
        <i-col-header>현설일자</i-col-header>
        <i-col colspan="8">
          <iui-datepicker-old :value.sync="spotDscrDtYmd" />
          <iui-text width="40px" :value.sync="spotDscrDtHH" />
          <span class="mr5">:</span>
          <iui-text width="40px" :value.sync="spotDscrDtMM" />
        </i-col>
        <i-col-header>현설담당</i-col-header>
        <i-col colspan="8">
          <iui-searchbox
            :type="'worker'"
            :idEditable="false"
            :nameEditable="false"
            :paramBind="'searchInfo'"
            :idValue="spotDscrUsrcd"
            :nameValue="spotDscrUsrnm"
          />
        </i-col>
      </i-row>
      <i-row v-if="spotDscrYn == '000002'">
        <i-spacer />
        <i-col-header>현설장소</i-col-header>
        <i-col colspan="7">
          <iui-text :value.sync="spotDscrLoc" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>입찰마감일시</i-col-header>
        <i-col colspan="8">
          <iui-datepicker-old :value.sync="bidExpireDtYmd" required />
          <iui-text width="40px" max-length="2" :value.sync="bidExpireDtHH" required />
          <span class="mr5">:</span>
          <iui-text width="40px" max-length="2" :value.sync="bidExpireDtMM" required />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>지급조건</i-col-header>
        <i-col colspan="8">
          <iui-text label="현금" type="rate" width="90px" :value.sync="payCashRatio" max-length="3" required />
          <span class="mr5"></span>
          <iui-text label="어음" type="rate" width="90px" :value.sync="payCheckRatio" max-length="3" required />
          <span class="mr5"></span>
          <iui-text type="number" suffix="일 이내" width="80px" :value.sync="payTerm" max-length="3" required />
        </i-col>
        <i-spacer />
        <i-col-header>과세유형</i-col-header>
        <i-col colspan="8">
          <iui-text :value="taxTypenm" :enable="false" width="100px" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>납품요구일</i-col-header>
        <i-col colspan="8">
          <iui-datepicker-old :value.sync="dlvReqDt" required />
        </i-col>
        <i-spacer />
        <i-col-header required>납품조건</i-col-header>
        <i-col colspan="8">
          <iui-select-old p-code="0345" defaultCd="S" :value.sync="dlvCondRmrk" required></iui-select-old>
        </i-col>
      </i-row>
      <i-row>
        <i-col-header required>납품장소</i-col-header>
        <i-col colspan="8">
          <iui-text :value="dlvLoc" max-length="166" required />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>비고</i-col-header>
        <i-col colspan="8">
          <iui-text type="multi" :value.sync="rfqRmrk" max-length="466" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>등록일자</i-col-header>
        <i-col colspan="8">
          <iui-datepicker-old :value="regDt" :readonly="true" />
        </i-col>
        <i-spacer />
        <i-col-header>상태</i-col-header>
        <i-col colspan="8">
          <iui-text value="작성중" :enable="false" width="100px" />
        </i-col>
      </i-row>
    </iui-container>
    <iui-container type="table" theme="bullet">
      <i-row height="215px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>자재내역</template>
            <template v-slot:header-left>
              <label>공급가액</label>
              <iui-text type="amount" :value.sync="price" :enable="false" />
              <div class="ml15" />
              <label>부가세액</label>
              <iui-text type="amount" :value.sync="vat" :enable="false" />
              <div class="ml15" />
              <label>합계금액</label>
              <iui-text type="amount" :value.sync="totalPrice" :enable="false" />
            </template>
            <iui-container type="ibsheet" :id="elId" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>

    <iui-container type="table" theme="bullet">
      <i-row height="215px">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>첨부파일</template>
            <pmis-file-list
              v-model="fileList_files"
              :company-code="userInfo.pgmCd"
              :file-number.sync="fileList_fileNumber"
              :save.sync="fileList_save"
              :saveButtonVisible="false"
              @save-complete="onFileSaveComplete"
              toolbar-visible
            />
          </pmis-content-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <template v-slot:title>협력사목록</template>
            <template v-slot:header-right>
              <iui-button value="협력사선택" @click="onCcpyList" />
              <iui-button value="삭제" @click="onDelCcpy" />
            </template>
            <iui-container type="ibsheet" :id="elId2" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
    <iui-modal-old name="ccpyList" title="협력사정보" width="1350px" height="900px" :btns="modalBtns">
      <iui-ccpy-list :pgmCd="userInfo.pgmCd" />
    </iui-modal-old>
  </pmis-content-box>
</template>

<script>
import padStart from 'lodash/padStart';
export default {
  props: {
    prInfo: {
      type: Object,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      elId2: 'sheetDiv2' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      taxSubType: 'percent',
      taxSubTypeItems: [
        {text: '%', value: 'percent'},
        {text: '금액', value: 'amount'},
      ],
      fileList_files: [],
      fileList_fileNumber: 0,
      fileList_save: false,
      prNo: '', //입찰요청번호
      rfqSt: '000001', //입찰상태
      ttl: '', //입찰요청명
      taxTypecd: '', //과세구분
      taxTypenm: '', //과세구분명
      vatAmt: '', //부가가치세
      taxRate: '', //부가가치세율
      spotDscrYn: '', //현설유무
      dlvReqDt: '', //납품요구일
      dlvLoc: '', //납품장소
      dlvCondRmrk: '', //납품조건
      rfqRmrk: '', //입찰 비고
      chrgUsrcd: '', //구매요청자
      regDt: dateFormat(new Date()), //요청일자
      nullEstiFile: '', // 파일번호
      prSt: '', // 입찰요청상태
      prTypecd: '', // 자재공사구분
      quickYn: 'N', // 긴급여부
      grChrgUsrcd: '', // 입고담당자
      chkChrgUsrcd: '', // 검수담당자
      spotDscrDtYmd: '', // 현설일자
      spotDscrDtHH: '',
      spotDscrDtMM: '',
      spotDscrLoc: '', // 현설장소
      spotDscrUsrcd: '', // 현설담당자코드
      spotDscrUsrnm: '', // 현설담당자명
      spotDeptcd: '', //현장코드
      spotDeptnm: '', //현장명
      bidExpireDtYmd: '', //입찰마감일자
      bidExpireDtHH: '', //입찰마감일시
      bidExpireDtMM: '', //입찰마감일분
      payCashRatio: '', //현금비율
      payCheckRatio: '', //어음비율
      payTerm: '', //지급주기
      poTypecd: '000001', //계약구분

      vat: 0,
      price: 0,
      totalPrice: 0,
      modalBtns: [{name: '확인', callback: this.onCcpyListConfirm}],
      excludeKeys: ['ttl', 'rfqSt'],
      rfqNo: '',
      rfqCus: [],
    };
  },
  watch: {
    detailInfo: {
      deep: true,
      handler(val) {
        if (!val.nullEstiFile) {
          this.fileList_fileNumber = 0;
          this.fileList_files = [];
        } else {
          this.fileList_fileNumber = val.nullEstiFile;
        }
      },
    },
  },

  beforeCreate() {
    $mapGetters(this, {searchInfo: 'searchInfo', detailInfo: 'detailInfo', requestDtls: 'requestDtls'});
    $mapMutations(this, ['setDetailInfo']);
  },

  created() {
    this.addEvent([{name: 'bidActplan_onSave', func: this.onSave}]);

    if (this.prInfo) {
      this.setForm(this.prInfo);
    }
  },

  mounted() {
    this.initSheet();
    this.initSheet2();

    if (this.prInfo.rfqNo) this.selectBidActpInWritingInfo({rfqNo: this.prInfo.rfqNo});
    else this.selectItemList({prNo: this.prNo});
  },

  methods: {
    onFileSaveComplete() {
      this.callback({
        fileList: this.fileList_files,
        fileNumber: this.fileList_fileNumber,
      });
    },

    onFileSave(callback) {
      if (callback) {
        if (this.fileList_files.length) {
          this.fileList_save = true;
          this.callback = callback;
        } else {
          callback({});
        }
      }
    },
    setVat(e, s) {
      let totalPrice = 0;
      this.requestDtls.forEach(el => {
        totalPrice += el.planUnitPrc;
      });
      if (s == 'rate') {
        this.setDetailInfo({vatAmt: Math.round((totalPrice / 100) * this.taxRate)});
      } else {
        var rate = (this.vatAmt / totalPrice) * 100;
        var scale = Math.pow(10, 5);
        rate = Math.round(rate * scale) / scale;
        this.setDetailInfo({taxRate: rate});
      }
    },
    initFile() {
      this.fileList_files = [];
      this.fileList_fileNumber = 0;
    },
    initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '자재코드',
            Name: 'itemCd',
            Type: 'Text',
            Align: 'Center',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '명칭',
            Name: 'itemNm',
            Type: 'Text',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '규격',
            Name: 'ssize',
            Type: 'Text',
            Align: 'Center',
            Width: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '단위',
            Name: 'unitNm',
            Type: 'Text',
            Width: 90,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '수량',
            Name: 'qty',
            Extend: this.$ibsheetHelper.preset.quantity,
            Width: 100,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '단가',
            Name: 'planUnitPrc',
            Extend: this.$ibsheetHelper.preset.unitAmount,
            Width: 100,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '금액',
            Name: 'amt',
            Extend: this.$ibsheetHelper.preset.amount,
            Width: 100,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '비고',
            Name: 'itemRmrk',
            Type: 'Text',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.vat = 0;
      this.price = 0;
      this.totalPrice = 0;
    },
    initSheet2() {
      const OPT = {
        Cols: [
          {
            Header: '선택',
            Name: 'chk',
            Type: 'Bool',
            Align: 'center',
            Width: 50,
            CanEdit: 1,
            BoolIcon: 0,
            Visible: 1,
          },
          {
            Header: '협력사명',
            Name: 'frmNm',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '담당자명',
            Name: 'icprNm',
            Type: 'Text',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '전화번호',
            Name: 'icprPhone',
            Type: 'Text',
            Align: 'Center',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '이메일',
            Name: 'icprMail',
            Type: 'Text',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '상태',
            Name: '상태',
            Type: 'Text',
            Width: 100,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet2) this.sheet2.dispose();
      this.sheet2 = IBSheet.create({
        id: this.sheetId2, // 시트 객체 ID
        el: this.elId2, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
    },
    onCcpyList() {
      this.$modal.show('ccpyList');
    },
    onDelCcpy() {
      let checkedRows = this.sheet2.getRowsByChecked('chk');
      checkedRows.some(el => {
        this.sheet2.removeRow({row: el});
      });
    },
    onCcpyListConfirm() {
      this.callEvent({
        name: 'ccpyList_confirm',
        param: data => {
          this.$modal.hide('ccpyList');
          this.sheet2.loadSearchData(data);
        },
      });
    },
    setForm(prInfo) {
      if (prInfo) {
        for (let key in prInfo) {
          if (this.$data.hasOwnProperty(key) && this.excludeKeys.indexOf(key) == -1) {
            this[key] = prInfo[key];

            if (key == 'taxTypecd') {
              $getCode(this, '0290').then(response => {
                this.taxTypenm = response.find(o => o.code == prInfo.taxTypecd).codeNm;
              });
            }
          }
        }
      }
    },
    selectItemList(param) {
      axios.post('/purchsRequestRegist/selectPritemList', param).then(response => {
        if (response.status == 200) {
          let rows = response.data;
          this.price = 0;
          rows.forEach(el => {
            el.amt = el.qty * el.planUnitPrc;
            this.price += el.planUnitPrc;
          });
          this.vat = Math.floor(this.price * 0.1);
          this.totalPrice = this.price + this.vat;

          this.sheet.loadSearchData(rows);
        }
      });
    },

    async onSave(callback) {
      let validate = await this.validate();
      if (!validate.isValid) {
        this.$alert('필수입력값이 입력되지 않았습니다.');
        return;
      }

      let cusRows = this.sheet2.getDataRows();
      if (!cusRows.length) {
        this.$alert('협력사를 추가해주세요');
        return;
      }
      this.rfqCus = [];
      for (let cus of cusRows) {
        this.rfqCus.push(cus.bsnNo);
      }

      let spotDscrDt = dateFormat(this.spotDscrDtYmd) + ' ';
      spotDscrDt += padStart(this.spotDscrDtHH, 2, '0') + ':';
      spotDscrDt += padStart(this.spotDscrDtMM, 2, '0');
      spotDscrDt += ':00';

      let bidExpireDt = dateFormat(this.bidExpireDtYmd) + ' ';
      bidExpireDt += padStart(this.bidExpireDtHH, 2, '0') + ':';
      bidExpireDt += padStart(this.bidExpireDtMM, 2, '0');
      bidExpireDt += ':00';

      let param = {
        rfqNo: this.rfqNo,
        prNo: this.prNo,
        rfqSt: this.rfqSt,
        orgCd: this.searchInfo.orgCd,
        spotDeptcd: this.prInfo.spotDeptcd,
        ttl: this.ttl,
        spotDscrYn: this.spotDscrYn,
        spotDscrDt: this.spotDscrDtYmd ? spotDscrDt : '',
        spotDscrLoc: this.spotDscrLoc,
        bidExpireDt: bidExpireDt,

        payCashRatio: this.payCashRatio,
        payCheckRatio: this.payCheckRatio,
        payTerm: this.payTerm,
        taxTypecd: this.taxTypecd,
        dlvReqDt: this.dlvReqDt,
        dlvCondRmrk: this.dlvCondRmrk,
        dlvLoc: this.dlvLoc,
        rfqRmrk: this.rfqRmrk,
        rfqCusNoList: this.rfqCus,
        poTypecd: this.poTypecd,
      };

      await axios.post('/bidActpInWriting/saveRfqInfo', param);

      callback();
    },
    selectBidActpInWritingInfo(param) {
      axios.post('/bidActpInWriting/selectBidActpInWritingInfo', param).then(response => {
        if (response.status == 200) {
          let data = response.data;
          this.setForm(data);
          this.ttl = data.ttl;
          this.bidExpireDtYmd = data.bidExpireDt.substr(0, 10);
          this.bidExpireDtHH = data.bidExpireDt.substr(11, 2);
          this.bidExpireDtMM = data.bidExpireDt.substr(14, 2);

          this.sheet.loadSearchData(data.tbRfqitemVoList);
          this.sheet2.loadSearchData(data.tbRfqCusVoList);
        }
      });
    },
  },
};
</script>

<style scoped></style>

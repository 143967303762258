/* NE > 금액확정통보 > 상세 TAB */
<script>
import {reactive, watch, computed, onMounted} from '@vue/composition-api';
import {numToKr} from 'vue-number-to-kor';
import HistoryList from '@/view/common/components/HistoryList.vue';

const ibsheetOptions1 = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    {
      Header: '구분',
      Name: 'tradeTypeNm',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: 'tradeType',
      Name: 'tradeType',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'displayTradeNo',
      Name: 'displayTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: 'tradeNo',
      Name: 'tradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: '대표사여부',
      Name: 'suppMainFlag',
      Type: 'Text',
      Width: 90,
      Align: 'Center',
    },
    {
      Header: '상호(법인) / 이름(개인/개인사업자)',
      Name: 'tradeName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: '대표자',
      Name: 'repntName',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },

    {
      Header: '사업자번호',
      Name: 'businessNo',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
    {
      Header: '주소',
      Name: 'address',
      Type: 'Text',
      Width: 100,
      Align: 'Left',
      Visible: 0,
    },
  ],
};

export default {
  components: {HistoryList},
  props: {
    confirmationKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },

  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props) {
    const state = reactive({
      loading: false,

      // 1. 고객
      grid1: undefined,
      gridOptions1: ibsheetOptions1,

      memberInfo: {
        tradeType: 'CUST',
        tradeTypeNm: '구분',
        businessNo: undefined, // 사업자(주민)번호
        tradeName: undefined, // 상호(이름)
        repntName: undefined, // 대표자
        address: undefined, // 주소
      },

      // 2. 계약 정보
      contractInfo: {
        projectNo: undefined, // 프로젝트번호
        projectName: undefined, //프로젝트 명
        custTradeNo: undefined, //고객번호
        custTradeName: undefined, //고객명
        contDate: undefined, // 계약일자
        deliveryDate: undefined, //납기일, 변경 후 > 납기일
        contTotAmt: undefined, //총금액, 변경 후 > 총 계약 금액
        contTotAmtKor: undefined, //총금액
        contSupplyAmt: undefined, // 공급금액
        contSupplyAmtKor: undefined, // 공급금액
        contVatAmt: undefined, //부가세액
        contVatAmtKor: undefined, //부가세액
        instAddress: undefined, //설치장소
      },
      isChangeContract: computed(() => {
        //return false; //props.confirmationKey !== undefined && props.confirmationKey.tradeType === 'CUST';
        return state.memberInfo !== undefined && state.memberInfo.tradeType === 'CUST';
      }),
    });

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      onDataLoad() {},
      onClick(e) {
        // Header 클릭
        if (e.row.id == 'Header') {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);
        state.memberInfo = rowValue;
        console.log(' state.memberInfo', rowValue);
      },
    };

    const method = {
      btn_click() {
        context.emit('change', true);
      },

      async getData() {
        state.loading = true;

        const param = {
          ctrtNo: props.confirmationKey.ctrtNo,
          ctrtOdr: props.confirmationKey.ctrtOdr,
          ctrtDivCd: props.confirmationKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/ne/confirmation/selectConfirmationView', param);

          // 1. 계약 대상자
          state.memberList = response.data.memberList;

          console.log('view > memberInfo', state, state.memberInfo);

          // 2. 계약정보
          state.contractInfo = response.data;
          const contractInfo = state.contractInfo;

          console.log('view > state', state, contractInfo);

          state.contractInfo.contTotAmtKor = numToKr(contractInfo.contTotAmt); // 총계약금액
          state.contractInfo.contSupplyAmtKor = numToKr(contractInfo.contSupplyAmt); // 총공급금액
          state.contractInfo.contVatAmtKor = numToKr(contractInfo.contVatAmt); // 총공급금액

          // if (state.isChangeContract) {
          //   state.contractInfo.befContTotAmtKor = numToKr(contractInfo.befContTotAmt); // 변경전 총계약금액
          //   state.contractInfo.chngAmtKor = numToKr(contractInfo.chngAmt); // 변경후 > 변경 계약금액
          // }
          // if (!state.isChangeContract) {
          //   state.contractInfo.contSupplyAmtKor = numToKr(contractInfo.contSupplyAmt); // 공급금액
          //   state.contractInfo.contVatAmtKor = numToKr(contractInfo.contVatAmt); // 부가세액
          // }
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },
    };

    watch(
      () => props.confirmationKey,
      newValue => {
        state.grid1.removeAll();

        state.memberInfo = {}; // 고객정보
        state.contractInfo = {}; //계약정보
        state.memberInfo.tradeTypeNm = '구분';
        state.memberInfo.tradeType = 'CUST';

        if (newValue === undefined) {
          return;
        }
        method.getData();
      }
    );

    onMounted(() => {
      if (props.confirmationKey) {
        // state.contractInfo.tradeType = {};
        method.getData();
      }
    });

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <!-- <iui-content-box :loading="state.loading"> -->
  <iui-tab-group :loading="state.loading">
    <iui-tab title="계약 대상자">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.contractInfo.memberList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container v-show="state.isChangeContract" type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="4" width="80px">{{ state.memberInfo.tradeTypeNm }}</i-col-header>
                <i-col-header width="100px">사업자(주민)번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호(이름)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.repntName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.address" disable />
                </i-col>
              </i-row>
            </iui-container>
            <iui-container v-show="!state.isChangeContract" type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="7" width="80px">{{ state.memberInfo.tradeTypeNm }}</i-col-header>
                <i-col-header width="100px">사업자(주민)번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호(이름)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.repntName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.address" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="계약 정보">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header colspan="2">계약번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtNo" disable />
                </i-col>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">프로젝트</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.projectName" disable />
                </i-col>
                <i-col-header colspan="2">고객명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.custTradeNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.custTradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">계약 일자</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.contDate" disable />
                </i-col>
                <i-col-header colspan="2">설치장소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.instAddress" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="3">계약금액</i-col-header>
                <i-col-header>총금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contTotAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contTotAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
              </i-row>
              <i-row>
                <i-col-header>공급금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contSupplyAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contSupplyAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
              </i-row>
              <i-row>
                <i-col-header>부가세액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contVatAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contVatAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :confirmation-key="confirmationKey" />
    </iui-tab>
  </iui-tab-group>
  <!-- </iui-content-box> -->
</template>

<style></style>

<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container type="css-flex" theme="bullet">
      <i-row>
        <i-col style="padding:0px;">
          <iframe style="width:100%;height:100%;margin:0px;" ref="ifbi" border="0" frameborder="no"> </iframe>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  beforeCreate() {},
  async created() {},
  data() {
    return {
      theme: 'bullet',
    };
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {

      const data = {          
          CMN_U_CD: 'EXTLINK',
          CMN_D_CD: 'POWERBI',
        };
      try {
        const response = await axios.post('/extlink/common/powerbi', data);
        console.log("response.data",response.data);
        this.$refs.ifbi.src = response.data.RMK;
      } catch (ex) {        
        console.log(ex);
      }
      // this.$refs.ifbi.src="http://okrda0d/powerbi/?id=59898497-443e-4bfd-880c-2f0f69a31986&formatLocale=ko-KR&hostdata=%7B%22Build%22:%2215.0.1105.230%22,%22ExternalUser%22:%22True%22,%22IsPublicBuild%22:true,%22Host%22:%22Microsoft.ReportingServices.Portal.Services%22,%22HashedUserId%22:%228A948F7175623BC0C94A91E8DF6357B2F39C736631FF4065C7B88C6F4A663019%22,%22InstallationId%22:%221cf574df-1018-42d7-accb-70650991a9e7%22,%22IsEnabled%22:true,%22Edition%22:%22PBIRS%20SQL%20EESA%22,%22AuthenticationTypes%22:%22RSWindowsNTLM%22,%22NumberOfProcessors%22:1,%22NumberOfCores%22:6,%22IsVirtualMachine%22:false,%22MachineId%22:%22F738E264E6297A4EEF4C17CE9170A4D0%22,%22CountInstances%22:2,%22Count14xInstances%22:0,%22Count13xInstances%22:0,%22Count12xInstances%22:1,%22Count11xInstances%22:0,%22ProductSku%22:%22SSRSPBI%22%7D";
      // this.$refs.ifbi.src =
      //   'http://okrda0d/powerbi/?id=37e58d1d-88ef-42b3-aee9-f7f5833bf3f9&formatLocale=ko&hostdata=%7B%22Build%22:%2215.0.1105.230%22,%22ExternalUser%22:%22True%22,%22IsPublicBuild%22:true,%22Host%22:%22Microsoft.ReportingServices.Portal.Services%22,%22HashedUserId%22:%221EB450D7A7C61E4513AB26A6B88C404DB46ED9D394AD9BC506100C35B4E48554%22,%22InstallationId%22:%221cf574df-1018-42d7-accb-70650991a9e7%22,%22IsEnabled%22:true,%22Edition%22:%22PBIRS%20SQL%20EESA%22,%22AuthenticationTypes%22:%22RSWindowsNTLM%22,%22NumberOfProcessors%22:1,%22NumberOfCores%22:6,%22IsVirtualMachine%22:false,%22MachineId%22:%22F738E264E6297A4EEF4C17CE9170A4D0%22,%22CountInstances%22:2,%22Count14xInstances%22:0,%22Count13xInstances%22:0,%22Count12xInstances%22:1,%22Count11xInstances%22:0,%22ProductSku%22:%22SSRSPBI%22%7D';
      //this.$refs.ifbi.src =
      //  'http://okrda0d:80/powerbi/?id=9f8044b2-0a88-40ea-a6be-6c33c70a1b46&amp;formatLocale=ko&amp;hostdata={&quot;Build&quot;:&quot;15.0.1108.297&quot;,&quot;ExternalUser&quot;:&quot;True&quot;,&quot;IsPublicBuild&quot;:true,&quot;Host&quot;:&quot;Microsoft.ReportingServices.Portal.Services&quot;,&quot;HashedUserId&quot;:&quot;FB7D79ED7735318BA85487BC46F4EECDF92D5D85205BA74D8BFC3B355DB821C6&quot;,&quot;InstallationId&quot;:&quot;1cf574df-1018-42d7-accb-70650991a9e7&quot;,&quot;IsEnabled&quot;:true,&quot;Edition&quot;:&quot;PBIRS SQL EESA&quot;,&quot;AuthenticationTypes&quot;:&quot;RSWindowsNTLM&quot;,&quot;NumberOfProcessors&quot;:1,&quot;NumberOfCores&quot;:6,&quot;IsVirtualMachine&quot;:false,&quot;MachineId&quot;:&quot;F738E264E6297A4EEF4C17CE9170A4D0&quot;,&quot;CountInstances&quot;:2,&quot;Count14xInstances&quot;:0,&quot;Count13xInstances&quot;:0,&quot;Count12xInstances&quot;:1,&quot;Count11xInstances&quot;:0,&quot;ProductSku&quot;:&quot;SSRSPBI&quot;,&quot;PortalVersion&quot;:&quot;2&quot;}';
      //this.$refs.ifbi.src = 'http://okrda0d/powerbi/?id=29d08f0f-f817-4956-849f-a63c06005733&formatLocale=ko-KR&hostdata={%22Build%22:%2215.0.1108.297%22,%22ExternalUser%22:%22True%22,%22IsPublicBuild%22:true,%22Host%22:%22Microsoft.ReportingServices.Portal.Services%22,%22HashedUserId%22:%22BE32BFE9B4CAFCA475CF0D8E753AC52F52609C3138C5CB5FB7E63DAC8552462B%22,%22InstallationId%22:%221cf574df-1018-42d7-accb-70650991a9e7%22,%22IsEnabled%22:true,%22Edition%22:%22PBIRS%20SQL%20EESA%22,%22AuthenticationTypes%22:%22RSWindowsNTLM%22,%22NumberOfProcessors%22:1,%22NumberOfCores%22:6,%22IsVirtualMachine%22:false,%22MachineId%22:%22F738E264E6297A4EEF4C17CE9170A4D0%22,%22CountInstances%22:2,%22Count14xInstances%22:0,%22Count13xInstances%22:0,%22Count12xInstances%22:1,%22Count11xInstances%22:0,%22ProductSku%22:%22SSRSPBI%22,%22PortalVersion%22:%222%22}'
      //this.$refs.ifbi.src = 'http://okrda0d/powerbi/?id=0b951812-3772-42e5-9032-204d3b80d768&formatLocale=ko-KR&hostdata={%22Build%22:%2215.0.1108.297%22,%22ExternalUser%22:%22True%22,%22IsPublicBuild%22:true,%22Host%22:%22Microsoft.ReportingServices.Portal.Services%22,%22HashedUserId%22:%22BE32BFE9B4CAFCA475CF0D8E753AC52F52609C3138C5CB5FB7E63DAC8552462B%22,%22InstallationId%22:%221cf574df-1018-42d7-accb-70650991a9e7%22,%22IsEnabled%22:true,%22Edition%22:%22PBIRS%20SQL%20EESA%22,%22AuthenticationTypes%22:%22RSWindowsNTLM%22,%22NumberOfProcessors%22:1,%22NumberOfCores%22:6,%22IsVirtualMachine%22:false,%22MachineId%22:%22F738E264E6297A4EEF4C17CE9170A4D0%22,%22CountInstances%22:2,%22Count14xInstances%22:0,%22Count13xInstances%22:0,%22Count12xInstances%22:1,%22Count11xInstances%22:0,%22ProductSku%22:%22SSRSPBI%22,%22PortalVersion%22:%222%22}'
      //this.$refs.ifbi.src = 'http://okrda0d/powerbi/?id=6a7d4db4-67d1-47e5-b2ab-5c666bf62e5d&formatLocale=ko-KR&hostdata={%22Build%22:%2215.0.1108.297%22,%22ExternalUser%22:%22True%22,%22IsPublicBuild%22:true,%22Host%22:%22Microsoft.ReportingServices.Portal.Services%22,%22HashedUserId%22:%22BE32BFE9B4CAFCA475CF0D8E753AC52F52609C3138C5CB5FB7E63DAC8552462B%22,%22InstallationId%22:%2210eefe94-b040-456e-add1-9480cf4f9cbd%22,%22IsEnabled%22:true,%22Edition%22:%22PBIRS%20Developer%22,%22AuthenticationTypes%22:%22RSWindowsNTLM%22,%22NumberOfProcessors%22:1,%22NumberOfCores%22:6,%22IsVirtualMachine%22:false,%22MachineId%22:%225FBC8537A1AEEC74D996D6188A0A3FFF%22,%22CountInstances%22:1,%22Count14xInstances%22:0,%22Count13xInstances%22:0,%22Count12xInstances%22:0,%22Count11xInstances%22:0,%22ProductSku%22:%22SSRSPBI%22,%22PortalVersion%22:%222%22}'
    },
  },
  computed: {},
  beforeDestroy() {},
};
</script>

<style></style>

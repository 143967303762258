<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>자동연장공문 계약정보</template>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header>이전차수 계약기간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.beforeCheckContStartDate" suffix="~" :enable="false" />
                <iui-datepicker-old :value="contractInfo.beforeCheckContFinDate" :enable="false" />
              </i-col>
              <i-col-header>변경 계약기간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.checkContStartDate" suffix="~" :enable="false" />
                <iui-datepicker-old :value="contractInfo.checkContFinDate" :enable="false" />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>송신이력</template>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header>1차 공문 송부일시</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.frstSendngDt" :enable="false" />
              </i-col>
              <i-col-header>2차 안내문 송부일시</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.scdSendngDt" :enable="false" />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>공문 조회 현황</template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'OContractTabContractChangeInfo_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      sheetId: 'sheet' + this._uid,
    };
  },
  mounted() {
    const me = this;
    $('#' + me.sheetId).jqGrid({
      height: 100,
      width: 800,
      colModel: [
        {name: 'agreSn', label: '동의순번', width: 0, hidden: true},
        {name: 'ctrtNo', label: '계약번호', width: 0, hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, hidden: true},
        {name: 'tradeNo', label: '거래선번호', width: 0, hidden: true},
        {name: 'agreSttusCd', label: '동의상태코드', width: 0, hidden: true},
        {name: 'agreSttusNm', label: '동의상태', width: 50, align: 'center'},
        {name: 'processResultCd', label: '처리결과코드', width: 0, hidden: true},
        {name: 'processResultNm', label: '처리결과', width: 0, hidden: true},
        {name: 'tradeNm', label: '고객명', width: 100, hidden: true},
        {name: 'ofldcRcverNm', label: '수신자명', width: 100, align: 'center'},
        {name: 'ofldcRcverMoblphonNo', label: '수신자 전화번호', width: 100, align: 'center'},
        {name: 'ofldcRcverEmail', label: '수신자 Email', width: 100, align: 'center'},
        {
          name: 'ofldcInqireDt',
          label: '조회 일자',
          width: 100,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d H:i:s A'},
        },
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });

    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);
    },
    async onLoad() {
      console.log('OContractTabContractChangeInfo_Load');

      let param = {
        ctrtNo: this.contractInfo.ctrtNo,
        ctrtOdr: this.contractInfo.ctrtOdr,
        ctrtDivCd: this.contractInfo.ctrtDivCd,
      };
      let response = await axios.post('/ocontract/selectAgreementList', param);

      $('#' + this.sheetId)
        .clearGridData(true)
        .setGridParam({data: response.data.agreementList})
        .trigger('reloadGrid');
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

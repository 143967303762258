/* MOD > 금액확정통보 > 목록 * /
<script>
import {reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js';
//import moment from 'moment';

const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  LeftCols: [
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      ButtonText: '보기',
    },
    {
      Header: ['계약번호', '계약번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 150,
      Align: 'Center',
    },
    {
      Header: ['차수', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
  ],
  Cols: [
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      Width: 200,
      Align: 'Left',
    },
    {
      Header: ['고객', '고객번호'],
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Formula: 'Row.custTradeNo == "0000000" ? "" : Row.custTradeNo',
    },
    {
      Header: ['고객', '고객명'],
      Name: 'custTradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
    },
    {
      Header: ['계약일자', '계약일자'],
      Name: 'contDate',
      Type: 'Date',
      Width: 100,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },

    {
      Header: ['계약금액', '계약금액'],
      Name: 'contTotAmt',
      Type: 'Int',
      Width: 100,
      Format: '#,###',
      Align: 'Right',
    },

    {
      Header: ['계약담당자', '계약담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 90,
      Align: 'Center',
    },
    {
      Header: ['확정월\n(PERIOD)', '확정월\n(PERIOD)'],
      Name: 'closeoutPeriod',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CustomFormat: function(v) {
        return v.substr(2, 2) + 'P' + v.substr(4, 2);
      },
    },
    {
      Header: ['확정일자', '확정일자'],
      Name: 'closeoutDate',
      Type: 'Date',
      Width: 100,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['송부일자', '송부일자'],
      Name: 'frstSendngDt',
      Type: 'Date',
      Width: 100,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['송부', '송부'],
      Name: 'sttusSend',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['ctrtDivCd', 'ctrtDivCd'],
      Name: 'ctrtDivCd',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['canSend', 'canSend'],
      Name: 'canSend',
      Type: 'Bool',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

export default {
  props: {
    otisPage: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    selectedConfirmationKey: {
      type: Object,
      default: undefined,
    },
  },

  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props, context) {
    // console.log('setup > context', context);
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading: false,

      grid: undefined,
      gridOptions: ibsheetOptions,

      searchData: {
        projectName: undefined, // 프로젝트
        tradeName: undefined, // 고객
        empName: undefined, // 계약담당
        ctrtNo: undefined, // 계약번호
        closeoutPeriod: $_getCurrentDate(), // 확정월
        contDateStart: undefined, // 계약일
        contDateEnd: undefined, // 계약일
      },

      list: undefined,
      selectedRow: undefined,

      canSend: computed(() => {
        // TODO: 2022-07-28 은정범, 금액확정통보 legacy 채권 관련 확정/완료 전까지 송부 불가 처리
        // return (
        //   state.selectedRow !== undefined &&
        //   state.selectedRow !== null &&
        //   state.selectedRow.ctrtSttusCd !== undefined &&
        //   state.selectedRow.ctrtSttusCd === null &&
        //   state.selectedRow.canSend !== undefined &&
        //   state.selectedRow.canSend !== null &&
        //   state.selectedRow.canSend.toString() === '1' &&
        //   !state.loading &&
        //   !props.pageLoading
        // );
        return false;
      }), // 송부

      // contractKey: undefined,
      confirmationKey: undefined,
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        // 로딩 상태 완료로 변경
        state.loading = false;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col === '보기') {
          state.selectedRow = e.row;
          openViewer();
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        state.selectedRow = e.row;
        // console.log('onClick', state.selectedRow, computedSelectedContractKey.value);
        context.emit('update:selected-confirmation-key', computedSelectedConfirmationKey.value);
      },
    };

    const computedSelectedConfirmationKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
    });

    onMounted(() => {
      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: context.refs.searchBox,
        srchList: context.parent.$parent,
      });

      // console.log('onMounted', context);
    });

    // 금액확정통보 보기
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    watch(
      () => props.search,
      (newValue, oldValue) => {
        // 상위 페이지의 search 상태가 변경된 경우 처리
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );

    const method = {
      // 목록 조회
      async getList() {
        if (state.loading) {
          return;
        }
        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedContractKey 변경 이벤트 호출 -> 상위 페이지의 contractKey 변경
        state.selectedRow = undefined;
        context.emit('update:selectedConfirmationKey', computedSelectedConfirmationKey.value);

        state.grid.removeAll();

        let param = {
          otisPage: props.otisPage,
          ...state.searchData,
          // contDateStart: '20210101',
        };

        try {
          const response = await axios.post('/mod/confirmation/selectConfirmationList', param);
          state.list = response.data;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        } finally {
          // state.loading = false;
        }
      },

      downloadExcel() {
        state.grid.down2Excel();
      },
      grid_created() {
        method.getList();
      },

      //송부
      async btnSend_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm('금액확정 통보서를 송부하시겠습니까?')) {
          const data = {
            ctrtNo: state.selectedRow.ctrtNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            reason: '금액확정 통보서',
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/reSend', data);

            await vm.$alert('송부가 완료되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            await vm.$alert('실패하였습니다.' + ex);
          } finally {
            context.emit('update:page-loading', false);
          }
        }
      },
    };

    return {
      state,
      computedSelectedConfirmationKey,
      ...method,
    };
  },
};
</script>

<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="searchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>프로젝트</i-col-header>
            <i-col>
              <iui-textbox
                v-model="state.searchData.projectName"
                placeholder=" 코드 또는 명칭"
                @keyup.enter="getList"
              />
            </i-col>
            <i-col-header>고객</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.tradeName" placeholder=" 고객명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>계약담당</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.empName" placeholder=" 계약담당자" @keyup.enter="getList" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.ctrtNo" placeholder=" 계약번호" @keyup.enter="getList" />
            </i-col>
            <i-col-header>확정월</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.closeoutPeriod" type="month" />
              </span>
            </i-col>
            <i-col-header>계약일</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.contDateStart" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.contDateEnd" />
              </span>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row style="min-height: 260px;">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            목록
          </template>
          <template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
          </template>

          <iui-container
            type="ibsheet"
            :loading="state.loading"
            :ibsheet.sync="state.grid"
            :ibsheet-options="state.gridOptions"
            :ibsheet-data="state.list"
            @ibsheet-created="grid_created"
          />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<style></style>

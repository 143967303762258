<template>
  <pmis-content-box style="height:320px">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header colspan="2">고객명</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.tradeName1" :enable="false" />
              </i-col>
              <i-col-header colspan="2">현장명(건물명)</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mainName" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약일자</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.checkContDate" :enable="false" />
              </i-col>
              <i-col-header colspan="2">소재지</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkAddr12" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약기간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.checkContStartDate" suffix="~" :enable="false" />
                <iui-datepicker-old :value="contractInfo.checkContFinDate" :enable="false" />
              </i-col>
              <i-col-header colspan="2">청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="2">계약상품명</i-col-header>
              <i-col-header>주계약상품</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkContTypeA3" :enable="false" width="100px" />
              </i-col>
              <i-col-header rowspan="3">통지방식</i-col-header>
              <i-col-header>우편</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.giroAddress" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>부가상품(옵션)</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkContOpts" :enable="false" />
              </i-col>
              <i-col-header>Email</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.email" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="3">월 계약금액</i-col-header>
              <i-col-header>월 단가</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.supplyTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.supplyTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
              <i-col-header>SMS</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mphone" :enable="false" width="100px" />
              </i-col>
            </i-row>
            <i-row v-if="isDocCat1_030 || isDocCat1_060 || isDocCat1_070 || isDocCat1_080">
              <i-col-header>부가가치세</i-col-header>
              <i-col colspan="4">
                <span>
                  <iui-text :value="contractInfo.taxTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.taxTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
            </i-row>
            <i-row v-else-if="isDocCat1_040">
              <i-col-header>부가가치세</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.taxTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.taxTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
              <i-col-header colspan="2">장기수선충당금(VAT별도)</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.maintPlanAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.maintPlanAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
            </i-row>
            <i-row v-else>
              <i-col-header>부가가치세</i-col-header>
              <i-col>
                <span>
                  <iui-text :value="contractInfo.taxTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.taxTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
              <i-col-header colspan="2">eService 리포트 수신여부</i-col-header>
              <i-col>
                Elite Service고객은 매월 1회 원격점검 리포트를 'Elite 신청서'의 수령방법에 따라 제공받습니다.
                <!-- <iui-checkbox-group-old
                  :items="[{label: '예', value: 'Y'}, {label: '아니오', value: 'N'}]"
                  :checkedValues="[contractInfo.eserviceFlag]"
                  :enable="false"
                /> -->
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>합계금액</i-col-header>
              <i-col colspan="4">
                <span>
                  <iui-text :value="contractInfo.sumTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.sumTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약기간 총 계약금액 (VAT별도)</i-col-header>
              <i-col colspan="4">
                <span>
                  <iui-text :value="contractInfo.ckeckContTotalAmt" type="amount" prefix="\" :enable="false" />
                  (일금 {{ contractInfo.ckeckContTotalAmtKor }} 원 정)&nbsp;
                </span>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'OContractTabContractInfo_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      console.log('OContractTabContractInfo_Load');
    },
  },
  computed: {
    isDocCat1_030() {
      return this.contractInfo.docCat1 == '030';
    },
    isDocCat1_040() {
      return this.contractInfo.docCat1 == '040';
    },
    isDocCat1_060() {
      return this.contractInfo.docCat1 == '060';
    },
    isDocCat1_070() {
      return this.contractInfo.docCat1 == '070';
    },
    isDocCat1_080() {
      return this.contractInfo.docCat1 == '080';
    },
  },
};
</script>

<style></style>

<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container type="css-flex">
      <i-row ref="srchList">
        <i-col>
          <iui-container type="css-flex" theme="bullet">
            <i-row height="40px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <!-- <i-col-header style="width: 70px">CC/팀</i-col-header>
                        <i-col style="width: 100px">
                            <iui-text name="searchCct" :value.sync="searchInfo.searchCct" @enter="onPreSearch" />
                        </i-col> -->
                    <i-col-header style="width: 60px">현장명</i-col-header>
                    <i-col style="width: 120px">
                      <iui-text name="searchBuldNm" :value.sync="searchInfo.searchBuldNm" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header style="width: 60px">계약번호</i-col-header>
                    <i-col style="width: 100px">
                      <iui-text name="searchCtrtNo" :value.sync="searchInfo.searchCtrtNo" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header style="width: 60px"><!--계약일-->계약년도</i-col-header><!--20230327 ITSR69083-->
                    <i-col style="width: 120px">
                      <span style="display:flex; margin-right:5px;width: 120px" class="stampTaxDate">
                        <iui-datepicker-old
                          style=""
                          class="aaa"
                          name="yyyy"
                          type="year"
                          :value="searchInfo.yyyy"
                          :enable="true"
                          @change="onSetValue($event, 'yyyy')"                          
                        />
                        
                          <iui-select-old
                            id="mm"
                            name="mm"
                            :items="mmArray"
                            :value="searchInfo.mm"
                            defaultCd="A"
                            @change="onSetValue($event, 'mm')"
                          ></iui-select-old>
                           
                      </span>
                    </i-col>
                    <!--20230327 ITSR69083-->
                    <i-col-header style="width: 105px">인지세 요청년도</i-col-header>
                    <i-col style="width: 120px">
                      <span style="display:flex; margin-right:5px;width: 120px"  class="stampTaxDate">
                        <iui-datepicker-old
                          style=""
                          name="rceptyyyy"
                          type="year"
                          :value="searchInfo.rceptyyyy"
                          :enable="true"
                          @change="onSetValue($event, 'rceptyyyy')"                          
                        />         
                        
                          <iui-select-old
                            id="rceptMm"
                            name="rceptMm"
                            :items="mmArray"
                            :value="searchInfo.rceptMm"
                            defaultCd="A"
                            @change="onSetValue($event, 'rceptMm')"
                          ></iui-select-old>
                                
                      </span>
                    </i-col>
                    <i-col-header style="width: 130px">인지세납부 접수번호</i-col-header>
                    <i-col style="width:240px;">
                      <iui-text name="rceptNoTrim" @input="inputUpdate" :value.sync="searchInfo.rceptNoTrim" @enter="onPreSearch" />
                      <iui-button @click="searchClear"> 검색조건 초기화 </iui-button>
                    </i-col>
                    <!--20230327 ITSR69083-->
                    <i-col style="float: right">
                      계약년도
                      <span style="display:flex; margin-right:5px;"  class="stampTaxDate">
                        <iui-datepicker-old
                          style="margin-right:5px"
                          name="downloadyyyy"
                          type="year"
                          :value="searchInfo.downloadyyyy"
                          :enable="true"
                          @change="onSetValue($event, 'downloadyyyy')"
                        />
                        <iui-button @click="downLoadYearExcel">선택년도 엑셀다운</iui-button>
                      </span>
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <!-- 인지세 납부대장 -->
            <i-row>
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    인지세 납부대장 목록
                  </template>
                  <iui-container
                    type="jqgrid"
                    :id="sheetId"
                    :loading="listBoxLoading"
                    style="width:99.9%; height: 99.9%"
                  />
                  <iui-container type="jqgrid" :id="sheetIdExcel" :loading="listBoxLoading" style="display:none;" />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      cmmBtns: [
        //{name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        //{name: '출력', class: 'p', callback: this.onPrint},
      ],
      // 검색 정보
      searchInfo: {
        searchBuldNm: '', // 건물명
        searchContEmpNm: '', // 영업담당자
        searchCct: '', // CC
        searchCtrtNo: '', // 계약 번호
        applyNewYn: '',
        summaryYm: '',
        yyyyMm: '', // 계약 달
        downloadyyyy: '', // 선택년도 다운로드
        isExcel: 'N',

        yyyy : '', //계약년
        mm : '',  //계약달

        rceptyyyyMm : '', //인지세요청년월
        rceptyyyy : '', //인지세요청년 
        rceptMm: '', //인지세요청월

        rceptNo : '', //인지세납부번호
        rceptNoTrim : '', //인지세납부번호

        ctrtDivCd : 'SVC', // TC OC 조회
      },

      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId, // jqGrid ID
      sheetIdExcel: 'sheetExcel' + this.$store.getters['mdi/currentUrls'].menuId, // jqGrid ID
      listBoxLoading: false,
      tradeNo: '', // 거래선번호 (선택년도 엑셀다운 위해 따로 저장)

      mmArray: [
				{text: '01', value: '01'},
				{text: '02', value: '02'},
				{text: '03', value: '03'},
				{text: '04', value: '04'},
				{text: '05', value: '05'},
				{text: '06', value: '06'},
				{text: '07', value: '07'},
				{text: '08', value: '08'},
				{text: '09', value: '09'}, 
				{text: '10', value: '10'},
				{text: '11', value: '11'},
        {text: '12', value: '12'},
			],
      rceptNo : '',
    };
  },
  created() {},
  mounted() {
    const OPT = {
      Cols: [
        //{name: "reqstdocInfo",        label: "신청서",          width: 80,    align: "center"},
        {
          name: 'entryDate',
          label: '인지세요청일자',
          width: 100,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {name: 'ctrtDivCd', label: '계약구분', width: 60, align: 'center'},
        {name: 'ctrtNoOdr', label: '관리번호(계약번호)', width: 120, align: 'center'},
        {name: 'rceptNo', label: '인지세납부 접수번호', width: 150, align: 'center',
          formatter: function(v) {
            console.log("rowObject.rceptNo",v);
            return (v.length  > 7) ? v.substr(0, 7)+"-"+v.substring(7) :'';
          },
          cellattr: $_rowMearge,
        },
        {name: 'contNm', label: '과세문서명', width: 280, align: 'left'}, // 계약명
        {name: 'businessNo', label: '거래상대방<br>사업자(주민)등록번호', width: 150, align: 'center'}, // 상대방 사업자
        {
          name: 'contAllAmt',
          label: '기재금액',
          width: 100,
          align: 'right',
          formatter: 'currency',
          formatoptions: {thousandsSeparator: ',', decimalPlaces: 0},
        }, // 기재금액 (계약금 같음)
        {
          name: 'stmptaxAm',
          label: '인지세액',
          width: 100,
          align: 'right',
          formatter: 'currency',
          formatoptions: {thousandsSeparator: ',', decimalPlaces: 0},
        }, // 인지세액
        {
          name: 'contCreateDate',
          label: '계약생성일자',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {name: 'tradeNo', label: '거래선번호', width: 0, hidden: true}, // 거래선번호
        {name: 'tradeSfx', label: 'SUFFIX', width: 0, hidden: true}, // 거래선 SUFFIX
      ],
    };
    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      //width:1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });

    //20220104 엑셀 다운로드용
    var $gridExcel = $('#' + me.sheetIdExcel);
    $gridExcel.jqGrid({
      //width:1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g2_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });

    // 계약기간 검색 이번달로 기본 설정
    this.searchInfo.yyyyMm = $_getCurrentDate().substring(0, 6);
    this.searchInfo.downloadyyyy = $_getCurrentDate().substring(0, 4);

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onPreSearch();

    // 20210407 검색창 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });
  },
  methods: {
    // 창 리사이즈
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      var newHeight = pobj.height();
      $grid.jqGrid('setGridWidth', newWidth - 2, true);
      $grid.jqGrid('setGridHeight', newHeight - 80, true);
      //$grid.jqGrid("setGridWidth", newWidth-1, true); // true 줄 시에 크기값 비율고정됨
    },
    // 페이지 최초 검색
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;

        if (params !== undefined) {
          this.searchInfo.applyNewYn = '';
          this.searchInfo.summaryYm = '';

          this.searchInfo.applyNewYn = params.applyNewYn;
          this.searchInfo.summaryYm = params.summaryYm;
        }
      }
      this.onSearch();
    },
    //상단 검색조건 설정 (건물명/계약종류/구분/CC)
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.searchInfo[target] = value;
    },
    // 검색조건 초기화
    searchClear() {
      for (var key in this.searchInfo) {
        if (key != 'downloadyyyy') {
          if (this.searchInfo.hasOwnProperty(key)) {
            this.searchInfo[key] = '';
          }
        }
      }
      this.searchInfo.yyyyMm = $_getCurrentDate().substring(0, 6);
      //this.searchInfo.downloadyyyy = $_getCurrentDate().substring(0, 4); // 필요시 주석해제
      this.searchInfo.isOtisPage = false;
      this.searchInfo.isCustomerPage = true;
    },
    // 검색
    async onSearch() {

      /*
      if (this.searchInfo.yyyyMm == '') {
        this.$alert('계약 기간을 선택해주세요.');
        return;
      }
      */
      if( this.searchInfo.searchBuldNm == '' 
          && this.searchInfo.searchCtrtNo ==''
          && this.searchInfo.yyyy ==''
          && this.searchInfo.rceptyyyy ==''
          && this.searchInfo.rceptNoTrim ==''){
          
          this.$alert('한개 이상의 검색 조건이 입력 되어야 합니다.');
          return;
      }

      if(this.searchInfo.mm !='' && this.searchInfo.yyyy ==''){
        this.$alert('계약년도을 입력해 주세요.');
        return;
      }

      if(this.searchInfo.rceptMm !='' && this.searchInfo.rceptyyyy ==''){
        this.$alert('인지세 요청년도을 입력해 주세요.');
        return;
      }
      if(this.searchInfo.rceptNoTrim !=''){
        this.searchInfo.rceptNo = this.searchInfo.rceptNoTrim.replace("-","");
        if(this.searchInfo.rceptNo.length != 16){
          this.$alert('올바른 납부 번호를 입력해주세요.');
          return
        }
      }
      

      this.searchInfo.yyyyMm = this.searchInfo.yyyy + this.searchInfo.mm;
      this.searchInfo.rceptyyyyMm = this.searchInfo.rceptyyyy + this.searchInfo.rceptMm;

      let param = this.searchInfo;

      this.listBoxLoading = true;
      try {
        const response = await axios.post('/stamptax/selectUserStamptaxListbyCust', param);
        this.listBoxLoading = false;
        this.tradeNo = response.data.tradeNo; // 거래선번호 저장

        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data.stmapInfo})
          .trigger('reloadGrid');
        this.onResize();
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },

    // 20220104 lgksja 엑셀다운
    async downLoadYearExcel() {
      if (this.searchInfo.downloadyyyy == '') {
        this.$alert('엑셀을 다운받을 년도를 선택해주세요.');
        return;
      }
      this.searchInfo.isExcel = 'Y';
      let param = this.searchInfo;

      this.$loading(this.searchInfo.downloadyyyy + '년도 인지세 엑셀 다운로드 진행중입니다.');
      try {
        const response = await axios.post('/stamptax/selectUserStamptaxListbyCust', param);
        this.tradeNo = response.data.tradeNo; // 거래선번호 저장
        $('#' + this.sheetIdExcel)
          .clearGridData(true)
          .setGridParam({data: response.data.stmapInfo})
          .trigger('reloadGrid');
        $printExcel(this.$store.getters['mdi/currentUrls'].menuId);
      } catch (ex) {
        console.log(ex);
      }
      this.searchInfo.isExcel = 'N';
      this.$loading('close');
    },
    /*
		async downLoadYearExcel() {
            if (this.searchInfo.downloadyyyy == ''){
                this.$alert("엑셀을 다운받을 년도를 선택해주세요.");
                return ;
            }
            if (this.tradeNo == ''){
                this.$alert("아래의 인지세 납부대장 목록 로딩이 끝난 뒤 클릭해주세요.");
                return ;
            }
            
            // eslint-disable-next-line
            var opt = {};
            const btns = [];

            let param = {
                manageCd : 'R04',                           // 필수(*) : (R04: 인지세 납부관리대장)
                tradeNo : this.tradeNo,                     // 고객페이지 본인 것 조회,다운로드 위해
                downloadyyyy: this.searchInfo.downloadyyyy, // 검색 년도
            }
            
            axios.post('/ozrpt/selectOzStamptaxFormService', param).then((response) => {
                console.log('조회결과 response >>>', response);
                console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);
                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = { }; // 전달할 param X

                // eslint-disable-next-line
                opt = $open({        
                    componentPath: '@/view/statistics/stamptax/components/popup/StampTaxViewer.vue',	 // 컴포넌트 경로 (필수)
                    param: {
                        OZParam: this.OZParam,
                    },                              // 파라미터 (생략가능)
                    btns: btns,                     // 팝업 버튼 (생략가능)
                    name: 'StampTaxViewer',         // 팝업 윈도우 이름 (생략가능)
                    option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                    title: '인지세 납부 관리대장',              // 팝업 제목 (생략가능)
                });

            }).catch(function (error) {
                console.log('========error====================');
                console.log(error);
            });

		},
        */
     async inputUpdate(val) {
      await this.$nextTick();
      //console.log("value",this.searchInfo.rceptNoTrim,val);
      this.searchInfo.rceptNoTrim = this.rcepNoFormat(this.searchInfo.rceptNoTrim);
      
    },
    rcepNoFormat(val){
      let value= val;

      value = value.replace(/[^0-9]/g, "");

      if(value.length > 16){
          value = value.substr(0, 16);
      }

      let result = [];
      result.push(value.substr(0, 7));
      result.push(value.substring(7));         

      //this.searchInfo.rceptNoTrim = 
      return result.filter((val) => val).join("-");
    }
  },
};
</script>

<style>
.stampTaxDate .mx-input-wrapper{width: 60px !important;}
.stampTaxDate .mx-input{ width:60px !important;}
.stampTaxDate .iui-datepicker .datepicker {   min-width: unset !important;}
</style>

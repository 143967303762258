<template>
  <table>
    <tr>
      <td>111</td>
    </tr>
    <tr>
      <td>
        <textarea
          id="pe_aZC"
          name="pe_aZC"
          alt="initText"
          title="initText"
          style="width:730px; height:450px; font-size:10pt"
        ></textarea>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
	created() {},
	mounted() {
		var CrossEditor = new NamoSE('pe_aZC');
		// CrossEditor.params.Width = '100%' ;
		CrossEditor.params.UserLang = 'auto';
		CrossEditor.params.FullScreen = false;

		CrossEditor.EditorStart();
	},
};
</script>

<style>
</style>
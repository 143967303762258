<style>
.drag-over {
  background-color: #2881dd !important;
  boder: 2px dashed #2881dd !important;
  color: white;
}

#drop-zone {
  width: 100%;
  height: 50px;
  color: #92aab0;
  background-color: white;
  border: 2px dotted #0b85a1;
  border-radius: 10px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  font-size: 200%;
}
#drop-zone input {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col width="100%">
        <pmis-tab-box>
          <template v-slot:title>작업확인 사진첨부</template>
          <template v-slot:title-right>
            <iui-button value="조회" @click="onSearch()" />
            <iui-button value="신규" @click="onAdd()" />
            <iui-button value="저장" @click="onSave()" />
            <iui-button value="삭제" @click="onDelete()" />
          </template>

          <pmis-content-box>
            <!--<iui-container -->
            <div
              v-on:drop="onDrop"
              v-on:dragenter="onDragEnter"
              v-on:dragover="onDragOver"
              id="drop-zone"
              style="border:2px dotted #0B85A1;
                      width:100%;
                      height:50px;
                      color:#92AAB0;
                      text-align:center;vertical-align:middle;
                      padding:10px 10px 10px 10px;
                      margin-bottom:10px;
                      font-size:200%;"
              draggable="true"
            >
              Drag & Drop Files Here
            </div>
            <iui-container type="jqgrid" :id="sheetId" />
          </pmis-content-box>
        </pmis-tab-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
// eslint-disable-next-line
//import {mapGetters, mapMutations} from 'vuex';

export default {
  beforeCreate() {
    //map 설정.
    // $mapGetters(this, ['reportMain']);
    // $mapMutations(this, ['setReportMain' ]);
  },
  created() {
    // this.addEvent([
    //   {name: 'main_search', func: this.onBeforeSearch},
    //   {name: 'main_add', func: this.onAdd},
    //   {name: 'main_save', func: this.onSave},
    //   {name: 'main_delete', func: this.onDelete},
    // ]);
  },
  mounted() {
    //this.$emit('emitReportMain');

    this.initSheet();

    /*
    $('#drop-zone').on('drop', function (e){
         $(this).css('border', '');
         e.preventDefault();
         var files = e.originalEvent.dataTransfer.files;
         handleFileUpload(files);
    });

    $('#drop-zone').on('dragenter', function (e) 
    {
        e.stopPropagation();
        e.preventDefault();
        $(this).css('border', '2px dotted #0B85A1');
    });

    $('#drop-zone').on('dragleave', function (e) 
    {
        e.stopPropagation();
        e.preventDefault();
        $(this).css('border', '');
    });

    draggable="true" v-on:dragstart="dragstart(item, $event)" v-on:dragend="dragend($event)" v-on:dragenter="dragenter(item, $event)"
*/
  },
  data() {
    return {
      sheetId: 'sheet' + this._uid,
      searchInfo: {
        rptNm: '',
      },
      lastRowId: '', //마지막선택한 RowId 저장변수.
      newData: {
        gridData: {},
        formData: {},
      },
      fd: new FormData(),
      selectedRow: 0, //grid 저장셀 row
      selectedCol: 0, //grid 저장셀 col
    };
  },
  methods: {
    // ...mapMutations('store', {
    //   setReportMain: 'setReportMain'
    // }),
    // onChange(e){
    //   console.log('eeee', e);

    // },
    onDrop(e) {
      console.log('on drop===>', e);
      console.log('e.target.files===>', e.target.files);
      console.log('e.dataTransfer.files===>', e.dataTransfer.files);

      $('#drop-zone').css('border', '2px dotted #0B85A1');
      $('#drop-zone').css('background-color', '#2881DD !important');
      $('#drop-zone').css('color', 'white');
      e.preventDefault();

      //var files = e.originalEvent.dataTransfer.files;
      var files = e.target.files || e.dataTransfer.files;
      this.handleFileUpload(files);
      e.target.value = '';
    },

    onDragEnter(e) {
      console.log('on drag enter....');
      e.stopPropagation();
      e.preventDefault();
      $('#drop-zone').css('border', '2px dotted #0B85A1');
    },

    onDragOver(e) {
      console.log('on drag over....');
      e.stopPropagation();
      e.preventDefault();
      $('#drop-zone').css('background-color', '#2881DD !important');
    },
    // 일단 FormData를 local변수로 하나 생성하고
    // eslint-disable-next-line
    handleFileUpload(files, obj) {
      console.log('on handleFileUpload....', files);

      var $grdMain = $('#' + this.sheetId);

      //this.fd = new FormData();
      // 그리드도 초기화
      //$grdMain.jqGrid("clearGridData", true).trigger("reloadGrid");

      // 드랍된 파일이 몇개일까..
      for (var i = 0; i < files.length; i++) {
        // fd에 파일을 추가한다.
        //this.fd.append('file_'+i, files[i]);
        this.fd.append('files', files[i]);

        //var grid = $("#theGrid01");
        var rowId = $grdMain.jqGrid('getGridParam', 'reccount');
        rowId++;
        rowId = rowId + '';

        // 파일 사이즈를 구해서
        var sizeStr = '';
        var sizeKB = files[i].size / 1024;
        if (parseInt(sizeKB) > 1024) {
          var sizeMB = sizeKB / 1024;
          sizeStr = sizeMB.toFixed(2) + ' MB';
        } else {
          sizeStr = sizeKB.toFixed(2) + ' KB';
        }
        var _fileLen = files[i].name.length;
        var _lastDot = files[i].name.lastIndexOf('.');
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = files[i].name.substring(_lastDot + 1, _fileLen).toUpperCase();

        var parameters = {
          rowID: rowId,
          // 초기 데이터를 넣고
          initdata: {fileName: files[i].name, fileSize: sizeStr, fileExt: _fileExt},
          position: 'last',
          useDefValues: false,
          useFormatter: false,
          addRowParams: {
            extraparam: {},
            keys: true,
          },
        };
        // 그리드에 row를 추가
        $grdMain.jqGrid('addRow', parameters);
        $grdMain.jqGrid('setCell', rowId, '_rowStatusName', 'I');
        $grdMain.jqGrid('setCell', rowId, '_rowStatusCode', 'I');
        $grdMain.jqGrid('setCell', rowId, 'confirmNo', '1');
        $grdMain.jqGrid('setCell', rowId, 'workCnts', '');

        // 페이징 처리 시 현 페이지의 Max RowId 값
        //$grdMain.jqGrid("addRowData", rowCnt+1, addData, 'last'); // 마지막 행에 Row 추가
        //$grdMain.jqGrid("editCell", rowCnt+1, 2, true); //관리코드
        this.lastRowId = $grdMain.getGridParam('selrow');
      }
    },

    initSheet() {
      const OPT = {
        Cols: [
          {
            label: '상태',
            name: '_rowStatusName',
            editable: false,
            align: 'center',
            width: 45,
          },
          {
            label: '작업번호',
            name: 'confirmNo',
            editable: false,
            align: 'center',
            width: 30,
          },
          {
            label: '파일명',
            name: 'fileName',
            edittype: 'text',
            //editrules:{required:true},
            //editoptions: {maxlength: 3},
            editable: false,
            //cellEdit: true,
            //ResultMask: '^[A-Za-z0-9]{0,20}$' /* 영문(대/소문자), 숫자 4자리 */,
            //ResultMessage: '영문, 숫자 조합으로 20자리까지만 입력가능합니다.',
            align: 'left',
            width: 100,
          },
          {
            label: '크기',
            name: 'fileSize',
            edittype: 'text',
            align: 'right',
            editable: false,
            editrules: {required: true},
            //editoptions: {maxlength: 30},
            width: 50,
          },
          {
            label: '종류',
            name: 'fileExt',
            edittype: 'text',
            align: 'center',
            //editable: false,
            //editrules:{required:true},
            //editoptions: {maxlength: 300},
            width: 30,
          },
          {
            label: '작업내용',
            name: 'workCnts',
            edittype: 'text',
            align: 'left',
            editable: true,
            editrules: {required: true},
            editoptions: {maxlength: 300},
            width: 300,
          },
          {name: '_rowStatusCode', hidden: true},
          {name: 'fileId', hidden: true},
          {name: 'flNo', hidden: true},
        ],
        Events: {
          onDataLoad: this.grid_onDataLoad,
          onClick: this.grid_onClick,
        },
      };

      var me = this;
      var $grdMain = $('#' + this.sheetId);

      console.log('grid_sheet_id=' + '#' + me.sheetId);

      $grdMain.jqGrid({
        height: 800,
        width: 200,
        autowidth: true,
        //shrinkToFit:true,
        selectionMode: 'singlerow',
        onselectrow: true,
        multiselect: false,
        colModel: OPT.Cols,
        cellEdit: true,
        cellsubmit: 'clientArray',
        //datatype: 'local',
        //data: 'dataArray',

        //sortname: 'invdate',
        viewrecords: true,
        //sortorder: "desc",

        sortable: true, //sort 기능
        loadonce: true, //sort 기능

        // eslint-disable-next-line
        beforeSelectRow: function(rowid, e) {
          console.log('beforeSelectRow:rowid>>>>>' + rowid);
          return true; // true 반환하지 않으면 다음 이벤트가 수행되지 않음.
        },
        onSelectCell: function(rowid) {
          //$('#history').append('onSelectRow() - rowid: ', rowid,'\t\tstatus: ', status,'\t\te: ', e + '<br/>');
          console.log('onSelectRow:rowid>>>>>' + rowid);

          var $grdMain = $('#' + me.sheetId);

          //var rowid = grdMain.getGridParam("selrow");

          console.log(' rowid=' + rowid + ',lastRowId=' + me.lastRowId);

          if (rowid && rowid !== me.lastRowId && me.lastRowId) {
            console.log(' save 처리...');
            $grdMain.saveRow(me.lastRowId);
            $grdMain.restoreRow(me.lastRowId);
          }
          //$grdMain.editRow(rowid, true);
          // eslint-disable-next-line
          var data = $grdMain.getRowData(rowid);
          if (me.lastRowId != rowid) {
            //me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
            me.lastRowId = rowid;
          }

          // eslint-disable-next-line
          //var rowdata = $grdMain.getRowData(rowid);

          //me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
        },
        loadComplete: function() {
          //조회된건은 수정불가. 삭제만가능...
          console.log('loadComplete~~~');
          var $grdMain = $('#' + me.sheetId);
          var ids = $grdMain.jqGrid('getDataIDs');
          for (var i = 0; i < ids.length; i++) {
            // eslint-disable-next-line
            var id = ids[i];
            //if ($grdMain.jqGrid('getCell',id,'manageCd') != '') {
            //    $grdMain.jqGrid('setCell',id,'manageCd','','not-editable-cell');
            //}
          }
          //첫번째로우 선택.
          if (ids && ids.length > 0) {
            // eslint-disable-next-line
            var data = $grdMain.getRowData(ids[0]);
            //me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
            me.lastRowId = ids[0];
          }
        },
        //수정건 수정으로표시.
        // eslint-disable-next-line
        afterSaveCell: function(rowid, name, val, iRow, iCol) {
          //beforeSaveCell: function (rowid, name, val, iRow, iCol) {
          console.log('afterSaveCell~~~' + rowid);
          var $grdMain = $('#' + me.sheetId);
          if ($grdMain.jqGrid('getCell', rowid, '_rowStatusCode') == 'N') {
            $grdMain.jqGrid('setRowData', rowid, {_rowStatusCode: 'U'});
            $grdMain.jqGrid('setRowData', rowid, {_rowStatusName: 'U'});
          }
          return true;
        },
        // eslint-disable-next-line
        beforeEditCell: function(rowid, cellname, value, iRow, iCol) {
          console.log('beforeEditCell~~~' + rowid);
          // eslint-disable-next-line
          var data = $grdMain.getRowData(rowid);
          if (me.lastRowId != rowid) {
            //me.setReportMain(data);
            //me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
            me.lastRowId = rowid;
          }
        },
        //열려있는 상태에서도 저장이되게끔 해줘야함.
        afterEditCell: function(rowid, cellname, value, iRow, iCol) {
          //beforeSaveCell:function(rowid, cellname, value, iRow, iCol){
          console.log('afterEditCell~~~' + rowid);

          me.selectedRow = iRow;
          me.selectedCol = iCol;

          var $grdMain = $('#' + me.sheetId);
          $('#' + rowid + '_' + cellname).blur(function() {
            $grdMain.jqGrid('saveCell', iRow, iCol);
          });
          return true;
        },
      });
      $gridAutoResize($grdMain);
    },
    onBeforeSearch() {
      console.log('onBeforeSearch!!');
      //this.upperFocusKeyCode = '';
      //this.onSearch();
    },
    async onSearch() {
      //this.searchInfo.rptNm = '';
      let response = await axios.post('/work/selectWorkFile', {confirmNo: '1'});
      var data = response.data;
      for (var i = 0; i < data.length; i++) {
        data[i].no = i + 1;
        data[i].isNew = false;
        //data[i].pgmCd = this.searchInfo.pgmCd;
      }

      let me = this;
      var $grdMain = $('#' + me.sheetId);
      $grdMain.jqGrid('clearGridData');
      $grdMain.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
      //$("#"+this.sheetId1).clearGridData(true).setGridParam({data:response.data}).trigger("reloadGrid");
      //$grid.jqGrid.setGridParam({data:response.data}).trigger("reloadGrid");

      //form data 초기화 처리.
      this.fd = new FormData();
    },
    /**
     *  신규추가. 한건씩만 추가 가능.
     */
    onAdd() {
      let me = this;
      var $grdMain = $('#' + me.sheetId);
      let rowCnt = $grdMain.getGridParam('reccount');

      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);

      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);

      //$grdMain.saveRow(me.lastRowId);
      //$grdMain.restoreRow(me.lastRowId);

      var ids = $grdMain.jqGrid('getDataIDs');
      console.log('getDataIDs>>>>', ids);
      for (var i = 0; i < ids.length; i++) {
        var rowData = $grdMain.getRowData(ids[i]);
        //console.log("rowData>>>>", rowData);
        if (String(rowData._rowStatusCode).trim() == 'I') {
          this.$alert('신규 추가건이 존재합니다.<br/>저장 후 이력상세정보를 입력하세요.');
          return;
        }
      }

      //console.log(  $grdMain.getrow);

      //me.callEvent({name: 'hist_search', param: { manageCd: '' }});

      var addData = {
        _rowStatusCode: 'I',
        _rowStatusName: 'I',
        no: rowCnt + 1,
        manageCd: '',
        verNo: 0,
        rptNm: '',
        rptFlpth: '',
        applcDe: '',
        applcYn: '',
        changeCn: '',
      };
      // 페이징 처리 시 현 페이지의 Max RowId 값
      $grdMain.jqGrid('addRowData', rowCnt + 1, addData, 'last'); // 마지막 행에 Row 추가
      //$grdMain.jqGrid("setSelection", rowCnt+1, false);
      $grdMain.jqGrid('editCell', rowCnt + 1, 2, true); //관리코드

      me.lastRowId = $grdMain.getGridParam('selrow');
    },
    async onSave() {
      var $grdMain = $('#' + this.sheetId);

      //grid editing 반영.
      $grdMain.jqGrid('saveCell', this.selectedRow, this.selectedCol);

      var isChanged = false;
      var grdData = $grdMain.getRowData();
      if (grdData) {
        for (var i = 0; i < grdData.length; i++) {
          if ('I,D,U'.indexOf(String(grdData[i]._rowStatusCode).trim()) > -1) {
            isChanged = true;
            break;
          }
        }
      }

      if (!isChanged) {
        this.$alert('변경된 내용이 없습니다.');
        return;
      }

      // //중복데이터체크.
      // if(this.newData && this.newData.length == 1){
      //   var response = await axios.post('/report/selectDuplicateCheckReportFile', this.newData);
      //   console.log( "중복체크", response );
      //   if (parseInt( response.data.checkCnt)>0) {
      //     //this.$alert(`중복되는 계약서 관리코드가 있습니다.`);
      //     this.$alert(`중복되는 계약서 관리코드가 있습니다.<br/>[${this.newData.manageCd}]`);
      //     return;
      //   }
      // }

      //object가 아닌 string 으로 설정.
      this.fd.append('grdData', JSON.stringify(grdData));

      let message = '저장하시겠습니까?';
      if (await this.$confirm(message)) {
        var response = await axios
          .post(
            '/work/saveWorkFile',
            this.fd, //this.newData
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          this.onSearch();
        } else {
          this.$alert('저장을 실패했습니다.');
        }
      }
    },
    onDelete() {
      // _rowStatusCode:'I' ->삭제처리.
      // _rowStatusCode:'N','U' -> '삭제'표시
      //$("#testGrid").jqGrid('delRowData', rowids[i]); // 로우 삭제
      var $grdMain = $('#' + this.sheetId);
      var rowid = $grdMain.getGridParam('selrow');
      if (!rowid) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      // eslint-disable-next-line
      var data = $grdMain.getRowData(rowid);
      //console.log('선택데이터', data);
      //if(parseInt(data.ctrtRowCount) > 1){
      //  this.$alert('[계약서 상세이력]이 존재합니다.<br>화면 좌측의 [상세이력정보]를 확인하세요');
      //  return;
      //}

      //if('Y'==data.applcYn){
      //  this.$alert('사용중인 계약서 파일입니다.<br>화면 좌측의 [상세이력정보]를 확인하세요');
      //  return;
      //}

      $grdMain.jqGrid('setCell', rowid, '_rowStatusCode', 'D');
      $grdMain.jqGrid('setCell', rowid, '_rowStatusName', 'D');

      $grdMain.saveRow(rowid);
      $grdMain.restoreRow(rowid);
    },
    onChange(e) {
      this.searchInfo.rptNm = e.target.value;
    },
  },
};
</script>

<style scoped></style>

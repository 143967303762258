<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <!-- 회사기본정보 -->
        <cmpInfo />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <!-- 관리자정보 -->
        <adminInfo />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <!-- 노무비관리정보 -->
        <lbrcInfo v-show="companyInfo.frmGb == '000001'" />
        <!-- 공사/납품 지역정보 -->
        <areaInfo
          v-show="companyInfo.frmGb == '000002' || companyInfo.frmGb == '000003'"
          :title="companyInfo.frmGb == '000002' ? '공사지역정보' : '납품지역정보'"
        />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <!-- 보유면허정보 -->
        <holdLcnsInfo v-show="companyInfo.frmGb == '000001'" />
        <!-- 면허(세부공종)정보 -->
        <workTypeInfo v-show="companyInfo.frmGb == '000002'" />
        <!-- 공급물품(자재)정보 -->
        <mtrilInfo v-show="companyInfo.frmGb == '000003'" />
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <!-- 문서관리정보 -->
        <docManageInfo :imageSave.sync="imageSave" :pgmCd.sync="pgmCd" />
      </i-col>
    </i-row>
    <i-row height="180px">
      <pmis-content-box>
        <template v-slot:title>첨부파일정보</template>
        <pmis-file-list
          v-model="fileList_files"
          :company-code.sync="pgmCd"
          :file-number.sync="fileList_fileNumber"
          :save.sync="fileList_save"
          :saveButtonVisible="false"
          :allowAdd="false"
          :allowDelete="false"
          @save-complete="onFileSaveComplete"
          toolbar-visible
        />
      </pmis-content-box>
    </i-row>
    <!-- <i-row>
      <i-col>
         공동대표정보
        <ceoInfo />
      </i-col>
    </i-row> -->
    <i-row v-if="companyInfo.pgmCd">
      <i-col>
        <systemUseInfo />
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import cmpInfo from '@/view/sysManage/userManage/member/components/cmpInfo';
import adminInfo from '@/view/sysManage/userManage/member/components/adminInfo';
import lbrcInfo from '@/view/sysManage/userManage/member/components/lbrcInfo';
import holdLcnsInfo from '@/view/sysManage/userManage/member/components/holdLcnsInfo';
import areaInfo from '@/view/sysManage/userManage/member/components/areaInfo';
import workTypeInfo from '@/view/sysManage/userManage/member/components/workTypeInfo';
import mtrilInfo from '@/view/sysManage/userManage/member/components/mtrilInfo';
import docManageInfo from '@/view/sysManage/userManage/member/components/docManageInfo';
// import ceoInfo from '@/view/sysManage/userManage/member/components/ceoInfo';
import systemUseInfo from '@/view/sysManage/userManage/member/components/systemUseInfo';
import store from '@/view/sysManage/userManage/member/store/member.js';
export default {
  components: {
    cmpInfo,
    adminInfo,
    lbrcInfo,
    areaInfo,
    workTypeInfo,
    mtrilInfo,
    holdLcnsInfo,
    docManageInfo,
    // ceoInfo,
    systemUseInfo,
  },
  watch: {
    fileFlag: {
      handler(val) {
        let fileComplete = true;
        for (let key in val) {
          if (!val[key]) {
            fileComplete = false;
          }
        }
        if (fileComplete) {
          this.saveCompany();
          this.initFileFlag();
        } else {
          if (val['lgFl'] && val['djFl'] && val['smFl'])
            //첨부파일 업로드
            this.fileList_save = true;
        }
      },
      deep: true,
    },
    fileList_save: {
      handler(val) {
        if (!val) this.setFileFlag({fl: true});
      },
    },
  },
  data() {
    return {
      fileList_files: [],
      fileList_fileNumber: 0,
      fileList_save: false,
      pgmCd: '',
      imageSave: false,
      param: {},
      companyData: {},
      cud: 0,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['fileFlag', 'companyInfo', 'worker', 'dupBsnNoStatus']);
    $mapMutations(this, [
      'initFileFlag',
      'setFileFlag',
      'initCompanyInfo',
      'setCompanyInfo',
      'setWorker',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setLicenseList',
      'setDocnmList',
      'setCeoList',
    ]);
  },
  created() {
    this.addEvent([
      {name: 'onSaveCompany', func: this.onSaveCompany},
      {name: 'selectCompanyInfo', func: this.selectCompanyInfo},
    ]);
  },
  methods: {
    onFileSaveComplete() {
      this.setCompanyInfo({flNo: this.fileList_fileNumber});
    },
    async onSaveCompany(cud) {
      let msg = cud == 1 ? '가입하시겠습니까?' : '저장하시겠습니까?';
      if (!(await this.$confirm(msg))) return;
      if (this.dupBsnNoStatus == 0 && cud == 1) {
        this.$alert('사업자번호 중복확인을 해주세요.');
        return;
      } else if (this.dupBsnNoStatus == 2) {
        this.$alert('이미 가입된 사업자입니다. <br> 운영팀에 연락바랍니다.');
        return;
      }
      this.cud = cud;
      this.beforeSaveCompany();
    },
    async beforeSaveCompany() {
      //회사코드 생성
      let response = await axios.post('/companyRegist/selectPgmCd');
      this.pgmCd = response.status == 200 ? String(response.data) : undefined;
      // 관인/로고/심볼 파일 업로드
      this.imageSave = true;
      // 관리자정보
      this.param['worker'] = {...this.worker};
      if (this.companyInfo.frmGb == '000003') {
        //납품지역
        this.callEvent({name: 'areaInfo_getSaveJson', param: data => (this.param['areaList'] = data)});
        // 공급물품정보
        this.callEvent({name: 'mtrilInfo_getSaveJson', param: data => (this.param['matList'] = data)});
      } else if (this.companyInfo.frmGb == '000002' || this.companyInfo.frmGb == '000004') {
        // 공사지역
        this.callEvent({name: 'areaInfo_getSaveJson', param: data => (this.param['areaList'] = data)});
        // 면허(세부)정보
        this.callEvent({name: 'workTypeInfo_getSaveJson', param: data => (this.param['gcodeList'] = data)});
      } else {
        //보유면허정보
        this.callEvent({name: 'holdLcnsInfo_getSaveJson', param: data => (this.param['licenseList'] = data)});
      }
      // 발신명의정보
      this.callEvent({name: 'docManageInfo_getSaveJson', param: data => (this.param['docnmList'] = data)});
      // 공동대표정보
      this.callEvent({name: 'ceoInfo_getSaveJson', param: data => (this.param['ceoList'] = data)});
    },
    saveCompany() {
      this.imageSave = false;
      this.param = {...this.param, ...this.companyInfo, pgmCd: this.pgmCd, cud: this.cud};
      let url = this.userInfo ? '/companyRegist/saveCompanyInfo' : '/companyRegist/noAuthSaveCompanyInfo';
      axios.post(url, this.param).then(response => {
        if (response.status == 200) {
          if (!this.userInfo) {
            this.initCompanyInfo();
            this.$parent.nextStep('04');
          }
        }
      });
    },
    selectCompanyInfo(param) {
      this.callEvent({name: 'companyRegist_onDetailLoading', param: true});
      axios.post('/companyRegist/selectCompanyInfo', param).then(response => {
        if (response.status == 200) {
          this.companyData = response.data;
          this.setCompanyInfo(this.companyData);
          this.setWorker(this.companyData.worker);
          if (this.companyData.licenseList) {
            this.setLicenseList(this.companyData.licenseList);
          }
          if (this.companyData.areaList) {
            this.setAreaList(this.companyData.areaList);
          }
          if (this.companyData.gcodeList) {
            this.setGcodeList(this.companyData.gcodeList);
          }
          if (this.companyData.matList) {
            this.setMatList(this.companyData.matList);
          }
          if (this.companyData.docnmList) {
            this.setDocnmList(this.companyData.docnmList);
          }
          if (this.companyData.ceoList) {
            this.setCeoList(this.companyData.ceoList);
          }
          this.callEvent({name: 'companyRegist_onDetailLoading', param: false});
        }
      });
    },
  },
  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style scoped>
.popup_modal .popup_contents {
  margin: 30px;
}
</style>

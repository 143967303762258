<template>
  <pmis-tab-box>
    <template v-slot:title>입력.</template>
    <template v-slot:title-left>왼쪽</template>
    <template v-slot:title-right>
      <iui-button value="클릭하세요" @click="onClickT" />
    </template>

    <pmis-content-box>
      <template v-slot:title>title</template>
      <template v-slot:title-left>title-left</template>
      <template v-slot:title-right>title-right</template>

      <template v-slot:header-right>header-right</template>
      <iui-text name="name" label="이름" :value="detailInfo.name" :bindArray="['setDetailInfo']" />
      <iui-text name="age" label="나이" :value="detailInfo.age" :bindArray="['setDetailInfo']" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
	data() {
		return {
			aaa: 'aaa',
		};
	},
	beforeCreate() {
		$mapGetters(this, {searchInfo: 'searchInfo', detailInfo: 'detailInfo'});
	},
	created() {
		this.addEvent({
			name: 'onClickT',
			func: this.onClickT,
		});
	},
	methods: {
		onClickT(param) {
			console.log(typeof param);
			console.log(param);
			alert(this.aaa);
			// param(this.aaa);
			param(this.aaa);
		},
	},
};
</script>

<style>
</style>
<script>
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'iui-content-box',
  props: {
    titleType: {
      type: String,
      default: undefined,
    },
    borderBox: {
      type: Boolean,
      default: false,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleBoxVisible() {
      return this.titleVisible || this.titleLeftVisible || this.titleCenterVisible || this.titleRightVisible;
    },
    titleVisible() {
      return this.$slots['title'] !== undefined;
    },
    titleLeftVisible() {
      return this.$slots['title-left'] !== undefined;
    },
    titleCenterVisible() {
      return this.$slots['title-center'] !== undefined;
    },
    titleRightVisible() {
      return this.$slots['title-right'] !== undefined;
    },
    headerBoxVisible() {
      return this.headerLeftVisible || this.headerCenterVisible || this.headerRightVisible;
    },
    headerLeftVisible() {
      let vnodes = this.$slots['header-left'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }

      // return this.$slots['header-left'] !== undefined;
    },
    headerCenterVisible() {
      let vnodes = this.$slots['header-center'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
      // return this.$slots['header-center'] !== undefined;
    },
    headerRightVisible() {
      let vnodes = this.$slots['header-right'];
      if (vnodes !== undefined) {
        return Array.from(vnodes).filter(vnode => vnode.tag !== undefined).length > 0;
      } else {
        return false;
      }
      // return this.$slots['header-right'] !== undefined;
    },
  },
});
</script>

<template>
  <div class="iui-content-box">
    <!-- title-box -->
    <iui-content-box-title-box v-if="titleBoxVisible" :title-type="titleType">
      <template #title>
        <slot name="title" />
      </template>
      <template #title-left>
        <slot name="title-left" />
      </template>
      <template #title-center>
        <slot name="title-center" />
      </template>
      <template #title-right>
        <slot name="title-right" />
      </template>
    </iui-content-box-title-box>
    <!-- title-box -->

    <!-- outer-content-box -->
    <div
      :class="{'outer-content-box': true, grid_box: borderBox && !noBorderBox, 'iui-loading-overlay-parent': true}"
      :style="{
        height: titleBoxVisible ? 'calc(100% - 30px)' : '100%',
        /*'border-top': noBorderBox ? '2px solid #3378c1' : '', */
        padding: noPaddingBox ? '0px' : '',
      }"
    >
      <iui-loading-overlay :visible="loading" />
      <!-- header-box -->
      <iui-content-box-header-box v-if="headerBoxVisible">
        <template #header-left>
          <slot name="header-left" />
        </template>
        <template #header-center>
          <slot name="header-center" />
        </template>
        <template #header-right>
          <slot name="header-right" />
        </template>
      </iui-content-box-header-box>
      <!-- header-box -->
      <!-- inner-content-box -->
      <div :class="{grid_inner_scroll: true}" :style="{height: headerBoxVisible ? 'calc(100% - 41px)' : '100%'}">
        <slot />
      </div>
    </div>
    <!-- outer-content-box -->
  </div>
</template>

<style></style>

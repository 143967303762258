<script>
import {defineComponent, getCurrentInstance, reactive, computed, watch} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';

export default defineComponent({
  name: 'IuiDialog',
  props: {
    ...BaseProps,
    ...LayoutProps,
    title: {
      type: String,
      default: undefined,
    },
    noTitleBar: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    noCloseButton: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    // hideCloseButton: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  emits: ['before-open', 'opend', 'before-close', 'closed'],
  setup(props, context) {
    const instance = getCurrentInstance();

    const Base = useBase(props, context, 'iui-dialog');
    const Layout = useLayout(props, context, 'iui-dialog');

    const width = computed(() => Layout.state.width || '700px');
    const height = computed(() => Layout.state.height || '70%');

    const state = reactive({
      title: props.title,
      width: width,
      height: height,
    });

    const promise = {
      promise: undefined,
      resolve: undefined,
      reject: undefined,
    };

    watch(
      () => props.title,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        state.title = newValue;
      }
    );

    const method = {
      window_keyup(e) {
        if (e.which === 27) {
          method.hide();
        }
      },
      modal_beforeOpen(e) {
        window.addEventListener('keyup', method.window_keyup);
        context.emit('before-open', e);
      },
      modal_opened() {
        context.emit('opened');
      },
      modal_beforeClose(e) {
        window.removeEventListener('keyup', method.window_keyup);
        context.emit('before-close', e);
      },
      modal_closed() {
        context.emit('closed');
      },
      btnClose_click() {
        method.hide();
      },

      show(options) {
        if (options && options.title) {
          state.title = options.title;
        }

        if (promise.promise) {
          promise.reject();
        }

        promise.promise = new Promise((resolve, reject) => {
          promise.resolve = resolve;
          promise.reject = reject;
        });

        instance.proxy.$modal.show(Base.state.id);

        return promise.promise;
      },
      hide(result) {
        promise.resolve(result);

        promise.promise = undefined;
        promise.resolve = undefined;
        promise.reject = undefined;

        instance.proxy.$modal.hide(Base.state.id);
      },
    };

    return {
      Base,
      Layout,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <modal
    :name="Base.state.id"
    :clickToClose="false"
    :draggable="true"
    :height="state.height"
    :scrollable="false"
    :width="state.width"
    :resizable="true"
    style="z-index: 998;"
    @before-open="modal_beforeOpen"
    @opened="modal_opened"
    @before-close="modal_beforeClose"
    @closed="modal_closed"
  >
    <div class="popup_modal">
      <div class="fg" :style="{width: '100%', height: '100%'}">
        <div v-if="!noTitleBar" class="popup_header">
          <div>{{ state.title }}</div>
          <button v-if="!noCloseButton" class="btn_close" @click="btnClose_click">
            <span class="blind">닫기</span>
          </button>
        </div>
        <div
          class="popup_contents"
          :style="{height: 'calc(100% - 20px' + (noTitleBar ? '' : ' - 40px') + (noFooter ? '' : ' - 40px') + ')'}"
        >
          <slot></slot>
        </div>
        <div v-if="!noFooter" class="popup_footer">
          <div class="footer-left">
            <slot name="button-left"></slot>
          </div>
          <div class="footer-center">
            <slot name="button-center"></slot>
          </div>
          <div class="footer-right">
            <slot name="button-right"></slot>
            <iui-button v-if="!noCloseButton" color="btn_pop_neg" @click="btnClose_click">닫기</iui-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<style>
.v--modal {
  border-radius: 10px;
  box-shadow: 0 0 5px #333;
  min-width: 300px;
  min-height: 200px;
}
</style>

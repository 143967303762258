<template>
  <section class="spot_w_left">
    <div class="spot_t_box">
      <h3 class="spot_title01">
        <span class="blind">현장현황</span>
      </h3>
      <span class="pl20 pr5">진행구분</span>
      <select style="width:100px" id="status" v-model="searchInfo.status">
        <option value="000001">진행</option>
        <option value="000002">완료</option>
      </select>
      <span class="pl20 pr5">현장명</span>
      <input type="text" style="width:170px" id="prjNm" v-model="searchInfo.prjNm" @keyup.enter="onSearch" />
      <input type="button" class="button_s mt0" @click="onSearch" />
    </div>
    <div class="mt10" style="height: calc(100% - 52px);">
      <iui-container type="ibsheet" :id="elId" />
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchInfo: {
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
        status: '000001',
        prjNm: '',
      },
    };
  },
  mounted: function() {
    const me = this;
    const OPT = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1 : 전체 편집 가능 (default),
        HeaderMerge: 3,
        DataMerge: 1,
      },
      Def: {
        Row: {
          NoColor: 1,
          CanFocus: 1,
        },
      },
      Cols: [
        {
          Header: ['현장명', '현장명'],
          Name: 'prjNm',
          Type: 'Text',
          RelWidth: 2,
        },
        {
          Header: ['계약일자', '진행상태'],
          Name: 'data1',
          RelWidth: 1,
          CanFocus: 0,
        },
        {
          Header: ['착공일자', '계약금액'],
          Name: 'data2',
          RelWidth: 1,
          CanFocus: 0,
        },
        {
          Header: ['준공(예정)일', '기성금액'],
          Name: 'data3',
          RelWidth: 1,
          CanFocus: 0,
        },
        {
          Header: ['공사기간(일)', '기성율(%)'],
          Name: 'data4',
          RelWidth: 1,
          CanFocus: 0,
        },
        {Name: 'pgmCd', Visible: false},
        {Name: 'prjCd', Visible: false},
        {Name: 'rowSeq', Visible: false},
      ],
      Events: {
        onDataLoad: me.onDataLoad,
        onClick: me.onClick,
      },
    };

    me.sheet = IBSheet.create({id: me.sheetId, el: me.elId, options: OPT});
    me.onSearch();
  },
  methods: {
    async onSearch() {
      /*
      axios
        .post('/homeController/selectProjectStatusList', this.searchInfo)
        .then(response => this.sheet.loadSearchData(response.data));

      let response = await axios.post('/homeController/selectProjectStatusList', this.searchInfo);
      this.sheet.loadSearchData(response.data);
      */
    },
    onDataLoad(e) {
      // Grid Col 속성 설정
      for (let row of e.sheet.getDataRows()) {
        if (row.rowSeq == '1') {
          this.sheet.setAttribute(row, 'data1', 'Type', 'Text', 1);
          this.sheet.setAttribute(row, 'data2', 'Type', 'Text', 1);
          this.sheet.setAttribute(row, 'data3', 'Type', 'Text', 1);
          this.sheet.setAttribute(row, 'data4', 'Type', 'Int', 1);

          this.sheet.setAttribute(row, 'data1', 'Align', 'center', 1);
          this.sheet.setAttribute(row, 'data2', 'Align', 'center', 1);
          this.sheet.setAttribute(row, 'data3', 'Align', 'center', 1);
          this.sheet.setAttribute(row, 'data4', 'Align', 'right', 1);

          this.sheet.setAttribute(row, 'data1', 'CustomFormat', this.dateFormat, 1);
          this.sheet.setAttribute(row, 'data2', 'CustomFormat', this.dateFormat, 1);
          this.sheet.setAttribute(row, 'data3', 'CustomFormat', this.dateFormat, 1);
        } else if (row.rowSeq == '2') {
          this.sheet.setAttribute(row, 'data1', 'Type', 'Text', 1);
          this.sheet.setAttribute(row, 'data2', 'Type', 'Float', 1);
          this.sheet.setAttribute(row, 'data3', 'Type', 'Float', 1);
          this.sheet.setAttribute(row, 'data4', 'Type', 'Float', 1);

          this.sheet.setAttribute(row, 'data1', 'Align', 'center', 1);
          this.sheet.setAttribute(row, 'data2', 'Align', 'right', 1);
          this.sheet.setAttribute(row, 'data3', 'Align', 'right', 1);
          this.sheet.setAttribute(row, 'data4', 'Align', 'right', 1);

          this.sheet.setAttribute(row, 'data2', 'Format', '#,##0', 1);
          this.sheet.setAttribute(row, 'data3', 'Format', '#,##0', 1);
          this.sheet.setAttribute(row, 'data4', 'Format', '#,##0.00', 1);
        }
      }

      this.onSearchDetail(this.sheet.getFirstRow());
    },
    onClick(e) {
      this.onSearchDetail(e.row);
    },
    dateFormat(v) {
      return v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6);
    },
    setSearchInfo(key, e) {
      this.searchInfo[key] = e.target.value;
    },
    onSearchDetail(row) {
      this.callEvent({name: 'ProjectStatus_search', param: row});
    },
  },
};
</script>

<style scoped></style>

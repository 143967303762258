/* NE > 계약 */
<script>
import {defineComponent, reactive, onMounted, ref} from '@vue/composition-api';
import ContractCompleteList from './components/ContractCompleteList.vue';
import ContractView from './components/ContractView.vue';

export default defineComponent({
  components: {
    ContractCompleteList,
    ContractView,
  },

  setup(props, context) {
    const pageButton = reactive({
      search: false,
      excel: false,
    });

    const oList = ref(null);

    const state = reactive({
      loading: false,
      contractKey: undefined,
    });

    onMounted(() => {
      // 검색란 보이기/감추기
      $initContViewShowButton({
        srchBox: context.refs.contViewBox,
        srchList: context.refs.contListBox,
        srchParent: context.parent.$parent,
      });

      //console.log('onMounted', context);
    });

    const method = {
      search_click() {
        oList.value.getList();
      },
      excel_click() {
        oList.value.downloadExcel();
      },
    };

    return {
      pageButton,
      oList,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page :loading="state.loading" @search-click="search_click" @excel-click="excel_click">
    <iui-container type="css-flex" ref="contListBox">
      <i-row style="min-height: 330px;">
        <i-col>
          <contract-complete-list
            ref="oList"
            otis-page
            :page-loading.sync="state.loading"
            :selected-contract-key.sync="state.contractKey"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="330px" style="min-height: 330px;" ref="contViewBox">
        <i-col>
          <contract-view otis-page :contract-key="state.contractKey" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="isBase">
      <label>회사구분</label><iui-select-old p-code="0279" :value.sync="frmGb" @change="onFrmGbChange" />
    </template>

    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  props: {
    isBase: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      comps1: [],
      comps2: [],
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      frmGb: '000000',
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },

  created() {
    this.addEvent([{name: 'onSearch', func: this.onSearch}]);
  },

  async mounted() {
    const OTP = {
      Cfg: {
        SearchMode: 2,
        MainCol: 'mnuNm',
        CanDrag: 1,
      },
      Cols: [
        {
          Header: '프로그램명',
          Type: 'Text',
          Name: 'mnuNm',
          MinWidth: '200',
          Align: 'Left',
          RelWidth: 1,
          CanEdit: 0,
        },
        {
          Header: '조회',
          Type: 'Text',
          Name: 'vwYn',
          Width: '100',
          Align: 'Center',
          CanEdit: 0,
          Format: await $getCodeIBSheetFormat(this, 'YESNO'),
        },
        {
          Header: '저장',
          Type: 'Text',
          Name: 'svYn',
          Width: '100',
          Align: 'Center',
          CanEdit: 0,
          Format: await $getCodeIBSheetFormat(this, 'YESNO'),
        },
        {
          Header: '삭제',
          Type: 'Text',
          Name: 'delYn',
          Width: '100',
          Align: 'Center',
          CanEdit: 0,
          Format: await $getCodeIBSheetFormat(this, 'YESNO'),
        },
        {
          Header: '출력',
          Type: 'Text',
          Name: 'prnYn',
          Width: '100',
          Align: 'Center',
          CanEdit: 0,
          Format: await $getCodeIBSheetFormat(this, 'YESNO'),
        },
        {
          Name: 'mnuCd',
          Visible: false,
        },
        {
          Name: 'uppMnuCd',
          Visible: false,
        },
        {
          Name: 'mnuSqn',
          Visible: false,
        },
        {
          Name: 'useDs',
          Visible: false,
        },
        {
          Name: 'pgmCd',
          Visible: false,
        },
      ],
      Events: {
        onStartDrag: e => {
          e.row.beforeLevel = e.row.Level;
        },
        onEndDrag: e => {
          if (!e.type) return 0;
          if (e.type == 2) {
            if (e.row.beforeLevel != e.torow.Level + 1) {
              this.$alert(`${e.row.beforeLevel}레벨은 ${e.torow.Level + 1}레벨의 하위로만 이동 가능합니다.`);
              return 0;
            }
          } else {
            if (e.row.beforeLevel != e.torow.Level) {
              this.$alert(`${e.row.beforeLevel}레벨로만 이동 가능합니다.`);
              return 0;
            }
          }

          setTimeout(() => {
            if (e.type == 2) {
              e.sheet.setValue(e.row, 'uppMnuCd', e.torow.mnuCd, 1);
              let childRow = e.torow.firstChild;
              for (let i = 0; i < e.torow.childNodes.length; i++) {
                e.sheet.setValue(childRow, 'mnuSqn', i + 1, 1);
                childRow = childRow.nextSibling;
              }
            } else {
              e.sheet.setValue(e.row, 'uppMnuCd', e.torow.uppMnuCd, 1);
              let childRow = e.torow.parentNode.firstChild;
              for (let i = 0; i < e.torow.parentNode.childNodes.length; i++) {
                e.sheet.setValue(childRow, 'mnuSqn', i + 1, 1);
                childRow = childRow.nextSibling;
              }
            }

            let rows = e.sheet.getSaveJson().data;
            rows.forEach(el => {
              el.beforeMnuCd = el.mnuCd;
              if (this.isBase) el.frmGb = this.frmGb;
            });

            let url;
            if (this.isBase) {
              url = '/baseMenu/updateMenuAsSort';
            } else {
              url = '/menu/updateMenuAsSort';
            }

            axios.post(url, {Items: rows}).then(response => {
              if (response.status == 200 && response.data > 0) {
                this.onSearch();
              }
            });
          }, 10);
        },
      },
    };

    this.sheet = IBSheet.create({
      id: this.sheetId, // 시트 객체 ID
      el: this.elId, // 시트를 생성할 DIV객체 ID
      options: OTP, // 초기화 구문 변수
    });

    this.sheet.bind('onFocus', e => {
      this.onSystemTreeMenu(e.row);
    });

    this.sheet.bind('onDataLoad', () => {
      this.focusRow();
    });

    this.onSearch();
  },
  methods: {
    ...mapActions('menu', ['onSystemTreeMenu']),
    onSearch: function(mnuCd) {
      let url = '';
      let param = {};
      if (this.isBase) {
        url = '/baseMenu/selectMenuList';
        param = {
          frmGb: this.frmGb,
        };
      } else {
        url = '/menu/selectMenuList';
        param = {
          pgmCd: this.searchInfo.pgmCd,
        };
      }
      axios
        .post(url, param)
        .then(response => {
          if (mnuCd) {
            this.mnuCd = mnuCd;
          }
          this.menuList = Array.from(response.data);
          this.sheet.loadSearchData(this.menuList, 0);
          //alert(url+"/"+JSON.stringify(param))
          //alert(JSON.stringify(this.menuList));
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },

    focusRow() {
      let focusRow;
      if (this.mnuCd) {
        for (let row of this.sheet.getDataRows()) {
          if (row.mnuCd == this.mnuCd) {
            focusRow = row;
            break;
          }
        }
      } else {
        focusRow = this.sheet.getRowById('AR1');
      }

      if (focusRow) {
        this.onSystemTreeMenu(focusRow);
      }

      setTimeout(() => {
        this.sheet.focus(focusRow);
        this.mnuCd = null;
      }, 300);
    },
    onFrmGbChange() {
      this.onSearch();
    },
  },
};
</script>

<style scoped></style>

import * as util from '@/components/Iui/util.js';
import {BUTTON_TYPE} from '@/components/Iui/const.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';
import TagMap from './tagMap.js';

export default {
  name: 'i-row',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  inject: ['containerType', 'containerHeaderWidth'],
  props: {
    rowspan: {
      type: String,
      default: undefined,
    },
  },
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  render(h) {
    let data = {};

    /**
     * tag 처리
     */
    let tag = TagMap.get(this.containerType);
    if (tag === undefined || tag === null) {
      tag = TagMap.get('default');
    }

    tag = tag.row;

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = 'row';
    classList.push({[defaultClassName]: true});

    util.appendArrayToObject(data, 'class', classList);

    /**
     * style 처리
     */
    let styleList = [];

    if (this.containerType === 'css-flex') {
      if (this.height !== undefined) {
        styleList.push({flex: `0 0 ${this.height}`});
        styleList.push({'overflow-y': 'auto'});
      } else if (this.flex !== undefined) {
        styleList.push({flex: this.flex});
      }
    }

    // if (this.containerType === 'css-flex-table') {
    //   if (this.rowspan !== undefined) {
    //     styleList.push({flex: `${this.rowspan}`});
    //   }
    // }

    if (this.containerType === 'table') {
      if (this.width !== undefined) {
        styleList.push({width: this.width});
      }
      if (this.height !== undefined) {
        styleList.push({height: this.height});
      }
    }

    util.appendArrayToObject(data, 'style', styleList);

    /**
     * attrs 처리
     */
    // let attrList = [];

    // if (this.containerType === 'table') {
    //   if (this.rowspan !== undefined && this.rowspan > 1) {
    //     attrList.push({rowspan: this.rowspan});
    //   }
    //   if (this.colspan !== undefined && this.colspan > 1) {
    //     attrList.push({colspan: this.colspan});
    //   }
    // }

    // util.appendArrayToObject(data, 'attrs', attrList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    childNodes.push(this.$slots.default);

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
};

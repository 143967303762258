<template>
  <pmis-page :searchProjectVisible="false" @search-click="onSearch" @save-click="onSave">
    <iui-container type="css-flex">
      <i-row>
        <i-col width="550px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <cmpList />
          </pmis-tab-box>
        </i-col>
        <i-col>
          <pmis-tab-box :loading="loading">
            <template v-slot:title>정보</template>
            <template v-slot:title-right>
              <iui-button value="승인" @click="onApproval" />
            </template>
            <member />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import cmpList from '@/view/sysManage/userManage/member/components/cmpList';
import member from '@/view/sysManage/userManage/member/join';
import store from '@/view/sysManage/userManage/member/store/member.js';
export default {
  components: {
    cmpList,
    member,
  },

  data() {
    return {
      loading: false,
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['companyInfo', 'worker']);
  },

  created() {
    this.addEvent([{name: 'companyRegist_onDetailLoading', func: this.onDetailLoading}]);
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'cmpList_selectCompanyNotConfirmList'});
    },

    onSave() {
      this.$confirm('저장하시겠습니까?').then(() => {
        let param = {
          pgmCd: this.companyInfo.pgmCd,
          useStrdate: this.companyInfo.useStrdate,
          useEnddate: this.companyInfo.useEnddate,
          cud: 2,
        };
        axios.post('/companyRegist/saveCompanyInfo', param);
      });
    },

    onApproval() {
      if (!this.companyInfo.useStrdate) {
        this.$alert('시작(계약)일자를 입력해주세요.');
        return;
      }
      if (!this.companyInfo.useEnddate) {
        this.$alert('종료일자를 입력해주세요.');
        return;
      }

      this.$confirm('승인하시겠습니까?').then(() => {
        let param = {
          pgmCd: this.companyInfo.pgmCd,
          useEnddate: this.companyInfo.useEnddate,
          useStrdate: this.companyInfo.useStrdate,
          frmGb: this.companyInfo.frmGb,
          worker: this.worker,
        };

        axios.post('/companyRegist/updateCompanyUseFlag', param).then(response => {
          if (response.status == 200) {
            this.callEvent({name: 'cmpList_selectCompanyNotConfirmList'});
          }
        });
      });
    },

    onDetailLoading(isLoading) {
      this.loading = isLoading;
    },
  },
};
</script>

<style scoped></style>

<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>표준조건</template>
          <iui-container type="jqgrid" :id="sheetId1" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>비표준조건</template>
          <iui-container type="jqgrid" :id="sheetId2" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo', 'paymentCondForStdList', 'paymentCondForNonStdList']);
  },
  created() {
    this.addEvent({name: 'TContractTabPaymentMethod_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 100,
      width: 800,
      colModel: [
        {name: 'chkPayCondition', label: 'chkPayCondition', width: 0, align: 'center', hidden: true},
        {name: 'chkPayConditionNm', label: '구분', width: 50, align: 'center'},
        {
          name: 'supplyAmt',
          label: '공급가액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'vatAmount',
          label: 'VAT',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalAmount',
          label: '합계',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {name: 'paymentTiming', label: '지급시기', width: 100, align: 'center'},
        {name: 'collectionCondNm', label: '지급조건', width: 100, align: 'center'},
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, align: 'center', hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, align: 'center', hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, align: 'center', hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });

    $('#' + me.sheetId2).jqGrid({
      height: 100,
      width: 800,
      colModel: [
        {name: 'chkPayCondition', label: 'chkPayCondition', width: 0, align: 'center', hidden: true},
        {name: 'chkPayConditionNm', label: '구분', width: 50, align: 'center'},
        {
          name: 'supplyAmt',
          label: '공급가액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'vatAmount',
          label: 'VAT',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalAmount',
          label: '합계',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {name: 'paymentTiming', label: '지급시기', width: 100, align: 'center'},
        {name: 'collectionCondNm', label: '지급조건', width: 100, align: 'center'},
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, align: 'center', hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, align: 'center', hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, align: 'center', hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      $grid = $('#' + this.sheetId2);
      pobj = $grid.closest('.ui-jqgrid').parent();
      newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);
    },
    onLoad() {
      console.log('TContractTabPaymentMethod_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.paymentCondForStdList})
        .trigger('reloadGrid');

      $('#' + this.sheetId2)
        .clearGridData(true)
        .setGridParam({data: this.paymentCondForNonStdList})
        .trigger('reloadGrid');
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

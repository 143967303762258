/* FOD > 인수증 > 목록 */
<script>
import {reactive, computed, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js'; // 인수증 보기
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import RejectManagePopup from '@/view/common/components/RejectManagePopup.vue'; // 반송/회수 관리
import RecoveryPopup from '@/view/common/components/RecoveryPopup.vue'; // 회수
import moment from 'moment';

const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
    },
    {
      Header: ['문서번호', '문서번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: ['문서번호', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
  ],
  Cols: [
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: ['프로젝트', '항번'],
      Name: 'mfgNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
    },
    {
      Header: ['고객', '고객번호'],
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: ['고객', '고객명'],
      Name: 'custTradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
    },
    {
      Header: ['설치팀', '팀코드'],
      Name: 'instTeam',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
    },
    {
      Header: ['설치팀', '팀명'],
      Name: 'instTeamName',
      Type: 'Text',
      Width: 160,
      Align: 'Left',
    },
    {
      Header: ['SV', 'SV'],
      Name: 'svEmpName',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
    },
    {
      Header: ['승강기\n번호', '승강기\n번호'],
      Name: 'globalId',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
    },
    {
      Header: ['기종', '기종'],
      Name: 'modelType',
      Type: 'Text',
      Width: 190,
      Align: 'Center',
    },
    {
      Header: ['검사일자', '검사일자'],
      Name: 'insptDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['검사기관', '검사기관'],
      Name: 'insptTradeName',
      Type: 'Text',
      nWidth: 170,
      Width: 140,
      Align: 'Left',
    },
    {
      Header: ['송부일자', '송부일자'],
      Name: 'frstSendngDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['완료일자', '완료일자'],
      Name: 'tmstmpIssuDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
  ],
  RightCols: [
    {
      Header: ['진행상태', '회수'],
      Name: 'sttusRecovery',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['진행상태', '송부'],
      Name: 'sttusSend',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['진행상태', '서명'],
      Name: 'sttusSigning',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['진행상태', '반송'],
      Name: 'sttusReject',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['진행상태', '완료'],
      Name: 'sttusComplete',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['담당자', '담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
    },
    {
      Header: ['진행상태', '진행상태'],
      Name: 'ctrtSttusCd',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['계약구분', '계약구분'],
      Name: 'ctrtDivCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['송부', '송부'],
      Name: 'canSend',
      Type: 'Bool',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['반송/회수 관리', '반송/회수 관리'],
      Name: 'canRejectRecoveryManage',
      Type: 'Bool',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['회수', '회수'],
      Name: 'canRecovery',
      Type: 'Bool',
      Width: 60,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

export default {
  props: {
    otisPage: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    selectedContractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {RejectManagePopup, RecoveryPopup},

  setup(props, context) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading: false,

      codeList: {
        // 진행상태
        status: [
          {text: '업체송부', value: '10'},
          // {text: '구성원반송', value: '11'},
          {text: '회수', value: '12'},
          // {text: 'OKSS송부', value: '13'},
          {text: '고객반송', value: '14'},
          // {text: '구성원서명중', value: '20'},
          {text: '계약완료', value: '30'},
          // {text: '공전소전송완료', value: '40'},
          // {text: '공전소전송실패', value: '41'},
        ],
      },

      grid: undefined,
      gridOptions: ibsheetOptions,

      searchData: {
        projectName: undefined, // 프로젝트
        tradeName: undefined, // 고객명
        empName: undefined, // 담당자
        ctrtNo: undefined, // 문서번호
        contDateStart: moment().add(-1,'month').format('YYYYMMDD'), // lgksja 20221103 한달전 으로
        //  .startOf('month')
        //  .add(-1, 'month')
        //  .format('YYYYMMDD'), // 변경일
        contDateEnd: moment().format('YYYYMMDD'), // lgksja 20221103  당일로 변경
        //  .endOf('month')
        //  .add(1, 'month')
        //  .format('YYYYMMDD'), // 변경일
        ctrtSttusCd: undefined, // 진행상태
      },

      list: undefined,
      selectedRow: undefined,

      // 송부
      canSend: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          state.selectedRow.ctrtSttusCd === null &&
          (state.selectedRow.canSend.toString() === '1' 
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)) &&
          !state.loading &&
          !props.pageLoading
        );
      }),
      // 반송/회수관리
      canRejectManage: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          (state.selectedRow.ctrtSttusCd === null ||
            state.selectedRow.ctrtSttusCd.toString() === '' ||
            ['11', '12', '14'].includes(state.selectedRow.ctrtSttusCd.toString())) &&
          (state.selectedRow.canRejectRecoveryManage.toString() === '1' 
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)) &&
          !state.loading &&
          !props.pageLoading
        );
      }),
      // 회수
      canRecovery: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          state.selectedRow.ctrtSttusCd !== null &&
          ['10', '20'].includes(state.selectedRow.ctrtSttusCd.toString()) &&
          (state.selectedRow.canRecovery.toString() === '1' 
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)) &&
          !state.loading &&
          !props.pageLoading
        );
      }),

      contractKey: undefined, // 반송/회수 관리
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        state.loading = false; // 로딩 상태 완료로 변경
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col === '보기') {
          state.selectedRow = e.row;
          openViewer();
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        state.selectedRow = e.row;

        // selectedContractKey 변경 이벤트 호출 -> 상위 페이지의 contractKey 변경
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);
      },
    };

    const computedSelectedContractKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
    });

    onMounted(() => {
      // 검색란 보이기/숨기기
      $initSrchShowButton({
        srchBox: context.refs.searchBox,
        srchList: context.parent.$parent,
      });
    });

    // 인수증 보기
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
        tradeNo: state.selectedRow.canSend ? state.selectedRow.custTradeNo : undefined,
      };

      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    // 회수
    const recoveryPopup = useDialog('recoveryPopup');
    const recoveryPopupSetup = {
      ...recoveryPopup.dialogRef,
      async btnRecovery_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        const ctrtSttusCd = state.selectedRow.ctrtSttusCd;

        // 10 : 업체송부, 20 : 구성원서명중
        if (!(ctrtSttusCd == '10' || ctrtSttusCd == '20')) {
          await vm.$alert('회수 할 수 없는 상태입니다.');
          return;
        }

        state.contractKey = state.selectedRow;

        const result = await recoveryPopup.show();

        state.contractKey = undefined;

        if (result == true) {
          method.getList();
        }
      },
    };

    // 반송/회수 관리
    const rejectManagePopup = useDialog('rejectManagePopup');
    const rejectManagePopupSetup = {
      ...rejectManagePopup.dialogRef,
      async btnReject_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        const ctrtSttusCd = state.selectedRow.ctrtSttusCd;

        // 11 : 구성원반송, 12 : 회수. 14 : 고객반송
        if (
          !(
            ctrtSttusCd == null ||
            ctrtSttusCd == '' ||
            ctrtSttusCd == '11' ||
            ctrtSttusCd == '12' ||
            ctrtSttusCd == '14'
          )
        ) {
          await vm.$alert('반송 또는 회수 할 수 없는 상태입니다.');
          return;
        }

        state.contractKey = state.selectedRow;

        const result = await rejectManagePopup.show();
        state.contractKey = undefined;
        if (result == true) {
          method.getList();
        }
      },
    };

    const method = {
      // 목록 조회
      async getList() {
        if (state.loading) {
          return;
        }

        state.loading = true; // 로딩 상태 변경

        // 데이터를 가져오기 전 selectedContractKey 변경 이벤트 호출 -> 상위 페이지의 contractKey 변경
        state.selectedRow = undefined;
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);

        state.grid.removeAll();

        const param = {
          otisPage: props.otisPage,
          ...state.searchData,
        };

        try {
          const response = await axios.post('/fod/receipt/selectReceiptList', param);

          state.list = response.data;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        }
      },

      downloadExcel() {
        state.grid.down2Excel();
      },

      grid_created() {
        method.getList();
      },

      // 송부
      async btnSend_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm('인수증을 송부하시겠습니까?')) {
          const data = {
            ctrtNo: state.selectedRow.ctrtNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            reason: '인수증 송부',
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/reSend', data);

            await vm.$alert('송부가 완료되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            console.log(ex);
          } finally {
            context.emit('update:page-loading', false);
          }
        }
      },
    };

    return {
      state,
      computedSelectedContractKey,
      ...method,
      ...rejectManagePopupSetup,
      ...recoveryPopupSetup,
    };
  },
};
</script>

<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="searchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>프로젝트</i-col-header>
            <i-col>
              <iui-textbox
                v-model="state.searchData.projectName"
                placeholder=" 코드 또는 명칭"
                @keyup.enter="getList"
              />
            </i-col>
            <i-col-header>고객</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.tradeName" placeholder=" 고객명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>담당자</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.empName" placeholder=" 담당자명" @keyup.enter="getList" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>문서번호</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.ctrtNo" placeholder=" 문서번호" @keyup.enter="getList" />
            </i-col>
            <i-col-header>검사일</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.contDateStart" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.contDateEnd" />
              </span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select v-model="state.searchData.ctrtSttusCd" :items="state.codeList.status" placeholder="전체" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row style="min-height: 260px;">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            목록
          </template>
          <template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
            <iui-button :enable="state.canRejectManage" @click="btnReject_click">반송/회수 관리</iui-button>
            <iui-button :enable="state.canRecovery" @click="btnRecovery_click">회수</iui-button>
          </template>

          <iui-container
            type="ibsheet"
            :loading="state.loading"
            :ibsheet.sync="state.grid"
            :ibsheet-options="state.gridOptions"
            :ibsheet-data="state.list"
            @ibsheet-created="grid_created"
          />
        </iui-content-box>
      </i-col>
    </i-row>
    <reject-manage-popup ref="rejectManagePopup" type="fod" :contract-key="state.contractKey" />
    <recovery-popup ref="recoveryPopup" type="fod" :contract-key="state.contractKey" />
  </iui-container>
</template>

<style></style>

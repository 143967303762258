<template>
  <iui-content-box :loading="internalAction.search" v-show="toolbarVisible">
    <template v-slot:header-right v-if="toolbarVisible">
      <label>fileNumber: {{ fileNumber }}</label>&nbsp;
      <label>fileName: {{ fileName }}</label>
      <iui-button v-if="allowAdd" :enable="buttonEnable" @click="btnAdd_click">{{ addButtonText }}</iui-button>
      <iui-button v-if="allowDelete" :enable="buttonEnable" @click="btnDelete_click">{{ deleteButtonText }}</iui-button>
      <iui-button v-show="saveButtonVisible" :enable="buttonEnable" @click="btnSave_click">{{ saveButtonText }}</iui-button>
      <iui-button v-if="allowDownload" :enable="buttonEnable" @click="btnDownload_click">{{ downloadButtonText }}</iui-button>      
    </template>

    <file-upload
      :headers="postHeader"
      :post-action="internalPostAction"
      ref="upload"
      v-model="internalFiles"
      name="files"
      :drop="allowAdd && allowDrop"
      :multiple="false"
      :accept="accept"
      @input-file="fileUpload_inputFile"
      @input-filter="inputFilter"
      style="display:none;"
    >
    </file-upload>
  </iui-content-box>
</template>

<script>
import VueCookies from 'vue-cookies';
import FileUpload from 'vue-upload-component';

export const ADD_MODE = {
  ADD_ROW: 'ADD_ROW',
  UPDATE_ROW: 'UPDATE_ROW',
};

export default {
  name: 'file-up-down',
  components: {
    FileUpload: FileUpload,
  },
  model: {
    prop: 'files',
    event: 'update:files',
  },  
  props: {
    companyCode: {
      type: String,
      default: undefined,
    },
    fileNumber: {
      type: [Number, String],
      default: 0,
    },
    files: {
      type: Object,
      default: undefined,
    },
    accept: {
      type: String,
      default: undefined,
    },
    toolbarVisible: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    allowDrop: {
      type: Boolean,
      default: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },

    addButtonText: {
      type: String,
      default: '추가',
    },
    deleteButtonText: {
      type: String,
      default: '삭제',
    },
    saveButtonText: {
      type: String,
      default: '저장',
    },
    downloadButtonText: {
      type: String,
      default: '다운로드',
    },    
    saveButtonVisible: {
      type: Boolean,
      default: true,
    },

    // action
    changed: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    delete: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },    

    searchUrl: {
      type: String,
      default: '/file/selectFileList',
    },
  },
  data() {
    return {
      sheetId: 'sheet' + this._uid,

      internalFileNumber: 0,
      internalPostAction: `${process.env.VUE_APP_BASE_URL}/file/saveFile`,
      internalFiles: [],

      internalAction: {
        search: false,
        add: false,
        delete: false,
        save: false,
        clear: false,
        download: false,
      },

      addMode: undefined,

      fileInfo: {
        data: undefined,
        del: false
      },
      fileName: '',
    };
  },
  computed: {
    buttonEnable() {
      return (
        !this.internalAction.search &&
        !this.internalAction.add &&
        !this.internalAction.delete &&
        !this.internalAction.save &&
        !this.internalAction.clear &&
        !this.internalAction.download
      );
    },
    postHeader() {
      let authorization = '';

      if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
        authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD'+ '_accessToken');
      }

      return {Authorization: authorization};
    },
  },
  watch: {
    fileNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue !== this.internalFileNumber) {
          this.internalFileNumber = newValue;
          this.internalAction.search = true;
          this._search();
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.search = " + newValue);
        this.internalAction.search = newValue;

        if (newValue) {
          this._search();
        }
      }
    },
    add(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.add = " + newValue);
        this.internalAction.add = newValue;

        if (newValue) {
          this.btnAdd_click();
        }
      }
    },
    delete(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.delete = " + newValue);
        this.internalAction.delete = newValue;

        if (newValue) {
          this.btnDelete_click();
        }
      }
    },
    save(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.save = " + newValue);
        this.internalAction.save = newValue;

        if (newValue) {
          this.btnSave_click();
        }
      }
    },
    clear(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.clear = " + newValue);
        this.internalAction.clear = newValue;

        if (newValue) {
          this._clear();
        }
      }
    },
    download(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("watch.download = " + newValue);
        this.internalAction.download = newValue;

        if (newValue) {
          this.btnDownload_click();
        }
      }
    },
    
  },
  created() {
    const vm = this;
    this.internalFileNumber = this.fileNumber;
    this.$on('update:search', e => {
      console.log("update:search",e);
      vm.internalAction.search = e;
    });
    this.$on('update:add', e => {
      console.log("update:add",e);
      vm.internalAction.add = e;
    });
    this.$on('update:delete', e => {
      console.log("update:delete",e);
      vm.internalAction.delete = e;
    });
    this.$on('update:save', e => {
      console.log("update:save",e);
      vm.internalAction.save = e;
    });
    this.$on('update:clear', e => {
      console.log("update:clear",e);
      vm.internalAction.clear = e;
    });
    this.$on('update:download', e => {
      console.log("created.update:download");
      console.log(e);
      vm.internalAction.download = e;
    });
  },
  async mounted() {

    this.internalAction.search = true;

    await this.$nextTick();

    this._search();
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile !== undefined && newFile.name !== undefined) {  //20211209 newFile !== undefined 추가
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          this.$alert(newFile.name + '는 첨부할 수 없는 파일입니다.');
          return prevent();
        }

        if (/\.(php5?|htm|html?|js?|java|class?)$/i.test(newFile.name)) {
          this.$alert(newFile.name + '는 첨부할 수 없는 파일입니다.');
          return prevent();
        }
      }
    },
    btnAdd_click(e) {
      if (e !== undefined) {
        this.internalAction.add = true;
      }

      if (this.fileInfo.data.lclFlNm !== undefined && this.fileInfo.data.lclFlNm != "") {
        this.addMode = ADD_MODE.UPDATE_ROW;
      } else {
        this.addMode = ADD_MODE.ADD_ROW;  
      }
      this.$refs.upload.$children[0].$el.click();

      this.$emit('update:add', false);
    },
    async btnDelete_click(e) {
      if (e !== undefined) {
        this.internalAction.delete = true;
      }      
      if (this.fileInfo.data.lclFlNm == undefined || this.fileInfo.data.lclFlNm == "") {
        await this.$alert('선택된 파일이 없습니다.');
        this.$emit('update:delete', false);
        return;
      }
      //20211201 lgksja 삭제 누르면 바로 삭제로 변경하자 직관적으로
      if(this.fileInfo.file !== undefined){     
        this.$emit('update:delete', false);             
        this.fileInfo.data = {};
        this.fileInfo.data.pgmCd = this.companyCode;
        this.fileInfo.data.flNo = this.internalFileNumber;
        this.fileInfo.data.flDs = '';
        this.fileInfo.data.prjCd = '';
        this.fileInfo.data.fbs = '';
        this.fileInfo.data.lclFlNm = '';
        this.fileInfo.data.flSz = '';
        this.fileName = "";
        this._search("notCall");
        return;
      }
      this.$emit('update:delete', false);
      if (await this.$confirm('삭제하시겠습니까?')){        
        
        this.$emit('delete-start');
        this.$refs.upload.remove(this.fileInfo);
        this.fileInfo.del = true;
        let b = await this.deleteFile();
        if (b) {
            this.fileInfo.data = {};
            this.fileInfo.data.pgmCd = this.companyCode;
            this.fileInfo.data.flNo = this.internalFileNumber;
            this.fileInfo.data.flDs = '';
            this.fileInfo.data.prjCd = '';
            this.fileInfo.data.fbs = '';
            this.fileInfo.data.lclFlNm = '';
            this.fileInfo.data.flSz = '';
  
            this.fileName = "";
            this._search("notCall");
          }
      }
      
      /*
      var delIdx1 = this.fileName.indexOf("(삭제대상)");
      var delIdx2 = this.fileInfo.data.lclFlNm.indexOf("(삭제대상)");
      if (delIdx1 == -1) {
        this.fileInfo.del = true;
        this.fileName += '(삭제대상)';
        this.fileInfo.data.lclFlNm += '(삭제대상)';
        this.$refs.upload.remove(this.fileInfo);
      } else {
        this.fileInfo.del = false;
        this.fileName = this.fileName.substring(0, delIdx1);
        this.fileInfo.data.lclFlNm = this.fileInfo.data.lclFlNm.substring(0, delIdx2);
      }
      
      this.$emit('update:delete', false);
      */
      //20211201 lgksja 삭제 누르면 바로 삭제로 변경하자 직관적으로
    },
    async btnSave_click(e) {

      if (e !== undefined) {
        this.internalAction.save = true;
      }

      if (this.fileInfo.data.lclFlNm !== undefined 
            && this.fileInfo.data.lclFlNm != "" 
            && this.fileInfo.del == false
            && this.fileInfo.file === undefined){
        console.log('empty save target.');
        this.internalAction.save = false;
        this.$emit('update:save', false);
        this.$emit('update:changed', false);
        this.$emit('save-complete');
        return;
      }

      // var message = "파일을 저장하시겠습니까?";
      // var bolSave = await this.$confirm(message);
      // console.log(message);
      // console.log(bolSave);
      var bolSave = true;

      if (bolSave) {

        this.$emit('save-start');

        if (this.fileInfo.data.lclFlNm !== undefined && this.fileInfo.data.lclFlNm != "" && this.fileInfo.del == false) {
          console.log("btnSave_click.update");
          this.$emit('upload-start', this.fileInfo.data.lclFlNm);
          this.$refs.upload.update(this.fileInfo, {active: true, error: '', progress: '0.00'});
        } else {
          console.log("btnSave_click.delete");
          let b = await this.deleteFile();
          this.$emit('update:save', false);
          if (b) {
            this.fileInfo.data = {};
            this.fileInfo.data.pgmCd = this.companyCode;
            this.fileInfo.data.flNo = this.internalFileNumber;
            this.fileInfo.data.flDs = '';
            this.fileInfo.data.prjCd = '';
            this.fileInfo.data.fbs = '';
            this.fileInfo.data.lclFlNm = '';
            this.fileInfo.data.flSz = '';
  
            this.fileName = "";
            this.$emit('update:changed', false);
            this.$emit('update:files', this.fileInfo.data);
            this.$emit('save-complete');
          } else {
            this.$emit('save-error');
          }
        }

      }

    },
    async btnDownload_click(e) {
      if (e !== undefined) {
        this.internalAction.download = true;
      }
      console.log("btnDownload_click");
      console.log(this.fileInfo);

      if (this.fileInfo.data.flNo != 0 && this.fileInfo.data.lclFlNm !== undefined && this.fileInfo.data.lclFlNm != "") {
        var url = '/file/download?pgmCd=' + this.fileInfo.data.pgmCd + '&flNo=' + this.fileInfo.data.flNo + '&grpNo=' + this.fileInfo.data.grpNo;
        
        //20220322 lgksja 에러 처리및 다운로드 로딩 추가
        this.$loading("다운로드 요청중 입니다."); //20220322 lgksja 다운로드 로딩 추가
        let response = [];
        try {
          response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });
        } catch (ex) {
          this.$emit('update:download', false);
          response.data = [];
        }
        
        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(
              response.data,
              this.fileInfo.data.lclFlNm
            );
          } else {
            link.setAttribute('download', this.fileInfo.data.lclFlNm);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.$loading("close"); //20220322 lgksja 다운로드 로딩 추가
        } else {
          this.$loading("close"); //20220322 lgksja 다운로드 로딩 추가
          this.$alert('다운로드 받을 파일이 존재하지 않습니다.');
        }
      }

      this.$emit('update:download', false);
    },
    async _search(f) {
      console.log("f",f);
      console.log(`_search`, this.internalFileNumber);
      await this.$nextTick();
    
      if (this.$refs.upload !== undefined) {
        this.$refs.upload.clear();
      }

      if (this.internalFileNumber === 0) {
        this.fileInfo.data = {};
      } else {
        const data = {
          pgmCd: this.companyCode,
          flNo: this.internalFileNumber,
        };
        if(f === undefined){
          try {
            const response = await axios.post(this.searchUrl, data);
            // console.log(`loadSearchData`, this.internalFileNumber, data, response.data);
            this.fileInfo.data = response.data[0];
            if (this.fileInfo.data !== undefined) {
              this.fileName = this.fileInfo.data.lclFlNm;
            } else {
              this.fileInfo.data = {};
              this.fileInfo.data.pgmCd = this.companyCode;
              this.fileInfo.data.flNo = this.internalFileNumber;
              this.fileInfo.data.flDs = '';
              this.fileInfo.data.prjCd = '';
              this.fileInfo.data.fbs = '';
              this.fileInfo.data.lclFlNm = '';
              this.fileInfo.data.flSz = '';
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      }

      this.$emit('update:changed', false);
      this.$emit('update:files', this.fileInfo.data);
      this.$emit('update:search', false);
    },
    async deleteFile() {
      let r = true;

      if (this.fileInfo.data.lclFlNm !== undefined && this.fileInfo.del == true) {
        this.$emit('delete-start');
        try {
          await axios.post('/file/deleteSingleFile', this.fileInfo.data);
          this.$emit('delete-complete');
        } catch (ex) {
          this.$emit('delete-error');
          r = false;
        }
      }

      return r;
    },
    _clear() {
      this.$emit('update:files', undefined);
      this.$emit('update:fileNumber', 0);
      this.$emit('update:clear', false);
    },
    async fileUpload_inputFile(newFile, oldFile) {
     
      // console.group('fileUpload_inputFile');
      // console.log(newFile);
      // console.log(oldFile);
      // console.log(this.fileInfo);
      // console.groupEnd();

      if (newFile && !oldFile) {
        console.log('fileUpload_inputFile.Add file');
        console.log(this.addMode);
        // Add file
        if (this.addMode === ADD_MODE.UPDATE_ROW) {
          this.fileInfo.data.lclFlNm = newFile.name;
          this.fileInfo.data.flSz = newFile.size;

          newFile.data = this.fileInfo.data;
          newFile.del = false;
          this.fileInfo = newFile;
          this.fileName = newFile.name;
        } else {
          const data = {
            pgmCd: this.companyCode,
            flNo: this.internalFileNumber,
            flDs: this.$store.state.code2.codeAlias.FLDS_NORMAL.code,
            lclFlNm: newFile.name,
            flSz: newFile.size,
          };

          newFile.data = data;
          newFile.del = false;
          this.fileInfo = newFile;
          this.fileName = newFile.name;
        }

        this.addMode = undefined;

        this.$emit('update:changed', true);
        this.$emit('update:files', newFile.data);
      }

      if (newFile && oldFile) {
        console.log('fileUpload_inputFile.Update file');
        // Update file

        if (newFile.active && !oldFile.active) {
          // upload beforeSend
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, {error: 'size'});
          // }
        }
        if (newFile.progress !== oldFile.progress) {
          console.log('fileUpload_inputFile.Update file.uploading progress');
          // uploading progress
        }
        if (newFile.error && !oldFile.error) {
          console.log('fileUpload_inputFile.Update file.upload error');
          // upload error
          this.$emit('update:save', false);
          this.$emit('upload-error', newFile.name);
          this.$emit('update:fileNumber', this.internalFileNumber);
          this.$emit('save-error');
        }
        if (newFile.success && !oldFile.success) {
          console.log('fileUpload_inputFile.Update file.upload success');
          // upload success
          let oldFileNumber = this.fileInfo.data.flNo;
          if (oldFileNumber === 0) {
            this.fileInfo.data.flNo = newFile.response.flNo;
          }
          this.fileInfo.data.grpNo = newFile.response.grpNo;
          this.fileInfo.data.flDs = newFile.response.flDs;
          this.fileInfo.data.revId = newFile.response.revId;
          this.fileInfo.data.verNo = newFile.response.verNo;

          // 파일번호가 초기값이면 업로드 후 생성된 파일번호를 신규 데이터에 설정한다.
          if (this.internalFileNumber === 0) {
            this.internalFileNumber = newFile.response.flNo;
            this.fileInfo.data.flNo = this.internalFileNumber;
          }

          this.$refs.upload.remove(newFile);

          // if (newRows.length > 0) {
          //   this.$emit('upload-start', this.fileInfo.name);
          //   this.$refs.upload.update(this.fileInfo, {active: true, error: '', progress: '0.00'});
          // } else {
            this.$emit('upload-all-complete');

          //   let b = await this.deleteFile();

            this.$emit('update:save', false);
          //   if (b) {
              this.$emit('update:changed', false);
              this.$emit('update:files', this.fileInfo.data);
              this.$emit('update:fileNumber', this.internalFileNumber);
              this.$emit('save-complete');
          //   } else {
          //     this.$emit('update:fileNumber', this.internalFileNumber);
          //     this.$emit('save-error');
          //   }
          // }
        }
      }

      if (!newFile && oldFile) {
        console.log('fileUpload_inputFile.Remove file');
        // Remove file
      }

    },
    
  }
}
</script>

<style></style>

export const gridOption = {
  Cfg: {
    ReqStatusName: 'STATUS', //서버상태변수명
    FitWidth: true,
    CanEdit: 1,
    IgnoreFocused: 0,
    SearchMode: 0,
    //CustomScroll: 1,
    //Style: 'IBMT',
    //FocusedRow: 'AR1', // 데이터 첫번째 행에 포커스 처리
  },
  Def: {
    Row: {
      CanFormula: 1,
      CalcOrder: 'cbxCanEdit,manageCdCanEdit', //필드명의 Formula 속성명 명시
    },
  },
  //틀고정 좌측 컬럼 설정
  LeftCols: [
    {Header: 'No.', Type: 'Int', Width: 50, Align: 'Center', Name: 'SEQ'},
    {
      Header: '선택',
      Type: 'Bool',
      Name: 'cbx',
      Align: 'Center',
      Width: 50,
      // 이력이 존재할경우 삭제불가로직 추가. 22.04.01.
      CanEditFormula: param => param.Row['ctrtRowCount'] < 2,
      // CanEditFormula: function(param) {
      //   return param.Row['ctrtRowCount'] <= 1 ? true : false;
      // },
    },
    {
      Header: '관리번호',
      Type: 'Text',
      Name: 'manageCd',
      Align: 'Center',
      //Format: '####',
      Size: '10',
      Width: 100,
      Required: 1,
      ResultMask: '^[A-Z0-9_]{0,10}$' /* 영문(대/소문자), _(undersore))숫자 10자리 */,
      ResultMessage: '영문(대문자),_(undersore), 숫자 조합으로 10자리까지만 입력가능합니다.',
      //CanEdit: 1,
      // 이력이 존재할경우 삭제불가로직 추가. 22.04.01.
      CanEditFormula: param => param.Row['ctrtRowCount'] < 2,
    },
  ],
  Cols: [
    {
      Header: '계약서명',
      Type: 'Text',
      Name: 'rptNm',
      Align: 'Left',
      Required: 1,
      Size: 200,
      CanEdit: 1,
      Width: '400',
    },
    {
      Name: 'verNo',
      Visible: 0,
    },
    {
      Name: 'rptFlpth',
      Visible: 0,
    },
    {
      Name: 'applcDe',
      Visible: 0,
    },
    {
      Name: 'applcYn',
      Visible: 0,
    },
    {
      Name: 'changeCn',
      Visible: 0,
    },
    {
      Name: 'entryEmpId',
      Visible: 0,
    },
    {
      Name: 'entryEmpName',
      Visible: 0,
    },
    {
      Name: 'entryDate',
      Visible: 0,
    },
    {
      Name: 'chngEmpId',
      Visible: 0,
    },
    {
      Name: 'chngEmpName',
      Visible: 0,
    },
    {
      Name: 'chngDate',
      Visible: 0,
    },
    {
      Name: 'ctrtRowCount',
      Visible: 0,
    },
  ],
  Events: {
    // onDataLoad(e) {
    //  console.log('onDataLoad', e);
    // },
    // onClick(e){
    // }
  },
};

export default gridOption;

/* FOD > 돌관 발주서 > 일괄서명 */
<script>
import {defineComponent, getCurrentInstance, reactive, computed,onMounted} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js'; // 발주서 보기

const gridOptions_FOD_CT1 = {
  Cfg: {
    CanEdit: 1,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
      CanEdit: 0,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
      CanEdit: 0,
    },
    {
      Header: ['선택', '선택'],
      Name: 'choice',
      Type: 'Bool',
      Width: 50,
      DefaultValue: 1,
      CanEdit: 1,
    },
    {
      Header: ['변경통보\n문서번호', '변경통보\n문서번호'],
      Name: 'noticeDocNo',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '차수'],
      Name: 'degree',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
  Cols: [
    {
      Header: ['고객', '고객번호'],
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['고객', '고객명'],
      Name: 'custTradeName',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['공동수급업체', '변경 전'],
      Name: 'befTradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['공동수급업체', '변경 후'],
      Name: 'tradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['변경일자', '변경일자'],
      Name: 'contDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['변경통보\n담당자', '변경통보\n담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
};

const gridOptions_FOD_CT2 = {
  Cfg: {
    CanEdit: 1,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
      CanEdit: 0,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
      CanEdit: 0,
    },
    {
      Header: ['선택', '선택'],
      Name: 'choice',
      Type: 'Bool',
      Width: 50,
      DefaultValue: 1,
      CanEdit: 1,
    },
    {
      Header: ['계약번호', '계약번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['계약번호', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
  Cols: [
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '항번'],
      Name: 'mfgNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      Width: 200,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['고객', '고객번호'],
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['고객', '고객명'],
      Name: 'custTradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['계약일자', '계약일자'],
      Name: 'contDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['공사기간', '시작일'],
      Name: 'workDateStart',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['공사기간', '종료일'],
      Name: 'workDateEnd',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['계약율', '계약율'],
      Name: 'contRate',
      Type: 'Float',
      Width: 80,
      Align: 'Right',
      Format: '#,###\\%',
      CanEdit: 0,
    },
    {
      Header: ['공동수급금액', '변경 전'],
      Name: 'befContTotAmt',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
      CanEdit: 0,
    },
    {
      Header: ['공동수급금액', '변경 후'],
      Name: 'contTotAmt',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
      CanEdit: 0,
    },
    {
      Header: ['계약\n담당자', '계약\n담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
};

const gridOptions_FOD_CT3 = {
  Cfg: {
    CanEdit: 1,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
      CanEdit: 0,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
      CanEdit: 0,
    },
    {
      Header: ['선택', '선택'],
      Name: 'choice',
      Type: 'Bool',
      Width: 50,
      DefaultValue: 1,
      CanEdit: 1,
    },
    {
      Header: ['계약번호', '계약번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['계약번호', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
  Cols: [
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '항번'],
      Name: 'mfgNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      Width: 200,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['발주일자', '발주일자'],
      Name: 'contDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['작업기간', '시작일'],
      Name: 'workDateStart',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['작업기간', '종료일'],
      Name: 'workDateEnd',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['작업내용', '작업내용'],
      Name: 'workDesc',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['금액', '금액'],
      Name: 'contTotAmt',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
      CanEdit: 0,
    },
    {
      Header: ['계약\n담당자', '계약\n담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
};

const gridOptions_FOD_CT4 = {
  Cfg: {
    CanEdit: 1,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
      CanEdit: 0,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
      CanEdit: 0,
    },
    {
      Header: ['선택', '선택'],
      Name: 'choice',
      Type: 'Bool',
      Width: 50,
      DefaultValue: 1,
      CanEdit: 1,
    },
    {
      Header: ['계약번호', '계약번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['계약번호', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
  Cols: [
    {
      Header: ['프로젝트', '프로젝트'],
      Name: 'projectNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '항번'],
      Name: 'mfgNo',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['프로젝트', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      Width: 200,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['발주일자', '발주일자'],
      Name: 'contDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['작업요청\n일자', '작업요청\n일자'],
      Name: 'workReqstDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['작업내용', '작업내용'],
      Name: 'workDesc',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['금액', '금액'],
      Name: 'contTotAmt',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
      CanEdit: 0,
    },
    {
      Header: ['계약\n담당자', '계약\n담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
};

const gridOptions_FOD_PY1 = {
  Cfg: {
    CanEdit: 1,
  },
  LeftCols: [
    {
      Header: [' ', ' '],
      Name: 'SEQ',
      Type: 'Int',
      Width: 40,
      CanEdit: 0,
    },
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      Align: 'Center',
      ButtonText: '보기',
      ButtonWidth: 40,
      CanEdit: 0,
    },
    {
      Header: ['선택', '선택'],
      Name: 'choice',
      Type: 'Bool',
      Width: 50,
      DefaultValue: 1,
      CanEdit: 1,
    },
    {
      Header: ['정산합의 문서번호', '문서번호'],
      Name: 'ctrtNo',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['정산합의 문서번호', '차수'],
      Name: 'ctrtOdr',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
  Cols: [
    {
      Header: ['마감년월', '마감년월'],
      Name: 'payMonth',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['계약형태', '계약형태'],
      Name: 'contTypeNm',
      Type: 'Text',
      Width: 120,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['설치팀', '팀코드'],
      Name: 'instTeam',
      Type: 'Text',
      Width: 60,
      Align: 'Center',
      CanEdit: 0,
    },
    {
      Header: ['설치팀', '팀명'],
      Name: 'instTeamName',
      Type: 'Text',
      Width: 160,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['제목', '제목'],
      Name: 'approvalTitle',
      Type: 'Text',
      MinWidth: 200,
      RelWidth: 1,
      Align: 'Left',
      CanEdit: 0,
    },
    {
      Header: ['정산합의금액', '정산합의금액'],
      Name: 'payTotAmt',
      Type: 'Float',
      Width: 100,
      Align: 'Right',
      CanEdit: 0,
    },
    {
      Header: ['정산일자', '정산일자'],
      Name: 'payDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
      CanEdit: 0,
    },
    {
      Header: ['정산\n담당자', '정산\n담당자'],
      Name: 'contEmpName',
      Type: 'Text',
      Width: 70,
      Align: 'Center',
      CanEdit: 0,
    },
  ],
};

const gridCommonOptions = {
  Cols: [
    {
      Header: ['계약구분', '계약구분'],
      Name: 'ctrtDivCd',
      Type: 'Text',
      Width: 80,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['batchSignTradeNo', 'batchSignTradeNo'],
      Name: 'batchSignTradeNo',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['personId', 'personId'],
      Name: 'personId',
      Type: 'Text',
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['businessNo', 'businessNo'],
      Name: 'businessNo',
      Type: 'Text',
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['buyagntYn', 'buyagntYn'],
      Name: 'buyagntYn',
      Type: 'Text',
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['corporateYn', 'corporateYn'],
      Name: 'corporateYn',
      Type: 'Text',
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['canSignYn', 'canSignYn'],
      Name: 'canSignYn',
      Type: 'Text',
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['ctrtOrginlHashcd', 'ctrtOrginlHashcd'],
      Name: 'ctrtOrginlHashcd',
      Type: 'Text',
      Width: 200,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['pkcs7SignedMessage', 'pkcs7SignedMessage'],
      Name: 'pkcs7SignedMessage',
      Type: 'Text',
      Width: 200,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['signerDn', 'signerDn'],
      Name: 'signerDn',
      Type: 'Text',
      Width: 200,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
    {
      Header: ['vidRandom', 'vidRandom'],
      Name: 'vidRandom',
      Type: 'Text',
      Width: 200,
      Align: 'Center',
      CanEdit: 0,
      Visible: 0,
    },
  ],
};

export default defineComponent({
  props: {
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const batchSignDialog = useDialog('batchSignDialog');

    const state = reactive({
      loading: false,

      grid: undefined,
      gridOptions: undefined,

      list: undefined,
      selectedRow: undefined,

      // 일괄서명
      canSign: computed(() => {
        return !state.loading;
      }),
    });

    let gridOptions = undefined;

    switch (props.ctrtDivCd) {
      case 'FOD_CT1':
        gridOptions = gridOptions_FOD_CT1;
        break;
      case 'FOD_CT2':
        gridOptions = gridOptions_FOD_CT2;
        break;
      case 'FOD_CT3':
      case 'FOD_CT6':
        gridOptions = gridOptions_FOD_CT3;
        break;
      case 'FOD_CT4':
      case 'FOD_CT5':
      case 'FOD_OC1':
      case 'FOD_OC3':
      case 'FOD_OC4':
      case 'FOD_OC5':
      case 'FOD_OC6':
      case 'FOD_PA1':
        gridOptions = gridOptions_FOD_CT4;
        break;
      case 'FOD_PY1':
        gridOptions = gridOptions_FOD_PY1;
        break;
    }

    gridOptions.Cols = [...gridOptions.Cols, ...gridCommonOptions.Cols];

    state.gridOptions = gridOptions;

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        state.loading = false; // 로딩 상태 완료로 변경
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col == '보기') {
          state.selectedRow = e.row;
          openViewer();
        }
      },
    };

    // 발주서 보기
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };

      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    const method = {
      // 목록 조회
      async getList() {
        state.grid.removeAll();

        const param = {
          otisPage: false,
          ctrtDivCd: props.ctrtDivCd,
          agreYn: 'Y',
        };

        let uri = '';
        switch (props.ctrtDivCd) {
          case 'FOD_CT1':
            uri = '/fod/consortium/selectMemberChangeList';
            break;
          case 'FOD_CT2':
            uri = '/fod/consortium/selectContractList';
            break;
          case 'FOD_CT3':
          case 'FOD_CT4':
          case 'FOD_CT5':
          case 'FOD_CT6':
            uri = '/fod/order/selectContractList';
            break;
          case 'FOD_PY1':
            uri = '/fod/payment/selectPaymentList';
            break;
          case 'FOD_OC1':
          case 'FOD_OC3':
          case 'FOD_OC4':
          case 'FOD_OC5':
          case 'FOD_OC6':
            uri = '/fod/ordercancel/selectOrderCancelList';
            break;
          case 'FOD_PA1':
            uri = '/fod/paymentadjust/selectPaymentAdjustList';
            break;
        }

        try {
          state.loading = true; // 로딩 상태 변경

          const response = await axios.post(uri, param);

          let list = response.data;

          for (let i = 0; i < list.length; i++) {
            const row = list[i];

            const data = {
              ctrtNo: row.ctrtNo,
              ctrtOdr: row.ctrtOdr,
              ctrtDivCd: row.ctrtDivCd,
              tradeNo: row.batchSignTradeNo,
            };

            try {
              const response = await axios.post('/urlLink/signerInfo', data);

              const signerInfo = response.data;

              // 대리인이 아니면 공동인증서 서명이 가능
              // 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능
              row.personId = signerInfo.personId;
              row.businessNo = signerInfo.businessNo;
              row.buyagntYn = signerInfo.buyagnt ? 'Y' : 'N';
              row.corporateYn = signerInfo.corporate ? 'Y' : 'N';
              row.canSignYn = signerInfo.canSign ? 'Y' : 'N';
            } catch (ex) {
              console.log(ex);
            }
          }

          state.list = list;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        }
      },

      grid_created() {
        method.getList();
      },

      // 일괄서명
      async btnBatchSign_click() {
        const rows = state.grid.getRowsByChecked('choice');
        console.log("vm.$secuKit",vm.$secuKit);
        if (!vm.$secuKit.secuKitReady) {
          await vm.$alert('공동인증서 툴킷 로딩이 완료되지 못 했습니다.');
          return;
        }
        if (rows && rows.length === 0) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm(`${rows.length}건의 계약서(발주서)를 일괄서명하시겠습니까?`)) {
          console.log(rows);

          try {
            await vm.$secuKit.showDialog();
          } catch (ex) {
            return;
          }

          const certID = vm.$secuKit.certListInfo.getCertID();

          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const ctrtOrginlHashcd = row.ctrtOrginlHashcd;

            const data1 = {
              certType: 'SignCert',
              sourceString: ctrtOrginlHashcd,
              certID: certID,
            };

            const r2 = await vm.$secuKit.generatePKCS7SignedData(data1);
            console.log('generatePKCS7SignedData', r2);

            const pkcs7SignedMessage = r2.generatePKCS7SignedData;

            const data2 = {
              certType: 'SignCert',
              certID: certID,
              isViewVID: '1',
            };

            const r3 = await vm.$secuKit.viewCertInfomationWithVID(data2);
            console.log('viewCertInfomationWithVID', r3);

            // const vidRandom = r3.vidRandom;
            const signerDn = r3.userDN;

            const data3 = {
              certType: 'SignCert',
              sourceString: r3.vidRandom,
              certID: certID,
            };

            const r4 = await vm.$secuKit.generatePKCS7SignedData(data3);
            console.log('generatePKCS7SignedData', r4);

            const vidRandom = r4.generatePKCS7SignedData;

            if (pkcs7SignedMessage === '') {
              vm.$alert('전자서명한 값이 반드시 필요합니다.');
              // state.signerInfo.pkcs7SignedMessage = '';
              // state.signerInfo.signerDn = '';
              return;
            }

            const data4 = {
              signedType: '1',
              signedString: removePEMHeader(removeCRLF(pkcs7SignedMessage)),
            };

            const r5 = await vm.$secuKit.verifyPKCS7SignedData(data4);
            console.log('verifyPKCS7SignedData', r5);

            if (ctrtOrginlHashcd !== r5.verifyPKCS7SignedData) {
              vm.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.');
              // state.signerInfo.pkcs7SignedMessage = '';
              // state.signerInfo.signerDn = '';
              return;
            }

            state.grid.setValue(row, 'pkcs7SignedMessage', pkcs7SignedMessage, true, false);
            state.grid.setValue(row, 'signerDn', signerDn, true, false);
            state.grid.setValue(row, 'vidRandom', vidRandom, true, false);
          }

          let tradeName = undefined;

          try {
            tradeName = JSON.parse(sessionStorage.userInfo).loginName;
          } catch (ex) {
            console.log(ex);
          }

          try {
            state.loading = true;

            for (let i = 0; i < rows.length; i++) {
              const row = rows[i];

              const data = {
                ctrtNo: row.ctrtNo,
                ctrtOdr: row.ctrtOdr,
                ctrtDivCd: row.ctrtDivCd,
                tradeType: 'CCPY',
                tradeNo: row.batchSignTradeNo,
                tradeSuffix: null,
                tradeName: tradeName,
                personId: row.personId,
                businessNo: row.businessNo,

                buyagnt: row.buyagntYn === 'Y',
                corporate: row.corporateYn === 'Y',

                signMthCd: '10',
                pkcs7SignedMessage: row.pkcs7SignedMessage,
                signerDn: row.signerDn,
                vidRandom: row.vidRandom,
                pgmCd: '201900',
                chngPgmId: 'BatchSign',
              };

              const response = await axios.post('/urlLink/verify', data);
              console.log('response : ', response);
            }
            state.loading = false;
            await vm.$alert('일괄서명이 완료되었습니다.');
            method.getList();
          } catch (ex) {
            console.log(ex);
          } finally {
            state.loading = false;
          }
        }
      },

      dialog_before_close() {
        //state.grid.removeAll();
        state.list = undefined;
      },
    };

    //20221209 추가
    onMounted(async () => {
      if (state.canSign) {
          vm.$secuKit.init();
          vm.$secuKit.checkReady();
      }
    });

    return {
      state,
      ...method,
      ...batchSignDialog.dialogRef,
      ...batchSignDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog ref="batchSignDialog" title="일괄 서명" width="1200px" height="600px" @before-close="dialog_before_close">
    <template #button-center>
      <iui-button @click="btnBatchSign_click" :enable="state.canSign">일괄서명</iui-button>
    </template>

    <iui-content-box>
      <template #title>
        일괄 서명 대상 목록
      </template>
      <iui-container
        type="ibsheet"
        :loading="state.loading"
        :ibsheet.sync="state.grid"
        :ibsheet-options="state.gridOptions"
        :ibsheet-data="state.list"
        @ibsheet-created="grid_created"
      />
    </iui-content-box>
  </iui-dialog>
</template>

<style></style>

<template>
  <article id="contents">
    <iui-mdi></iui-mdi>
    <!--<hr class="sub_line mb10" />-->
    <div
      class="gnb_location h1"
      style="display: none;"
      v-if="$store.getters.currentUrl == '/Home' || $store.getters.currentUrl == '/'"
    />
    <div class="gnb_location" style="display: none;" v-else>
      <div class="stit">
        <h3>{{ selectedViewNm }}</h3>
      </div>
      <div class="breadcrumbs" v-html="selectedViewPath"></div>
      <div class="button_section">
        <a
          href="#"
          v-for="btn in cmmBtns"
          :key="btn.name"
          :class="[
            btn.name == '조회'
              ? 'btn_01 mr5'
              : btn.name == '신규'
              ? 'btn_02 mr5'
              : btn.name == '저장'
              ? 'btn_03 mr5'
              : btn.name == '삭제'
              ? 'btn_04 mr5'
              : btn.name == '출력'
              ? 'btn_05 mr5'
              : '',
          ]"
          @click="btn.callback"
          :disabled="btn.disabled"
          >{{ btn.name }}</a
        >
        <a href="#" class="btn_06 mr5" @click="onClose">닫기</a>
      </div>
    </div>
    <!-- 메뉴 밑에 라인 -->
    <pmis-keep-alive>
      <router-view></router-view>
    </pmis-keep-alive>
    <!-- 메뉴 밑에 라인 -->

    <!-- <article id="content" style="height: calc(100% - 48px);"> -->
    <!-- content -->
    <!-- <keep-alive :include="modules">
        <router-view class="view"></router-view>
      </keep-alive> -->
    <!-- //content -->
    <!-- </article> -->
  </article>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import IuiMdi from './IuiMdi.vue';

export default {
  name: 'iui-main',
  components: {
    'iui-mdi': IuiMdi,
  },
  computed: {
    ...mapGetters('layout', {
      cmmBtns: 'cmmBtns',
    }),
    ...mapGetters('menu', {
      selectedViewNm: 'selectedViewNm',
      selectedViewPath: 'selectedViewPath',
    }),
    ...mapGetters({modules: 'modules'}),
  },
  methods: {
    ...mapActions('mdi', ['addMDI']),
    onClose: function() {
      this.$store.dispatch('mdi/removeMdi', this.$store.getters.currentUrl);
    },
  },
};
</script>

<style></style>

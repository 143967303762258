<script>
import {defineComponent, ref} from '@vue/composition-api';
import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup.js';

export default defineComponent({
  props: {
    v1: {
      type: String,
      default: '',
    },
    v2: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const samplePopup = useWindowPopup('samplePopup');

    const btn_click = () => {
      // 부모창으로 이벤트 보낼 때 사용
      samplePopup.emit('popup-click', 'popup-click-value');
    };

    const btnClose_click = () => {
      samplePopup.close();
    };

    return {
      ...samplePopup.popupRef,
      btn_click,
      btnClose_click,
    };
  },
});
</script>

<template>
  <iui-window-popup ref="samplePopup" title="제목">
    <template #button-center>
      <iui-button @click="btn_click">적용</iui-button>
      <iui-button @click="btnClose_click">닫기</iui-button>
    </template>

    v1: {{ v1 }} / v2: {{ v2 }}
  </iui-window-popup>
</template>

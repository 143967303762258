// CSS Flexible Box Layout
// https://developer.mozilla.org/ko/docs/Web/CSS/CSS_Flexible_Box_Layout
export const CssFlexProps = {
  // flex
  // 하나의 플렉스 아이템이 자신의 컨테이너가 차지하는 공간에 맞추기 위해 크기를 키우거나 줄이는 방법을 설정하는 속성
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-grow
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-shrink
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-basis
  //
  // /* Keyword values */
  // flex: auto;
  // flex: initial;
  // flex: none;

  // /* One value, unitless number: flex-grow */
  // flex: 2;

  // /* One value, width/height: flex-basis */
  // flex: 10em;
  // flex: 30%;
  // flex: min-content;

  // /* Two values: flex-grow | flex-basis */
  // flex: 1 30px;

  // /* Two values: flex-grow | flex-shrink */
  // flex: 2 2;

  // /* Three values: flex-grow | flex-shrink | flex-basis */
  // flex: 2 2 10%;

  // /* Global values */
  // flex: inherit;
  // flex: initial;
  // flex: unset;
  //
  // flex 속성은 한 개에서 세 개의 값을 사용해 지정할 수 있습니다.

  // * 값이 한 개일 때, 그 값은 다음 중 하나여야 합니다.
  // <number>를 지정하면 <flex-grow>입니다.
  // <length> 또는 <percentage>를 지정하면 <flex-basis>입니다.
  // none, auto, initial 중 하나를 지정할 수 있습니다.

  // * 값이 두 개일때, 첫 번째 값은 <number>여야 하며 <flex-grow>가 됩니다. 두 번째 값은 다음 중 하나여야 합니다.
  // <number>를 지정하면 <flex-shrink>입니다.
  // <length>, <percentage>, 또는 auto를 지정하면 <flex-basis>입니다.

  // * 값이 세 개일 때는 다음 순서를 따라야 합니다.
  // <flex-grow>에 사용할 <number>
  // <flex-shrink>에 사용할 <number>
  // <flex-basis>에 사용할 <length>, <percentage>, 또는 auto
  flex: {
    type: String,
    default: undefined,
  },
  // flexDirection
  // 주 축(main-axis) 설정
  // 플렉스 컨테이너 내의 아이템을 배치할 때 사용할 주축 및 방향(정방향, 역방향)을 지정
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-direction
  // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction
  //
  // /* The direction text is laid out in a line */
  // flex-direction: row;

  // /* Like <row>, but reversed */
  // flex-direction: row-reverse;

  // /* The direction in which lines of text are stacked */
  // flex-direction: column;

  // /* Like <column>, but reversed */
  // flex-direction: column-reverse;

  // /* Global values */
  // flex-direction: inherit;
  // flex-direction: initial;
  // flex-direction: unset;
  flexDirection: {
    type: String,
    default: undefined,
  },
  // flexWrap
  // flex-item 요소들이 강제로 한줄에 배치되게 할 것인지, 또는 가능한 영역 내에서 벗어나지 않고 여러행으로 나누어 표현 할 것인지 결정하는 속성
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-wrap
  // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-wrap
  //
  // flex-wrap: nowrap; /* Default value */
  // flex-wrap: wrap;
  // flex-wrap: wrap-reverse;

  // /* Global values */
  // flex-wrap: inherit;
  // flex-wrap: initial;
  // flex-wrap: unset;
  flexWrap: {
    type: String,
    default: undefined,
  },
  // flexFlow
  // flex-direction, flex-wrap 속성의 단축 속성
  // https://developer.mozilla.org/ko/docs/Web/CSS/flex-flow
  // https://developer.mozilla.org/ko/docs/Web/CSS/CSS_Flexible_Box_Layout/Flexbox%EC%9D%98_%EA%B8%B0%EB%B3%B8_%EA%B0%9C%EB%85%90#%EC%B6%95%EC%95%BD%ED%98%95_%EC%86%8D%EC%84%B1_flex-flow
  // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-flow
  //
  // /* flex-flow: <'flex-direction'> */
  // flex-flow: row;
  // flex-flow: row-reverse;
  // flex-flow: column;
  // flex-flow: column-reverse;

  // /* flex-flow: <'flex-wrap'> */
  // flex-flow: nowrap;
  // flex-flow: wrap;
  // flex-flow: wrap-reverse;

  // /* flex-flow: <'flex-direction'> and <'flex-wrap'> */
  // flex-flow: row nowrap;
  // flex-flow: column wrap;
  // flex-flow: column-reverse wrap-reverse;

  // /* Global values */
  // flex-flow: inherit;
  // flex-flow: initial;
  // flex-flow: unset;
  flexFlow: {
    type: String,
    default: undefined,
  },

  // align-content: 교차 축(cross-axis)의 정렬 방법을 설정(2줄 이상)
  // align-items: 교차 축(cross-axis)에서 Items의 정렬 방법을 설정(1줄)
  // https://developer.mozilla.org/ko/docs/Web/CSS/align-items
  // flex 컨테이너에 지정하는 속성이며, 교차축을 따라 flex 항목 열을 정렬하는 방식을 지정
  //
  // align-self
  // justify-content
  // 주 축(main-axis)의 정렬 방법을 설정
  // 주축을 따라 flex 항목 행을 정렬하는 방식을 지정
  //
  // https://developer.mozilla.org/ko/docs/Web/CSS/CSS_Flexible_Box_Layout/Flexbox%EC%9D%98_%EA%B8%B0%EB%B3%B8_%EA%B0%9C%EB%85%90#%EC%A0%95%EB%A0%AC_%EB%81%9D_%EB%A7%9E%EC%B6%94%EA%B8%B0(justification)_flex_%ED%95%AD%EB%AA%A9%EA%B0%84_%EC%97%AC%EC%9C%A0_%EA%B3%B5%EA%B0%84_%EB%B6%84%EB%B0%B0
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Alignment
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Aligning_Items_in_a_Flex_Container
  //
  // /* Positional alignment */
  // justify-content: center;     /* Pack items around the center */
  // justify-content: start;      /* Pack items from the start */
  // justify-content: end;        /* Pack items from the end */
  // justify-content: flex-start; /* Pack flex items from the start */
  // justify-content: flex-end;   /* Pack flex items from the end */
  // justify-content: left;       /* Pack items from the left */
  // justify-content: right;      /* Pack items from the right */

  // /* Baseline alignment */
  // /* justify-content does not take baseline values */

  // /* Normal alignment */
  // justify-content: normal;

  // /* Distributed alignment */
  // justify-content: space-between; /* Distribute items evenly
  //                                    The first item is flush with the start,
  //                                    the last is flush with the end */
  // justify-content: space-around;  /* Distribute items evenly
  //                                    Items have a half-size space
  //                                    on either end */
  // justify-content: space-evenly;  /* Distribute items evenly
  //                                    Items have equal space around them */
  // justify-content: stretch;       /* Distribute items evenly
  //                                    Stretch 'auto'-sized items to fit
  //                                    the container */

  // /* Overflow alignment */
  // justify-content: safe center;
  // justify-content: unsafe center;

  // /* Global values */
  // justify-content: inherit;
  // justify-content: initial;
  // justify-content: unset;

  // /* Basic keywords */
  // align-items: normal;
  // align-items: stretch;

  // /* Positional alignment */
  // /* align-items does not take left and right values */
  // align-items: center; /* Pack items around the center */
  // align-items: start; /* Pack items from the start */
  // align-items: end; /* Pack items from the end */
  // align-items: flex-start; /* Pack flex items from the start */
  // align-items: flex-end; /* Pack flex items from the end */

  // /* Baseline alignment */
  // align-items: baseline;
  // align-items: first baseline;
  // align-items: last baseline; /* Overflow alignment (for positional alignment only) */
  // align-items: safe center;
  // align-items: unsafe center;

  // /* Global values */
  // align-items: inherit;
  // align-items: initial;
  // align-items: unset;

  // /* Keyword values */
  // align-self: auto;
  // align-self: normal;

  // /* Positional alignment */
  // /* align-self does not take left and right values */
  // align-self: center; /* Put the item around the center */
  // align-self: start; /* Put the item at the start */
  // align-self: end; /* Put the item at the end */
  // align-self: self-start; /* Align the item flush at the start */
  // align-self: self-end; /* Align the item flush at the end */
  // align-self: flex-start; /* Put the flex item at the start */
  // align-self: flex-end; /* Put the flex item at the end */

  // /* Baseline alignment */
  // align-self: baseline;
  // align-self: first baseline;
  // align-self: last baseline;
  // align-self: stretch; /* Stretch 'auto'-sized items to fit the container */

  // /* Overflow alignment */
  // align-self: safe center;
  // align-self: unsafe center;

  // /* Global values */
  // align-self: inherit;
  // align-self: initial;
  // align-self: unset;

  // /* Basic positional alignment */
  // /* align-content does not take left and right values */
  // align-content: center;     /* Pack items around the center */
  // align-content: start;      /* Pack items from the start */
  // align-content: end;        /* Pack items from the end */
  // align-content: flex-start; /* Pack flex items from the start */
  // align-content: flex-end;   /* Pack flex items from the end */

  // /* Normal alignment */
  // align-content: normal;

  // /* Baseline alignment */
  // align-content: baseline;
  // align-content: first baseline;
  // align-content: last baseline;

  // /* Distributed alignment */
  // align-content: space-between; /* Distribute items evenly
  //                                  The first item is flush with the start,
  //                                  the last is flush with the end */
  // align-content: space-around;  /* Distribute items evenly
  //                                  Items have a half-size space
  //                                  on either end */
  // align-content: space-evenly;  /* Distribute items evenly
  //                                  Items have equal space around them */
  // align-content: stretch;       /* Distribute items evenly
  //                                  Stretch 'auto'-sized items to fit
  //                                  the container */

  // /* Overflow alignment */
  // align-content: safe center;
  // align-content: unsafe center;

  // /* Global values */
  // align-content: inherit;
  // align-content: initial;
  // align-content: unset;
};

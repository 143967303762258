const name = '/SYS301';
const state = {
  reportMain: {
    manageCd: '',
    verNo: '',
    rptNm:'',
    applcDe: '',
    applcYn : '',
    rptFlpth: '',
    changeCn: '',
    _rowStatusCode: '',
    _rowStatusName: '',
  },
};

const getters = {
  reportMain: state => state.reportMain,
};

const actions = {};

const mutations = {
  //setUpperDetailInfo(state, payload) {
    setReportMain(state, payload) {
    for (let key in state.reportMain) {
      if (payload && payload.hasOwnProperty(key)) {
        state.reportMain[key] = payload[key];
      } else {
        state.reportMain[key] = '';
      }
    }
  },
};

export default {
  name,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

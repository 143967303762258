<template>
  <pmis-content-box>
    <iui-container type="jqgrid" :id="sheetId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
    isCheck: {
      type: Boolean,
      default: true,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'focusKeyCode']);
    $mapMutations(this, ['setSearchInfo']);
  },
  created() {
    this.addEvent([
      {name: 'EmpList_search', func: this.onSearch},
      {name: 'EmpList_setData', func: this.onSetData},
    ]);
  },
  data() {
    return {
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      sheetId: 'sheet' + this._uid,
      bindArray: ['setSearchInfo'],
    };
  },
  async mounted() {
    const me = this;

    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 200,
      width: 800,
      autowidth: true,
      shrinkToFit: true,
      selectionMode: 'singlerow',
      colModel: [
        {name: 'empId', label: '사번', width: 100, align: 'center'},
        {name: 'empName', label: '이름', width: 150, align: 'center'},
        {name: 'posTitleName', label: '직위', width: 70, align: 'center'},
        {name: 'teamNo', label: '부서코드', width: 100, align: 'center'},
        {name: 'teamNoDesc', label: '부서명', width: 200},
      ],
      // eslint-disable-next-line
      ondblClickRow: function(rowid, iRow, iCol, e) {
        var $grid = $('#' + me.sheetId);
        $grid.setSelection(rowid);
        me.callEvent({name: `empSearchOnConfirm${me.uid}`});
      },
    });
    $gridAutoResize($grid);

    return;
    // me.sheet.bind('onDblClick', function (e) {
    // 	if (e.row.Kind == 'Data') {
    // 		me.callEvent({name: `empSearchOnConfirm${me.uid}`}); // iuiSearchbox.vue => ${this.type}SearchOnConfirm${this._uid}
    // 	}
    // });
  },
  methods: {
    onSearch(param) {
      const me = this;
      var $grid = $('#' + me.sheetId);

      if (!param.searchNm || param.searchNm == '') {
        // 검색조건 없음
        $grid.jqGrid('clearGridData');
        return;
      }

      param = {
        empId: param.searchSe == 'empId' ? param.searchNm : '',
        empName: param.searchSe == 'empName' ? param.searchNm : '',
        teamNo: param.searchSe == 'teamNo' ? param.searchNm : '',
        teamNoDesc: param.searchSe == 'teamNoDesc' ? param.searchNm : '',
      };
      axios.post('/common/popup/selectEmpList', param).then(function(response) {
        $grid.jqGrid('clearGridData');
        $grid.jqGrid('setGridParam', {data: response.data}).trigger('reloadGrid');
      });
    },
    onReSearch(e) {
      const me = this;
      me.setSearchInfo({searchUseCloseDs: e.target.value});
      me.onSearch();
    },
    onEmitEmpInfo(row) {
      this.$emit('emitEmpInfo', row);
    },
    onDelete() {},

    onSetData(param) {
      const me = this;
      var grid = $('#' + me.sheetId);
      var rowId = grid.jqGrid('getGridParam', 'selrow');
      var rowData = grid.getRowData(rowId);
      param(rowData);
    },
  },
};
</script>

<style scoped></style>

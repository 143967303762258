export const Code = {
  async bind(el, binding, vnode) {
    console.group(`[Code directive] bind`);
    console.log(arguments);
    console.log(`typeof binding.value: ${typeof binding.value}`);
    console.log(`typeof vnode.componentInstance: ${typeof vnode.componentInstance}`);
    console.log(`typeof vnode.componentInstance.items: ${typeof vnode.componentInstance.items}`);
    console.log(
      `vnode.componentInstance.$props.hasOwnProperty('items'): ${vnode.componentInstance.$props.hasOwnProperty(
        'items'
      )}`
    );

    console.groupEnd();
  },
  async inserted(el, binding, vnode) {
    console.group(`[Code directive] inserted`);
    console.log(arguments);

    let hasItemsProps = vnode.componentInstance.$props.hasOwnProperty('items');

    if (hasItemsProps) {
      if (vnode.componentInstance.items === undefined) {
        console.log(`[Code directive] getItems - start`);
        let key = undefined;
        let keyAlias = undefined;
        let textAlias = undefined;
        let valueAlias = undefined;

        if (typeof binding.value === 'object') {
          key = binding.value.key;
          keyAlias = binding.value.keyAlias;
          textAlias = binding.value.textAlias;
          valueAlias = binding.value.valueAlias;
        } else {
          key = binding.value;
          keyAlias = 'code';
          textAlias = 'codeNm';
          valueAlias = 'code';
        }

        let codes = await $getCode(vnode.context, key);
        console.log(`[Code directive] getItems - end`);
        // console.log(`[test1 directive] set props items - start`);
        // console.log(`[test1 directive] set props items - end`);

        console.log(`[Code directive] set data internalItems - start`);
        // vnode.componentInstance.$props.items = codes.dCodeList;
        // vnode.componentInstance.$props.itemKeyAlias = 'code';
        // vnode.componentInstance.$props.itemTextAlias = 'codeNm';
        // vnode.componentInstance.$props.itemValueAlias = 'code';

        vnode.componentInstance.$data.internalItems = codes;
        vnode.componentInstance.$data.internalItemKeyAlias = keyAlias;
        vnode.componentInstance.$data.internalItemTextAlias = textAlias;
        vnode.componentInstance.$data.internalItemValueAlias = valueAlias;
        console.log(`[Code directive] set data internalItems - end`);
      }
    }

    vnode.componentInstance.$on('change', () => {
      console.log(`[Code directive] change event`);
    });

    console.groupEnd();
  },
  update() {
    console.group(`[Code directive] update`);
    console.log(arguments);
    console.groupEnd();
  },
  componentUpdated() {
    console.group(`[Code directive] componentUpdated`);
    console.log(arguments);
    console.groupEnd();
  },
  unbind() {
    console.group(`[Code directive] unbind`);
    console.log(arguments);
    console.groupEnd();
  },
};

export default Code;

<template>
  <iui-container type="ibsheet" :id="elId" />
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },

  beforeCreate() {
    $mapGetters(this, {grpAuthInfo: 'grpAuthInfo', userAuthInfo: 'userAuthInfo'});
  },

  created() {
    this.addEvent([
      {name: 'selectGrpMenuAuthList', func: this.selectGrpMenuAuthList},
      {name: 'grpMenuAuthSaveJson', func: this.grpMenuAuthSaveJson},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    async initSheet() {
      const me = this;
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            Header: '프로그램명',
            Name: 'mnuNm',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '조회',
            Name: 'vwYn',
            Type: 'Enum',
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', 'YESNO'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', 'YESNO'),
            Width: 80,
            Visible: 1,
            CanEdit: 1,
          },
          {
            Header: '저장',
            Name: 'svYn',
            Type: 'Enum',
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', 'YESNO'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', 'YESNO'),
            Width: 80,
            Visible: 1,
            CanEdit: 1,
          },
          {
            Header: '삭제',
            Name: 'delYn',
            Type: 'Enum',
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', 'YESNO'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', 'YESNO'),
            Width: 80,
            Visible: 1,
            CanEdit: 1,
          },
          {
            Header: '출력',
            Name: 'prnYn',
            Type: 'Enum',
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', 'YESNO'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', 'YESNO'),
            Width: 80,
            Visible: 1,
            CanEdit: 1,
          },
          {
            Name: 'mnuCd',
            Type: 'Text',
            Visible: 0,
            CanEdit: 0,
          },
        ],
        Cfg: {
          CanEdit: 1,
          SearchMode: 2,
          MainCol: 'mnuNm',
        },
      };

      me.sheet = IBSheet.create({
        id: me.sheetId, // 시트 객체 ID
        el: me.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      me.sheet.bind('onAfterChange', e => {
        if (e.col == 'vwYn' && e.val == 'N') {
          this.sheet.setValue(e.row, 'svYn', 'N', 1);
          this.sheet.setValue(e.row, 'delYn', 'N', 1);
          this.sheet.setValue(e.row, 'prnYn', 'N', 1);
        }

        this.sheet.getChildRows(e.row).some(item => {
          if (e.row.Level == 1) {
            if (e.row.mnuCd != item.uppMnuCd) {
              return true;
            }
          }

          if (e.col == 'vwYn') {
            if (e.val == 'N') {
              this.sheet.setValue(item, 'vwYn', e.val, 1);
              this.sheet.setValue(item, 'svYn', e.val, 1);
              this.sheet.setValue(item, 'delYn', e.val, 1);
              this.sheet.setValue(item, 'prnYn', e.val, 1);
            } else {
              this.sheet.setValue(item, 'vwYn', e.val, 1);
            }
          } else {
            this.sheet.setValue(item, e.col, e.val, 1);
          }
        });
      });

      this.selectGrpMenuAuthList();
    },

    selectGrpMenuAuthList(grpAuth) {
      if (!grpAuth) grpAuth = this.grpAuthInfo.grpAuth;
      axios.post('/grpAuth/selectGrpMenuAuthList', {pgmCd: this.userInfo.pgmCd, grpAuth: grpAuth}).then(response => {
        if (response.status == 200) {
          let items = $_treeModel(response.data, 'PGM000', 'mnuCd', 'uppMnuCd');
          this.sheet.loadSearchData(items, 0);
        }
      });
    },

    grpMenuAuthSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>

<script>
import {defineComponent} from '@vue/composition-api';
import {BUTTON_TYPE} from '@/components/Iui/const.js';

export default defineComponent({
  name: 'iui-content-box-title-box',
  props: {
    titleType: {
      type: String,
      default: undefined,
    },
  },
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  computed: {
    // titleBoxVisible() {
    //   return this.titleVisible || this.titleLeftVisible || this.titleCenterVisible || this.titleRightVisible;
    // },
    titleVisible() {
      return this.$slots['title'] !== undefined;
    },
    titleLeftVisible() {
      return this.$slots['title-left'] !== undefined;
    },
    titleCenterVisible() {
      return this.$slots['title-center'] !== undefined;
    },
    titleRightVisible() {
      return this.$slots['title-right'] !== undefined;
    },
  },
});
</script>

<template>
  <div class="title-box">
    <p v-if="titleVisible && titleType !== 'tab'" class="title">
      <slot name="title" />
    </p>
    <ul v-if="titleVisible && titleType === 'tab'" class="title-tab-box">
      <li class="selected">
        <slot name="title" />
      </li>
    </ul>
    <div class="title-spacer"></div>
    <div v-if="titleLeftVisible" class="title-left">
      <slot name="title-left" />
    </div>
    <div class="title-center">
      <slot name="title-center" />
    </div>
    <div v-if="titleRightVisible" class="title-right">
      <slot name="title-right" />
    </div>
  </div>
</template>

<style></style>

export function appendArrayToObject(targetObject, propertyName, sourceArray) {
  if (sourceArray.length > 0) {
    Object.defineProperty(targetObject, propertyName, {writable: true});
    targetObject[propertyName] = Object.assign(...sourceArray);
  }
}

export function emitUpdateObjectProps(context, updatePropsName, objectProps, propertyName, value) {
  context.emit(`update:${updatePropsName}`, {...objectProps, [propertyName]: value});
}

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100">
      <i-row>
        <i-col-header>해지 방법</i-col-header>
        <i-col>
          <iui-select-old
            name="trmnatMthCd"
            :items="arrTrmnatMthCd"
            :value="contractMain.trmnatMthCd"
            :enable="false"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            name="trmnatResnCn"
            :value="contractMain.trmnatResnCn"
            :enable="true"
            @change="onSetValue"
            height="100px"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>오프라인 완료일</i-col-header>
        <i-col>
          <iui-datepicker-old
            name="trmnatComptDe"
            :value="contractMain.trmnatComptDe"
            @change="onSetValue($event, 'trmnatComptDe')"
            :enable="true"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>CC 담당자</i-col-header>
        <i-col>
          <iui-text name="contEmpNm" :value="contractInfo.contEmpNm" :enable="false" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  name: 'TerminationAgreement',
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: String,
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'TerminationAgreement_onSave', func: this.onSave});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      arrTrmnatMthCd: [{text: '오프라인 진행', value: 'OFF'}],

      contractMain: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        trmnatMthCd: 'OFF',
        trmnatResnCn: '',
        trmnatComptDe: '',
      },
    };
  },
  mounted() {
    console.log('TerminationAgreement');

    this.contractMain.ctrtNo = this.ctrtNo;
    this.contractMain.ctrtOdr = this.ctrtOdr;
    this.contractMain.ctrtDivCd = this.ctrtDivCd;
  },
  methods: {
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.contractMain[key] = value;
    },
    async onSave(callback) {
      console.log('onSave');
      if (!this.contractMain.trmnatResnCn.trim()) {
        this.$alert('사유를 입력해야합니다.');
        return;
      }

      if (this.contractMain.trmnatComptDe <= 0) {
        this.$alert('오프라인 완료일을 입력해야 합니다.');
        return;
      }

      if (await this.$confirm('해지합의서 결과를 처리 하시겠습니까?')) {
        let param = {
          ...this.contractMain,
          pgmCd: this.userInfo.pgmCd,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        const me = this;
        try {
          me.listBoxLoading = true;
          await axios.post('/tcontract/saveTerminationAgreement', param);
          me.listBoxLoading = false;
          me.$alert('해지 합의서 결과 처리가 완료되었습니다.');
          callback({action: 'onSendToOkss'});
        } catch (ex) {
          me.listBoxLoading = false;
          me.$alert('해지 합의서 결과 처리가 실패하였습니다.' + e);
        }
      }
    },
  },
};
</script>

<style></style>

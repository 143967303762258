<template>
  <v-dialog />
</template>

<script>
import Loading from '@/plugins/loading';
export default {
	data() {
		return {
			msg: '',
			name: 'dialog',
		};
	},
	created() {
		Loading.event.$on('show', this.handleShowEvent);
	},
	beforeMount() {
		window.addEventListener('keyup', this.onEscapeKeyUp);
	},
	methods: {
		handleShowEvent(msg, resolve, reject, callback) {
			this.msg = msg;
			console.log("this.msg",this.msg);
			if(this.msg == "close"){
				this.hide();
			}else{
				this.show(resolve, reject, callback);
			}
		},
		onEscapeKeyUp(event) {
			if (event.which === 27) {
				this.callback;
			}
		},
		show(resolve, reject, callback) {
			if (!callback) callback = () => {};
			this.$modal.show(this.name, {
				title: 'Infomation!',
				text: this.msg,
				buttons: [
					{
						title: '<div class="iui-loading-overlay" style="display: flex"><div class="background" style="display: none;"></div><div class="icon"></div></div>',
						
					},
				],
			});
		},
		hide() {
			this.$modal.hide(this.name);
		},
	},
};
</script>

<style></style>

<script>
import {defineComponent, ref, reactive} from '@vue/composition-api';
import DataList from './components/DataList.vue';
import DataView from './components/DataView.vue';

export default defineComponent({
  components: {DataList, DataView},
  setup() {
    const state = reactive({
      search: false,
      dataKey: undefined,
      dataChanged: false,
    });

    const refs = {
      list: ref(null),
    };

    const pageButton = {
      search_click() {
        // state.search = true;

        refs.list.value.getList();
      },
    };

    return {
      state,
      ...refs,
      ...pageButton,
    };
  },
});
</script>

<template>
  <otis-page @search-click="search_click">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <data-list
            ref="list"
            :search="state.search"
            :selected-data-key.sync="state.dataKey"
            :selected-data-changed="state.dataChanged"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <data-view :data-key="state.dataKey" :data-changed.sync="state.dataChanged" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

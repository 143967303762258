import {reactive, toRef, computed} from '@vue/composition-api';

export const LayoutProps = {
  theme: {
    type: String,
    default: undefined,
  },
  width: {
    type: String,
    default: undefined,
  },
  height: {
    type: String,
    default: undefined,
  },
  align: {
    type: String,
    default: undefined,
  },
  verticalAlign: {
    type: String,
    default: undefined,
  },
  valign: {
    type: String,
    default: undefined,
  },

  layoutDebug: {
    type: Boolean,
    default: false,
  },
};

export function useLayout(props, context, rootClassName) {
  const widthRef = toRef(props, 'width');
  const heightRef = toRef(props, 'height');
  const alignRef = toRef(props, 'align');
  const verticalAlignRef = toRef(props, 'verticalAlign');
  const valignRef = toRef(props, 'valign');

  const verticalAlign = computed(() => verticalAlignRef.value || valignRef.value);

  const state = reactive({
    width: widthRef,
    height: heightRef,
    align: alignRef,
    verticalAlign: verticalAlign,
    valign: verticalAlign,
    layoutDebug: props.layoutDebug,

    class: {
      [rootClassName]: true,
      'iui-layout-debug': props.layoutDebug,
    },
    style: {
      width: widthRef,
      height: heightRef,
      flex: computed(() => (widthRef.value === undefined ? undefined : `0 0 ${widthRef.value} !important`)),
    },
  });

  return {
    state,
  };
}

export default useLayout;

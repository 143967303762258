<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <!-- <i-row height="330px" ref='srchList'> 20211110 lgksja 사이즈조절-->
      <i-row height="60%" ref="srchList">
        <i-col>
          <MainList customer-page @click="onClickMainListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-content-box :loading="contentBoxLoading">
            <i-row>
              <i-col>
                <SubList customer-page @selRow="onClickSubListRow" @resetValues="onResetValues" />
              </i-col>
              <i-spacer />
              <i-col>
                <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
              </i-col>
            </i-row>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from '@/view/confdoc/part/store/part.js';
import MainList from './components/MainList.vue';
import SubList from './components/SubList.vue';
export default {
  name: 'Part',
  components: {
    MainList,
    SubList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'mainList', 'subList', 'cnfrmnMain', 'tradeType']);
    $mapMutations(this, [
      'setSearchInfo',
      'setMainList',
      'setSubList',
      'setCnfrmnMain',
      'setPartList',
      'setCheckerList',
      'setPictureList',
      'setMemberList',
      'resetAllValues',
      'setTradeType',
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        // {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '교체부품내역',
          compNm: 'Part_Parts',
          path: '@/view/confdoc/part/components/tab/Parts.vue',
        },
        {
          tabNm: '고객 확인자 정보',
          compNm: 'Part_Checker',
          path: '@/view/confdoc/part/components/tab/Checker.vue',
        },
        {
          tabNm: '작업증빙사진 첨부',
          compNm: 'Part_Picture',
          path: '@/view/confdoc/part/components/tab/Picture.vue',
        },
        {
          tabNm: '구성원정보',
          compNm: 'Part_Members',
          path: '@/view/confdoc/part/components/tab/Members.vue',
        },
      ],
      tabIndex: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      cnfrmnNo: '',
      contentBoxLoading: false,
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'Part_SearchSubDetail', func: this.onSearchSubDetail});
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    //this.onSearch();
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'PartMainList_PreSearch'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },

    // async onPrint() {
    //   this.$store.dispatch('mdi/doPrint');
    // },

    onClickMainListRow(row) {
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;

      // console.log('cindex row.tradeType', row.tradeType);

      this.setTradeType(row.tradeType);

      this.callEvent({name: 'PartSubList_ReBindJqGrid', param: null});

      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        paymentType: '',
        statusCode: '',
        summaryYm: '',
        empNo: '',
      };

      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined && urls.params !== undefined) {
        var params = urls.params;
        console.log('params', params);

        param.paymentType = params.paymentType;
        param.statusCode = params.statusCode;
        param.summaryYm = params.summaryYm;
        param.empNo = params.empNo;
      }

      this.callEvent({name: 'PartSubList_PreSearch', param: param});
    },
    onClickSubListRow(row) {
      this.cnfrmnNo = row.cnfrmnNo;
      if (this.cnfrmnNo !== undefined && this.cnfrmnNo != '') {
        this.onSearchSubDetail();
      }
    },
    async onSearchSubDetail() {
      this.contentBoxLoading = true;
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        cnfrmnNo: this.cnfrmnNo,
        isCustomerPage: true,
        tradeType: this.tradeType,
      };
      // eslint-disable-next-line
      let response = await axios.post('/confdoc/part/selectAllSubDetails', param);
      this.contentBoxLoading = false;

      this.setCnfrmnMain(response.data.cnfrmnMain);
      this.setPartList(response.data.partList);
      this.setCheckerList(response.data.checkerList);
      this.setPictureList(response.data.pictureList);
      this.setMemberList(response.data.memberList);

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'Part_Parts_Load', param: {otisPage: false}});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Part_Checker_Load', param: {otisPage: false}});
      } else if (this.tabIndex == 2) {
        this.callEvent({name: 'Part_Picture_Load', param: {otisPage: false}});
      } else if (this.tabIndex == 3) {
        this.callEvent({name: 'Part_Members_Load', param: {otisPage: false}});
      }
    },
    onResetValues() {
      console.log('onResetValues');
      this.callEvent({name: 'PartSubList_ResetSubListData', param: null});
      this.resetAllValues();
      this.onCallEvent();
    },
  },
};
</script>

<style scoped></style>

import './css/style.scss';
import * as util from '@/components/Iui/util.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';
import IuiLoadingOverlay from '@/components/Iui/components/IuiLoadingOverlay/IuiLoadingOverlay';
import TagMap from './tagMap.js';

export default {
  name: 'iui-container',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  props: {
    id: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'table',
    },
    headerWidth: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },

    ibsheet: {
      default: undefined,
    },
    ibsheetId: {
      default: undefined,
    },
    ibsheetOptions: {
      default: undefined,
    },
    ibsheetData: {
      default: undefined,
    },
  },
  data() {
    return {
      internalId: undefined,
      internalIBSheet: undefined,
      internalIBSheetData: undefined,
    };
  },
  provide() {
    return {
      containerType: this.type,
      containerHeaderWidth: this.headerWidth,
    };
  },
  watch: {
    ibsheetData(newValue, oldValue) {
      this.ibsheet_setInternalIBSheetData(newValue);
      this.ibsheet_loadSearchData();
    },
  },
  created() {
    if (this.id) {
      this.internalId = this.id;
    } else {
      this.internalId = this.generateNewId();
      this.$emit('update:id', this.internalId);
    }

    if (this.type === 'ibsheet') {
      this.ibsheet_setInternalIBSheetData(this.ibsheetData);
    }
  },
  async mounted() {
    const me = this;

    if (this.type === 'ibsheet') {
      const options = {
        ...me.ibsheetOptions,
      };

      options.Def = {
        Row: {
          CanFormula: 1,
          ShowHint: 0,
          Tip: 1,
        },
      };

      options.Cfg = {
        // CanSort: false,
        CustomScroll: 1,
        Down2ExcelConfig: {
          downCols: 'Visible',
          excelFontSize: 11,
          merge: 1,
        },
        Export: {
          Url: process.env.VUE_APP_BASE_URL + '/ibsheet/',
          Down2ExcelUrl: process.env.VUE_APP_BASE_URL + '/ibsheet/downloadExcel',
        },
        FitWidth: true,
        HeaderMerge: 6,
        // IgnoreFocused: true,
        MarkupTagDelimiter: ['┼', '╫', '╬', '╪'],
        SearchMode: 0,
        // Style: 'IBSP',
        ...options.Cfg,
      };

      if (options.LeftCols !== undefined) {
        this.ibsheet_setDefaultColSetting(options.LeftCols);
      }

      if (options.RightCols !== undefined) {
        this.ibsheet_setDefaultColSetting(options.RightCols);
      }

      this.ibsheet_setDefaultColSetting(options.Cols);

      options.Events = {
        ...options.Events,
      };

      console.log('options', options);

      const ibsheetLoader = window.IBSheetLoader;
      const ibsheet = await ibsheetLoader.createSheet({
        el: me.internalId,
        options: options,
      });

      this.internalIBSheet = ibsheet;

      this.$emit('update:ibsheet', this.internalIBSheet);
      this.$emit('ibsheet-created', this.internalIBSheet);

      if (this.ibsheetData) {
        this.ibsheet_loadSearchData();
      }
    }
  },
  beforeDestroy() {
    if (this.type === 'ibsheet' && this.internalIBSheet) {
      const ibsheetLoader = window.IBSheetLoader;
      ibsheetLoader.removeSheet(this.internalIBSheet.id);
    }
  },
  render(h) {
    let data = {};

    /**
     * tag 처리
     */
    let tag = TagMap.get(this.type);
    if (tag === undefined || tag === null) {
      tag = TagMap.get('default');
    }

    tag = tag.container;

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = `iui-container ${this.type}`;
    if (this.theme === undefined) {
      defaultClassName = `${defaultClassName} default`;
    } else {
      defaultClassName = `${defaultClassName} ${this.theme}`;
    }

    classList.push({[defaultClassName]: true});
    if (this.type === 'ibsheet') {
      classList.push({'iui-loading-overlay-parent': true});
    }
    if (this.type === 'jqgrid') {
      //classList.push({'iui-loading-overlay-parent': true});
    }

    util.appendArrayToObject(data, 'class', classList);

    /**
     * style 처리
     */
    let styleList = [];

    // if (this.type === 'ibsheet') {
    // styleList.push({width: this.width === undefined ? '100%' : this.width});
    // styleList.push({height: this.height === undefined ? '100%' : this.height});
    // } else {
    if (this.width !== undefined) {
      styleList.push({width: this.width});
    }
    if (this.height !== undefined) {
      styleList.push({height: this.height});
    }
    // }

    if (this.flexDirection !== undefined) {
      styleList.push({'flex-direction': this.flexDirection});
    }

    util.appendArrayToObject(data, 'style', styleList);

    /**
     * attrs 처리
     */
    // let attrList = [];

    // if (this.type === 'ibsheet') {
    //   if (this.id !== undefined) {
    //     attrList.push({id: this.id});
    //   }
    // }

    // util.appendArrayToObject(data, 'attrs', attrList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    if (this.type === 'ibsheet') {
      childNodes.push(h(IuiLoadingOverlay, {props: {visible: this.loading}}));
      childNodes.push(
        h('div', {
          style: {
            width: this.width === undefined ? '100%' : this.width,
            height: this.height === undefined ? '100%' : this.height,
          },
          attrs: {id: this.internalId},
        })
      );
    }
    if (this.type === 'jqgrid') {
      childNodes.push(h(IuiLoadingOverlay, {props: {visible: this.loading}}));
      childNodes.push(
        h('table', {
          style: {
            width: this.width === undefined ? '100%' : this.width,
            height: this.height === undefined ? '100%' : this.height,
          },
          attrs: {id: this.internalId},
        })
      );
    }

    childNodes.push(this.$slots.default);

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
  methods: {
    generateNewId() {
      return `iui_${Math.random()
        .toString(16)
        .substring(2)}`;
    },
    ibsheet_setDefaultColSetting(colList) {
      colList.forEach(col => {
        if (col.Type === 'Date' && !col.DataFormat) {
          col.DataFormat = 'yyyy-MM-dd HH:mm:ss';
        }

        if (['ctrtNo', 'projectNo'].includes(col.Name)) {
          // col.TextFont = 'Noto Sans Mono';
          // col.TextSize = '12px';
          col.TextFont = 'GulimChe';
        }
      });
    },
    ibsheet_setInternalIBSheetData(data) {
      if (Array.isArray(data)) {
        this.internalIBSheetData = {data: data};
        return;
      }

      this.internalIBSheetData = data;
    },
    ibsheet_loadSearchData() {
      if (this.internalIBSheetData === undefined || this.internalIBSheetData === null) {
        this.internalIBSheet.removeAll();
        return;
      }

      this.internalIBSheet.loadSearchData(this.internalIBSheetData);
    },
    ibsheet_down2Excel(option) {
      this.internalIBSheet.down2Excel(option);
    },
  },
};

/*
#
{
  // `v-bind:class` 와 같음
  // accepting either a string, object, or array of strings and objects.
  class: {
    foo: true,
    bar: false
  },
  // `v-bind:style` 와 같음
  // accepting either a string, object, or array of objects.
  style: {
    color: 'red',
    fontSize: '14px'
  },
  // 일반 HTML 속성
  attrs: {
    id: 'foo'
  },
  // 컴포넌트 props
  props: {
    myProp: 'bar'
  },
  // DOM 속성
  domProps: {
    innerHTML: 'baz'
  },
  // `v-on:keyup.enter`와 같은 수식어가 지원되지 않으나
  // 이벤트 핸들러는 `on` 아래에 중첩됩니다.
  // 수동으로 핸들러에서 keyCode를 확인해야 합니다.
  on: {
    click: this.clickHandler
  },
  // 컴포넌트 전용.
  // `vm.$emit`를 사용하여 컴포넌트에서 발생하는 이벤트가 아닌
  // 기본 이벤트를 받을 수 있게 합니다.
  nativeOn: {
    click: this.nativeClickHandler
  },
  // 사용자 지정 디렉티브.
  // Vue는 이를 관리하기 때문에 바인딩의 oldValue는 설정할 수 없습니다.
  directives: [
    {
      name: 'my-custom-directive',
      value: '2',
      expression: '1 + 1',
      arg: 'foo',
      modifiers: {
        bar: true
      }
    }
  ],
  // 범위 지정 슬롯. 형식은
  // { name: props => VNode | Array<VNode> } 입니다.
  scopedSlots: {
    default: props => createElement('span', props.text)
  },
  // 이 컴포넌트가 다른 컴포넌트의 자식인 경우, 슬롯의 이름입니다.
  slot: 'name-of-slot',
  // 기타 최고 레벨 속성
  key: 'myKey',
  ref: 'myRef',
  // If you are applying the same ref name to multiple
  // elements in the render function. This will make `$refs.myRef` become an
  // array
  refInFor: true
}

# functional
context를 통해 컴포넌트에서 필요한 모든 것이 전달 됩니다. 이 객체는 다음을 포함합니다.

props: 전달받은 props에 대한 객체
children: VNode 자식의 배열
slots: 슬롯 객체를 반환하는 함수
scopedSlots: (2.6.0+) An object that exposes passed-in scoped slots. Also exposes normal slots as functions.
data: 컴포넌트에 전달된 전체 데이터 객체
parent: 상위 컴포넌트에 대한 참조
listeners: (2.3.0+) 부모에게 등록된 이벤트 리스너를 가진 객체입니다. data.on의 알리아스입니다.
injections: (2.3.0+) inject 옵션을 사용하면 리졸브드 인젝션을 가집니다

functional:true를 추가한 후 anchor를 가지는 heading 컴포넌트의 렌더 함수를 업데이트 하는 것은 단순히 context 전달인자를 추가하고 this.$slots.default를 context.children으로 갱신한 다음 this.level을 context.props.level로 갱신하면 됩니다.
*/

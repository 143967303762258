import {reactive, toRef, computed} from '@vue/composition-api';

export const LabelProps = {
  label: {
    type: String,
    default: undefined,
  },
  labelWidth: {
    type: String,
    default: undefined,
  },
};

export function useLabel(props, context, base) {
  const labelRef = toRef(props, 'label');
  const labelWidthRef = toRef(props, 'labelWidth');

  const state = reactive({
    has: computed(() => labelRef.value !== undefined || context.slots.label !== undefined),
    id: computed(() => base.getId('label')),
    style: {
      width: labelWidthRef,
      flex: computed(() => (labelWidthRef.value === undefined ? undefined : `0 0 ${labelWidthRef.value} !important`)),
    },
  });

  return {
    state,
  };
}

export default useLabel;

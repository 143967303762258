<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <iui-container type="jqgrid" :id="sheetId1" style="height:99.9%; width:99.9%;" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['partList']);
  },
  created() {
    this.addEvent({name: 'ConstCmpl_Parts_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'gubun', label: '구분', width: 50, align: 'left'},
        {name: 'itemNo', label: '품목번호', width: 100, align: 'left'},
        {name: 'itemName', label: '품명', width: 100, align: 'left'},
        {name: 'itemSize', label: '규격', width: 100, align: 'left'},
        {name: 'itemUnit', label: '단위', width: 50, align: 'left'},
        {
          name: 'qty',
          label: '수량',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'damt',
          label: '단가',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalAmt',
          label: '금액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          summaryType: 'sum',
          index: 'sumAmt',
        },
      ],
    };

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      // height:100,
      // width:800,
      colModel: OPT.Cols,
      selectionMode: 'singlerow',
      rownumbers: true,
    });

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      /*
      var $grid = $("#"+this.sheetId1);
      var pobj = $grid.closest(".ui-jqgrid").parent();
      var newWidth = pobj.width();
      $grid.jqGrid("setGridWidth", newWidth-20, true);
      */
      $('#gbox_' + this.sheetId1)
        .parent()
        .css('height', '100%');
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      var bottomLeftObj = $grid
        .parents('.pmis-page')
        .find('.spacer')
        .eq(0)
        .next();
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 77, false);
    },
    onLoad() {
      console.log('ConstCmpl_Parts_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      this.onResize();
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.partList})
        .trigger('reloadGrid');
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

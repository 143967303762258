<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @search-click="search_click"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col width="550px">
          <userList />
        </i-col>
        <i-spacer />
        <i-col>
          <log />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import userList from './components/userList';
import log from './components/log';
import store from './store/loginLog.js';
export default {
  components: {
    userList,
    log,
  },
  beforeCreate() {
    $init(this, store);

    $mapGetters(this, {searchInfo: 'searchInfo'});

    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd});
  },

  methods: {
    search_click() {
      this.callEvent({name: 'userList_selectLoginUserList', param: this.searchInfo});
    },
  },
};
</script>

<style scoped></style>

const state = {
  searchInfo: {
    pgmCd: '',
    frmNm: '',
  },
};
const getters = {
  searchInfo: state => state.searchInfo,
};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

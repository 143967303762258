<template>
  <aside>
    <nav id="gnb" >
    <div class="nav" style='position:absolute;left:-15px;top:0px;z-index:100'>
      <a href="#" class="menu_slide btn_slide_l" style='display:block;' @click="sideToggle" />
      <span class="blind">열고/닫기</span>
    </div>
      <h1>
        <a href="#" style='color:white;'><img src="@/assets/img/img_logo.jpg" width="200" height="54" border="0" alt=""></a>
      </h1>
      <div class='nav'>
        <div style='height:28px;'>&nbsp; </div>
      <ul v-if="Items.length" class="menu">
        <li v-for="m in Items" v-bind:key="m.menuId" :class="{m_selected: m.isSelected}">
          <a
            href="#"
            @click.prevent.self
            @click="onSelectItems"
            :data-menu-id="m.menuId"
            :class="[m.isSelected ? 'm_on' : 'm_off']"
          >{{ m.menuName }} </a>
          <ul v-if="m.Items.length && m.isSelected" class='s_menu'>
            <li v-for="sM in m.Items" v-bind:key="sM.menuId">
              <a
                href="#"
                @click.prevent.self
                @click="mnu_click(sM)"
                :class="[sM.isSelected ? 's_on' : 's_off']"
              >
                {{
                sM.menuName
                }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      </div>
    </nav>
    <!-- lnb end -->

    <!-- <nav class="nav">
      <dl>
        <dt>{{ selectedMenuNm }}</dt>
        <dd>
          <ul v-if="Items.length">
            <li v-for="m in Items" v-bind:key="m.menuId" :class="{open: m.isSelected}">
              <a href="#" @click.prevent.self @click="onSelectItems" :data-mnu-cd="m.menuId">{{ m.menuName }}</a>
              <ul v-if="m.Items.length && m.isSelected">
                <li v-for="sM in m.Items" v-bind:key="sM.menuId">
                  <a href="#" @click.prevent.self @click="addMDI(sM)">{{ sM.menuName }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </dd>
      </dl>
      <a href="#" class="close" @click.prevent.self @click="sideToggle"></a>
      <a href="#" class="open" @click.prevent.self @click="sideToggle"></a>
      <address>ⓒ INOGARD Corp</address>
    </nav>-->
  </aside>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

const MAX_URLS_LENGTH = 10;

export default {
  name: 'iui-side',
  data: function() {
    return {
      navs: [
        {name: 'myview1', url: '/myview1', path: './myview1.vue'},
        {name: 'myview2', url: '/myview2', path: './myview2.vue'},
      ],
    };
  },
  computed: {
    ...mapGetters('menu', {
      Items: 'Items',
      selectedMenuNm: 'selectedMenuNm',
    }),
    ...mapGetters('mdi', {
      urls: 'urls',
    }),
  },
  methods: {
    ...mapActions('mdi', ['addMDI']),
    ...mapActions('layout', ['sideToggle']),
    ...mapActions('menu', ['onSelectItems']),
    openSide: function() {},
    closeSide: function() {},

    async mnu_click(data) {
      // if (data.useDs === 'N') {
      //   this.$alert('화면을 준비 중입니다.');

      //   if (process.env.NODE_ENV === 'production') {
      //     return;
      //   }
      // }
      if ( (this.urls.filter(e => e.url === data.url).length==0) && (this.urls.length >= MAX_URLS_LENGTH)) {
        await this.$alert(
          `화면은 최대 ${MAX_URLS_LENGTH}개까지 열 수 있습니다.<br /><br />다른 화면을 닫은 후 다시 열어 주십시오.`
        );
        return;
      } else {
        this.addMDI(data, false);
      }
    },
  },
};
</script>

<style></style>

<template>
  <pmis-page
    :searchProjectVisible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @search-click="onSearch"
    @save-click="onSave"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box :loading="loading">
            <template v-slot:title>상세정보</template>
            <template>
              <iui-container type="css-flex">
                <i-row>
                  <i-col>
                    <i-row>
                      <i-col>
                        <!-- 회사기본정보 -->
                        <cmpInfo />
                      </i-col>
                    </i-row>
                    <i-row height="180px">
                      <!-- <i-row v-if="fileList_files.length > 0" height="180px"> -->
                      <pmis-content-box>
                        <template v-slot:title>첨부파일정보</template>
                        <pmis-file-list
                          v-model="fileList_files"
                          :company-code.sync="pgmCd"
                          :file-number.sync="fileList_fileNumber"
                          :save.sync="fileList_save"
                          :saveButtonVisible="false"
                          :allowAdd="false"
                          :allowDelete="false"
                          @save-complete="onFileSaveComplete"
                          toolbar-visible
                        />
                      </pmis-content-box>
                    </i-row>
                    <i-row>
                      <i-col>
                        <!-- 노무비관리정보 -->
                        <lbrcInfo v-show="companyInfo.frmGb == '000001'" />

                        <!-- 공사/납품 지역정보 -->
                        <areaInfo
                          v-show="companyInfo.frmGb == '000002' || companyInfo.frmGb == '000003'"
                          :title="companyInfo.frmGb == '000002' ? '공사지역정보' : '납품지역정보'"
                        />
                      </i-col>
                    </i-row>
                    <i-row v-if="companyInfo.pgmCd">
                      <i-col>
                        <!-- 시스템사용정보 -->
                        <systemUseInfo />
                      </i-col>
                    </i-row>
                  </i-col>
                  <i-spacer></i-spacer>
                  <i-col>
                    <i-row>
                      <i-col>
                        <!-- 보유면허정보 -->
                        <holdLcnsInfo v-show="companyInfo.frmGb == '000001'" />
                        <!-- 면허(세부공종)정보 -->
                        <workTypeInfo v-show="companyInfo.frmGb == '000002'" />
                        <!-- 공급물품(자재)정보 -->
                        <mtrilInfo v-show="companyInfo.frmGb == '000003'" />
                      </i-col>
                    </i-row>
                    <i-row>
                      <i-col>
                        <!-- 문서관리정보 -->
                        <docManageInfo :imageSave.sync="imageSave" :pgmCd.sync="pgmCd" />
                      </i-col>
                    </i-row>
                  </i-col>
                </i-row>
              </iui-container>
            </template>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import cmpInfo from '@/view/sysManage/userManage/member/components/cmpInfo';
import lbrcInfo from '@/view/sysManage/userManage/member/components/lbrcInfo';
import holdLcnsInfo from '@/view/sysManage/userManage/member/components/holdLcnsInfo';
import areaInfo from '@/view/sysManage/userManage/member/components/areaInfo';
import workTypeInfo from '@/view/sysManage/userManage/member/components/workTypeInfo';
import mtrilInfo from '@/view/sysManage/userManage/member/components/mtrilInfo';
import docManageInfo from '@/view/sysManage/userManage/member/components/docManageInfo';
import systemUseInfo from '@/view/sysManage/userManage/member/components/systemUseInfo';
import store from '@/view/sysManage/userManage/member/store/member.js';
export default {
  components: {
    cmpInfo,
    lbrcInfo,
    areaInfo,
    workTypeInfo,
    mtrilInfo,
    holdLcnsInfo,
    docManageInfo,
    systemUseInfo,
  },

  watch: {
    fileFlag: {
      handler(val) {
        let fileComplete = true;
        for (let key in val) {
          if (!val[key]) {
            fileComplete = false;
          }
        }
        if (fileComplete) {
          this.saveCompany();
          this.initFileFlag();
        } else {
          if (val['lgFl'] && val['djFl'] && val['smFl'])
            //첨부파일 업로드
            this.fileList_save = true;
        }
      },
      deep: true,
    },
    fileList_save: {
      handler(val) {
        if (!val) this.setFileFlag({fl: true});
      },
    },
  },

  data() {
    return {
      loading: false,
      fileList_files: [],
      fileList_fileNumber: 0,
      fileList_save: false,
      pgmCd: '',
      imageSave: false,
      param: {},
      companyData: {},
      cud: 2,
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['fileFlag', 'companyInfo', 'worker', 'dupBsnNoStatus', 'ceoList']);
    $mapMutations(this, [
      'initFileFlag',
      'setFileFlag',
      'initCompanyInfo',
      'setCompanyInfo',
      'setWorker',
      'setAreaList',
      'setMatList',
      'setGcodeList',
      'setLicenseList',
      'setDocnmList',
      'setCeoList',
    ]);
  },

  created() {
    this.addEvent([{name: 'companyRegist_onDetailLoading', func: this.onDetailLoading}]);
  },

  mounted() {
    this.selectCompanyInfo({pgmCd: this.userInfo.pgmCd});
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'cmpList_selectCompanyNotConfirmList'});
    },

    onSave() {
      this.onSaveCompany();
    },

    onDetailLoading(isLoading) {
      this.loading = isLoading;
    },

    onFileSaveComplete() {
      this.setCompanyInfo({flNo: this.fileList_fileNumber});
    },

    async onSaveCompany() {
      let msg = '저장하시겠습니까?';
      if (!(await this.$confirm(msg))) return;
      this.beforeSaveCompany();
    },

    async beforeSaveCompany() {
      this.pgmCd = this.companyInfo.pgmCd;

      // 관인/로고/심볼 파일 업로드
      this.imageSave = true;

      if (this.companyInfo.frmGb == '000003') {
        //납품지역
        this.callEvent({
          name: 'areaInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['areaList'] = $_statusToCud(data);
          },
        });
        // 공급물품정보
        this.callEvent({
          name: 'mtrilInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['matList'] = $_statusToCud(data);
          },
        });
      } else if (this.companyInfo.frmGb == '000002' || this.companyInfo.frmGb == '000004') {
        // 공사지역
        this.callEvent({
          name: 'areaInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['areaList'] = $_statusToCud(data);
          },
        });
        // 면허(세부)정보
        this.callEvent({
          name: 'workTypeInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['gcodeList'] = $_statusToCud(data);
          },
        });
      } else {
        //보유면허정보
        this.callEvent({
          name: 'holdLcnsInfo_getSaveJson',
          param: data => {
            data.forEach(el => (el.pgmCd = this.pgmCd));
            this.param['licenseList'] = $_statusToCud(data);
          },
        });
      }
      // 발신명의정보
      this.callEvent({
        name: 'docManageInfo_getSaveJson',
        param: data => {
          data.forEach(el => (el.pgmCd = this.pgmCd));
          this.param['docnmList'] = $_statusToCud(data);
        },
      });
      // 공동대표정보
      //   this.callEvent({name: 'ceoInfo_getSaveJson', param: (data) => (this.param['ceoList'] = data)});
      this.param['ceoList'] = this.ceoList;
    },
    saveCompany() {
      this.imageSave = false;
      this.param = {...this.param, ...this.companyInfo, pgmCd: this.pgmCd, cud: this.cud};
      let url = '/companyRegist/saveCompanyInfo';
      axios
        .post(url, this.param)
        .then(response => {
          if (response.status == 200) {
            this.$alert('저장을 완료하였습니다.');
            this.selectCompanyInfo({pgmCd: this.companyInfo.pgmCd});
          } else this.$alert('저장을 실패하였습니다.');
        })
        .catch(ex => {
          console.log('저장을 실패하였습니다. : ', ex);
        });
    },
    selectCompanyInfo(param) {
      this.callEvent({name: 'companyRegist_onDetailLoading', param: true});
      axios.post('/companyRegist/selectCompanyInfo', param).then(response => {
        if (response.status == 200) {
          this.companyData = response.data;
          this.setCompanyInfo(this.companyData);
          this.setWorker(this.companyData.worker);
          if (this.companyData.licenseList) {
            this.setLicenseList(this.companyData.licenseList);
          }
          if (this.companyData.areaList) {
            this.setAreaList(this.companyData.areaList);
          }
          if (this.companyData.gcodeList) {
            this.setGcodeList(this.companyData.gcodeList);
          }
          if (this.companyData.matList) {
            this.setMatList(this.companyData.matList);
          }
          if (this.companyData.docnmList) {
            this.setDocnmList(this.companyData.docnmList);
          }
          if (this.companyData.ceoList) {
            this.setCeoList(this.companyData.ceoList);
          }
          this.callEvent({name: 'companyRegist_onDetailLoading', param: false});
        }
      });
    },
  },

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style scoped>
.popup_modal .popup_contents {
  margin: 30px;
}
</style>

<template>
  <div class="contents">
    <div class="join_step03">
      <span class="blind">step03 회원정보 작성</span>
    </div>

    <div class="terms_box" style="width:100%; height:510px">
      <member />
    </div>

    <div class="btn_area">
      <button type="button" class="btn_default" @click="prevStep">취소</button>
      <button type="button" class="btn_confirm ml10" @click="nextStep">가입하기</button>
    </div>
  </div>
  <!-- contents end -->
</template>

<script>
import member from '@/view/sysManage/userManage/member/join';
export default {
  components: {
    member,
  },

  beforeCreate() {
    $mapMutations(this, ['initCompanyInfo']);
  },

  methods: {
    prevStep() {
      this.initCompanyInfo();
      this.$parent.nextStep('02');
    },
    nextStep(step) {
      if (typeof step == 'string') {
        this.$parent.nextStep('04');
      } else {
        this.callEvent({name: 'onSaveCompany', param: 1});
      }
    },
  },
};
</script>

<style scoped></style>

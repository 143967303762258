<template>
  <pmis-tab-box>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-select-old
          name="searchSe"
          :items="searchSeList"
          :value="'empName'"
          @change="setSearchInfo({searchSe: $event.target.value})"
        ></iui-select-old>
        <iui-text
          :value="searchNm"
          @change="setSearchInfo({searchNm: $event.target.value})"
          @enter="selectUserList"
          width="150px"
        />
        <iui-button @click="selectUserList">검색</iui-button>
      </template>
      <iui-container type="jqgrid" :id="elId" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchSeList: [
        {value: 'empName', text: '성명'},
        {value: 'empNo', text: '사번'},
        {value: 'userid', text: '아이디'},
      ],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['tabIndex', 'searchInfo']);
    $mapMutations(this, ['setEmpNo', 'setSearchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'getUserListSaveJson', func: this.getUserListSaveJson},
      {name: 'UserList_selectUserList', func: this.selectUserList},
    ]);
  },

  mounted() {
    this.initSheet();
  },
  methods: {
    async initSheet() {
      var me = this;
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {label: 'No', name: 'rowNumber', align: 'center', width: 30},
          {
            label: '아이디',
            name: 'userid',
            align: 'center',
            width: 80,
          },
          {
            label: '사번',
            name: 'empNo',
            align: 'center',
            width: 80,
          },
          {
            label: '성명',
            name: 'empName',
            width: 120,
          },
          {
            label: '부서명',
            name: 'teamNoDesc',
            width: 150,
          },
        ],
        Cfg: {
          CanEdit: 1,
        },
      };
      var $grid = $('#' + me.elId);
      $grid.jqGrid({
        height: 200,
        width: 800,
        autowidth: true,
        shrinkToFit: true,
        selectionMode: 'singlerow',
        colModel: OPT.Cols,
        onSelectRow: function(rowid) {
          var $grid = $('#' + me.elId);
          var rowdata = $grid.getRowData(rowid);
          me.setEmpNo(rowdata.empNo);
          if (me.tabIndex === 0) {
            me.callEvent({name: 'UserGroupList_selectUserGroupRList', param: rowdata.empNo});
          } else {
            me.callEvent({name: 'GrpMenuAuthList_selectUserMenuAuthList', param: rowdata.empNo});
          }
        },
      });
      $gridAutoResize($grid);
    },

    selectUserList() {
      const me = this;
      var $grid = $('#' + me.elId);

      if (me.searchInfo.searchNm == '') return;
      var sparam = {
        pgmCd: this.userInfo.pgmCd,
        empName: me.searchInfo.searchSe == 'empName' ? me.searchInfo.searchNm : '',
        empNo: me.searchInfo.searchSe == 'empNo' ? me.searchInfo.searchNm : '',
        userid: me.searchInfo.searchSe == 'userid' ? me.searchInfo.searchNm : '',
      };

      $grid.jqGrid('clearGridData');
      axios.post('/userAuth/selectUserList', sparam).then(response => {
        if (response.status == 200) {
          var data = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i]['rowNumber'] = i + 1;
          }
          $grid.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
        }
      });
    },

    getUserListSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>

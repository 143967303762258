<template>
  <pmis-content-box style="height:320px">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-container v-if="isDocCat1_040" type="table" :headerWidth="width200">
            <i-row>
              <i-col-header rowspan="6">대금지급의 방식</i-col-header>
              <i-col-header rowspan="3">유지관리료의<br />지급방식<br />{{ cust1Name }}</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>지급방식</i-col-header>
              <i-col>
                <input type="checkbox" id="chkCmsYn" v-model="isChkCust1MainSuFlagA7Yn" disabled="true" />
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust1payAtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="3">유지관리료의<br />지급방식<br />{{ cust2Name }}</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>지급방식</i-col-header>
              <i-col>
                <!-- <input type="checkbox" id="chkCmsYn" v-model="isChkCust1SubSuFlagA7Yn" disabled="true"> -->
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust1payBtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="3">중도해지위약별</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mediCourt974" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="3">자체점검주기</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkCntAz" :enable="false" width="200px" />
              </i-col>
            </i-row>
          </iui-container>
          <iui-container
            v-else-if="isDocCat1_060 || isDocCat1_050 || isDocCat1_070"
            type="table"
            :headerWidth="width200"
          >
            <i-row>
              <i-col-header rowspan="4">대금지급의 방식</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="2">지급방식(개별지급)</i-col-header>
              <i-col>
                <iui-text :value="osep1Name" :enable="false" width="200px" />
                <input type="checkbox" id="chkCmsYn" v-model="isChkCust1MainSuFlagA7Yn" disabled="true" />
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust1payAtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-text :value="osep2Name" :enable="false" width="200px" />
                <!-- <input type="checkbox" id="chkCmsYn" v-model="isChkCust2MainSuFlagA7Yn" disabled="true"> -->
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust2payAtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">중도해지위약별</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mediCourt974" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">자체점검주기</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkCntAz" :enable="false" width="200px" />
              </i-col>
            </i-row>
          </iui-container>
          <iui-container v-else-if="isDocCat1_080" type="table" :headerWidth="width200">
            <i-row>
              <i-col-header rowspan="8">대금지급의 방식</i-col-header>
              <i-col-header rowspan="4">유지관리료의<br />지급방식<br />{{ cust1Name }}</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="2">지급방식(개별지급)</i-col-header>
              <i-col>
                <iui-text :value="osep1Name" :enable="false" width="200px" />
                <input type="checkbox" id="chkCmsYn" v-model="isChkCust1MainSuFlagA7Yn" disabled="true" />
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust1payAtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-text :value="osep2Name" :enable="false" width="200px" />
                <!-- <input type="checkbox" id="chkCmsYn" v-model="isChkCust2MainSuFlagA7Yn" disabled="true"> -->
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust2payAtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="4">유지관리료의<br />지급방식<br />{{ cust2Name }}</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="2">지급방식(개별지급)</i-col-header>
              <i-col>
                <iui-text :value="osep1Name" :enable="false" width="200px" />
                <input type="checkbox" id="chkCmsYn" v-model="isChkCust1SubSuFlagA7Yn" disabled="true" />
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust1payBtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <iui-text :value="osep2Name" :enable="false" width="200px" />
                <!-- <input type="checkbox" id="chkCmsYn" v-model="isChkCust2SubSuFlagA7Yn" disabled="true"> -->
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <iui-text
                  :value="cust2payBtypeAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="3">중도해지위약별</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mediCourt974" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="3">자체점검주기</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkCntAz" :enable="false" width="200px" />
              </i-col>
            </i-row>
          </iui-container>
          <iui-container
            v-else-if="isDocCat1_010 || isDocCat1_020 || isDocCat1_030"
            type="table"
            :headerWidth="width200"
          >
            <i-row>
              <i-col-header rowspan="3">대금지급의 방식</i-col-header>
              <i-col-header>지급주기</i-col-header>
              <i-col>
                <input type="checkbox" id="chkPayMonthlyYn" v-model="isPayMonthlyYn" disabled="true" />
                <label for="chkPayMonthlyYn"><span></span>매월&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayPrepayYn" v-model="isPayPrepayYn" disabled="true" />
                <label for="chkPayPrepayYn"><span></span>선지급&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayYearlyYn" v-model="isPayYearlyYn" disabled="true" />
                <label for="chkPayYearlyYn"><span></span>(분기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayQuarterYn" v-model="isPayQuarterYn" disabled="true" />
                <label for="chkPayQuarterYn"><span></span>반기&nbsp;</label>
                &nbsp;
                <input type="checkbox" id="chkPayHalfyearYn" v-model="isPayHalfyearYn" disabled="true" />
                <label for="chkPayHalfyearYn"><span></span>연간)&nbsp;</label>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>청구일자</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.billPrtDay" :enable="false" suffix="일" width="50px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>지급방식</i-col-header>
              <i-col>
                <input type="checkbox" id="chkCmsYn" v-model="isChkCmsYnSingletype" disabled="true" />
                <label for="chkCmsYn"><span></span>자동이체(CMS)&nbsp;</label>
                <!-- <iui-text :value="contractInfo.sumTotalAmt" type="amount" prefix="월 총" suffix="원(VAT 별도)" :enable="false" /> -->
                <iui-text
                  :value="contractInfo.supplyTotalAmt"
                  type="amount"
                  prefix="월 총"
                  suffix="원(VAT 별도)"
                  :enable="false"
                />
                <div style="width:100%; text-align:left; color: red;">
                  * 대금 청구시에는 부가세를 포함한 금액이 청구됩니다.
                </div>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">중도해지위약별</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mediCourt974" :enable="false" width="200px" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">자체점검주기</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkCntAz" :enable="false" width="200px" />
              </i-col>
            </i-row>
          </iui-container>
          <iui-container v-else type="table" :headerWidth="width200">
            <i-row>
              <i-col>계약서의 지급조건을 참조해주세요.</i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo', 'paymentList']);
  },
  created() {
    this.addEvent({name: 'OContractTabPaymentMethod_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      cust1Name: '',
      cust2Name: '',
      osep1Name: '',
      osep2Name: '',
      cust1payAtypeAmt: 0,
      cust1payBtypeAmt: 0,
      cust2payAtypeAmt: 0,
      cust2payBtypeAmt: 0,

      isChkCmsYnSingletype: false,
      isChkCust1MainSuFlagA7Yn: false,
      isChkCust1SubSuFlagA7Yn: false,
      isChkCust2MainSuFlagA7Yn: false,
      isChkCust2SubSuFlagA7Yn: false,

      isPayMonthlyYn: false,
      isPayPrepayYn: false,
      isPayYearlyYn: false,
      isPayQuarterYn: false,
      isPayHalfyearYn: false,
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      console.log('OContractTabPaymentMethod_Load');

      this.isChkCmsYnSingletype = this.contractInfo.suFlagA7Yn == 'Y' ? true : false;

      if (this.paymentList[0] !== undefined) {
        this.cust1Name = this.paymentList[0].mainCustName;
        this.cust2Name = this.paymentList[0].subCustName;

        this.osep1Name = this.paymentList[0].tradeName;
        this.cust1payAtypeAmt = this.paymentList[0].payAtypeAmt;
        this.cust1payBtypeAmt = this.paymentList[0].payBtypeAmt;
        this.isChkCust1MainSuFlagA7Yn = this.paymentList[0].mainSuFlagA7Yn == 'Y' ? true : false;
        this.isChkCust1SubSuFlagA7Yn = this.paymentList[0].subSuFlagA7Yn == 'Y' ? true : false;
      }

      if (this.paymentList[1] !== undefined) {
        this.osep2Name = this.paymentList[1].tradeName;
        this.cust2payAtypeAmt = this.paymentList[1].payAtypeAmt;
        this.cust2payBtypeAmt = this.paymentList[1].payBtypeAmt;
        this.isChkCust2MainSuFlagA7Yn = this.paymentList[1].mainSuFlagA7Yn == 'Y' ? true : false;
        this.isChkCust2SubSuFlagA7Yn = this.paymentList[1].subSuFlagA7Yn == 'Y' ? true : false;
      }

      this.isPayMonthlyYn = this.contractInfo.payMonthlyYn == 'Y' ? true : false;
      this.isPayPrepayYn = this.contractInfo.payPrepayYn == 'Y' ? true : false;
      this.isPayQuarterYn = this.contractInfo.payQuarterYn == 'Y' ? true : false;
      this.isPayHalfyearYn = this.contractInfo.payHalfyearYn == 'Y' ? true : false;
      this.isPayYearlyYn = this.contractInfo.payYearlyYn == 'Y' ? true : false;
    },
  },
  computed: {
    isDocCat1_010() {
      return this.contractInfo.payMthDocCat1 == '010';
      //   return false;
    },
    isDocCat1_020() {
      return this.contractInfo.payMthDocCat1 == '020';
      //   return false;
    },
    isDocCat1_030() {
      return this.contractInfo.payMthDocCat1 == '030';
      //   return false;
    },
    isDocCat1_040() {
      return this.contractInfo.payMthDocCat1 == '040';
      //   return false;
    },
    isDocCat1_050() {
      return this.contractInfo.payMthDocCat1 == '050';
      //   return false;
    },
    isDocCat1_060() {
      return this.contractInfo.payMthDocCat1 == '060';
      //   return false;
    },
    isDocCat1_070() {
      return this.contractInfo.payMthDocCat1 == '070';
      //   return false;
    },
    isDocCat1_080() {
      return this.contractInfo.payMthDocCat1 == '080';
      //   return false;
    },
  },
};
</script>

<style></style>

/* 보증보험 등록 팝업 * /
<script>
import {defineComponent, reactive, watch, computed, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import FileUpDown from '@/view/sample/FileUpDown';

export default defineComponent({
  props: {
    grntySn: {
      type: Number,
      default: undefined,
    },
    contractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {FileUpDown},

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const guaranteeDialog = useDialog('guaranteeDialog');

    const state = reactive({
      loading: false,

      codeList: {
        // 구분
        arrGrntyKndCd: [
          {text: '계약이행', value: '002'},
          {text: '선급금이행', value: '001'},
          {text: '하자이행', value: '003'},
        ],
      },

      guaranteeInfo: {
        grntySn: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        grntyKndCd: '002', // 구분
        issuInsttNm: '', // 보증기관
        grntyNo: '', // 증권번호
        grntyAm: '', // 보증금액
        issuDe: $_getCurrentDate(), // 보증일자
        grntyBgnDe: '', // 보증기간 시작일
        grntyEndDe: '', // 보증기간 종료일
        fileNo: 0,
        scdYn: 'Y',
      },

      entryEmpId: undefined,

      canEnable: computed(() => {
        if (props.grntySn === undefined) {
          return true;
        }
        if (sessionStorage.getItem('userInfo') !== null) {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          return userInfo.empNo == state.entryEmpId;
        } else {
          return false;
        }
      }),

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false, // 추가
      fileUpDown_delete: false, // 삭제
      fileUpDown_download: false, // 다운
      fileUpDown_search: false,
      fileUpDown_save: false,
      fileUpDown_changed: false,
    });

    watch(
      () => props.grntySn,
      newValue => {
        if (newValue === undefined) {
          return;
        }
        method.getData();
      }
    );

    watch(
      () => props.contractKey,
      newValue => {
        if (newValue === undefined) {
          return;
        }

        state.guaranteeInfo.grntySn = '';
        state.guaranteeInfo.ctrtNo = props.contractKey.ctrtNo;
        state.guaranteeInfo.ctrtOdr = props.contractKey.ctrtOdr;
        state.guaranteeInfo.ctrtDivCd = props.contractKey.ctrtDivCd;
        state.guaranteeInfo.grntyKndCd = '002';
        state.guaranteeInfo.issuInsttNm = '';
        state.guaranteeInfo.grntyNo = '';
        state.guaranteeInfo.grntyAm = '';
        state.guaranteeInfo.issuDe = $_getCurrentDate();
        state.guaranteeInfo.grntyBgnDe = '';
        state.guaranteeInfo.grntyEndDe = '';
        state.guaranteeInfo.fileNo = 0;
        state.guaranteeInfo.scdYn = 'Y';
      }
    );

    const method = {
      // 조회
      async getData() {
        const param = {
          grntySn: props.grntySn,
        };

        try {
          state.loading = true;

          const response = await axios.post('/guarantee/selectByGrntySn', param);

          state.guaranteeInfo = response.data;
          state.entryEmpId = response.data.entryEmpId;
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      // 저장
      async btnSave_click() {
        if (!state.canEnable) {
          vm.$alert('읽기모드로 조회 중입니다.');
          return;
        }

        if (state.guaranteeInfo.grntyKndCd.length == 0) {
          vm.$alert('구분을 선택해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.issuInsttNm.length == 0) {
          vm.$alert('보증기관을 입력해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.grntyNo.length == 0) {
          vm.$alert('증권번호를 입력해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.issuDe <= 0) {
          vm.$alert('보증일자를 입력해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.grntyAm.length == 0 || state.guaranteeInfo.grntyAm <= 0) {
          vm.$alert('보험가입금액을 입력해야 합니다.');
          return;
        }
        var regType1 = /^[0-9+]*$/;
        if (!regType1.test(state.guaranteeInfo.grntyAm)) {
          vm.$alert('보험가입금액은 숫자만 입력 가능합니다.');
          return;
        }
        if (state.guaranteeInfo.grntyBgnDe <= 0) {
          vm.$alert('보험기간 시작일자를 입력해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.grntyEndDe <= 0) {
          vm.$alert('보험기간 종료일자를 입력해야 합니다.');
          return;
        }
        if (state.guaranteeInfo.grntyBgnDe > state.guaranteeInfo.grntyEndDe) {
          vm.$alert('보험기간 시작일자가 종료일자보다 클 수 없습니다.');
          return;
        }
        if (state.fileUpDown_files.lclFlNm === undefined || state.fileUpDown_files.lclFlNm == '') {
          vm.$alert('보증서 파일을 첨부해야 합니다.');
          return;
        }

        if (!(await vm.$confirm('저장하시겠습니까?'))) {
          return;
        }

        if (state.fileUpDown_files.lclFlNm !== undefined && state.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            state.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          method.saveGuarantee();
        }
      },

      // 삭제
      async btnDelete_click() {
        if (props.grntySn === undefined) {
          vm.$alert('저장 후 삭제가 가능합니다.');
          return;
        }

        if (!state.canEnable) {
          vm.$alert('읽기모드로 조회 중입니다.');
          return;
        }

        if (!(await vm.$confirm('삭제하시겠습니까?'))) {
          return;
        }

        let pgmCd = undefined;
        if (sessionStorage.getItem('userInfo') !== null) {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          pgmCd = userInfo.pgmCd;
        }

        const data = {
          ...state.guaranteeInfo,
          pgmCd: pgmCd, //this.userInfo.pgmCd,
          chngPgmId: undefined,
        };

        if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
          data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
        }

        try {
          state.loading = true; // 로딩 상태 변경

          await axios.post('/guarantee/deleteByGrntySn', data);

          await vm.$alert('삭제되었습니다.');

          guaranteeDialog.hide(true);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false; // 로딩 상태 변경
        }
      },

      async saveGuarantee() {
        const data = {
          ...state.guaranteeInfo,
          chngPgmId: undefined,
        };

        if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
          data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
        }

        try {
          state.loading = true; // 로딩 상태 변경

          const uri =
            props.grntySn === undefined ? '/guarantee/insertGuarantee' : '/guarantee/updateByGrntySnSelective';

          await axios.post(uri, data);

          await vm.$alert('저장되었습니다.');

          guaranteeDialog.hide(true);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false; // 로딩 상태 변경
        }
      },

      file_save_complete() {
        console.log('파일 저장이 완료되었습니다.');

        if (state.fileUpDown_files.lclFlNm === undefined || state.fileUpDown_files.lclFlNm == '') {
          state.guaranteeInfo.fileNo = 0;
        } else {
          state.guaranteeInfo.fileNo = state.fileUpDown_files.flNo;
        }

        method.saveGuarantee();
      },
      file_save_error() {
        console.log('파일 저장을 실패했습니다.');
      },
      file_delete_complete() {
        console.log('파일 삭제가 완료되었습니다.');
      },
      file_delete_error() {
        console.log('파일 삭제를 실패했습니다.');
      },
    };

    return {
      state,
      ...method,
      ...guaranteeDialog.dialogRef,
      ...guaranteeDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog ref="guaranteeDialog" :loading="state.loading" width="700px" height="300px" title="보증 정보">
    <template #button-center>
      <iui-button @click="btnSave_click" :enable="state.canEnable">저장</iui-button>
      <iui-button @click="btnDelete_click" :enable="state.canEnable">삭제</iui-button>
    </template>

    <iui-content-box>
      <iui-container type="table" header-width="100px">
        <i-row>
          <i-col-header>계약번호</i-col-header>
          <i-col>
            <iui-textbox v-model="state.guaranteeInfo.ctrtNo" width="200px" disable />
          </i-col>
          <i-col-header>구분</i-col-header>
          <i-col>
            <iui-select
              v-model="state.guaranteeInfo.grntyKndCd"
              :items="state.codeList.arrGrntyKndCd"
              :enable="state.canEnable"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>보증기관</i-col-header>
          <i-col>
            <iui-textbox v-model="state.guaranteeInfo.issuInsttNm" width="200px" :enable="state.canEnable" />
          </i-col>
          <i-col-header>증권번호</i-col-header>
          <i-col>
            <iui-textbox v-model="state.guaranteeInfo.grntyNo" width="200px" :enable="state.canEnable" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>보증일자</i-col-header>
          <i-col>
            <iui-datepicker v-model="state.guaranteeInfo.issuDe" :enable="state.canEnable" />
          </i-col>
          <i-col-header>보증금액</i-col-header>
          <i-col>
            <iui-textbox
              type="number"
              v-model="state.guaranteeInfo.grntyAm"
              prefix="\"
              width="200px"
              :enable="state.canEnable"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>보험기간</i-col-header>
          <i-col colspan="3">
            <iui-datepicker v-model="state.guaranteeInfo.grntyBgnDe" :enable="state.canEnable" suffix="~" />
            <iui-datepicker v-model="state.guaranteeInfo.grntyEndDe" :enable="state.canEnable" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>보증서첨부</i-col-header>
          <i-col colspan="3">
            {{ state.fileUpDown_files.lclFlNm }}
            <iui-button @click="state.fileUpDown_add = true" v-show="state.canEnable && !state.fileUpDown_files.lclFlNm"
              >추가</iui-button
            >
            <iui-button
              @click="state.fileUpDown_delete = true"
              v-show="state.canEnable && state.fileUpDown_files.flNo > 0 && state.fileUpDown_files.lclFlNm"
              >삭제</iui-button
            >
            <iui-button
              @click="state.fileUpDown_download = true"
              v-if="state.fileUpDown_files.flNo > 0 && state.fileUpDown_files.svrFlPth"
              >다운</iui-button
            >
            <iui-button @click="state.fileUpDown_search = true" v-if="false">조회</iui-button>
            <iui-button @click="state.fileUpDown_save = true" v-if="false">저장</iui-button>
            <file-up-down
              company-code="201900"
              v-model="state.fileUpDown_files"
              :file-number.sync="state.guaranteeInfo.fileNo"
              add-button-text="파일추가"
              delete-button-text="파일삭제"
              save-button-text="파일저장"
              download-button-text="파일다운"
              :add.sync="state.fileUpDown_add"
              :delete.sync="state.fileUpDown_delete"
              :download.sync="state.fileUpDown_download"
              :search.sync="state.fileUpDown_search"
              :save.sync="state.fileUpDown_save"
              :changed.sync="state.fileUpDown_changed"
              @save-complete="file_save_complete"
              @save-error="file_save_error"
              @delete-complete="file_delete_complete"
              @delete-error="file_delete_error"
            />
          </i-col>
        </i-row>
      </iui-container>
    </iui-content-box>
  </iui-dialog>
</template>

<style></style>

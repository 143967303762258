<template>
  <div style="width:100%; height:100%" id="iOzViewer">
    <iframe
      :src="urlParam"
      id="ozFrame"
      name="ozFrame"
      style="width:100%; height:95%; border:0px solid red;"
      scrolling="no"
      title="OZ Iframe"
    ></iframe>
    <div class="btn_area" id="aa">
      <!-- <button type="button" class="btn_default" @click="onReloadOzViewer">Reload</button> -->
      <button type="button" class="btn_default" @click="onReBindOzViewer">ReBind</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    OZParam: {
      type: Object,
    },
  },
  beforeCreate() {
    //console.log('@beforeCreate~');
  },
  created() {
    console.log('@created~');
    console.log('OZParam >>>', this.OZParam);

    this.addEvent({name: 'eForm_callOZSVGViewerEvent', func: this.onPopConfirm});

    //OZ Viewer 에서 message 를 호출.
    window.addEventListener('message', this.reqSetOzParam, false);
  },
  data() {
    return {
      //pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      urlParam: `${process.env.VUE_APP_OZ_SVG_QRY_VIEWER_URL}`,
      //urlParam : '/valid_chk.html'
      //urlParam : `${process.env.VUE_APP_BASE_URL}/oz/OzViewer.do`
    };
  },
  //async mounted() {
  mounted() {
    console.log(`@mounted->${process.env.VUE_APP_OZ_SVG_QRY_VIEWER_URL}`);
  },
  methods: {
    reqSetOzParam(e) {
      console.log('[parent]reqSetOzParam:::', e);
      if (e.data.msg == 'requestOzParam') {
        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

        //Event Listener 를 제거.
        // //document.getElementById('ozFrame').contentWindow.postMessage({msg: 'receiveMessage', ozdata: this.OZParam}, '*');
        window.removeEventListener('message', this.reqSetOzParam, false);
      } else if (e.data.msg == 'bind_completed') {
        this.ozBindCallback(e.data);
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    onPopConfirm(param) {
      //OZ Viewer 의 입력값들를 리턴받음.
      var ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
      console.log('oz_ret_val', ozVal);
      if (ozVal != undefined && ozVal != null) {
        param(ozVal);
      } else {
        return;
      }
    },
    onReloadOzViewer() {
      // let me = this;
      // console.log('reload==', this.OZParam);
      // /**** 오즈파라미터설정  ******/
      // this.OZParam.OZFormParam.signable = 'N';
      // //OZ Viewer 에서 message 를 호출할 Event 등록.
      // window.addEventListener('message', me.reqSetOzParam, false);
      // //OZ Reload
      // document.getElementById('ozFrame').contentWindow.location.reload(true);
    },

    // 21.03.09 작성.
    //오즈리포트 리바인딩 처리함수
    onReBindOzViewer() {
      //OZ Viewer 에서 message 를 호출.
      window.addEventListener('message', this.reqSetOzParam, false);

      /**** 오즈파라미터설정  ******/
      this.OZParam.OZFormParam.signable = 'Y';

      //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
      document.getElementById('ozFrame').contentWindow.setOzReBindData(this.OZParam);
    },
    ozBindCallback(p) {
      console.log('OZ Viewer Bind 완료시 호출되는 함수::::여기서 처리', p);
    },
  },
};
</script>

<style scoped></style>

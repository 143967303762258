export const upperCodeOpt = {
  Cfg: {
    ReqStatusName: 'STATUS',
    FitWidth: true,
    IgnoreFocused: 0, //첫번째행으로 포커스이동.
    SearchMode: 0,
    //CustomScroll: 1,
    //Style: 'IBMT',
  },
  Def: {
    Row: {
      CanFormula: true,
      CalcOrder: 'cbxCanEdit', //,manageCdCanEdit', //필드명의 Formula 속성명 명시
    },
  },
  //틀고정 좌측 컬럼 설정
  LeftCols: [
    {Header: 'No.', Type: 'Int', Width: 50, Align: 'Center', Name: 'SEQ'},
    {
      Header: '선택',
      Type: 'Bool',
      Name: 'cbx',
      Align: 'Center',
      Width: 50,
      // 이력이 존재할경우 삭제불가로직 추가. 22.04.20.
      CanEditFormula: fr => {
        // console.log('grid row:::::' + fr.Row['subRowCnt']);
        return fr.Row['subRowCnt'] < 1;
      },
    },
  ],
  Cols: [
    {
      Header: '코드',
      Type: 'Text',
      Name: 'cmnDCd',
      Align: 'Left',
      // Format: '####',
      Size: 20,
      Width: 250,
      Required: 1,
      ResultMask: '^[A-Za-z0-9_]{0,20}$' /* 영문(대/소문자), 숫자 4자리 */,
      ResultMessage: '영문, 숫자 조합으로 20자리까지만 입력가능합니다.',
      CanEdit: 1,
      // CanEditFormula: fr => fr.Row['verNo'] >= 1,
    },
    {
      Header: ['코드명'],
      Type: 'Text',
      Name: 'cmnDNm',
      Align: 'Left',
      Size: 20,
      Width: 150,
      Required: 1,
      CanEdit: 1,
    },
    {
      Header: ['코드설명'],
      Type: 'Text',
      Name: 'rmk',
      Width: 200,
      Size: 500,
      CanEdit: 1,
      Align: 'Left',
    },
    {Name: 'useDs', Visible: 0},
    {Name: 'cmnUCd', Visible: 0},
    {Name: 'pgmCd', Visible: 0},
    {Name: 'cdSqn', Visible: 0},
    {Name: 'etc1', Visible: 0},
    {Name: 'etc2', Visible: 0},
    {Name: 'etc3', Visible: 0},
    {Name: 'etc4', Visible: 0},
    {Name: 'etc5', Visible: 0},
  ],
  Events: {
    // onDataLoad(e) {
    //   console.log('onDataLoad', e);
    // },
  },
};

export default upperCodeOpt;

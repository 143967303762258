<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <colgroup>
        <col width="10%" />
        <col />
        <col width="10%" />
        <col />
      </colgroup>
      <i-row>
        <i-col-header>구분</i-col-header>
        <i-col>
          {{ natDsName }}
        </i-col>
        <i-col-header>조회수</i-col-header>
        <i-col>
          {{ detailInfo.inqCnt }}
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>제목</i-col-header>
        <i-col colspan="3">
          {{ detailInfo.tl }}
        </i-col>
      </i-row>
      <i-row v-if="this.natDs == 'NOTI'">
        <i-col-header>게시기간</i-col-header>
        <i-col>
          <iui-datepicker-old :value.sync="detailInfo.natStDate" :enable="false" />
          <label>-</label>
          <iui-datepicker-old :value.sync="detailInfo.natEnDate" :enable="false" />
        </i-col>
        <i-col-header>첨부파일</i-col-header>
        <i-col>
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-if="false">추가</iui-button>
          <iui-button @click="fileUpDown_delete = true" v-if="false">삭제</iui-button>
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            v-model="fileUpDown_files"
            :file-number.sync="detailInfo.flNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
          />
        </i-col>
      </i-row>
      <i-row v-if="this.natDs != 'NOTI'">
        <i-col-header>첨부파일</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-if="false">추가</iui-button>
          <iui-button @click="fileUpDown_delete = true" v-if="false">삭제</iui-button>
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            v-model="fileUpDown_files"
            :file-number.sync="detailInfo.flNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>등록자</i-col-header>
        <i-col>
          {{ detailInfo.rgprNm }}
        </i-col>
        <i-col-header>등록일시</i-col-header>
        <i-col>
          {{ detailInfo.rgsDttm }}
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>내용</i-col-header>
        <i-col colspan="3" style="text-align:left;">
          <Viewer :initialValue="initialValue" ref="Viewer" height="100%" :key="editorKey" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Viewer} from '@toast-ui/vue-editor';
export default {
  components: {
    FileUpDown,
    Viewer,
  },
  props: {
    natDs: {
      type: String,
      default: undefined,
    },
    natNo: {
      type: [Number, String],
      default: 0,
    },
  },
  created() {},
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      keyInfo: {
        pgmCd: '201900',
        natDs: '',
        natNo: 0,
      },

      detailInfo: {
        natNo: 0,
        natDs: '',
        tl: '',
        rngDs: 'ALL',
        files: 0,
        natStDate: '',
        natEnDate: '',
        cts: '',
        rgprNm: '',
        rgsDttm: '',
        flNo: 0,
        inqCnt: 0,
        tskDs: '',
        cfmDs: '',
        cfmDsNm: '',
        prcDs: '',
        prcDsNm: '',
        svLctNo: 0,
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,

      initialValue: '',
      editorKey: 0,

      style: {
        'white-space': 'pre-wrap',
        'word-wrap': 'break-word',
      },
    };
  },
  mounted() {
    // console.log('natDs', this.natDs);
    // console.log('natNo', this.natNo);
    this.onSearch();
  },
  methods: {
    async onSearch() {
      this.keyInfo.natDs = this.natDs;
      this.keyInfo.natNo = this.natNo;

      let response = await axios.post('/boardCust/selectBoardCustDetailInfo', this.keyInfo);
      for (let key in this.detailInfo) {
        if (response.data.boardInfo.hasOwnProperty(key)) {
          this.detailInfo[key] = response.data.boardInfo[key];
        }
      }

      this.initialValue = this.detailInfo['cts'];
      this.editorKey += 1;
    },
    file_save_complete() {},
    file_save_error() {},
    file_delete_complete() {},
    file_delete_error() {},
  },
  computed: {
    natDsName() {
      let value;
      switch (this.natDs) {
        case 'NOTI':
          value = '공지사항';
          break;
        case 'FAQ':
          value = 'FAQ';
          break;
        case 'FILE':
          value = '자료실';
          break;

        default:
          value = '공지사항';
          break;
      }

      return value;
    },
  },
};
</script>

<style></style>

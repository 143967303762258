<template>
  <div class='tab_n_wrap'>
  <nav class="tab_nav">
      <li >
        <a href="#HOME" class="btn_home_on" @click.prevent.self @click="goHome">
          <span class="blind">닫기</span>
        </a>
      </li>
      <li v-for="url in urls" :key="url.menuName">
        <a
          :href="'#' + url.url"
          :data-url="url.url"
          @click.prevent.self
          @click="routePush(url, $event)"
        >
          {{
          url.menuName
          }}
        </a>
        <a href="#close" class="btn_close_off" @click.prevent.self @click="closeMdi(url.url)">
          <span class="blind">닫기</span>
        </a>
      </li>
      <li class='btn_close_all'>
      <a
        href="#"
        @click.prevent.self
        @click="allCloseMdi"
      ><span class="blind">X</span></a>
      </li>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: 'iui-mdi',
  created: function() {},
  computed: {
    ...mapGetters('mdi', {
      urls: 'urls',
      history: 'history',
    }),
  },
  mounted: function() {
    this.$el.querySelector('[href*="#HOME"]').click();
  },
  methods: {
    goHome: function(e) {
      this.setCmmBtns([]);
      for (let sibling of Array.from(document.querySelector('.tab_nav').querySelectorAll('li'))) {
        sibling.classList.remove('select');
      }
      e.target.parentNode.classList.add('select');
      e.target.parentNode.querySelector('[href="#HOME"]').classList.remove('btn_home_off');
      e.target.parentNode.querySelector('[href="#HOME"]').classList.add('btn_home_on');
      this.$router.replace({path: '/Home', params: {}});
    },
    /** 라우터 이동 */
    routePush: function(urls, e) {
      const me = this;
      for (let sibling of Array.from(document.querySelector('.tab_nav').querySelectorAll('li'))) {
        sibling.classList.remove('select');
        if (sibling.querySelector('[href="#HOME"]')) {
          sibling.querySelector('[href="#HOME"]').classList.remove('btn_home_on');
          sibling.querySelector('[href="#HOME"]').classList.add('btn_home_off');
        }

        if (sibling.querySelector('[href="#close"]')) {
          sibling.querySelector('[href="#close"]').classList.remove('btn_close_on');
          sibling.querySelector('[href="#close"]').classList.add('btn_close_off');
        }

        // sibling.querySelector('a').classList.remove('active');
      }
      e.target.parentNode.classList.add('select');
      e.target.parentNode.querySelector('[href="#close"]').classList.add('btn_close_on');
      const historyLength = me.history[urls.url].length;
      if (historyLength) {
        const url = me.history[urls.url][historyLength - 1].url;
        const name = me.history[urls.url][historyLength - 1].name;
        me.$router.replace({name, path: url, params: {research: true}}, function() {
          me.$store.dispatch('mdi/setUrl', urls);
        });
      } else {
        // 2021.03.02 선영욱 추가
        var params = {};
        if (urls.params !== undefined) {
          params = urls.params;
        }

        // 2021.03.02 선영욱 수정
        // me.$router.replace({path: urls.url, params: {}}, function() {
        me.$router.replace({path: urls.url, params: params}, function() {
          me.$store.dispatch('mdi/setUrl', urls);
        });

        // let routeData = this.$router.resolve({name: urls.name});
        // console.log(routeData)
        // window.open(routeData.href, '_blank');
      }
    },
    closeMdi(url) {
      this.$store.commit('removePgmCd');
      this.$store.dispatch('mdi/removeMdi', url);
      if (this.$store.getters.modules.indexOf(url.replace(/\//, '')) != -1) {
        this.$store.commit('removeModules', url.replace(/\//, ''));
      }

      if (this.$store.getters['loadVue'][url]) {
        this.$store.getters['loadVue'][url].$destroy();
        // this.$store.commit('removeLoadVue', url);
      }

      if (this.$store.state[url] !== undefined) {
        this.$store.unregisterModule(url);
      }
    },
    allCloseMdi() {
      let urls = [];
      Array.from(this.$store.getters['mdi/urls']).forEach(e => {
        urls.push(e.url);
      });

      this.$store.commit('removeAllPgmCd');
      this.$store.dispatch('mdi/allRemoveMdi');
      this.$store.commit('allRemoveModules');
      urls.forEach(url => {
        if (this.$store.getters['loadVue'][url]) {
          this.$store.getters['loadVue'][url].$destroy();
        }

        if (this.$store.state[url] !== undefined) {
          this.$store.unregisterModule(url);
        }
      });
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer" v-if="isOzReport">
            <div style="width:100%; height:100%" id="iOzViewer" v-if="isViewPdf"><!--20221123 ITSR62079-->
              <iframe
                id="pdfViewer"
                name="pdfViewer"
                :src="pdfViewerConfig.viewerUrl"
                title="PDF Viewer"
                style="width: 100%; height: 100%; border: 0px solid red; overflow: hidden;"
              ></iframe>
            </div>
            <div style="width:100%; height:100%" v-else>
               <iframe
                :src="urlParam"
                id="ozFrame"
                name="ozFrame"
                style="width:100%; height:100%; border:0px solid red;"
                scrolling="no"
                title="Iframe Example"
              ></iframe>
            </div>
          </div>
          <div style="width:100%; height:100%" v-else>
            <pmis-content-box no-border-box>
              <template #title-right>
                <iui-button @click="($refs.pdf.length ? $refs.pdf[0] : $refs.pdf).print()">출력</iui-button>
                <iui-button @click="downloadPdf()">다운로드</iui-button>
              </template>
              <iui-container type="table" theme="bullet" style="height:100%">
                <i-row>
                  <i-col>
                    <div style="width: 100%">
                      <div
                        v-if="loadedRatio > 0 && loadedRatio < 1"
                        style="background-color: green; color: white; text-align: center"
                        :style="{width: loadedRatio * 100 + '%'}"
                      >
                        {{ Math.floor(loadedRatio * 100) }}%
                      </div>
                      <pdf
                        ref="pdf"
                        v-for="i in numPages"
                        :key="i"
                        :src="pdfUrl"
                        :page="i"
                        @error="error"
                        @progress="loadedRatio = $event"
                        style="display:inline-block;width:calc(100% - 20px);margin:10px;border:1px solid #a0a0a0;box-shadow:5px 5px 10px #a0a0a0"
                      ></pdf>
                    </div>
                  </i-col>
                </i-row>
              </iui-container>
            </pmis-content-box>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowNotAccept" height="50px">
        <i-col-header>미동의 사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            :value="ctrtAgre.rmCn"
            :enable="true"
            required
            @change="ctrtAgre.rmCn = $event.target.value"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import pdf from 'vue-pdf';
export default {
  props: {
    ctrtNo: {
      type: String,
      default: undefined,
    },
    ctrtOdr: {
      type: String,
      default: undefined,
    },
    ctrtDivCd: {
      type: String,
      default: undefined,
    },
    docCat1: {
      type: String,
      default: undefined,
    },
    OZParam: {
      type: Object,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    pdf,
  },
  beforeCreate() {},
  created() {
    this.addEvent({name: 'OffcialDocumentPopup_Accept', func: this.onPopupConfirmOffcialDocumentAccept});
    this.addEvent({name: 'OffcialDocumentPopup_NotAccept', func: this.onPopupConfirmOffcialDocumentNotAccept});

    var me = this;
    //OZ Viewer 에서 message 를 호출.
    window.addEventListener('message', me.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: `${process.env.VUE_APP_OZ_VIEWER_URL}`,

      isNotAccept: false,

      ctrtAgre: {
        agreSn: '0',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        agreSttusCd: '',
        processResultCd: '',
        rmCn: '',
        visitDe: '',
        cstmrNm: '',
        cstmrTelno: '',
      },

      contractInfo: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        ctrtSttusCd: '',
        histSn: '',
        ctrtOrginlCn: '',
        ctrtOrginlHashcd: '',
        ctrtOrginlFileNo: '',
        dwldUrl : '', //20221123 ITSR62079
      },

      signerInfoVo: {
        tradeNo: '',
        tradeSuffix: '',
        personId: '',
        businessNo: '',
        mphone: '',
        userNm: '',
        canSign: false,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: 0,
        reqstdocDivCd: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        signDt: '',
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
        infoProvdAgreYn: '',
        //ITSR62600
        tradeType:'',
        tradeName:'',
      },

      isVerifyPASS: false,

      // pdfUrl: `${process.env.VUE_APP_BASE_URL}/vc/download`,
      pdfUrl: '',
      page: 1,
      numPages: 0,
      loadedRatio: 0,
      rotate: 0,

      //20221123 ITSR62079
      pdfViewerConfig: {
        src: './ext/pdfjs/v2.14.305-legacy/web/viewer.html',
        url: undefined,
        viewerUrl:'',
      },
    };
  },
  mounted() {
    if (!this.paramObj.otisPage) {
      this.$secuKit.init();
      this.$secuKit.checkReady();
      console.log('checkReady = ' + this.$secuKit.secuKitReady);
    }
    this.listBoxLoading = true;
    this.onSearch();
  },
  methods: {
    async viewPdf() {  //20221123 ITSR62079
      const hostUrl = new URL(window.location.href);
      let origin = '';

      if (hostUrl.host.indexOf('localhost') !== -1) {
        origin = 'http://localhost:8101';
      }
      if (hostUrl.host.indexOf('127.0.0.1') !== -1) {
        origin = 'http://127.0.0.1:8101';
      }

      const downloadUrl = new URL(this.contractInfo.dwldUrl);
      const url = `${origin}${downloadUrl.pathname}${downloadUrl.search}`;

      console.log('url', url);
      this.pdfViewerConfig.url = url;
    },
    pageUp() {
      if (this.page <= 1) {
        this.page = 1;
      } else {
        this.page = this.page - 1;
      }
    },
    pageDown() {
      if (this.page >= this.numPages) {
        this.page = this.numPages;
      } else {
        this.page = this.page + 1;
      }
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.paramObj.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.paramObj.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
        this.listBoxLoading = false;
      }
    },
    async downloadPdf() {
      axios.downloadPdf(this.pdfUrlSaved);
    },
    async onSearch() {
      if (this.paramObj.docCat1 == '000') {
        this.listBoxLoading = true;
        var url = `${process.env.VUE_APP_BASE_URL}`;
        url += '/ocontract/downloadPdf';
        url += '?ctrtNo=' + this.paramObj.ctrtNo;
        url += '&ctrtOdr=' + this.paramObj.ctrtOdr;
        url += '&ctrtDivCd=' + this.paramObj.ctrtDivCd;

        this.pdfUrlSaved = url;

        var loadingTask = pdf.createLoadingTask({
          url: url,
          withCredentials: false,
          httpHeaders: {
            Authorization: axios.defaults.headers.common.Authorization,
          },
        });

        this.pdfUrl = loadingTask;
        this.listBoxLoading = false;
        // 20210326
        this.pdfUrl.promise.then(pdf => {
          this.pdfDownloader = pdf;
          this.numPages = pdf.numPages;
        });
      }

      let param = {
        ctrtNo: this.paramObj.ctrtNo,
        ctrtOdr: this.paramObj.ctrtOdr,
        ctrtDivCd: this.paramObj.ctrtDivCd,
      };
      let response = await axios.post('/ocontract/selectContractInfo', param);

      console.log(response);
      this.contractInfo = response.data;
  
      this.ctrtAgre.agreSn = this.contractInfo.agreSn;
      this.ctrtAgre.ctrtNo = this.contractInfo.ctrtNo;
      this.ctrtAgre.ctrtOdr = this.contractInfo.ctrtOdr;
      this.ctrtAgre.ctrtDivCd = this.contractInfo.ctrtDivCd;

      console.log(this.ctrtAgre);
      //20221123 ITSR62079
      if(this.isViewPdf){
        let response_link = await axios.post('/urlLink/contractInfo', param);
        console.log("response_link",response_link);
        this.contractInfo.dwldUrl = response_link.data.dwldUrl;
        this.viewPdf();
        this.pdfViewerConfig.viewerUrl = this.pdfViewerConfig.src+'?file=' + encodeURIComponent(this.pdfViewerConfig.url);
        this.listBoxLoading = false;
      }else{

        let param2 = {
          ctrtNo: this.paramObj.ctrtNo,
          ctrtOdr: this.paramObj.ctrtOdr,
          ctrtDivCd: this.paramObj.ctrtDivCd,
        };
        let response2 = await axios.post('/ocontract/selectSignerInfo', param2);

        console.log(response2);
        this.signerInfoVo = response2.data;

        this.signInfo.ctrtNo = this.contractInfo.ctrtNo;
        this.signInfo.ctrtOdr = this.contractInfo.ctrtOdr;
        this.signInfo.ctrtDivCd = this.contractInfo.ctrtDivCd;
        this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
        this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
        this.signInfo.canSign = this.signerInfoVo.canSign;
        this.signInfo.corporate = this.signerInfoVo.corporate;
        this.signInfo.customer = this.signerInfoVo.customer;
        this.signInfo.buyagnt = this.signerInfoVo.buyagnt;
        this.signInfo.personal = this.signerInfoVo.personal;
        this.signInfo.personId = this.signerInfoVo.personId;
        this.signInfo.businessNo = this.signerInfoVo.businessNo;

        this.signInfo.retrnResnCn = '';
        this.signInfo.agentIdntyImageCn = '';

        //ITSR62600
        this.signInfo.tradeType = (this.signerInfoVo.customer)?"CUST":"CCPY";
        this.signInfo.tradeName = this.signerInfoVo.tradeName;
      }
    },

    async onPopupConfirmOffcialDocumentAccept(callback) {
      if (this.isNotAccept) this.isNotAccept = false;

      // 정보제공 동의여부
      if (this.paramObj.docCat1 != '000') {
        //OZ Viewer 의 입력값들를 리턴받음.
        var ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
        console.log('getOzInputData input ', ozVal);
        if (ozVal == null || ozVal == '') {
          return;
        }
        this.signInfo.infoProvdAgreYn = ozVal['infoProvdAgreYn'];
      }

      if (!(await this.$confirm('동의 하시겠습니까?'))) return;

      // 대리인은 웹 로그인이 불가능하기 때문에 고객이 동의하면 서명 없이 동의 처리 완료.

      let param = {
        ...this.signInfo,
        pgmCd: this.userInfo.pgmCd,
        chngPgmId: 'OfficialDocumentViewer',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/ocontract/offcialDocumentAccept', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('동의가 완료되었습니다.');
          callback({action: 'onPopupConfirmOffcialDocumentAccept'});
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('동의 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },

    async onPopupConfirmOffcialDocumentNotAccept(callback) {
      if (!this.isNotAccept) this.isNotAccept = true;

      if (!this.ctrtAgre.rmCn.trim()) {
        this.$alert('미동의 사유를 입력해야합니다.');
        return;
      }

      if (!(await this.$confirm('미동의 하시겠습니까?'))) return;

      let param = {
        ...this.ctrtAgre,
        pgmCd: this.userInfo.pgmCd,
        chngPgmId: 'OfficialDocumentViewer',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/ocontract/offcialDocumentNotAccept', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('미동의 완료되었습니다.');
          callback({action: 'onPopupConfirmOffcialDocumentNotAccept'});
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('미동의 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },

    error(err) {
      console.log(err);
    },
  },
  computed: {
    isShowNotAccept() {
      return this.isNotAccept;
    },
    isOzReport() {
      return this.paramObj.docCat1 != '000';
    },
    isViewPdf() { //20221123 ITSR62079
      console.log("this.contractInfo.ctrtSttusCd",this.contractInfo.ctrtSttusCd);
      console.log("this.paramObj.ctrtSttusCd",this.paramObj.ctrtSttusCd);
      const _paramObj = (typeof (this.paramObj.ctrtSttusCd) == 'undefined' || this.paramObj.ctrtSttusCd=='')?this.contractInfo.ctrtSttusCd:this.paramObj.ctrtSttusCd;
      return ['30', '40', '41'].includes(_paramObj);
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <template v-slot:title>
      수리공사 계약
    </template>
    <template v-slot:title-left>
      <font style="color:#FF6666; font-weight: bold;">(더 많은 정보는 로그인 후 사용 바랍니다.)</font>
    </template>
    <template #title-right>
      <iui-button color="blue" v-show="isCanSign" @click="onSign">서명</iui-button>
      <iui-button color="blue" v-show="isCanReject" @click="onReject">반려</iui-button>
      <iui-button color="blue" v-show="isCanRejectByCust" @click="onRejectByCust">반려</iui-button>
    </template>

    <iui-container type="table" :theme="theme" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer" v-if="isOzReport">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            ></iframe>
          </div>
          <div style="width:100%; height:100%" v-else>
            <pmis-content-box no-border-box>
              <template #title-right>
                <iui-button @click="($refs.pdf.length ? $refs.pdf[0] : $refs.pdf).print()">출력</iui-button>
                <iui-button @click="downloadPdf()">다운로드</iui-button>
              </template>
              <div style="height:calc(100vh - 130px); width:100%; overflow:scroll;">
                <pdf
                  ref="pdf"
                  v-for="i in numPages"
                  :key="i"
                  :src="pdfUrl"
                  :page="i"
                  @error="error"
                  @progress="loadedRatio = $event"
                  style="display:inline-block;width:calc(100% - 20px);margin:10px;border:1px solid #a0a0a0;box-shadow:5px 5px 10px #a0a0a0"
                />
              </div>
            </pmis-content-box>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowReject">
        <i-col-header>반려 사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            :value="signInfo.retrnResnCn"
            :enable="true"
            height="40px"
            required
            @change="signInfo.retrnResnCn = $event.target.value"
          />
        </i-col>
      </i-row>
      <i-row v-show="isShowSign">
        <i-col-header>서명방법</i-col-header>
        <i-col>
          <iui-select-old
            name="signMthCd"
            :items="arrSignMthCd"
            :value="signInfo.signMthCd"
            :enable="true"
            required
            @change="onSetSignMthCd"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import pdf from 'vue-pdf';
import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 't-contract-view',
  components: {
    pdf,
  },
  created() {
    this.addEvent({name: 'TContract_Search', func: this.onSearch});
    console.log('addEvent', 'TContract_Search');
    window.addEventListener('message', this.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: '',
      OZParam: {},

      arrSignMthCd: [
        {text: '선택', value: ''},
        // {text: '공동인증서', value: '10'},
        // {text: '디지털서명', value: '30'},
      ],

      isReject: false,
      isSign: false,

      signerInfoVo: {
        tradeNo: '',
        tradeSuffix: '',
        personId: '',
        businessNo: '',
        mphone: '',
        userNm: '',
        canSign: false,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: 0,
        reqstdocDivCd: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        signDt: '',
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        buyagnt: false,
        personal: false,
        infoProvdAgreYn: '',

        //ITSR62600
        tradeType:'',
        tradeName:'',
      },

      isVerifyPASS: false,

      pdfUrl: '',
      page: 1,
      numPages: 0,
      loadedRatio: 0,
      rotate: 0,

      pdfUrlSaved: '',
      pdfDownloader: null,
    };
  },
  mounted() {
    this.$secuKit.init();
    this.$secuKit.checkReady();
    console.log('checkReady = ' + this.$secuKit.secuKitReady);
  },
  methods: {
    ...mapMutations('TContractStore', {
      setContractInfo: 'setContractInfo',
    }),
    pageUp() {
      if (this.page <= 1) {
        this.page = 1;
      } else {
        this.page = this.page - 1;
      }
    },
    pageDown() {
      if (this.page >= this.numPages) {
        this.page = this.numPages;
      } else {
        this.page = this.page + 1;
      }
    },
    onSetSignMthCd(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;

      this.signInfo[target] = value;

      if (this.contractInfo.docCat1 != '000') {
        if (value == '30') {
          // 디지털 서명
          this.onViewReport(true, 'Y');
        } else {
          // 고객은 정보제공동의여부를 선택할 수 있도록 리포트의 signable을 Y로 넘긴다.
          if (this.isCanSign && eval(this.signerInfoVo.customer) == true) {
            this.onViewReport(true, 'Y');
          } else {
            this.onViewReport(true, 'N');
          }
        }
      }

      this.signInfo[target] = value;
    },
    onViewReport(isReload, signable) {
      // var signable    = (eval(this.signerInfoVo.canSign) == true ? 'Y' : 'N')
      var signerSeCd = this.decParams.isBuyagnt ? '20' : eval(this.signerInfoVo.customer) == true ? '10' : '30';
      var tradeNo = eval(this.signerInfoVo.canSign) == true ? this.signerInfoVo.tradeNo : '';
      var signMthCd = this.signInfo.signMthCd;

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);
      console.log('signMthCd', signMthCd);

      let param = {
        checkContNo: this.contractInfo.ctrtNo,
        checkSeq: this.contractInfo.ctrtOdr,
        manageCd: this.contractInfo.docCat1,
      };

      var uri = '/ozrpt/selectOzTContract';
      // 발주서 리포트 코드 값이 바뀌면 수정해야 함.
      if (
        this.contractInfo.docCat1 == 'C20' ||
        this.contractInfo.docCat1 == 'C50' ||
        this.contractInfo.docCat1 == 'C80'
      ) {
        uri = '/ozrpt/selectOzTCtrtOrder';
      }
      axios
        .post(uri, param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign({}, response.data.jsonData);

          this.OZParam.reportname = response.data.reportPathName;
          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
            signMthCd: signMthCd,
          };

          this.urlParam = process.env.VUE_APP_OZ_VIEWER_URL;

          if (isReload) {
            //OZ Viewer 에서 message 를 호출할 Event 등록.
            window.addEventListener('message', this.reqSetOzParam, false);

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;

            if (isIE) {
              document.getElementById('ozFrame').src = process.env.VUE_APP_OZ_VIEWER_URL;
            } else {
              //OZ Reload
              document.getElementById('ozFrame').contentWindow.location.reload(true);
            }
          }
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    async downloadPdf() {
      axios.downloadPdf(this.pdfUrlSaved);
    },
    async onSearch(isReload) {
      console.log('TContract_Search', 'onSearch');
      console.log('decParams.ctrtNo', this.decParams.ctrtNo);
      console.log('decParams.ctrtOdr', this.decParams.ctrtOdr);
      console.log('decParams.ctrtDivCd', this.decParams.ctrtDivCd);
      console.log('decParams.tradeNo', this.decParams.tradeNo);
      console.log('decParams.tradeSfx', this.decParams.tradeSfx);

      this.isReject = false;
      this.isSign = false;

      let param = {
        ctrtNo: this.decParams.ctrtNo,
        ctrtOdr: this.decParams.ctrtOdr,
        ctrtDivCd: this.decParams.ctrtDivCd,
        tradeNo: this.decParams.tradeNo,
      };

      console.log('param', param);
      if (param.ctrtNo === undefined || param.ctrtNo == '') return;

      try {
        this.listBoxLoading = true;
        let response = await axios.post('/urlLink/selectTContractInfo', param);
        this.listBoxLoading = false;
        console.log(response.data);

        this.setContractInfo(response.data.contractInfo);

        if (this.contractInfo.docCat1 == '000') {
          var url = `${process.env.VUE_APP_BASE_URL}`;
          url += '/urlLink/tcDownloadPdf';
          url += '?ctrtNo=' + this.decParams.ctrtNo;
          url += '&ctrtOdr=' + this.decParams.ctrtOdr;
          url += '&ctrtDivCd=' + this.decParams.ctrtDivCd;
          url += '&tradeNo=' + this.decParams.tradeNo;
          url += '&tradeSfx=' + this.decParams.tradeSfx;

          var loadingTask = pdf.createLoadingTask({
            url: url,
            withCredentials: false,
          });

          this.pdfUrl = loadingTask;
          this.pdfUrlSaved = url;

          this.pdfUrl.promise.then(pdf => {
            this.pdfDownloader = pdf;
            this.numPages = pdf.numPages;
          });
        }

        let param2 = {
          ctrtNo: this.decParams.ctrtNo,
          ctrtOdr: this.decParams.ctrtOdr,
          ctrtDivCd: this.decParams.ctrtDivCd,
          tradeNo: this.decParams.tradeNo,
          tradeSfx: this.decParams.tradeSfx,
        };
        let response2 = await axios.post('/urlLink/selectTContractSignerInfo', param2);

        console.log(response2);
        this.signerInfoVo = response2.data;

        this.signInfo.ctrtNo = this.contractInfo.ctrtNo;
        this.signInfo.ctrtOdr = this.contractInfo.ctrtOdr;
        this.signInfo.ctrtDivCd = this.contractInfo.ctrtDivCd;
        this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
        this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
        this.signInfo.canSign = this.signerInfoVo.canSign;
        this.signInfo.corporate = this.signerInfoVo.corporate;
        this.signInfo.customer = this.signerInfoVo.customer;
        this.signInfo.buyagnt = this.signerInfoVo.buyagnt;
        this.signInfo.personal = this.signerInfoVo.personal;
        this.signInfo.personId = this.signerInfoVo.personId;
        this.signInfo.businessNo = this.signerInfoVo.businessNo;
        this.signInfo.signerSeCd = this.decParams.isBuyagnt ? '20' : '10';

        //ITSR62600
        this.signInfo.tradeType = (this.signerInfoVo.customer)?"CUST":"CCPY";
        this.signInfo.tradeName = this.signerInfoVo.tradeName;

        this.signInfo.retrnResnCn = '';
        this.signInfo.agentIdntyImageCn = '';

        // 대리인이 아니면 공동인증서 서명이 가능.
        if (eval(this.signInfo.buyagnt) == false) {
          this.arrSignMthCd.push({text: '공동인증서', value: '10'});
        }

        // 비표준 계약이 아닌 경우
        if (this.contractInfo.docCat1 != '000') {
          // 법인 사업자가 아니거나, 대리인이면 디지털 서명이 가능.
          if (eval(this.signInfo.corporate) == false || eval(this.signInfo.buyagnt) == true) {
            this.arrSignMthCd.push({text: '디지털서명', value: '30'});
          }
        }

        if (this.contractInfo.docCat1 == '000') {
          if (this.signInfo.signMthCd == '') {
            this.signInfo.signMthCd = this.arrSignMthCd[1].value;
          }
        } else {
          if (this.signInfo.signMthCd == '') {
            this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
          } else {
            this.onViewReport(isReload, 'N');
          }
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSign() {
      if (this.isReject) this.isReject = false;
      if (!this.isSign) this.isSign = true;

      if (!this.signInfo.signMthCd.trim()) {
        this.$alert('서명방법을 선택해야합니다.');
        return;
      }

      if (!this.decParams.isBuyagnt && eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
        this.$alert('법인 사업자는 서명방법을 디지털서명을 선택할 수 없습니다.');
        this.signInfo.signMthCd = '';
        return;
      }

      var ozVal = '';
      if (this.signInfo.signMthCd == '10') {
        // 정보제공 동의여부
        if (this.contractInfo.docCat1 != '000' && eval(this.signerInfoVo.customer) == true) {
          //OZ Viewer 의 입력값들를 리턴받음.
          ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
          console.log('getOzInputData input ', ozVal);
          if (ozVal == null || ozVal == '') {
            return;
          }
          this.signInfo.infoProvdAgreYn = ozVal['infoProvdAgreYn'];
        }

        console.log('this.contractInfo.ctrtOrginlHashcd', this.contractInfo.ctrtOrginlHashcd);

        let checkReady = this.$secuKit.secuKitReady;
        console.log('checkReady = ' + checkReady);

        if (!checkReady) {
          this.$alert('공동인증서 툴킷 로딩이 완료되지 못했습니다.');
          return;
        }

        if (!(await this.$confirm('전자서명 하시겠습니까?'))) return;

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.contractInfo.ctrtOrginlHashcd,
          certID: certID,
        };

        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.signInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

        console.log('PKCS7SignedMessage', this.signInfo.pkcs7SignedMessage);

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.signInfo.vidRandom = r3.vidRandom;
        this.signInfo.signerDn = r3.userDN;

        console.log('vidRandom', this.signInfo.vidRandom);
        console.log('signerDn', this.signInfo.signerDn);

        let data3 = {
          certType: 'SignCert',
          sourceString: r3.vidRandom,
          certID: certID,
        };

        const r4 = await this.$secuKit.invoke('generatePKCS7SignedData', data3);
        console.log(r4);

        this.signInfo.vidRandom = r4.generatePKCS7SignedData;

        if (this.signInfo.pkcs7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }

        let data4 = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.signInfo.pkcs7SignedMessage)),
        };

        const r5 = await this.$secuKit.verifyPKCS7SignedData(data4);
        console.log(r5);

        console.log('VerifyPKCS7SignedData', r5.verifyPKCS7SignedData);

        if (this.contractInfo.ctrtOrginlHashcd != r5.verifyPKCS7SignedData) {
          this.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }
      } else if (this.signInfo.signMthCd == '20') {
        // 모바일 인증서 서명은 웹에서는 해당 없음.
      } else if (this.signInfo.signMthCd == '30') {
        // 디지털서명은 PASS 인증을 수행하고 통과 해야 디지털 서명이 가능하다.
        if (!this.isVerifyPASS) {
          this.signInfo.passNo = 0;
          console.log('this.signerInfoVo.userNm', this.signerInfoVo.userNm);
          console.log('this.signerInfoVo.mphone', this.signerInfoVo.mphone);
          var passResult = await this.$PASSCertification(this.signerInfoVo.userNm, this.signerInfoVo.mphone);
          console.log(passResult);
          if (passResult.result) {
            this.isVerifyPASS = passResult.result;
            this.signInfo.passNo = passResult.data.passNo;
          } else {
            this.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다..');
            return;
          }
        }

        //OZ Viewer 의 입력값들를 리턴받음.
        ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
        console.log('OZ Viewer input ', ozVal);
        if (ozVal == null || ozVal == '') {
          // this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
          return;
        }

        // 정보제공 동의여부
        if (eval(this.signerInfoVo.customer) == true) {
          this.signInfo.infoProvdAgreYn = ozVal['infoProvdAgreYn'];
        }

        this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];

        if (this.signInfo.agentIdntyImageCn == undefined) {
          this.signInfo.agentIdntyImageCn = '';
          this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
          return;
        }

        if (!this.signInfo.agentIdntyImageCn.trim()) {
          this.$alert('계약서의 서명란에 디지털서명을 해야합니다.');
          return;
        }
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'LinkView',
      };

      if (!(await this.$confirm('서명을 완료 하시겠습니까?'))) return;

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/verifyTContractPKCS7SignedData', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('서명이 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('서명 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    async onReject() {
      if (!this.isReject) this.isReject = true;
      if (this.isSign) this.isSign = false;

      console.log(this.contractInfo);

      if (!this.signInfo.retrnResnCn.trim()) {
        this.$alert('반려 사유를 입력해야합니다.');
        return;
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'LinkView',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/rejectTContract', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('반려가 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('반려 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    async onRejectByCust() {
      if (!this.isReject) this.isReject = true;
      if (this.isSign) this.isSign = false;

      console.log(this.contractInfo);

      if (!this.signInfo.retrnResnCn.trim()) {
        this.$alert('반려 사유를 입력해야합니다.');
        return;
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'LinkView',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/rejectByCustTContract', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('반려가 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('반려 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    error(err) {
      console.log(err);
    },
  },
  computed: {
    ...mapGetters('LinkViewStore', {
      decParams: 'decParams',
    }),
    ...mapGetters('TContractStore', {
      contractInfo: 'contractInfo',
    }),
    isShowReject() {
      return this.isReject;
    },
    isShowSign() {
      return this.isSign;
    },
    isCanSign() {
      return (
        eval(this.signerInfoVo.canSign) == true &&
        (this.contractInfo.ctrtSttusCd == '10' || this.contractInfo.ctrtSttusCd == '20')
      );
    },
    isCanReject() {
      return (
        eval(this.signerInfoVo.customer) == false &&
        eval(this.signerInfoVo.canSign) == true &&
        (this.contractInfo.ctrtSttusCd == '10' || this.contractInfo.ctrtSttusCd == '20')
      );
    },
    isCanRejectByCust() {
      return (
        eval(this.signerInfoVo.customer) == true &&
        eval(this.signerInfoVo.canSign) == true &&
        (this.contractInfo.ctrtSttusCd == '10' || this.contractInfo.ctrtSttusCd == '20')
      );
    },
    isOzReport() {
      return this.contractInfo.docCat1 != '000';
    },
  },
};
</script>

<style></style>

const defaultTag = {
  container: 'table',
  row: 'tr',
  colHeader: 'th',
  col: 'td',
  spacer: 'td',
};

const tableTag = {
  container: 'table',
  row: 'tr',
  colHeader: 'th',
  col: 'td',
  spacer: 'td',
};

const cssTableTag = {
  container: 'div',
  row: 'div',
  colHeader: 'div',
  col: 'div',
  spacer: 'div',
};

const cssFlexTag = {
  container: 'div',
  row: 'div',
  colHeader: 'div',
  col: 'div',
  spacer: 'div',
};

const cssFlexTableTag = {
  container: 'div',
  row: 'div',
  colHeader: 'div',
  col: 'div',
  spacer: 'div',
};

const cssGridTag = {
  container: 'div',
  row: 'div',
  colHeader: 'div',
  col: 'div',
  spacer: 'div',
};

const TagMap = new Map();
TagMap.set('default', defaultTag);
TagMap.set('table', tableTag);
TagMap.set('css-table', cssTableTag);
TagMap.set('css-flex', cssFlexTag);
TagMap.set('css-flex-table', cssFlexTableTag);
TagMap.set('css-grid', cssGridTag);

export default TagMap;

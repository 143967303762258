<template>
  <pmis-content-box>
    <template v-slot:title>기본 데이터 복제</template>
    <template v-slot:title-right>
      <iui-button value="복제" @click="onCopy" />
    </template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['pgmCd']);
  },
  created() {
    this.addEvent({name: 'BaseInfo_search', func: this.onBeforeSearch});
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      focusKeyCode: '',
    };
  },
  mounted() {
    const OPT = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: true, // 1: 전체 편집 가능 (default)
        CanSort: false,
      },
      Cols: [
        {
          Header: '선택',
          Name: 'cbx',
          Type: 'Bool',
          Align: 'center',
          Width: 80,
          CanEdit: true,
        },
        {
          Header: '기본정보',
          Name: 'cmnDNm',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1,
          CanEdit: false,
        },
        {
          Header: '최초복제일시',
          Name: 'firstRgsDttm',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1,
          CanEdit: false,
        },
        {
          Header: '최종복제일시',
          Name: 'lastRgsDttm',
          Type: 'Text',
          Align: 'center',
          RelWidth: 1,
          CanEdit: false,
        },
        {Name: 'cmnDCd', Visible: false},
        {Name: 'pgmCd', Visible: false},
      ],
      Events: {
        onDataLoad: this.grid_onDataLoad,
      },
    };
    this.sheet = IBSheet.create({id: this.sheetId, el: this.elId, options: OPT});
    this.onSearch();
  },
  methods: {
    onBeforeSearch() {
      this.focusKeyCode = '';
      this.onSearch();
    },
    async onSearch() {
      let response = await axios.post('/baseCompanyRegist/selectBaseInfoList', {pgmCd: this.pgmCd});
      this.sheet.loadSearchData(response.data);
    },
    async onCopy() {
      let copyList = this.sheet.getSaveJson().data;
      if (copyList.length == 0) {
        this.$alert('복제할 대상이 선택되지 않았습니다.');
        return;
      }
      if (copyList.find(row => row.cmnDCd == '000002') !== undefined) {
        let response = await axios.post('/baseCompanyRegist/selectMenuListCount', {pgmCd: this.pgmCd});
        if (0 == response.data) {
          this.$alert('시스템 관리자를 복제하려는 경우에는 프로그램 메뉴가<br/>먼저 생성되어 있어야 합니다.');
          return;
        }
      }

      if (await this.$confirm('선택한 항목들을 복제하시겠습니까?')) {
        let response = await axios
          .post('/baseCompanyRegist/saveCopyBaseInfoList', {copyList})
          .catch(e => console.error('복제를 실패했습니다. :', e));

        if (response.status == 200) {
          this.focusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert('복제를 실패했습니다.');
        }
      }
    },
    grid_onDataLoad() {
      if (this.focusKeyCode) {
        let focusRow = this.sheet.getDataRows().find(row => row.cmnDCd == this.focusKeyCode);
        if (focusRow !== undefined) {
          setTimeout(() => this.sheet.focus(focusRow), 100);
        }
      }
    },
  },
};
</script>

<style></style>

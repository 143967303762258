import * as util from '@/components/Iui/util.js';
import {BUTTON_TYPE} from '@/components/Iui/const.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';
import TagMap from './tagMap.js';

export default {
  name: 'i-col',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  inject: ['containerType', 'containerHeaderWidth'],
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    colspan: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  render(h) {
    /**
     * header 처리를 위한 변수
     * header 속성이 있거나, 태그명이 i-col-header일 경우
     */
    let isHeader = this.header || this.$options._componentTag === 'i-col-header';

    let data = {};

    /**
     * tag 처리
     */
    let tag = TagMap.get(this.containerType);
    if (tag === undefined || tag === null) {
      tag = TagMap.get('default');
    }
    tag = tag[`col${isHeader ? 'Header' : ''}`];

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = 'col';

    if (isHeader) {
      defaultClassName += '-header';
    }
    classList.push({[defaultClassName]: true});
    classList.push({required: this.required});

    util.appendArrayToObject(data, 'class', classList);

    /**
     * style 처리
     */
    let styleList = [];

    if (this.containerType === 'css-flex') {
      if (this.width !== undefined) {
        styleList.push({flex: `0 0 ${this.width}`});
      } else if (this.flex !== undefined) {
        styleList.push({flex: this.flex});
      }
    }

    if (this.containerType === 'css-flex-table') {
      if (this.colspan !== undefined) {
        //   styleList.push({flex: '1'});
        // } else {
        styleList.push({flex: `${this.colspan}`});
      }

      if (this.width !== undefined) {
        styleList.push({flex: `0 0 ${this.width}`});
      } else {
        if (this.flex !== undefined) {
          styleList.push({flex: this.flex});
        } else {
          styleList.push({flex: '1'});
        }
      }
    }

    if (this.containerType === 'table') {
      if (this.width !== undefined) {
        styleList.push({width: `${this.width} !important`});
      } else if (isHeader && this.containerHeaderWidth !== undefined) {
        styleList.push({width: this.containerHeaderWidth});
        styleList.push({'min-width': this.containerHeaderWidth});
      }
      if (this.height !== undefined) {
        styleList.push({height: this.width});
      }
      // if (this.align !== undefined) {
      //   styleList.push({'text-align': this.align});
      // }
    }

    util.appendArrayToObject(data, 'style', styleList);

    /**
     * attrs 처리
     */
    let attrList = [];

    if (this.containerType === 'table') {
      if (this.rowspan !== undefined && this.rowspan > 1) {
        attrList.push({rowspan: this.rowspan});
      }
      if (this.colspan !== undefined && this.colspan > 1) {
        attrList.push({colspan: this.colspan});
      }
    }

    util.appendArrayToObject(data, 'attrs', attrList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    if (this.containerType === 'css-flex-table') {
      let justifyContent = '';
      let cellBox = h(
        'div',
        {class: {'border-box': true, 'cell-box': true}, style: {'justify-content': justifyContent}},
        [this.$slots.default]
      );
      childNodes.push(cellBox);
    } else if (this.containerType === 'table') {
      let justifyContent = '';

      switch (this.align) {
        case 'left':
          justifyContent = 'flex-start';
          break;
        case 'right':
          justifyContent = 'flex-end';
          break;
        default:
          justifyContent = this.align;
          break;
      }

      let cellBox = h('div', {class: {'cell-box': true}, style: {'justify-content': justifyContent}}, [
        this.$slots.default,
      ]);
      childNodes.push(cellBox);
    } else {
      childNodes.push(this.$slots.default);
    }

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
};

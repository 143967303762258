import {reactive, toRef, provide, inject, computed} from '@vue/composition-api';

export const BUTTON_TYPE = {
  XSMALL: {className: 'x-small', colorClassName: 'gray'},
  SMALL: {className: 'small', colorClassName: 'gray'},
  NORMAL: {className: 'normal', colorClassName: 'blue'},
  LARGE: {className: 'large', colorClassName: 'blue'},
  XLARGE: {className: 'x-large', colorClassName: 'blue'},
};

export const ButtonTypeProps = {
  // button size
  xSmall: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  normal: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  xLarge: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: undefined,
  },
};

export function useProvideDefaultButtonType(buttonType) {
  provide('defaultButtonType', buttonType);
}

export function useInjectDefaultButtonType(buttonType) {
  return inject('defaultButtonType', buttonType);
}

export function useButtonType(props, context) {
  const colorRef = toRef(props, 'color');
  const defaultButtonType = useInjectDefaultButtonType(BUTTON_TYPE.NORMAL);

  const state = reactive({
    type: BUTTON_TYPE.NORMAL,
    color: computed(() => {
      return colorRef.value === undefined ? state.type.colorClassName : colorRef.value;
    }),
  });

  if (props.xSmall || props.small || props.normal || props.large || props.xLarge) {
    if (props.xSmall) {
      state.type = BUTTON_TYPE.XSMALL;
    } else if (props.small) {
      state.type = BUTTON_TYPE.SMALL;
    } else if (props.normal) {
      state.type = BUTTON_TYPE.NORMAL;
    } else if (props.large) {
      state.type = BUTTON_TYPE.LARGE;
    } else if (props.xLarge) {
      state.type = BUTTON_TYPE.XLARGE;
    }
  } else if (defaultButtonType !== undefined) {
    state.type = defaultButtonType;
  }

  return {
    defaultButtonType,
    state,
  };
}

export default useButtonType;

<script>
import {reactive, toRef, computed, watch} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {PrefixProps, usePrefix} from '@/components/Iui/composables/usePrefix';
import {SuffixProps, useSuffix} from '@/components/Iui/composables/useSuffix';

// text
const useTextFormatter = () => {
  return {
    format(value) {
      value = value === undefined ? '' : value;
      return value;
    },
    unformat(value) {
      value = value === undefined ? '' : value;
      return value;
    },
  };
};

// number
const useNumberFormatter = () => {
  const intlFormatter = new Intl.NumberFormat();

  const format = value => {
    // console.group('NumberFormatter.format');
    // console.log('resolvedOptions:', intlFormatter.resolvedOptions());
    // console.log('formatToParts:', intlFormatter.formatToParts(value));
    // console.log('value:', value);

    // undefined 또는 null일 경우 by pass
    if (value === undefined || value === null) {
      // console.groupEnd();
      return value;
    }

    // Number로 형 변환
    let numberValue = Number(value);

    // console.log(
    //   'numberValue:',
    //   numberValue,
    //   ', Number.isNaN:',
    //   Number.isNaN(numberValue),
    //   ', isNaN:',
    //   isNaN(numberValue)
    // );

    // NaN일 경우 0으로 설정
    if (Number.isNaN(numberValue) || isNaN(numberValue)) {
      numberValue = 0;
    }

    // console.log('numberValue:', numberValue);

    let formattedValue = intlFormatter.format(value);

    // console.log('formattedValue:', formattedValue);

    // console.groupEnd();

    return formattedValue;
  };

  const unformat = value => {
    // console.group('NumberFormatter.unformat');
    // console.log('rawValue:', value);

    if (value === undefined || value === null) {
      // console.groupEnd();
      return value;
    }

    // console.log('value:', value);

    const unformattedValue = value.toString().replace(/,/g, '');
    // console.log('unformattedValue:', unformattedValue);

    let numberValue = Number(unformattedValue);

    // console.log(
    //   'numberValue:',
    //   numberValue,
    //   ', Number.isNaN:',
    //   Number.isNaN(numberValue),
    //   ', isNaN:',
    //   isNaN(numberValue)
    // );

    if (Number.isNaN(numberValue) || isNaN(numberValue)) {
      numberValue = 0;
    }

    // console.log('numberValue:', numberValue);

    // console.groupEnd();

    return numberValue;
  };

  return {
    format,
    unformat,
  };
};

export default {
  name: 'IuiTextbox',
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...PrefixProps,
    ...SuffixProps,

    modelValue: {
      // type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      type: undefined,
      default: undefined,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  setup(props, context) {
    const Base = useBase(props, context, 'iui-textbox');
    const Layout = useLayout(props, context, 'iui-textbox');
    const Label = useLabel(props, context, Base);
    const Prefix = usePrefix(props, context, Base);
    const Suffix = useSuffix(props, context, Base);

    const placeholderRef = toRef(props, 'placeholder');

    let formatter = undefined;

    switch (props.type) {
      case 'text':
        formatter = useTextFormatter();
        break;
      case 'number':
        formatter = useNumberFormatter();
        break;
      default:
        formatter = useTextFormatter();
        break;
    }

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        // console.log('watch props.modelValue');

        if (newValue !== oldValue) {
          // console.log('watch props.modelValue > set state.modelValue');

          state.modelValue = formatter.unformat(newValue);
        }
      }
    );

    const state = reactive({
      modelValue: formatter.unformat(props.modelValue),
      formattedModelValue: computed(() => formatter.format(state.modelValue)),
      placeholder: placeholderRef,

      id: computed(() => Base.getId('textbox')),
      class: computed(() => {
        return {
          textbox: true,
        };
      }),
      style: computed(() => {
        return {
          height: props.type === 'multi' ? '100%' : undefined,
        };
      }),
    });

    const log = (eventName, event) => {
      // console.group(eventName);
      // console.log(event);
      // console.log('document.activeElement:', document.activeElement);
      // console.log('context.refs.textbox:', context.refs.textbox);
      // console.log('document.activeElement === context.refs.textbox', document.activeElement === context.refs.textbox);
      // console.groupEnd();
    };

    // method
    const method = {
      textbox_focus(e) {
        log('textbox_focus', e);
      },
      textbox_blur(e) {
        log('textbox_blur', e);
      },
      textbox_input(e) {
        log('textbox_input', e);
        context.emit('update:model-value', formatter.unformat(e.target.value));
      },
      async textbox_change(e) {
        log('textbox_change', e);
        context.emit('update:model-value', formatter.unformat(e.target.value));
      },
      textbox_keydown(e) {
        log('textbox_keydown', e);
      },
      textbox_keypress(e) {
        log('textbox_keypress', e);
      },
      textbox_keyup(e) {
        context.emit('keyup', e);
      },
    };

    return {
      Base,
      Layout,
      Label,
      Prefix,
      Suffix,
      state,
      ...method,
    };
  },
};
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style" :data-type="type">
    <label
      v-if="Label.state.has"
      :id="Label.state.id"
      ref="label"
      :for="state.id"
      class="label"
      :style="Label.state.style"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <label
      v-if="Prefix.state.has"
      :id="Prefix.state.id"
      ref="prefix"
      :for="state.id"
      class="prefix"
      :style="Prefix.state.style"
    >
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </label>

    <input
      v-if="type != 'multi'"
      :id="state.id"
      ref="textbox"
      type="text"
      :class="state.class"
      :style="state.style"
      :readonly="Base.state.readonly"
      :disabled="!Base.state.enable"
      :value="state.formattedModelValue"
      :placeholder="state.placeholder"
      @focus="textbox_focus"
      @blur="textbox_blur"
      @change="textbox_change"
      @input="textbox_input"
      @keydown="textbox_keydown"
      @keypress="textbox_keypress"
      @keyup="textbox_keyup"
    />

    <textarea
      v-if="type == 'multi'"
      :id="state.id"
      ref="textbox"
      type="text"
      :class="state.class"
      :style="state.style"
      :readonly="Base.state.readonly"
      :disabled="!Base.state.enable"
      :value="state.formattedModelValue"
      :placeholder="state.placeholder"
      @focus="textbox_focus"
      @blur="textbox_blur"
      @change="textbox_change"
      @input="textbox_input"
      @keydown="textbox_keydown"
      @keypress="textbox_keypress"
      @keyup="textbox_keyup"
    />

    <!-- <div style="flex: 0 0 300px">
      {{ state }}
    </div> -->

    <label
      v-if="Suffix.state.has"
      :id="Suffix.state.id"
      ref="suffix"
      :for="state.id"
      class="suffix"
      :style="Suffix.state.style"
    >
      <slot name="suffix">
        {{ suffix }}
      </slot>
    </label>
  </div>
</template>

<style></style>

<template>
  <iui-container type="css-flex" theme="bullet">
    <div id="contents_dash_t">
      <div class="dash_head">
        <h2 class="dash_title">점검계약</h2>
        <div class="mb5" style="display:inline-flex;">
          <iui-datepicker-old
            name="yyyyMm"
            type="month"
            :value="searchParam.yyyyMm"
            :enable="true"
            @change="onSetValue($event, 'yyyyMm')"
            required
          />
          <iui-button @click="onSearch">조회</iui-button>
        </div>
      </div>

      <table class="dash01_list">
        <caption>
          보수계약 통계
        </caption>
        <colgroup>
          <col style="width:100px" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
        </colgroup>
        <tr>
          <th>구분</th>
          <td class="bg_grey">송부합계</td>
          <td class="bg_bgrey">송부</td>
          <td class="bg_grey">구성원 서명중</td>
          <td class="bg_bgrey">체결완료</td>
          <td class="bg_grey">구성원 반송</td>
          <td class="bg_bgrey">고객 반송</td>
          <td class="bg_grey">회수</td>
          <td class="bg_bgrey">전월까지<br />누적 미진행</td>
        </tr>
        <tr>
          <th>계약서</th>
          <td>{{ summary.oContractSumCnt }}</td>
          <td @click="goMenu_oContractSendCnt" style="cursor:pointer">{{ summary.oContractSendCnt }}</td>
          <td @click="goMenu_oContractSingingCnt" style="cursor:pointer">{{ summary.oContractSingingCnt }}</td>
          <td @click="goMenu_oContractCompleteCnt" style="cursor:pointer">{{ summary.oContractCompleteCnt }}</td>
          <td @click="goMenu_oContractRejectCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.oContractRejectCnt }}</span>
          </td>
          <td @click="goMenu_oContractRejectCustCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.oContractRejectCustCnt }}</span>
          </td>
          <td @click="goMenu_oContractRecoveryCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.oContractRecoveryCnt }}</span>
          </td>
          <td @click="goMenu_oContractPrevSumCnt" style="cursor:pointer">
            <span class="fc_re01 fwb">{{ summary.oContractPrevSumCnt }}</span>
          </td>
        </tr>
        <tr>
          <th>자동연장</th>
          <td>{{ summary.officialDocumentSumCnt }}</td>
          <td colspan="7">
            <ul class="type">
              <li class="head">공문 응답 유형</li>
              <li @click="goMenu_officialDocumentAgreeCnt" style="cursor:pointer; margin-left:2px;">
                동의 <span>{{ summary.officialDocumentAgreeCnt }}</span>
              </li>
              <li @click="goMenu_officialDocumentNotAgreeCnt" style="cursor:pointer; margin-left:2px;">
                미동의 <span>{{ summary.officialDocumentNotAgreeCnt }}</span>
              </li>
              <li @click="goMenu_officialDocumentNotAckCnt" style="cursor:pointer; margin-left:2px;">
                응답없음 <span>{{ summary.officialDocumentNotAckCnt }}</span>
              </li>
              <li @click="goMenu_officialDocumentSecondSendCnt" style="cursor:pointer; margin-left:2px;">
                2차 발송 <span>{{ summary.officialDocumentSecondSendCnt }}</span>
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <h2 class="dash_title">수리공사계약</h2>
      <table class="dash02_list">
        <caption>
          수리공사계약 통계
        </caption>
        <colgroup>
          <col style="width:100px" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
          <col style="width:12.25%" />
        </colgroup>
        <tr>
          <th>구분</th>
          <td class="bg_grey">송부합계</td>
          <td class="bg_bgrey">송부</td>
          <td class="bg_grey">구성원 서명중</td>
          <td class="bg_bgrey">체결완료</td>
          <td class="bg_grey">구성원 반송</td>
          <td class="bg_bgrey">고객 반송</td>
          <td class="bg_grey">회수</td>
          <td class="bg_bgrey">전월까지<br />누적 미진행</td>
        </tr>
        <tr>
          <th>계약서</th>
          <td>{{ summary.tContractSumCnt }}</td>
          <td @click="goMenu_tContractSendCnt" style="cursor:pointer">{{ summary.tContractSendCnt }}</td>
          <td @click="goMenu_tContractSingingCnt" style="cursor:pointer">{{ summary.tContractSingingCnt }}</td>
          <td @click="goMenu_tContractCompleteCnt" style="cursor:pointer">{{ summary.tContractCompleteCnt }}</td>
          <td @click="goMenu_tContractRejectCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tContractRejectCnt }}</span>
          </td>
          <td @click="goMenu_tContractRejectCustCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tContractRejectCustCnt }}</span>
          </td>
          <td @click="goMenu_tContractRecoveryCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tContractRecoveryCnt }}</span>
          </td>
          <td @click="goMenu_tContractPrevSumCnt" style="cursor:pointer">
            <span class="fc_re01 fwb">{{ summary.tContractPrevSumCnt }}</span>
          </td>
        </tr>
        <tr>
          <th>변경합의</th>
          <td>{{ summary.tChangeContractSumCnt }}</td>
          <td @click="goMenu_tChangeContractSendCnt" style="cursor:pointer">{{ summary.tChangeContractSendCnt }}</td>
          <td @click="goMenu_tChangeContractSingingCnt" style="cursor:pointer">
            {{ summary.tChangeContractSingingCnt }}
          </td>
          <td @click="goMenu_tChangeContractCompleteCnt" style="cursor:pointer">
            {{ summary.tChangeContractCompleteCnt }}
          </td>
          <td @click="goMenu_tChangeContractRejectCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tChangeContractRejectCnt }}</span>
          </td>
          <td @click="goMenu_tChangeContractRejectCustCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tChangeContractRejectCustCnt }}</span>
          </td>
          <td @click="goMenu_tChangeContractRecoveryCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.tChangeContractRecoveryCnt }}</span>
          </td>
          <td @click="goMenu_tChangeContractPrevSumCnt" style="cursor:pointer">
            <span class="fc_re01 fwb">{{ summary.tChangeContractPrevSumCnt }}</span>
          </td>
        </tr>
        <tr>
          <th>견적서</th>
          <td>{{ summary.estimateSumCnt }}</td>
          <td @click="goMenu_estimateSendCnt" style="cursor:pointer">{{ summary.estimateSendCnt }}</td>
          <td @click="goMenu_estimateSingingCnt" style="cursor:pointer">{{ summary.estimateSingingCnt }}</td>
          <td @click="goMenu_estimateCompleteCnt" style="cursor:pointer">{{ summary.estimateCompleteCnt }}</td>
          <td @click="goMenu_estimateRejectCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.estimateRejectCnt }}</span>
          </td>
          <td @click="goMenu_estimateRejectCustCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.estimateRejectCustCnt }}</span>
          </td>
          <td @click="goMenu_estimateRecoveryCnt" style="cursor:pointer">
            <span class="fc_bl01 fwb">{{ summary.estimateRecoveryCnt }}</span>
          </td>
          <td @click="goMenu_estimatePrevSumCnt" style="cursor:pointer">
            <span class="fc_re01 fwb">{{ summary.estimatePrevSumCnt }}</span>
          </td>
        </tr>
      </table>

      <div class="bottom_group">
        <div class="b_group_l">
          <!-- <h2 class="dash_title">신청서</h2>
					<table class="dash03_list">
					<caption>신청서 통계</caption>
					<colgroup>
					<col style="width:100px">
					<col style="width:33.333%">
					<col style="width:33.333%">
					<col style="width:33.333%">
					</colgroup>
					<tr>
					<th>구분</th>
					<td class="bg_dgreen">신청합계</td>
					<td class="bg_bgrey">신규</td>
					<td class="bg_dgreen">변경</td>
					</tr>
					<tr>
					<th>CMS</th>
            <td>{{ summary.selectCmsSumCnt }}</td>
            <td @click="goMenu_CmsNewCnt" style="cursor:pointer">{{ summary.selectCmsNewCnt }}</td>
            <td @click="goMenu_CmsChangeCnt" style="cursor:pointer">{{ summary.selectCmsChangeCnt }}</td>
					</tr>
					<tr>
					<th>View</th>
            <td>{{ summary.selectViewSumCnt }}</td>
            <td @click="goMenu_ViewNewCnt" style="cursor:pointer">{{ summary.selectViewNewCnt }}</td>
            <td @click="goMenu_ViewChangeCnt" style="cursor:pointer">{{ summary.selectViewChangeCnt }}</td>
					</tr>
					<tr>
					<th>Elite</th>
            <td>{{ summary.selectEliteSumCnt }}</td>
            <td @click="goMenu_EliteNewCnt" style="cursor:pointer">{{ summary.selectEliteNewCnt }}</td>
            <td @click="goMenu_EliteChangeCnt" style="cursor:pointer">{{ summary.selectEliteChangeCnt }}</td>
					</tr>
					</table> -->
          <pmis-content-box :loading="contentBoxLoading">
            <h2 class="dash_title">확인서</h2>
            <table class="dash03_list">
              <caption>
                확인서 통계
              </caption>
              <colgroup>
                <col style="width:100px" />
                <col style="width:100px" />
                <col style="width:25%" />
                <col style="width:25%" />
                <col style="width:25%" />
                <col style="width:25%" />
              </colgroup>
              <tr>
                <th>구분</th>
                <th>상세</th>
                <td class="bg_dgreen">작성중</td>
                <td class="bg_bgrey">회수</td>
                <td class="bg_dgreen">서명요청</td>
                <td class="bg_bgrey">완료</td>
              </tr>
              <tr>
                <th rowspan="3">부품</th>
                <th>무상</th>
                <td
                  @click="summary.selectPartFreeWriteCnt == 0 ? '' : goMenu_Parts('무상', '10')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartFreeWriteCnt }}
                </td>
                <td
                  @click="summary.selectPartFreeRecoveryCnt == 0 ? '' : goMenu_Parts('무상', '30')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartFreeRecoveryCnt }}
                </td>
                <td
                  @click="summary.selectPartFreeReqSignCnt == 0 ? '' : goMenu_Parts('무상', '20')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartFreeReqSignCnt }}
                </td>
                <td
                  @click="summary.selectPartFreeCompleteCnt == 0 ? '' : goMenu_Parts('무상', '50')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartFreeCompleteCnt }}
                </td>
              </tr>
              <tr>
                <th>유상</th>
                <td
                  @click="summary.selectPartPaidWriteCnt == 0 ? '' : goMenu_Parts('유상', '10')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartPaidWriteCnt }}
                </td>
                <td
                  @click="summary.selectPartPaidRecoveryCnt == 0 ? '' : goMenu_Parts('유상', '30')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartPaidRecoveryCnt }}
                </td>
                <td
                  @click="summary.selectPartPaidReqSignCnt == 0 ? '' : goMenu_Parts('유상', '20')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartPaidReqSignCnt }}
                </td>
                <td
                  @click="summary.selectPartPaidCompleteCnt == 0 ? '' : goMenu_Parts('유상', '50')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartPaidCompleteCnt }}
                </td>
              </tr>
              <tr>
                <th>클래임</th>
                <td
                  @click="summary.selectPartClaimWriteCnt == 0 ? '' : goMenu_Parts('CLAIM', '10')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartClaimWriteCnt }}
                </td>
                <td
                  @click="summary.selectPartClaimRecoveryCnt == 0 ? '' : goMenu_Parts('CLAIM', '30')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartClaimRecoveryCnt }}
                </td>
                <td
                  @click="summary.selectPartClaimReqSignCnt == 0 ? '' : goMenu_Parts('CLAIM', '20')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartClaimReqSignCnt }}
                </td>
                <td
                  @click="summary.selectPartClaimCompleteCnt == 0 ? '' : goMenu_Parts('CLAIM', '50')"
                  style="cursor:pointer"
                >
                  {{ summary.selectPartClaimCompleteCnt }}
                </td>
              </tr>
              <tr>
                <th rowspan="2">공사</th>
                <th>무상</th>
                <td
                  @click="summary.selectConstCmplFreeWriteCnt == 0 ? '' : goMenu_ConstCmpl('무상', '10')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplFreeWriteCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplFreeRecoveryCnt == 0 ? '' : goMenu_ConstCmpl('무상', '30')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplFreeRecoveryCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplFreeReqSignCnt == 0 ? '' : goMenu_ConstCmpl('무상', '20')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplFreeReqSignCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplFreeCompleteCnt == 0 ? '' : goMenu_ConstCmpl('무상', '50')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplFreeCompleteCnt }}
                </td>
              </tr>
              <tr>
                <th>유상</th>
                <td
                  @click="summary.selectConstCmplPaidWriteCnt == 0 ? '' : goMenu_ConstCmpl('유상', '10')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplPaidWriteCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplPaidRecoveryCnt == 0 ? '' : goMenu_ConstCmpl('유상', '30')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplPaidRecoveryCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplPaidReqSignCnt == 0 ? '' : goMenu_ConstCmpl('유상', '20')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplPaidReqSignCnt }}
                </td>
                <td
                  @click="summary.selectConstCmplPaidCompleteCnt == 0 ? '' : goMenu_ConstCmpl('유상', '50')"
                  style="cursor:pointer"
                >
                  {{ summary.selectConstCmplPaidCompleteCnt }}
                </td>
              </tr>
            </table>
          </pmis-content-box>
        </div>
        <!-- b_group_l end -->
        <div class="b_group_r">
          <a href="javascript://" class="notice_tit on" id="boardTab1" @click="boardTabChange(1)">공지사항</a
          ><a href="javascript://" class="notice_tit" id="boardTab2" @click="boardTabChange(2)">자료실</a>
          <div id="boardTabContent1">
            <ul class="notice_list">
              <li v-if="notiBoardList.length == 0"><a href="#">공지사항에 등록된 데이터가 없습니다.</a></li>
              <li v-else v-for="(item, index) in notiBoardList" :key="index">
                <a @click="onBoardView('NOTI', item.natNo)" style="cursor:pointer">{{ item.tl }}</a
                ><span>{{ item.rgsDttm }}</span>
              </li>
            </ul>
          </div>
          <div id="boardTabContent2" style="display:none;">
            <ul class="notice_list">
              <li v-if="fileBoardList.length == 0"><a href="#">자료실에 등록된 데이터가 없습니다.</a></li>
              <li v-else v-for="(item, index) in fileBoardList" :key="index">
                <a @click="onBoardView('NOTI', item.natNo)" style="cursor:pointer">{{ item.tl }}</a
                ><span>{{ item.rgsDttm }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- b_group_r end -->
      </div>
      <!-- bottom_group end -->
    </div>
    <!-- contents_dash_t end -->

    <iui-modal-old :name="boardViewModalId" :title="'게시판 조회'" :btns="modalBtns1" width="800px" height="600px">
      <BoardView :natDs="board.natDs" :natNo="board.natNo" />
    </iui-modal-old>
  </iui-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import BoardView from '../common/board/components/BoardViewModal.vue';

const MAX_URLS_LENGTH = 10;

export default {
  components: {
    BoardView,
  },
  data() {
    return {
      listBoxLoading: false,

      menus: [],

      searchParam: {
        yyyyMm: '',
        otisPage: false,
        customerPage: false,
      },

      boardTabIndex: 1,

      summary: {
        oContractSumCnt: 0,
        oContractSendCnt: 0,
        oContractSingingCnt: 0,
        oContractCompleteCnt: 0,
        oContractRejectCnt: 0,
        oContractRejectCustCnt: 0,
        oContractRecoveryCnt: 0,
        oContractPrevSumCnt: 0,
        officialDocumentSumCnt: 0,
        officialDocumentAgreeCnt: 0,
        officialDocumentNotAgreeCnt: 0,
        officialDocumentNotAckCnt: 0,
        officialDocumentSecondSendCnt: 0,
        tContractSumCnt: 0,
        tContractSendCnt: 0,
        tContractSingingCnt: 0,
        tContractCompleteCnt: 0,
        tContractRejectCnt: 0,
        tContractRejectCustCnt: 0,
        tContractRecoveryCnt: 0,
        tContractPrevSumCnt: 0,
        tChangeContractSumCnt: 0,
        tChangeContractSendCnt: 0,
        tChangeContractSingingCnt: 0,
        tChangeContractCompleteCnt: 0,
        tChangeContractRejectCnt: 0,
        tChangeContractRejectCustCnt: 0,
        tChangeContractRecoveryCnt: 0,
        tChangeContractPrevSumCnt: 0,
        estimateSumCnt: 0,
        estimateSendCnt: 0,
        estimateSingingCnt: 0,
        estimateCompleteCnt: 0,
        estimateRejectCnt: 0,
        estimateRejectCustCnt: 0,
        estimateRecoveryCnt: 0,
        estimatePrevSumCnt: 0,
        selectCmsSumCnt: 0,
        selectCmsNewCnt: 0,
        selectCmsChangeCnt: 0,
        selectViewSumCnt: 0,
        selectViewNewCnt: 0,
        selectViewChangeCnt: 0,
        selectEliteSumCnt: 0,
        selectEliteNewCnt: 0,
        selectEliteChangeCnt: 0,
        selectPartFreeWriteCnt: 0,
        selectPartFreeReqSignCnt: 0,
        selectPartFreeRecoveryCnt: 0,
        selectPartFreeSignedCnt: 0,
        selectPartFreeCompleteCnt: 0,
        selectPartPaidWriteCnt: 0,
        selectPartPaidReqSignCnt: 0,
        selectPartPaidRecoveryCnt: 0,
        selectPartPaidSignedCnt: 0,
        selectPartPaidCompleteCnt: 0,
        selectPartClaimWriteCnt: 0,
        selectPartClaimReqSignCnt: 0,
        selectPartClaimRecoveryCnt: 0,
        selectPartClaimSignedCnt: 0,
        selectPartClaimCompleteCnt: 0,
        selectConstCmplFreeWriteCnt: 0,
        selectConstCmplFreeReqSignCnt: 0,
        selectConstCmplFreeRecoveryCnt: 0,
        selectConstCmplFreeSignedCnt: 0,
        selectConstCmplFreeCompleteCnt: 0,
        selectConstCmplPaidWriteCnt: 0,
        selectConstCmplPaidReqSignCnt: 0,
        selectConstCmplPaidRecoveryCnt: 0,
        selectConstCmplPaidSignedCnt: 0,
        selectConstCmplPaidCompleteCnt: 0,
      },

      boardViewModalId: 'boardViewModal' + this._uid,
      modalBtns1: [],
      board: {
        natDs: '',
        natNo: 0,
      },
      notiBoardList: [],
      fileBoardList: [],
      contentBoxLoading: false, //로딩바 추가
    };
  },
  created() {},
  async mounted() {
    //lgksja 202200318 불필요한 호출 제거 아래 watch로 처리
    //this.menus = await this.getMenu();
    
    this.searchParam.yyyyMm = $_getCurrentDate().substring(0, 6);
    this.selectSummaryCnt();
    this.onSearchNotiBoardList();
    this.onSearchFileBoardList();
  },
  methods: {
    ...mapActions('mdi', ['addMDI']),
    ...mapActions('layout', ['sideToggle']),
    ...mapActions('menu', ['onSelectItems']),
    /* //lgksja 202200318 불필요한 호출 제거용
    async getMenu() {
      let param = {pgmCd: this.userInfo.pgmCd};      
      let response = await axios
        .post('/scdUser/selectMenuWithAuthList', param)
        .catch(e => console.error('ERR!!! : ', e));
      var tdata = response.data;

      // isSelected 값 설정 + 루트메뉴 구하기
      var finder = {};
      var row;
      var i = 0;
      for (i = 0; i < tdata.length; i++) {
        row = tdata[i];

        // 버튼 권한 넣기
        // start

        // eslint-disable-next-line
        var buttonId = row.buttonId + 'NNNNNNNNN';
        // eslint-disable-next-line
        var ynC = row.buttonId.charAt(1) == 'Y' ? 'Y' : 'N';
        var ynR = row.buttonId.charAt(2) == 'Y' ? 'Y' : 'N';
        var ynU = row.buttonId.charAt(3) == 'Y' ? 'Y' : 'N';
        var ynD = row.buttonId.charAt(4) == 'Y' ? 'Y' : 'N';
        var ynP = row.buttonId.charAt(5) == 'Y' ? 'Y' : 'N';
        var ynX = row.buttonId.charAt(6) == 'Y' ? 'Y' : 'N';

        row.vwYn = ynR;
        row.userVwYn = ynR;

        row.svYn = ynU;
        row.userSvYn = ynU;

        row.delYn = ynD;
        row.userDelYn = ynD;

        row.prnYn = ynP;
        row.userPrnYn = ynP;

        row.excelYn = ynX;
        row.userExcelYn = ynX;

        // end

        row.isSelected = false;
        var menuId = row.menuId;
        var parentMenuId = row.parentMenuId;
        finder[menuId] = {parentMenuId: parentMenuId, row: row};
      }
      for (i = 0; i < tdata.length; i++) {
        row = tdata[i];
        var frow = row;
        var whileVal = 1;
        do {
          if (frow == null) {
            row.rootMenuId = '';
            break;
          }
          if (frow.parentMenuId == 'MNU00000') {
            row.rootMenuId = frow.menuId;
            break;
          }

          frow = finder[frow.parentMenuId].row;
        } while (whileVal == 1);
      }
      return Array.from(tdata);
    },*/
    boardTabChange(n) {
      if (this.boardTabIndex == n) return;

      document.getElementById('boardTab' + this.boardTabIndex).classList.remove('on');
      document.getElementById('boardTab' + n).classList.add('on');

      document.getElementById('boardTabContent' + this.boardTabIndex).style.display = 'none';
      document.getElementById('boardTabContent' + n).style.display = 'block';

      this.boardTabIndex = n;
    },
    async goMenu_oContractSendCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractSingingCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractCompleteCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/complete') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractRejectCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '11';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractRejectCustCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '14';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractRecoveryCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '12';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContractPrevSumCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.delayYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_officialDocumentAgreeCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/offcialDocument/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.agreSttusCd = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OfficialDocumentList_Search'});
    },
    async goMenu_officialDocumentNotAgreeCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/offcialDocument/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.agreSttusCd = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OfficialDocumentList_Search'});
    },
    async goMenu_officialDocumentNotAckCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/offcialDocument/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.agreSttusCd = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OfficialDocumentList_Search'});
    },
    async goMenu_officialDocumentSecondSendCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/offcialDocument/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.agreSttusCd = '10';
      params.scdSendng = 'Y';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OfficialDocumentList_Search'});
    },
    async goMenu_tContractSendCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractSingingCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractCompleteCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/complete') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractRejectCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '11';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractRejectCustCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '14';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractRecoveryCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '12';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tContractPrevSumCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/firstContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.delayYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tChangeContractSendCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractSingingCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractCompleteCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/complete') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractRejectCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '11';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractRejectCustCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '14';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractRecoveryCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '12';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_tChangeContractPrevSumCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/changeContract/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.delayYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TChangeContractList_Search'});
    },
    async goMenu_estimateSendCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimateSingingCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimateCompleteCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/complete') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimateRejectCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '11';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimateRejectCustCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '14';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimateRecoveryCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '12';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_estimatePrevSumCnt() {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/tContract/estimate/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.delayYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },
    async goMenu_CmsNewCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/cms') > -1)[0];

      var params = {};
      params.applyNewYn = 'Y';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'CmsList_Search'});
    },
    async goMenu_CmsChangeCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/cms') > -1)[0];

      var params = {};
      params.applyNewYn = 'N';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'CmsList_Search'});
    },
    async goMenu_EliteNewCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/elite') > -1)[0];

      var params = {};
      params.applyNewYn = 'Y';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EliteList_Search'});
    },
    async goMenu_EliteChangeCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/elite') > -1)[0];

      var params = {};
      params.applyNewYn = 'N';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EliteList_Search'});
    },
    async goMenu_ViewNewCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/view') > -1)[0];

      var params = {};
      params.applyNewYn = 'Y';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ViewList_Search'});
    },
    async goMenu_ViewChangeCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/applicationForm/view') > -1)[0];

      var params = {};
      params.applyNewYn = 'N';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ViewList_Search'});
    },

    /*한개 펑션으로 통일*/
    async goMenu_Parts(pT, sC) {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = pT;
      params.statusCode = sC;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;
      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_ConstCmpl(pT, sC) {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = pT;
      params.statusCode = sC;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    /*
    async goMenu_PartFreeWriteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
      
    },
    async goMenu_PartFreeRecoveryCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartFreeReqSignCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartFreeCompleteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '50';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartPaidWriteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartPaidRecoveryCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartPaidReqSignCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartPaidCompleteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '50';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartClaimWriteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = 'CLAIM';
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartClaimRecoveryCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = 'CLAIM';
      params.statusCode = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartClaimReqSignCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = 'CLAIM';
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_PartClaimCompleteCnt() {
      //부품확인서는 청구 메뉴로 보낸다
      //var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part') > -1)[0];
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = 'CLAIM';
      params.statusCode = '50';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_ConstCmplFreeWriteCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplFreeRecoveryCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplFreeReqSignCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplFreeCompleteCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '무상';
      params.statusCode = '50';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplPaidWriteCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '10';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplPaidRecoveryCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '30';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplPaidReqSignCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '20';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async goMenu_ConstCmplPaidCompleteCnt() {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = '유상';
      params.statusCode = '50';
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;  
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    */
    async mnu_click(menuData) {
      if (this.urls.filter(e => e.url === menuData.url).length == 0 && this.urls.length >= MAX_URLS_LENGTH) {
        await this.$alert(
          `화면은 최대 ${MAX_URLS_LENGTH}개까지 열 수 있습니다.<br /><br />다른 화면을 닫은 후 다시 열어 주십시오.`
        );
        return;
      } else {
        this.addMDI(menuData, false);
      }
    },
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.searchParam[key] = value;
    },
    async onSearch() {
      if (!this.listBoxLoading) {
        this.selectSummaryCnt();
      } else {
        this.$alert('먼저 요청한 정보를 조회 중 입니다.');
      }
    },
    async selectSummaryCnt() {
      console.log('this.userInfo', this.userInfo);
      this.searchParam.otisPage = this.userInfo.userType == 'U';
      this.searchParam.customerPage = this.userInfo.userType != 'U';
      console.log(this.searchParam);

      let param = {
        ...this.searchParam,
      };

      try {
        this.listBoxLoading = true;
        this.contentBoxLoading = true;
        let response = await axios.post('/summary/summaryInfoSp', param);
        this.listBoxLoading = false;
        console.log('response.data.length', response.data.length);
        console.log(response.data);

        var nvl0 = function(v) {
          return !v || v == '' ? 0 : v;
        };
        var right = function(str, chr) {
          return str.slice(str.length - chr, str.length);
        };
        var $this = this;
        for (const [key, value] of Object.entries(response.data)) {
          if (right(key, 3) == 'Cnt') {
            $this.summary[key] = nvl0(value);
            console.log('this.summary[key]', $this.summary[key]);
          }
        }
        this.contentBoxLoading = false;
        /*

        this.summary.oContractSumCnt			          = nvl0( response.data.oContractSumCnt );
        this.summary.oContractSendCnt		            = nvl0( response.data.oContractSendCnt );
        this.summary.oContractSingingCnt		        = nvl0( response.data.oContractSingingCnt );
        this.summary.oContractCompleteCnt		        = nvl0( response.data.oContractCompleteCnt );
        this.summary.oContractRejectCnt			        = nvl0( response.data.oContractRejectCnt );
        this.summary.oContractRejectCustCnt         = nvl0( response.data.oContractRejectCustCnt );
        this.summary.oContractRecoveryCnt           = nvl0( response.data.oContractRecoveryCnt );
        this.summary.oContractPrevSumCnt		        = nvl0( response.data.oContractPrevSumCnt );
        this.summary.officialDocumentSumCnt		      = nvl0( response.data.officialDocumentSumCnt );
        this.summary.officialDocumentAgreeCnt		    = nvl0( response.data.officialDocumentAgreeCnt );
        this.summary.officialDocumentNotAgreeCnt	  = nvl0( response.data.officialDocumentNotAgreeCnt );
        this.summary.officialDocumentNotAckCnt		  = nvl0( response.data.officialDocumentNotAckCnt );
        this.summary.officialDocumentSecondSendCnt	= nvl0( response.data.officialDocumentSecondSendCnt );
        this.summary.tContractSumCnt			          = nvl0( response.data.tContractSumCnt );
        this.summary.tContractSendCnt		            = nvl0( response.data.tContractSendCnt );
        this.summary.tContractSingingCnt		        = nvl0( response.data.tContractSingingCnt );
        this.summary.tContractCompleteCnt		        = nvl0( response.data.tContractCompleteCnt );
        this.summary.tContractRejectCnt			        = nvl0( response.data.tContractRejectCnt );
        this.summary.tContractRejectCustCnt         = nvl0( response.data.tContractRejectCustCnt );
        this.summary.tContractRecoveryCnt           = nvl0( response.data.tContractRecoveryCnt );
        this.summary.tContractPrevSumCnt		        = nvl0( response.data.tContractPrevSumCnt );
        this.summary.tChangeContractSumCnt		      = nvl0( response.data.tChangeContractSumCnt );
        this.summary.tChangeContractSendCnt		      = nvl0( response.data.tChangeContractSendCnt );
        this.summary.tChangeContractSingingCnt		  = nvl0( response.data.tChangeContractSingingCnt );
        this.summary.tChangeContractCompleteCnt		  = nvl0( response.data.tChangeContractCompleteCnt );
        this.summary.tChangeContractRejectCnt		    = nvl0( response.data.tChangeContractRejectCnt );
        this.summary.tChangeContractRejectCustCnt   = nvl0( response.data.tChangeContractRejectCustCnt );
        this.summary.tChangeContractRecoveryCnt     = nvl0( response.data.tChangeContractRecoveryCnt );
        this.summary.tChangeContractPrevSumCnt		  = nvl0( response.data.tChangeContractPrevSumCnt );
        this.summary.estimateSumCnt			            = nvl0( response.data.estimateSumCnt );
        this.summary.estimateSendCnt		            = nvl0( response.data.estimateSendCnt );
        this.summary.estimateSingingCnt			        = nvl0( response.data.estimateSingingCnt );
        this.summary.estimateCompleteCnt		        = nvl0( response.data.estimateCompleteCnt );
        this.summary.estimateRejectCnt			        = nvl0( response.data.estimateRejectCnt );
        this.summary.estimateRejectCustCnt          = nvl0( response.data.estimateRejectCustCnt );
        this.summary.estimateRecoveryCnt            = nvl0( response.data.estimateRecoveryCnt );
        this.summary.estimatePrevSumCnt			        = nvl0( response.data.estimatePrevSumCnt );
        this.summary.selectCmsSumCnt			          = nvl0( response.data.selectCmsSumCnt );
        this.summary.selectCmsNewCnt			          = nvl0( response.data.selectCmsNewCnt );
        this.summary.selectCmsChangeCnt			        = nvl0( response.data.selectCmsChangeCnt );
        this.summary.selectViewSumCnt			          = nvl0( response.data.selectViewSumCnt );
        this.summary.selectViewNewCnt			          = nvl0( response.data.selectViewNewCnt );
        this.summary.selectViewChangeCnt            = nvl0( response.data.selectViewChangeCnt );
        this.summary.selectEliteSumCnt			        = nvl0( response.data.selectEliteSumCnt );
        this.summary.selectEliteNewCnt			        = nvl0( response.data.selectEliteNewCnt );
        this.summary.selectEliteChangeCnt		        = nvl0( response.data.selectEliteChangeCnt );

        this.summary.selectPartFreeWriteCnt	= nvl0( response.data.selectPartFreeWriteCnt );
        this.summary.selectPartFreeReqSignCnt	= nvl0( response.data.selectPartFreeReqSignCnt );
        this.summary.selectPartFreeRecoveryCnt	= nvl0( response.data.selectPartFreeRecoveryCnt );
        this.summary.selectPartFreeSignedCnt	= nvl0( response.data.selectPartFreeSignedCnt );
        this.summary.selectPartFreeCompleteCnt	= nvl0( response.data.selectPartFreeCompleteCnt );
        this.summary.selectPartPaidWriteCnt	= nvl0( response.data.selectPartPaidWriteCnt );
        this.summary.selectPartPaidReqSignCnt	= nvl0( response.data.selectPartPaidReqSignCnt );
        this.summary.selectPartPaidRecoveryCnt	= nvl0( response.data.selectPartPaidRecoveryCnt );
        this.summary.selectPartPaidSignedCnt	= nvl0( response.data.selectPartPaidSignedCnt );
        this.summary.selectPartPaidCompleteCnt	= nvl0( response.data.selectPartPaidCompleteCnt );
        this.summary.selectPartClaimWriteCnt	= nvl0( response.data.selectPartClaimWriteCnt );
        this.summary.selectPartClaimReqSignCnt	= nvl0( response.data.selectPartClaimReqSignCnt );
        this.summary.selectPartClaimRecoveryCnt	= nvl0( response.data.selectPartClaimRecoveryCnt );
        this.summary.selectPartClaimSignedCnt	= nvl0( response.data.selectPartClaimSignedCnt );
        this.summary.selectPartClaimCompleteCnt	= nvl0( response.data.selectPartClaimCompleteCnt );
        this.summary.selectConstCmplFreeWriteCnt	= nvl0( response.data.selectConstCmplFreeWriteCnt );
        this.summary.selectConstCmplFreeReqSignCnt	= nvl0( response.data.selectConstCmplFreeReqSignCnt );
        this.summary.selectConstCmplFreeRecoveryCnt	= nvl0( response.data.selectConstCmplFreeRecoveryCnt );
        this.summary.selectConstCmplFreeSignedCnt	= nvl0( response.data.selectConstCmplFreeSignedCnt );
        this.summary.selectConstCmplFreeCompleteCnt	= nvl0( response.data.selectConstCmplFreeCompleteCnt );
        this.summary.selectConstCmplPaidWriteCnt	= nvl0( response.data.selectConstCmplPaidWriteCnt );
        this.summary.selectConstCmplPaidReqSignCnt	= nvl0( response.data.selectConstCmplPaidReqSignCnt );
        this.summary.selectConstCmplPaidRecoveryCnt	= nvl0( response.data.selectConstCmplPaidRecoveryCnt );
        this.summary.selectConstCmplPaidSignedCnt	= nvl0( response.data.selectConstCmplPaidSignedCnt );
        this.summary.selectConstCmplPaidCompleteCnt	= nvl0( response.data.selectConstCmplPaidCompleteCnt );
        */
      } catch (ex) {
        this.listBoxLoading = false;
        this.contentBoxLoading = false;
      }
    },
    async onSearchNotiBoardList() {
      let response = await axios.post('/board/selectBoardSummaryList', {
        pgmCd: '201900',
        natDs: 'NOTI',
        frmNm: '',
      });
      this.notiBoardList = response.data;
    },
    async onSearchFileBoardList() {
      let response = await axios.post('/board/selectBoardSummaryList', {
        pgmCd: '201900',
        natDs: 'FILE',
        frmNm: '',
      });
      this.fileBoardList = response.data;
    },
    async onBoardView(natDs, natNo) {
      this.board.natDs = natDs;
      this.board.natNo = natNo;
      this.$modal.show(this.boardViewModalId);
    },
  },
  computed: {
    ...mapGetters('menu', {
      Items: 'Items',
      selectedMenuNm: 'selectedMenuNm',
      menuInitData: 'menuInitData', //lgksja 202200318 불필요한 호출 제거용
    }),
    ...mapGetters('mdi', {
      urls: 'urls',
    }),
    isOtisPage() {
      return this.userInfo.userType == 'U';
    },
    isCustomerPage() {
      return this.userInfo.userType != 'U';
    },
  },

  //lgksja 202200318 불필요한 호출 제거용
  watch: {
    menuInitData(data) {
      this.menus = data;
    }
  },
};
</script>

<style></style>

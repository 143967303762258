<template>
  <header id="cheader">
    <ul class="header_utils">
      <li>
        <a
          v-for="m in menu"
          v-bind:key="m.menuId"
          href="#"
          @click.prevent.self
          @click="onSelectMenu"
          :data-menu-id="m.menuId"
          :class="m.isSelected ? 'svc' : 'link mr10'"
        >
          {{ m.menuName }}
        </a>
      </li>
      <li class="tar">
        <a href="#" class="logout" @click.prevent.self @click="doLogout()">{{ userInfo.loginName }} 님 로그아웃</a>
        <a v-if="isCustomer" class="btn_change_pwd ml5" @click.prevent.self @click="openChangePw()">비밀번호변경</a>
      </li>
    </ul>
    <!--
    <iui-modal-old name="MyPageModal" title="마이페이지" width="500px" height="600px" :btns="modalBtns">
      <MyPagePopup :type="'equt'" />
    </iui-modal-old>
-->

    <iui-modal-old name="pwEdit" title="비밀번호 변경" width="600px" height="350px">
      <UserEditPw />
    </iui-modal-old>
  </header>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import UserEditPw from '@/view/user/edit/UserEditPw.vue';

//import MyPagePopup from '@/view/sysManage/userManage/myPage/components/MyPagePopup.vue';
export default {
  name: 'iui-header',
  //components: {MyPagePopup},
  components: {
    UserEditPw,
  },
  data() {
    return {
      isCustomer: false,
      modalBtns: [{name: '확인', callback: this.onPopupConfirm}],
      modalNm: '',
    };
  },
  computed: {
    ...mapGetters('menu', {
      menu: 'menu',
    }),
    ...mapGetters('layout', {
      isUserMenu: 'isUserMenu',
    }),
  },
  mounted() {
    this.isCustomer = this.userInfo.userType != 'U';

    if ('Y' == this.userInfo.passwordExpireYn) {
      this.$alert('최근 2개월간 비밀번호를 변경하지 않았습니다.\n비밀번호를 변경하시기 바랍니다.');
      this.openChangePw();
    }
  },
  methods: {
    ...mapActions('menu', ['onSelectMenu']),
    ...mapActions('layout', ['userMenuToggle']),
    ...mapActions('login', ['doLogout']),
    // btnNavigationToggle_click(display) {
    //   let gnb_location = document.querySelector('.gnb_location');

    //   if (display === undefined) {
    //     gnb_location.style.display = gnb_location.style.display === 'none' ? '' : 'none';
    //   } else {
    //     gnb_location.style.display = display;
    //   }
    // },
    onPopup(modalNm) {
      this.modalNm = `${modalNm}Modal`;
      this.$modal.show(this.modalNm);
    },
    onPopupConfirm() {
      if ('MyPageModal' == this.modalNm) {
        this.callEvent({name: 'MyPage_confirm'});
      }
    },
    openChangePw() {
      this.$modal.show('pwEdit');
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-content-box>
    <template v-slot:title>보유면허정보</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
    </template>
    <iui-container type="css-flex">
      <i-row height="120px">
        <i-col>
          <iui-container type="ibsheet" :id="elId" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
      saveJson: '',
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'licenses', 'licenseList']);
  },

  created() {
    this.addEvent([{name: 'holdLcnsInfo_getSaveJson', func: this.getSaveJson}]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/licenseList`],
      value => {
        if (value && value.length) {
          let load = setInterval(() => {
            if (this.sheet) {
              clearInterval(load);
              this.sheet.loadSearchData(value);
            }
          }, 10);
        } else {
          if (this.sheet) {
            this.sheet.removeAll();
          }
        }
      },
      {deep: true}
    );
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    async initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
        },
        Cols: [
          {
            Header: '선택',
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            Visible: this.enable,
          },
          {
            Header: '구분',
            Name: 'conSpDs',
            Type: 'Enum',
            Width: 80,
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', '0215'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', '0215'),
            CanEdit: this.enable,
          },
          {
            Header: '업종',
            Name: 'subWClass',
            Type: 'Enum',
            Width: 200,
            EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', '0200'),
            Enum: await $getCodeIBSheetEnum(this, 'VALUE', '0200'),
            CanEdit: this.enable,
            // Required: 1,
            // EmptyValue: '필수 입력항목입니다.',
          },
          {
            Header: '등록번호',
            Name: 'licenseNo',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
            // Required: 1,
          },
          {
            Header: '발급기관',
            Name: 'licenseOrg',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
            // Required: 1,
          },
          {
            Header: '취득일자',
            Name: 'licenseDate',
            Extend: this.$ibsheetHelper.preset.date,
            CanEdit: this.enable,
          },
          {
            Header: '갱신일자',
            Name: 'licenseChgDate',
            Extend: this.$ibsheetHelper.preset.date,
            CanEdit: this.enable,
          },
          {Name: 'pgmCd', Visible: false},
          {Name: 'licenseSeq', Visible: false},
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.sheet.bind('onRenderFirstFinish', () => {
        if (this.licenseList.length) {
          this.sheet.loadSearchData(this.licenseList);
          let rows = this.sheet.getDataRows();
          for (let i = 0; i < rows.length; i++) {
            this.sheet.refreshRow(rows[i]);
          }
        }
      });
    },

    onAdd() {
      this.sheet.addRow();
    },

    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert('저장할 보유면허정보가 존재하지 않습니다.');
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }

      let saveJson = this.sheet.getSaveJson().data;
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });

      if (await this.$confirm('저장하시겠습니까?')) {
        axios
          .post('/companyRegist/saveLicenseList', {licenseList: $_statusToCud(saveJson)})
          .then(response => {
            if (response.status == 200) {
              this.onSearchLicenseList(this.companyInfo.pgmCd);
              this.focusKeyCode = response.data;
              this.$alert('저장을 완료하였습니다.');
            } else {
              this.$alert('저장을 실패하였습니다.');
            }
          })
          .catch(ex => {
            console.log('저장을 실패하였습니다. : ', ex);
          });
      }
    },
    onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');

      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      checkedRows.forEach(row => {
        if (row.pgmCd) {
          this.sheet.deleteRow(row);
        } else {
          this.sheet.removeRow(row);
        }
      });
    },

    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },

    onSearchLicenseList(pgmCd) {
      axios.post('/companyRegist/selectCompanyLicenseList', {pgmCd: pgmCd}).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  }, //methods

  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style></style>

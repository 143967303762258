<template>
  <div class="contents">
    <div class="join_step04">
      <span class="blind">step04 가입완료</span>
    </div>

    <div class="join_end">
      <span class="blind">회원가입을 축하드립니다.</span>
    </div>

    <div class="btn_area">
      <button type="button" class="btn_confirm" @click="goLogin">로그인 화면으로</button>
    </div>
  </div>
  <!-- contents end -->
</template>

<script>
import {mapMutations} from 'vuex';
export default {
	methods: {
		...mapMutations('login', ['setPage']),
		goLogin() {
			this.setPage('Login');
		},
	},
};
</script>

<style scoped>
</style>
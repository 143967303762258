var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{attrs:{"id":"contents"}},[_c('iui-mdi'),(_vm.$store.getters.currentUrl == '/Home' || _vm.$store.getters.currentUrl == '/')?_c('div',{staticClass:"gnb_location h1",staticStyle:{"display":"none"}}):_c('div',{staticClass:"gnb_location",staticStyle:{"display":"none"}},[_c('div',{staticClass:"stit"},[_c('h3',[_vm._v(_vm._s(_vm.selectedViewNm))])]),_c('div',{staticClass:"breadcrumbs",domProps:{"innerHTML":_vm._s(_vm.selectedViewPath)}}),_c('div',{staticClass:"button_section"},[_vm._l((_vm.cmmBtns),function(btn){return _c('a',{key:btn.name,class:[
          btn.name == '조회'
            ? 'btn_01 mr5'
            : btn.name == '신규'
            ? 'btn_02 mr5'
            : btn.name == '저장'
            ? 'btn_03 mr5'
            : btn.name == '삭제'
            ? 'btn_04 mr5'
            : btn.name == '출력'
            ? 'btn_05 mr5'
            : '' ],attrs:{"href":"#","disabled":btn.disabled},on:{"click":btn.callback}},[_vm._v(_vm._s(btn.name))])}),_c('a',{staticClass:"btn_06 mr5",attrs:{"href":"#"},on:{"click":_vm.onClose}},[_vm._v("닫기")])],2)]),_c('pmis-keep-alive',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const name = '/SYS301';
const state = {
  upperDetailInfo: {
    manageCd: '',
    verNo:'',
    applcDate: '',
    applcYn: '',
    mngrNm: '',
    mngrSignUrl: '',
    rmCn: '',
  },
};

const getters = {
  masterSignHist: state => state.masterSignHist,
};

const actions = {};

const mutations = {
  setMasterSignHist(state, payload) {
    for (let key in state.masterSignHist) {
      if (payload && payload.hasOwnProperty(key)) {
        state.masterSignHist[key] = payload[key];
      } else {
        state.masterSignHist[key] = '';
      }
    }
  },
};

export default {
  name,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

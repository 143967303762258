<template>
  <iui-container type="css-flex" theme="bullet" :loading="listBoxLoading">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            유지보수 계약
          </template>

          <div style="width:100%; text-align:center; font-size:20px; font-weight: bold;">
            계약서 진위확인 정보
          </div>

          <br />

          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header>고객명</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mskTradeName1" :enable="false" />
              </i-col>
              <i-col-header>계약일자</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.checkContDate" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>계약기간</i-col-header>
              <i-col>
                <iui-datepicker-old :value="contractInfo.checkContStartDate" suffix="~" :enable="false" />
                <iui-datepicker-old :value="contractInfo.checkContFinDate" :enable="false" />
              </i-col>
              <i-col-header>현장명(건물명)</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.mainName" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>계약금액</i-col-header>
              <i-col>비공개</i-col>
              <i-col-header>소재지</i-col-header>
              <i-col>
                <iui-text :value="contractInfo.checkAddr12" :enable="false" />
              </i-col>
            </i-row>
          </iui-container>

          <br />

          <div style="width:100%; text-align:center; color:red; font-weight: bold;">
            해당페이지는 계약서 표지에 있는 QR코드로 조회한 계약서 및 발주서의 요약 정보만 제공 드리는 화면입니다.<br />
            계약체결의 진위확인을 위해 제공되는 화면으로 세부적인 내용은 공개하지 않습니다.<br /><br />

            세부정보는 각 영업담당자에게 문의하시거나 OTIS엘리베이터 전자계약시스템에 로그인 하시면 확인 할 수 있습니다.
          </div>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 'o-contract-proving',
  created() {
    this.addEvent({name: 'OContract_Search', func: this.onSearch});
    console.log('addEvent', 'OContract_Search');
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,
    };
  },
  mounted() {},
  methods: {
    ...mapMutations('OContractStore', {
      setContractInfo: 'setContractInfo',
    }),
    async onSearch() {
      console.log('OContract_Search', 'onSearch');
      console.log('decParams.ctrtNo', this.decParams.ctrtNo);
      console.log('decParams.ctrtOdr', this.decParams.ctrtOdr);
      console.log('decParams.ctrtDivCd', this.decParams.ctrtDivCd);

      let param = {
        ctrtNo: this.decParams.ctrtNo,
        ctrtOdr: this.decParams.ctrtOdr,
        ctrtDivCd: this.decParams.ctrtDivCd,
      };

      console.log('param', param);
      if (param.ctrtNo === undefined || param.ctrtNo == '') return;

      try {
        this.listBoxLoading = true;
        let response = await axios.post('/urlLink/selectOContractInfo', param);
        this.listBoxLoading = false;
        console.log(response.data);

        this.setContractInfo(response.data.contractInfo);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters('LinkViewStore', {
      decParams: 'decParams',
    }),
    ...mapGetters('OContractStore', {
      contractInfo: 'contractInfo',
    }),
  },
};
</script>

<style></style>

/* FOD > 외주 발주서 */
<script>
import {defineComponent, reactive, ref} from '@vue/composition-api';
import OrderList from './components/OrderList.vue';
import OrderView from './components/OrderView.vue';

export default defineComponent({
  components: {
    OrderList,
    OrderView,
  },

  setup() {
    const pageButton = reactive({
      search: false,
      excel: false,
    });

    const oList = ref(null);

    const state = reactive({
      loading: false,
      contractKey: undefined,
    });

    const method = {
      search_click() {
        oList.value.getList();
      },
      excel_click() {
        oList.value.downloadExcel();
      },
    };

    return {
      pageButton,
      oList,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page :loading="state.loading" @search-click="search_click" @excel-click="excel_click">
    <iui-container type="css-flex">
      <i-row style="min-height: 330px;">
        <i-col>
          <order-list ref="oList" :page-loading.sync="state.loading" :selected-contract-key.sync="state.contractKey" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="330px" style="min-height: 330px;">
        <i-col>
          <order-view :contract-key="state.contractKey" />
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

<style></style>

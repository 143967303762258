<template>
  <pmis-page
    :loading="pageLoading"
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="search_click"
    @new-click="new_click"
    @save-click="save_click"
    @delete-click="delete_click"
    @print-click="print_click"
  >
    <pmis-content-box title-type="tab" :loading="contentBoxLoading" style="height:290px">
      <template v-slot:title>
        서버 인증서 등록
      </template>

      <iui-container type="table" header-width="150px">
        <i-row>
          <i-col-header colspan="2">암호화용 공개키</i-col-header>
          <i-col>
            <file-upload
              input-id="file1"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files"
              @input="inputUpdate1"
              ref="upload1"
            >
              <button>SELECT FILE</button>
              {{ fileName1 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">암호화용 개인키</i-col-header>
          <i-col>
            <file-upload
              input-id="file2"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files"
              @input="inputUpdate2"
              ref="upload2"
            >
              <button>SELECT FILE</button>
              {{ fileName2 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">서명용 공개키</i-col-header>
          <i-col>
            <file-upload
              input-id="file3"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files"
              @input="inputUpdate3"
              ref="upload3"
            >
              <button>SELECT FILE</button>
              {{ fileName3 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">서명용 개인키</i-col-header>
          <i-col>
            <file-upload
              input-id="file4"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files"
              @input="inputUpdate4"
              ref="upload4"
            >
              <button>SELECT FILE</button>
              {{ fileName4 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">인증서 비밀번호</i-col-header>
          <i-col>
            <input
              type="password"
              name="password"
              value="signgate1!"
              placeholder="비밀번호"
              style="height: 20px; font-size: 12px"
            />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">BUTTON</i-col-header>
          <i-col>
            <button @click="onSubmit" style="width: 90px">SUBMIT</button>
            <button @click="onDelete" style="width: 90px">DELETE</button>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header colspan="2">STATUS</i-col-header>
          <i-col>{{ message }}</i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>

    <br />

    <pmis-content-box title-type="tab" :loading="contentBoxLoading" style="height:350px">
      <template v-slot:title>
        클라이언트 전자서명 및 서명검증, 서버 서명검증 샘플
      </template>
      <iui-container type="css-flex" flex-direction="row" height="150px">
        <i-row>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                원문
              </template>
              <template v-slot:title-right>
                <iui-button @click="SecuKit_PKCS7SignMessage">Sign</iui-button>
              </template>
              <iui-text type="multi" :value.sync="SecuKit_OriginalMessage" />
            </pmis-content-box>
          </i-col>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                PKCS#7 Signed Message
              </template>
              <template v-slot:title-right>
                <iui-button @click="SecuKit_PKCS7AddSignMessage">AddSign</iui-button>
              </template>
              <iui-text type="multi" :value.sync="SecuKit_PKCS7SignedMessage" />
            </pmis-content-box>
          </i-col>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                PKCS#7 Verified Message
              </template>
              <template v-slot:title-right>
                <iui-button @click="SecuKit_PKCS7VerifyMessage">Verify</iui-button>
              </template>
              <iui-text type="multi" :value.sync="SecuKit_PKCS7VerifiedMessage" />
            </pmis-content-box>
          </i-col>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                Server PKCS7 Verify Message
              </template>
              <template v-slot:title-right>
                <iui-button @click="Server_SecuKit_PKCS7VerifyMessage">Verify</iui-button>
              </template>
              <iui-text type="multi" :value.sync="Server_SecuKit_PKCS7VerifiedMessage" />
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container>

      <iui-container type="css-flex" flex-direction="row" height="150px">
        <i-row>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                Multi PKCS7SignMessage
              </template>
              <template v-slot:title-right>
                <iui-button @click="SecuKit_MultiPKCS7SignMessag">Multi PKCS7SignMessag</iui-button>
              </template>

              <iui-container type="css-flex" flex-direction="row">
                <i-row>
                  <i-col>
                    <iui-text type="multi" :value.sync="MultiSignData[0].PKCS7SignedMessage" />
                  </i-col>
                  <i-col>
                    <iui-text type="multi" :value.sync="MultiSignData[1].PKCS7SignedMessage" />
                  </i-col>
                  <i-col>
                    <iui-text type="multi" :value.sync="MultiSignData[2].PKCS7SignedMessage" />
                  </i-col>
                </i-row>
              </iui-container>
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>

    <br />

    <pmis-content-box title-type="tab" :loading="contentBoxLoading" style="height:200px">
      <template v-slot:title>
        사용자 신원확인
      </template>
      <iui-container type="css-flex" flex-direction="row" height="150px">
        <i-row>
          <i-col>
            <pmis-content-box>
              <template v-slot:title>
                Identify
              </template>
              <template v-slot:title-right>
                <iui-button @click="SecuKit_Identify">Identify</iui-button>
              </template>

              <iui-container type="css-flex" flex-direction="row">
                <i-row>
                  <i-col>
                    <iui-text type="multi" :value.sync="Identify.Message" />
                  </i-col>
                </i-row>
              </iui-container>
            </pmis-content-box>
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import FileUpload from 'vue-upload-component';
export default {
  name: 'iui-file',
  components: {
    FileUpload,
  },
  data() {
    return {
      SecuKit_OriginalMessage: '원문',
      SecuKit_PKCS7SignedMessage: '',
      SecuKit_PKCS7VerifiedMessage: '',
      Server_SecuKit_PKCS7VerifiedMessage: '',
      SignerDn: '',
      MultiSignData: [
        {OriginalMessage: '원문1', PKCS7SignedMessage: '', SignerDn: ''},
        {OriginalMessage: '원문2', PKCS7SignedMessage: '', SignerDn: ''},
        {OriginalMessage: '원문3', PKCS7SignedMessage: '', SignerDn: ''},
      ],
      Identify: {
        KmCertPEM: '',
        LoginId: 'sample',
        UserSignCert: '',
        EncryptedSessionKey: '',
        EncryptedUserRandomNumber: '',
        Message: '',
      },
      files1: [],
      files2: [],
      files3: [],
      files4: [],
      passwd: '',
      fileName1: '',
      fileName2: '',
      fileName3: '',
      fileName4: '',
      message: '',
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.$secuKit.checkReady();
  },
  methods: {
    async search_click() {
      this.$alert('조회');
    },
    async new_click() {
      this.$alert('신규');
    },
    async save_click() {
      this.$alert('저장');
    },
    async delete_click() {
      this.$alert('삭제');
    },
    async print_click() {
      this.$alert('안쇄');
    },

    inputUpdate1(files) {
      if (files) {
        this.files1 = files;
        if (this.files1[0].file.name.toLowerCase() != 'kmcert.der') {
          this.$alert('kmCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName1 = this.files1[0].file.name;
      }
    },
    inputUpdate2(files) {
      if (files) {
        this.files2 = files;
        if (this.files2[0].file.name.toLowerCase() != 'kmpri.key') {
          this.$alert('kmCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName2 = this.files2[0].file.name;
      }
    },
    inputUpdate3(files) {
      if (files) {
        this.files3 = files;
        if (this.files3[0].file.name.toLowerCase() != 'signcert.der') {
          this.$alert('signCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName3 = this.files3[0].file.name;
      }
    },
    inputUpdate4(files) {
      if (files) {
        this.files4 = files;
        if (this.files4[0].file.name.toLowerCase() != 'signpri.key') {
          this.$alert('signPri.key 파일을 선택해야 합니다.');
          return;
        }
        this.fileName4 = this.files4[0].file.name;
      }
    },
    async onSubmit() {
      const me = this;

      if (this.files1.length == 0) {
        this.$alert('암호화용 공개키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files2.length == 0) {
        this.$alert('암호화용 개인키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files3.length == 0) {
        this.$alert('서명용 공개키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files4.length == 0) {
        this.$alert('서명용 개인키 파일을 선택해야 합니다.');
        return;
      }

      this.passwd = document.getElementsByName('password')[0].value;

      if (this.passwd == '') {
        this.$alert('비밀번호를 입력해야 합니다.');
        return;
      }

      let response1 = await axios.post('/secukit/kmCertPem');
      console.log(response1);
      this.Identify.KmCertPEM = response1.data;

      let data1 = {
        targetCert: removePEMHeader(removeCRLF(this.Identify.KmCertPEM)),
        sourceString: this.passwd,
      };
      const r1 = await this.$secuKit.invoke('generatePKCS7EnvelopedData', data1);
      console.log(r1);

      const encryptedPasswd = r1.generatePKCS7EnvelopedData;

      /* 20220215 lgksja 클라이언트 문제발생으로 임시사용
      const encryptedPasswd = "-----BEGIN PKCS7-----\n"+
"MIIBxQYJKoZIhvcNAQcDoIIBtjCCAbICAQAxggFuMIIBagIBATBSMEoxCzAJBgNV\n"+
"BAYTAktSMQ0wCwYDVQQKDARLSUNBMRUwEwYDVQQLDAxBY2NyZWRpdGVkQ0ExFTAT\n"+
"BgNVBAMMDHNpZ25HQVRFIENBNQIEBbGmEjANBgkqhkiG9w0BAQEFAASCAQBd+C3b\n"+
"J7d+q9T1av+ZhRE7ZFWQWd9UtpdDZOjfyjS9L2gVx3bsBHF3pMg1qHDcXcsjTj4L\n"+
"vA8bwFQ/M7tgRgqInthe/yS+BlTyTSM4HwtIWZIfaxhH32GJ7kOv0w1cyS+kaK1D\n"+
"7We//gSvgUnujJHPCf4/c9cp2X8rJ69zNi/0mxHg+q1L+dF1FDGWmlnXmSaxF+ny\n"+
"U4XEpppXwY2WEmnKghwGGIxpv00bKyXP4NDPMEFYtJFLKlVFUZ0j+HelBIFcT1mm\n"+
"KdMHZFsE9Mman0hZF+kmP+CP6MAev4/yhU7/I5bL0r23ttcUEjrmDp6CJXxQu+91\n"+
"h0+qBS417B7mkc4XMDsGCSqGSIb3DQEHATAcBggqgxqMmkQBBAQQ59+sj4aEA4hx\n"+
"FQFxTTsTF4AQwx3Y5CzMJn9Yxwr9SY6wmA==\n"+
"-----END PKCS7-----";
      */
      console.log(encryptedPasswd);

      let formData = new FormData();
      formData.append('kmCert', this.files1[0].file);
      formData.append('kmPri', this.files2[0].file);
      formData.append('signCert', this.files3[0].file);
      formData.append('signPri', this.files4[0].file);
      formData.append('encryptedPasswd', encryptedPasswd);

      let headers = {headers: {'Content-Type': 'multipart/form-data'}};
      let response2 = await axios.post('/secukit/certFileUpload', formData, headers).catch(e => {
        console.error('파일 업로드 실패 ::', e);
        me.message = '실패';
      });

      if (response2.status == 200) {
        me.message = '성공';
      } else {
        me.message = '실패';
      }
    },
    onDelete() {
      this.files1 = [];
      this.files2 = [];
      this.files3 = [];
      this.files4 = [];
      this.passwd = '';
      this.fileName1 = '';
      this.fileName2 = '';
      this.fileName3 = '';
      this.fileName4 = '';
      this.message = '';
    },

    async SecuKit_PKCS7SignMessage() {
      try {
        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.SecuKit_OriginalMessage,
          certID: certID,
        };

        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.SecuKit_PKCS7SignedMessage = r2.generatePKCS7SignedData;

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.SignerDn = r3.userDN;
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_PKCS7AddSignMessage() {
      try {
        if (this.SecuKit_PKCS7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.SecuKit_PKCS7SignedMessage = '';
          });
          return;
        }

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: removePEMHeader(removeCRLF(this.SecuKit_PKCS7SignedMessage)),
          certID: certID,
        };

        const r2 = await this.$secuKit.addPKCS7SignedData(data);
        console.log(r2);

        this.SecuKit_PKCS7SignedMessage = r2.addPKCS7SignedData;

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.SignerDn = r3.userDN;
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_PKCS7VerifyMessage() {
      try {
        if (this.SecuKit_PKCS7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.SecuKit_PKCS7SignedMessage = '';
          });
          return;
        }

        let data = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.SecuKit_PKCS7SignedMessage)),
        };

        const r1 = await this.$secuKit.verifyPKCS7SignedData(data);
        console.log(r1);

        this.SecuKit_PKCS7VerifiedMessage = r1.verifyPKCS7SignedData;
      } catch (ex) {
        console.log(ex);
      }
    },

    async Server_SecuKit_PKCS7VerifyMessage() {
      try {
        if (this.SecuKit_PKCS7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.SecuKit_PKCS7SignedMessage = '';
          });
          return;
        }

        if (this.SignerDn == '') {
          this.$alert('전자서명한 인증서의 DN값이 반드시 필요합니다.', () => {
            this.SecuKit_PKCS7SiSignerDngnedMessage = '';
          });
          return;
        }

        let param = {
          pkcs7SignedMessage: this.SecuKit_PKCS7SignedMessage,
          signerDn: this.SignerDn,
        };

        let response = await axios.post('/secukit/pkcs7StringVerify', param);
        console.log(response);
        if (response.data.bolVerify == true) {
          this.Server_SecuKit_PKCS7VerifiedMessage = response.data.resultData;
        } else {
          this.Server_SecuKit_PKCS7VerifiedMessage = response.data.message;
        }
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_MultiPKCS7SignMessag() {
      try {
        if (0 == this.MultiSignData.length) {
          this.$alert('다중 전자서명 할 내용이 존재하지 않습니다.');
          return;
        }

        let isEmptyOriginalMessage = false;
        Array.from(this.MultiSignData).every(item => {
          let originalMessage = item.OriginalMessage;

          if (originalMessage === undefined) {
            isEmptyOriginalMessage = true;
            return false;
          }
        });

        if (isEmptyOriginalMessage) {
          this.$alert('전자서명 할 원문이 존재하지 않습니다.');
          return;
        }

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        //console.log('certID', certID);

        for (let index = 0; index < this.MultiSignData.length; index++) {
          const item = this.MultiSignData[index];

          let data = {
            certType: 'SignCert',
            sourceString: item.OriginalMessage,
            certID: certID,
          };

          const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
          //console.log(r2);

          item.PKCS7SignedMessage = r2.generatePKCS7SignedData;

          let data2 = {
            certType: 'SignCert',
            certID: certID,
            isViewVID: '1',
          };
          const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
          //console.log(r3);

          item.SignerDn = r3.userDN;

          //console.log(item);
        }

        //console.log(this.MultiSignData);
      } catch (ex) {
        console.log(ex);
      }
    },

    async SecuKit_Identify() {
      let response = await axios.post('/secukit/kmCertPem');
      console.log(response);
      this.Identify.KmCertPEM = response.data;

      const r1 = await this.$secuKit.showDialog();
      console.log('r1 :', r1);

      const certID = this.$secuKit.certListInfo.getCertID();

      let data1 = {
        certType: 'SignCert',
        certID: certID,
        isViewVID: '1', // 0 : VID 추출 안함,  1 : VID 추출
      };

      const r2 = await this.$secuKit.invoke('viewCertInfomationWithVID', data1);
      console.log('r2 :', r2);

      let UserSignCert = r2.certPEM; // SignCert 정보
      // let UserOID         = r2.policy;            // OID 정보
      // let UserKmCert      = r2.encryptCertPEM;    // KmCert 정보
      // let UserDN          = r2.userDN;            // DN 정보
      let vidRandom = r2.vidRandom; // 신원확인 정보

      let data2 = {
        algorithm: 'SEED/CBC/PADDING', // 암호화 알고리즘
        keyName: certID,
        sourceString: vidRandom,
      };

      const r3 = await this.$secuKit.invoke('symmetricEncryptData', data2);
      console.log('r3 :', r3);

      //r3.symmetricEncryptData; // 신원확인 정보를 대칭키 암호화

      let data3 = {
        serverCert: removePEMHeader(removeCRLF(this.Identify.KmCertPEM)), // 서버 인증서 정보
        rsaVersion: 'V15', // RSA 버전 (V15 / V21)
        keyName: certID,
      };

      const r4 = await this.$secuKit.invoke('getSymmetricKeyIV', data3);
      console.log('r4 :', r4);

      // r4.getSymmetricKeyIV; // 서버 인증서로 대칭키 암호화에 사용된 키 RSA 암호화 추 추출

      this.Identify.UserSignCert = UserSignCert;
      this.Identify.EncryptedSessionKey = r4.getSymmetricKeyIV;
      this.Identify.EncryptedUserRandomNumber = r3.symmetricEncryptData;

      let param = {
        userSignCert: this.Identify.UserSignCert,
        encryptedSessionKey: this.Identify.EncryptedSessionKey,
        encryptedUserRandomNumber: this.Identify.EncryptedUserRandomNumber,
      };

      let response2 = await axios.post('/secukit/identify', param);
      console.log(response2);
      if (response2.data.bolVerify == true) {
        this.Identify.Message = response2.data.subjectDN + '\n' + response2.data.serialNumber;
      } else {
        this.Identify.Message = response2.data.message;
      }
    },
  },
};
</script>

<style scoped></style>

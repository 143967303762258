<template>
  <pmis-page
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="search_click"
    @new-click="new_click"
    @save-click="save_click"
    @delete-click="delete_click"
    @print-click="print_click"
  >
    <pmis-content-box :loading="listBoxLoading" style="height:230px">
      <iui-container type="jqgrid" :id="sheetId" style="width:99.5%" />
    </pmis-content-box>

    <br />

    <pmis-content-box title-type="tab" style="height:200px">
      <template v-slot:title>
        서버 인증서 정보
      </template>

      <iui-container type="table">
        <i-row>
          <i-col-header>순번</i-col-header>
          <i-col>
            <iui-text type="smallcode" :value.sync="svrCertInfo.certNo" name="certNo" :enable="false" />
          </i-col>
          <i-col-header>인증서DN</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.certDnDs" name="certDnDs" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>유효시작일자</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.validFromDt" name="validFromDt" :enable="false" />
          </i-col>
          <i-col-header>유효종료일자</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.validToDt" name="validToDt" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>사용유무</i-col-header>
          <i-col>
            <iui-checkbox-group-old
              name="useYn"
              :items="[{label: '사용', value: 'Y'}]"
              :checkedValues="[svrCertInfo.useYn]"
              @change="onSetValue"
            />
          </i-col>
          <i-col-header>시리얼번호</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.serialNo" name="serialNo" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>등록자</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.rgprName" name="rgprName" :enable="false" />
          </i-col>
          <i-col-header>등록일시</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.rgsDttm" name="rgsDttm" :enable="false" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>수정자</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.mdfyName" name="mdfyName" :enable="false" />
          </i-col>
          <i-col-header>수정일시</i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="svrCertInfo.mdfyDttm" name="mdfyDttm" :enable="false" />
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>

    <br />

    <pmis-content-box title-type="tab" style="height:180px" v-show="regMode">
      <template v-slot:title>
        서버 인증서 등록
      </template>

      <iui-container type="table" header-width="150px">
        <i-row>
          <i-col-header>암호화용 공개키</i-col-header>
          <i-col>
            <file-upload
              input-id="file1"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files1"
              @input="inputUpdate1"
              ref="upload1"
            >
              <button>SELECT FILE</button>
              {{ fileName1 }}
            </file-upload>
          </i-col>
          <i-col-header>암호화용 개인키</i-col-header>
          <i-col>
            <file-upload
              input-id="file2"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files2"
              @input="inputUpdate2"
              ref="upload2"
            >
              <button>SELECT FILE</button>
              {{ fileName2 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>서명용 공개키</i-col-header>
          <i-col>
            <file-upload
              input-id="file3"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files3"
              @input="inputUpdate3"
              ref="upload3"
            >
              <button>SELECT FILE</button>
              {{ fileName3 }}
            </file-upload>
          </i-col>
          <i-col-header>서명용 개인키</i-col-header>
          <i-col>
            <file-upload
              input-id="file4"
              class="btn btn-primary"
              post-action="/upload/post"
              :multiple="false"
              :size="1024 * 1024"
              :value="files4"
              @input="inputUpdate4"
              ref="upload4"
            >
              <button>SELECT FILE</button>
              {{ fileName4 }}
            </file-upload>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>인증서 비밀번호</i-col-header>
          <i-col>
            <input
              type="password"
              name="password"
              value=""
              placeholder="비밀번호"
              style="height: 20px; font-size: 12px"
            />
          </i-col>
          <i-col-header>BUTTON</i-col-header>
          <i-col>
            <button @click="onSubmit" style="width: 90px">UPLOAD</button>
            <button @click="onClear" style="width: 90px">CLEAR</button>
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>STATUS</i-col-header>
          <i-col colspan="3">{{ message }}</i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import FileUpload from 'vue-upload-component';
export default {
  name: 'iui-file',
  components: {
    FileUpload,
  },
  data() {
    return {
      listBoxLoading: false,

      KmCertPEM: '',
      MdfyAppPath: '/view/sysManage/svrCertManage/index.vue',
      files1: [],
      files2: [],
      files3: [],
      files4: [],
      passwd: '',
      fileName1: '',
      fileName2: '',
      fileName3: '',
      fileName4: '',
      message: '',
      sheetId: 'sheet' + this._uid,
      searchInfo: {
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      },
      svrCertInfo: {
        certNo: '',
        certDnDs: '',
        validFromDt: '',
        validToDt: '',
        serialNo: '',
        useYn: '',
        rgprId: '',
        rgsDttm: '',
        mdfyId: '',
        mdfyDttm: '',
        rgprName: '',
        mdfyName: '',
      },
      regMode: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.$secuKit.init();
    this.$secuKit.checkReady();
    console.log('checkReady = ' + this.$secuKit.secuKitReady);

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 150,
      width: 800,
      colModel: [
        {name: 'certNo', label: '순번', width: 30, align: 'center'},
        {name: 'certDnDs', label: '인증서DN', width: 300, align: 'left'},
        {name: 'validFromDt', label: '유효시작일', width: 60, align: 'center'},
        {name: 'validToDt', label: '유효종료일', width: 60, align: 'center'},
        {name: 'serialNo', label: '시리얼번호', width: 0, align: 'center', hidden: true},
        {name: 'useYn', label: '사용유무', width: 50, align: 'center'},
        {name: 'rgprId', label: '등록자ID', width: 0, align: 'center', hidden: true},
        {name: 'rgprName', label: '등록자명', width: 50, align: 'center'},
        {
          name: 'rgsDttm',
          label: '등록일시',
          width: 120,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: true, srcformat: 'ISO8601Long', newformat: 'Y-m-d H:i:s A'},
        },
        {name: 'mdfyId', label: '수정자ID', width: 0, align: 'center', hidden: true},
        {name: 'mdfyName', label: '수정자명', width: 50, align: 'center'},
        {
          name: 'mdfyDttm',
          label: '수정일시',
          width: 120,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: true, srcformat: 'ISO8601Long', newformat: 'Y-m-d H:i:s A'},
        },
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        // console.log("rowId = " + rowId);
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        // console.log(rowData);

        me.svrCertInfo.certNo = rowData.certNo;
        me.svrCertInfo.certDnDs = rowData.certDnDs;
        me.svrCertInfo.validFromDt = rowData.validFromDt;
        me.svrCertInfo.validToDt = rowData.validToDt;
        me.svrCertInfo.serialNo = rowData.serialNo;
        me.svrCertInfo.useYn = rowData.useYn;
        me.svrCertInfo.rgprId = rowData.rgprId;
        me.svrCertInfo.rgsDttm = rowData.rgsDttm;
        me.svrCertInfo.mdfyId = rowData.mdfyId;
        me.svrCertInfo.mdfyDttm = rowData.mdfyDttm;
        me.svrCertInfo.rgprName = rowData.rgprName;
        me.svrCertInfo.mdfyName = rowData.mdfyName;
      },
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.search_click();
  },

  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);
    },
    async search_click() {
      this.regMode = false;
      this.listBoxLoading = true;

      let param = {};
      let response = null;
      try {
        response = await axios.post('/svrCertRegist/selectSvrCertList', param);
        // console.log(response);
        this.listBoxLoading = false;

        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          this.selectGridRow(ids[0]);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async new_click() {
      this.onClear();
      this.regMode = true;
    },
    async save_click() {
      var message = '서버 인증서 정보를 수정하시겠습니까?';

      if (await this.$confirm(message)) {
        let param = {
          certNo: this.svrCertInfo.certNo,
          useYn: this.svrCertInfo.useYn,
          mdfyAppPath: this.MdfyAppPath,
        };

        let response = await axios.post('/svrCertRegist/updateSvrCert', param).catch(e => {
          console.error('저장을 실패했습니다 ::', e);
        });
        if (response.status == 200) {
          this.search_click();
        } else {
          this.$alert('저장을 실패했습니다.');
        }
      }
    },
    async delete_click() {
      if (this.svrCertInfo.useYn == 'Y') {
        this.$alert('사용중인 서버 인증서를 삭제할 수 없습니다.');
        return;
      }

      var message = '서버 인증서 정보를 삭제하시겠습니까?';

      if (await this.$confirm(message)) {
        let param = {
          certNo: this.svrCertInfo.certNo,
        };

        let response = await axios.post('/svrCertRegist/deleteSvrCert', param).catch(e => {
          console.error('삭제를 실패했습니다 ::', e);
        });
        if (response.status == 200) {
          this.search_click();
        } else {
          this.$alert('삭제를 실패했습니다.');
        }
      }
    },
    async print_click() {
      // this.$alert('인쇄');
      this.$store.dispatch('mdi/doPrint');
    },

    selectGridRow(rowId) {
      var rowData = $('#' + this.sheetId).jqGrid('getRowData', rowId);

      // console.log(rowData);

      this.svrCertInfo.certNo = rowData.certNo;
      this.svrCertInfo.certDnDs = rowData.certDnDs;
      this.svrCertInfo.validFromDt = rowData.validFromDt;
      this.svrCertInfo.validToDt = rowData.validToDt;
      this.svrCertInfo.serialNo = rowData.serialNo;
      this.svrCertInfo.useYn = rowData.useYn;
      this.svrCertInfo.rgprId = rowData.rgprId;
      this.svrCertInfo.rgsDttm = rowData.rgsDttm;
      this.svrCertInfo.mdfyId = rowData.mdfyId;
      this.svrCertInfo.mdfyDttm = rowData.mdfyDttm;
      this.svrCertInfo.rgprName = rowData.rgprName;
      this.svrCertInfo.mdfyName = rowData.mdfyName;
    },

    inputUpdate1(files1) {
      if (files1) {
        this.files1 = files1;
        if (this.files1[0].file.name.toLowerCase() != 'kmcert.der') {
          this.$alert('kmCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName1 = this.files1[0].file.name;
      }
    },
    inputUpdate2(files2) {
      if (files2) {
        this.files2 = files2;
        if (this.files2[0].file.name.toLowerCase() != 'kmpri.key') {
          this.$alert('kmCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName2 = this.files2[0].file.name;
      }
    },
    inputUpdate3(files3) {
      if (files3) {
        this.files3 = files3;
        if (this.files3[0].file.name.toLowerCase() != 'signcert.der') {
          this.$alert('signCert.der 파일을 선택해야 합니다.');
          return;
        }
        this.fileName3 = this.files3[0].file.name;
      }
    },
    inputUpdate4(files4) {
      if (files4) {
        this.files4 = files4;
        if (this.files4[0].file.name.toLowerCase() != 'signpri.key') {
          this.$alert('signPri.key 파일을 선택해야 합니다.');
          return;
        }
        this.fileName4 = this.files4[0].file.name;
      }
    },
    async onSubmit() {
      let checkReady = this.$secuKit.secuKitReady;
      console.log('checkReady = ' + checkReady);

      const me = this;

      if (this.files1.length == 0) {
        this.$alert('암호화용 공개키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files2.length == 0) {
        this.$alert('암호화용 개인키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files3.length == 0) {
        this.$alert('서명용 공개키 파일을 선택해야 합니다.');
        return;
      }

      if (this.files4.length == 0) {
        this.$alert('서명용 개인키 파일을 선택해야 합니다.');
        return;
      }

      this.passwd = document.getElementsByName('password')[0].value;

      if (this.passwd == '') {
        this.$alert('비밀번호를 입력해야 합니다.');
        return;
      }

      let response1 = await axios.post('/svrCertRegist/kmCertPem');
      //let response1 = await axios.post('/secukit/kmCertPem');
      // console.log(response1);
      this.KmCertPEM = response1.data;

      if (this.KmCertPEM == '') {
        this.$alert('암호화용 공개키 정보를 서버에서 얻을 수 없습니다.');
        return;
      }

      if (!checkReady) {
        this.$alert('SecukitNX가 아직 로딩되지 않았습니다.');
        return;
      }

      let data1 = {
        targetCert: removePEMHeader(removeCRLF(this.KmCertPEM)),
        sourceString: this.passwd,
      };
      const r1 = await this.$secuKit.invoke('generatePKCS7EnvelopedData', data1);
      // console.log(r1);

      const encryptedPasswd = r1.generatePKCS7EnvelopedData;

      // 20220215 lgksja 클라이언트 문제발생으로 임시사용
      /*
      const encryptedPasswd = "-----BEGIN PKCS7-----\n"+
"MIIBxQYJKoZIhvcNAQcDoIIBtjCCAbICAQAxggFuMIIBagIBATBSMEoxCzAJBgNV\n"+
"BAYTAktSMQ0wCwYDVQQKDARLSUNBMRUwEwYDVQQLDAxBY2NyZWRpdGVkQ0ExFTAT\n"+
"BgNVBAMMDHNpZ25HQVRFIENBNQIEBbGmEjANBgkqhkiG9w0BAQEFAASCAQBd+C3b\n"+
"J7d+q9T1av+ZhRE7ZFWQWd9UtpdDZOjfyjS9L2gVx3bsBHF3pMg1qHDcXcsjTj4L\n"+
"vA8bwFQ/M7tgRgqInthe/yS+BlTyTSM4HwtIWZIfaxhH32GJ7kOv0w1cyS+kaK1D\n"+
"7We//gSvgUnujJHPCf4/c9cp2X8rJ69zNi/0mxHg+q1L+dF1FDGWmlnXmSaxF+ny\n"+
"U4XEpppXwY2WEmnKghwGGIxpv00bKyXP4NDPMEFYtJFLKlVFUZ0j+HelBIFcT1mm\n"+
"KdMHZFsE9Mman0hZF+kmP+CP6MAev4/yhU7/I5bL0r23ttcUEjrmDp6CJXxQu+91\n"+
"h0+qBS417B7mkc4XMDsGCSqGSIb3DQEHATAcBggqgxqMmkQBBAQQ59+sj4aEA4hx\n"+
"FQFxTTsTF4AQwx3Y5CzMJn9Yxwr9SY6wmA==\n"+
"-----END PKCS7-----";
*/

      // console.log(encryptedPasswd);

      if (encryptedPasswd == '') {
        this.$alert('비밀번호를 암호화 하는데 실패하였습니다.');
        return;
      }

      let formData = new FormData();
      formData.append('kmCert', this.files1[0].file);
      formData.append('kmPri', this.files2[0].file);
      formData.append('signCert', this.files3[0].file);
      formData.append('signPri', this.files4[0].file);
      formData.append('encryptedPasswd', encryptedPasswd);
      formData.append('mdfyAppPath', this.MdfyAppPath);

      let headers = {headers: {'Content-Type': 'multipart/form-data'}};
      let response2 = await axios.post('/svrCertRegist/certFileUpload', formData, headers).catch(e => {
        console.error('파일 업로드 실패 ::', e);
        me.message = '실패';
      });

      if (response2.status == 200) {
        me.message = '성공';
        this.clearData();
        this.search_click();
      } else {
        me.message = '실패';
      }
    },
    async onClear() {
      var message = '서버 인증서 등록을 위해 정보를 초기화 하시겠습니까?';

      if (await this.$confirm(message)) {
        this.clearData();
      }
    },

    clearData() {
      this.files1 = [];
      this.files2 = [];
      this.files3 = [];
      this.files4 = [];
      this.passwd = '';
      this.fileName1 = '';
      this.fileName2 = '';
      this.fileName3 = '';
      this.fileName4 = '';
      this.message = '';
    },

    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : 'N') : obj.target.value;
      }

      if (key == 'useYn') {
        // console.log("this.svrCertInfo.useYn = " + this.svrCertInfo.useYn);
        this.svrCertInfo.useYn = value;
        // console.log("useYn = " + value);
        // console.log("this.svrCertInfo.useYn = " + this.svrCertInfo.useYn);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped></style>

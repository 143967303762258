<template>
  <pmis-content-box>
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-container type="table">
            <colgroup>
              <col width="150px" />
              <col width="50px" />
              <col width="25%" />
              <col width="100px" />
              <col width="25%" />
              <col width="100px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header colspan="2">계약변경사유</i-col-header>
              <i-col colspan="5">
                <iui-text :value="contractInfo.renewReasonTrade" :enable="false" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">구분</i-col-header>
              <i-col-header colspan="2">변경 전</i-col-header>
              <i-col-header colspan="2">변경 후</i-col-header>
              <i-col-header>비고</i-col-header>
            </i-row>
            <i-row>
              <i-col-header colspan="2">법인명(이름)</i-col-header>
              <i-col colspan="2"><iui-text :value="contractInfo.beforeTradeName" :enable="false"/></i-col>
              <i-col colspan="2"><iui-text :value="contractInfo.tradeName" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">대표자</i-col-header>
              <i-col colspan="2"><iui-text :value="contractInfo.beforeRepntName" :enable="false"/></i-col>
              <i-col colspan="2"><iui-text :value="contractInfo.repntName" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">사업자번호/주민번호</i-col-header>
              <i-col colspan="2"><iui-text :value="contractInfo.beforeBusinessNo" :enable="false"/></i-col>
              <i-col colspan="2"><iui-text :value="contractInfo.businessNo" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약일자</i-col-header>
              <i-col colspan="2"><iui-datepicker-old :value="contractInfo.beforeContDate" :enable="false"/></i-col>
              <i-col colspan="2"><iui-datepicker-old :value="contractInfo.contDate" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.diffContDate" type="quantity" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header colspan="2">계약금액</i-col-header>
              <i-col colspan="2"
                ><iui-text :value="contractInfo.beforeTotalBiddingAmt" type="amount" :enable="false"
              /></i-col>
              <i-col colspan="2"><iui-text :value="contractInfo.totalBiddingAmt" type="amount" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.diffTotalBiddingAmt" type="amount" :enable="false"/></i-col>
            </i-row>
            <i-row>
              <i-col-header rowspan="3">공동수급업체 구성원</i-col-header>
              <i-col-header>A</i-col-header>
              <i-col><iui-text :value="contractInfo.beforeOespNameA" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.beforeBillRateA" type="rate" suffix="%" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.oespNameA" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.billRateA" type="rate" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col-header>B</i-col-header>
              <i-col><iui-text :value="contractInfo.beforeOespNameB" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.beforeBillRateB" type="rate" suffix="%" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.oespNameB" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.billRateB" type="rate" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
            <i-row>
              <i-col-header>C</i-col-header>
              <i-col><iui-text :value="contractInfo.beforeOespNameC" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.beforeBillRateC" type="rate" suffix="%" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.oespNameC" :enable="false"/></i-col>
              <i-col><iui-text :value="contractInfo.billRateC" type="rate" :enable="false"/></i-col>
              <i-col>&nbsp;</i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'TContractTabContractChangeInfo_Load', func: this.onLoad});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',
      width250: '250px',
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      console.log('TContractTabContractChangeInfo_Load');
    },
  },
  computed: {},
};
</script>

<style></style>

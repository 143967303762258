<template>
  <pmis-page ref="page" :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container type="css-flex">
      <i-row ref="srchList">
        <i-col>
          <!-- 검색조건 -->
          <iui-container type="css-flex" theme="bullet">
            <i-row height="70px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header style="width: 80px">구분</i-col-header>
                    <i-col style="width: 100px">
                      <iui-select-old
                        id="searchCheckStatus"
                        name="searchCheckStatus"
                        :items="checkStatusArray"
                        defaultCd="S"
                        @change="setSearchValue('searchCheckStatus', $event)"
                      ></iui-select-old>
                    </i-col>
                    <i-col-header style="width: 70px">CC/팀</i-col-header>
                    <i-col style="width: 180px">
                      <!-- <iui-select-old
                id="searchCct"
                name="searchCct"
                :items="cctArray"
                defaultCd="S"
                @change="setSearchValue('searchCct', $event)"
              ></iui-select-old> -->
                      <iui-text name="searchCct" :value.sync="searchInfo.searchCct" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>건물명</i-col-header>
                    <i-col>
                      <iui-text name="searchBuldNm" :value.sync="searchInfo.searchBuldNm" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>영업담당자</i-col-header>
                    <i-col>
                      <iui-text name="searchContEmpNm" :value.sync="searchInfo.searchContEmpNm" @enter="onPreSearch" />
                    </i-col>
                    <i-col style="float: right">
                      <iui-button @click="searchClear"> 검색조건 초기화 </iui-button>
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>계약일</i-col-header>
                    <i-col
                      ><span style="white-space:nowrap">
                        <iui-datepicker-old
                          name="checkContDateStart"
                          suffix="~"
                          @change="onSetValue($event, 'checkContDateStart')"
                          :value.sync="searchInfo.checkContDateStart"/>
                        &nbsp;<iui-datepicker-old
                          name="checkContDateEnd"
                          @change="onSetValue($event, 'checkContDateEnd')"
                          :value.sync="searchInfo.checkContDateEnd"
                      /></span>
                    </i-col>

                    <i-col-header></i-col-header>
                    <i-col></i-col>

                    <i-col-header>계약번호</i-col-header>
                    <i-col>
                      <iui-text name="searchCtrtNo" :value.sync="searchInfo.searchCtrtNo" @enter="onPreSearch" />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>

            <!-- 신청서 목록 (sheet)-->
            <!-- <i-row height="240px"> -->
            <i-row >
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    신청서 목록
                  </template>
                  <iui-container type="jqgrid" :id="sheetId1" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>

            <!-- 호기명 -->
            <i-row height="160px" style="margin-top:5px;">
              <i-col width="520px">
                <pmis-content-box>
                  <template v-slot:title>
                    호기명
                  </template>
                  <template v-slot:title-left>
                    <font
                      style="color:#FF6666; font-weight: bold; font-size: 11px; width: 250px !important;"
                      v-if="!isOtisPage"
                      >더블클릭하여 내용 편집</font
                    >
                  </template>
                  <template v-slot:title-right>
                    <iui-button v-if="!isOtisPage" @click="hogiAll">전체선택/해제</iui-button>
                    <iui-button v-if="!isOtisPage" @click="hogiAdd">추가</iui-button>
                    <iui-button style="margin-right: 10px;" v-if="!isOtisPage" @click="hogiDel">삭제</iui-button>
                  </template>
                  <iui-container
                    type="jqgrid"
                    :id="sheetId2"
                    :loading="listBoxLoading"
                    style="width:99.9%;"
                  />
                </pmis-content-box>
              </i-col>

              <!-- 고객정보 -->
              <i-col style="margin-left:15px;">
                <pmis-content-box>
                  <template v-slot:title>
                    view 고객정보
                  </template>
                  <template v-slot:title-left>
                    <font style="color:#FF6666; font-weight: bold; font-size: 11px; width: 250px" v-if="!isOtisPage"
                      >더블클릭하여 내용 편집</font
                    >
                  </template>
                  <template v-slot:title-right>
                    <iui-button v-if="!isOtisPage" @click="cstmrAll">전체선택/해제</iui-button>
                    <iui-button v-if="!isOtisPage" @click="cstmrAdd">추가</iui-button>
                    <iui-button style="margin-right: 10px;" v-if="!isOtisPage" @click="cstmrDel">삭제</iui-button>
                  </template>
                  <iui-container type="jqgrid" :id="sheetId3" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>
            <!-- 저장 및 전자서명 -->
            <i-row v-if="isCustomerPage" height="40px">
              <i-col>
                <div style="display: flex;">
                  <p style="margin: 0px auto; margin-top:10px">
                    <span style="margin-left: 10px;" v-if="requestInfo.progrsSttusCd != '10'"
                      ><iui-button style="margin: 0px auto;" @click="onSave">변경 및 전자서명</iui-button></span
                    >
                    <span style="margin-left: 10px;" v-if="requestInfo.progrsSttusCd == '10'"
                      ><iui-button style="margin: 0px auto;" @click="onSave">저장 및 전자서명</iui-button></span
                    >
                  </p>
                </div>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  data() {
    return {
      cmmBtns: [
        //{name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        //{name: '출력', class: 'p', callback: this.onPrint},
      ],
      // 검색 정보
      searchInfo: {
        searchBuldNm: '', // 건물명
        searchContEmpNm: '', // 영업담당자
        searchCheckStatus: '', // 구분 (신청서 상태 - 신청완료/작성중/미작성 )
        searchCct: '', // CC
        searchCtrtNo: '', // 계약 번호
        checkContDateStart: '', // 계약 시작일
        checkContDateEnd: '', // 계약 종료일
        applyNewYn: '',
        summaryYm: '',
        isOtisPage: true, // 담당자 페이지
        isCustomerPage: false, // 고객 페이지
      },
      // 구분 (신청서 상태)
      checkStatusArray: [
        {text: '작성중', value: '10'},
        {text: '신청완료', value: '20'},
        {text: '오프라인완료', value: '30'}, // 오프라인 완료 추가(21.05.10)
        //{text: "미작성",   value: "99"}, // 신청서 데이터 X (21.04.27 담당자페이지는 주석처리함)
      ],
      cctArray: [], //CCT 배열

      // 신청정보
      requestInfo: {
        reqstdocNo: '', // 신청서 번호 (계약번호)
        reqstdocOdr: -1, // 신청서 차수
        ctrtNo: '', // 계약 번호
        buldNm: '', // 계약명(현장명)
        manageNo: '', // 관리번호
        progrsSttusCd: '', // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
        contEmpId: '', // 영업 담당자 ID (CHARGER_ID)
        tradeNo: '', // 거래선번호
        tradeSfx: '', // 거래선SUFFIX
        tradeName: '', // 거래선명
        reqstDt: '', // 신청일
        checkContDate: '', // 계약일
        agreYn: 'X', // 동의 여부 (Y / N / X )- 동의 / 미동의 / 선택 X
        applcntNm: '', // 신청인명
        reqstdocDivCd: 'VIEW', // 신청서 구분 코드
        repntName: '', // 거래선 대표자명 (신청인 명으로 들어감)
        isOtisPage: true, // 담당자 페이지
        isCustomerPage: false, // 고객 페이지
        hogiAllNm: '', // 전체 호기 명
        notiType: 'REQALL', // 신청정보 알림
        isReqZero: false, // 신청서 0차수 여부
      },

      // view Service 포커싱
      focusKeys: {
        reqstdocNo: '', // 신청서 번호  (계약 번호)
        reqstdocOdr: -1, // 신청서 차수
        ctrtNo: '', // 계약 번호
        ctrtOdr: '', // 계약 차수
        ctrtDivCd: '', // 계약 종류
        contractType: '',
        signType: '',
      },

      sheetId1: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId, // JQ시트
      sheetId2: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId + 1, // JQ시트2
      sheetId3: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId + 2, // JQ시트2
      listBoxLoading: false,
      isProcessing: false, // 저장 무한클릭 방지

      OZParam: {},
      isFirstSearch: true, // 페이지 첫 로드 OK 여부
      isHogiAllCheck: false, // 호기 전체체크 여부
      isCstmrAllCheck: false, // 고객 전체체크 여부
      isOtisPage: true, // 담당자 페이지
      isCustomerPage: false, // 고객 페이지
      goSign: false, // 저장 후 바로 전자서명 여부

      delRow: [], // 삭제 행 배열
      editableCells: ['chrgTelno', 'chrgNm', 'rmCn', 'conectHogiNm', 'hogiNm'], // 편집 가능한 열 사전 설정
    };
  },
  created() {
    this.addEvent({name: 'ViewList_Search', func: this.onPreSearch});
  },
  mounted() {
    console.log('sheetId1 : ', this.sheetId1);
    console.log('sheetId2 : ', this.sheetId2);
    console.log('sheetId3 : ', this.sheetId3);

    this.onInitRequestInfo();

    /* ===================
     * JQGrid1 - 신청서목록
     * =================== */
    const OPT1 = {
      Cols: [
        //{name: "reqstdocInfo",        label: "신청서",          width: 80,    align: "center"},
        {name: 'status', label: '구분', width: 100, align: 'center'},
        {name: 'cct', label: 'CCT', width: 70, align: 'center'},
        {name: 'buldNm', label: '건물명', width: 170},
        {name: 'ctrtNo', label: '계약번호', width: 100, align: 'center'},
        {name: 'contEmpNm', label: '영업담당자', width: 90, align: 'center'},
        {
          name: 'reqstDt',
          label: '신청일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContDate',
          label: '계약일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContStartDate',
          label: '계약시작일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContFinDate',
          label: '계약종료일',
          width: 90,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {name: 'tradeName', label: '대표고객명', width: 180},
        {name: 'tradeNameOther', label: '대표외고객', width: 90, align: 'center'},
        {name: 'docCat', label: '표준/비표준', width: 90, align: 'center'},
        {name: 'progrsSttusCd', label: '상태코드', width: 0, hidden: true},
        {name: 'docNm', label: '계약종류', width: 0, hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약종류2', width: 0, hidden: true}, // OC/TC
        {name: 'reqstdocNo', label: '신청서번호', width: 0, hidden: true}, // 신청서번호
        {name: 'reqstdocOdr', label: '신청서차수', width: 0, hidden: true}, // 신청서차수
        {name: 'contEmpId', label: '영업담당자ID', width: 0, hidden: true}, // 영업담당자ID
        {name: 'tradeNo', label: '거래선번호', width: 0, hidden: true}, // 거래선번호
        {name: 'tradeSfx', label: '거래선SUFFIX', width: 0, hidden: true}, // 거래선 SUFFIX
        {name: 'repntName', label: '거래선대표자명', width: 0, hidden: true}, // 거래선 대표자명
        {name: 'signType', label: 'signType', width: 0, hidden: true}, // 서명 ON/ OFF
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
      ],
    };
    OPT1.Cols.push(
      
      {
        name: '계약서',
        label: '계약서',
        width: 60,
        align: 'center',
        // eslint-disable-next-line
        formatter: function(cellvalue, options, rowObject) {
          cellvalue = true;
          return cellvalue == true ? '<button >보기</button>' : '';
        },
        cellattr: $_rowMearge,
      }
    );
    OPT1.Cols.unshift(
      // eslint-disable-next-line
      {
        name: '신청서',
        label: '신청서',
        width: 60,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          if (rowObject.status == '신청완료' || rowObject.status == '작성중' || rowObject.status == '오프라인완료') {
            cellvalue = true;
          }
          return cellvalue == true ? '<button >보기</button>' : '';
        },
        cellattr: $_rowMearge,
      }
    );
    //ITSR54651 - OTIS eContract OTIS View 계약정보 및 고객정보 다운로드 기능추가 20220701
    OPT1.Cols.push(      
      {
        name: 'custTel',
        label: '전화번호',
        width: 100,
        align: 'left',
        excelView: true,
        hidden: true,
      },
      {
        name: 'custName',
        label: '이름',
        width: 70,
        align: 'left',
        excelView: true,
        hidden: true,
      },
      {
        name: 'custHogi',
        label: '접속호기',
        width: 70,
        align: 'center',
        excelView: true,
        hidden: true,
      },
      {
        name: 'custEtc',
        label: '비고',
        width: 100,
        align: 'left',
        excelView: true,
        hidden: true,
      },
    );

    
    const me = this;
    var $grid1 = $('#' + me.sheetId1);
    $grid1.jqGrid({
      height: 150,
      width: 1115,
      colModel: OPT1.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,

      // 셀 클릭 이벤트
      onCellSelect: function(rowId, iCol) {
        var rowData = $('#' + me.sheetId1).jqGrid('getRowData', rowId);
        me.onInitRequestInfo(); //requestInfo init()

        // focuskey set
        me.focusKeys.reqstdocNo = rowData.reqstdocNo;
        me.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        me.focusKeys.contractType = rowData.contractType;
        me.focusKeys.signType = rowData.signType;
        console.log('focus key ', me.focusKeys);

        // 상세조회 START
        me.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
        me.onReqDetailSearch(); // 상세조회

        // 계약서 보기
        if (iCol == $('#' + me.sheetId1).jqGrid('getGridParam').iColByName['계약서']) {
          if (rowData.signType == 'ON') {
            me.onCtrtView(rowData);
          } else {
            me.$alert('선택된 계약은 오프라인으로 진행한 계약이라 계약서를 조회할 수 없습니다.');
          }
        }
        // 신청서 보기
        if (iCol == $('#' + me.sheetId1).jqGrid('getGridParam').iColByName['신청서']) {
          if (rowData.status == '신청완료' || rowData.status == '작성중') {
            me.onReqView();
          } else if (rowData.status == '오프라인완료') {
            me.$alert('선택된 신청서는 오프라인으로 진행한 신청서로 신청서를 조회할 수 없습니다.');
          }
        }
      },
    });
    var groups = [];
    // 대표고객명 + 대표외 고객 header 병합
    groups.push({startColumnName: 'tradeName', numberOfColumns: 2, titleText: '고객정보(대표)'});
    groups.push({startColumnName: 'custTel', numberOfColumns: 4, titleText: 'view 고객정보'});
    $grid1.jqGrid('setGroupHeaders', {
      useColSpanStyle: true,
      groupHeaders: groups,
    });

    /* ====================
     * JQGrid2 - 호기명
     * =================== */
    const OPT2 = {
      Cols: [
        {
          name: 'hogiNm',
          label: '호기명',
          width: 480,
          align: 'center',
          editable: true,
          cellEdit: true,
          edittype: 'text',
        }, // 호기명
        {name: 'reqstdocNo', label: '신청서번호', width: 0, hidden: true}, // 신청서번호
        {name: 'reqstdocOdr', label: '신청서차수', width: 0, hidden: true}, // 신청서차수
      ],
    };
    OPT2.Cols.unshift({
      name: 'hogiCheck',
      label: '',
      width: 30,
      align: 'center',
      formatter: function() {
        return '<input type="checkBox" name="hogiCheckBox" style="display:block !important; margin: 0px auto;">';
      },
    });

    var $grid = $('#' + me.sheetId2);
    $grid.jqGrid({
      height: 90,
      //width: 510,
      colModel: OPT2.Cols,
      iconSet: 'fontAwesome',

      // 더블클릭시에 열 편집 할 수 있도록 하는 이벤트
      ondblClickRow: function(rowid, iRow, iCol) {
        // 고객페이지 아닐경우 return
        if (me.isOtisPage) {
          return;
        }
        var colModels = $(this).getGridParam('colModel');
        var colName = colModels[iCol].name;

        // 사전에 등록해 둔 col들 name (editableCells)에 있을 시에만 편집 가능하도록 설정
        if (me.editableCells.indexOf(colName) >= 0) {
          $(this).editRow(rowid, {keys: true, focusField: iCol}); // row 편집가능 / enter 저장  , esc 취소  , focusField => 선택 cell

          // edit 끝낸 뒤에 focusOut시에 입력했던 값 바로 저장
          $('#' + iRow + '_' + colName).bind('blur', function() {
            $('#' + me.sheetId2).jqGrid('saveRow', iRow, false, 'clientArray'); // 서버 호출없이 그리드에만 저장
            return;
          });
        }
      },
    });

    /* =======================
     * JQGrid3 - View 고객정보
     * ====================== */
    const OPT3 = {
      Cols: [
        {
          name: 'chrgTelno',
          label: '전화번호',
          width: 130,
          align: 'center',
          editable: true,
          cellEdit: true,
          edittype: 'text',
        },
        {name: 'chrgNm', label: '이름', width: 130, align: 'center', editable: true, cellEdit: true, edittype: 'text'},
        {
          name: 'conectHogiNm',
          label: '접속호기',
          width: 130,
          align: 'center',
          editable: true,
          cellEdit: true,
          edittype: 'text',
        },
        {name: 'rmCn', label: '비고', width: 190, align: 'center', editable: true, cellEdit: true, edittype: 'text'},
        {name: 'reqstdocNo', label: '신청서번호', width: 0, hidden: true}, // 신청서번호
        {name: 'reqstdocOdr', label: '신청서차수', width: 0, hidden: true}, // 신청서차수
      ],
    };
    OPT3.Cols.unshift({
      name: 'cstmrCheck',
      label: '',
      width: 45,
      align: 'center',
      formatter: function() {
        return '<input type="checkBox" name="cstmrCheckBox" style="display:block !important; margin: 0px auto;">';
      },
    });

    var $grid3 = $('#' + me.sheetId3);
    $grid3.jqGrid({
      height: 90,
      //width:1400,
      colModel: OPT3.Cols,
      iconSet: 'fontAwesome',

      // 더블클릭시에 열 편집 할 수 있도록 하는 이벤트
      // eslint-disable-next-line
      ondblClickRow: function(rowid, iRow, iCol, e) {
        // 고객페이지 아닐경우 return
        if (me.isOtisPage) {
          return;
        }
        var colModels = $(this).getGridParam('colModel');
        var colName = colModels[iCol].name;
        var pressKey = 0; // tab키 저장용
        // 사전에 등록해 둔 col들 name (editableCells)에 있을 시에만 편집 가능하도록 설정
        if (me.editableCells.indexOf(colName) >= 0) {
          $(this).editRow(rowid, {keys: true, focusField: iCol}); // row 편집가능 / enter 저장  , esc 취소  , focusField => 선택 cell
          // keydown bind
          // $("#"+iRow+"_"+colName).bind("keydown",function(e){
          //   console.log("bind e : ",e);

          //   var key = e.charCode || e.keyCode;
          //   pressKey = key;
          //   var nextColName = "";

          //   console.log("key :",key);
          //   console.log("colName : ",colName);

          //   // 체크박스 열이거나 마지막 열(접속호기) 일 시 0 저장
          //   if (colName == "conectHogiNm" || colName == "cstmrCheck"){
          //     pressKey = 0;
          //   }

          //   // tab키 일때 지금 열에 따라 다음 열 focusing
          //   if(pressKey === 9){
          //     console.log("in key 9");
          //     if (colName == "chrgTelno"){
          //       nextColName = "chrgNm";
          //     } else if (colName == "chrgNm"){
          //       nextColName = "conectHogiNm";
          //     } else if (colName = "conectHogiNm"){
          //       nextColName = "rmCn";
          //     }
          //     setTimeout(function(e) {
          //       $('#' + rowid + '_'+nextColName).focus();
          //       $('#' + rowid + '_'+nextColName).select();
          //     }, 100);
          //   }
          // });

          // edit 끝낸 뒤에 focusOut시에 입력했던 값 바로 저장
          // eslint-disable-next-line
          $('#' + iRow + '_' + colName).bind('blur', function(e) {
            if (pressKey !== 9) {
              //console.log("EDIT OVER");
              $('#' + me.sheetId3).jqGrid('saveRow', iRow, false, 'clientArray'); // 서버 호출없이 그리드에만 저장
              return;
            } else {
              //console.log("tabClicked");
            }
          });
        }
      },
    });

    // 검색시 날짜 지정 없으면 1년치만 조회
    if (this.searchInfo.summaryYm === undefined || this.searchInfo.summaryYm == '') {
      this.searchInfo.checkContDateStart = $_addDay($_getCurrentDate(), -30);
      this.searchInfo.checkContDateEnd = $_getCurrentDate();
    }

    // 검색조건 - 구분 IE 로딩위해 추가
    setTimeout(() => {
      $('#searchCheckStatus_select').prop('selectedIndex', 0);
    }, 100);

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onDefaultValSet();
    this.onPreSearch();

    // 20210407 검색창 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });
  },

  methods: {
    // 창 리사이즈
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      //$grid.jqGrid("setGridWidth", newWidth-1, true); // true 줄 시에 크기값 비율고정됨

      var $grid1 = $('#' + this.sheetId1);
      var pobj1 = $grid1
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth1 = pobj1.width();
      $grid1.jqGrid('setGridWidth', newWidth1 - 2, false);

      var $grid2 = $('#' + this.sheetId2);
      var pobj2 = $grid2
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth2 = pobj2.width();
      $grid2.jqGrid('setGridWidth', newWidth2 - 2, false);

      var $grid3 = $('#' + this.sheetId3);
      var pobj3 = $grid3
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth3 = pobj3.width();
      $grid3.jqGrid('setGridWidth', newWidth3 - 2, false);

      var bottomLeftObj = $grid1
				//.closest('.bullet')
				.parent()
				.parent()
				.parent()
				.parent()
				.parent()
				.parent();
			$grid1.jqGrid('setGridHeight', bottomLeftObj.height() -60, false);
    },
    // 페이지 값/변수 초기화
    onDefaultValSet() {
      // 개인정보 운영방침 문구 replace
      // var pStr = document.getElementById("privacyTextarea").value;
      // pStr = pStr.replace(/<br>/gi, "\r\n");
      // pStr = pStr.replace(/<t>/gi, "\t");
      // document.getElementById("privacyTextarea").value = pStr;
      // checkbox 값 설정
      //document.getElementsByName("agreYn")[0].checked = false;

      // default value 설정
      this.requestInfo.recptMthPostYn = 'N';
      this.requestInfo.smsYn = 'Y';
      this.requestInfo.nmSeCd = '10';
      this.requestInfo.progrsSttusCd = '10';
      this.requestInfo.reqstdocDivCd = 'VIEW';
      this.requestInfo.notiType = 'REQALL';
      this.requestInfo.isReqZero = false;
    },
    // RequestInfo 초기화 후 기본값 설정
    onInitRequestInfo() {
      for (var key in this.requestInfo) {
        if (this.requestInfo.hasOwnProperty(key)) {
          this.requestInfo[key] = '';
        }
      }
      this.requestInfo.reqstdocDivCd = 'VIEW';
      this.requestInfo.notiType = 'REQALL';
      this.requestInfo.isReqZero = false;
    },
    // 페이지 최초 검색
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.searchInfo.applyNewYn = '';
          this.searchInfo.summaryYm = '';

          this.searchInfo.applyNewYn = params.applyNewYn;
          this.searchInfo.summaryYm = params.summaryYm;
        }
      }

      this.focusKeys.reqstdocNo = '';
      this.focusKeys.reqstdocOdr = 0;
      this.onSearch();
    },
    //상단 검색조건 설정 (건물명/계약종류/구분/CC)
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.searchInfo[target] = value;
    },
    // 서치 selectBox 설정
    setSearchValue(target, event) {
      this.searchInfo[target] = event.target.value;
    },
    // 검색조건 초기화
    searchClear() {
      for (var key in this.searchInfo) {
        if (this.searchInfo.hasOwnProperty(key)) {
          this.searchInfo[key] = '';
        }
      }
      for (var key2 in this.focusKeys) {
        if (this.focusKeys.hasOwnProperty(key2)) {
          this.focusKeys[key2] = '';
        }
      }
      this.searchInfo.checkContDateStart = $_addDay($_getCurrentDate(), -30);
      this.searchInfo.checkContDateEnd = $_getCurrentDate();
      this.searchInfo.isOtisPage = true;
      this.searchInfo.isCustomerPage = false;
      $('#searchCheckStatus_select').prop('selectedIndex', 0);
      //$("#searchCct_select").prop("selectedIndex",0);
    },
    // DATA -> RequestInfo 값 셋팅
    onSetRequestInfo(Data) {
      // 검색되는 key insert
      for (var key in Data) {
        if (this.requestInfo.hasOwnProperty(key)) {
          this.requestInfo[key] = Data[key];
        }
      }
    },
    // 호기 전체선택/해제
    hogiAll() {
      if (this.isHogiAllCheck) {
        // 전체 해제
        $("input[name='hogiCheckBox']").prop('checked', false);
        this.isHogiAllCheck = false;
      } else {
        // 전체 선택
        $("input[name='hogiCheckBox']").prop('checked', true);
        this.isHogiAllCheck = true;
      }
    },
    // 호기추가
    hogiAdd() {
      let rowId = $('#' + this.sheetId2).getGridParam('reccount'); // 페이징 처리 시 현 페이지의 Max RowId 값
      $('#' + this.sheetId2).jqGrid('addRowData', rowId + 1, {}, 'last'); // 마지막 행에 Row 추가
    },
    // 호기삭제
    async hogiDel() {
      this.delRow = [];
      var ids = $('#' + this.sheetId2).jqGrid('getDataIDs'); // 전체 row id 배열
      var isChecked = false;
      var i = 0;

      // 체크된 호기 정보 저장
      if (ids && ids.length > 0) {
        for (i = 0; i <= ids.length - 1; i++) {
          isChecked = $("input[name='hogiCheckBox']")[i].checked;
          if (isChecked) {
            this.delRow.push(ids[i]); // 삭제할 행 id 저장 (setGridParam으로 bind된 데이터는 id가 jq14,jq15등으로 되어있음)
          }
        }
        // 삭제할 행이 존재할때 삭제
        if (this.delRow.length > 0) {
          if (!(await this.$confirm('체크한 호기 정보가 삭제됩니다. 삭제하시겠습니까?'))) return;
          for (i = 0; i <= this.delRow.length - 1; i++) {
            $('#' + this.sheetId2).jqGrid('delRowData', this.delRow[i]); // 행 삭제
          }
          this.isCstmrAllCheck = false; // 삭제시 전체선택/해제 클릭시 전체선택 우선 될 수 있도록 설정
        }
      } else {
        this.$alert('삭제할 행이 존재하지 않습니다.');
      }
    },
    // 고객 전체선택/해제
    cstmrAll() {
      if (this.isCstmrAllCheck) {
        // 전체 해제
        $("input[name='cstmrCheckBox']").prop('checked', false);
        this.isCstmrAllCheck = false;
      } else {
        // 전체 선택
        $("input[name='cstmrCheckBox']").prop('checked', true);
        this.isCstmrAllCheck = true;
      }
    },
    // 고객추가
    cstmrAdd() {
      let rowId = $('#' + this.sheetId3).getGridParam('reccount'); // 페이징 처리 시 현 페이지의 Max RowId 값
      //$("#"+this.sheetId3).jqGrid("addRowData", rowId+1, 'first'); // 첫 행에 Row 추가
      $('#' + this.sheetId3).jqGrid('addRowData', rowId + 1, {}, 'last'); // 마지막 행에 Row 추가
    },
    // 고객삭제
    async cstmrDel() {
      this.delRow = [];
      var ids = $('#' + this.sheetId3).jqGrid('getDataIDs'); // 전체 row id 배열
      var isChecked = false;
      var i = 0;

      // 체크된 고객 정보 저장
      if (ids && ids.length > 0) {
        for (i = 0; i <= ids.length - 1; i++) {
          isChecked = $("input[name='cstmrCheckBox']")[i].checked;
          if (isChecked) {
            this.delRow.push(ids[i]); // 삭제할 행 저장
          }
        }
        // 삭제할 행이 존재할때 삭제
        if (this.delRow.length > 0) {
          if (!(await this.$confirm('체크한 고객 정보가 삭제됩니다. 삭제하시겠습니까?'))) return;
          for (i = 0; i <= this.delRow.length - 1; i++) {
            $('#' + this.sheetId3).jqGrid('delRowData', this.delRow[i]); // 행 삭제
          }
          this.isCstmrAllCheck = false; // 삭제시 전체선택/해제 클릭시 전체선택 우선 될 수 있도록 설정
        }
      } else {
        this.$alert('삭제할 행이 존재하지 않습니다.');
      }
    },
    // 검색
    async onSearch() {
      let response = '';
      let param = this.searchInfo;
      let yearCheck = 0;
      let monthCheck = 0;
      let dayCheck = 0;

      let startYyyy = this.searchInfo.checkContDateStart.substring(0, 4); // 시작 년도
      let endYyyy = this.searchInfo.checkContDateEnd.substring(0, 4); // 종료 년도

      let startMm = this.searchInfo.checkContDateStart.substring(4, 6); // 시작 달
      let endMm = this.searchInfo.checkContDateEnd.substring(4, 6); // 종료 달

      let startDd = this.searchInfo.checkContDateStart.substring(6, 8); // 시작 일
      let endDd = this.searchInfo.checkContDateEnd.substring(6, 8); // 종료 일

      // 계약기간 비워져 있을시에 alert
      if (!this.searchInfo.checkContDateStart || !this.searchInfo.checkContDateEnd) {
        this.$alert('검색하실 계약기간의 시작일과 종료일을 선택해주세요. (1개월 이내)');
        return;
      }
      // 계약기간 검사 (연,월,일 기준 검사)
      yearCheck = endYyyy - startYyyy;
      monthCheck = endMm - startMm;
      dayCheck = endDd - startDd;

      // 3개월용
      // if (yearCheck == 1){
      //   // 작년 10월, 11월, 12월 기준 으로 3개월 검사시
      //   if (!((startMm == 10 || startMm == 11 || startMm == 12) && monthCheck <- 8)){
      //     this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
      //     return ;
      //   }
      // } else if (yearCheck == 0){
      //   if (monthCheck > 3 && yearCheck == 0){
      //     this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
      //     return ;
      //   }
      // } else {
      //   this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
      //   return ;
      // }

      // 1개월용
      if (yearCheck == 1) {
        // 작년 10월, 11월, 12월 기준 으로 3개월 검사시
        if (!(startMm == 12 && monthCheck < -10)) {
          this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
          return;
        }
      } else if (yearCheck == 0) {
        if (monthCheck > 1 && yearCheck == 0) {
          this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
          return;
        }
      } else {
        this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
        return;
      }

      if (yearCheck <= 0 && monthCheck <= 0 && dayCheck < 0) {
        this.$alert('계약 검색기간 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
        this.searchInfo.checkContDateStart = '';
        return;
      }

      this.listBoxLoading = true;
      try {
        response = await axios.post('/cmsReq/selectViewReqServiceList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId1)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var i = 0;
        var rowData;
        var ids = $('#' + this.sheetId1).jqGrid('getDataIDs'); // 전체 row id 배열
        if (ids && ids.length > 0) {
          // CCT selectBox 설정
          // for (var i = 0 ; i <= ids.length-1; i++ ){
          //   rowData = $("#"+this.sheetId1).jqGrid('getRowData', ids[i]);
          //   // 첫 검색일때만 CC select option 추가
          //   if (this.isFirstSearch){
          //     let canPush = true; // 저장가능 여부 T/F(중복체크)
          //     for (var j = 0 ; j < this.cctArray.length ; j++){
          //       if (rowData["cct"] == this.cctArray[j].value){
          //         canPush = false;
          //         break;
          //       }
          //     }
          //     if (canPush){
          //       this.cctArray.push({text: rowData["cct"] , value: rowData["cct"]});
          //     }
          //   }
          // }
          // if (this.isFirstSearch){
          //   setTimeout(() => {
          //     $("#searchCct_select").prop("selectedIndex",0);
          //   },100);
          // }
          this.isFirstSearch = false;

          // 포커싱 해주는 부분
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr) {
            for (i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId1).jqGrid('getRowData', ids[i]);

              // 포커싱 정보와 일치하는 행이 있을때
              if (rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr) {
                $('#' + this.sheetId1).jqGrid('setSelection', ids[i], true);

                this.focusKeys.reqstdocNo = rowData.reqstdocNo;
                this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
                this.focusKeys.ctrtNo = rowData.ctrtNo;
                this.focusKeys.ctrtOdr = rowData.ctrtOdr;
                this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
                this.focusKeys.contractType = rowData.contractType;
                this.focusKeys.signType = rowData.signType;

                // 상세조회 START
                this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
                this.onReqDetailSearch();

                // 바로 전자서명 할 시에 oz viewer 띄워줌
                if (this.goSign) {
                  this.onReqView();
                }

                // 해당 행으로 focus 이동
                let rowHeight = 30; // jqgrid 행 기본 높이
                let index = jQuery('#' + this.sheetId1).getInd(ids[i]);
                // 검색 행이 중간으로 갈 수 있도록 설정
                jQuery('#' + this.sheetId1)
                  .closest('.ui-jqgrid-bdiv')
                  .scrollTop(rowHeight * index - 90);
                break;
              }
              // 포커싱 만 존재하고 일치하는 행이 없을때 -> 첫번째 행 선택
              if (
                i == ids.length - 1 &&
                !(rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr)
              ) {
                $('#' + this.sheetId1).jqGrid('setSelection', ids[0], true);
                rowData = $('#' + this.sheetId1).jqGrid('getRowData', ids[0]);

                // 첫번쨰 행 focuskey set
                this.focusKeys.reqstdocNo = rowData.reqstdocNo;
                this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
                this.focusKeys.ctrtNo = rowData.ctrtNo;
                this.focusKeys.ctrtOdr = rowData.ctrtOdr;
                this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
                this.focusKeys.contractType = rowData.contractType;
                this.focusKeys.signType = rowData.signType;

                this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
                this.onReqDetailSearch();
              }
            }
            // 포커싱 정보가 없을때 - 첫번째 행 선택
          } else {
            $('#' + this.sheetId1).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId1).jqGrid('getRowData', ids[0]);

            // 첫번쨰 행 focuskey set
            this.focusKeys.reqstdocNo = rowData.reqstdocNo;
            this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
            this.focusKeys.ctrtNo = rowData.ctrtNo;
            this.focusKeys.ctrtOdr = rowData.ctrtOdr;
            this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
            this.focusKeys.contractType = rowData.contractType;
            this.focusKeys.signType = rowData.signType;

            this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
            this.onReqDetailSearch();
          }
        } else {
          // 검색된 데이터 존재 X
          this.onInitRequestInfo();
          this.onDefaultValSet();
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    // View service 신청서 상세조회 + 신청서 호기 + 고객 조회
    async onReqDetailSearch() {
      let param = {
        reqstdocNo: this.focusKeys.reqstdocNo == '' ? this.focusKeys.ctrtNo : this.focusKeys.reqstdocNo,
        reqstdocOdr: parseInt(this.focusKeys.reqstdocOdr),
      };
      // view Service 상세 조회
      try {
        const response1 = await axios.post('/cmsReq/selectViewReqInfo', param);

        if (response1.data == '') {
          this.onDefaultValSet(); //검색 결과 없을시에 default Set
        } else {
          this.onSetRequestInfo(response1.data); // 검색값 setting
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }

      // 호기정보
      try {
        const response2 = await axios.post('/cmsReq/selectViewReqHogiList', param);
        $('#' + this.sheetId2)
          .clearGridData(true)
          .setGridParam({data: response2.data})
          .trigger('reloadGrid'); // 초기화 후 데이터 load
          this.onResize();
          
        if (response2.data == '') {
          this.onDefaultValSet(); //검색 결과 없을시에 default Set
        } else {
          this.onSetRequestInfo(response2.data); // 검색값 setting
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }

      // 고객정보
      try {
        const response3 = await axios.post('/cmsReq/selectViewReqCstmrList', param);
        $('#' + this.sheetId3)
          .clearGridData(true)
          .setGridParam({data: response3.data})
          .trigger('reloadGrid'); // 초기화
          this.onResize();
        if (response3.data == '') {
          this.onDefaultValSet(); //검색 결과 없을시에 default Set
        } else {
          this.onSetRequestInfo(response3.data); // 검색값 setting
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    // 저장 및 전자서명
    async onSave() {
      // 조회 계약 없을때 저장 하려고 할 시 alert
      var ids = $('#' + this.sheetId1).jqGrid('getDataIDs'); // 전체 row id 배열
      if (ids.length < 1) {
        this.$alert('신청서를 저장하기 위한 계약정보가 존재하지 않습니다.<br>목록을 다시 조회해주세요.');
        return;
      }

      // 변경시에 알림
      if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd != '10') {
        if (!(await this.$confirm('변경시 작성된 정보로 새로운 신청서가 작성됩니다.<br>변경신청 하시겠습니까?')))
          return;
      }
      /*  ===============================
       *  onSave() validation CHECK START
       *  =============================== */

      console.log('req No : ', this.requestInfo.reqstdocNo);
      console.log('req Odr : ', this.requestInfo.reqstdocOdr);

      let saveIds2 = $('#' + this.sheetId2).jqGrid('getDataIDs'); // 호기 sheet 전체 row id 배열
      let saveIds3 = $('#' + this.sheetId3).jqGrid('getDataIDs'); // 고객 sheet 전체 row id 배열
      let rowData = '';

      /* ===== 1. 호기 CHECK ===== */
      this.requestInfo.hogiAllNm = ''; // 호기명 전체 초기화
      if (saveIds2.length < 1) {
        this.$alert('View 서비스를 신청할 호기명을 하나 이상 등록해주세요.');
        return;
      } else {
        // 공백 검사
        for (var i = 0; i <= saveIds2.length - 1; i++) {
          rowData = $('#' + this.sheetId2).jqGrid('getCell', saveIds2[i], 'hogiNm');

          if (rowData == '') {
            this.$alert('호기명을 입력해 주세요.');
            return;
            // 호기 이름 제한 40자
          } else if (rowData.length > 40) {
            this.$alert('호기명을 40자 이내로 입력해주세요. <br><br> 호기 대상명 : ' + rowData);
            return;
          }
          // 호기명이 정상 입력되었다면 저장
          if (this.requestInfo.hogiAllNm == '') {
            this.requestInfo.hogiAllNm = rowData;
          } else {
            this.requestInfo.hogiAllNm = this.requestInfo.hogiAllNm + ', ' + rowData;
          }
        }
      }

      /* ===== 2. 고객정보 CHECK ==== */
      if (saveIds3.length < 1) {
        this.$alert('View 서비스를 신청할 고객 정보를 하나 이상 등록해주세요.');
        return;
      } else {
        // eslint-disable-next-line
        for (var i = 0; i <= saveIds3.length - 1; i++) {
          // 휴대폰번호 체크
          let telNoPattern = /^(01+[(0-9)]*$)/; // 휴대폰번호 정규식 (01x 번호만 검색)
          rowData = $('#' + this.sheetId3).jqGrid('getCell', saveIds3[i], 'chrgTelno');
          rowData = rowData
            .replace(/ /gi, '')
            .replace(/-/gi, '')
            .replace(/\(/gi, '')
            .replace(/\)/gi, '');

          if (rowData == '') {
            this.$alert('휴대폰 번호를 입력해주세요.');
            return;
          } else {
            // 휴대폰 번호 양식 검사
            if (!telNoPattern.test(rowData) || !(rowData.length == 10 || rowData.length == 11)) {
              this.$alert('SMS를 수신 가능한 휴대폰 번호를 입력해주세요. ( - 없이 입력해 주세요 )');
              return;
            }
          }
          // 이름 체크
          rowData = $('#' + this.sheetId3).jqGrid('getCell', saveIds3[i], 'chrgNm');
          if (rowData == '') {
            this.$alert('이름을 입력해주세요.');
            return;
            // 이름 제한 40자
          } else if (rowData.length > 40) {
            this.$alert('호기명을 40자 이내로 입력해주세요. <br><br> 이름 대상명 : ' + rowData);
            return;
          }
          // 접속호기 체크
          rowData = $('#' + this.sheetId3).jqGrid('getCell', saveIds3[i], 'conectHogiNm');
          if (rowData == '') {
            this.$alert('접속호기를 입력해주세요.');
            return;
            // 접속호기 제한 40자
          } else if (rowData.length > 40) {
            this.$alert('접속호기명을 40자 이내로 입력해주세요. <br><br> 접속호기 대상명 : ' + rowData);
            return;
          }
          // 비고 체크
          rowData = $('#' + this.sheetId3).jqGrid('getCell', saveIds3[i], 'rmCn');
          if (rowData == '') {
            this.$alert('비고를 입력해 주세요.');
            return;
            // 비고 제한 800자
          } else if (rowData.length > 800) {
            this.$alert('비고을 800자 이내로 입력해주세요. <br><br> 비고 대상명 : ' + rowData);
            return;
          }
        }
      }

      /* ===== 3. 신청인(안전관리인) CHECK ===== */
      if (this.requestInfo.applcntNm == '' || this.requestInfo.applcntNm == null) {
        this.requestInfo.applcntNm = this.requestInfo.repntName; // 신청인 존재 안할시에 거래선 대표자명
      }

      /*  ===============================
       *  onSave() validation CHECK END
       *  =============================== */

      /* jqgrid <input> 태그 제거 (edit 상태 -> save 상태로 변경) */
      if (saveIds2 && saveIds2.length > 0) {
        // eslint-disable-next-line
        for (var i = 0; i <= saveIds2.length - 1; i++) {
          $('#' + this.sheetId2).jqGrid('saveRow', saveIds2[i], false, 'clientArray'); // 서버 호출없이 그리드에만 저장
        }
      }

      if (saveIds3 && saveIds3.length > 0) {
        // eslint-disable-next-line
        for (var i = 0; i <= saveIds3.length - 1; i++) {
          $('#' + this.sheetId3).jqGrid('saveRow', saveIds3[i], false, 'clientArray'); // 서버 호출없이 그리드에만 저장
        }
      }

      /* DB insert / update 시작 */
      let response = '';
      let requestInfo = this.requestInfo; // 기본 view service 신청
      let hogiData = $('#' + this.sheetId2).getRowData(); // 호기 정보
      let cstmrData = $('#' + this.sheetId3).getRowData(); // 고객 정보

      console.log('requestInfo data : ', requestInfo);

      let param = {requestInfo, hogiData, cstmrData}; // param 설정 ( Map , List<Map> , List<Map>)

      if (this.isProcessing) {
        alert('신청서 정보를 저장 또는 변경처리 중입니다. 잠시만 기다려 주세요.');
        return;
      }

      try {
        this.listBoxLoading = true;
        this.isProcessing = true;

        // 신규(변경)신청
        // 1. 계약서 차수 X , 작성중(10) --> 신규 신청
        // 2. 계약서 차수 O , 작성중(10) --> 수정 신청
        // 3. 계약서 차수 O , 신청완료(20) --> 변경 신청

        // 신규 신청
        if (this.requestInfo.reqstdocOdr === '' && this.requestInfo.progrsSttusCd == '10') {
          response = await axios.post('/cmsReq/insertViewReq', param); // insert (변경 / 신규 신청)
          // 변경 신청
        } else if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd != '10') {
          response = await axios.post('/cmsReq/insertViewReq', param); // insert (변경 / 신규 신청)
          // 수정 신청
        } else {
          if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd == '10') {
            response = await axios.post('/cmsReq/updateViewReq', param); // update (작성중)
          } else {
            console.log('on save() not in case');
          }
        }

        if (response.data == 'success') {
          this.listBoxLoading = false;
          this.isProcessing = false;
          if (
            !(await this.$confirm(
              'View 작성 정보가 성공적으로 저장되었습니다. <br>전자서명을 완료해야 View 신청이 완료됩니다.<br><br>전자서명을 바로 진행하시겠습니까?<br>( 취소하여도 작성 정보는 저장되어 있습니다. )'
            ))
          ) {
            // 취소 클릭시
            this.onSearch();
          } else {
            // 확인 클릭시 상세조회 완료하면서 oz viewer 시작
            this.goSign = true;
            this.onSearch();
          }
        }
        console.log(response);
      } catch (ex) {
        this.listBoxLoading = false;
        this.isProcessing = false;
        this.$alert('저장에 실패하였습니다.');
        console.log(ex);
      }
    },
    // View 신청 정보 조회
    async onReqView() {
      this.goSign = false;
      // eslint-disable-next-line
      var opt = {};
      const btns = [];

      // 신청서 조회자 서명 정보 조회
      // let signInfoParam = {
      //   ctrtNo: this.focusKeys.ctrtNo,
      //   ctrtOdr: this.focusKeys.ctrtOdr,
      //   ctrtDivCd: this.focusKeys.ctrtDivCd,
      // };
      // eslint-disable-next-line
      // let response2 = await axios.post('/ocontract/selectSignerInfo', signInfoParam);

      // 최초 작성 후 바로 전자서명 하려고 할 시에 번호/차수 넣어줌
      this.focusKeys.reqstdocNo = this.focusKeys.reqstdocNo === '' ? this.focusKeys.ctrtNo : this.focusKeys.reqstdocNo;
      this.focusKeys.reqstdocOdr = this.focusKeys.reqstdocOdr === '' ? 1 : this.focusKeys.reqstdocOdr;

      let param = {
        manageCd: 'R01', // 필수(*):공통 (R01: View , R02: Elite , R03: CMS)
        reqstdocNo: this.focusKeys.reqstdocNo, // 필수(*) : cms, view, elite 신청서일 경우
        reqstdocOdr: this.focusKeys.reqstdocOdr, // 필수(*) : cms, view, elite 신청서일 경우
      };

      // 작성중 상태 + 첫번째 고객일때만 전자서명 버튼 나올 수 있도록 설정
      // if (this.requestInfo.progrsSttusCd == '10' && (eval(response2.data.customer) == true && response2.data.tradeOrder == "A" )){
      //   btns.push(
      //     {
      //       name: '전자서명',
      //       callback: () =>{
      //         popupInterface[opt.name].vue.callEvent({
      //           name: 'RequestViewerPopup_Sign',
      //           param: data => {
      //             console.log('RequestViewerPopup_Sign Data :',data);
      //             opt.window.close();
      //             this.onSignReq(data);
      //           },
      //         });
      //       },
      //     },
      //   );
      // }

      axios
        .post('/ozrpt/selectOzEtcFormService', param)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);

          var signable = 'N'; // 필수(*):디지털 서명가능여부(Y/N)
          var signerSeCd = '10'; // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
          var tradeNo = this.requestInfo.tradeNo; // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)

          this.OZParam = Object.assign({}, response.data.jsonData);
          this.OZParam.reportname = response.data.reportPathName;
          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
            signMthCd: '', // 서명방법 (21.03.17 추가)
          };

          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/applicationForm/components/popup/RequestViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ctrtNo: this.focusKeys.ctrtNo,
              ctrtOdr: '00', // 계약은 0차수 (당초 만 조회)
              ctrtDivCd: this.focusKeys.ctrtDivCd,
              reqstdocNo: this.focusKeys.reqstdocNo,
              reqstdocOdr: this.focusKeys.reqstdocOdr,
              progrsSttusCd: this.requestInfo.progrsSttusCd, // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
              checkContDate: this.requestInfo.checkContDate, // 계약일
              reqstdocDivCd: this.requestInfo.reqstdocDivCd, // 신청서 구분 코드
              otisPage: this.isOtisPage, // 고객/ OTIS 구분
              OZParam: this.OZParam,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'RequestViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '신청서조회', // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    // 신청서 전자서명 후 검색
    async onSignReq(data) {
      console.log(' View onSignReq data :', data);

      let param = this.requestInfo;
      const response = await axios.post('/cmsReq/notiViewReq', param);
      if (response.data == 'success') {
        console.log('noti Complete');
      } else {
        console.log('noti Fail');
      }

      this.$alert('전자서명 및 신청이 완료되었습니다.');
      this.onSearch();

      // 추후 해당 신청서 전자서명정보 삭제 필요시 사용
      // this.requestInfo.agreYn = data.ozVal.agreYn;
      // this.requestInfo.reqstdocNo  = this.focusKeys.reqstdocNo;
      // this.requestInfo.reqstdocOdr = this.focusKeys.reqstdocOdr;
      // let param = this.requestInfo;
      // const response = await axios.post('/cmsReq/deleteViewReqSignInfoByFail', param);
      // if (response.data == "success"){
      //   console.log("del Complete");
      // } else {
      //   console.log("del Fail");
      // }
    },
    // 계약서 조회
    async onCtrtView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);
      // this.$alert('계약서 보기 팝업.');

      let param2 = {
        ctrtNo: data.ctrtNo,
        ctrtOdr: data.ctrtOdr,
        ctrtDivCd: data.ctrtDivCd,
      };
      let response2 = await axios.post('/ocontract/selectSignerInfo', param2);

      console.log(' onCtrtView response2 :', response2);

      var signable = 'N';
      var signerSeCd =
        eval(response2.data.buyagnt) == true ? '20' : eval(response2.data.customer) == true ? '10' : '30';
      var tradeNo =
        eval(response2.data.buyagnt) == false && eval(response2.data.canSign) == true ? response2.data.tradeNo : '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약서 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      //var opt = {};
      const btns = [];
      if (data.docCat1 == '000') {
        // eslint-disable-next-line
        opt = $open({
          componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
          param: {
            ctrtNo: this.focusKeys.ctrtNo,
            ctrtOdr: this.focusKeys.ctrtOdr,
            ctrtDivCd: this.focusKeys.ctrtDivCd,
            docCat1: data.docCat1,
            OZParam: {},
            otisPage: this.isOtisPage,
          }, // 파라미터 (생략가능)
          btns: btns, // 팝업 버튼 (생략가능)
          name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
          option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
          title: '계약서조회', // 팝업 제목 (생략가능)
        });
      } else {
        axios
          .post('/ozrpt/selectOzOContract', param)
          .then(response => {
            //console.log('조회결과 response >>>', response);
            //console.log('조회결과 response.data >>>', response.data);

            this.OZParam = Object.assign({}, response.data.jsonData);

            this.OZParam.reportname = response.data.reportPathName;
            this.OZParam.OZFormParam = {
              signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
              signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
              tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
              signMthCd: '', // 서명방법 (21.03.17 추가)
            };

            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ctrtNo: this.focusKeys.ctrtNo,
                ctrtOdr: this.focusKeys.ctrtOdr,
                ctrtDivCd: this.focusKeys.ctrtDivCd,
                docCat1: data.docCat1,
                OZParam: this.OZParam,
                otisPage: this.isOtisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          })
          .catch(function(error) {
            console.log('========error====================');
            console.log(error);
          });
      }
    },
  },
};
</script>

<style></style>

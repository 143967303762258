import Vue from 'vue';
const Plugin = {
  install(Vue) {
    this.event = new Vue();

    Vue.prototype.$confirm = (msg, confirmCallback, cancelCallback) => {
      let p1 = new Promise((resolve, reject) => {
        Plugin.event.$emit('show', msg, resolve, reject, confirmCallback, cancelCallback);
      });
      return p1;
    };

    /**
     * ITSR55206 전자계약(SCD) 확인서(서면/전자)관리 확인 창 수정개발 요청
     * 20220630
     * lgksja 
     * 체크박스가 있는 것 */
    Vue.prototype.$confirmChk = (msg, confirmCallback, cancelCallback) => {
      let p1 = new Promise((resolve, reject) => {
        Plugin.event.$emit('show2', msg, resolve, reject, confirmCallback, cancelCallback);
      });
      return p1;
    };
  },
};

Vue.use(Plugin);

export default Plugin;

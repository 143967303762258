const state = {
    grpAuthInfo: {
        pgmCd: '', // 회사코드
        grpAuth: '', // 그룹권한
        originGrpAuth: '', // 기존그룹권한
    },
    tabIndex: 0,
    target: 'grp',
};

const getters = {
    grpAuthInfo: state => state.grpAuthInfo,
    tabIndex: state => state.tabIndex,
    target: state => state.target,
};

const actions = {};

const mutations = {
    setGrpAuthInfo(state, payload) {
        for (let key in state.grpAuthInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.grpAuthInfo[key] = payload[key];
            }
        }
    },
    setTabIndex(state, index) {
        state.tabIndex = index;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
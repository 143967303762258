<template>
  <iui-container type="ibsheet" :id="elId" v-if="isPopup" />
  <pmis-content-box v-else>
    <template v-slot:header-left>
      <iui-text
        name="name"
        :label="'성명'"
        :bindArray="bindArray"
        @change="setValue('name', $event)"
        @enter="selectWorkerList(null)"
      />
      <iui-button value="조회" @click="selectWorkerList(null)" />
    </template>
    <template v-slot:header-right>
      <iui-checkbox-group-old
        name="inclslabtClose"
        :items="labtCloseItem"
        :checkedValues="[inclslabtClose]"
        @change="inclslabtClose"
      />
    </template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    isExtend: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'focusKeyCode']);
    $mapMutations(this, ['setSearchInfo', 'setStatus']);
  },
  created() {
    this.addEvent([
      {name: 'selectWorkerList', func: this.selectWorkerList},
      {name: 'onFocusedRowData', func: this.onFocusedRowData},
      {name: 'WorkerList_delete', func: this.onDelete},
    ]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      bindArray: ['setSearchInfo'],
      labtCloseItem: [{value: '', label: '근무종료자포함'}],
      response: [],
      response2: [],
      labtCloseChecked: false,
    };
  },
  async mounted() {
    const me = this;
    const OPT = {
      Cfg: {
        FitWidth: 1,
      },
    };

    // pop
    if (me.isExtend) {
      OPT.Cols = [
        {
          Header: '현장명',
          Name: 'prjNm',
          Type: 'Text',
          RelWidth: 2,
          CanEdit: 0,
          Align: 'left',
        },
        {
          Header: '근로자구분',
          Name: 'laborDs',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
          Format: await $getCodeIBSheetFormat(this, '0045'),
        },
        {
          Header: 'ID',
          Name: 'empNo',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '근로자명',
          Name: 'name',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '성별',
          Name: 'sex',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '생년월일',
          Name: 'birthday',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '작업반명',
          Name: 'subCodeName',
          Type: 'Text',
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
        },
        {Name: 'subCode', Visible: false},
        {Name: 'dtsNo', Visible: false},
        {Name: 'priceLGb', Visible: false},
      ];
    } else {
      OPT.Cols = [
        {
          Header: '선택',
          Type: 'Bool',
          Name: 'cbx',
          Width: 50,
          CanEdit: 1,
          Visible: !me.isPopup,
        },
        {
          Header: 'ID',
          Name: 'empNo',
          Type: 'Text',
          Width: 80,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '성명',
          Name: 'name',
          Type: 'Text',
          Width: 100,
          CanEdit: 0,
          Align: 'center',
        },
        {
          Header: '주민번호',
          Name: 'regiNo',
          Type: 'Text',
          // Width: 120,
          RelWidth: 1,
          CanEdit: 0,
          Align: 'center',
          CustomFormat: 'IdNo' /* IdNo, IdNoMask */,
        },
      ];
      const Def = {
        Row: {
          CanFormula: true,
          CalcOrder: 'TextColor',
          TextColorFormula: function(param) {
            if (param.Row['labtClose']) {
              return '#FF0000';
            }
          },
        },
      };
      OPT.Def = Def;
    }
    me.sheet = IBSheet.create({
      id: me.sheetId,
      el: me.elId,
      options: OPT,
    });

    if (me.isPopup) {
      me.sheet.bind('onDblClick', function(e) {
        if (e.row.Kind == 'Data') {
          me.callEvent({name: `workerSearchOnConfirm${me.uid}`});
        }
      });
    } else {
      me.sheet.bind('onSearchFinish', function() {
        if (me.focusKeyCode != '') {
          let focusRow = {};
          for (let row of me.sheet.getDataRows()) {
            if (me.focusKeyCode == row.empNo) {
              focusRow = row;
              break;
            }
          }
          setTimeout(() => {
            me.sheet.focus(focusRow);
            me.$emit('emitWorkerInfo', focusRow);
          }, 100);
        } else {
          me.$emit('emitWorkerInfo', me.sheet.getFirstRow());
        }
      });

      me.sheet.bind('onClick', function(e) {
        if (e.row.Kind == 'Data' && e.col != 'cbx') {
          me.$emit('emitWorkerInfo', e.row);
        }
      });
    }

    me.selectWorkerList();
  },
  methods: {
    inclslabtClose(e) {
      if (e.target.checked) {
        this.labtCloseChecked = true;
      } else {
        this.labtCloseChecked = false;
      }
      this.selectWorkerList();
    },
    async selectWorkerList(param) {
      const me = this;
      this.response2 = [];
      if (!param) {
        param = {pgmCd: me.pgmCd};
      }
      if (me.isPopup) {
        param = me.$store.getters['modalParam'];
      } else {
        param.name = me.searchInfo.name;
      }
      param.isPopup = me.isPopup;
      await axios.post('/worker/selectWorkerList', param).then(response => {
        this.response = response.data;
        response.data.forEach(el => {
          if (el.labtClose != '000001') this.response2.push(el);
        });
      }); //me.sheet.loadSearchData(response.data));
      if (this.labtCloseChecked) this.sheet.loadSearchData(this.response);
      //근무종료자 포함 o
      else {
        this.sheet.loadSearchData(this.response2); //근무종료자 포함 x
      }
    },
    onFocusedRowData(param) {
      if (typeof param == 'function') {
        param(this.sheet.getFocusedRow());
      }
    },
    async onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다');
        return;
      }

      let workerList = [];
      for (let row of checkedRows) {
        workerList.push({
          pgmCd: row.pgmCd,
          empNo: row.empNo,
        });
      }

      if (await this.$confirm('선택하신 근로자를 삭제하시겠습니까?')) {
        let response = await axios
          .post('/worker/deleteWorkerInfos', {workerList})
          .catch(e => console.error('삭제를 실패하였습니다. : ', e));

        if (response.status == 200) {
          this.selectWorkerList();
        } else {
          this.$alert('삭제를 실패하였습니다.');
        }
      }
    },
    setValue(key, event) {
      let payload = {};
      payload[key] = event.target.value;
      this.setSearchInfo(payload);
    },
  },
};
</script>

<style scoped></style>

<template>
  <!--pmis-page :loading="pageLoading"-->
  <pmis-page>
    <!--template v-slot:searchbox>
      <iui-button value="pmis-page 로딩" @click="page_loading_test" />
      <a href="#">앵커</a>
    </template -->

    <pmis-content-box v-if="true">
      <template v-slot:header-left> </template>
      <template v-slot:header-right>
        <iui-button @click="onProcessPass">패스 실명인증</iui-button>
        <iui-button @click="onSearch">Openbanking인증정보 조회</iui-button>
        <!--iui-button @click="onReqToken">토큰발급</iui-button>
        <iui-button @click="onReqRealName">실명확인</iui-button-->

        <iui-button @click="onRealNamePop">팝업:실명확인</iui-button>
        <iui-button @click="onTokenPop">팝업:토큰발급</iui-button>
        <iui-button @click="onIdFindPop">팝업:ID찾기</iui-button>
        <iui-button @click="onPwFindPop">팝업:Pw찾기</iui-button>
      </template>

      <iui-container type="table" theme="bullet">
        <i-row height="30px">
          <i-col-header style="width:10%">
            API Key
          </i-col-header>
          <i-col style="width:90%">
            <iui-text type="text" id="client_id" :value.sync="cmsData.clientId" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            API Secret
          </i-col-header>
          <i-col>
            <iui-text type="text" :value.sync="cmsData.clientSecret" />
          </i-col>
        </i-row>
        <i-row height="100px">
          <i-col-header>
            Access Token
          </i-col-header>
          <i-col>
            <iui-text type="multi" id="access_token" rows="4" :value.sync="cmsData.accessToken" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            토큰발행일시
          </i-col-header>
          <i-col>
            <iui-text type="text" id="tknIssuDt" :value.sync="cmsData.tknIssuDt" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            은행거래고유번호
          </i-col-header>
          <i-col>
            <iui-text type="text" id="bank_tran_id" :value.sync="cmsData.bankTranId" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            요청일시
          </i-col-header>
          <i-col>
            <iui-text type="text" id="tran_dtime" :value.sync="cmsData.currentDt" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            계좌번호
          </i-col-header>
          <i-col>
            <iui-text type="text" id="account_num" value="123456789088" />
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header>
            개설기관.표준코드
          </i-col-header>
          <i-col>
            <iui-select-old name="bank_code_std" :items="openBankCd" value="097"></iui-select-old>
            <!--iui-text @change="setListSearchInfo({searchNm : $event.target.value}) " width="150px" /-->
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header style="width:10%">
            예금주실명번호<br />
            구분코드
          </i-col-header>
          <i-col style="width:30%">
            <iui-select-old name="account_holder_info_type" :items="accountHolderTypeCd" value=" "></iui-select-old>
            <!--<iui-text @change="setListSearchInfo({searchNm : $event.target.value}) " width="150px" />-->
          </i-col>
        </i-row>
        <i-row height="30px">
          <i-col-header style="width:10%">
            예금주실명번호
          </i-col-header>
          <i-col style="width:50%">
            <iui-text type="text" name="account_holder_info" value="880101"></iui-text>
          </i-col>
        </i-row>
      </iui-container>
    </pmis-content-box>

    <iui-modal-old name="openBankingToken" title="토큰재발급" width="600px" height="350px" :btns="modalTokenBtns">
      <openBankingToken :cmsData="cmsData" />
    </iui-modal-old>

    <iui-modal-old
      name="openBankingRealName"
      title="계좌실명확인"
      width="600px"
      height="500px"
      :btns="modalRealNameBtns"
    >
      <openBankingRealName :cmsData="cmsData" />
    </iui-modal-old>

    <!-- 21-02-02 이동원 추가 -->
    <iui-modal-old name="idFind" title="아이디찾기" width="600px" height="550px">
      <UserIdFind />
    </iui-modal-old>

    <iui-modal-old name="pwFind" title="비밀번호찾기" width="600px" height="550px">
      <UserPwFind :userId="searchId" />
    </iui-modal-old>
  </pmis-page>
</template>

<script>
// import Editor from '@tinymce/tinymce-vue';
//import {PMIS_FILE_LIST_DEFAULT_COLUMNS} from '@/components/PmisFileList.vue';
//import Vue from "vue";
//import moment from "moment";
//import VueMomentJS from "vue-momentjs";
//Vue.use(require('vue-moment'));
//Vue.use(VueMomentJS, moment);
//Vue.use(moment);

//import VueResource from 'vue-resource'
//Vue.use(VueResource);

import VueCookies from 'vue-cookies';
// eslint-disable-next-line
import store from '@/store/index.js';

// modal 팝업창 오픈 테스트.
import openBankingToken from './popup/OpenBankingTokenPop'; //<== modal 팝업 추가.
import openBankingRealName from './popup/OpenBankingRealNamePop'; //<== modal 팝업 추가.

// 21-02-02 이동원 테스트
import UserIdFind from '../../user/find/UserIdFind.vue';
import UserPwFind from '../../user/find/UserPwFind.vue';

export default {
  components: {
    openBankingToken, //<== modal 팝업 추가.
    openBankingRealName, //<== modal 팝업 추가.
    // 21-02-02 이동원 modal 팝업 추가
    UserIdFind,
    UserPwFind,
  },
  beforeCreate() {
    //$init(this, store);
  },
  data() {
    return {
      searchId: '',
      OrgAuthToken: this.postHeader().Authorization,
      modalTokenBtns: [{name: '확인', callback: this.onPopConfirmToken}],
      modalRealNameBtns: [{name: '확인', callback: this.onPopConfirmRealName}],
      cmsData: {
        inputDivCd: '',
        clientUseCode: '',
        clientId: '',
        clientSecret: '',
        accessToken: '',
        refreshToken: '',
        expiresIn: '',
        tknIssuDt: '',
        scope: '',
        entryEmpId: '',
        entryDate: '',
        chngEmpID: '',
        chngDate: '',
        chngPgmId: '',
        bankTranId: '',
        currentDt: '',
        userAuthKey: this.postHeader().Authorization,
      },
      openBankCd: [
        {value: '', text: '- 선택 -'},
        {value: '002', text: 'KDB산업은행'},
        {value: '003', text: 'IBK기업은행'},
        {value: '004', text: 'KB국민은행'},
        {value: '007', text: '수협중앙회'},
        {value: '011', text: 'NH농협은행'},
        {value: '020', text: '우리은행'},
        {value: '023', text: 'SC제일은행'},
        {value: '027', text: '한국씨티은행'},
        {value: '031', text: '대구은행'},
        {value: '032', text: '부산은행'},
        {value: '034', text: '광주은행'},
        {value: '035', text: '제주은행'},
        {value: '037', text: '전북은행'},
        {value: '039', text: '경남은행'},
        {value: '045', text: '새마을금고'},
        {value: '050', text: '상호저축은행'},
        {value: '081', text: 'KEB하나은행'},
        {value: '088', text: '신한은행'},
        {value: '089', text: '케이뱅크'},
        {value: '090', text: '카카오은행'},
        {value: '097', text: '오픈은행'},
      ],
      accountHolderTypeCd: [
        {value: '', text: '- 선택 -'},
        {value: ' ', text: '생년월일(6자리)'},
        {value: '1', text: '주민등록번호'},
        {value: '2', text: '외국인등록번호'},
        {value: '3', text: '국내거소신고번호'},
        {value: '4', text: '조합주민번호'},
        {value: '5', text: '여권번호'},
        {value: '6', text: '사업자등록번호'},
        {value: 'E', text: '기타(외국인투자등록증번호 등)'},
      ],
    };
  },
  computed: {
    test2() {
      // return this.$store.getters['code2/getCodeByAlias']('FIX_Y');
      return this.$store.state.code2.codeAlias.USE_CLOSE_Y;
    },
  },

  async created() {
    console.group(`[Sample1.vue] created`);
    console.log(this);
    console.groupEnd();

    // this.items = await $getCode(this, '0260');

    this.items = [
      {key: '1', value: 'v1', text: 't1t1t1t1t1t1t1t1t1'},
      {key: '2', value: 'v2', text: 't2t2t2t2t2t2t2t2t2', enable: false},
      {key: '3', value: 'v3', text: 't3t3t3t3t3t3t3t3t3'},
      {key: '4', value: 'v4', text: 't4t4t4t4t4t4t4t4t4', enable: false},
      {key: '5', value: 'v5', text: 't5t5t5t5t5t5t5t5t5'},
    ];

    //시작호출
    this.onSearch();
  },
  mounted() {
    /*
    window.addEventListener("message", function(ev) {
      console.log("hahah "+JSON.stringify(ev.data));
    });
    */
    this.$secuKit.checkReady();
    // console.log(this.CODE_ALIAS);
    // console.log(this.test2);
    // console.log(this.test2.code);
    // console.log(this.test2.codeNm);
    // console.log(IBSheet.version());
  },
  methods: {
    postHeader() {
      let authorization = '';

      if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
        authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD' + '_accessToken');
      }

      return {Authorization: authorization};
    },
    page_loading_test() {
      var me = this;

      me.pageLoading = true;

      setTimeout(() => {
        me.pageLoading = false;
      }, 3000);
    },
    content_box_loading_test() {
      var me = this;

      me.contentBoxLoading = true;

      setTimeout(() => {
        me.contentBoxLoading = false;
      }, 3000);
    },

    onSearch() {
      let param = {
        inputDivCd: this.cmsData.inputDivCd,
      };

      //this.cmsData.userAuthKey = this.postHeader().Authorization;

      //console.log( 'Authorization===>', this.postHeader() );
      console.log('[조회]OrgAuthToken===>', this.OrgAuthToken);
      console.log('[조회]this.Authorization===>', this.postHeader().Authorization);

      //let response = axios.post('/selectCmsBase', param);
      //console.log('cms 조회====>', response.data.clientUseCode);
      //let myResHeader = XMLHttpRequest.getResponseHeader('Authorization');
      //console.log('myResHeader===>',myResHeader);

      axios.post('/cms/selectCmsBase', param).then(response => {
        //console.log('cms 조회결과 Header 1====>', response.headers);

        this.cmsData.clientId = response.data.cmsData.clientId;
        this.cmsData.clientSecret = response.data.cmsData.clientSecret;
        this.cmsData.bankTranId = response.data.cmsData.bankTranId;
        this.cmsData.accessToken = response.data.cmsData.accessToken;
        this.cmsData.tknIssuDt = response.data.cmsData.tknIssuDt;
        this.cmsData.clientUseCode = response.data.cmsData.clientUseCode;
        this.cmsData.scope = response.data.cmsData.scope;
        this.cmsData.currentDt = response.data.cmsData.currentDt;

        //console.log('response.data.bankTranId==',response.data.cmsData.bankTranId);
        //console.log('this.cmsData.bankTranId==',this.cmsData.bankTranId);
      });
    },
    //토큰발급
    onReqToken() {
      console.log('onReqToken 토큰발급 시작~~');

      var me = this;

      //var axios = require('axios');
      var qs = require('qs'); //querystring
      var data = qs.stringify({
        client_id: this.cmsData.clientId,
        client_secret: this.cmsData.clientSecret,
        scope: 'oob',
        grant_type: 'client_credentials',
      });
      var config = {
        method: 'post',
        url: 'https://testapi.openbanking.or.kr/oauth/2.0/token',
        // headers: {
        //   'Content-Type': 'application/x-www-form-urlencoded',
        //   'Authorization' : 'Bearer '
        // },
        data: data,
      };

      axios(config)
        .then(function(response) {
          console.log('받은해더:' + response.headers); //XMLHttpRequest.getResponseHeader('Authorization'));
          console.log('토큰데이터 수신 1==', response.data);
          //console.log('토큰데이터 수신 2==', JSON.stringify(response.data));

          //발급받은 토큰을 DB에 저장한다.
          // console.log(response.data.access_token);  //access_token
          // console.log(response.data.expires_in); //Access Token 만료 기간(초)
          // console.log(response.data.client_use_code); //이용기관코드

          me.cmsData.accessToken = response.data.access_token;
          me.cmsData.expiresIn = response.data.expires_in;
          me.cmsData.scope = response.data.scope;

          alert('토큰정보가 생성되었습니다.');

          //this.$emit('callbackToken', response.data);
          //this.callEvent({name: 'callbackToken', param:  response.data});

          me.callbackToken(response.data);

          //콜백함수 호출
          //this.callEvent({name: 'callbackToken', param: JSON.stringify(response.data) });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    onSaveToken() {
      //console.log('calback Token =>', resData, typeof resData);
      //console.log('this.Authorization =>', this.Authorization);

      console.log('[저장]저장한넘==' + this.OrgAuthToken);
      console.log('[저장]현재==' + this.postHeader().Authorization);
      // if(this.OrgAuthToken !== this.postHeader().Authorization){
      //   axios.defaults.headers.common.Authorization = this.OrgAuthToken;

      // // const accessTokenKey = store.getters['login/accessTokenKey'];
      // // const accessToKenExpires = store.getters['login/accessToKenExpires'];
      // // const refreshTokenKey = store.getters['login/refreshTokenKey'];
      // // const refreshTokenExpires = store.getters['login/refreshTokenExpires'];

      // // console.log('accessTokenKey='+accessTokenKey);
      // // console.log('response.data.token_type='+response.data.token_type);
      // // console.log('response.data.access_token='+response.data.access_token);
      // // console.log('accessToKenExpires='+accessToKenExpires);
      //   VueCookies.set(store.getters['login/accessTokenKey'], this.OrgAuthToken, store.getters['login/refreshTokenExpires']);

      // }

      console.log('[저장]설정된넘2==' + axios.defaults.headers.common.Authorization);

      // if (resData == undefined || resData == null ) {
      //   return;
      // }
      var me = this;
      let param = {
        inputDivCd: 'CMS',
        // accessToken: resData.access_token,
        // expiresIn : resData.expires_in,
        accessToken: this.cmsData.accessToken,
        expiresIn: this.cmsData.expiresIn,
        refreshToken: '',
        scope: this.cmsData.scope,
      };
      var config = {
        method: 'post',
        url: '/cms/updateCmsToken',
        // headers: {
        //   //'Content-Type': 'application/x-www-form-urlencoded',
        //   //'Authorization' : this.Authorization
        // },
        data: param,
      };

      axios(config)
        .then(function(response) {
          console.log('받은해더:' + response.headers); //XMLHttpRequest.getResponseHeader('Authorization'));
          console.log('토큰데이터 수신 1==', response.data);
          alert('토큰정보가 저장되었습니다.');

          me.onSearch();
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });

      // axios
      // .post('/cms/updateCmsToken', param
      // ,{ 'Authorization': this.Authorization}
      // ).then((response) => {
      //   console.log('cms 등록 결과====>', response);

      //   alert('토큰정보가 저장되었습니다.');
      //   me.onSearch();

      // })
      // .catch(function (error) {
      //   console.log('========error====================');
      //   console.log(error);
      // });
    },
    //계좌실명확인
    onReqRealName() {
      console.log('go~~1');
      //let response = await
      //this.replyList = response.data.list;
      axios
        .post(
          'https://testapi.openbanking.or.kr/v2.0/inquiry/real_name',
          {
            bank_tran_id: 'T991667760U000022345',
            tran_dtime: '20201110121533', //"요청전문 포맷 에러 [tran_dtime 이 날짜시간 형식(yyyyMMddHHmmss) 에 맞지 않습니다.]"
            bank_code_std: '097',
            account_num: '123456789088',
            account_holder_info_type: '',
            account_holder_info: '880101',
          },
          {
            headers: {
              Authorization:
                'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJUOTkxNjY3NzYwIiwic2NvcGUiOlsib29iIl0sImlzcyI6Imh0dHBzOi8vd3d3Lm9wZW5iYW5raW5nLm9yLmtyIiwiZXhwIjoxNjEyNzUwNTM2LCJqdGkiOiIzNmE1MzUzYS1jZDRjLTQzOTMtYmMxYS1iZjg5OGMzOWM1MjAifQ.2Wh_oaWwDnBciAqtCq0HcSwVpO8Fkf5OlbZhPsa4aCs',
              'Content-Type': 'application/json; charset=UTF-8',
            },
          }
        )
        .then(response => {
          console.log('response=>>>', response);
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },

    /**
     *
     */
    onTokenPop() {
      this.$modal.show('openBankingToken');

      // const me = this;
      // let win = window.open(
      //   //process.env.VUE_APP_BASE_URL + '/popup/OpenBankingTokenPop.vue',
      //   './popup/OpenBankingTokenPop.vue',
      //   'jusoPop',
      //   'width=570,height=420, scrollbars=yes, resizable=yes'
      // );
      // var interval = window.setInterval(function () {
      //   try {
      //     if (win == null || win.closed) {
      //       window.clearInterval(interval);
      //       if (me.callback && Object.keys(me.$store.getters.externalData).length) {
      //         //me.callback(me.$store.getters.externalData);
      //         //me.$store.dispatch('setExternalData', {});
      //       }
      //     }
      //   } catch (e) {
      //     console.error(e);
      //   }
      // }, 1000);
    },
    onRealNamePop() {
      this.$modal.show('openBankingRealName');
    },
    // 21-02-02 이동원
    onIdFindPop() {
      this.$modal.show('idFind');
    },
    onPwFindPop() {
      this.searchId = '';
      this.$modal.show('pwFind');
    },

    onPopConfirmToken() {
      this.callEvent({
        name: 'OpenBankingTokenPop_popConfirm',
        param: data => {
          this.$modal.hide('openBankingToken');
          //this.selectPrList();
          //console.log('pop data>', data);
          this.cmsData.accessToken = data.accessToken;
          this.cmsData.expiresIn = data.expiresIn;

          //this.onSaveToken(); //저장기능.
          this.onSearch();
        },
      });
    },
    onPopConfirmRealName() {
      //this.$alert('여기서 콜백함수가 호출되어진다.');
      this.callEvent({
        name: 'OpenBankingRealNamePop_popConfirm',
        param: data => {
          this.$modal.hide('openBankingRealName');
          //this.selectPrList();
          console.log('openBankingRealName pop data>>>>', data);
        },
      });
    },
    /* 20210112 pass */
    onProcessPass() {
      var host = `${process.env.VUE_APP_BASE_URL}`;
      var url = host + '/niceid/process_start.do';
      var win = window.open(
        url,
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );
      win.focus();

      window.addEventListener(
        'message',
        function(ev) {
          if (ev.origin == host) {
            if (ev.data.errorCode != 0) {
              // 에러 : 에러메시지 표시
              alert(ev.data.errorMessage);
            } else {
              /* 
            성공
            ev.data.xxxx 로 값 조회하여 사용
              passNo          : 순번( T_RB_PASS_RESULT 패스번호 )
              cipherDatetime  : 암호화시간 YYMMDDHHMISS
              reqSeq          : 요청번호
              authType        : 인증방식
              resSeq          : 응답번호
              name            : 이름
              birthdate       : 생년월일
              gender          : 성별(남자-1, 여자-2)
              nationalinfo    : 외국인여부(0-내국인, 1-외국인)
              di              : DI
              ci              : CI
              mobileCo        : 휴대폰회사(3자리)
              mobileNo        : 휴대폰번호
            */

              alert(JSON.stringify(ev.data));
            }
          }
        },
        {once: true}
      ); // 한번만 호출
    },
  },
};
</script>

<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row height="330px">
        <i-col>
          <ContractList otis-page complete-page @click="onClickListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-content-box :loading="contentBoxLoading">
            <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from '@/view/contract/tContract/store/tContract.js';
import ContractList from '../../components/ContractList.vue';
export default {
  name: 'TContractComplete',
  components: {
    // eslint-disable-next-line
    ContractList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'contractList']);
    $mapMutations(this, [
      'setSearchInfo',
      'setContractList',
      'setContractInfo',
      'setItemList',
      'setHistoryList', //20221202 ITSR62600
      'setPaymentCondForStdList',
      'setPaymentCondForNonStdList',
      'setMemberList',
      'setStamptaxList',
      'setGuaranteeList',
      'setEtcDocList',
      'resetAllValues',
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '계약대상자',
          compNm: 'TContractComplete_Members',
          path: '@/view/contract/tContract/components/tab/Members.vue',
        },
        {
          tabNm: '계약정보',
          compNm: 'TContractComplete_ContractInfo',
          path: '@/view/contract/tContract/components/tab/ContractInfo.vue',
        },
        {
          tabNm: '교체부품내역',
          compNm: 'TContractComplete_Items',
          path: '@/view/contract/tContract/components/tab/Items.vue',
        },
        {
          tabNm: '대금의 지급방법',
          compNm: 'TContractComplete_PaymentMethod',
          path: '@/view/contract/tContract/components/tab/PaymentMethod.vue',
        },
        {
          tabNm: '인지세 및 부속서류',
          compNm: 'TContractComplete_EtcDocuments',
          path: '@/view/contract/components/tab/EtcDocuments.vue',
        },
        {
          tabNm: '진행 이력',
          compNm: 'OContractcomplete_History',
          path: '@/view/contract/components/tab/HistoryList.vue', //20221202 ITSR62600
        },
      ],
      tabIndex: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      contentBoxLoading: false,
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'Contract_SearchDetail', func: this.onSearchDetail});
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    this.onSearch();
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'TContractList_Search'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },
    async onPrint() {
      this.$store.dispatch('mdi/doPrint');
    },

    onClickListRow(row) {
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;
      this.onSearchDetail();
    },
    async onSearchDetail() {
      this.contentBoxLoading = true;
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        isProgressPage: false,
        isCompletePage: true,
        isCustomerPage: false,
      };
      let response = await axios.post('/tcontract/selectAllContractInfo', param);
      this.contentBoxLoading = false;

      this.setContractInfo(response.data.contractInfo);
      this.setItemList(response.data.itemList);
      this.setHistoryList(response.data.historyList); //20221202 ITSR62600
      this.setPaymentCondForStdList(response.data.paymentCondForStdList);
      this.setPaymentCondForNonStdList(response.data.paymentCondForNonStdList);
      this.setMemberList(response.data.memberList);
      this.setStamptaxList(response.data.stamptaxList);
      this.setGuaranteeList(response.data.guaranteeList);
      this.setEtcDocList(response.data.etcDocList);

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'TContractTabMembers_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'TContractTabContractInfo_Load', param: undefined});
      } else if (this.tabIndex == 2) {
        this.callEvent({name: 'TContractTabItems_Load', param: undefined});
      } else if (this.tabIndex == 3) {
        this.callEvent({name: 'TContractTabPaymentMethod_Load', param: undefined});
      } else if (this.tabIndex == 4) {
        this.callEvent({name: 'ContractTabEtcDocuments_Load', param: undefined});
      } else if (this.tabIndex == 5) {
        this.callEvent({name: 'OContractTabHistoryList_Load', param: undefined}); //20221202 ITSR62600
      }
    },
    onResetValues() {
      console.log('onResetValues');
      this.resetAllValues();
      this.onCallEvent();
    },
  },
};
</script>

<style scoped></style>

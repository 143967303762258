<template>
  <pmis-page
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @search-company-changed="onCompanyChanged"
    @search-click="onSearch"
    @save-click="onSave"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col width="550px">
          <pmis-tab-box no-border-box no-padding-box>
            <template v-slot:title>사용자목록</template>
            <UserList />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <iui-tab-old :comps="comps" :keepAlive="true" @click="setTabIndexS"></iui-tab-old>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import UserList from './components/UserList.vue';
import store from './store/userAuthRegist.js';
export default {
  components: {UserList},

  data() {
    return {
      comps: [
        {
          tabNm: '권한그룹',
          compNm: 'authGrp',
          path: '@/view/sysManage/authManage/userAuthRegist/components/UserGroupList.vue',
        },
        {
          tabNm: '메뉴정보',
          compNm: 'menuInfo',
          path: '@/view/sysManage/authManage/userAuthRegist/components/GrpMenuAuthList.vue',
        },
      ],
    };
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['tabIndex', 'grpAuthInfo', 'searchInfo', 'empNo']);
    $mapMutations(this, ['setTabIndex', 'setSearchInfo']);
  },

  created() {
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd, frmNm: this.userInfo.frmNm});
  },

  methods: {
    onSearch() {
      this.callEvent({name: 'UserList_selectUserList'});
    },
    onSave() {
      if (this.tabIndex == 0) {
        this.callEvent({
          name: 'UserGroupList_getSaveJson',
          param: data => {
            data.forEach(el => {
              el.auth = el.auth == 1 ? true : false;
              el.grpAuth = el.grpAuth;
              el.empNo = this.empNo;
            });
            axios.post('/userAuth/saveUserGroupR', {userAuthList: data}).then(response => {
              if (response.status == 200) {
                this.callEvent({name: 'UserGroupList_selectUserGroupRList', param: this.empNo});
              }
            });
          },
        });
      } else {
        this.callEvent({
          name: 'GrpMenuAuthList_grpMenuAuthSaveJson',
          param: data => {
            data.forEach(el => {
              (el.pgmCd = this.searchInfo.pgmCd), (el.empNo = this.empNo);
            });

            axios.post('/userAuth/saveUserMenuAuth', {userAuthList: data}).then(response => {
              if (response.status == 200) {
                this.callEvent({name: 'GrpMenuAuthList_selectUserMenuAuthList', param: this.empNo});
              }
            });
          },
        });
      }
    },
    setTabIndexS(index) {
      this.setTabIndex(index);
      if (this.tabIndex == 0) {
        this.callEvent({name: 'UserGroupList_selectUserGroupRList', param: this.empNo});
      } else {
        setTimeout(() => {
          this.callEvent({name: 'GrpMenuAuthList_selectUserMenuAuthList', param: this.empNo});
        }, 100);
      }
    },
    onCompanyChanged(e) {
      this.setSearchInfo({pgmCd: e.pgmCd});
      this.callEvent({name: 'selectGrpAuthUserList'});
    },
  },
};
</script>

<style></style>

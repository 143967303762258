<script>
import {defineComponent, getCurrentInstance, reactive, onMounted} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup.js';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js';
import dialog1 from './dialog1.vue';
import RecoveryPopup from '@/view/common/components/RecoveryPopup.vue';

export default defineComponent({
  components: {dialog1, RecoveryPopup},
  setup() {
    const instance = getCurrentInstance();

    const codeList = {
      code1: [
        {text: '명칭1', value: '값1'},
        {text: '명칭2', value: '값2'},
        {text: '명칭3', value: '값3'},
        {text: '명칭4', value: '값4'},
        {text: '명칭5', value: '값5'},
      ],

      code2: [
        {txt: '명칭1', val: '값1'},
        {txt: '명칭2', val: '값2'},
        {txt: '명칭3', val: '값3'},
        {txt: '명칭4', val: '값4'},
        {txt: '명칭5', val: '값5'},
      ],

      country: [
        {text: '미국', value: '미국'},
        {text: '일본', value: '일본'},
        {text: '한국', value: '한국'},
        {text: '영국', value: '영국'},
        {text: '캐나다', value: '캐나다'},
        {text: '중국', value: '중국'},
        {text: '프랑스', value: '프랑스'},
        {text: '브라질', value: '브라질'},
        {text: '인도', value: '인도'},
        {text: '이탈리아', value: '이탈리아'},
      ],
    };

    const state = reactive({
      loading: false,

      text1: undefined,
      text2: undefined,
      text3: undefined,
      text4: undefined,

      date1: '20221212',
      date2: undefined,
      date3: undefined,
      date4: undefined,

      value1: undefined,
      multiValue1: undefined,

      select1: undefined,
      select2: undefined,

      checkbox1: undefined,
      checkbox2: undefined,

      radio1: undefined,
      radio2: undefined,

      testValue: undefined,

      popupViewer: {
        ctrtNo: '2021F  0299',
        ctrtOdr: '1',
        ctrtDivCd: 'FOD_CT1',
        tradeNo: undefined,
        tradeSfx: undefined,
      },

      linkViewer: {
        url: undefined,
      },
    });

    const pageButtonSetup = {
      search_click(e) {
        console.log('search_click', e);
      },
      new_click(e) {
        console.log('new_click', e);
      },
      save_click(e) {
        console.log('save_click', e);
      },
      delete_click(e) {
        console.log('delete_click', e);
      },
      print_click(e) {
        console.log('print_click', e);
      },
      excel_click(e) {
        console.log('excel_click', e);
      },
    };

    const buttonSetup = {
      btnLoadingToggle_click() {
        state.loading = !state.loading;
      },
      btn1_click() {
        alert('btn1_click');
      },
      async btnAlert_click() {
        await instance.proxy.$alert('alert');

        console.log('alert 이후 실행');
      },
      async btnConfirm_click() {
        const r = await instance.proxy.$confirm('confirm');

        alert(r);
      },
    };

    onMounted(() => {
      createIBChart('divIBChart', 'ibChart', {
        width: '270px',
        height: '270px',
      });

      // var data = {
      //   ibchart: {
      //     backgroundColor: 'white',
      //     borderWidth: '1',
      //     borderColor: '#000',
      //     zoomType: 'x',
      //     title: '타이틀',
      //     subtitle: '부제목',
      //     data: [
      //       {
      //         axisLabel: '1/4분기',
      //         series: [
      //           {
      //             seriesType: 'line',
      //             seriesName: '중국',
      //             seriesColor: 'blue',
      //             pointName: '하나',
      //             pointColor: 'yellow',
      //             value: 100,
      //           },
      //           {
      //             seriesType: 'column',
      //             seriesName: '호주',
      //             seriesColor: '#DDDDFF',
      //             pointName: '열하나',
      //             pointColor: '#C6E2FF',
      //             value: 100,
      //           },
      //           {
      //             seriesType: 'spline',
      //             seriesName: 'KOREA',
      //             seriesColor: 'green',
      //             sointName: '스물하나',
      //             sointColor: '#EECCFF',
      //             value: 100,
      //           },
      //         ],
      //       },
      //       {
      //         axisLabel: '2/4분기',
      //         series: [200, 200, 200],
      //       },
      //       {
      //         axisLabel: '3/4분기',
      //         series: [100, 100, 100],
      //       },
      //       {
      //         axisLabel: '4/4분기',
      //         series: [200, 200, 200],
      //       },
      //     ],
      //   },
      // };

      // window.ibChart.loadSearchData(data, {
      //   append: true,
      // });

      // window.ibChart.setOptions(
      //   {
      //     chart: {
      //       type: 'column',
      //     },
      //     series: [
      //       {
      //         name: '매출목표',
      //         data: [10, 32, 15, 53, 18, 22],
      //         color: '#0B843D',
      //       },
      //       {
      //         name: '매출신장',
      //         data: [10, 40, 30, 60, 100, 89],
      //         color: '#8B849D',
      //       },
      //     ],
      //   },
      //   {
      //     append: true,
      //     redraw: true,
      //   }
      // );

      window.ibChart.setOptions(
        {
          chart: {
            type: 'pie',
          },
          plotOptions: {
            pie: {
              innerSize: '50%',
              startAngle: 30,
            },
          },
          series: [
            {
              data: [
                ['한국', 636],
                ['일본', 75],
                ['중국', 40],
                ['미국', 577],
                ['영국', 105],
                ['프랑스', 20],
              ],
            },
          ],
        },
        {
          append: true,
          redraw: true,
        }
      );
    });

    // dialog1 처리
    // 바로 iui-dialog를 사용할 경우
    const dialog1 = useDialog('dialog1');
    const dialog1Setup = {
      ...dialog1.dialogRef,
      async btnDialog1_click() {
        // 기본 show
        // const r = await refs.dialog1.value.show();

        // show 시 인자로 title 지정
        const r = await dialog1.show({title: `title: ${new Date()}`});

        console.log('dialog1 close', r);
      },
      async dialog1_btn1_click() {
        await instance.proxy.$alert('dialog1_btn1_click');

        dialog1.hide('result');
      },
      dialog1_beforeOpen(e) {
        console.log('dialog1_beforeOpen', e);
      },
      dialog1_opened() {
        console.log('dialog1_opened');
      },
      dialog1_beforeClose(e) {
        console.log('dialog1_beforeClose', e);
      },
      dialog1_closed() {
        console.log('dialog1_closed');
      },
    };

    // dialog2 처리
    // 컴포넌트 형태
    const dialog2 = useDialog('dialog2');
    const dialog2Setup = {
      ...dialog2.dialogRef,
      async btnDialog2_click() {
        const r = await dialog2.show();

        console.log('dialog2 close', r);
      },
    };

    const recoveryPopup = useDialog('recoveryPopup');
    const recoveryPopupSetup = {
      ...recoveryPopup.dialogRef,
      async btnRecoveryPopup_click() {
        const reason = await recoveryPopup.show();

        console.log('회수사유', reason);
      },
    };

    // window popup 처리
    const windowPopup = useWindowPopup();
    let windowPopup1 = undefined;
    const windowPopup1Setup = {
      btnWindowPopup1Open_click() {
        // window.open(url, 윈도우이름, 속성);

        const popup = windowPopup.open({
          name: 'sampleWindowPopup1', // 윈도우이름
          componentPath: '@/view/sample/phase2/components/WinPopup.vue', // 팝업 내 컴포넌트 경로
          width: 700,
          height: 500,
          title: '팝업 제목', // 팝업 제목
          data: {
            v1: state.text1,
            v2: state.text3,
          }, // 팝업 내 컴포넌트에 props로 전달할 값(자동 바인딩)
        });

        // 팝업에서 발생하는 이벤트 수신
        // 팝업에서 이벤트 발생은 props.emitter.emit 사용 -> components/WinPopup.vue 참조
        popup.on('popup-click', e => {
          console.log('popup-click', e);
          state.text1 = e;
        });

        windowPopup1 = popup;
      },

      btnWindowPopup1Close_click() {
        // windowPopup1.windowObject.close();
        windowPopup.close(windowPopup1);
        // windowPopup.close(windowPopup1.uniqueId);
      },
    };

    // sampleViewer
    const viewerWindowPopup = useViewerWindowPopup();
    const sampleViewerPopupSetup = {
      btnSampleViewer_click(type) {
        let data = undefined;

        switch (type) {
          case 'NE':
            data = {
              ctrtNo: 'D1010-0159',
              ctrtOdr: '1',
              ctrtDivCd: 'NE_ET1',
              tradeNo: undefined,
            };
            break;
          case 'FOD':
            data = {
              ctrtNo: '2021F  0299',
              ctrtOdr: '1',
              ctrtDivCd: 'FOD_CT1',
              tradeNo: undefined,
            };
            break;
        }

        const popup = viewerWindowPopup.open(data);

        popup.on('reject', e => {
          console.log('reject', e);
        });

        console.log('btnSampleViewer_click', popup);
      },

      btnPopupViewer_click() {
        const popup = viewerWindowPopup.open(state.popupViewer);

        popup.on('reject', e => {
          console.log('reject', e);
        });

        console.log('btnPopupViewer_click', popup);
      },

      btnPCLinkViewer_click() {
        window.open('http://localhost:8101/' + state.linkViewer.url);
        // http://localhost:8101/m/view.do
      },

      btnMobileLinkViewer_click() {
        window.open('http://localhost:8101/' + state.linkViewer.url + '&deviceType=mobile');
      },
    };

    return {
      codeList,
      state,
      ...pageButtonSetup,
      ...buttonSetup,
      ...dialog1Setup,
      ...dialog2Setup,
      ...recoveryPopupSetup,
      ...windowPopup1Setup,
      ...sampleViewerPopupSetup,
    };
  },
});
</script>

<template>
  <otis-page
    @search-click="search_click"
    @new-click="new_click"
    @save-click="save_click"
    @delete-click="delete_click"
    @print-click="print_click"
    @excel-click="excel_click"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-content-box title-type="tab" :loading="state.loading">
            <template #title>
              title-type="tab"
            </template>
            <template #title-right>
              <iui-button @click="btnLoadingToggle_click">로딩 토글</iui-button>
            </template>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header>텍스트박스</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.text1" />
                </i-col>
                <i-col-header>state.text1</i-col-header>
                <i-col>
                  {{ state.text1 }}
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>텍스트박스(number)</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.text2"
                    type="number"
                    label="금액"
                    prefix="\"
                    suffix="원"
                    :label-width="state.testValue"
                  />
                </i-col>
                <i-col-header>state.text2</i-col-header>
                <i-col>
                  {{ state.text2 }}
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>텍스트박스(multi)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.text3" type="multi" height="200px" />
                </i-col>
                <i-col-header>state.text3</i-col-header>
                <i-col>
                  <pre>{{ state.text3 }}</pre>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>달력</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.date1" suffix="~" />
                  <iui-datepicker v-model="state.date2" />
                  /
                  <iui-datepicker v-model="state.date3" type="month" />
                  /
                  <iui-datepicker v-model="state.date4" type="year" />
                  <button
                    @click="
                      () => {
                        state.testValue = '200px';
                      }
                    "
                  >
                    TEST
                  </button>
                </i-col>
                <i-col-header>state.date1 ~ state.date2</i-col-header>
                <i-col> {{ state.date1 }} ~ {{ state.date2 }} / {{ state.date3 }} / {{ state.date4 }} </i-col>
              </i-row>
              <i-row>
                <i-col-header>콤보박스</i-col-header>
                <i-col>
                  <iui-select v-model="state.value1" :items="codeList.code1" />
                  <iui-select
                    v-model="state.value1"
                    :items="codeList.code2"
                    item-text-alias="txt"
                    item-value-alias="val"
                    placeholder="선택"
                  />
                </i-col>
                <i-col-header>state.value1</i-col-header>
                <i-col>
                  {{ state.value1 }}
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>라디오그룹</i-col-header>
                <i-col>
                  <iui-radio-group v-model="state.value1" :items="codeList.code1" item-direction="column" />
                </i-col>
                <i-col-header>라디오</i-col-header>
                <i-col>
                  <iui-radio v-model="state.value1" label="개별선택5" value="값5" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>라디오그룹</i-col-header>
                <i-col>
                  <iui-radio-group
                    v-model="state.value1"
                    :items="codeList.code2"
                    item-text-alias="txt"
                    item-value-alias="val"
                    item-direction="column"
                  />
                </i-col>
                <i-col-header>state.value1</i-col-header>
                <i-col>{{ state.value1 }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>체크박스그룹</i-col-header>
                <i-col>
                  <iui-checkbox-group v-model="state.multiValue1" :items="codeList.code1" item-direction="column" />
                </i-col>
                <i-col-header>체크박스</i-col-header>
                <i-col>
                  <iui-checkbox
                    v-model="state.multiValue1"
                    label="개별선택5"
                    value="값5"
                    :width="state.testValue"
                    :height="state.testValue"
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>체크박스그룹</i-col-header>
                <i-col>
                  <iui-checkbox-group
                    v-model="state.multiValue1"
                    :items="codeList.code2"
                    item-text-alias="txt"
                    item-value-alias="val"
                    item-direction="column"
                  />
                </i-col>
                <i-col-header>state.multiValue1</i-col-header>
                <i-col>{{ state.multiValue1 }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>버튼</i-col-header>
                <i-col colspan="3">
                  <iui-button @click="btn1_click">버튼1</iui-button>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>alert / confirm</i-col-header>
                <i-col>
                  <iui-button @click="btnAlert_click">alert</iui-button>
                  <iui-button @click="btnConfirm_click">confirm</iui-button>
                </i-col>
                <i-col-header>dialog</i-col-header>
                <i-col>
                  <iui-button @click="btnDialog1_click">dialog1</iui-button>
                  <iui-button @click="btnDialog2_click">dialog2</iui-button>
                  <iui-button @click="btnRecoveryPopup_click">회수</iui-button>
                  <iui-button @click="btnWindowPopup1Open_click">window popup open</iui-button>
                  <iui-button @click="btnWindowPopup1Close_click">window popup close</iui-button>
                </i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-col>
      </i-row>
      <i-row height="300px">
        <i-col>
          <iui-content-box>
            <template #title>
              OZ Viewer
            </template>

            <iui-container type="table">
              <i-row>
                <i-col-header>
                  팝업 뷰어
                </i-col-header>
                <i-col>
                  <iui-button @click="btnSampleViewer_click('NE')">viewer1(NE)</iui-button>
                  <iui-button @click="btnSampleViewer_click('FOD')">viewer2(FOD)</iui-button>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  팝업 뷰어
                </i-col-header>
                <i-col>
                  <iui-button @click="btnPopupViewer_click">보기</iui-button>
                  <iui-textbox v-model="state.popupViewer.ctrtNo" placeholder="ctrtNo" />
                  <iui-textbox v-model="state.popupViewer.ctrtOdr" placeholder="ctrtOdr" />
                  <iui-textbox v-model="state.popupViewer.ctrtDivCd" placeholder="ctrtDivCd" />
                  <iui-textbox v-model="state.popupViewer.tradeNo" placeholder="tradeNo" />
                  <iui-textbox v-model="state.popupViewer.tradeSfx" placeholder="tradeSfx" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>
                  링크 뷰어
                </i-col-header>
                <i-col>
                  <iui-button @click="btnPCLinkViewer_click">보기(PC)</iui-button>
                  <iui-button @click="btnMobileLinkViewer_click">보기(모바일)</iui-button>
                  <iui-textbox v-model="state.linkViewer.url" placeholder="url" />
                </i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-content-box>
            <template #title>
              IBChart
            </template>

            <div id="divIBChart"></div>
          </iui-content-box>
        </i-col>
      </i-row>
    </iui-container>

    <iui-dialog
      ref="dialog1"
      title="title"
      @before-open="dialog1_beforeOpen"
      @opened="dialog1_opened"
      @before-close="dialog1_beforeClose"
      @closed="dialog1_closed"
    >
      <template #button-left>
        button-left
      </template>
      <template #button-center>
        <iui-button @click="dialog1_btn1_click">버튼 클릭 시 창 닫기</iui-button>
      </template>
      <template #button-right>
        button-right
      </template>

      다이얼로그 내용<br />
      닫기 버튼을 안 보이게 하려면 hide-close-button 속성 추가<br />
    </iui-dialog>

    <dialog1 ref="dialog2" :text1="state.text1" />
    <recovery-popup ref="recoveryPopup" type="ne" :contract-key="state" />
  </otis-page>
</template>

<style></style>

<template>
  <pmis-page
    :search-company-visible="false"
    :search-project-visible="false"
    @search-click="onSearch"
    @new-click="onNew"
    @save-click="onSave"
    @delete-click="onDelete"
    @print-click="onPrint"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-text
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>계약명</i-col-header>
                <i-col>
                  <iui-text
                    type="text"
                    name="contractName"
                    :value="omInfo.contractName"
                    :enable="true"
                    @change="onSetValue"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-text
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>계약금액</i-col-header>
                <i-col>
                  <iui-text
                    type="amount"
                    name="contractAmt"
                    :value="omInfo.contractAmt"
                    prefix="\"
                    :enable="true"
                    @change="onSetValue"
                    required
                  />
                  (일금 {{ omInfo.contractAmtKor }}원 정)
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-text
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>계약보증율</i-col-header>
                <i-col>
                  <iui-text
                    type="rate"
                    name="guaranteeRate"
                    prefix="계약금액의"
                    :value="omInfo.guaranteeRate"
                    suffix="%"
                    :enable="true"
                    :width="width150"
                    @change="onSetValue"
                    required
                  />
                  <iui-text
                    type="amount"
                    name="guaranteeAmt"
                    prefix="(보증금"
                    :value="omInfo.guaranteeAmt"
                    suffix="원)"
                    :enable="false"
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-text
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>계약비고</i-col-header>
                <i-col>
                  <iui-text
                    type="multi"
                    name="contractMemo"
                    :value="omInfo.contractMemo"
                    :enable="true"
                    :width="width170"
                    @change="onSetValue"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-select-old
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>과세유형</i-col-header>
                <i-col>
                  <iui-select-old
                    name="taxTypeCd"
                    :items="arrTaxType"
                    :value="omInfo.taxType"
                    :enable="true"
                    @change="onSetValue"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-radio-group-old
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>인지세유무</i-col-header>
                <i-col>
                  <iui-radio-group-old
                    name="stampTaxYn"
                    :value="omInfo.stampTaxYn"
                    :items="arrStampTax"
                    :enable="true"
                    @change="onSetValue"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-checkbox-group-old
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>보증서유무</i-col-header>
                <i-col>
                  <iui-checkbox-group-old
                    name="guaranteeYn"
                    :items="[{label: '보증서유무', value: 'Y'}]"
                    :checkedValues="[omInfo.guaranteeYn]"
                    :enable="true"
                    @change="onSetValue"
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-datepicker-old
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header required>계약기간</i-col-header>
                <i-col>
                  <iui-datepicker-old
                    name="contFrDt"
                    ref="contFrDt"
                    :value="omInfo.contFrDt"
                    :enable="true"
                    @change="onSetValue($event, 'contFrDt')"
                    required
                  />
                  <iui-datepicker-old
                    name="contToDt"
                    ref="contToDt"
                    prefix="~"
                    :value="omInfo.contToDt"
                    :enable="true"
                    @change="onSetValue($event, 'contToDt')"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-button
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>버튼</i-col-header>
                <i-col>
                  <iui-button value="버튼" @click="onClickButton()" />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-searchbox
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>고객</i-col-header>
                <i-col>
                  <iui-searchbox
                    :type="'customer'"
                    :paramObj="{customerYn: 'Y'}"
                    :callback="onCallbackCustomerCode"
                    :init.sync="searchboxInit"
                    required
                  />
                </i-col>
                <i-col-header>협력회사</i-col-header>
                <i-col>
                  <iui-searchbox
                    :type="'customer'"
                    :paramObj="{customerYn: 'N'}"
                    :callback="onCallbackCustomerCode"
                    :init.sync="searchboxInit"
                    required
                  />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>사용자</i-col-header>
                <i-col>
                  <iui-searchbox
                    :type="'emp'"
                    :paramObj="{}"
                    :callback="onCallbackEmpCode"
                    :init.sync="searchboxInit"
                    required
                  />
                </i-col>
                <i-col-header>부서</i-col-header>
                <i-col>
                  <iui-searchbox
                    :type="'dept'"
                    :paramObj="{}"
                    :callback="onCallbackDeptCode"
                    :init.sync="searchboxInit"
                    required
                  />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              jqgrid
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>리스트</i-col-header>
                <i-col>
                  <jqgrid />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              iui-tab
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>탭</i-col-header>
                <i-col>
                  <iui-tab-old :comps="tabComps" :keepAlive="true" :isAuto="true" @click="setTabIndex" />
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <template v-slot:title>
              file-up-down
            </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header>파일번호</i-col-header>
                <i-col>
                  <iui-text type="smallcode" :value.sync="fileUpDown_fileNumber" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>파일명</i-col-header>
                <i-col>
                  <iui-text type="largecode" :value.sync="fileUpDown_files.lclFlNm" :enable="false" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>파일크기</i-col-header>
                <i-col>
                  <iui-text type="amount" :value.sync="fileUpDown_files.flSz" :enable="false" prefix="" suffix="byte" />
                </i-col>
              </i-row>
            </iui-container>
            <iui-button @click="fileUpDown_search = true">외부조회</iui-button>&nbsp;
            <iui-button @click="fileUpDown_add = true">외부추가</iui-button>&nbsp;
            <iui-button @click="fileUpDown_delete = true">외부삭제</iui-button>&nbsp;
            <iui-button @click="fileUpDown_save = true">외부저장</iui-button>&nbsp;
            <iui-button @click="fileUpDown_download = true">외부다운</iui-button>
            <file-up-down
              company-code="201900"
              v-model="fileUpDown_files"
              :file-number.sync="fileUpDown_fileNumber"
              add-button-text="파일추가"
              delete-button-text="파일삭제"
              save-button-text="파일저장"
              download-button-text="파일다운"
              :add.sync="fileUpDown_add"
              :changed.sync="fileUpDown_changed"
              :delete.sync="fileUpDown_delete"
              :save.sync="fileUpDown_save"
              :search.sync="fileUpDown_search"
              :download.sync="fileUpDown_download"
              @save-complete="file_save_complete"
              @save-error="file_save_error"
              @delete-complete="file_delete_complete"
              @delete-error="file_delete_error"
            />
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box>
            <iui-container type="table" style="height:100%">
              <i-row>
                <i-col>
                  <input type="number" v-model.number="page" style="width:5em" /> / {{ numPages }}
                  <!-- <button @click="rotate += 90">&#x27F3;</button>
                  <button @click="rotate -= 90">&#x27F2;</button> -->
                  <button @click="$refs.pdf.print()">print</button>
                </i-col>
              </i-row>
              <i-row>
                <i-col>
                  <div style="width: 50%">
                    <div
                      v-if="loadedRatio > 0 && loadedRatio < 1"
                      style="background-color: green; color: white; text-align: center"
                      :style="{width: loadedRatio * 100 + '%'}"
                    >
                      {{ Math.floor(loadedRatio * 100) }}%
                    </div>
                    <pdf
                      ref="pdf"
                      style="border: 1px solid red"
                      :src="pdfUrl"
                      :page="page"
                      :rotate="rotate"
                      @progress="loadedRatio = $event"
                      @error="error"
                      @num-pages="numPages = $event"
                      @link-clicked="page = $event"
                    ></pdf>
                  </div>
                  <!-- <pdf :src="pdfUrl"
                      :page="page" 
                        @num-pages="numPages = $event" 
                        style="width:75%"
                  ></pdf> -->
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import {numToKr} from 'vue-number-to-kor';
import jqgrid from './jqgrid/components/jqgrid';
import FileUpDown from './FileUpDown.vue';
import pdf from 'vue-pdf';

export default {
  components: {
    jqgrid,
    FileUpDown,
    pdf,
  },
  beforeCreate() {},
  data() {
    return {
      width50: '50px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width150: '150px',
      width170: '170px',
      ZERO: parseFloat(0).toFixed(2),

      searchboxInit: true,

      arrTaxType: [
        {text: '과세', value: '000001'},
        {text: '영세', value: '000002'},
        {text: '면세', value: '000003'},
      ],
      arrStampTax: [
        {label: '필수', value: 'Y'},
        {label: '해당없음', value: 'N'},
      ],
      arrGuarantee: [
        {label: '필수', value: 'Y'},
        {label: '해당없음', value: 'N'},
      ],

      omInfo: {
        contractName: '',
        contractAmt: 0,
        contractAmtKor: '',
        guaranteeRate: 0,
        guaranteeAmt: 0,
        contractMemo: '',
        taxType: '000001',
        stampTaxYn: 'N',
        guaranteeYn: 'Y',
        contFrDt: '',
        contToDt: '',
      },

      tabComps: [
        {
          tabNm: '테스트',
          compNm: 'test1',
          paramObj: {paramKey: true},
          path: '@/view/sample/Sample1.vue',
        },
        {
          tabNm: '테스트',
          compNm: 'test2',
          paramObj: {paramKey: true},
          path: '@/view/sample/Sample2.vue',
        },
      ],
      tabIndex: 0,

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_fileNumber: 0,
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,

      //pdfUrl: `${process.env.VUE_APP_BASE_URL}/vc/download`,
      page: 1,
      numPages: 0,
      loadedRatio: 0.5,
      rotate: 0,
    };
  },
  computed: {},
  mounted() {
    console.log('#################');
    console.log(this.$store.state.code2.codeAlias.USE_CLOSE_Y);
    console.log('#################');
  },
  methods: {
    onSearch() {
      this.$alert('조회');
    },
    onNew() {
      this.$alert('신규');
    },
    onSave() {
      this.$alert('저장');
    },
    onDelete() {
      this.$alert('삭제');
    },
    onPrint() {
      this.$alert('인쇄');
    },
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.omInfo[key] = value;

      console.log('key = ' + key);
      console.log('value = ' + value);
      console.log('this.omInfo[key] = ' + this.omInfo[key]);

      if (key == 'contractAmt') {
        this.omInfo.contractAmtKor = numToKr(this.omInfo.contractAmt);
        this.calcGuaranteeAmt();
      }

      if (key == 'guaranteeRate') {
        if (parseFloat(value) > 100) {
          this.$alert('계약 보증금 비율이 100보다 클 수 없습니다.', () => {
            this.omInfo.guaranteeRate = 0;
            this.omInfo.guaranteeAmt = 0;
          });
        } else if (!value) {
          this.omInfo.guaranteeRate = 0;
          this.omInfo.guaranteeAmt = 0;
        } else {
          this.calcGuaranteeAmt();
        }
      }

      if (key == 'contFrDt') {
        console.log('this.omInfo.contToDt = ' + this.omInfo.contToDt);
        if (parseFloat(value) > parseFloat(this.omInfo.contToDt)) {
          this.$alert('계약시작이 계약종료일보다 클 수 없습니다.', () => {
            this.omInfo[key] = '';
            this.$refs.contFrDt.vdata = '';
          });
        }
      }

      if (key == 'contToDt') {
        console.log('this.omInfo.contFrDt = ' + this.omInfo.contFrDt);
        if (parseFloat(value) < parseFloat(this.omInfo.contFrDt)) {
          this.$alert('계약종료일이 계약시작일보다 작을 수 없습니다.', () => {
            this.omInfo[key] = '';
            this.$refs.contToDt.vdata = '';
          });
        }
      }
    },
    calcGuaranteeAmt() {
      let contractAmt = parseFloat(this.omInfo.contractAmt) || 0;
      let guaranteeRate = parseFloat(this.omInfo.guaranteeRate) || 0;
      if (contractAmt > 0 && guaranteeRate) {
        this.omInfo.guaranteeAmt = Math.round((contractAmt * guaranteeRate) / 100);
      }
    },
    onClickButton() {
      this.$alert('버튼클릭');
    },
    onCallbackCustomerCode(param) {
      this.$alert(JSON.stringify(param));
    },
    onCallbackEmpCode(param) {
      this.$alert(JSON.stringify(param));
    },
    onCallbackDeptCode(param) {
      this.$alert(JSON.stringify(param));
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
    },
    file_save_complete() {
      this.$alert('파일 저장이 완료되었습니다.');
    },
    file_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      setTimeout(() => {
        if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
          this.fileUpDown_fileNumber = 0;
        }
      }, 300);
    },
    file_delete_error() {
      this.$alert('파일 삭제를 실패했습니다.');
    },
    error(err) {
      console.log(err);
    },
  },
};
</script>

<style scoped></style>

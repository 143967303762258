const state = {
  searchInfo: {
    prjCd: '',
    prjNm: '',
  },
  detailInfo: {
    no: '',
    name: '',
    age: '',
    cud: '',
  },
};
const getters = {
  searchInfo: state => state.searchInfo,
  detailInfo: state => state.detailInfo,
};
const actions = {};
const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },

  initDetailInfo() {
    for (let key in state.detailInfo) {
      state.detailInfo[key] = '';
    }
  },

  setDetailInfo(state, payload) {
    for (let key in state.detailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.detailInfo[key] = payload[key];
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

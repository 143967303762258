<template>
  <div class="password-wrap" v-show="visible">
    <label v-if="label">{{ label }}</label>
    <input type="password" class="_target_" v-model="vdata" :disabled="!enable" :readonly="readonly" />
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import rootStore from '@/store';

export default {
  name: 'iui-password',
  mixins: [IuiBaseMixin],
  created: function() {
    this.setBindArray();
  },
  data: function() {
    return {
      vdata: this.value || '',
    };
  },
  props: {
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    formObj: {
      type: String,
      default: 'none!',
    },
  },
  mounted: function() {
    //속성 주입
    for (let k in this.$attrs) {
      this.$el.querySelector('._target_').setAttribute(k, this.$attrs[k]);
      this.$el.removeAttribute(k);
      if (k == 'id' && this.$el.querySelector('label')) {
        this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
      }
    }

    //이벤트 주입
    for (let e of this.event) {
      this.$el.querySelector('._target_').addEventListener(e.name, e.callback);
    }

    //스타일 주입
    for (let k in this.css) {
      this.$el.querySelector('._target_').style[k] = this.css[k];
    }

    //기본요소 height와 라벨 height 맞춤
    if (this.$el.querySelector('label')) {
      this.$el.querySelector('label').style.height = this.$el.querySelector('._target_').offsetHeight + 'px';
      this.$el.querySelector('label').style.lineHeight = this.$el.querySelector('._target_').offsetHeight + 'px';
    }

    //기본요소 구분하기 위한 class명 삭제
    this.$el.querySelector('._target_').classList.remove('_target_');
  },
  methods: {
    setFormData(value) {
      const me = this;
      setTimeout(function() {
        me.$store.dispatch('setFormData', {
          formObj: me.formObj,
          key: me.name,
          value: !value ? me.vdata : value,
          element: me.$el.querySelector('._target_'),
        });
      }, 10);
    },
    setBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
  },
  watch: {
    vdata: function(value) {
      this.setBindArray(value);
      this.setFormData(value);
    },
    value: function(value) {
      this.vdata = value;
    },
  },
};
</script>

<style scoped></style>

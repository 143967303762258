/* NE > 전자계약 > 목록 * /
<script>
import {reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js';
import RejectManagePopup from '@/view/common/components/RejectManagePopup.vue'; // 반송/회수 관리
import RecoveryPopup from '@/view/common/components/RecoveryPopup.vue'; // 회수
import moment from 'moment';

// prettier-ignore
const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  LeftCols: [
    { Header: ['보기', '보기'], Name: '보기', Type: 'Button', Width: 50, ButtonText: '보기', },
    { Header: ['계약서\n형태', '계약서\n형태'], Name: 'contDocTypeName', Type: 'Text', Width: 60, Align: 'Center', },
    { Header: ['서명\n타입', '서명\n타입'], Name: 'contSignTypeName', Type: 'Text', Width: 50, Align: 'Center', },
    { Header: ['구분', '구분'], Name: 'ctrtDivNm', Type: 'Text', Width: 100, Align: 'Left', Visible: 0 },
    { Header: ['계약번호', '계약번호'], Name: 'ctrtNo', Type: 'Text', Width: 150, Align: 'Center', },
    { Header: ['계약번호', '차수'], Name: 'ctrtOdr', Type: 'Text', Width: 50, Align: 'Center', },
  ],
  Cols: [
    { Header: ['프로젝트', '프로젝트'], Name: 'projectNo', Type: 'Text', Width: 100, Align: 'Center',FormulaRow: fr => {return useDialog().progressCount(fr);}, },
    { Header: ['프로젝트', '프로젝트명'], Name: 'projectName', Type: 'Text', Width: 200, Align: 'Left', },
    { Header: ['고객', '고객번호'], Name: 'custTradeNo', Type: 'Text', Width: 80, Align: 'Center', Formula: 'Row.custTradeNo == "0000000" ? "" : Row.custTradeNo', },
    { Header: ['고객', '고객명'], Name: 'custTradeName', Type: 'Text', Width: 170, Align: 'Left', },
    { Header: ['계약일자', '계약일자'], Name: 'contDate', Type: 'Date', Width: 100, Align: 'Center', Format: 'yyyy-MM-dd', },
    { Header: ['계약납기', '계약납기'], Name: 'deliveryDate', Type: 'Date', Width: 100, Align: 'Center', Format: 'yyyy-MM-dd', },
    { Header: ['계약금액', '계약금액'], Name: 'contTotAmt', Type: 'Int', Width: 100, Format: '#,###', Align: 'Right',},
  ],
  RightCols: [
    { Header: ['진행상태', '회수'], Name: 'sttusRecovery', Type: 'Text', Width: 50, Align: 'Center',FormulaRow: fr => {return useDialog().progressCount(fr);}, },
    { Header: ['진행상태', '송부'], Name: 'sttusSend', Type: 'Text', Width: 50, Align: 'Center', FormulaRow: fr => {return useDialog().progressCount(fr);}, },
    { Header: ['진행상태', '서명'], Name: 'sttusSigning', Type: 'Text', Width: 50, Align: 'Center', FormulaRow: fr => {return useDialog().progressCount(fr);}, },
    { Header: ['진행상태', '반송'], Name: 'sttusReject', Type: 'Text', Width: 50, Align: 'Center', FormulaRow: fr => {return useDialog().progressCount(fr);},},
    { Header: ['진행상태', '완료'], Name: 'sttusComplete', Type: 'Text', Width: 50, Align: 'Center',FormulaRow: fr => {return useDialog().progressCount(fr);},},
    { Header: ['계약담당자', '계약담당자'], Name: 'contEmpName', Type: 'Text', Width: 90, Align: 'Center', },
    { Header: ['계약상태코드', '계약상태코드'], Name: 'ctrtSttusCd', Type: 'Text', Width: 90, Align: 'Center', Visible: 0, },
    { Header: ['ctrtDivCd', 'ctrtDivCd'], Name: 'ctrtDivCd', Type: 'Text', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canSend', 'canSend'], Name: 'canSend', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canRejectRecoveryManage', 'canRejectRecoveryManage'], Name: 'canRejectRecoveryManage', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canRecovery', 'canRecovery'], Name: 'canRecovery', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
    { Header: ['canDispose', 'canDispose'], Name: 'canDispose', Type: 'Bool', Width: 50, Align: 'Center', Visible: 0, },
  ],
};

export default {
  props: {
    otisPage: {
      type: Boolean,
      default: false,
    },

    pageLoading: {
      type: Boolean,
      default: false,
    },

    selectedContractKey: {
      type: Object,
      default: undefined,
    },
  },

  components: {RejectManagePopup, RecoveryPopup},

  // ********************************************************************************
  // composition api setup 방식 개발
  // ********************************************************************************
  setup(props, context) {
    // console.log('setup > context', context);
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading: false,

      codeList: {
        // 진행상태
        status: [
          {text: '업체송부', value: '10'},
          {text: '구성원반송', value: '11'},
          {text: '회수', value: '12'},
          {text: '고객반송', value: '14'},
          {text: '구성원서명중', value: '20'},
          {text: '계약완료', value: '30'},
        ],
      },

      grid: undefined,
      gridOptions: ibsheetOptions,

      searchData: {
        projectName: undefined, // 프로젝트
        tradeName: undefined, // 고객
        empName: undefined, // 계약담당
        ctrtNo: undefined, // 계약번호
        checkContDateStart: moment()
          .startOf('month')
          .add(-1, 'month')
          .format('YYYYMMDD'), // 계약일
        checkContDateEnd: moment()
          .endOf('month')
          .add(1, 'month')
          .format('YYYYMMDD'), // 계약일
        statusCode: undefined, // 진행상태
      },

      list: undefined,
      selectedRow: undefined,

      canSend: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          state.selectedRow.ctrtSttusCd === null &&
          state.selectedRow.canSend !== undefined &&
          state.selectedRow.canSend !== null &&
          (state.selectedRow.canSend.toString() === '1'  
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)
          || $isAdmin()) &&
          !state.loading &&
          !props.pageLoading
        );
      }),
      // 반송/회수관리
      canRejectManage: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          (state.selectedRow.ctrtSttusCd === null ||
            state.selectedRow.ctrtSttusCd.toString() === '' ||
            ['11', '12', '14'].includes(state.selectedRow.ctrtSttusCd.toString())) &&
          state.selectedRow.canRejectRecoveryManage !== undefined &&
          state.selectedRow.canRejectRecoveryManage !== null &&
          (state.selectedRow.canRejectRecoveryManage.toString() === '1' 
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)
          || $isAdmin()) &&
          !state.loading &&
          !props.pageLoading
        );
      }),
      // 회수
      canRecovery: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          state.selectedRow.ctrtSttusCd !== null &&
          ['10', '20'].includes(state.selectedRow.ctrtSttusCd.toString()) &&
          state.selectedRow.canRecovery !== undefined &&
          state.selectedRow.canRecovery !== null &&
          (state.selectedRow.canRecovery.toString() === '1' 
          || (location.hostname.indexOf('econtracttest.') !== -1 || location.hostname.indexOf('localhost') !== -1 || location.hostname.indexOf('127.0.0.1') !== -1)
          || $isAdmin()) &&
          !state.loading &&
          !props.pageLoading
        );
      }),

      // 폐기
      canDispose: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.ctrtSttusCd !== undefined &&
          state.selectedRow.ctrtSttusCd !== null &&
          ['30','40','50'].includes(state.selectedRow.ctrtSttusCd.toString()) &&
          state.selectedRow.canDispose !== undefined &&
          state.selectedRow.canDispose !== null &&
          state.selectedRow.canDispose.toString() === '1' &&
          !state.loading &&
          !props.pageLoading
        );
      }),

      contractKey: undefined,
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        // 로딩 상태 완료로 변경
        state.loading = false;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col === '보기') {
          state.selectedRow = e.row;
          openViewer();
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        state.selectedRow = e.row;
        // console.log('onClick', state.selectedRow, computedSelectedContractKey.value);
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);
      },
    };

    const computedSelectedContractKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
    });

    onMounted(() => {
      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: context.refs.searchBox,
        srchList: context.parent.$parent,
      });

      // console.log('onMounted', context);
    });

    // 계약서 보기
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    watch(
      () => props.search,
      (newValue, oldValue) => {
        // 상위 페이지의 search 상태가 변경된 경우 처리
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );

    // 반송/회수 관리
    const rejectManagePopup = useDialog('rejectManagePopup');
    const rejectManagePopupSetup = {
      ...rejectManagePopup.dialogRef,
      async btnReject_click() {
        if (!state.selectedRow) {
          await instance.proxy.$alert('선택된 항목이 없습니다.');
          return;
        }

        const ctrtSttusCd = state.selectedRow.ctrtSttusCd;

        // 11 : 구성원반송, 12 : 회수. 14 : 고객반송
        if (
          !(
            ctrtSttusCd == null ||
            ctrtSttusCd == '' ||
            ctrtSttusCd == '11' ||
            ctrtSttusCd == '12' ||
            ctrtSttusCd == '14'
          )
        ) {
          await vm.$alert('반송 또는 회수 할 수 없는 상태입니다.');
          return;
        }

        state.contractKey = state.selectedRow;
        const result = await rejectManagePopup.show();
        state.contractKey = undefined;
        if (result == true) {
          method.getList();
        }
      },
    };

    // 회수
    const recoveryPopup = useDialog('recoveryPopup');
    const recoveryPopupSetup = {
      ...recoveryPopup.dialogRef,
      async btnRecovery_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        const ctrtSttusCd = state.selectedRow.ctrtSttusCd;

        // 10 : 업체송부, 20 : 구성원서명중
        if (!(ctrtSttusCd == '10' || ctrtSttusCd == '20')) {
          await vm.$alert('회수 할 수 없는 상태입니다.');
          return;
        }

        state.contractKey = state.selectedRow;
        //console.log(state.contractKey);
        const result = await recoveryPopup.show();
        state.contractKey = undefined;
        if (result == true) {
          method.getList();
        }
      },
    };

    const method = {
      // 목록 조회
      async getList() {
        if (state.loading) {
          return;
        }
        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedContractKey 변경 이벤트 호출 -> 상위 페이지의 contractKey 변경
        state.selectedRow = undefined;
        context.emit('update:selectedContractKey', computedSelectedContractKey.value);

        state.grid.removeAll();

        let param = {
          otisPage: props.otisPage,
          ...state.searchData,
          // checkContDateStart: '20210101',
        };

        try {
          const response = await axios.post('/ne/contract/selectContractList', param);
          state.list = response.data;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        } finally {
          // state.loading = false;
        }
      },

      downloadExcel() {
        state.grid.down2Excel();
      },

      grid_created() {
        method.getList();
      },

      //송부
      async btnSend_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm('계약서를 송부하시겠습니까?')) {
          const data = {
            ctrtNo: state.selectedRow.ctrtNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            reason: '계약서 송부',
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }         
          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/reSend', data);

            await vm.$alert('송부가 완료되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            //context.emit('update:page-loading', false);
            //await vm.$alert('실패하였습니다.' + ex);
          } finally {
            context.emit('update:page-loading', false); 
          }
        }
      },

      async btnDispose_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        }

        if (await vm.$confirm('계약번호 '+state.selectedRow.ctrtNo+'는(은) <br>eOKESS <b>"계약승인완료"</b> 단계로 되돌아갑니다.<br>완료된 계약서를 재작성 하시겠습니까??<br><span style="color:red">(작업 수행 후 복구가 어렵습니다.)</span>')) {
          const data = {
            ctrtNo: state.selectedRow.ctrtNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            chngPgmId: undefined,
          };

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }

          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/dispose', data);

            await vm.$alert('재작성 처리 되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            console.log(ex);
          } finally {
            context.emit('update:page-loading', false);
          }
        }
      },
    };

    return {
      state,
      computedSelectedContractKey,
      ...method,
      ...rejectManagePopupSetup,
      ...recoveryPopupSetup,
    };
  },
};
</script>

<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="searchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>프로젝트</i-col-header>
            <i-col>
              <iui-textbox
                v-model="state.searchData.projectName"
                placeholder=" 코드 또는 명칭"
                @keyup.enter="getList"
              />
            </i-col>
            <i-col-header>고객</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.tradeName" placeholder=" 고객명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>계약담당</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.empName" placeholder=" 계약담당자" @keyup.enter="getList" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.ctrtNo" placeholder=" 계약번호" @keyup.enter="getList" />
            </i-col>
            <i-col-header>계약일</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.checkContDateStart" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.checkContDateEnd" />
              </span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select v-model="state.searchData.ctrtSttusCd" :items="state.codeList.status" placeholder="전체" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row style="min-height: 260px;">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            목록
          </template>
          <template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
            <iui-button :enable="state.canRejectManage" @click="btnReject_click">반송/회수 관리</iui-button>
            <iui-button :enable="state.canRecovery" @click="btnRecovery_click">회수</iui-button>
            <!--ITSR73018 폐기추가-->
            <iui-button v-show="state.canDispose" @click="btnDispose_click">재작성</iui-button>
          </template>

          <iui-container
            type="ibsheet"
            :loading="state.loading"
            :ibsheet.sync="state.grid"
            :ibsheet-options="state.gridOptions"
            :ibsheet-data="state.list"
            @ibsheet-created="grid_created"
          />
        </iui-content-box>
      </i-col>
    </i-row>
    <!-- <send-popup ref="sendPopup" type="fod" :contract-key="state" /> -->
    <reject-manage-popup ref="rejectManagePopup" type="ne" :contract-key="state.contractKey" />
    <recovery-popup ref="recoveryPopup" type="ne" :contract-key="state.contractKey" />
  </iui-container>
</template>

<style></style>

<script>
import {defineComponent, reactive, computed, provide, onMounted} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';

export default defineComponent({
  name: 'IuiTabGroup',
  props: {
    ...BaseProps,
    borderBox: {
      type: Boolean,
      default: true,
    },
    noBorderBox: {
      type: Boolean,
      default: false,
    },
    noPaddingBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const Base = useBase(props, context, 'iui-tab-group');

    const state = reactive({
      tabList: [],
      activeTab: undefined,

      titleVisible: computed(() => {
        return context.slots['title'] !== undefined;
      }),
      titleLeftVisible: computed(() => {
        return context.slots['title-left'] !== undefined;
      }),
      titleCenterVisible: computed(() => {
        return context.slots['title-center'] !== undefined;
      }),
      titleRightVisible: computed(() => {
        return context.slots['title-right'] !== undefined;
      }),
    });

    provide('addTab', tab => {
      state.tabList.push(tab);
    });

    provide('removeTab', tabId => {
      const index = state.tabList.findIndex(tab => tab.tabId === tabId);

      state.tabList.splice(index, 1);
    });

    const method = {
      tab_click(tab) {
        if (!tab.enable) return;

        if (state.activeTab) {
          state.activeTab.active = false;
        }

        state.activeTab = tab;
        state.activeTab.active = true;
      },
    };

    onMounted(() => {
      if (state.tabList.length === 0) {
        return;
      }

      method.tab_click(state.tabList[0]);
    });

    return {
      Base,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <!-- <div class="section_con" style="width: 100%; height: 100%;">
    <div class="tab_box">
      <ul class="tab_menu">
        <li v-for="(tab, index) in state.tabList" :key="index" :class="tab.active ? 'select' : ''">
          <a href="#" :disabled="!tab.enable" @click="tab_click(tab)" style="cursor: pointer;">{{ tab.title }}</a>
        </li>
      </ul>
    </div>

    <div
      class="grid_box grid_inner_scroll iui-loading-overlay-parent"
      style="height: calc(100% - 30px); border-top:2px solid #225eac;"
    >
      <iui-loading-overlay :visible="loading" />
      <slot></slot>
    </div>
  </div> -->

  <div class="iui-content-box">
    <!-- title-box -->
    <div class="title-box">
      <ul class="title-tab-box">
        <li v-for="(tab, index) in state.tabList" :key="index" :class="tab.active ? 'selected' : ''">
          <a href="#" :disabled="!tab.enable" @click="tab_click(tab)" style="cursor: pointer;">{{ tab.title }}</a>
        </li>
      </ul>
      <div class="title-spacer"></div>
      <div v-if="state.titleLeftVisible" class="title-left">
        <slot name="title-left" />
      </div>
      <div class="title-center">
        <slot name="title-center" />
      </div>
      <div v-if="state.titleRightVisible" class="title-right">
        <slot name="title-right" />
      </div>
    </div>
    <!-- title-box -->

    <!-- outer-content-box -->
    <div
      :class="{'outer-content-box': true, grid_box: borderBox && !noBorderBox, 'iui-loading-overlay-parent': true}"
      :style="{
        height: 'calc(100% - 30px)',
        'border-top': noBorderBox ? '2px solid #3378c1' : '',
        padding: noPaddingBox ? '0px' : '',
      }"
    >
      <!-- 'border-top': noBorderBox ? '2px solid #3378c1' : '', -->
      <iui-loading-overlay :visible="loading" />
      <!-- inner-content-box -->
      <div :class="{grid_inner_scroll: true}" style="height: 100%">
        <slot />
      </div>
    </div>
    <!-- outer-content-box -->
  </div>
</template>

<style></style>

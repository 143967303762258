/* MOD > 견적 > 목록 */
<script>
import {reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';
import {useViewerWindowPopup} from '@/composables/useViewerWindowPopup.js';
import moment from 'moment';

const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  LeftCols: [
    {
      Header: ['보기', '보기'],
      Name: '보기',
      Type: 'Button',
      Width: 50,
      ButtonText: '보기',
    },
    {
      Header: ['구분', '구분'],
      Name: 'ctrtDivNm',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
    },
    {
      Header: ['견적번호', '견적번호'],
      Name: 'biddingNo',
      Type: 'Text',
      Width: 110,
      Align: 'Center',
    },
  ],
  Cols: [
    {
      Header: ['견적일자', '견적일자'],
      Name: 'biddingDate',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['프로젝트명', '프로젝트명'],
      Name: 'projectName',
      Type: 'Text',
      Width: 200,
      Align: 'Left',
    },
    {
      Header: ['고객', '고객번호'],
      Name: 'displayTradeNo',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
      Formula: 'Row.displayTradeNo == "0000000" ? "" : Row.displayTradeNo',
    },

    {
      Header: ['고객', '고객명'],
      Name: 'custTradeName',
      Type: 'Text',
      Width: 170,
      Align: 'Left',
    },
    // {
    //   Header: ['품명', '품명'],
    //   Name: 'biddingNo',
    //   Type: 'Text',
    //   Width: 60,
    //   Align: 'Center',
    // },

    {
      Header: ['견적금액', '견적금액'],
      Name: 'biddingAmt',
      Type: 'Int',
      Width: 150,
      Format: '#,###',
      Align: 'Right',
    },
    {
      Header: ['견적담당자', '견적담당자'],
      Name: 'biddingEmpName',
      Type: 'Text',
      Width: 100,
      Align: 'Center',
    },
    {
      Header: ['송부일자', '송부일자'],
      Name: 'frstSendngDt',
      Type: 'Date',
      Width: 90,
      Align: 'Center',
      Format: 'yyyy-MM-dd',
    },
    {
      Header: ['송부', '송부'],
      Name: 'sttusSend',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
    },
    {
      Header: ['고객전화번호', '고객전화번호'],
      Name: 'custMphone',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['메일', '메일'],
      Name: 'custEmail',
      Type: 'Text',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['custTradeNo', 'custTradeNo'],
      Name: 'custTradeNo',
      Type: 'Text',
      Width: 120,
      Align: 'Center',
      Visible: 0,
    },
    {
      Header: ['canSend', 'canSend'],
      Name: 'canSend',
      Type: 'Bool',
      Width: 50,
      Align: 'Center',
      Visible: 0,
    },
  ],
};

export default {
  props: {
    otisPage: {
      type: Boolean,
      default: false,
    },

    pageLoading: {
      type: Boolean,
      default: false,
    },

    selectedEstimateKey: {
      type: Object,
      default: undefined,
    },
  },

  setup(props, context) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;

    const state = reactive({
      loading: false,

      grid: undefined,
      gridOptions: ibsheetOptions,

      codeList: {
        // 진행상태
        status: [
          {text: '미송부', value: 'N'},
          {text: '송부', value: 'Y'},
        ],
      },

      searchData: {
        projectName: undefined, // 프로젝트
        tradeName: undefined, // 고객
        empName: undefined, // 계약담당
        biddingNo: undefined, // 견적번호
        estimateDateStart: moment()
          .startOf('month')
          .add(-1, 'month')
          .format('YYYYMMDD'), // 견적일자
        estimateDateEnd: moment()
          .endOf('month')
          .add(1, 'month')
          .format('YYYYMMDD'), // 견적일자
        sendYn: undefined, // 진행상태
      },

      list: undefined,
      selectedRow: undefined,

      canSend: computed(() => {
        return (
          state.selectedRow !== undefined &&
          state.selectedRow !== null &&
          state.selectedRow.canSend !== undefined &&
          state.selectedRow.canSend !== null &&
          state.selectedRow.canSend.toString() === '1' &&
          !state.loading &&
          !props.pageLoading
        );
      }),
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        // 로딩 상태 완료로 변경
        // state.loading = false;
        // search 완료 이벤트 호출 -> 상위 페이지의 search 상태 변경
        // context.emit('update:search', false);
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }

        if (e.col === '보기') {
          state.selectedRow = e.row;
          openViewer();
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        console.log('grid > onClick', e);

        state.selectedRow = e.row;

        context.emit('update:selected-estimate-key', computedSelectedEstimateKey.value);
      },
    };

    //견적서 팝업
    const viewerWindowPopup = useViewerWindowPopup();
    const openViewer = () => {
      const data = {
        ctrtNo: state.selectedRow.ctrtNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
        custTradeNo: state.selectedRow.custTradeNo,
        tradeSfx: state.selectedRow.tradeSfx,
      };

      const popup = viewerWindowPopup.open(data);

      popup.on('reject', e => {
        console.log(e);
      });
    };

    const computedSelectedEstimateKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        ctrtNo: state.selectedRow.biddingNo,
        ctrtOdr: state.selectedRow.ctrtOdr,
        ctrtDivCd: state.selectedRow.ctrtDivCd,
      };
    });

    watch(
      () => props.search,
      (newValue, oldValue) => {
        // 상위 페이지의 search 상태가 변경된 경우 처리
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );

    onMounted(() => {
      // 검색란 보이기/숨기기
      $initSrchShowButton({
        srchBox: context.refs.searchBox,
        srchList: context.parent.$parent,
      });
    });

    const method = {
      // 목록 조회
      async getList() {
        if (state.loading) {
          return;
        }

        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedEstimateKey변경 이벤트 호출 -> 상위 페이지의 estimatetKey 변경
        state.selectedRow = undefined;
        context.emit('update:selected-estimate-key', computedSelectedEstimateKey.value);

        state.grid.removeAll();

        let param = {
          // projectName: state.searchData.projectName === undefined ? '' : state.searchData.projectName, // 프로젝트\
          // custTradeName: state.searchData.custTradeName === undefined ? '' : state.searchData.custTradeName, // 고객
          // empName: state.searchData.empName === undefined ? '' : state.searchData.empName, // 계약담당
          // biddingNo: state.searchData.biddingNo === undefined ? '' : state.searchData.biddingNo, // 견적번호
          // estimateDateStart:
          //   state.searchData.estimateDateStart === undefined ? '20090101' : state.searchData.estimateDateStart, // 견적일자
          // estimateDateEnd:
          //   state.searchData.estimateDateEnd === undefined ? '20220101' : state.searchData.estimateDateEnd, // 견적일자

          otisPage: props.otisPage,
          ...state.searchData,
          // estimateDateStart: '20190101',
          // estimateDateEnd: '20191231',

          // progressPage: true,
          // completePage: false,
          // signType: 'ON',
        };

        console.log(param);

        try {
          const response = await axios.post('/mod/estimate/selectEstimateList', param);

          state.list = response.data;
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },

      downloadExcel() {
        state.grid.down2Excel();
      },

      grid_created() {
        method.getList();
      },

      //송부
      async btnSend_click() {
        if (!state.selectedRow) {
          await vm.$alert('선택된 항목이 없습니다.');
          return;
        } else {
          const hasCustMphone =
            state.selectedRow.custMphone === undefined ||
            state.selectedRow.custMphone === null ||
            state.selectedRow.custMphone.length === 0;
          const hasCustEmail =
            state.selectedRow.custEmail === undefined ||
            state.selectedRow.custEmail === null ||
            state.selectedRow.custEmail.length === 0;
          if (hasCustMphone && hasCustEmail) {
            await vm.$alert('등록된 전화번호나 메일이 없습니다.');
            return;
          }
        }
        if (await vm.$confirm('견적서를 송부하시겠습니까?')) {
          const data = {
            ctrtNo: state.selectedRow.biddingNo,
            ctrtOdr: state.selectedRow.ctrtOdr,
            ctrtDivCd: state.selectedRow.ctrtDivCd,
            reason: '견적서 송부',
            chngPgmId: undefined,
          };

          if (
            state.selectedRow.custTradeNo === undefined ||
            state.selectedRow.custTradeNo === null ||
            state.selectedRow.custTradeNo.length === 0
          ) {
            await vm.$alert('등록된 고객번호가 없습니다');
            return;
          }

          if (vm.$store && vm.$store.getters && vm.$store.getters['mdi/currentUrls']) {
            data.chngPgmId = vm.$store.getters['mdi/currentUrls'].url;
          }
          try {
            context.emit('update:page-loading', true);

            await axios.post('/contract/reSend', data);

            await vm.$alert('송부가 완료되었습니다.');

            context.emit('update:page-loading', false);

            method.getList();
          } catch (ex) {
            state.loading = false;
            await vm.$alert('송부가 실패하였습니다.' + e);
          } finally {
            context.emit('update:page-loading', false);
          }
        }
      },
    };

    return {
      state,
      computedSelectedEstimateKey,
      ...method,
    };
  },
};
</script>

<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="searchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>프로젝트</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.projectName" placeholder=" 프로젝트명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>고객</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.tradeName" placeholder=" 고객명" @keyup.enter="getList" />
            </i-col>
            <i-col-header>견적담당</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.empName" placeholder=" 견적담당자" @keyup.enter="getList" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>견적번호</i-col-header>
            <i-col>
              <iui-textbox v-model="state.searchData.biddingNo" placeholder=" 견적번호" @keyup.enter="getList" />
            </i-col>
            <i-col-header>견적일자</i-col-header>
            <i-col>
              <span style="white-space:nowrap">
                <iui-datepicker v-model="state.searchData.estimateDateStart" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.estimateDateEnd" />
              </span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select
                v-model="state.searchData.sendYn"
                :items="state.codeList.status"
                item-direction="column"
                placeholder="전체"
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row style="min-height: 260px;">
      <i-col>
        <iui-content-box no-border-box>
          <template #title>
            목록
          </template>
          <template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
          </template>
          <iui-container
            type="ibsheet"
            :loading="state.loading"
            :ibsheet.sync="state.grid"
            :ibsheet-options="state.gridOptions"
            :ibsheet-data="state.list"
            @ibsheet-created="grid_created"
          />
        </iui-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<style></style>

<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @search-company-changed="onCompanyChanged"
    @search-click="onSearch"
    @new-click="onNew"
    @save-click="onSave"
    @delete-click="onDelete"
    @print-click="onPrint"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>부서목록</template>
            <deptList></deptList>
          </pmis-tab-box>
        </i-col>
        <i-col>
          <iui-tab-old :comps="tabList" :keepAlive="true" @click="setTabIndex"></iui-tab-old>
        </i-col>
      </i-row>
    </iui-container>

    <iui-modal-old name="deptListPopup" title="부서선택" :btns="modalBtn" width="800px" height="600px">
      <deptList :isPopup.sync="isPopup" />
    </iui-modal-old>
  </pmis-page>
</template>

<script>
import deptList from './components/deptList';
import store from './store/deptRegist.js';

export default {
  components: {
    deptList,
  },
  data: function() {
    return {
      tabList: [
        {
          tabNm: '부서정보',
          compNm: 'DeptInfo',
          path: '@/view/sysManage/userManage/deptRegist/components/deptInfo',
        },
        {
          tabNm: '사원명',
          compNm: 'WorkerNm',
          path: '@/view/sysManage/userManage/deptRegist/components/workerNm',
        },
      ],
      modalBtn: [{name: '확인', callback: this.onConfirm}],
      isPopup: false,
    };
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, {savedDeptInfo: 'savedDeptInfo'});
    $mapMutations(this, ['initDeptInfo', 'setDeptInfo', 'setSearchInfo']);
  },
  created() {
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd, frmNm: this.userInfo.frmNm});
  },
  mounted() {
    // this.onSearch();
  },
  methods: {
    onCompanyChanged: function(e) {
      this.setSearchInfo({pgmCd: e.pgmCd, frmNm: e.frmNm});
    },
    setTabIndex(index) {
      if (index == 1) {
        setTimeout(() => {
          this.callEvent({name: 'workerNm_selectDeptUserList'});
        }, 100);
      }
    },

    onSearch() {
      this.callEvent({name: 'selectDeptList'});
    },
    onNew() {
      this.setDeptInfo({
        cud: '1',
        uppFldrCd: this.savedDeptInfo.uppFldrCd,
        uppFldrNm: this.savedDeptInfo.uppFldrNm,
        uppFldrNo: this.savedDeptInfo.uppFldrNo,
      });
    },
    onSave: function() {
      this.callEvent({name: 'saveDeptInfo'});
    },
    async onDelete() {
      this.callEvent({name: 'deleteDept'});
    },
    onPrint() {
      //팝업버튼
      this.isPopup = true;

      this.$modal.show('deptListPopup');
    },
    onConfirm() {
      //팝업 확인버튼
      this.callEvent({
        name: 'onFocusedDeptListRowData',
        param: data => {
          console.log(data);
          this.$modal.hide('deptListPopup');
        },
      });
    },
  }, //methods
};
</script>

<style></style>

const IuiItemsMixin = {
  props: {
    pCode: {
      type: String,
      default: undefined,
    },
    items: {
      type: [Array, Object],
      default: undefined,
    },
    excludeItems: {
      type: [Array, Object, String],
      default: undefined,
    },
    itemKeyAlias: {
      type: String,
      default: undefined,
    },
    itemTextAlias: {
      type: String,
      default: undefined,
    },
    itemValueAlias: {
      type: String,
      default: undefined,
    },
    itemEnableAlias: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      internalItems: undefined,
      internalExcludeItems: undefined,
      internalItemKeyAlias: 'value',
      internalItemTextAlias: 'text',
      internalItemValueAlias: 'value',
      internalItemEnableAlias: 'enable',
    };
  },
  watch: {
    // eslint-disable-next-line
    items(newValue, oldValue) {
      this.internalItems = newValue;
    },
    // eslint-disable-next-line
    excludeItems(newValue, oldValue) {
      this.internalExcludeItems = newValue;
    },
    // eslint-disable-next-line
    itemKeyAlias(newValue, oldValue) {
      this.internalItemKeyAlias = newValue;
    },
    // eslint-disable-next-line
    itemTextAlias(newValue, oldValue) {
      this.internalItemTextAlias = newValue;
    },
    // eslint-disable-next-line
    itemValueAlias(newValue, oldValue) {
      this.internalItemValueAlias = newValue;
    },
    // eslint-disable-next-line
    itemEnableAlias(newValue, oldValue) {
      this.internalItemEnableAlias = newValue;
    },
  },
  created() {
    if (this.excludeItems !== undefined) {
      if (typeof this.excludeItems === 'string') {
        // try {
        //   this.internalExcludeItems = JSON.parse(this.excludeItems);
        // } catch (ex) {
        //   console.log(ex);
        // }

        // try {
        //   if (this.internalExcludeItems === undefined) {
        //     this.internalExcludeItems = JSON.parse('[' + this.excludeItems + ']');
        //   }
        // } catch (ex) {
        //   console.log(ex);
        // }

        try {
          if (this.internalExcludeItems === undefined) {
            this.internalExcludeItems = this.excludeItems.split(',');
          }
        } catch (ex) {
          console.log(ex);
        }
        // try {
        //   if (this.internalExcludeItems === undefined) {
        //     this.internalExcludeItems = eval(this.excludeItems);
        //   }
        // } catch (ex) {
        //   console.log(ex);
        // }
      } else {
        this.internalExcludeItems = this.excludeItems;
      }
    }

    if (this.pCode !== undefined) {
      const me = this;

      $getCode(this, this.pCode, this.internalExcludeItems).then(codes => {
        me.internalItems = codes;
      });

      this.internalItemKeyAlias = 'code';
      this.internalItemTextAlias = 'codeNm';
      this.internalItemValueAlias = 'code';
    } else {
      if (this.items !== undefined) {
        this.internalItems = this.items;
      }
    }

    if (this.itemKeyAlias !== undefined) {
      this.internalItemKeyAlias = this.itemKeyAlias;
    }
    if (this.itemTextAlias !== undefined) {
      this.internalItemTextAlias = this.itemTextAlias;
    }
    if (this.itemValueAlias !== undefined) {
      this.internalItemValueAlias = this.itemValueAlias;
    }
    if (this.itemEnableAlias !== undefined) {
      this.internalItemEnableAlias = this.itemEnableAlias;
    }
  },
};

export default IuiItemsMixin;

<template>
  <pmis-content-box>
    <iui-container type="jqgrid" :id="sheetId" />
    <!--template v-slot:header-left>
      <iui-text
        type="text"
        name="rptNm"
        label="계약서명"
        :value="searchInfo.rptNm"
        @change="onChange"
        @enter="onSearch"
      ></iui-text>
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" class="btn" @click="onBeforeSearch" />
    </template>
    <iui-container type="jqgrid" :id="sheetId" />
    [계약서]탭의 신규건 추가(저장) 후 [계약상세 이력]탭에 상세정보가 조회됩니다.
    -->
  </pmis-content-box>
</template>

<script>
// eslint-disable-next-line
import {mapGetters, mapMutations} from 'vuex';
export default {
  beforeCreate() {
    $mapGetters(this, ['reportMain']);
    $mapMutations(this, ['setReportMain']);
  },
  created() {
    this.addEvent([
      {name: 'search', func: this.onBeforeSearch},
      {name: 'add', func: this.onAdd},
      {name: 'save', func: this.onSave},
      {name: 'delete', func: this.onDelete},
    ]);
  },
  beforeDestroy() {
    console.log('beforeDestroy...resize');
    window.removeEventListener('resize', this.resizeHandler); //resize 이벤트 제거
  },
  mounted() {
    //console.log("ready...resize");
    window.addEventListener('resize', this.resizeHandler); //resize 이벤트추가.
    this.$emit('emitMasterSignInfo');
    this.initSheet();
  },
  data() {
    return {
      sheetId: 'sheet' + this._uid,
      searchInfo: {
        manageCd: '1000',
      },
      lastRowId: '', //마지막선택한 RowId 저장변수.
      upperFocusKeyCode: '',
      newData: {},
      saveRow: 0, //grid 저장셀 row
      saveCol: 0, //grid 저장셀 col
    };
  },
  methods: {
    ...mapMutations('store', {
      setMasterSignHist: 'setMasterSignHist',
    }),

    // eslint-disable-next-line
    resizeHandler(e) {
      //console.log('resizeHandler >>>>>>>>');
      //console.log("curentWidth=="+$("#"+this.sheetId).width());

      var $grdMain = $('#' + this.sheetId);
      var parentWidth = $grdMain
        .closest('.ui-jqgrid')
        .parent()
        .parent()
        .width();
      //console.log( "parentWidth=="+parentWidth);
      $grdMain.jqGrid('setGridWidth', parentWidth - 10, true);
    },

    initSheet() {
      var editoptionsYN = {value: 'Y:사용;N:미사용'};
      // eslint-disable-next-line
      //var formatterYN = function(v) { return v=='Y'?'사용':'미사용'; };

      const OPT = {
        Cols: [
          {
            label: '상태',
            name: '_rowStatusName',
            editable: false,
            align: 'center',
            width: 45,
          },
          {
            label: 'No',
            name: 'no',
            editable: false,
            align: 'center',
            width: 50,
          },
          {
            label: '관리번호',
            name: 'manageCd',
            edittype: 'text',
            editrules: {required: true},
            editoptions: {maxlength: 3},
            editable: false,
            align: 'center',
            width: 100,
          },
          {
            label: '이력순번',
            name: 'verNo',
            edittype: 'text',
            align: 'center',
            editable: false,
            width: 80,
          },
          {
            label: '사용여부',
            name: 'applcYn',
            edittype: 'select',
            align: 'center',
            editable: true,
            width: 100,
            editoptions: editoptionsYN,
            formatter: 'select',
          },
          {
            label: '사용시작일자',
            name: 'applcDate', //yyyy-mm-dd 형태로 조회해야 포멧적용됨.
            //edittype: 'text',
            align: 'center',
            editable: true,
            width: 110,
            formatter: 'date',
            //editrules : {date:true},
            //datefmt : "date",
            formatoptions: {
              newformat: 'Y-m-d',
              //srcformat: "Y-m-d"
            },
            editoptions: {
              readonly: 'readonly',
              size: 10,
              dataInit: function(el) {
                //$("#" + el.id).removeClass('hasDatepicker');
                console.log('dataInit======', el);
                console.log('dataInit======' + el.id);
                //setTimeout(function(){
                //$(el).datepicker({ dateFormat: "yy-mm-dd", constraintinput:false});
                $(el).datepicker({
                  closeText: '닫기',
                  prevText: '이전달',
                  nextText: '다음달',
                  currentText: '오늘',
                  monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                  monthNamesShort: [
                    '1월',
                    '2월',
                    '3월',
                    '4월',
                    '5월',
                    '6월',
                    '7월',
                    '8월',
                    '9월',
                    '10월',
                    '11월',
                    '12월',
                  ],
                  dayNames: ['일', '월', '화', '수', '목', '금', '토'],
                  dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
                  dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                  changeMonth: true, //달력 셀렉트 박스 유무
                  changeYear: true, //년 셀렉트 박스 유무
                  duration: 20,
                  buttonImageOnly: false, //true이면 입력 필드 옆에 이미지만 표시됩니다. false이면 버튼 안에 이미지가 표시
                  //buttonText : '', //button 에 표시되는 글자입니다.
                  showOn: 'focus', //focus, button, both 중에 선택할 수 있습니다. focus 는 포커스가 오면 달력이 팝업 됩니다. button 은 버튼을 클릭하면 달력이 팝업 됩니다. both는 두 가지 경우 모두에서 팝업 됩니다.
                  //buttonImage : "../images/button/butt_calender.gif",
                  dateFormat: 'yy-mm-dd', //날짜 출력 형식
                  // eslint-disable-next-line
                  onSelect: function(dateText, inst) {
                    //console.log('formattedDate',formattedDate);
                    console.log('date', dateText);
                    //console.log('inst',inst);

                    console.log('datepicker==>R,C:' + me.saveRow + ',' + me.saveCol);
                    $('#' + me.sheetId).jqGrid('saveCell', me.saveRow, me.saveCol);
                  },
                  onClose: function() {
                    console.log('datepicker==onclose~~');
                    //alert( $(this).datepicker().val() );
                  },
                });
                //}, 30);
              },
              defaultValue: function() {
                var currentTime = new Date();
                var month = parseInt(currentTime.getMonth() + 1);
                month = month <= 9 ? '0' + month : month;
                var day = currentTime.getDate();
                day = day <= 9 ? '0' + day : day;
                var year = currentTime.getFullYear();
                return year + '-' + month + '-' + day;
              },
            },
          },
          {
            label: '본부장성명',
            name: 'mngrNm',
            type: 'text',
            align: 'center',
            width: 120,
            editable: true,
          },
          {
            label: '사인이미지경로',
            name: 'mngrSignUrl',
            type: 'text',
            align: 'left',
            //editrules: { required:true, integer:true },
            width: 250,
            editable: true,
          },
          {
            label: '변경사유',
            name: 'rmCn',
            type: 'text',
            align: 'left',
            //editrules: { required:true, integer:true },
            width: 200,
            editable: true,
          },
          {
            label: '변경일자',
            name: 'chngDate',
            type: 'text',
            align: 'center',
            //editrules: { required:true, integer:true },
            width: 100,
            editable: false,
          },
          {
            label: '변경자',
            name: 'chngEmpName',
            type: 'text',
            align: 'center',
            //editrules: { required:true, integer:true },
            width: 100,
            editable: false,
          },
          {name: '_rowStatusCode', hidden: true},
        ],
        Events: {
          onDataLoad: this.grid_onDataLoad,
        },
      };

      var me = this;
      var $grdMain = $('#' + this.sheetId);

      $grdMain.jqGrid({
        height: 200,
        width: 200,
        autowidth: true,
        //shrinkToFit:true,
        selectionMode: 'singlerow',
        onselectrow: true,
        multiselect: false,
        colModel: OPT.Cols,
        cellEdit: true,
        cellsubmit: 'clientArray',
        //datatype: 'local',
        //data: 'dataArray',

        //sortname: 'invdate',
        viewrecords: true,
        //sortorder: "desc",

        //sortable : true,   //sort 기능
        //loadonce : true,   //sort 기능
        //beforeSelectRow: function(rowid, e) {
        //  console.log( "beforeSelectRow:rowid>>>>>"+rowid);
        //  return true; // true 반환하지 않으면 다음 이벤트가 수행되지 않음.
        //},
        onSelectCell: function(rowid) {
          //$('#history').append('onSelectRow() - rowid: ', rowid,'\t\tstatus: ', status,'\t\te: ', e + '<br/>');
          console.log('onSelectRow:rowid>>>>>' + rowid);

          var $grdMain = $('#' + me.sheetId);

          //var rowid = grdMain.getGridParam("selrow");

          console.log(' rowid=' + rowid + ',lastRowId=' + me.lastRowId);

          if (rowid && rowid !== me.lastRowId && me.lastRowId) {
            console.log(' save 처리...');
            $grdMain.saveRow(me.lastRowId);
            $grdMain.restoreRow(me.lastRowId);
          }
          //$grdMain.editRow(rowid, true);
          var data = $grdMain.getRowData(rowid);
          if (me.lastRowId != rowid) {
            me.setReportMain(data);
            me.callEvent({name: 'hist_search', param: {manageCd: data.manageCd}});
            me.lastRowId = rowid;
          }

          // eslint-disable-next-line
          //var rowdata = $grdMain.getRowData(rowid);

          //me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
        },
        loadComplete: function() {
          //조회된건은 수정불가. 삭제만가능...
          console.log('loadComplete~~~');
          // var $grdMain = $("#"+me.sheetId);
          // var ids = $grdMain.jqGrid('getDataIDs');
          // for (var i=0;i<ids.length;i++) {
          //     var id=ids[i];
          //     if ($grdMain.jqGrid('getCell',id,'manageCd') != '') {
          //         $grdMain.jqGrid('setCell',id,'manageCd','','not-editable-cell');
          //   }
          // }
        },
        //수정건 수정으로표시.
        // eslint-disable-next-line
        afterSaveCell: function(rowid, name, val, iRow, iCol) {
          //beforeSaveCell: function (rowid, name, val, iRow, iCol) {
          console.log('afterSaveCell~~~' + rowid);
          var $grdMain = $('#' + me.sheetId);
          if ($grdMain.jqGrid('getCell', rowid, '_rowStatusCode') == 'N') {
            $grdMain.jqGrid('setRowData', rowid, {_rowStatusCode: 'U'});
            $grdMain.jqGrid('setRowData', rowid, {_rowStatusName: 'U'});
          }
          return true;
        },
        // eslint-disable-next-line
        beforeEditCell: function(rowid, cellname, value, iRow, iCol) {
          console.log('beforeEditCell~~~' + rowid);
          var data = $grdMain.getRowData(rowid);
          if (me.lastRowId != rowid) {
            me.setReportMain(data);
            me.callEvent({name: 'hist_search', param: {manageCd: data.manageCd}});
            me.lastRowId = rowid;
          }
        },
        //열려있는 상태에서도 저장이되게끔 해줘야함.
        afterEditCell: function(rowid, cellname, value, iRow, iCol) {
          //beforeSaveCell:function(rowid, cellname, value, iRow, iCol){
          console.log('afterEditCell~~~' + rowid);
          me.saveRow = iRow;
          me.saveCol = iCol;
          console.log('**afterEditCell:' + cellname + '=' + me.saveRow + ',' + me.saveCol);

          var $grdMain = $('#' + me.sheetId);
          $('#' + rowid + '_' + cellname).blur(function() {
            $grdMain.jqGrid('saveCell', iRow, iCol);
          });
          return true;
        },
      });

      $gridAutoResize($grdMain);
    },
    onBeforeSearch() {
      console.log('onBeforeSearch!!');
      this.upperFocusKeyCode = '';
      this.onSearch();
    },
    async onSearch() {
      //this.searchInfo.rptNm = '';
      console.log('onSearch!!');
      let response = await axios.post('/report/selectMasterSignHist', {...this.searchInfo});
      var data = response.data;
      for (var i = 0; i < data.length; i++) {
        data[i].no = i + 1;
        data[i].isNew = false;
        //data[i].pgmCd = this.searchInfo.pgmCd;
      }

      let me = this;
      var $grdMain = $('#' + me.sheetId);
      $grdMain.jqGrid('clearGridData');
      $grdMain.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
      //$("#"+this.sheetId1).clearGridData(true).setGridParam({data:response.data}).trigger("reloadGrid");
      //$grid.jqGrid.setGridParam({data:response.data}).trigger("reloadGrid");
    },
    /**
     *  신규추가. 한건씩만 추가 가능.
     */
    onAdd() {
      let me = this;
      var $grdMain = $('#' + me.sheetId);
      let rowCnt = $grdMain.getGridParam('reccount');

      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);

      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);

      //$grdMain.saveRow(me.lastRowId);
      //$grdMain.restoreRow(me.lastRowId);

      var ids = $grdMain.jqGrid('getDataIDs');
      console.log('getDataIDs>>>>', ids);
      for (var i = 0; i < ids.length; i++) {
        var rowData = $grdMain.getRowData(ids[i]);
        //console.log("rowData>>>>", rowData);
        if (String(rowData._rowStatusCode).trim() == 'I') {
          this.$alert('신규 추가건이 존재합니다.<br/>저장 후 이력상세정보를 입력하세요.');
          return;
        }
      }

      //console.log(  $grdMain.getrow);
      //me.callEvent({name: 'hist_search', param: { manageCd: '' }});
      //사용시작일자처리.
      var currentTime = new Date();
      var month = parseInt(currentTime.getMonth() + 1);
      month = month <= 9 ? '0' + month : month;
      var day = currentTime.getDate();
      day = day <= 9 ? '0' + day : day;
      var year = currentTime.getFullYear();
      var defaultDate = year + '-' + month + '-' + day;

      var addData = {
        _rowStatusCode: 'I',
        _rowStatusName: 'I',
        no: rowCnt + 1,
        manageCd: '1000',
        verNo: '',
        mngrNm: '',
        mngrSignUrl: '',
        applcDate: defaultDate,
        applcYn: 'N',
        rmCn: '',
      };
      // 페이징 처리 시 현 페이지의 Max RowId 값
      $grdMain.jqGrid('addRowData', rowCnt + 1, addData, 'last'); // 마지막 행에 Row 추가
      //$grdMain.jqGrid("setSelection", rowCnt+1, false);
      $grdMain.jqGrid('editCell', rowCnt + 1, 6, true); //본부장성명

      me.lastRowId = $grdMain.getGridParam('selrow');

      //$("#"+this.sheetId).setColProp('manageCd',{editoptions:{editable:true}});

      //$("#"+this.sheetId).jqGrid('setColProp', 'manageCd', { editable: true }); //전체셀정보 수정.

      //let addRow = $grid.addRow();
      //let addRow = $grid.addRowData(0, addData);
      //$("#"+this.sheetId).jqGrid.setCell(rowCnt+1, "manageCd", "", "editable-cell");
      //$("#"+this.sheetId).jqGrid("setCell", rowCnt+1, "manageCd", "", "editable-cell");

      //$grid.jqGrid("setCell", addRow,  "manageCd", "", "edit-cell"); //not-editable-cell
      //$(".review-"+addRow).removeClass('not-editable-cell');
      //$grid.jqGrid.setColProp("manageCd", {editable: true});
    },
    async onSave() {
      var $grdMain = $('#' + this.sheetId);
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.

      //var currSelRowId = $grdMain.getGridParam("selrow"); //현재선택된 로우ID.
      //$grdMain.jqGrid("saveCell",currSelRowId,this.saveCol); //input type 변경사유
      console.log('onSave==>' + this.saveRow + ',' + this.saveCol);
      $grdMain.jqGrid('saveCell', this.saveRow, this.saveCol);
      var isChanged = false;
      var grdData = $grdMain.getRowData();
      if (grdData) {
        for (var i = 0; i < grdData.length; i++) {
          if ('I,D,U'.indexOf(String(grdData[i]._rowStatusCode).trim()) > -1) {
            isChanged = true;
            break;
          }
        }
      }
      console.log('onSave:getRowData>>>>', grdData);
      //console.log("onSave:changeData>>>>", changeData);

      // if(!changeData || changeData.length == 0){
      //   this.$alert('수정된 내용이 없습니다.');
      //   return;
      // }

      if (!isChanged) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }
      //일단 다(all)보낸다.
      // eslint-disable-next-line
      for (var i = 0; i < grdData.length; i++) {
        if ('I' == String(grdData[i]._rowStatusCode).trim()) {
          if ('' == String(grdData[i].manageCd).trim()) {
            this.$alert('관리코드는 필수입력 항목입니다.');
            $grdMain.jqGrid('editCell', grdData[i].id, 2, true); //관리코드
            return;
          }
          this.newData = Object.assign({}, grdData[i]);
          console.log('this.newData==1', grdData[i]);
        }
        if ('' == String(grdData[i].applcYn).trim()) {
          this.$alert('사용여부 필수입력 항목입니다.');
          $grdMain.jqGrid('editCell', grdData[i].id, 4, true); //사용여부
          return;
        }
        if ('' == String(grdData[i].applcDate).trim()) {
          this.$alert('사용시작일는 필수입력 항목입니다.');
          $grdMain.jqGrid('editCell', grdData[i].id, 5, true); //사용시작일
          return;
        }
        if ('' == String(grdData[i].mngrNm).trim()) {
          this.$alert('본부장 성명은 필수입력 항목입니다.');
          $grdMain.jqGrid('editCell', grdData[i].id, 6, true); //본부장 성명
          return;
        }
        if ('' == String(grdData[i].mngrSignUrl).trim()) {
          this.$alert('사인 이미지경로는 필수입력 항목입니다.');
          $grdMain.jqGrid('editCell', grdData[i].id, 7, true); //사인 이미지경로
          return;
        }
      }

      console.log('this.newData==2', this.newData);

      /*
      //중복데이터체크.
      if(this.newData && this.newData.length == 1){
        var response = await axios.post('/report/selectDuplicateCheckReportFile', this.newData);
        console.log( "중복체크", response );
        if (parseInt( response.data.checkCnt)>0) {
          //this.$alert(`중복되는 계약서 관리코드가 있습니다.`);
          this.$alert(`중복되는 계약서 관리코드가 있습니다.<br/>[${this.newData.manageCd}]`);
          return;
        }
      } */

      let message = '저장하시겠습니까?';
      if (await this.$confirm(message)) {
        var response = await axios
          .post('/report/saveMasterSignHist', grdData)
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          //this.upperFocusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert('저장을 실패했습니다.');
        }
      }
    },
    async onDelete() {
      // _rowStatusCode:'I' ->삭제처리.
      // _rowStatusCode:'N','U' -> '삭제'표시
      //$("#testGrid").jqGrid('delRowData', rowids[i]); // 로우 삭제
      var $grdMain = $('#' + this.sheetId);
      var rowid = $grdMain.getGridParam('selrow');
      if (!rowid) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      var data = $grdMain.getRowData(rowid);
      if ('Y' == data.applcYn) {
        this.$alert('사용중인 데이터 입니다.');
        return;
      }
      if (await this.$confirm('삭제하시겠습니까?<br>(삭제 후 저장을 수행하세요.)')) {
        $grdMain.jqGrid('setCell', rowid, '_rowStatusCode', 'D');
        $grdMain.jqGrid('setCell', rowid, '_rowStatusName', 'D');

        $grdMain.saveRow(rowid);
        $grdMain.restoreRow(rowid);
      }
    },
    onChange(e) {
      this.searchInfo.manageCd = e.target.value;
    },
  },
};
</script>

<style scoped></style>

import {reactive, toRef, computed} from '@vue/composition-api';

export const SuffixProps = {
  suffix: {
    type: String,
    default: undefined,
  },
  suffixWidth: {
    type: String,
    default: undefined,
  },
};

export function useSuffix(props, context, base) {
  const suffixRef = toRef(props, 'suffix');
  const suffixWidthRef = toRef(props, 'suffixWidth');

  const state = reactive({
    has: computed(() => suffixRef.value !== undefined || context.slots.suffix !== undefined),
    id: computed(() => base.getId('suffix')),
    style: {
      width: suffixWidthRef,
      flex: computed(() => (suffixWidthRef.value === undefined ? undefined : `0 0 ${suffixWidthRef.value} !important`)),
    },
  });

  return {
    state,
  };
}

export default useSuffix;

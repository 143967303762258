/* JSA > 공동수급협정서 > 상세 TAB */
<script>
import {reactive, watch, computed, onMounted, getCurrentInstance} from '@vue/composition-api';
import {numToKr} from 'vue-number-to-kor';
import HistoryList from '@/view/common/components/HistoryList.vue';

// prettier-ignore
const ibsheetOptions = {
  Cfg: {
    CanEdit: 0,
  },
  Cols: [
    { Header: '구분'                              , Name: 'tradeType'     , Type: 'Text', Width: 80 , Align: 'Center', Visible: 0, },
    { Header: '구분'                              , Name: 'tradeTypeNm'   , Type: 'Text', Width: 100 , Align: 'Center', },
    { Header: '대표사여부'                        , Name: 'mainTradeYn'   , Type: 'Text', Width: 90 , Align: 'Center', Formula: 'Row.mainTradeYn == "Y" ? "○" : ""', Visible: 0, },
    { Header: '업체번호'                          , Name: 'displayTradeNo', Type: 'Text', Width: 80 , Align: 'Center', Visible: 0, },
    { Header: '업체번호'                          , Name: 'tradeNo'       , Type: 'Text', Width: 80 , Align: 'Center', Visible: 0, },
    { Header: '상호(법인) / 이름(개인/개인사업자)', Name: 'tradeName'     , Type: 'Text',             Align: 'Left'  , MinWidth: 200, RelWidth: 1, },
    { Header: '대표자'                            , Name: 'repntName'     , Type: 'Text', Width: 100, Align: 'Center', },
    { Header: '서명/반송'                         , Name: 'signOrReject'  , Type: 'Text', Width: 100, Align: 'Center', },
    { Header: '사업자번호'                        , Name: 'businessNo'    , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: '주소'                              , Name: 'address'       , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: '서명일시'                          , Name: 'signDt'        , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: '서명방법'                          , Name: 'signMthNm'     , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: 'IP'                                , Name: 'accesIp'       , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: 'mphone'                            , Name: 'mphone'        , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    { Header: 'email'                             , Name: 'email'         , Type: 'Text', Width: 100, Align: 'Left'  , Visible: 0, },
    {
      Header: 'URL',
      Name: 'urlLink',
      Type: 'Text',
      MinWidth: 200,
      ColMerge: false,
      RelWidth: 1,
      Align: 'Left',
      Visible: 0,
    }, //개별 알림재송부  lgksja 20230111
    {
      Header: 'CANSING',
      Name: 'canSign',
      Type: 'Bool',
      MinWidth: 200,
      ColMerge: false,
      RelWidth: 1,
      Align: 'Left',
      Visible: 0,
    }, //20240508 lgksja 이게 있어야. 오티스 인원이 알림재송부 복사를 할수 있음
  ],
};

export default {
  components: {HistoryList},
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy; 
    const state = reactive({
      loading: false,

      // 1. 계약 대상자
      grid: undefined,
      gridOptions: ibsheetOptions,

      memberInfo: {
        tradeTypeNm: '구분', // 구분
      },

      // 2. 계약 정보
      contractInfo: {},

      //개별 알림재송부  lgksja 20230111
      isCanResendNoti : computed(() => {
        return  (
          //console.log("tate.memberInfo.signDt",state.memberInfo.signDt),
          ( $isAdmin() || state.memberInfo.canSign ) &&
          props.otisPage && 
          state.memberInfo.signDt != undefined && 
          state.memberInfo.signDt =='' && 
          state.memberInfo.tradeType !='OTIS' &&
          ( (state.memberInfo.tradeType =='CCPY' && state.contractInfo.ctrtSttusCd =='10') 
            || 
            (state.memberInfo.tradeType =='CUST' && state.contractInfo.ctrtSttusCd =='20'))
        )
      }),
      isCanUrlCopy : computed(() => {
        
        return  (
          console.log("state.memberInfo.urlLink",state.memberInfo.urlLink),
          ( $isAdmin() || state.memberInfo.canSign ) &&
          props.otisPage && 
          state.memberInfo.urlLink != undefined && 
          state.memberInfo.urlLink !='' && 
          state.memberInfo.tradeType !='OTIS'
        )
      }),
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad(e) {
        const rowValue = e.sheet.getRowValue(e.sheet.getFirstRow());        
        state.memberInfo = rowValue;
      },
      onClick(e) {
        if (e.row.Kind !== 'Data') {
          return;
        }
      },
      onFocus(e) {
        if (e.row === undefined || e.row === null) {
          return;
        }

        if (e.orow !== undefined && e.orow !== null && e.row.id === e.orow.id) {
          return;
        }

        const rowValue = e.sheet.getRowValue(e.row);
        state.memberInfo = rowValue;
      },
    };

    watch(
      () => props.contractKey,
      newValue => {
        // 초기화
        state.grid.removeAll();

        state.memberInfo = {}; // 계약 대상자
        state.contractInfo = {}; // 계약 정보
        state.memberInfo.tradeTypeNm = '구분';

        // contractKey가 변경된 경우 처리
        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.contractKey) {
        method.getData();
      }
    });

    const method = {
      async getData() {
        state.loading = true;

        let param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/jsa/contract/selectContractView', param);

          state.contractInfo = response.data;
          console.log("state.contractInfo",state.contractInfo);
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },
      //개별 알림재송부  lgksja 20230111
      async resendNoti() {
        if (!(await vm.$confirm(state.memberInfo.tradeName + '에게 알림재송부 하시겠습니까?'))) return;

        const param = {
          ctrtNo: state.contractInfo.ctrtNo,
          ctrtOdr: state.contractInfo.ctrtOdr,
          ctrtDivCd: state.contractInfo.ctrtDivCd,
          tradeNo: state.memberInfo.tradeNo,
          tradeSfx: state.memberInfo.tradeSfx,
        };
        //console.log("state.memberInfo",state.contractInfo);
        state.loading = true;
        try {
          await axios.post('/contract/reSendNoti', param);
          await vm.$alert('알림재송부가 완료되었습니다.');
          state.loading = false;
          state.grid.removeAll();
          await method.getData();
        } catch (ex) {
          console.log(ex);
          state.loading = false;
          await vm.$alert('알림재송부 중 오류가 발생하였습니다.');
        } finally {
          state.loading = false;
        }
        
      },
      onCopy() {
        vm.$alert(
          '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
        );
      },
      onError(e) {
        vm.$alert('클립보드 복사에 실패하였습니다.');
        console.log(e);
      },
    };

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <iui-tab-group :loading="state.loading">
    <iui-tab title="계약 대상자">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <iui-container
                type="ibsheet"
                :ibsheet.sync="state.grid"
                :ibsheet-options="state.gridOptions"
                :ibsheet-data="state.contractInfo.memberList"
              />
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="8" width="80px">{{ (state.memberInfo.tradeTypeNm == '3PI')? state.contractInfo.consortiumDiv:state.memberInfo.tradeTypeNm }}</i-col-header>
                <i-col-header width="100px">사업자번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.repntName" disable />
                  <iui-button
                    color="blue"
                    v-if="state.isCanUrlCopy"
                    v-clipboard:copy="state.memberInfo.urlLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >URL복사</iui-button
                  >
                  <iui-button iui-button color="blue" v-if="state.isCanResendNoti" @click="resendNoti">알림재송부</iui-button>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.address" disable />
                </i-col>
              </i-row>
              <i-row v-show="state.memberInfo.tradeType!='OTIS'">
                <i-col-header>전화번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.mphone" disable />
                </i-col>
              </i-row>
              <i-row v-show="state.memberInfo.tradeType!='OTIS'">
                <i-col-header>이메일</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.email" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명일시</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.signDt" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>방법 / IP</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo.signMthNm" disable />
                  <iui-textbox v-model="state.memberInfo.accesIp" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="계약 정보">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header>계약번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtNoOrg" disable />
                </i-col>
                <i-col-header>차수</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtOdrOrg" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>고객번호</i-col-header>
                <i-col >
                  <iui-textbox v-model="state.contractInfo.customerTradeNo" disable />
                </i-col>
                <i-col-header>고객명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.customerTradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>컨소시엄 구분</i-col-header>
                <i-col >
                  <iui-textbox v-model="state.contractInfo.consortiumDiv" disable />
                </i-col>
                <i-col-header>컨소시엄 거래선번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.consortiumTradeNo" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>컨소시엄 거래선명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.consortiumTradeName" disable />
                </i-col>
                <i-col-header>사업부</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.divCd" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>프로젝트번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectNo" disable />
                </i-col>
                <i-col-header>프로젝트명</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>계약일자</i-col-header>
                <i-col  colspan="3">
                  <iui-datepicker v-model="state.contractInfo.contDate" disable />
                </i-col>
               
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :document-key="contractKey" />
    </iui-tab>
  </iui-tab-group>
</template>

<style></style>

import {reactive, toRef, watch} from '@vue/composition-api';

export const BaseProps = {
  // id, name
  id: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: undefined,
  },

  // enable, disable, readonly, visible
  enable: {
    type: Boolean,
    default: true,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  visible: {
    type: Boolean,
    default: true,
  },
};

export function useBase(props, context, componentName) {
  if (componentName && componentName.startsWith('iui-')) {
    componentName = componentName.substring(4);
  }

  const readonlyRef = toRef(props, 'readonly');
  const visibleRef = toRef(props, 'visible');

  const state = reactive({
    id: undefined,
    name: props.name,
    enable: props.enable && !props.disable,
    readonly: readonlyRef,
    visible: visibleRef,

    $iuiId: undefined,
  });

  watch(
    () => props.enable,
    (newValue, oldValue) => {
      if (newValue === oldValue) return;

      state.enable = newValue;

      context.emit('update:disable', !newValue);
    }
  );

  watch(
    () => props.disable,
    (newValue, oldValue) => {
      if (newValue === oldValue) return;

      state.enable = !newValue;

      context.emit('update:enable', !newValue);
    }
  );

  /**
   * 새로운 ID를 생성합니다.
   * @param {*} prefix
   * @returns
   */
  const generateNewId = (prefix = '') => {
    const uniqueId = Math.random()
      .toString(16)
      .substring(2);

    if (prefix && typeof prefix === 'string' && prefix.length > 0) {
      return `iui_${prefix}_${uniqueId}`;
    }

    return `iui_${uniqueId}`;
  };

  /**
   * suffixID 해당하는 ID를 가져옵니다.
   * @param {string} suffixID
   */
  const getId = suffixID => {
    return typeof suffixID === 'string' && suffixID.length > 0 ? `${state.id}_${suffixID}` : `${state.id}`;
  };

  state.id = props.id || generateNewId(componentName);
  state.$iuiId = state.id;

  return {
    state,
    generateNewId,
    getId,
  };
}

export default useBase;

import {addRouter} from '../../main';
const state = {
  urls: [],
  urlHistory: {},
  router: [],
  currentUrls: {},
};

const getters = {
  urls: state => state.urls,
  history: state => state.urlHistory,
  currentUrls: state => state.currentUrls,
  router: state => state.router,
};

const mutations = {
  setCurrentUrls(state, urls) {
    state.currentUrls = urls;
  },

  ADD_URL(state, payload) {
    state.urls.push(payload);
  },

  ADD_ROUTER(state, payload) {
    state.router.push(payload);
  },
};

const actions = {
  addMDI(context, tab) {
    tab.webUrl = tab.webUrl.indexOf('.') != 0 ? '.' + tab.webUrl : tab.webUrl;
    tab.url = '/' + tab.menuId;

    if (context.state.urls.filter(e => e.url === tab.url).length > 0) {
      document.querySelector('[href*="#' + tab.url + '"]').click();
    } else {
      if (!context.state.router.filter(e => e.url === tab.url).length) {
        // var url = "@/view/contract/oContract/firstContract/progress/index.vue";
        //import('@/view/contract/oContract/firstContract/progress/index.vue');
        addRouter(tab);

        // 20210513 로그
        try {
          axios.post('/scdUser/logAccessMenu', { progrmId: tab.menuId } );
        } catch (ex) {
          console.log(ex);
        }

      }

      // context.state.router.push(tab);
      // context.state.urls.push(tab);
      context.commit('ADD_ROUTER', tab);
      context.commit('ADD_URL', tab);

      context.state.urlHistory[tab.url] = [];
      setTimeout(function() {
          document.querySelector('[href*="#' + tab.url + '"]').click();
      }, 10);
    }
  },
  setUrl({state, commit}, urls) {
    commit('setCurrentUrls', urls);
    state.urls.map(url => {
      url.isSelected = false; 
      if (url.url == urls.url) {
        url.isSelected = true;
      }
    });
  },
  setLocation({state}, url) {
    const rootUrl = state.urls.filter(url => url.isSelected)[0].url;
    !url.idx ? (url.idx = 0) : url.idx++;
    state.urlHistory[rootUrl].push(url);
  },
  popHistory({state}, url) {
    state.urlHistory[url].splice(-1);
  },
  removeMdi({state}, url) {
    let idx = state.urls.findIndex(function(u) {
      return u.url === url;
    });

    if (idx < 0) {
      url = Object.keys(state.urlHistory)[Object.keys(state.urlHistory).length - 1];
      idx = state.urls.findIndex(function(u) {
        return u.url === url;
      });
    }
    
    delete state.urlHistory[url];
    if (idx > -1) state.urls.splice(idx, 1);

    let openUrl = 'HOME';
    if (state.urls[idx - 1]) {
      openUrl = state.urls[idx - 1].url;
    } else if (0 < state.urls.length) {
      openUrl = state.urls[idx].url;
    }
    document.querySelector(`[href*="#${openUrl}"]`).click();
  },
  allRemoveMdi({state}) {
    state.urls = [];
    state.urlHistory = {};
    document.querySelector('[href*="#HOME"]').click();
  },
  doPrint() {
    const prtHtml = document.querySelector('.scroll_wrap').innerHTML;
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      stylesHtml += node.outerHTML;
    }
    const WinPrint = window.open('', '', 'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(`<!DOCTYPE html>
    <html>
      <head>
        ${stylesHtml}
      </head>
      <body>
        ${prtHtml}
      </body>
    </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

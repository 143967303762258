import store from '@/store';

const state = {
  menu: [

  ],
  system: {
    frmGb: '',
    rootMenuId: '',
    parentMenuId: '',
    uppMnuNm: '',
    menuId: '',
    menuName: '',
    orderSeq: '',
    svYn: '',
    vwYn: '',
    delYn: '',
    prnYn: '',
    webUrl: '',
    para: '',
    lvl: '',
    useDs: '',
    beforeMenuId: '',
    beforeParentMenuId: '',
    cud: 0,
  },
  systemMenuInq: 0,
  viewNm: '',
  path: '',

  //lgksja 202200318 불필요한 호출 제거용
  menuInitData : [],
};

const getters = {
  menu: state => state.menu,
  Items: state => {
    var items = state.menu.filter(item => item.isSelected).length ? state.menu.filter(item => item.isSelected)[0].Items : [];
    return items;
  },
  selectedMenuNm: state => 
    state.menu.filter(item => item.isSelected).length ? state.menu.filter(item => item.isSelected)[0].menuName : '',
  selectedViewNm: state => {
    const currentUrls = store.getters['mdi/currentUrls'];

    if (store.getters.currentUrl == '/' || store.getters.currentUrl == '/Home') {
      state.viewNm = '';
    } else {
      state.viewNm = currentUrls.menuName;
    }

    return state.viewNm;
  },
  selectedViewPath: state => {
    const currentUrls = store.getters['mdi/currentUrls'];
    const oneLevel = `${currentUrls.rootMenuId}`; //${String(currentUrls.parentMenuId).substr(0, 3)}00000`;
    const twoLevel = `${currentUrls.parentMenuId}`;
    const threeLevel = `${currentUrls.menuId}`;

    let isChange = false;
    let tmp = '';

    state.menu.forEach(function(menu) {
      if (menu.menuId == oneLevel) {
        menu.isSelected = true;
        tmp = '<span class="home fc_bl01">' + menu.menuName + '</span>';
        menu.Items.forEach(function(item) {
          if (item.menuId == twoLevel) {
            item.isSelected = true;
            tmp += '<span>' + item.menuName + '</span>';
            item.Items.forEach(function(sItem) {
              if (sItem.menuId == threeLevel) {
                sItem.isSelected = true;
                isChange = true;
                tmp += '<span>' + sItem.menuName + '</span>';
              } else {
                sItem.isSelected = false;
              }
            });
          } else {
            item.isSelected = false;
          }
        });
      } else {
        menu.isSelected = false;
      }
    });
    if (store.getters.currentUrl == '/' || store.getters.currentUrl == '/Home') {
      state.path = '';
    } else {
      if (isChange) state.path = tmp;
    }
    return state.path;
  },
  system: state => state.system,
  systemMenuInq: state => state.systemMenuInq,
  pgmCd: state => state.pgmCd,
  prjCd: state => state.prjCd,
  getMenu: state => menuId => {
    let keyword1 = menuId.substr(0, 3); // ex) BID
    let keyword2 = menuId.substr(0, 4); // ex) BID3
    let keyword3 = menuId; // ex) BID306

    let top = state.menu.find(menu => menu.menuId.indexOf(keyword1) > -1); // menu > top
    let side = top.items.find(menu => menu.menuId.indexOf(keyword2) > -1); // top > side
    let sub = side.items.find(menu => menu.menuId == keyword3); // side > sub
    return sub;
  },

  //lgksja 202200318 불필요한 호출 제거용
  menuInitData: state => state.menuInitData,
};

const actions = {
  //1depth 메뉴 선택
  onSelectMenu({state}, e) {
    for (let sibling of Array.from(e.target.closest('ul').querySelectorAll('li'))) {
      sibling.classList.remove('on');
    }
    e.target.closest('li').classList.add('on');
    for (let menu of state.menu) {
      if (menu.menuId == e.target.dataset.menuId) {
        if (menu.isSelected) break; // 선택중이던 메뉴 클릭시 메뉴로직 안탐
        menu.isSelected = true;
        //메뉴 2depth 기본 선택 설정
        menu.Items.map((Items, i) => {
          if (i == 0) {
            Items.isSelected = true;
          } else {
            Items.isSelected = false;
          }

          //메뉴 3depth 기본 선택 모두 제거
          if (!Items.Items) Items.Items = [];
          Items.Items.map(sItems => {
            sItems.isSelected = false;
          });
        });
      } else {
        menu.isSelected = false;
      }
    }
  },
  //2depth 메뉴선택
  onSelectItems({state}, e) {
    for (let sibling of Array.from(e.target.closest('ul').querySelectorAll('li'))) {
      sibling.classList.remove('open');
    }
    e.target.closest('li').classList.add('open');

    const Items = state.menu.filter(item => item.isSelected)[0].Items;
    for (let menu of Items) {
      if (menu.menuId == e.target.dataset.menuId) {
        if (menu.isSelected) break; // 선택중이던 메뉴 클릭시 메뉴로직 안탐
        menu.isSelected = true;
        //메뉴 3depth 기본 선택 모두 제거
        menu.Items.map(Items => {
          Items.isSelected = false;
        });
      } else {
        menu.isSelected = false;
      }
    }
  },
  //시스템관리 > 프로그램메뉴 트리선택
  onSystemTreeMenu({state}, e) {
    state.system.frmGb = String(e.frmGb || '');
    state.system.parentMenuId = String(e.parentNode.menuId || e.parentMenuId || '');
    state.system.uppMnuNm = String(e.parentNode.menuName || e.uppMnuNm || '');
    state.system.menuId = String(e.menuId);
    state.system.menuName = String(e.menuName);
    state.system.orderSeq = String(e.orderSeq);
    state.system.svYn = String(e.svYn);
    state.system.vwYn = String(e.vwYn);
    state.system.delYn = String(e.delYn);
    state.system.prnYn = String(e.prnYn);
    state.system.webUrl = String(e.webUrl);
    state.system.para = String(e.para);
    state.system.lvl = String(e.lvl);
    state.system.useDs = String(e.useDs);
    state.system.beforeMenuId = String(e.menuId);
    state.system.beforeParentMenuId = String(e.parentNode.menuId || e.parentMenuId || '');
    state.system.cud = 0;
  },
  setMenu({state}, menu) {
    state.menu = menu;
    setTimeout(function() {
      document.querySelectorAll('[id=cheader] li')[0].children[0].click();
    }, 10);
  },
  onSystemMenuInq({state}, inq) {
    state.systemMenuInq = inq;
  },
  setPgmCd({state}, pgmCd) {
    state.pgmCd = pgmCd || 'INOG0001';
  },
  setPrjCd({state}, prjCd) {
    state.prjCd = prjCd || 'STANDARD';
  },
  // 특정메뉴 열기
  onOpenMenu(context, menuId) {
    let targetMenu = context.getters.getMenu(menuId);
    //alert(JSON.stringify(targetMenu));
    store.dispatch('mdi/addMDI', targetMenu);
  },

  //lgksja 202200318 불필요한 2번 호출 제거용
  setMenuInitData({state}, menuInitData) {
    state.menuInitData = menuInitData;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<script>
import {defineComponent, ref, reactive} from '@vue/composition-api';
import {useDialog} from '@/components/Iui/composables/useDialog.js';
import sample1 from './sample1.vue';

export default defineComponent({
  components: {
    sample1,
  },
  props: {
    text1: {
      type: String,
      default: undefined,
    },
  },
  setup(props, context) {
    const sampleDialog = useDialog('sampleDialog');

    const state = reactive({});

    const method = {
      btnApply_click() {
        sampleDialog.show('apply');
      },
    };

    return {
      state,
      ...method,
      ...sampleDialog.dialogRef,
      ...sampleDialog.method,
    };
  },
});
</script>

<template>
  <iui-dialog ref="sampleDialog" title="제목">
    <template #button-center>
      <iui-button @click="btnApply_click">적용</iui-button>
    </template>

    다이얼로그 샘플 : {{ text1 }}

    <hr />

    <sample1 />
  </iui-dialog>
</template>

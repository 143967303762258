<template>
  <pmis-content-box>
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <iui-container type="table" :headerWidth="width200">
            <i-row>
              <i-col-header>인지세 과세구간(부가세 포함)</i-col-header>
              <i-col-header>인지세액(1/계약당사자(N))</i-col-header>
            </i-row>
            <i-row>
              <i-col>1천만원 초과 ~</i-col>
              <i-col>2만원의 ½</i-col>
            </i-row>
            <i-row>
              <i-col>3천만원 초과 ~</i-col>
              <i-col>4만원의 ½</i-col>
            </i-row>
            <i-row>
              <i-col>5천만원 초과 ~</i-col>
              <i-col>7만원의 ½</i-col>
            </i-row>
            <i-row>
              <i-col>1억원 초과 ~</i-col>
              <i-col>15만원의 ½</i-col>
            </i-row>
            <i-row>
              <i-col>10억원 초과 ~</i-col>
              <i-col>35만원의 ½</i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <pmis-content-box no-border-box>
            <template v-slot:title>
              전자문서 인지세 납부방법 안내
            </template>
            <iui-container type="table">
              <i-row>
                <i-col style="text-align:left;">
                  수입인지는 전자수입인지 사이트(https://www.e-revenuestamp.or.kr/), 우체국, 은행에서 구매할 수 있으며
                  구매 후 본 전자계약시스템의 해당 전자문서에 반드시 첨부하여야 합니다.<br />
                  (전자수입인지 구매 문의 : 금융결재원 고객센터 1577-5500)<br />
                  참고로, 「인지세법」제8조와「인지세법시행령」제11조의2에 따른, 국세청 고시 제2017-11호(2017.6.23)
                  ｢전자문서에 대한 인지세 납부방법 등에 관한 고시｣ 제5조4항에 따라 전자문서 계약당사자가 인지세 무(과소)
                  납부시 가산세가 300% 부가됩니다.
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',
    };
  },
  methods: {},
};
</script>

<style></style>

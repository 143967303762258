<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>협력사목록</template>
          <template v-slot:header-left>
            <label>검색구분</label>
            <iui-select-old :items="searchSeItems" :value="searchSe" defaultCd="S" />
            <iui-text :value="searchNm" />
            <div class="mr15"></div>
            <label>지역</label>
            <iui-select-old defaultCd="S" />
            <iui-select-old defaultCd="S" />
            <iui-select-old defaultCd="S" />
          </template>
          <template v-slot:header-right>
            <iui-button value="검색" @click="onSearch" />
          </template>
          <iui-container type="ibsheet" :id="elId" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>회사기본정보</template>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>회사코드</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
              <i-spacer />
              <i-col-header>회사구분</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명</i-col-header>
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>회사명(영문)</i-col-header>
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>사업자번호</i-col-header>
              <i-col>
                <iui-text width="40px" />
                <span class="mr5">-</span>
                <iui-text width="40px" />
                <span class="mr5">-</span>
                <iui-text width="60px" />
              </i-col>
              <i-spacer />
              <i-col-header>주민/법인번호</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>대표자명</i-col-header>
              <i-col>
                <iui-searchbox :type="'worker'" :idEditable="false" :nameEditable="false" :paramBind="'searchInfo'" />
              </i-col>
              <i-spacer />
              <i-col-header>대표자명(영문)</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>대표전화</i-col-header>
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>주소</i-col-header>
              <i-col colspan="4">
                <iui-text width="70px" />
              </i-col>
            </i-row>
            <i-row>
              <i-spacer />
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-spacer />
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>홈페이지</i-col-header>
              <i-col colspan="4">
                <iui-text />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>업태</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
              <i-spacer />
              <i-col-header>종목</i-col-header>
              <i-col>
                <iui-text />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
      <i-spacer />
      <i-col>
        <iui-container type="css-flex">
          <i-row>
            <i-col>
              <pmis-content-box>
                <template v-slot:title>납품지역정보</template>
                <iui-container type="ibsheet" :id="elId2" />
              </pmis-content-box>
            </i-col>
          </i-row>
          <i-row>
            <i-col>
              <pmis-content-box>
                <template v-slot:title>공급물품(자재)정보</template>
                <iui-container type="ibsheet" :id="elId3" />
              </pmis-content-box>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  props: {
    pgmCd: {
      type: String,
      default: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).pgmCd : '',
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      elId2: 'sheetDiv2' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      elId3: 'sheetDiv3' + this._uid,
      sheetId3: 'sheet3' + this._uid,
      searchSe: '',
      searchNm: '',
      searchSeItems: [
        {text: '협력사명', value: 'frmNm'},
        {text: '사업자번호', value: 'bsnNo'},
        {text: '대표자명', value: 'rpsvNm'},
        {text: '담당자명', value: 'icprNm'},
      ],
    };
  },

  created() {
    this.addEvent([{name: 'ccpyList_confirm', func: this.onConfirm}]);
    this.onSearch();
  },

  mounted() {
    this.initSheet();
    this.initSheet2();
    this.initSheet3();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '선택',
            Name: 'chk',
            Type: 'Bool',
            Align: 'center',
            Width: 50,
            CanEdit: 1,
            BoolIcon: 0,
            Visible: 1,
          },
          {
            Header: '사업자번호',
            Name: 'bsnNo',
            Type: 'Text',
            Align: 'Center',
            Width: 150,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
            CustomFormat: '###-##-#####',
          },
          {
            Header: '협력사명',
            Name: 'frmNm',
            Type: 'Text',
            Align: 'Left',
            RelWidth: 1,
            MinWidth: 200,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '지역',
            Name: '지역',
            Type: 'Text',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '전화번호',
            Name: 'rpsvPhone',
            Type: 'Text',
            Align: 'Left',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '대표자명',
            Name: 'rpsvNm',
            Type: 'Text',
            Align: 'Center',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '담당자명',
            Name: 'icprNm',
            Type: 'Text',
            Align: 'Center',
            Width: 120,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
    },
    initSheet2() {
      const OPT = {
        Cols: [
          {
            Header: '순번',
            Name: '사업자번호',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '지역',
            Name: 'itemCode',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '대표지역여부',
            Name: 'itemName',
            Type: 'Text',
            RelWidth: 2,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet2) this.sheet2.dispose();
      this.sheet2 = IBSheet.create({
        id: this.sheetId2, // 시트 객체 ID
        el: this.elId2, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
    },
    initSheet3() {
      const OPT = {
        Cols: [
          {
            Header: '순번',
            Name: '1',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '대분류',
            Name: '2',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '중분류',
            Name: '3',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '소분류',
            Name: '4',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
          {
            Header: '비고',
            Name: '5',
            Type: 'Text',
            RelWidth: 1,
            Visible: 1,
            CanEdit: 0,
          },
        ],
        Cfg: {
          SearchMode: 2,
        },
      };

      if (this.sheet3) this.sheet3.dispose();
      this.sheet3 = IBSheet.create({
        id: this.sheetId3, // 시트 객체 ID
        el: this.elId3, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
    },
    onSearch() {
      let param = {
        isPopup: true,
        pgmCd: this.pgmCd,
      };

      axios.post('/customer/selectCustomerList', param).then(response => this.sheet.loadSearchData(response.data));
    },
    onConfirm(callback) {
      let checkedRows = this.sheet.getRowsByChecked('chk');
      if (typeof callback == 'function') {
        callback(checkedRows);
      }
    },
  },
};
</script>

<style></style>

<template>
	<div class="box_w">
		<table :id="elId" style="width: 100%; height: 100%"></table>
	</div>
</template>

<script>
export default {
	data() {
		return {
			elId: 'sheetDiv' + this._uid,
			sheetId: 'sheet' + this._uid,
		};
	},

	beforeCreate() {
		$mapGetters(this, ['searchInfo']);
	},

	created() {
		this.addEvent([
			{name: 'GrpMenuAuthList_selectUserMenuAuthList', func: this.selectUserMenuAuthList},
			{name: 'GrpMenuAuthList_grpMenuAuthSaveJson', func: this.grpMenuAuthSaveJson},
		]);
	},

	mounted() {
		this.initSheet();
	},
	methods: {
		async initSheet() {
			const me = this;

			var editoptionsYN = { value:"Y:있음;N:없음" };
			// eslint-disable-next-line
			var formatterYN = function(v) { return v=='Y'?'있음':'없음'; };

			const OPT = {
				//각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
				//컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
				Cols: [
					{
						label: '프로그램명',
						name: 'mnuNm',
						edittype: 'text',
						hidden: false,
						editable: false,
					},
					{
						label: '조회',
						name: 'vwYn',
						edittype:"select",
						editoptions: editoptionsYN,
						formatter: 'select',
						align: 'center',
						width: 70,
						hidden: false,
						editable: true,
					},
					{
						label: '저장',
						name: 'svYn',
						edittype:"select",
						editoptions: editoptionsYN,
						formatter: 'select',
						align: 'center',
						width: 70,
						hidden: false,
						editable: true,
					},
					{
						label: '삭제',
						name: 'delYn',
						edittype:"select",
						editoptions: editoptionsYN,
						formatter: 'select',
						align: 'center',
						width: 70,
						hidden: false,
						editable: true,
					},
					{
						label: '출력',
						name: 'prnYn',
						edittype:"select",
						editoptions: editoptionsYN,
						formatter: 'select',
						align: 'center',
						width: 70,
						hidden: false,
						editable: true,
					},
					{
						name: 'mnuCd',
						edittype: 'text',
						hidden: true,
						editable: false,
					},
				]
			};

			var $grid = $("#"+me.elId);
			$grid.jqGrid({
				height:200,
				width:800,
				autowidth:true,
				shrinkToFit:true,
				selectionMode : 'singlerow', 
				colModel: OPT.Cols,
				editable: true,
				treeGrid: true,
				treeGridModel: 'adjacency',
				ExpandColumn: 'mnuNm',
				jsonReader:{ root:"rows" },
				onSelectRow: function(rowid) {
					var $grid = $("#"+me.elId);
					//var lastRowId = $grid.lastRowId;
					if(rowid && rowid!==me.lastRowId && me.lastRowId){
						$grid.saveRow(me.lastRowId);
						$grid.restoreRow(me.lastRowId);
					}
					$grid.editRow(rowid,true);
					me.lastRowId = rowid;
					//var rowdata = $grid.getRowData(rowid);
					//me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
				} 
			});
			$gridAutoResize( $grid );


		},

		selectUserMenuAuthList(empNo) {
			const me = this;
			var $grid = $("#"+me.elId);

			$grid.jqGrid( 'clearGridData' );
			axios.post('/userAuth/selectUserMenuAuthList', {pgmCd: this.searchInfo.pgmCd, empNo: empNo}).then((response) => {
				if (response.status == 200) {
					var data = response.data;

					for(var i=0;i<data.length;i++) {
						var row = data[i];
						row.level = row.lvl-2;
						row.isLeaf = row.lvl==4;
						row.expanded = true;
						row.loaded = true;
						row.id = row.mnuCd;
						row.parent = row.uppMnuCd;
					}

					$grid[0].addJSONData( { rows:data });
					$grid.trigger('reloadGrid');
				}
			});
		},

		grpMenuAuthSaveJson(callback) {
			if (callback) {
				callback(this.sheet.getSaveJson().data);
			}
		},
	},
};
</script>

<style></style>

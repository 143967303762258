<script>
import {defineComponent, reactive, computed} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {ButtonTypeProps, useButtonType} from '@/components/Iui/composables/useButtonType';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';

export default defineComponent({
  name: 'iui-button',
  mixins: [IuiValidatorMixin],
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...ButtonTypeProps,

    type: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    },

    /**
     * TODO: autofocus
     */
    /**
     * form 관련 props
     */
    /**
     * HTML5
     * The form element that the button is associated with (its form owner). The value of the attribute must be the id attribute of a <form> element in the same document. If this attribute is not specified, the <button> element must be a descendant of a form element. This attribute enables you to place <button> elements anywhere within a document, not just as descendants of their <form> elements.
     */
    form: {
      type: String,
    },
    /**
     * HTML5
     * The URI of a program that processes the information submitted by the button. If specified, it overrides the action attribute of the button's form owner.
     */
    formAction: {
      type: String,
    },
    /**
     * HTML5
     * If the button is a submit button, this attribute specifies the type of content that is used to submit the form to the server. Possible values are:
     * - application/x-www-form-urlencoded: The default value if the attribute is not specified.
     * - multipart/form-data: Use this value if you are using an <input> element with the type attribute set to file.
     * - text/plain
     * If this attribute is specified, it overrides the enctype attribute of the button's form owner.
     */
    formEncType: {
      type: String,
    },
    /**
     * HTML5
     * If the button is a submit button, this attribute specifies the HTTP method that the browser uses to submit the form. Possible values are:
     * - post: The data from the form is included in the body of the form and is sent to the server.
     * - get: The data from the form are appended to the form attribute URI, with a '?' as a separator, and the resulting URI is sent to the server. Use this method when the form has no side-effects and contains only ASCII characters.
     * If specified, this attribute overrides the method attribute of the button's form owner.
     */
    formMethod: {
      type: String,
      validator: function(value) {
        return /^(get|post)$/i.test(value);
      },
    },
    /**
     * HTML5
     * If the button is a submit button, this Boolean attribute specifies that the form is not to be validated when it is submitted. If this attribute is specified, it overrides the novalidate attribute of the button's form owner.
     */
    formNoValidate: {
      type: String,
    },
    /**
     * HTML5
     * If the button is a submit button, this attribute is a name or keyword indicating where to display the response that is received after submitting the form. This is a name of, or keyword for, a browsing context (for example, tab, window, or inline frame). If this attribute is specified, it overrides the target attribute of the button's form owner. The following keywords have special meanings:
     * - _self: Load the response into the same browsing context as the current one. This value is the default if the attribute is not specified.
     * - _blank: Load the response into a new unnamed browsing context.
     * - _parent: Load the response into the parent browsing context of the current one. If there is no parent, this option behaves the same way as _self.
     * - _top: Load the response into the top-level browsing context (that is, the browsing context that is an ancestor of the current one, and has no parent). If there is no parent, this option behaves the same way as _self.
     */
    formTarget: {
      type: String,
    },
  },
  emits: ['click'],
  setup(props, context) {
    const Base = useBase(props, context, 'iui-button');
    const Layout = useLayout(props, context, 'iui-button');
    const Label = useLabel(props, context, Base);
    const ButtonType = useButtonType(props, context);

    const state = reactive({
      id: computed(() => Base.getId('button')),
      class: computed(() => {
        return {
          button: true,
          [ButtonType.state.type.className]: true,
          [ButtonType.state.color]: true,
        };
      }),
      style: computed(() => {
        return {};
      }),
    });

    const method = {
      button_click(e) {
        context.emit('click', e);
      },
    };

    return {
      Base,
      Layout,
      Label,
      ButtonType,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style">
    <label
      ref="label"
      v-if="Label.state.has"
      :id="Label.state.id"
      :for="state.id"
      class="label"
      :style="Label.state.style"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <button
      ref="button"
      :class="state.class"
      :type="type"
      :id="state.id"
      :form="form"
      :formaction="formAction"
      :formenctype="formEncType"
      :formmethod="formMethod"
      :formnovalidate="formNoValidate"
      :formtarget="formTarget"
      @click="button_click"
      :disabled="!Base.state.enable"
    >
      <slot />{{ value }}
    </button>
  </div>
</template>

<style></style>

<template>
  <div v-show="visible" :data-type="type" :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="textBoxId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <input
      v-if="type !== 'multi'"
      ref="input"
      type="text"
      v-model="formattedValue"
      :id="textBoxId"
      :placeholder="isValid ? '' : errorMessage"
      @click="input_click"
      @focus="input_focus"
      @blur="input_blur"
      @keydown="input_keydown"
      @input="input_input"
      @keyup.enter="input_keyup_enter"
      @change="input_change"
      :disabled="!enable"
      :readonly="readonly"
      :class="textBoxClass"
      :style="textBoxStyle"
    />
    <textarea
      v-if="type === 'multi'"
      ref="textarea"
      v-model="formattedValue"
      :id="textBoxId"
      :placeholder="isValid ? '' : errorMessage"
      @blur="input_blur"
      @change="input_change"
      :disabled="!enable"
      :readonly="readonly"
      :class="textBoxClass"
      :style="textBoxStyle"
    ></textarea>
    <span v-if="internalSuffix" v-bind:id="suffixId" class="suffix" :style="suffixStyle">{{ internalSuffix }}</span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';

const ITEM_MARGIN_RIGHT = '3px';
const SMALL_CODE_TEXTBOX_WIDTH = '60px';
const CODE_TEXTBOX_WIDTH = '90px';
const LARGE_CODE_TEXTBOX_WIDTH = '120px';
const NUMBER_SUFFIX = '원';
const NUMBER_TEXTBOX_WIDTH = '120px';
const NUMBER_SUFFIX_WIDTH = '20px';
const RATE_SUFFIX = '%';
const RATE_TEXTBOX_WIDTH = '60px';
const RATE_SUFFIX_WIDTH = '20px';

export default {
  name: 'iui-text-test',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
    },

    formObj: {
      type: String,
      default: 'none!',
    },
    event: {
      type: Array,
      default: function() {
        return [];
      },
    },
    // bindArray: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   },
    // },
  },
  data: function() {
    return {
      /**
       * 데이터값
       */
      dataValue: '',
      // /**
      //  * 데이터의 정규식 패턴
      //  */
      // dataPattern: null,
      // /**
      //  * 기존 데이터값
      //  */
      // oldDataValue: null,
      // /**
      //  * 기존 SelectionStart 위치
      //  */
      // oldSelectionStart: null,
      // /**
      //  * 기존 SelectionEnd 위치
      //  */
      // oldSelectionEnd: null,
      hasFocus: false,
      selection: {
        keydown: {
          start: null,
          end: null,
        },
        input: {
          start: null,
          end: null,
        },
        old: {
          start: null,
          end: null,
        },
      },
    };
  },
  computed: {
    // id
    textBoxId: {
      get: function() {
        return this.getId('input');
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-textbox': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    textBoxClass: {
      get() {
        let obj = {
          _target_: true,
          textbox: true,
          'text-align-right': this.isNumberFormat,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.internalWidth !== undefined) {
          obj.width = this.internalWidth;
          obj.flex = `0 0 ${this.internalWidth} !important`;
        }
        if (this.internalHeight !== undefined) {
          obj.height = this.internalHeight;
        }

        return obj;
      },
    },
    textBoxStyle: {
      get() {
        let obj = {
          width: undefined,
          flex: undefined,
        };

        // if (this.textBoxWidth !== undefined) {
        //   obj.width = this.textBoxWidth;
        //   obj.flex = `0 0 ${this.textBoxWidth}`;
        // }

        return obj;
      },
    },

    // TODO: etc
    isNumberFormat: {
      get: function() {
        return (
          this.type === 'number' ||
          this.type === 'currency' ||
          this.type === 'amount' ||
          this.type === 'unitAmount' ||
          this.type === 'quantity' ||
          this.type === 'rate'
        );

        //"12,34567".replace(/,/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        // "10000".replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      },
    },

    formattedValue: {
      get: function() {
        // console.log(`[formattedValue > get]`);
        // if (this.$refs && this.$refs.inptu) {
        //   console.log(
        //     `[formattedValue > get] Selection: ${this.$refs.input.selectionStart} ~ ${this.$refs.input.selectionEnd}`
        //   );
        // }

        let v = this.dataValue;

        v = this.formatValue(v);
        // if (process.env.NODE_ENV !== 'production') {
        //   v = this.setDataSuffix(v);
        // }

        return v;
      },

      set: function(newValue) {
        // console.log(`[formattedValue > set]`);
        // if (this.$refs && this.$refs.input) {
        //   console.log(
        //     `[formattedValue > set] Selection: ${this.$refs.input.selectionStart} ~ ${this.$refs.input.selectionEnd}`
        //   );
        // }

        this.dataValue = this.unformatValue(newValue);
      },
    },
  },
  watch: {
    // dataValue: function(newValue, oldValue) {
    //   // value = me.isNumberFormat ? this.addCommas(value) : value;

    //   // TODO: 임시
    //   if (newValue !== oldValue) {
    //     this.$emit('update:value', newValue);
    //     this.setBindArray(newValue);
    //     this.setFormData(newValue);
    //   }
    //   // this.setBindArray(newValue);
    //   // this.setFormData(newValue);
    // },
    value: function(newValue) {
      // props가 변경될 경우 formattedValue에 해당 값을 설정
      this.formattedValue = newValue;
    },
  },
  beforeCreate() {
    console.group(`[iui-text] beforeCreate`);
    console.groupEnd();
  },
  created() {
    console.group(`[iui-text] created`);

    if (this.type === 'smallcode') {
      if (this.internalWidth === undefined) {
        this.internalWidth = SMALL_CODE_TEXTBOX_WIDTH;
      }
    }
    if (this.type === 'code') {
      if (this.internalWidth === undefined) {
        this.internalWidth = CODE_TEXTBOX_WIDTH;
      }
    }
    if (this.type === 'largecode') {
      if (this.internalWidth === undefined) {
        this.internalWidth = LARGE_CODE_TEXTBOX_WIDTH;
      }
    }
    if (this.type === 'quantity') {
      if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
        this.internalWidth = NUMBER_TEXTBOX_WIDTH;
      }
    }
    if (this.type === 'currency' || this.type === 'amount' || this.type === 'unitAmount') {
      if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
        this.internalWidth = `calc(${NUMBER_TEXTBOX_WIDTH} + ${NUMBER_SUFFIX_WIDTH} + ${ITEM_MARGIN_RIGHT})`;
      }
      if (this.internalSuffix === undefined) {
        this.internalSuffix = NUMBER_SUFFIX;
      }
      if (this.internalaSuffixWidth === undefined) {
        this.internalSuffixWidth = NUMBER_SUFFIX_WIDTH;
      }
    }

    if (this.type === 'rate') {
      if (this.internalWidth === undefined && this.internalaSuffixWidth === undefined) {
        this.internalWidth = `calc(${RATE_TEXTBOX_WIDTH} + ${RATE_SUFFIX_WIDTH} + ${ITEM_MARGIN_RIGHT})`;
      }
      if (this.internalSuffix === undefined) {
        this.internalSuffix = RATE_SUFFIX;
      }
      if (this.internalSuffixWidth === undefined) {
        this.internalSuffixWidth = RATE_SUFFIX_WIDTH;
      }
    }

    if (this.value !== undefined) {
      if (this.value !== null) {
        if (
          (this.type === 'number' ||
            this.type === 'currency' ||
            this.type === 'amount' ||
            this.type === 'unitAmount' ||
            this.type === 'quantity' ||
            this.type === 'rate') &&
          isNaN(this.value)
        ) {
          this.dataValue = '';
        } else {
          this.dataValue = this.value.toString();
        }
      }
    }

    this.setBindArray();

    // validator 설정
    this.validatorTargetDataProp = 'dataValue';
    this.validationTriggerEventName = ['input', 'change', 'update:value'];

    console.groupEnd();
  },
  mounted() {
    console.group(`[iui-text] mounted`);

    // maxLength가 설정되어 있을 경우 maxlength 설정
    if (this.maxLength !== undefined) {
      if (this.type === 'multi') {
        this.$refs.textarea.setAttribute('maxlength', this.maxLength);
      } else {
        this.$refs.input.setAttribute('maxlength', this.maxLength);
      }
    }

    this.setFormData();
    this.OLD_mounted();

    console.groupEnd();
  },
  beforeUpdate() {
    console.group(`[iui-text] beforeUpdate`);
    console.groupEnd();
  },
  updated() {
    console.group(`[iui-text] updated`);
    console.groupEnd();
  },
  methods: {
    /**
     * computed를 v-model로 설정했을 경우
     * 1. keydown > keypress
     * 2. computed.formattedValue.set > input(데이터변경)
     * 3. beforeUpdate > computed.formattedValue.get > updated
     * 4. keyup > change
     *
     * 1. keydown
     * 2. computed.formattedValue.set > input(데이터변경)
     * 3. beforeUpdate > computed.formattedValue.get > updated
     * 4. keyup > change
     *
     * data를 v-model로 설정했을 경우
     * 1. keydown > keypress
     * 2. input(데이터변경)
     * 3. beforeUpdate > updated
     * 4. keyup > change
     *
     * 1. keydown
     * 2. input(데이터변경)
     * 3. beforeUpdate > updated
     * 4. keyup > change
     *
     * computed를 v-bind로 설정하고 $refs로 값을 변경했을 경우
     * data를 v-bind로 설정하고 $refs로 값을 변경했을 경우
     * 1. keydown > keypress
     * 2. input(데이터변경)
     * 3. keyup > change
     *
     *
     * 처리 순서
     * keydown
     * backspace, delete 등 키를 눌렀을 경우 selection 저장
     *
     * input
     * 변경된 데이터를 unformatValue
     * unformat된 데이터를 패턴 점검
     * formatValue
     * format된 데이터를 패턴 점검
     * format된 데이터를 input에 설정
     * selection 조정
     *
     */
    formatValue(value) {
      // console.log(`[formatValue] value - ${value}`);
      if (value === undefined || value === null) {
        value = '';
      }

      if (!this.hasFocus) {
        if (
          this.type === 'number' ||
          this.type === 'currency' ||
          this.type === 'amount' ||
          this.type === 'unitAmount' ||
          this.type === 'quantity' ||
          this.type === 'rate'
        ) {
          value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }

      return value;
    },
    unformatValue(value) {
      // console.log(`[unformatValue] value - ${value}`);
      if (value === undefined || value === null) {
        value = '';
      }

      if (
        this.type === 'number' ||
        this.type === 'currency' ||
        this.type === 'amount' ||
        this.type === 'unitAmount' ||
        this.type === 'quantity' ||
        this.type === 'rate'
      ) {
        value = value.toString().replace(/,/g, '');
      }

      return value;
    },
    setDataSuffix(value) {
      // console.log(`[setDataSuffix] value - ${value}`);
      if (!this.hasFocus && value.length > 0) {
        if (this.type === 'currency' || this.type === 'amount' || this.type === 'unitAmount') {
          value += ' 원';
        }

        if (this.type === 'rate') {
          value += ' %';
        }
      }

      return value;
    },
    /**
     * 아래 이밴드 핸들러들은 이밴드 발생 순서에 따라 코드가 작성되어 있다.
     */
    /**
     * input의 focus 이밴트 핸들러
     * @param {Object} FocusEvent
     */
    input_focus(e) {
      this.hasFocus = true;
      this.$emit('focus', e);
    },
    /**
     * input의 click 이벤트 핸들러
     * @param {Object} MouseEvent
     */
    input_click(e) {
      this.$emit('click', e);
    },
    /**
     * input의 blur 이밴트 핸들러
     * @param {Object} FocusEvent
     */
    input_blur(e) {
      this.hasFocus = false;
      this.$emit('blur', e);
    },
    /**
     * input의 keydown 이벤트 핸들러
     * @param {Object} KeyboardEvent
     */
    input_keydown(e) {
      // console.log(`[keydown]`);
      this.selection.keydown.start = this.$refs.input.selectionStart;
      this.selection.keydown.end = this.$refs.input.selectionEnd;
      this.$emit('keydown', e);
    },
    // /**
    //  * input의 keypress 이벤트 핸들러
    //  * @param {Object} KeyboardEvent
    //  */
    // input_keypress() {
    //   console.log(`[kepress]`);
    // },
    /**
     * input의 input 이벤트 핸들러
     * @param {Object} InputEvent
     */
    input_input(e) {
      // console.log(`[input]`);
      // console.log(e);
      // console.log(`[input] Selection: ${this.$refs.input.selectionStart} ~ ${this.$refs.input.selectionEnd}`);
      // console.log(`[input] value: ${this.$refs.input.value}`);

      this.selection.input.start = this.$refs.input.selectionStart;
      this.selection.input.end = this.$refs.input.selectionEnd;

      this.$emit('input', e);
      this.$emit('update:value', this.dataValue);
      this.setBindArray(this.dataValue);
      this.setFormData(this.dataValue);
    },
    // /**
    //  * input의 keyup 이벤트 핸들러
    //  * @param {Object} KeyboardEvent
    //  */
    // input_keyup() {
    //   console.log(`[keyup]`);
    // },
    /**
     * input의 keyup.enter 이벤트 핸들러
     * @param {Object} KeyboardEvent
     */
    input_keyup_enter(e) {
      this.$emit('enter', e);
    },
    /**
     * input의 change 이벤트 핸들러
     * @param {Object} Event
     */
    input_change(e) {
      // console.log(`[change]`);
      // console.log(e);
      this.$emit('change', e);
      this.$emit('update:value', this.dataValue);
      this.setBindArray(this.dataValue);
      this.setFormData(this.dataValue);

      // const me = this;
      // let value = e.target.value;

      // if ('' != me.valid) {
      //   let regExp = '';
      //   let message = '';
      //   if (me.valid == 'num') {
      //     regExp = /^[0-9]*$/;
      //     if (!regExp.test(value)) message = '숫자만 입력 가능합니다.';
      //   } else if (me.valid == 'eng') {
      //     regExp = /^[A-Za-z]*$/;
      //     if (!regExp.test(value)) message = '영문만 입력 가능합니다.';
      //   } else if (me.valid == 'kor') {
      //     regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      //     if (!regExp.test(value)) message = '한글만 입력 가능합니다.';
      //   } else if (me.valid == 'email') {
      //     regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      //     if (!regExp.test(value)) message = '이메일 형식이 맞지 않습니다.';
      //   } else if (me.valid == 'phone') {
      //     regExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
      //     if (!regExp.test(value)) message = '휴대폰 번호 형식이 맞지 않습니다.';
      //   } else if (me.valid == 'specialChar') {
      //     regExp = /[{}[\]/?.,;:|)*~`!^\-_+┼<>@#$%&'"\\(=]/gi;
      //     if (regExp.test(value)) message = '특수문자 입력 불가능합니다.';
      //   }

      //   if (message != '') {
      //     e.target.value = '';
      //     alert(message);
      //   }
      // }

      // this.$emit('update:value', e.target.value); // sync 지원
    },
    // addCommas: function(value) {
    //   value = strReplaceAll(value, ',', '');
    //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // },
    /**
     * TODO: 정리 필요
     */
    setBindArray: function(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = me.isNumberFormat ? strReplaceAll(value, ',', '') : value;
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    setFormData(value) {
      // console.log(`setFormData - ${value}`);
      const me = this;
      setTimeout(function() {
        me.$store.dispatch('setFormData', {
          formObj: me.formObj,
          key: me.name,
          value: !value ? me.dataValue : value,
          element: me.$el.querySelector('._target_'),
        });
      }, 10);
    },
    OLD_mounted() {
      //속성 주입
      for (let k in this.$attrs) {
        this.$el.querySelector('._target_').setAttribute(k, this.$attrs[k]);
        this.$el.removeAttribute(k);
        if (k == 'id' && this.$el.querySelector('label')) {
          this.$el.querySelector('label').setAttribute('for', this.$attrs[k]);
        }
      }

      //이벤트 주입
      for (let e of this.event) {
        this.$el.querySelector('._target_').addEventListener(e.name, e.callback);
      }

      //스타일 주입
      for (let k in this.css) {
        if (k == 'width') {
          this.$el.style[k] = this.css[k];
          if (this.suffix) {
            let swith = this.$el.querySelector('._suffix').offsetWidth + 6;
            this.$el.querySelector('._target_').style[k] = 'calc(100% - ' + swith + 'px)';
          } else {
            this.$el.querySelector('._target_').style[k] = '100%';
          }
          this.$el.querySelector('._target_').style['padding'] = '2px 0px';
        } else {
          this.$el.querySelector('._target_').style[k] = this.css[k];
        }
      }

      // if (this.isNumberFormat) {
      //   this.$el.querySelector('._target_').style['text-align'] = 'right';
      // }

      // //기본요소 height와 라벨 height 맞춤
      // if (this.$el.querySelector('label')) {
      //   this.$el.querySelector('label').style.height = this.$el.querySelector('._target_').offsetHeight + 'px';
      //   this.$el.querySelector('label').style.lineHeight = this.$el.querySelector('._target_').offsetHeight + 'px';
      // }

      this.name = this.$el.querySelector('._target_').name;
    },
  },
};

// // Restricts input for the given textbox to the given inputFilter.
// function setInputFilter(textbox, inputFilter) {
//   ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
//     textbox.addEventListener(event, function() {
//       if (inputFilter(this.value)) {
//         this.oldValue = this.value;
//         this.oldSelectionStart = this.selectionStart;
//         this.oldSelectionEnd = this.selectionEnd;
//       } else if (this.hasOwnProperty("oldValue")) {
//         this.value = this.oldValue;
//         this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
//       } else {
//         this.value = "";
//       }
//     });
//   });
// }

// // Install input filters.
// setInputFilter(document.getElementById("intTextBox"), function(value) {
//   return /^-?\d*$/.test(value); });
// setInputFilter(document.getElementById("uintTextBox"), function(value) {
//   return /^\d*$/.test(value); });
// setInputFilter(document.getElementById("intLimitTextBox"), function(value) {
//   return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 500); });
// setInputFilter(document.getElementById("floatTextBox"), function(value) {
//   return /^-?\d*[.,]?\d*$/.test(value); });
// setInputFilter(document.getElementById("currencyTextBox"), function(value) {
//   return /^-?\d*[.,]?\d{0,2}$/.test(value); });
// setInputFilter(document.getElementById("latinTextBox"), function(value) {
//   return /^[a-z]*$/i.test(value); });
// setInputFilter(document.getElementById("hexTextBox"), function(value) {
//   return /^[0-9a-f]*$/i.test(value); });
</script>

<style lang="scss">
// @import '../Iui/Iui.scss';

// .iui-textbox {
//   @include iui-element-box('100%', '1', true, true, true);

//   // * {
//   //   font-family: '맑은 고딕' !important;
//   //   font-size: 12px !important;
//   // }

//   .textbox {
//     @include iui-element-box-element;
//   }

//   .text-align-right {
//     text-align: right;
//   }
// }
</style>

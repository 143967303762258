const state = () => ({
  //회사정보
  companyInfo: {
    pgmCd: '', // 회사코드
    frmNm: '', // 회사명
    frmEnNm: '', // 회사명(영문)
    bsnNo: '', // 사업자번호
    frmGb: '', // 회사구분
    regiNo: '', // 주민/법인번호
    rpsvNm: '', // 대표자명
    rpsvEnNm: '', // 대표자명(영문)
    rpsvPhone: '', // 대표전화번호
    postNo: '', // 우편번호
    adr1: '', // 주소
    adr2: '', // 상세주소
    homepage: '', // 홈페이지
    bcoNm: '', // 업태
    itemNm: '', // 종목
    bankCode: '', // 은행코드
    depositNo: '', // 계좌번호
    depositor: '', // 예금주
    lgFlNo: 0, // 회사로고파일번호
    djFlNo: 0, // 관인파일번호
    smFlNo: 0, // 회사심볼파일번호
    flNo: 0, // 파일번호
    slogan: '', // 회사슬로건
    femployInsurDs: '', // 외국인고용보험산출여부
    decimalDs: '', // 일노무비소숫점관리방법
    taxDs: '', // 소득세산출방식
    useFlag: '', // 회사정보승인Flag
    useStrdate: '', // 시작(계약)일자
    useEnddate: '', // 종료일자
  },

  //사업자번호 중복확인 0:확인필요, 1: 중복없음, 2: 중복있음
  dupBsnNoStatus: 0,

  //관리자 정보
  worker: {
    name: '', // 이름
    empNo: '', // 아이디(사번)
    tlno: '', // 전화번호
    mobileTlno: '', // 휴대폰번호
    rpsvFaxno: '', //팩스번호
    useSMS: '', // SMS수신동의여부
    email1: '', //이메일
    email2: '000099', //이메일
  },

  areaList: [],
  matList: [],
  gcodeList: [],
  licenseList: [],
  docnmList: [],
  ceoList: [],

  //파일 업로드 여부 체크를 위한 obj
  fileFlag: {
    lgFl: false,
    djFl: false,
    smFl: false,
    fl: false,
  },
});
const getters = {
  companyInfo: state => state.companyInfo,
  worker: state => state.worker,
  fileFlag: state => state.fileFlag,
  dupBsnNoStatus: state => state.dupBsnNoStatus,
  areaList: state => state.areaList,
  matList: state => state.matList,
  gcodeList: state => state.gcodeList,
  licenseList: state => state.licenseList,
  docnmList: state => state.docnmList,
  ceoList: state => state.ceoList,
};
const mutations = {
  initCompanyInfo(state) {
    for (let key in state.companyInfo) {
      if (['frmGb', 'lgFlNo', 'djFlNo', 'smFlNo', 'flNo'].indexOf(key) == -1) state.companyInfo[key] = '';
    }
    for (let key in state.worker) {
      state.worker[key] = '';
    }
    state.areaList = [];
    state.matList = [];
    state.gcodeList = [];
    state.licenseList = [];
    state.docnmList = [];
    state.ceoList = [];
  },
  setCompanyInfo(state, payload) {
    for (let key in state.companyInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.companyInfo[key] = payload[key];
      }
    }
  },
  setWorker(state, payload) {
    for (let key in state.worker) {
      if (payload && payload.hasOwnProperty(key)) {
        state.worker[key] = payload[key];
      }
    }
  },
  initFileFlag(state) {
    for (let key in state.fileFlag) {
      state.fileFlag[key] = false;
    }
  },
  setFileFlag(state, payload) {
    for (let key in state.fileFlag) {
      if (payload && payload.hasOwnProperty(key)) {
        state.fileFlag[key] = payload[key];
      }
    }
  },
  setDupBsnNoStatus(state, payload) {
    state.dupBsnNoStatus = payload;
  },

  setAreaList(state, payload) {
    state.areaList = payload;
  },
  setMatList(state, payload) {
    state.matList = payload;
  },
  setGcodeList(state, payload) {
    state.gcodeList = payload;
  },
  setLicenseList(state, payload) {
    state.licenseList = payload;
  },
  setDocnmList(state, payload) {
    state.docnmList = payload;
  },
  setCeoList(state, payload) {
    state.ceoList = payload;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <pmis-content-box :loading="listBoxLoading">
    <template v-slot:title>
      견적동의서
    </template>
    <template v-slot:title-left>
      <font style="color:#FF6666; font-weight: bold;">(더 많은 정보는 로그인 후 사용 바랍니다.)</font>
    </template>
    <template #title-right>
      <iui-button color="blue" v-show="isCanSign" @click="onSign">동의</iui-button>
      <iui-button color="blue" v-show="isCanReject" @click="onReject">반송</iui-button>
    </template>

    <iui-container type="table" :theme="theme" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            ></iframe>
          </div>
        </i-col>
      </i-row>
      <i-row v-show="isShowReject">
        <i-col-header>반송 사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            :value="signInfo.retrnResnCn"
            :enable="true"
            height="40px"
            required
            @change="signInfo.retrnResnCn = $event.target.value"
          />
        </i-col>
      </i-row>
      <i-row v-show="isShowSign">
        <i-col-header>서명방법</i-col-header>
        <i-col>
          <iui-select-old
            name="signMthCd"
            :items="arrSignMthCd"
            :value="signInfo.signMthCd"
            :enable="true"
            required
            @change="onSetSignMthCd"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  name: 'estimate-view',
  created() {
    this.addEvent({name: 'Estimate_Search', func: this.onSearch});
    console.log('addEvent', 'Estimate_Search');
    window.addEventListener('message', this.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: '',
      OZParam: {},

      arrSignMthCd: [
        {text: '선택', value: ''},
        {text: '공동인증서', value: '10'},
        // {text: '디지털서명', value: '30'},
      ],

      isReject: false,
      isSign: false,

      signerInfoVo: {
        tradeNo: '',
        tradeSuffix: '',
        personId: '',
        businessNo: '',
        mphone: '',
        userNm: '',
        canSign: false,
        corporate: false,
        customer: false,
        osep: false,
        buyagnt: false,
        personal: false,
      },

      signInfo: {
        signSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        tradeNo: '',
        tradeSuffix: '',
        estmtNo: '',
        estmtOdr: 0,
        reqstdocNo: '',
        reqstdocOdr: 0,
        reqstdocDivCd: '',
        signMthCd: '',
        signerSeCd: '',
        retrnResnCn: '',
        signDt: '',
        signoneCn: '',
        signtwoCn: '',
        signthreeCn: '',
        signfourCn: '',
        signfiveCn: '',
        agentIdntyImageCn: '',
        fileNo: 0,
        accesIp: '',
        accesEnvrnCn: '',
        pkcs7SignedMessage: '',
        signerDn: '',
        vidRandom: '',
        personId: '',
        businessNo: '',
        passNo: 0,
        corporate: false,
        customer: false,
        osep: false,
        buyagnt: false,
        personal: false,

        //ITSR62600
        tradeType:'',
        tradeName:'',
      },

      isVerifyPASS: false,
    };
  },
  mounted() {
    this.$secuKit.init();
    this.$secuKit.checkReady();
    console.log('checkReady = ' + this.$secuKit.secuKitReady);
  },
  methods: {
    ...mapMutations('EstimateStore', {
      setEstimateInfo: 'setEstimateInfo',
    }),
    onSetSignMthCd(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;

      if (value == '30') {
        // 디지털 서명
        this.onViewReport(true, 'Y');
      } else {
        this.onViewReport(true, 'N');
      }

      this.signInfo[target] = value;
    },
    onViewReport(isReload, signable) {
      // var signable    = (eval(this.signerInfoVo.canSign) == true ? 'Y' : 'N')
      var signerSeCd = '30';
      var tradeNo = eval(this.signerInfoVo.canSign) == true ? this.signerInfoVo.tradeNo : '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      let param = {
        checkContNo: this.estimateInfo.ctrtNo,
        checkSeq: this.estimateInfo.ctrtOdr,
        manageCd: this.estimateInfo.docCat1,
      };

      axios
        .post('/ozrpt/selectOzTCtrtEstimate', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign({}, response.data.jsonData);

          this.OZParam.reportname = response.data.reportPathName;
          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
          };

          this.urlParam = process.env.VUE_APP_OZ_VIEWER_URL;

          if (isReload) {
            //OZ Viewer 에서 message 를 호출할 Event 등록.
            window.addEventListener('message', this.reqSetOzParam, false);

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/ false || !!document.documentMode;

            if (isIE) {
              document.getElementById('ozFrame').src = process.env.VUE_APP_OZ_VIEWER_URL;
            } else {
              //OZ Reload
              document.getElementById('ozFrame').contentWindow.location.reload(true);
            }
          }
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
      }
    },
    async onSearch(isReload) {
      console.log('Estimate_Search', 'onSearch');
      console.log('decParams.ctrtNo', this.decParams.ctrtNo);
      console.log('decParams.ctrtOdr', this.decParams.ctrtOdr);
      console.log('decParams.ctrtDivCd', this.decParams.ctrtDivCd);
      console.log('decParams.tradeNo', this.decParams.tradeNo);
      console.log('decParams.tradeSfx', this.decParams.tradeSfx);

      this.isReject = false;
      this.isSign = false;

      let param = {
        ctrtNo: this.decParams.ctrtNo,
        ctrtOdr: this.decParams.ctrtOdr,
        ctrtDivCd: this.decParams.ctrtDivCd,
        tradeNo: this.decParams.tradeNo,
      };

      console.log('param', param);
      if (param.ctrtNo === undefined || param.ctrtNo == '') return;

      try {
        this.listBoxLoading = true;
        let response = await axios.post('/urlLink/selectEstimateInfo', param);
        this.listBoxLoading = false;
        console.log(response.data);

        this.setEstimateInfo(response.data.estimateInfo);

        let param2 = {
          ctrtNo: this.decParams.ctrtNo,
          ctrtOdr: this.decParams.ctrtOdr,
          ctrtDivCd: this.decParams.ctrtDivCd,
          tradeNo: this.decParams.tradeNo,
          tradeSfx: this.decParams.tradeSfx,
        };
        let response2 = await axios.post('/urlLink/selectEstimateSignerInfo', param2);

        console.log(response2);
        this.signerInfoVo = response2.data;

        this.signInfo.ctrtNo = this.estimateInfo.ctrtNo;
        this.signInfo.ctrtOdr = this.estimateInfo.ctrtOdr;
        this.signInfo.ctrtDivCd = this.estimateInfo.ctrtDivCd;
        this.signInfo.tradeNo = this.signerInfoVo.tradeNo;
        this.signInfo.tradeSuffix = this.signerInfoVo.tradeSuffix;
        this.signInfo.canSign = this.signerInfoVo.canSign;
        this.signInfo.corporate = this.signerInfoVo.corporate;
        this.signInfo.customer = this.signerInfoVo.customer;
        this.signInfo.osep = this.signerInfoVo.osep;
        this.signInfo.buyagnt = this.signerInfoVo.buyagnt;
        this.signInfo.personal = this.signerInfoVo.personal;
        this.signInfo.personId = this.signerInfoVo.personId;
        this.signInfo.businessNo = this.signerInfoVo.businessNo;
        this.signInfo.signerSeCd = '30';

        //ITSR62600
        this.signInfo.tradeType = (this.signerInfoVo.customer)?"CUST":"CCPY";
        this.signInfo.tradeName = this.signerInfoVo.tradeName;

        this.signInfo.retrnResnCn = '';
        this.signInfo.agentIdntyImageCn = '';

        if (eval(this.signInfo.canSign) == true && eval(this.signInfo.corporate) == false) {
          this.arrSignMthCd.push({text: '디지털서명', value: '30'});
        }

        if (this.signInfo.signMthCd == '') {
          this.onSetSignMthCd(this.arrSignMthCd[1].value, 'signMthCd');
        } else {
          this.onViewReport(isReload, 'N');
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSign() {
      if (this.isReject) this.isReject = false;
      if (!this.isSign) this.isSign = true;

      if (!this.signInfo.signMthCd.trim()) {
        this.$alert('서명방법을 선택해야합니다.');
        return;
      }

      if (eval(this.signInfo.corporate) == true && this.signInfo.signMthCd == '30') {
        this.$alert('법인 사업자는 서명방법을 디지털서명을 선택할 수 없습니다.');
        this.signInfo.signMthCd = '';
        return;
      }

      var ozVal = '';
      if (this.signInfo.signMthCd == '10') {
        console.log('this.estimateInfo.ctrtOrginlHashcd', this.estimateInfo.ctrtOrginlHashcd);

        let checkReady = this.$secuKit.secuKitReady;
        console.log('checkReady = ' + checkReady);

        if (!checkReady) {
          this.$alert('공동인증서 툴킷 로딩이 완료되지 못했습니다.');
          return;
        }

        if (!(await this.$confirm('전자서명 하시겠습니까?'))) return;

        const r1 = await this.$secuKit.showDialog();
        console.log(r1);

        const certID = this.$secuKit.certListInfo.getCertID();
        console.log('certID', certID);

        let data = {
          certType: 'SignCert',
          sourceString: this.estimateInfo.ctrtOrginlHashcd,
          certID: certID,
        };

        const r2 = await this.$secuKit.invoke('generatePKCS7SignedData', data);
        console.log(r2);

        this.signInfo.pkcs7SignedMessage = r2.generatePKCS7SignedData;

        console.log('PKCS7SignedMessage', this.signInfo.pkcs7SignedMessage);

        let data2 = {
          certType: 'SignCert',
          certID: certID,
          isViewVID: '1',
        };
        const r3 = await this.$secuKit.invoke('viewCertInfomationWithVID', data2);
        console.log(r3);

        this.signInfo.vidRandom = r3.vidRandom;
        this.signInfo.signerDn = r3.userDN;

        console.log('vidRandom', this.signInfo.vidRandom);
        console.log('signerDn', this.signInfo.signerDn);

        let data3 = {
          certType: 'SignCert',
          sourceString: r3.vidRandom,
          certID: certID,
        };

        const r4 = await this.$secuKit.invoke('generatePKCS7SignedData', data3);
        console.log(r4);

        this.signInfo.vidRandom = r4.generatePKCS7SignedData;

        if (this.signInfo.pkcs7SignedMessage == '') {
          this.$alert('전자서명한 값이 반드시 필요합니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }

        let data4 = {
          signedType: '1',
          signedString: removePEMHeader(removeCRLF(this.signInfo.pkcs7SignedMessage)),
        };

        const r5 = await this.$secuKit.verifyPKCS7SignedData(data4);
        console.log(r5);

        console.log('VerifyPKCS7SignedData', r5.verifyPKCS7SignedData);

        if (this.estimateInfo.ctrtOrginlHashcd != r5.verifyPKCS7SignedData) {
          this.$alert('원문과 전자서명 검증 원문이 일치하지 않습니다.', () => {
            this.signInfo.pkcs7SignedMessage = '';
            this.signInfo.signerDn = '';
          });
          return;
        }
      } else if (this.signInfo.signMthCd == '20') {
        // 모바일 인증서 서명은 웹에서는 해당 없음.
      } else if (this.signInfo.signMthCd == '30') {
        // 디지털서명은 PASS 인증을 수행하고 통과 해야 디지털 서명이 가능하다.
        if (!this.isVerifyPASS) {
          this.signInfo.passNo = 0;
          console.log('this.signerInfoVo.userNm', this.signerInfoVo.userNm);
          console.log('this.signerInfoVo.mphone', this.signerInfoVo.mphone);
          var passResult = await this.$PASSCertification(this.signerInfoVo.userNm, this.signerInfoVo.mphone);
          console.log(passResult);
          if (passResult.result) {
            this.isVerifyPASS = passResult.result;
            this.signInfo.passNo = passResult.data.passNo;
          } else {
            this.$alert('PASS 인증에 실패하여 디지털서명을 수행할 수 없습니다..');
            return;
          }
        }

        //OZ Viewer 의 입력값들를 리턴받음.
        ozVal = document.getElementById('ozFrame').contentWindow.getOzInputData();
        console.log('OZ Viewer input ', ozVal);
        if (ozVal == null || ozVal == '') {
          // this.$alert('디지털 전자서명 값이 존재하지 않습니다.');
          return;
        }
        this.signInfo.agentIdntyImageCn = ozVal[ozVal.signedFormId];

        if (this.signInfo.agentIdntyImageCn == undefined) {
          this.signInfo.agentIdntyImageCn = '';
          this.$alert('현재 문서의 서식은 디지털서명을 지원하지 않습니다. 다른 서명방법을 선택 해 주세요.');
          return;
        }

        if (!this.signInfo.agentIdntyImageCn.trim()) {
          this.$alert('견적서의 서명란에 디지털서명을 해야합니다.');
          return;
        }
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'LinkView',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/verifyEstimatePKCS7SignedData', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('동의가 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('동의 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    async onReject() {
      if (!this.isReject) this.isReject = true;
      if (this.isSign) this.isSign = false;

      console.log(this.estimateInfo);

      if (!this.signInfo.retrnResnCn.trim()) {
        this.$alert('반송 사유를 입력해야합니다.');
        return;
      }

      let param = {
        ...this.signInfo,
        pgmCd: '201900',
        chngPgmId: 'LinkView',
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/urlLink/rejectEstimate', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          me.$alert('반송이 완료되었습니다.');
          me.onSearch(true);
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('반송 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    error(err) {
      console.log(err);
    },
  },
  computed: {
    ...mapGetters('LinkViewStore', {
      decParams: 'decParams',
    }),
    ...mapGetters('EstimateStore', {
      estimateInfo: 'estimateInfo',
    }),
    isShowReject() {
      return this.isReject;
    },
    isShowSign() {
      return this.isSign;
    },
    isCanSign() {
      return (
        eval(this.signerInfoVo.osep) == true &&
        eval(this.signerInfoVo.canSign) == true &&
        (this.estimateInfo.ctrtSttusCd == '10' || this.estimateInfo.ctrtSttusCd == '20')
      );
    },
    isCanReject() {
      return (
        eval(this.signerInfoVo.osep) == true &&
        eval(this.signerInfoVo.canSign) == true &&
        (this.estimateInfo.ctrtSttusCd == '10' || this.estimateInfo.ctrtSttusCd == '20')
      );
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100" style="height:100%">
      <colgroup>
        <col width="25%" />
        <col />
      </colgroup>
      <i-row>
        <i-col colspan="2">
          <div style="width:100%; height:100%" id="iOzViewer">
            <iframe
              :src="urlParam"
              id="ozFrame"
              name="ozFrame"
              style="width:100%; height:100%; border:0px solid red;"
              scrolling="no"
              title="Iframe Example"
            ></iframe>
          </div>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    cnfrmnNo: {
      type: String,
      default: undefined,
    },
    OZParam: {
      type: Object,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  beforeCreate() {},
  created() {
    var me = this;
    //OZ Viewer 에서 message 를 호출.
    window.addEventListener('message', me.reqSetOzParam, false);
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      urlParam: `${process.env.VUE_APP_OZ_QRY_VIEWER_URL}`,
    };
  },
  mounted() {
    this.listBoxLoading = true;
  },
  methods: {
    reqSetOzParam(e) {
      if (e.data.msg == 'requestOzParam') {
        console.log('Parent:::reqSetOzParam:::', e.data);
        console.log('Parent:::reqSetOzParam:::OZParam==', this.paramObj.OZParam);

        //OZ View 의 파라미터로 부모객체를 전달하기 위해 receiveOzParam 함수를 호출.
        document.getElementById('ozFrame').contentWindow.receiveOzParam(this.paramObj.OZParam);

        //Event Listener 를 제거.
        window.removeEventListener('message', this.reqSetOzParam, false);
        this.listBoxLoading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>

<template>
  <pmis-content-box>
    <template v-slot:title>공동대표정보</template>
    <template v-slot:title-right>
      <iui-button class="btn" value="신규" @click="onAdd()" v-show="enable" />
      <iui-button class="btn" value="저장" @click="onSave()" v-if="companyInfo.pgmCd" v-show="enable" />
      <iui-button class="btn" value="삭제" @click="onDelete()" v-show="enable" />
    </template>
    <iui-container type="css-flex">
      <i-row height="300px">
        <i-col>
          <iui-container type="ibsheet" :id="elId" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'ceoList']);
  },

  created() {
    this.addEvent([
      {name: 'ceoInfo_getSaveJson', func: this.getSaveJson},
      {name: 'onFocusedCeoListRowData', func: this.onFocusedCeoListRowData},
      {name: 'saveCeoList', func: this.saveCeoList},
    ]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/ceoList`],
      value => {
        if (value && value.length) {
          let load = setInterval(() => {
            if (this.sheet) {
              clearInterval(load);
              this.sheet.loadSearchData(value);
            }
          }, 10);
        } else {
          if (this.sheet) {
            this.sheet.removeAll();
          }
        }
      }
    );
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
          ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
          IgnoreFocused: 1,
        },
        Cols: [
          {
            Header: '선택',
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            CanEdit: true,
            Visible: this.enable,
          },
          {
            Header: '순번',
            Name: 'seq',
            Type: 'Text',
            Width: 50,
            CanEdit: false,
          },
          {
            Header: '대표자명',
            Name: 'rpsvNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
          },
          {
            Header: '대표자명(영문)',
            Name: 'rpsvEnNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
          },
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      if (!this.companyInfo.pgmCd) {
        this.sheet.loadSearchData(this.ceoList);
      } else {
        this.onSearchCeoList(this.companyInfo.pgmCd);
      }
    },

    onAdd() {
      this.sheet.addRow();
    },
    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert('저장할 공동대표정보가 존재하지 않습니다.');
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }
      if (await this.$confirm('저장하시겠습니까?')) {
        this.saveCeoList();
      }
    },
    async saveCeoList() {
      let saveJson = this.sheet.getSaveJson().data;
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });
      await axios
        .post('/companyRegist/saveCeoList', {ceoList: $_statusToCud(saveJson)})
        .then(response => {
          if (response.status == 200) {
            this.onSearchCeoList(this.companyInfo.pgmCd);
            this.$alert('저장을 완료하였습니다.');
          } else {
            this.$alert('저장을 실패하였습니다.');
          }
        })
        .catch(ex => {
          console.log('저장을 실패하였습니다. : ', ex);
        });
    },
    onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');

      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      checkedRows.forEach(row => {
        if (row.pgmCd) {
          this.sheet.deleteRow(row);
        } else {
          this.sheet.removeRow(row);
        }
      });
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson(0).data);
      else {
        return this.sheet.getSaveJson().data;
      }
    },

    async onSearchCeoList(pgmCd) {
      await axios.post('/companyRegist/selectCeoList', {pgmCd: pgmCd}).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  }, //methods

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style></style>

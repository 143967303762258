<script>
import {defineComponent, ref, reactive, computed, watch, onMounted, getCurrentInstance} from '@vue/composition-api';

const ibsheetOptions = {
  Cfg: {
    CanEdit: 3,
  },
  Cols: [
    {
      Header: '보기',
      Name: '보기',
    },
    {
      Header: '키값1',
      Name: 'key1',
    },
    {
      Header: '키값2',
      Name: 'key2',
    },
    {
      Header: '키값3',
      Name: 'key3',
    },
  ],
};

export default defineComponent({
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    selectedDataChanged: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();

    const state = reactive({
      loading: false,
      grid: undefined,
      gridOptions: ibsheetOptions,

      searchData: {
        v1: undefined,
        v2: undefined,
        v3: undefined,
      },
      list: undefined,
      selectedRow: undefined,
    });

    state.gridOptions.Events = {
      ...state.gridOptions.Events,
      onDataLoad() {
        // 로딩 상태 완료로 변경
        state.loading = false;

        // search 완료 이벤트 호출 -> 상위 페이지의 search 상태 변경
        context.emit('update:search', false);
      },
      async onBeforeFocus(e) {
        console.log('onBeforeFocus', e);

        // confirm 사용(async/await) 시 ibsheet 미지원으로 무조건 focus를 주지 않도록 동작하기 때문에
        // 강제로 focus 처리
        // true | false가 리턴되는 것이 아닌 promise가 리턴되어 ibsheet에서는 focus를 주지 않는 것으로 동작

        if (e.row !== e.orow) {
          if (props.selectedDataChanged) {
            const b = await instance.proxy.$confirm('데이터가 수정되었습니다.<br />무시하고 조회하시겠습니까?');

            if (!b) {
              e.sheet.focus({row: e.orow, col: e.ocol, ignoreEvent: true});
              return;
            }
          }

          e.sheet.focus({row: e.row, col: e.col, ignoreEvent: true});

          state.selectedRow = e.row;

          // selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
          context.emit('update:selectedDataKey', selectedDataKey.value);
        }
      },
      onClick(e) {
        console.log('onClick', e);
      },
    };

    const selectedDataKey = computed(() => {
      // 선택된 row 값 중 key 값만 리턴
      if (!state.selectedRow) {
        return undefined;
      }

      return {
        key1: state.selectedRow.key1,
        key2: state.selectedRow.key2,
        key3: state.selectedRow.key3,
      };
    });

    watch(
      () => props.search,
      (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          method.getList();
        }
      }
    );

    const refs = {
      searchBox: ref(null),
    };

    onMounted(() => {
      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: refs.searchBox.value,
        srchList: context.parent.$parent,
      });
    });

    const method = {
      async getList() {
        if (props.selectedDataChanged) {
          const b = await instance.proxy.$confirm('데이터가 수정되었습니다.<br />무시하고 조회하시겠습니까?');

          if (!b) {
            // search 완료 이벤트 호출 -> 상위 페이지의 search 상태 변경
            context.emit('update:search', false);
            return;
          }
        }

        // 로딩 상태 변경
        state.loading = true;

        // 데이터를 가져오기 전 selectedDataKey 변경 이벤트 호출 -> 상위 페이지의 dataKey 변경
        state.selectedRow = undefined;
        context.emit('update:selectedDataKey', selectedDataKey.value);

        state.grid.removeAll();

        setTimeout(() => {
          state.list = [
            {key1: 'key11', key2: 'key12', key3: 'key13'},
            {key1: 'key21', key2: 'key22', key3: 'key23'},
            {key1: 'key31', key2: 'key32', key3: 'key33'},
          ];
        }, 300);
      },
    };

    return {
      state,
      selectedDataKey,
      ...refs,
      ...method,
    };
  },
});
</script>

<template>
  <iui-container type="css-flex">
    <i-row ref="searchBox" height="70px">
      <iui-container type="table" theme="bullet">
        <i-row>
          <i-col-header>검색1</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v1" placeholder="검색1" />
          </i-col>
          <i-col-header>검색2</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v2" placeholder="검색2" />
          </i-col>
          <i-col-header>검색3</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v3" placeholder="검색3" />
          </i-col>
        </i-row>
        <i-row>
          <i-col-header>검색1</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v1" placeholder="검색1" />
          </i-col>
          <i-col-header>검색2</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v2" placeholder="검색2" />
          </i-col>
          <i-col-header>검색3</i-col-header>
          <i-col>
            <iui-textbox v-model="state.searchData.v3" placeholder="검색3" />
          </i-col>
        </i-row>
      </iui-container>
    </i-row>
    <i-row>
      <iui-content-box :loading="state.loading">
        <template #title
          >목록 - search: {{ search }}, selectedDataChanged: {{ selectedDataChanged }}
          <iui-button
            @click="
              () => {
                $emit('invoke', {name: 'test', arguments: 'args'});
              }
            "
            >TEST</iui-button
          >
        </template>

        <iui-container
          type="ibsheet"
          :loading="state.loading"
          :ibsheet.sync="state.grid"
          :ibsheet-options="state.gridOptions"
          :ibsheet-data="state.list"
        />
      </iui-content-box>
    </i-row>
  </iui-container>
</template>

<script>
import {defineComponent, reactive} from '@vue/composition-api';
import gridOption from './gridOptions.js';

export default defineComponent({
  setup() {
    gridOption.Events = {
      ...gridOption.Events,
      onDataLoad(e) {
        console.log('grid', 'onDataLoad', e);

        state.loading = false;
      },
      onClick(e) {
        console.log('grid', 'onClick', e);
      },
      onBeforeRowDelete(e) {
        console.log('grid', 'onClick', e);
      },
    };

    const state = reactive({
      loading: false,
      list: [],
      grid: undefined,
    });

    const method = {
      async search_click(e) {
        state.loading = true;
        // state.grid.removeAll();
        const data = {
          name: 'name',
        };

        try {
          const response = await axios.post('/report/selectMasterSignHist', data);

          console.log('response', response);

          state.list = response.data;
        } catch (ex) {
          console.log('========error====================');
          console.log(ex);
        }
      },
      async new_click(e) {
        //console.log('new_click', state.grid.getSaveJson(0).data);

        state.grid.addRow({init: {applcYn: 'Y', applcDate: $_getCurrentDate()}});
      },
      async save_click(e) {
        // 유효성 체크
        if (!state.grid.hasChangedData()) {
          this.$alert(`수정된 내용이 없습니다.`);
          return;
        }

        for (let row of state.grid.getRowsByStatus('Added,Changed')) {
          if (String(row.manageCd).trim() == '') {
            this.$alert('관리코드는 필수입력 항목입니다.');
            state.grid.focus(row, 'manageCd');
            return;
          }
          if (String(row.applcYn).trim() == '') {
            this.$alert('사용여부 필수입력 항목입니다.');
            state.grid.focus(row, 'applcYn');
            return;
          }
          if (String(row.applcDate).trim() == '') {
            this.$alert('사용시작일는 필수입력 항목입니다.');
            state.grid.focus(row, 'applcDate');
            return;
          }
          if (String(row.mngrNm).trim() == '') {
            this.$alert('본부장 성명은 필수입력 항목입니다.');
            state.grid.focus(row, 'mngrNm');
            return;
          }
          if (String(row.mngrSignUrl).trim() == '') {
            this.$alert('사인 이미지경로는 필수입력 항목입니다.');
            state.grid.focus(row, 'mngrSignUrl');
            return;
          }
        }

        if (await this.$confirm('저장하시겠습니까?')) {
          try {
            state.loading = true;
            const response = await axios.post('/report/saveMasterSignHist', state.grid.getSaveJson().data);
            if (response.status == 200) {
              method.search_click(e);
            } else {
              this.$alert('저장을 실패했습니다.');
            }
          } catch (ex) {
            console.log('========error====================');
            console.log(ex);
          }
        }
      },
      async delete_click(e) {
        console.log('delete_click', e);

        let checkedRows = state.grid.getRowsByChecked('cbx');

        if (0 == checkedRows.length) {
          this.$alert('선택된 항목이 없습니다.');
          return;
        }
        checkedRows.forEach(row => {
          if (row.manageCd) {
            state.grid.deleteRow(row);
          } else {
            state.grid.removeRow(row);
          }
        });
      },
    };

    return {
      gridOption,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page @search-click="search_click">
    <iui-content-box>
      <template #title-right>
        <iui-button @click="new_click" >신규</iui-button>
        <iui-button @click="save_click" >저장</iui-button>
        <iui-button @click="delete_click" >삭제</iui-button>
      </template>
      <iui-container
        type="ibsheet"
        width="100%"
        :ibsheet.sync="state.grid"
        :loading="state.loading"
        :ibsheet-options="gridOption"
        :ibsheet-data="state.list"
      />
    </iui-content-box>
  </otis-page>
</template>

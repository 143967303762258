<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="text"
        :value.sync="searchText"
        :label="'부서명'"
        @enter="onSearchGrid"
        @change="onSearchTextChange"
      />
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" @click="onSearchGrid" />
    </template>

    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      searchText: '',
      searchIndex: 0,
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      printData: [
        {
          fldrNm: '',
          fldrCd: '',
          Items: '',
          fldrNo: '1',
        },
      ],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['deptInfo', 'searchInfo']);
    $mapMutations(this, ['setDeptInfo', 'setWorkerInfo', 'setSavedDeptInfo']);
  },
  created() {
    this.addEvent([
      {name: 'selectDeptList', func: this.selectDeptList},
      {name: 'deleteDept', func: this.deleteDept},
      {name: 'focusRow', func: this.focusRow},
      {name: 'onFocusedDeptListRowData', func: this.onFocusedRowData},
    ]);
  },

  mounted() {
    this.initSheet();
    this.selectDeptList();
  },
  methods: {
    initSheet() {
      const OPT = {
        Cols: [
          {
            Header: '명칭',
            Name: 'fldrNm',
            Type: 'Text',
            Align: 'Left',
            RelWidth: 1,
          },
          {
            Header: '코드',
            Name: 'fldrCd',
            Type: 'Text',
            Align: 'Center',
            Width: 150,
          },
        ],
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 0, // 1: 전체 편집 가능 (default)
          MainCol: 'fldrNm', //트리구조로 만들 필드
        },
        Def: {},
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      this.sheet.bind('onFocus', e => {
        if (e.row.Kind == 'Data') {
          this.setDeptInfo(e.row);
          if (e.row.Level == 0) {
            this.setDeptInfo({uppFldrCd: '', uppFldrNm: '', cud: '2'});
          } else {
            this.setDeptInfo({uppFldrCd: e.row.parentNode.fldrCd, uppFldrNm: e.row.parentNode.fldrNm, cud: '2'});
          }
          this.setSavedDeptInfo({uppFldrCd: e.row.fldrCd, uppFldrNm: e.row.fldrNm, uppFldrNo: e.row.fldrNo});
        }
      });
    }, //initSheet()

    selectDeptList(param) {
      axios
        .post('/deptRegist/selectDeptList', {
          fldrNm: this.searchText,
          pgmCd: this.userInfo.pgmCd,
        })
        .then(response => {
          if (response.status == 200) {
            let items = $_treeModel(response.data, 'OBS', 'fldrCd', 'uppFldrCd', 'fldrNo', 'uppFldrNo');
            this.printData[0].fldrCd = 'OBS';
            this.printData[0].fldrNm = this.searchInfo.frmNm;
            this.printData[0].Items = items;
            this.sheet.loadSearchData(this.printData, 0);

            this.searchText = '';
            var focusRow = this.sheet.getRowById('AR1');
            for (let row of this.sheet.getDataRows()) {
              if (row.fldrNo == param) {
                focusRow = row;
                break;
              }
            }

            setTimeout(() => {
              this.sheet.focus(focusRow);
            }, 100);
          }
        });
    }, //selectDeptList()

    async deleteDept() {
      if (await this.$confirm('삭제하시겠습니까?')) {
        this.setDeptInfo({cud: '3'});
        let param = this.deptInfo;
        await axios.post('/deptRegist/deleteDept', param).then(response => {
          if (response.status == 200) {
            this.$alert('삭제되었습니다.');
          }
        });
        this.callEvent({name: 'selectDeptList'});
      }
    }, //deleteDept()

    onFocusedRowData(param) {
      //팝업창 확인버튼
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },

    onSearchGrid() {
      if (this.searchText) {
        let searchText = this.searchText.toLowerCase();
        let searchLevel = 0;

        // 검색어에 맞는 row 색상 표시
        this.sheet.getDataRows().forEach(row => {
          let textColor = '';
          let targetText = row.fldrNm.toLowerCase();
          if (targetText.indexOf(searchText) > -1) {
            textColor = searchLevel ? (row.Level == searchLevel ? '#FF0000' : '#000000') : '#FF0000';
          } else {
            textColor = '#000000';
          }
          this.sheet.setAttribute({row: row, attr: 'TextColor', val: textColor});
        });

        // 검색어에 맞는 row 포커스
        let focusRow = this.sheet.getDataRows().find((row, index) => {
          let targetText = row.fldrNm.toLowerCase();

          if (index > this.searchIndex) {
            searchLevel = row.Level;
            this.searchIndex = index;
            return targetText.indexOf(searchText) > -1;
          }
        });

        // 검색레벨에 맞게 트리 펼침
        if (searchLevel) {
          this.sheet.showTreeLevel(parseInt(searchLevel) + 1);
        }

        if (focusRow !== undefined) {
          setTimeout(() => this.sheet.focus(focusRow), 10);
        }
      } else {
        this.sheet.getDataRows().forEach(row => {
          this.sheet.setAttribute({row: row, attr: 'TextColor', val: '#000000'});
        });
      }
    },

    onSearchTextChange() {
      this.searchIndex = 0;
    },
  }, //methods
};
</script>

<style></style>

'use strict';

import Vue from 'vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import store from '@/store/index.js';
import qs from 'qs';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
  axios.defaults.headers.common.Authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD'+ '_accessToken');
}

let config = {
  baseURL: process.env.VUE_APP_BASE_URL || '',
  timeout: 180 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
      axios.defaults.headers.common.Authorization = 
      config.headers.Authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD'+ '_accessToken');
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data

    // console.log("access token : "+response.data.access_token);

    if (response.data.access_token) {
      const accessTokenKey = store.getters['login/accessTokenKey'];
      const accessToKenExpires = store.getters['login/accessToKenExpires'];
      const refreshTokenKey = store.getters['login/refreshTokenKey'];
      const refreshTokenExpires = store.getters['login/refreshTokenExpires'];
      if (response.config.url.indexOf('openbanking.or.kr') == -1) {
        VueCookies.set(accessTokenKey, response.data.token_type + ' ' + response.data.access_token, accessToKenExpires);
        VueCookies.set(
          refreshTokenKey,
          response.data.token_type + ' ' + response.data.refresh_token,
          refreshTokenExpires
        );
      }
      console.log("accessTokenKey:"+VueCookies.get(accessTokenKey));
      
      axios.defaults.headers.common.Authorization = VueCookies.get(accessTokenKey);
    }
    return response;
  },
  function(error) {
    // Do something with response error
    const {config, response} = error;
    const originalRequest = config;
    console.log('error', JSON.stringify(error));
    console.log('response',response);
    console.log('response.data.exceptionClass',response.data.error_description);
    console.log('error1', "error1");
    if (error.message == "Network Error") {
      console.log('error2', "error2");
      return Promise.reject(error);
    }
    if (response.status === 401 && response.data.error_description.indexOf('expired') != -1) {
      console.log('error3', "error3");
      const accessTokenKey = store.getters['login/accessTokenKey'];
      const accessToKenExpires = store.getters['login/accessToKenExpires'];
      const refreshTokenKey = store.getters['login/refreshTokenKey'];
      const refreshTokenExpires = store.getters['login/refreshTokenExpires'];

      const data = qs.stringify({
        refresh_token: VueCookies.get(refreshTokenKey),
        grant_type: 'refresh_token',
      });
      return _axios
        .post('/oauth/token', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          auth: {
            username: process.env.VUE_APP_AUTH_USER_NAME,
            password: process.env.VUE_APP_AUTH_PASSWORD,
          },
        })
        .then(response => {
          const accessToken = response.data.access_token;
          const refreshToken = response.data.refresh_token;
          const tokenType = response.data.token_type;

          VueCookies.set(accessTokenKey, tokenType + ' ' + accessToken, accessToKenExpires);
          VueCookies.set(refreshTokenKey, refreshToken, refreshTokenExpires);

          originalRequest.headers.Authorization = VueCookies.get(accessTokenKey);
          return _axios(originalRequest);
        });
    }

    if (response.status === 403 && response.data.error.indexOf('access_denied') != -1) {
      console.log('error4', "error4");
      console.error(config);
      console.error(response);
      Vue.prototype.$alert('접근이 거부되었습니다.');
    } else if (config.url.indexOf('loginLog/updateLogoutHistory') > -1) {
      console.log('error5', "error5");
      // 세션이 만료되었을 때는 '서버에서 오류가 발행하였습니다.' 메세지 안나오게 처리
    } else if (config.url.indexOf('oauth/token') == -1) {
      console.log('error6', "error6");
      console.error(config);
      console.error(response);
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
      //console.error("response.data.exceptionClass",response.data.exceptionClass);
      if (response.data.message !== undefined && response.data.message != "") {        
        console.log('error7', "error7");
        if(response.data.exceptionClass=="com.otis.scd.ScdException" || response.data.trace.indexOf("com.otis.scd.ScdException") > -1) {
          console.log('error8', "error8");
          Vue.prototype.$alert(""+response.data.message);
        }
        else {
          console.log('error9', "error9");
          if(response.data.message!="" && response.data.message !='No message available') {
            Vue.prototype.$alert("서버에서 오류가 발생하였습니다.<span style='display:none;'>"+response.data.message+"</span>");
          }else{
            Vue.prototype.$alert('서버에서 오류가 발생하였습니다.');
          }
         
        }
      } else {
        console.log('error10', "error10");
        Vue.prototype.$alert('서버에서 오류가 발생하였습니다.');
      }
    }

    console.error("error_axios",error);

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

// 20210329
_axios.downloadPdf = async function(url) {
  const response = await axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  });

  var filename = "data.pdf";
  var disposition = response.headers['content-disposition'];
  var filenameRegex = /filename\*?[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) { 
    filename = decodeURIComponent( matches[1].replace(/(UTF-8'')|['"]/g, '') );
  }

  if (response.data.size > 0) {
    const downUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downUrl;
    if (typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(
        response.data,
        filename
      );
    } else {
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } else {
    this.$alert('다운로드 받을 파일이 존재하지 않습니다.');
  }
}

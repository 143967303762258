<template>
  <pmis-content-box>
    <template v-slot:title>자재분류</template>
    <template v-slot:header-left>
      <iui-text name="searchText" :value.sync="searchText" />
      <iui-button value="검색" @click="onSearchCodeList" />
    </template>
    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchText: '',
    };
  },

  created() {
    this.addEvent([{name: 'matList_onFocusedRowData', func: this.onFocusedRowData}]);
  },

  mounted() {
    this.initSheet();
    this.onSearchCodeList();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cfg: {
          MainCol: 'name', // 트리가 표시될 열이름
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 0, // 1: 전체 편집 가능 (default)
          NoTreeLines: 0, // 트리를 사용하는 시트 생성시 노드와 노드 사이의 연결선을 표시할 지 여부를 설정
          FitWidth: 1, //더미 헤더를 추가하여 스크롤바를 우측 끝에 붙인다.
        },
        Def: {
          Header: {
            Hidden: true,
          },
        },
        Cols: [
          {
            Header: '명칭',
            Name: 'name',
            Type: 'Text',
            RelWidth: 1,
          },
          {
            Header: '코드',
            Name: 'code',
            Type: 'Text',
            Width: 65,
            Align: 'center',
          },
        ],
      };

      this.sheet = IBSheet.create({id: this.sheetId, el: this.elId, options: OPT});
    },
    async onSearchCodeList() {
      let param = {
        searchText: this.searchText,
      };
      let response = await axios.post('/matCodeRegist/selectMaterialStandardCodeList', param);
      let treeList = Array.from(response.data)[0];
      treeList.Items = await $_treeModel(
        Array.from(response.data).filter((e, i) => i != 0),
        treeList.code,
        'code',
        'upCode'
      );

      this.sheet.loadSearchData(new Array(treeList));
    },

    onFocusedRowData(param) {
      let row = this.sheet.getFocusedRow();
      if (typeof param == 'function') {
        param(row);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-page
    ref="page"
    :search-project-visible="false"
    :search-company-visible="false"
    @search-click="onSearch"
    @new-click="onAdd"
    @save-click="onSave"
    @delete-click="onDelete"
  >
    <BoardList :boardType="'NOTI'" />
  </pmis-page>
</template>

<script>
import store from '../store/board.js';
import BoardList from '../components/BoardList';
export default {
  components: {
    BoardList,
  },

  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd, frmNm: this.userInfo.frmNm});
  },

  data() {
    return {};
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'BoardList_search'});
    },
    onAdd() {
      this.callEvent({name: 'BoardList_create'});
    },
    onSave() {},
    onDelete() {},
  },
};
</script>

<style></style>

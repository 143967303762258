<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100">
      <i-row>
        <i-col-header>계약번호</i-col-header>
        <i-col>
          <iui-text :value="stamptax.ctrtNo" :enable="false" />
        </i-col>
        <i-col-header>납부주체</i-col-header>
        <i-col>
          <iui-select-old
            name="payMbyCd"
            :items="arrPayMby"
            :value="stamptax.payMbyCd"
            :enable="!this.readonly"
            @change="onSetValue"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>기관명</i-col-header>
        <i-col>
          <iui-text
            name="insttNm"
            :value="stamptax.insttNm"
            :enable="!this.readonly"
            @change="onSetValue"
            required
            width="200px"
          />
        </i-col>
        <i-col-header>인지세 사전납부</i-col-header>
        <i-col>
          <iui-checkbox-group-old
            name="stmptaxBeffatPayYn"
            :items="[{label: '사전납부', value: 'Y'}]"
            :checkedValues="[stamptax.stmptaxBeffatPayYn]"
            @change="onSetValue"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>납부일자</i-col-header>
        <i-col>
          <iui-datepicker-old
            name="stmptaxPayDe"
            :value="stamptax.stmptaxPayDe"
            :enable="!this.readonly"
            @change="onSetValue($event, 'stmptaxPayDe')"
            required
          />
        </i-col>
        <i-col-header>인지세액</i-col-header>
        <i-col>
          <iui-text
            type="amount"
            name="stmptaxAm"
            :value="stamptax.stmptaxAm"
            prefix="\"
            :enable="!this.readonly"
            @change="onSetValue"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>접수번호</i-col-header>
        <!--20230327 ITSR69083
          <i-col colspan="3">
          <iui-text
            name="rceptNo"
            :value="stamptax.rceptNo"
            :enable="!this.readonly"
            @change="onSetValue"
            required
            width="200px"
          />
        </i-col>
        -->
        <i-col colspan="3">
          <iui-text
            name="rceptNo"
           
            :value.sync="stamptax.rceptNo"
            :enable="!this.readonly"
           
            required
            width="200px"
            @input="inputUpdate"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>증명서첨부</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-show="!this.readonly && !fileUpDown_files.lclFlNm"
            >추가</iui-button
          >
          <iui-button
            @click="fileUpDown_delete = true"
            v-show="!this.readonly && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >삭제</iui-button
          >
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.svrFlPth"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            v-model="fileUpDown_files"
            :file-number.sync="stamptax.fileNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
export default {
  components: {
    FileUpDown,
  },
  // props: {
  //   stmptaxSn: {
  //     type: [Number, String],
  //     default: undefined,
  //   },
  //   ctrtNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtOdr: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtDivCd: {
  //     type: String,
  //     default: undefined,
  //   },
  //   empNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   saveMode: {
  //     type: String,
  //     default: undefined,
  //   },
  //   readonly: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  beforeCreate() {
    $mapGetters(this, ['modalStamptax']);
  },
  created() {
    this.addEvent({name: 'Stamptax_Save', func: this.onSave});
    this.addEvent({name: 'Stamptax_Delete', func: this.onDelete});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      callback: '',

      arrPayMby: [
        {text: 'OTIS', value: '01'},
        {text: '고객', value: '02'},
        {text: 'OSEP', value: '03'},
      ],

      stmptaxSn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      empNo: '',
      saveMode: 'I',
      readonly: false,

      stamptax: {
        stmptaxSn: this.stmptaxSn,
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        tradeNo: '',
        payMbyCd: '01',
        insttNm: '',
        stmptaxAm: 0,
        stmptaxPayDe: $_getCurrentDate(),
        rceptNo: '',
        fileNo: 0,
        stmptaxBeffatPayYn: 'N',
        rpaYn: 'N',
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },
  mounted() {
    // console.log("###############################");
    // console.log('this.stmptaxSn', this.stmptaxSn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("###############################");

    // console.log('modalStamptax', this.modalStamptax);

    this.stmptaxSn = this.modalStamptax.stmptaxSn;
    this.ctrtNo = this.modalStamptax.ctrtNo;
    this.ctrtOdr = this.modalStamptax.ctrtOdr;
    this.ctrtDivCd = this.modalStamptax.ctrtDivCd;
    this.saveMode = this.modalStamptax.saveMode;
    this.readonly = this.modalStamptax.readonly;

    this.stamptax.stmptaxSn = this.stmptaxSn;
    this.stamptax.ctrtNo = this.ctrtNo;
    this.stamptax.ctrtOdr = this.ctrtOdr;
    this.stamptax.ctrtDivCd = this.ctrtDivCd;

    // console.log("==============================");
    // console.log('this.stmptaxSn', this.stmptaxSn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("==============================");

    if (this.stamptax.stmptaxSn > 0) {
      this.onSearch();
    }
  },
  methods: {
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      if (key == 'stmptaxBeffatPayYn') {
        if (value == 'Y') {
          this.$alert(
            '전자계약 인지세 사전납부 시 선택 해주시기 바랍니다.<br/>선택한 계약의 인지세는 CR팀 일괄납부대상에서 제외됩니다.'
          );
        }
      }
      this.stamptax[key] = value;
      console.log("value",value);
    },
    async onSearch() {
      let param = {
        stmptaxSn: this.stamptax.stmptaxSn,
      };
      try {
        this.listBoxLoading = true;
        let response = await axios.post('/stamptax/selectByStmptaxSn', param);
        this.listBoxLoading = false;
        this.stamptax = response.data;
        console.log('this.stamptax.stmptaxBeffatPayYn', this.stamptax.stmptaxBeffatPayYn);
        if (this.stamptax.rpaYn == 'Y') {
          this.readonly = true;
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSave(callback) {
      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      let validation = await this.$validator.validate(this);
      if (!validation.isValid) {
        this.$alert('입력되지 않은 필수항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.');
        return;
      }

      if (this.stamptax.insttNm.length == 0) {
        this.$alert('기관명을 입력해야 합니다.');
        return;
      }
      if (this.stamptax.stmptaxPayDe <= 0) {
        this.$alert('납부일자를 입력해야 합니다.');
        return;
      }
      if (this.stamptax.stmptaxAm.length == 0 || this.stamptax.stmptaxAm <= 0) {
        this.$alert('인지세액을 입력해야 합니다.');
        return;
      }
      var regType1 = /^[0-9+]*$/;
      if (!regType1.test(this.stamptax.stmptaxAm)) {
        this.$alert('인지세액은 숫자만 입력 가능합니다.');
        // document.getElementsByName('stmptaxAm')[0].focus();
        return;
      }
      if (this.stamptax.rceptNo.length == 0) {
        this.$alert('접수번호를 입력해야 합니다.');
        return;
      }
      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.$alert('증명서 파일을 첨부해야 합니다.');
        return;
      }

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.stamptax,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          var uri = this.saveMode == 'I' ? '/stamptax/insertStamptax' : '/stamptax/updateByStmptaxSnSelective';

          let response = axios.post(uri, param);
          console.log(response);
          this.listBoxLoading = false;
          callback(response);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onDelete(callback) {
      console.log('this.saveMode', this.saveMode);
      console.log('this.readonly', this.readonly);

      if (this.saveMode == 'I') {
        this.$alert('저장 후 삭제가 가능합니다.');
        return;
      }

      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      if (!(await this.$confirm('삭제하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;

        let param = {
          ...this.stamptax,
          pgmCd: this.userInfo.pgmCd,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        let response = axios.post('/stamptax/deleteByStmptaxSn', param);
        console.log(response);
        this.listBoxLoading = false;
        callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.stamptax.fileNo = 0;
      } else {
        this.stamptax.fileNo = this.fileUpDown_files.flNo;
      }

      let param = {
        ...this.stamptax,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      var uri = this.saveMode == 'I' ? '/stamptax/insertStamptax' : '/stamptax/updateByStmptaxSnSelective';
      try {
        let response = axios.post(uri, param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_error() {
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      console.log('파일 삭제가 완료되었습니다.');
    },
    file_delete_error() {
      console.log('파일 삭제를 실패했습니다.');
    },
    //20230327 ITSR69083
    async inputUpdate(val) {
      await this.$nextTick();
      //console.log("this.stamptax.rceptNo",this.stamptax.rceptNo);
      let value= this.stamptax.rceptNo;

      value = value.replace(/[^0-9]/g, "");

      if(value.length > 16){
          value = value.substr(0, 16);
      }

      let result = [];
      result.push(value.substr(0, 7));
      result.push(value.substring(7));         

      this.stamptax.rceptNo = result.filter((val) => val).join("-");
    },
  },
};
</script>

<style></style>

export const gridOptionsHist = {
  Cfg: {
    ReqStatusName: 'STATUS',
    FitWidth: true,
    IgnoreFocused: 0, //첫번째행으로 포커스이동.
    SearchMode: 0,
    //CustomScroll: 1,
    //Style: 'IBMT',
  },
  Def: {
    Row: {
      CanFormula: true,
      CalcOrder: 'cbxCanEdit', //,manageCdCanEdit', //필드명의 Formula 속성명 명시
    },
  },
  //틀고정 좌측 컬럼 설정
  LeftCols: [
    {Header: 'No.', Type: 'Int', Width: 60, Align: 'Center', Name: 'SEQ'},
    {
      Header: '선택',
      Type: 'Bool',
      Name: 'cbx',
      Align: 'Center',
      Width: 50,
      // 이력이 존재할경우 삭제불가로직 추가. 22.04.01.
      CanEditFormula: prm => {
        //return prm.Row['Added'] ? 1 : 0;
        return prm.Row['verNo'] > 1 || prm.Row['Added'] ? 1 : 0;
      },
    },
  ],
  Cols: [
    {
      Header: ['관리번호'],
      Type: 'Text',
      Name: 'manageCd',
      Align: 'Center',
      // Format: '####',
      Size: '4',
      Width: 100,
      // Required: 1,
      // ResultMask: '^[A-Z0-9]{0,4}$' /* 영문(대/소문자), 숫자 4자리 */,
      // ResultMessage: '영문(대문자), 숫자 조합으로 4자리까지만 입력가능합니다.',
      CanEdit: 0,
      // CanEditFormula: fr => fr.Row['verNo'] >= 1,
    },
    {
      Header: ['이력순번'],
      Type: 'Text',
      Name: 'verNo',
      CanEdit: 0,
      Width: 80,
    },
    {
      Header: ['사용여부'],
      Type: 'Enum',
      Name: 'applcYn',
      Enum: '|사용|미사용',
      EnumKeys: '|Y|N',
      Width: 100,
      CanEdit: 1,
    },
    {
      Header: ['사용시작일자'],
      Type: 'Date',
      Name: 'applcDate',
      DataFormat: 'yyyyMMdd',
      Format: 'yyyy-MM-dd',
      Width: 120,
      CanEdit: 1,
    },
    {
      Header: ['오즈파일 서버경로(.ozr)'],
      Type: 'Text',
      Name: 'rptFlpth',
      Align: 'Left',
      Size: '350',
      Required: 1,
      Width: 350,
      CanEdit: 1,
    },
    {
      Header: ['ODI 파일명'],
      Type: 'Text',
      Name: 'odiNm',
      Align: 'Left',
      Size: '100',
      Required: 0,
      Width: 100,
      CanEdit: 1,
    },
    {
      Header: ['변경사유'],
      Type: 'Text',
      Name: 'changeCn',
      Align: 'Left',
      Size: '200',
      Required: 1,
      Width: 200,
      CanEdit: 1,
    },
    {
      Header: ['변경일자'],
      Type: 'Date',
      Name: 'chngDate',
      DataFormat: 'yyyyMMdd',
      Format: 'yyyy-MM-dd',
      CanEdit: 4,
      //RelWidth: 1,
      Width: 120,
    },
    {
      Header: ['변경자'],
      Type: 'Text',
      Name: 'chngEmpName',
      //RelWidth: 1,
      CanEdit: 0,
      Width: 100,
    },
    {
      Name: 'rptNm',
      Visible: 0,
    },
    {
      Name: 'chngDate',
      Visible: 0,
    },
    {
      Name: 'entryEmpId',
      Visible: 0,
    },
    {
      Name: 'entryEmpName',
      Visible: 0,
    },
    {
      Name: 'chngEmpId',
      Visible: 0,
    },
  ],
  Events: {
    // onDataLoad(e) {
    //   console.log('onDataLoad', e);
    // },
  },
};

export default gridOptionsHist;

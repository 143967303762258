var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:_vm.rootClass,style:(_vm.rootStyle)},[(_vm.multiple)?_c('multiselect',{ref:"vueMultiselect",attrs:{"label":"text","track-by":"value","options":_vm.options,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.internalPlaceHolder,"selectLabel":_vm.selectLabel,"selectedLabel":_vm.selectedLabel,"deselectLabel":_vm.deselectLabel,"disabled":!_vm.enable,"searchable":_vm.searchable},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(values[0].text)+" "+_vm._s(values.length > 1 ? ("외 " + (values.length - 1) + "개") : ''))]):_vm._e()]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(option.text))])]}}],null,false,1507133179),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c('template',{slot:"noResult"},[_c('span',[_vm._v(_vm._s(_vm.internalNoResultMessage))])])],2):_c('Multiselect',{ref:"vueMultiselect",attrs:{"label":"text","track-by":"value","name":_vm.name,"options":_vm.options,"placeholder":_vm.internalPlaceHolder,"selectLabel":_vm.selectLabel,"deselectLabel":_vm.deselectLabel,"disabled":!_vm.enable,"searchable":_vm.searchable},on:{"select":_vm.onSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(option.text))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c('template',{slot:"noResult"},[_c('span',[_vm._v(_vm._s(_vm.internalNoResultMessage))])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
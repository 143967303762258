<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox" v-if="otisPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>고객/현장명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!-- 20220103 lgksja 통합 엑셀 83번 
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />                
            </i-col>
             -->
            <i-col-header>CC/팀</i-col-header>
            <i-col>
              <iui-text name="ccName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>주점검자</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!--20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가-->
            <i-col-header>지사명</i-col-header>
            <i-col>
              <iui-text name="jisaNm" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>그룹명</i-col-header>
            <i-col>
              <iui-text name="groupNm" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!--20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가-->
          </i-row>
          <i-row>
            <i-col-header>청구일자</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix="~"
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>청구번호</i-col-header>
            <i-col>
              <iui-text name="rqestNo" @change="onSetValue" @enter="onPreSearch" width="150px" />
            </i-col>
            <i-col-header>유/무상</i-col-header>
            <i-col>
              <iui-select-old
                name="paymentType"
                :items="arrPaymentType"
                :value="search.paymentType"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header></i-col-header>
            <i-col> </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="40px" ref="srchBox" v-if="customerPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>계약명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>청구일자</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix=" ~ "
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <!-- <i-row height="260px" > 20211110 lgksja 사이즈조절-->
    <i-row>
      <i-col>
        <pmis-content-box no-border-box>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  name: 'part-list',
  props: {
    otisPage: {
      // OTIS 페이지
      type: Boolean,
      default: false,
    },
    customerPage: {
      // 업체 페이지
      type: Boolean,
      default: false,
    },
  },
  components: {},
  beforeCreate() {},
  created() {
    this.addEvent({name: 'PartMainList_PreSearch', func: this.onPreSearch});
    this.addEvent({name: 'PartMainList_Search', func: this.onSearch});
  },
  data() {
    return {
      //sheetId: 'sheet',// + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,

      arrPaymentType: [
        {text: '전체', value: ''},
        {text: '무상', value: '무상'},
        {text: '유상', value: '유상'},
        {text: 'CLAIM', value: 'CLAIM'},
      ],
      arrStatusCode: [
        {text: '전체', value: ''},
        {text: '작성중', value: '10'},
        {text: '서명요청', value: '20'},
        {text: '회수', value: '30'},
        // {text: '서명완료', value: '40'},
        {text: '완료', value: '50'},
      ],

      search: {
        contractName: '',
        customerName: '',
        ccName: '',
        empName: '',
        checkContDateStart: '',
        checkContDateEnd: '',
        isOtisPage: this.otisPage,
        isCustomerPage: this.customerPage,
        rqestNo: '',
        paymentType: '',
        statusCode: '',
        summaryYm: '',
        empNo: '',

        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        jisaNm: '',
        groupNm: '',
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        docCat1: '',
        signType: '',
      },

      modalOZViewerBtns: [],
      OZParam: {},
    };
  },
  mounted() {
    if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
      if (this.customerPage) {
        this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -3);
      } else if (this.otisPage) {
        this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -1);
      }
      this.search.checkContDateEnd = $_getCurrentDate();
    }

    const OPT = {
      Cols: [
        // eslint-disable-next-line
        {
          name: '보기',
          label: '계약서',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button >보기</button>' : '';
          },
          cellattr: $_rowMearge,
        },
        {name: 'docCat1Nm', label: '표준<br/>/비표준', width: 50, align: 'center', cellattr: $_rowMearge},
        {name: 'signType', label: '전자<br/>/서면', width: 0, hidden: true},
        {name: 'signTypeNm', label: '전자<br/>/서면', width: 50, align: 'center', cellattr: $_rowMearge},
        {name: 'contTeamNoDesc', label: 'CCT', width: 50, align: 'left', cellattr: $_rowMearge},
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        // { name: "bonbuNm",                    label: "본부",                    width: 80,    align: "left", cellattr: $_rowMearge },
        {name: 'jisaNm', label: '지사', width: 70, align: 'left', cellattr: $_rowMearge},
        {name: 'groupNm', label: '그룹', width: 50, align: 'left', cellattr: $_rowMearge},
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        {name: 'checManAName', label: '주점검자', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'checkContTypeA3', label: '상품', width: 40, align: 'center'},
        {name: 'checkContGubun2Nm', label: '세부', width: 60, align: 'center'},
        {name: 'checkContNo', label: '번호', width: 80, align: 'center'},
        {name: 'checkSeq', label: '차수', width: 40, align: 'center'},
        {name: 'tradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'tradeName', label: '고객명', width: 170, align: 'left', cellattr: $_rowMearge},
        {name: 'minMainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'checkContNm', label: '현장명', width: 170, align: 'left', cellattr: $_rowMearge},
        {
          name: 'checkContDate',
          label: '계약일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContStartDate',
          label: '계약시작일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContFinDate',
          label: '계약종료일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'supplyTotalAmt',
          label: '월보수료<br/>(VAT별도)',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        },
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'ctrtSttusCd', label: 'ctrtSttusCd', width: 0, hidden: true},
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
        {name: 'fileName', label: 'fileName', width: 0, hidden: true},
        {name: 'tradeType', label: 'tradeType', width: 0, hidden: true},
      ],
    };

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      width: 1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: false,
      autowidth: false,
      shrinkToFit: false,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        if (me.focusKeys.ctrtNo!=rowData.ctrtNo 
            || me.focusKeys.ctrtOdr!=rowData.ctrtOdr 
            || me.focusKeys.ctrtDivCd!=rowData.ctrtDivCd) {
          // 20211101 lgksja 보기일경우는 팝업만 열리게
          me.$emit('click', rowData);          
        }

        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        me.focusKeys.docCat1 = rowData.docCat1;
        me.focusKeys.signType = rowData.signType;

        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기']) {
          if (rowData.signType == 'ON') {
            me.onView(rowData);
          } else {
            me.onDownloadOfflinePdf(rowData);
          }
        }
      },
    });

    var groups = [];
    groups.push({startColumnName: 'checkContTypeA3', numberOfColumns: 2, titleText: '계약유형'});
    groups.push({startColumnName: 'checkContNo', numberOfColumns: 2, titleText: '계약번호'});

    $grid.jqGrid('setGroupHeaders', {
      useColSpanStyle: true,
      groupHeaders: groups,
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$parent.$parent,
    });
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 10, false);

      //console.log("this.sheetId",this.sheetId);
      //console.log("this.height",$("#"+this.sheetId).height());
      /*20211110 사이즈 조절 lgksja ~S*/
      var bottomLeftObj = $grid.closest('.bullet');

      //$grid.jqGrid("setGridHeight", bottomLeftObj.height()-100, false);
      //console.log("bottomLeftObj.height()-90",bottomLeftObj.height()-150);
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 150, false);
      /*20211110 사이즈 조절 lgksja ~E*/
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.search.paymentType = '';
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.empNo = '';

          this.search.paymentType = params.paymentType;
          this.search.statusCode = params.statusCode;
          this.search.summaryYm = params.summaryYm;
          this.search.empNo = params.empNo;

          this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0].params = undefined;
        }
      }

      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.focusKeys.docCat1 = '';
      this.focusKeys.signType = '';
      this.onSearch();
    },
    async onSearch() {
      if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
        // 청구번호가 입력되면 청구일자와 무관하게 조회하도록
        if (this.search.rqestNo === undefined || this.search.rqestNo == '') {
          // 청구일자 기간 비워져 있을시에 alert
          if (!this.search.checkContDateStart || !this.search.checkContDateEnd) {
            this.$alert('검색하실 청구일자의 시작일과 종료일을 선택해주세요.');
            return;
          }

          if (parseInt(this.search.checkContDateStart) > parseInt(this.search.checkContDateEnd)) {
            this.$alert('청구일자의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          var sDate = new Date(
            this.search.checkContDateStart.substring(0, 4),
            this.search.checkContDateStart.substring(4, 6),
            this.search.checkContDateStart.substring(6, 8)
          );

          var eDate = new Date(
            this.search.checkContDateEnd.substring(0, 4),
            this.search.checkContDateEnd.substring(4, 6),
            this.search.checkContDateEnd.substring(6, 8)
          );

          var dif = eDate - sDate;
          var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
          var cMonth = cDay * 30; // 월 만듬
          // var cYear = cMonth * 12; // 년 만듬

          // console.log('sDate', sDate);
          // console.log('eDate', eDate);
          // console.log('dif', dif);
          // console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));

          if (this.customerPage) {
            if (parseInt(dif / cMonth) > 3) {
              this.$alert('청구일자 기간을 3개월 이내로 검색해주세요.');
              return;
            }
          } else if (this.otisPage) {
            if (parseInt(dif / cMonth) > 1) {
              this.$alert('청구일자 기간을 1개월 이내로 검색해주세요.');
              return;
            }
          }
        } else {
          this.search.checkContDateStart = '';
          this.search.checkContDateEnd = '';
        }
      } else {
        this.search.checkContDateStart = '';
        this.search.checkContDateEnd = '';
      }

      this.listBoxLoading = true;

      let param = {
        contractName: this.search.contractName,
        customerName: this.search.customerName,
        ccName: this.search.ccName,
        empName: this.search.empName,
        checkContDateStart: this.search.checkContDateStart,
        checkContDateEnd: this.search.checkContDateEnd,
        otisPage: this.search.isOtisPage,
        customerPage: this.search.isCustomerPage,
        signType: this.search.signType,
        rqestNo: this.search.rqestNo,
        paymentType: this.search.paymentType,
        statusCode: this.search.statusCode,
        summaryYm: this.search.summaryYm,
        empNo: this.search.empNo,

        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        jisaNm: this.search.jisaNm,
        groupNm: this.search.groupNm,
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
      };

      let response = null;
      try {
        response = await axios.post('/confdoc/part/selectMainList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        // TODO에서 넘어온 파라메터 값으로 조회한 이후 해당 파라메터들을 초기화해서 검색 버튼을 클릭했을 때 정상적으로 조회 될 수 있게 한다.
        if (this.search.summaryYm !== undefined && response.data.length == 0) {
          this.search.paymentType = '';
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.empNo = '';
        }

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              if (
                rowData['ctrtNo'] == this.focusKeys.ctrtNo &&
                rowData['ctrtOdr'] == this.focusKeys.ctrtOdr &&
                rowData['ctrtDivCd'] == this.focusKeys.ctrtDivCd
              ) {
                // console.log("MainList 일치하는 Row 찾아 조회");
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.$emit('click', rowData);
                break;
              }
            }
          } else {
            // console.log("MainList 일치하는 Row 못찾아 첫번째 행을 조회");
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.$emit('click', rowData);
          }
        } else {
          // console.log("MainList 검색된 데이터 없어서 리셋");
          this.$emit('resetValues', null);
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    async onDownloadOfflinePdf(data) {
      var fileName = data.fileName;
      var url = '/ocontract/downloadOfflinePdf?checkContNo=' + data.ctrtNo + '&checkSeq=' + data.ctrtOdr;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
      }
    },
    async onView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);

      var signable = 'N';
      var signerSeCd = '10';
      var tradeNo = '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      // eslint-disable-next-line
      var opt = {};
      const btns = [];

      //20221123 ITSR62079
      var opt_param = {
                    ctrtNo: data.ctrtNo,
                    ctrtOdr: data.ctrtOdr,
                    ctrtDivCd: data.ctrtDivCd,
                    docCat1: data.docCat1,
                    ctrtSttusCd: data.ctrtSttusCd,
                  }; 

      if (data.ctrtOdr == '00') {
        if (data.docCat1 == '000') {
          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ...opt_param,
              OZParam: {},
              otisPage: true,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '계약서조회', // 팝업 제목 (생략가능)
          });
        } else {
          axios
            .post('/ozrpt/selectOzOContract', param)
            .then(response => {
              // console.log('조회결과 response >>>', response);
              // console.log('조회결과 response.data >>>', response.data);

              this.OZParam = Object.assign({}, response.data.jsonData);

              this.OZParam.reportname = response.data.reportPathName;
              this.OZParam.OZFormParam = {
                signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                signMthCd: '',
              };

              // eslint-disable-next-line
              opt = $open({
                componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  ...opt_param,
                  OZParam: this.OZParam,
                  otisPage: true,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '계약서조회', // 팝업 제목 (생략가능)
              });
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      } else {
        if (data.docCat1 == '000') {
          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ...opt_param,
              OZParam: {},
              otisPage: true,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '공문조회', // 팝업 제목 (생략가능)
          });
        } else {
          axios
            .post('/ozrpt/selectOzOContract', param)
            .then(response => {
              // console.log('조회결과 response >>>', response);
              // console.log('조회결과 response.data >>>', response.data);

              this.OZParam = Object.assign({}, response.data.jsonData);

              this.OZParam.reportname = response.data.reportPathName;
              this.OZParam.OZFormParam = {
                signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
              };

              // eslint-disable-next-line
              opt = $open({
                componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  ...opt_param,
                  OZParam: this.OZParam,
                  otisPage: true,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '공문조회', // 팝업 제목 (생략가능)
              });
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      }
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

class ValidatorErrorMessage {
  constructor(required, min, max, minLength, maxLength, pattern) {
    this.defaultMessage = required;
    this.required = required;
    this.min = min;
    this.max = max;
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.pattern = pattern;
    this.success = '';
    this.currentErrorMessage = '';
  }

  getErrorMessage(rule) {
    // console.log(`getErrorMessage`);
    let errorMessage = this[rule] || this.defaultMessage;
    // console.log(`rule: ${rule}, errorMessage: ${errorMessage}`);
    // console.log(this[rule]);

    return errorMessage;
  }
}

export default ValidatorErrorMessage;

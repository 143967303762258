<template>
  <div class="join_wrap">
    <div class="header">
      <div class="gnb">
        <div class="logo_area">
          <h1 class="logo_pmis">
            <span class="blind">PMIS</span>
          </h1>
          <div class="title_join">
            <span class="blind">회원가입</span>
          </div>
        </div>
        <div class="link_area">
          <a href="#" target="_top" class="link_login" @click="goLogin">로그인</a>
        </div>
      </div>
      <!-- gnb end -->
    </div>
    <!-- header end -->

    <div class="container">
      <component v-bind:is="stepView"></component>
    </div>
    <!-- container end -->

    <div class="footer">
      <div class="copy">COPYRIGHT Ⓒ INOGARD. ALL RIGHTS RESERVED.</div>
    </div>
  </div>
  <!-- join_wrap end -->
</template>

<script>
import {mapMutations} from 'vuex';
import joinStep01 from './joinStep01';
import joinStep02 from './joinStep02';
import joinStep03 from './joinStep03';
import joinStep04 from './joinStep04';
import store from '@/view/sysManage/userManage/member/store/member.js';
export default {
	data() {
		return {
			stepView: joinStep01,
		};
	},

	beforeCreate() {
		this.$store.commit('setCurrentUrl', {path: 'joinStep'});

		$init(this, store);
		$mapGetters(this, ['step']);
	},

	methods: {
		...mapMutations('login', ['setPage']),
		goLogin() {
			this.setPage('Login');
		},
		nextStep(step) {
			switch (step) {
				case '01':
					this.stepView = joinStep01;
					break;
				case '02':
					this.stepView = joinStep02;
					break;
				case '03':
					this.stepView = joinStep03;
					break;
				case '04':
					this.stepView = joinStep04;
					break;
				default:
					this.stepView = joinStep01;
			}
		},
	},
};
</script>

<style>
</style>
<template>
  <section class="spot_w_right">
    <div class="spot_t_box">
      <h3 class="spot_title02">
        <span class="blind">현장명</span>
      </h3>
      <span class="pl20">{{ prjNm }}</span>
    </div>
    <div class="mt10 mb10 df">
      <div class="graph_w_left">
        <p class="por">
          <span class="spot_stit02">D-Day count</span>
          <span class="spot_stit03">Today: {{ ddayCountInfo.today }}</span>
        </p>
        <ul class="graph_t_box mt10">
          <li>
            D-Day
            <span class="g_date">{{ ddayCountInfo.remainDays }}</span>
          </li>
          <li>
            총공기
            <span class="g_date">{{ ddayCountInfo.wholeDays }}</span>
          </li>
          <li>
            경과일
            <span class="g_date">{{ ddayCountInfo.elapseDays }}</span>
          </li>
        </ul>
        <ul class="g_desc01 mt10">
          <li>{{ ddayCountInfo.ctrtStrdate }}</li>
          <li class="tar">{{ ddayCountInfo.ctrtEnddate }}</li>
        </ul>
        <div class="graph_01">
          <span class="bar" :style="{width: ddayCountInfo.progessRate + '%'}"></span>
        </div>
        <ul class="g_desc01">
          <li>착공</li>
          <li class="tac">{{ ddayCountInfo.progessRate }}%</li>
          <li class="tar">준공</li>
        </ul>
      </div>

      <div class="graph_w_right">
        <p class="por">
          <span class="spot_stit02">공정현황</span>
          <span class="spot_stit03">공정기준일: {{ processStatusInfo.today }}</span>
        </p>
        <ul class="graph_t_box mt10">
          <li>
            계획
            <span class="g_date">{{ processStatusInfo.planRate }}%</span>
          </li>
          <li>
            실적
            <span class="g_date">{{ processStatusInfo.resultRate }}%</span>
          </li>
          <li>
            달성
            <span class="g_date">{{ processStatusInfo.achievementRate }}%</span>
          </li>
        </ul>
        <p class="mt15">
          <span class="g_desc02">계획</span>
          <span class="graph_02">
            <span class="bar" :style="{width: processStatusInfo.planRate + '%'}"></span>
          </span>
          <span class="fc_wh">{{ processStatusInfo.planRate }}%</span>
        </p>
        <p>
          <span class="g_desc02">실적</span>
          <span class="graph_03">
            <span class="bar" :style="{width: processStatusInfo.resultRate + '%'}"></span>
          </span>
          <span class="fc_wh">{{ processStatusInfo.resultRate }}%</span>
        </p>
      </div>
    </div>
    <div style="height: calc(100% - 186px);">
      <pmis-content-box>
        <p class="spot_stit01">금일예정작업</p>
        <div class="mt5">
          <iui-container type="ibsheet" :id="elId1" height="170px" />
        </div>

        <p class="spot_stit01">출역정보</p>
        <div class="mt5">
          <iui-container type="ibsheet" :id="elId2" height="180px" />
        </div>

        <p class="spot_stit01">사진정보</p>
        <div class="mt5">
          <iui-card-carousel :items="photoItems" :imageWidth="80" :imageHeight="80" />
        </div>
      </pmis-content-box>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    this.addEvent({name: 'ProjectStatus_search', func: this.onSearch});
  },
  data: function() {
    return {
      elId1: 'sheetDiv1' + this._uid,
      elId2: 'sheetDiv2' + this._uid,
      sheetId1: 'sheet1' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      prjCd: '',
      prjNm: '',
      ddayCountInfo: {
        today: '', // Today
        wholeDays: '', // 총공사기간
        elapseDays: '', // 경과일
        remainDays: '', // 잔여일
        progessRate: '', // 진행율
        ctrtStrdate: '', // 착공일자
        ctrtEnddate: '', //  준공일자
      },
      processStatusInfo: {
        today: '', //  공정기준일
        planRate: '', // 공정율(계획)
        resultRate: '', // 공정율(실적)
        achievementRate: '', // 달성율
      },
      photoItems: [], // 사진정보
    };
  },
  mounted: function() {
    const OPT1 = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1 : 전체 편집 가능 (default),
      },
      Def: {
        Row: {
          CanFocus: 0,
        },
      },
      Cols: [
        {
          Header: '작업분류',
          Name: 'fldrPath',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '단위작업',
          Name: 'wbsNm',
          Type: 'Text',
          RelWidth: 1,
        },
        {Name: 'pgmCd', Visible: false},
        {Name: 'prjCd', Visible: false},
      ],
    };

    const OPT2 = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 0, // 1 : 전체 편집 가능 (default),
        HeaderMerge: 2,
      },
      Def: {
        Row: {
          CanFocus: 0,
        },
      },
      Cols: [
        {
          Header: ['금일', '직종'],
          Name: 'todayJdkNm',
          Type: 'Text',
          Width: 120,
        },
        {
          Header: ['금일', '인원'],
          Name: 'todayCount',
          Type: 'Text',
          Width: 50,
          Align: 'center',
        },
        {
          Header: ['금일', '공수'],
          Name: 'todayInvestQty',
          Type: 'Text',
          Width: 50,
          Align: 'center',
        },
        {
          Header: ['금일', '지급예정액'],
          Name: 'todayAmt',
          Extend: this.$ibsheetHelper.preset.amount,
          RelWidth: 1,
        },
        {
          Header: ['전일', '직종'],
          Name: 'beforeJdkNm',
          Type: 'Text',
          Width: 120,
        },
        {
          Header: ['전일', '인원'],
          Name: 'beforeCount',
          Type: 'Text',
          Width: 50,
          Align: 'center',
        },
        {
          Header: ['전일', '공수'],
          Name: 'beforeInvestQty',
          Type: 'Text',
          Width: 50,
          Align: 'center',
        },
        {
          Header: ['전일', '지급예정액'],
          Name: 'beforeAmt',
          Extend: this.$ibsheetHelper.preset.amount,
          RelWidth: 1,
        },
      ],
    };

    this.sheet1 = IBSheet.create({id: this.sheetId1, el: this.elId1, options: OPT1});
    this.sheet2 = IBSheet.create({id: this.sheetId2, el: this.elId2, options: OPT2});
  },
  methods: {
    onSearch(param) {
      if (param == null) {
        this.onInit();
      } else {
        this.prjNm = `[${param.prjCd}] ${param.prjNm}`;
        /*
        axios
          .post('/homeController/selectProjectStatusInfos', {
            pgmCd: param.pgmCd,
            prjCd: param.prjCd,
          })
          .then(response => {
            this.setData(this.ddayCountInfo, response.data.ddayCountInfo);
            this.setData(this.processStatusInfo, response.data.processStatusInfo);
            this.sheet1.loadSearchData(response.data.todayWorkScheduledList);
            this.sheet2.loadSearchData(response.data.attendanceList);
            this.setPhotoData(response.data.processPhotoList);
          });
          */
      }
    },
    onInit() {
      this.ddayCountInfo.today = ''; // Today
      this.ddayCountInfo.wholeDays = '0'; // 총공사기간
      this.ddayCountInfo.elapseDays = '0'; // 경과일
      this.ddayCountInfo.remainDays = '0'; // 잔여일
      this.ddayCountInfo.progessRate = '0'; // 진행율
      this.ddayCountInfo.ctrtStrdate = ''; // 착공일자
      this.ddayCountInfo.ctrtEnddate = ''; //  준공일자

      this.processStatusInfo.today = ''; //  공정기준일
      this.processStatusInfo.planRate = '0'; // 공정율(계획)
      this.processStatusInfo.resultRate = '0'; // 공정율(실적)
      this.processStatusInfo.achievementRate = '0'; // 달성율

      this.sheet1.removeAll();
      this.sheet2.removeAll();

      this.photoItems = [];
    },
    setData(dataInfo, data) {
      for (let key in dataInfo) {
        dataInfo[key] = data[key];
      }
    },
    setPhotoData(items) {
      let array = [];
      if (items.length > 0) {
        items.forEach(item => {
          let obj = {};
          obj.title = `촬영일자 : ${item.phoDt}`;

          item.photoFileInfoList.forEach(file => {
            let src = `${process.env.VUE_APP_BASE_URL}/image/getImage?pgmCd=${item.pgmCd}&prjCd=${item.prjCd}&flNo=${file.flNo}&revId=${file.revId}`;
            if (file.flDs == '001') {
              obj.imgSrc = src;
            } else if (file.flDs == '002') {
              obj.thumbnailSrc = src;
            }
          });
          array.push(obj);
        });
      }
      this.photoItems = array;
    },
  },
};
</script>

<style scoped></style>

import {defineComponent, getCurrentInstance, reactive} from '@vue/composition-api';
import * as util from '@/components/Iui/util.js';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiCssFlexMixin from '@/components/Iui/mixins/IuiCssFlexMixin';
import TagMap from './tagMap.js';

export default defineComponent({
  name: 'i-spacer',
  mixins: [IuiLayoutMixin, IuiCssFlexMixin],
  inject: ['containerType'],
  // setup(props, context) {
  //   const instance = getCurrentInstance();
  //   // const vm = instance.proxy;
  // },
  render(h) {
    let data = {};

    /**
     * tag 처리
     */
    let tag = TagMap.get(this.containerType);
    if (tag === undefined || tag === null) {
      tag = TagMap.get('default');
    }

    tag = tag.spacer;

    /**
     * class 처리
     */
    let classList = [];
    let defaultClassName = 'spacer';
    classList.push({[defaultClassName]: true});

    util.appendArrayToObject(data, 'class', classList);

    /**
     * childNode 처리
     */
    let childNodes = [];

    childNodes.push(this.$slots.default);

    /**
     * VNode 생성
     */
    let vnode = null;
    vnode = h(tag, data, childNodes);

    return vnode;
  },
});

<script>
import {defineComponent, getCurrentInstance} from '@vue/composition-api';
import {useWindowPopup} from '@/components/Iui/composables/useWindowPopup.js';

export default defineComponent({
  setup() {
    const instance = getCurrentInstance();

    const windowPopup = useWindowPopup();
    const popup = windowPopup.get(instance.proxy.$route.query.id);

    const state = {
      popupId: instance.proxy.$route.query.id,
      data: popup.data,
    };

    let asyncComponent = null;

    const components = require.context(
      // 컴포넌트들이 있는 폴더
      '@',
      // 하위 폴더까지 포함할 지 여부
      true,
      // 기본 컴포넌트를 찾는데 사용할 정규표현식
      /[A-Z-]\w+\.(vue|js)$/
    );

    components.keys().some(fileName => {
      if (fileName == popup.componentPath) {
        const componentConfig = components(fileName);
        asyncComponent = componentConfig.default || componentConfig;
        return true;
      }
    });

    // const invoke_handler = e => {
    //   console.log('invoke_handler', e);
    //   state.popup.emitter.emit(e.name, e.arguments);
    // };

    return {
      state,
      asyncComponent,
      // invoke_handler,
    };
  },
});
</script>

<template>
  <component :is="asyncComponent" :popup-id="state.popupId" v-bind="state.data" />
</template>

<template>
  <pmis-content-box>
    <template v-slot:title>회사기본정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header>회사코드</i-col-header>
        <i-col>
          <iui-text width="100px" :value="companyInfo.pgmCd" :enable="false" name="pgmCd" />
        </i-col>
        <i-col-header>회사구분</i-col-header>
        <i-col>
          <iui-text name="frmGb" :value="frmGbNm" :enable="false" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>회사명</i-col-header>
        <i-col colspan="3">
          <iui-text
            max-length="33"
            :value="companyInfo.frmNm"
            @change="setCompanyInfo({frmNm: $event.target.value})"
            :enable.sync="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>회사명(영문)</i-col-header>
        <i-col colspan="3">
          <iui-text
            max-length="100"
            :value="companyInfo.frmEnNm"
            @change="setCompanyInfo({frmEnNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>사업자번호</i-col-header>
        <i-col>
          <iui-text width="35px" max-length="3" :value.sync="bsnNo1" @change="setBsnNo" :enable="enable" />
          <div class="mr5">-</div>
          <iui-text width="30px" max-length="2" :value.sync="bsnNo2" @change="setBsnNo" :enable="enable" />
          <div class="mr5">-</div>
          <iui-text width="45px" max-length="5" :value.sync="bsnNo3" @change="setBsnNo" :enable="enable" />
          <iui-button value="중복확인" @click="onDupBsnNo" v-if="!companyInfo.pgmCd" />
        </i-col>
        <i-col-header>주민/법인번호</i-col-header>
        <i-col>
          <iui-text width="70px" max-length="6" :value.sync="regiNo1" @change="setRegiNo" :enable="enable" />
          <div class="mr5">-</div>
          <iui-text width="80px" max-length="7" :value.sync="regiNo2" @change="setRegiNo" :enable="enable" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>대표자명</i-col-header>
        <i-col>
          <iui-text
            width="200px"
            max-length="16"
            :value="companyInfo.rpsvNm"
            @change="setCompanyInfo({rpsvNm: $event.target.value})"
            :enable="enable"
          />
          <iui-button value="공동대표" @click="ceoListPopUp" />
          <!-- <iui-button value="공동대표" v-if="!companyInfo.pgmCd" /> -->
        </i-col>
        <i-col-header>대표자명(영문)</i-col-header>
        <i-col>
          <iui-text
            width="250px"
            max-length="50"
            :value="companyInfo.rpsvEnNm"
            @change="setCompanyInfo({rpsvEnNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>대표전화</i-col-header>
        <i-col colspan="3">
          <iui-text
            width="200px"
            max-length="16"
            :value="companyInfo.rpsvPhone"
            @change="setCompanyInfo({rpsvPhone: $event.target.value})"
            :enable="enable"
          />
          <label>(사업자등록증 주소)</label>
        </i-col>
      </i-row>
      <i-row rowspan="3">
        <i-col-header>주소</i-col-header>
        <i-col>
          <iui-text width="70px" :enable="enable" :value="companyInfo.postNo" />
          <iui-button value="검색" @click="onAddr" v-if="enable" />
        </i-col>
      </i-row>
      <i-row>
        <i-spacer />
        <i-col colspan="3">
          <iui-text :enable="enable" :value="companyInfo.adr1" />
        </i-col>
      </i-row>
      <i-row>
        <i-spacer />
        <i-col colspan="3">
          <iui-text :value="companyInfo.adr2" @change="setCompanyInfo({adr2: $event.target.value})" :enable="enable" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>홈페이지</i-col-header>
        <i-col>
          <iui-text
            max-length="300"
            :value="companyInfo.homepage"
            @change="setCompanyInfo({homepage: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>업태</i-col-header>
        <i-col>
          <iui-text
            max-length="33"
            :value="companyInfo.bcoNm"
            @change="setCompanyInfo({bcoNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
        <i-col-header>종목</i-col-header>
        <i-col>
          <iui-text
            max-length="33"
            :value="companyInfo.itemNm"
            @change="setCompanyInfo({itemNm: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col colspan="4">
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>거래은행</i-col-header>
              <i-col>
                <iui-select-old
                  p-code="0145"
                  :value="companyInfo.bankCode"
                  @change="setCompanyInfo({bankCode: $event.target.value})"
                  defaultCd="S"
                  :enable="enable"
                />
              </i-col>
              <i-col-header>계좌번호</i-col-header>
              <i-col>
                <iui-text
                  max-length="10"
                  :value="companyInfo.depositNo"
                  @change="setCompanyInfo({depositNo: $event.target.value})"
                  :enable="enable"
                />
              </i-col>
              <i-col-header>예금주</i-col-header>
              <i-col>
                <iui-text
                  max-length="16"
                  :value="companyInfo.depositor"
                  @change="setCompanyInfo({depositor: $event.target.value})"
                  :enable="enable"
                />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
    <iui-modal-old
      name="ceoListPopUp"
      title="공동대표"
      :btns="!companyInfo.pgmCd ? modalBtn : !modalBtn"
      @beforeClose="beforeClose"
      width="800px"
      height="600px"
    >
      <ceoInfo :isPopup="isPopup" />
    </iui-modal-old>
  </pmis-content-box>
</template>

<script>
import {mapGetters} from 'vuex';
import ceoInfo from './ceoInfo';
export default {
  components: {
    ceoInfo,
  },
  data() {
    return {
      bsnNo1: '',
      bsnNo2: '',
      bsnNo3: '',
      regiNo1: '',
      regiNo2: '',
      frmGbNm: '',
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
      modalBtn: [{name: '확인', callback: this.onConfirm}],
      isPopup: false,
    };
  },

  computed: {
    ...mapGetters(['externalData']),
  },

  watch: {
    externalData: {
      handler(val) {
        if (Object.keys(val).length == 0) return;
        let payload = {};
        payload['postNo'] = val.zipNo;
        payload['adr1'] = `${val.roadAddrPart1} ${val.roadAddrPart2}`;
        payload['adr2'] = val.addrDetail;
        this.setCompanyInfo(payload);
        this.$store.dispatch('setExternalData', {});
      },
      deep: true,
    },
    companyInfo: {
      handler(val) {
        if (val.frmGb) {
          $getCode(this, '0279').then(response => {
            response.forEach(el => {
              if (el.code == this.companyInfo.frmGb) this.frmGbNm = el.codeNm;
            });
          });
        }
        if (val.bsnNo) {
          this.bsnNo1 = val.bsnNo.substr(0, 3);
          this.bsnNo2 = val.bsnNo.substr(3, 2);
          this.bsnNo3 = val.bsnNo.substr(5, 5);
        }
        if (val.regiNo) {
          this.regiNo1 = val.regiNo.substr(0, 6);
          this.regiNo2 = val.regiNo.substr(6, 7);
        }
      },
      deep: true,
    },
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo', 'dupBsnNoStatus', 'ceoList']);
    $mapMutations(this, ['setCompanyInfo', 'setDupBsnNoStatus', 'setCeoList']);
  },

  created() {
    $getCode(this, '0279').then(response => {
      response.forEach(el => {
        if (el.code == this.companyInfo.frmGb) this.frmGbNm = el.codeNm;
      });
    });
  },

  methods: {
    setBsnNo() {
      this.setDupBsnNoStatus(0);
      this.setCompanyInfo({bsnNo: this.bsnNo1 + this.bsnNo2 + this.bsnNo3});
    },
    setRegiNo() {
      this.setCompanyInfo({regiNo: this.regiNo1 + this.regiNo2});
    },
    onAddr() {
      window.open(
        process.env.VUE_APP_BASE_URL + '/address/jusoPopup',
        'jusoPop',
        'width=570,height=420, scrollbars=yes, resizable=yes'
      );
    },
    onDupBsnNo() {
      if (this.companyInfo.bsnNo.length != 10) {
        this.$alert('사업자번호를 입력해주세요.');
        return;
      }
      axios.post('/companyRegist/selectCompanyExistBsnNo', {bsnNo: this.companyInfo.bsnNo}).then(response => {
        if (response.status == 200) {
          if (response.data) {
            this.$alert('이미 가입된 사업자입니다. <br> 운영팀에 연락바랍니다.');
            this.setDupBsnNoStatus(2);
          } else {
            this.$alert('사용할 수 있는 사업자번호 입니다.');
            this.setDupBsnNoStatus(1);
          }
        }
      });
    },

    ceoListPopUp() {
      this.isPopup = true;
      this.$modal.show('ceoListPopUp');
    },

    onConfirm() {
      this.callEvent({
        name: 'ceoInfo_getSaveJson',
        param: data => {
          this.setCeoList(data);
        },
      });
      this.$modal.hide('ceoListPopUp');
    },

    async beforeClose(e) {
      if (this.companyInfo.pgmCd) {
        let flag = false;
        this.callEvent({
          name: 'ceoInfo_getSaveJson',
          param: data =>
            data.forEach(async el => {
              if (el.status != '') {
                flag = true;
                e.stop();
              }
            }),
        });
        if (flag) {
          if (await this.$confirm('공동대표정보가 변경되었습니다.<br>저장하시겠습니까?')) {
            this.callEvent({name: 'saveCeoList'});
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.iui-container.table tr > th {
  width: 100px;
  min-width: 100px;
}
</style>

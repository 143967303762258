import Vue from 'vue';
import Router from 'vue-router';
import MainHome from '@/view/home/MainHome.vue';
import Popup from '@/view/common/popup/Popup.vue';
import WindowPopup from '@/view/common/popup/WindowPopup.vue';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {path: '/Home', component: MainHome},
    {path: '/Popup', component: Popup},
    {path: '/WindowPopup', component: WindowPopup},
  ],
});

export default router;

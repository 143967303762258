<template>
  <iui-container type="ibsheet" :id="elId" />
</template>

<script>
import sheetOpt from './sheetOpt.json';
import {mapActions} from 'vuex';
export default {
  data: function() {
    return {
      comps1: [],
      comps2: [],
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },
  created: function() {
    this.setMnuDs(0);
    this.onSearch();
    this.addEvent([{name: 'onSearch', func: this.onSearch}]);
  },
  mounted: function() {
    const me = this;
    this.sheet = IBSheet.create({
      id: me.sheetId, // 시트 객체 ID
      el: me.elId, // 시트를 생성할 DIV객체 ID
      options: sheetOpt, // 초기화 구문 변수
    });

    this.sheet.bind('onFocus', function(e) {
      me.onSystemTreeMenu(e.row);
    });

    this.sheet.bind('onDataLoad', () => this.sheet.showTreeLevel(2));
  },
  methods: {
    ...mapActions('menu', ['onSystemTreeMenu', 'setMnuDs']),
    onSearch: function(mnuCd) {
      const me = this;
      axios
        .post('/baseMenu/selectMenuList', {modify: true})
        .then(response => {
          me.menuList = Array.from(response.data);
          me.sheet.loadSearchData(me.menuList, 0);

          var focusRow;
          if (mnuCd) {
            for (let row of me.sheet.getDataRows()) {
              if (row.mnuCd == mnuCd) {
                focusRow = row;
                break;
              }
            }
          } else {
            focusRow = me.sheet.getRowById('AR1');
          }

          setTimeout(() => {
            me.sheet.focus(focusRow);
          }, 100);

          me.onSystemTreeMenu(focusRow);
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },
  },
};
</script>

<style scoped></style>

<template>
  <modal
    :name="name"
    :clickToClose="false"
    :draggable="true"
    :height="height"
    :scrollable="false"
    :width="width"
    :resizable="true"
    style="z-index: 100;"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="closed"
  >
    <!-- <modal :name="name" :width="width" :height="height" :clickToClose="false" :draggable="true"> -->
    <!-- <div class="popup" style="height:100%"> -->

    <!-- <div class="poph">
        <strong>{{ title }}</strong>
        <a href="#" @click="$modal.hide(name)">x</a>
    </div>-->
    <!-- <div style="height: calc(100% - 75px)">
        <slot></slot>
    </div>-->
    <!-- <div class="popbtn" v-if="btns.length">
        <button v-for="btn in btns" v-bind:key="btn.name" @click="btn.callback">{{ btn.name }}</button>
        <button @click="onClose">닫기</button>
    </div>-->
    <div class="popup_modal">
      <div class="fg" :style="{width: '100%', height: '100%'}">
        <div class="popup_header">
          <div>{{ internalTitle }}</div>
          <button class="btn_close" @click="$modal.hide(name)" v-if="enableCloseBtn">
            <span class="blind">닫기</span>
          </button>
        </div>
        <div class="popup_contents">
          <slot></slot>
        </div>
        <div class="popup_footer">
          <div class="footer-left"></div>
          <div class="footer-center"></div>
          <div class="footer-right">
            <button
              v-for="btn in btns"
              v-bind:key="btn.name"
              @click="btn.callback"              
              :class="btn.name == '닫기' ? 'btn_pop_neg' : 'btn_pop_pos'"
              v-show="btn.show!=false" 
              :id="btn.id"
              >{{ btn.name }}</button> <!--20211208 v-show="btn.show!=false" :id="btn.id"  추가-->
            <a href='javascript://' class='btn_pop_neg' @click="onClose" v-if="enableCloseBtn">닫기</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
      default: undefined,
    },
    btns: {
      type: Array,
      default: function() {
        return [];
      },
    },
    width: {
      type: String,
      default: '700px',
    },
    height: {
      type: String,
      default: '70%',
    },
    enableCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      internalTitle: '',
    };
  },
  created() {
    this.internalTitle = this.title;

    if (this.btns.length) {
      this.btns.forEach(obj => {
        if (!obj.callback) obj.callback = () => {};
      });
    }
  },
  methods: {
    onClose: function() {
      this.$modal.hide(this.name);
    },
    beforeOpen(event) {
      if (event.params) {
        this.internalTitle = event.params.title;
      }
    },
    opened() {
      this.$emit('opened');
    },
    beforeClose(e) {
      this.$emit('beforeClose', e);
    },
    closed() {
      this.$emit('closed');
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.$modal.hide(this.name);
      }
    },
  },
  watch: {
    title: function(newValue) {
      this.internalTitle = newValue;
    },
  },
};
</script>

<style>
.v--modal {
  border-radius: 10px;
  box-shadow:0 0 5px #333;
  min-width:300px;
  min-height:200px;
}
</style>

<template>
  <pmis-page ref="page">
    <button @click="onUpload">업로드</button>
    <fileInterface :sheet.sync="sheet" pgmCd="201900" mappedBy="pk" @handler="handler" @complete="onComplete" />
    <iui-container type="ibsheet" :id="elId" />
  </pmis-page>
</template>

<script>
import fileInterface from './FileInterface';
export default {
  components: {
    fileInterface,
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      sheet: {},
    };
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
          ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
          IgnoreFocused: 1,
        },
        Cols: [
          {
            Header: '순번',
            Name: 'seq',
            Type: 'Text',
            Width: 50,
            CanEdit: false,
          },
          {
            Header: '고유키',
            Name: 'pk',
            Type: 'Text',
            Width: 50,
            CanEdit: false,
          },
          {
            Header: '발신명의',
            Name: 'docNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: false,
          },
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      let data = [
        {seq: 1, pk: 'a001', docNm: '테스트1'},
        {seq: 2, pk: 'a002', docNm: '테스트2', flNo: 3520},
        {seq: 3, pk: 'a003', docNm: '테스트3'},
      ];

      this.sheet.loadSearchData(data);
    },

    handler(e) {
      this.upload = e.upload;
    },
    onUpload() {
      this.upload();
    },
    onComplete() {
      console.log('@@@@ complete @@@');
      console.log(this.sheet.getDataRows());
    },
  },
};
</script>

<style></style>

import {defineComponent, getCurrentInstance, toRef, onMounted, h} from '@vue/composition-api';

export default defineComponent({
  name: 'iui-loading-overlay',
  props: {
    iconType: {
      type: String,
      default: 'spinner',
    },
    iconWidth: {
      type: String,
      default: undefined,
    },
    iconHeight: {
      type: String,
      default: undefined,
    },
    iconWeight: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    iconBackgroundColor: {
      type: String,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: undefined,
    },
    opacity: {
      type: String,
      default: undefined,
    },
    message: {
      type: String,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();

    const visibleRef = toRef(props, 'visible');

    onMounted(() => {
      // vue3
      if (instance.parent.ctx) {
        instance.parent.ctx.$el.style.position = 'relative';
        return;
      }

      // vue2 + composition-api
      if (instance.parent.proxy) {
        instance.parent.proxy.$el.style.position = 'relative';
        return;
      }
    });

    // const childNodes = [];
    const background = h('div', {
      class: {background: true},
      style: {'backgroud-color': props.backgroundColor, opacity: props.opacity},
    });
    const icon = h('div', {
      class: {icon: true},
      style: {
        width: props.iconWidth,
        height: props.iconHeight,
        'border-width': props.iconWeight,
        'border-color': props.iconBackgroundColor,
        'border-top-color': props.iconColor,
      },
    });

    // childNodes.push(background);
    // childNodes.push(icon);

    // console.log('iui-loading-overlay', props.message);

    // if (props.message !== undefined && props.message !== null && props.message.length > 0) {
    //   const message = h('div', {class: 'message'}, props.message);
    //   childNodes.push(message);
    // }

    return () => {
      const childNodes = [];
      childNodes.push(background);
      childNodes.push(icon);

      if (props.message !== undefined && props.message !== null && props.message.length > 0) {
        const message = h('div', {class: 'message'}, props.message);
        childNodes.push(message);
      }

      const vnode = h(
        'div',
        {class: {'iui-loading-overlay': true}, style: {display: visibleRef.value ? 'flex' : 'none'}},
        childNodes
      );

      return vnode;
    };
  },
});

<template>
  <pmis-page :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row>
        <i-col width="450px">
          <pmis-tab-box>
            <template v-slot:title>목록</template>
            <CompanyList @emitCompanyInfo="onSearchDetail" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from './store/companyRegist.js';
import CompanyList from './components/CompanyList.vue';
export default {
  components: {CompanyList},
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['pgmCd']);
    $mapMutations(this, ['']);
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  data: function() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        {name: '신규', class: 'b', callback: this.onAdd},
        {name: '저장', class: 'b', callback: this.onSave},
        {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '상세정보',
          compNm: 'CompanyInfo',
          path: '@/view/sysManage/baseSysManage/companyRegist/components/CompanyInfo.vue',
        },
        {
          tabNm: '기초정보',
          compNm: 'BaseInfo',
          path: '@/view/sysManage/baseSysManage/companyRegist/components/BaseInfo.vue',
        },
      ],
      tabIndex: 0,
      isNew: false,
    };
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'CompanyList_search'});
    },
    setTabIndex(index) {
      if (this.isNew && index == 1) {
        this.$alert('상세정보를 먼저 등록해야합니다.');
      } else {
        this.tabIndex = index;
        this.callEvent({name: 'setActiveTab', param: this.tabIndex});
        this.onSearchDetail();
      }
    },
    onSearchDetail() {
      this.isNew = false;
      if (this.tabIndex == 0) {
        this.callEvent({name: 'CompanyInfo_search', param: this.pgmCd});
      } else {
        this.callEvent({name: 'BaseInfo_search', param: this.pgmCd});
      }
    },
    onAdd() {
      this.isNew = true;
      this.callEvent({name: 'setActiveTab', param: 0});
      this.callEvent({name: 'CompanyInfo_add'});
    },
    onSave() {
      this.callEvent({name: 'setActiveTab', param: 0});
      if (this.tabIndex == 0) {
        this.callEvent({name: 'CompanyInfo_save'});
      }
    },
    onDelete() {
      this.callEvent({name: 'setActiveTab', param: 0});
      this.callEvent({name: 'CompanyInfo_delete'});
    },
    onPrint() {},
  },
};
</script>

<style></style>

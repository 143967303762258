const state = {
  sideOpen: true,
  userMenu: false,
  cmmBtns: [],
};

const getters = {
  isSideOpen: state => state.sideOpen,
  isUserMenu: state => state.userMenu,
  cmmBtns: state => state.cmmBtns,
};

const actions = {
  sideToggle({state}) {
    state.sideOpen = !state.sideOpen;
  },
  userMenuToggle({state}) {
    state.userMenu = !state.userMenu;
  },
  setCmmBtns({state}, cmmBtns) {
    cmmBtns.forEach(e => {
      if (!e.callback) e.callback = () => {};
    });
    state.cmmBtns = cmmBtns;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <i-row>
        <i-col-header>계약번호</i-col-header>
        <i-col>
          <iui-text :value="etcDoc.ctrtNo" :enable="false" />
        </i-col>
        <i-col-header>종류</i-col-header>
        <i-col>
          <iui-select-old
            name="dockndcd"
            :items="arrDockndcd"
            :value="etcDoc.dockndcd"
            :enable="!this.readonly"
            @change="onSetValue"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>첨부 부연설명</i-col-header>
        <i-col colspan="3">
          <iui-text name="docrmcn" :value="etcDoc.docrmcn" :enable="!this.readonly" @change="onSetValue" />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>부속서류첨부</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-show="!this.readonly && !fileUpDown_files.lclFlNm"
            >추가</iui-button
          >
          <iui-button
            @click="fileUpDown_delete = true"
            v-show="!this.readonly && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >삭제</iui-button
          >
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.svrFlPth"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            v-model="fileUpDown_files"
            :file-number.sync="etcDoc.fileNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
export default {
  components: {
    FileUpDown,
  },
  // props: {
  //   etcdocsn: {
  //     type: [Number, String],
  //     default: undefined,
  //   },
  //   ctrtNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtOdr: {
  //     type: String,
  //     default: undefined,
  //   },
  //   ctrtDivCd: {
  //     type: String,
  //     default: undefined,
  //   },
  //   empNo: {
  //     type: String,
  //     default: undefined,
  //   },
  //   saveMode: {
  //     type: String,
  //     default: undefined,
  //   },
  //   readonly: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  beforeCreate() {
    $mapGetters(this, ['modalEtcDoc']);
  },
  created() {
    this.addEvent({name: 'EtcDoc_Save', func: this.onSave});
    this.addEvent({name: 'EtcDoc_Delete', func: this.onDelete});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      callback: '',

      arrDockndcd: [
        {text: '신분증', value: '10'},
        {text: '사업자등록증', value: '20'},
        {text: '위임장', value: '30'},
      ],

      etcdocsn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      empNo: '',
      saveMode: 'I',
      readonly: false,

      etcDoc: {
        etcdocsn: this.etcdocsn,
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        dockndcd: '10',
        docrmcn: '',
        fileNo: 0,
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },
  mounted() {
    // console.log("###############################");
    // console.log('this.etcdocsn', this.etcdocsn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("###############################");

    // console.log('modalEtcDoc', this.modalEtcDoc);

    this.etcdocsn = this.modalEtcDoc.etcdocsn;
    this.ctrtNo = this.modalEtcDoc.ctrtNo;
    this.ctrtOdr = this.modalEtcDoc.ctrtOdr;
    this.ctrtDivCd = this.modalEtcDoc.ctrtDivCd;
    this.saveMode = this.modalEtcDoc.saveMode;
    this.readonly = this.modalEtcDoc.readonly;

    this.etcDoc.etcdocsn = this.etcdocsn;
    this.etcDoc.ctrtNo = this.ctrtNo;
    this.etcDoc.ctrtOdr = this.ctrtOdr;
    this.etcDoc.ctrtDivCd = this.ctrtDivCd;

    // console.log("###############################");
    // console.log('this.etcdocsn', this.etcdocsn);
    // console.log('this.ctrtDivCd', this.ctrtDivCd);
    // console.log('this.saveMode', this.saveMode);
    // console.log('this.readonly', this.readonly);
    // console.log("###############################");

    if (this.etcDoc.etcdocsn > 0) {
      this.onSearch();
    }
  },
  methods: {
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.etcDoc[key] = value;
    },
    async onSearch() {
      let param = {
        etcdocsn: this.etcDoc.etcdocsn,
      };
      try {
        this.listBoxLoading = true;
        let response = await axios.post('/etcDoc/selectByEtcdocsn', param);
        this.listBoxLoading = false;
        this.etcDoc = response.data;
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onSave(callback) {
      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      let validation = await this.$validator.validate(this);
      if (!validation.isValid) {
        this.$alert('입력되지 않은 필수항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.');
        return;
      }

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.$alert('부속서류 파일을 첨부해야 합니다.');
        return;
      }

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.etcDoc,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          var uri = this.saveMode == 'I' ? '/etcDoc/insertEtcDoc' : '/etcDoc/updateByEtcdocsnSelective';

          let response = axios.post(uri, param);
          console.log(response);
          this.listBoxLoading = false;
          callback(response);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onDelete(callback) {
      if (this.saveMode == 'I') {
        this.$alert('저장 후 삭제가 가능합니다.');
        return;
      }

      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      if (!(await this.$confirm('삭제하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;

        let param = {
          ...this.etcDoc,
          pgmCd: this.userInfo.pgmCd,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        let response = axios.post('/etcDoc/deleteByEtcdocsn', param);
        console.log(response);
        this.listBoxLoading = false;
        callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.etcDoc.fileNo = 0;
      } else {
        this.etcDoc.fileNo = this.fileUpDown_files.flNo;
      }

      let param = {
        ...this.etcDoc,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      var uri = this.saveMode == 'I' ? '/etcDoc/insertEtcDoc' : '/etcDoc/updateByEtcdocsnSelective';
      try {
        let response = axios.post(uri, param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_error() {
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      console.log('파일 삭제가 완료되었습니다.');
    },
    file_delete_error() {
      console.log('파일 삭제를 실패했습니다.');
    },
  },
};
</script>

<style></style>

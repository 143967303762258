<template>
  <div>
    <article class="df">
      SET ::
      <iui-text id="searchCode" :value="searchCode" @change="setChangeValue('searchCode', $event)" />
      <iui-button
        value="GET_CODE"
        class="btn_08"
        style="margin-left: 3px;"
        @click="setChangeValue('searchCodeBtn', $event)"
      />
      <iui-button value="INIT_CODE" class="btn_07" style="margin-left: 3px;" @click="initCode()" />
      &emsp;=>&emsp;하위코드&nbsp;로드&nbsp;:&nbsp;{{ isLoad }} &emsp;/&emsp;하위코드&nbsp;존재&nbsp;:&nbsp;{{
        isExist
      }}
      &emsp;/&emsp;조회결과&nbsp;:&nbsp;{{ message }}
    </article>

    <br />
    <div :id="elId" style="width:70%; height:250px;"></div>

    <br />
    <br />(1)
    <select v-model="selectValue">
      <option value>선택</option>
      <option v-for="(i, idx) in dCodeList" :key="'S' + idx" :value="i.code">{{ i.codeNm }} ({{ i.code }})</option>
    </select>

    <br />
    <br />(2)
    <span v-for="(i, idx) in dCodeList" :key="'R' + idx">
      <input type="radio" name="rd" :id="'R' + idx" :value="i.code" v-model="radioValue" />
      <label :for="'R' + idx">
        <span />
        {{ i.codeNm }} ({{ i.code }})
      </label>
    </span>

    <br />
    <br />(3)
    <span v-for="(i, idx) in dCodeList" :key="'C' + idx">
      <input type="checkbox" :id="'C' + idx" :value="i.code" @change="setChangeValue('checkValues', $event)" />
      <label :for="'C' + idx">
        <span></span>
        <!-- X  <span /> -->
        {{ i.codeNm }} ({{ i.code }})
      </label>
    </span>

    <br />
    <br />
    <table class="bbs_list" style="width:70%">
      <colgroup>
        <col width="20%" />
        <col width="20%" />
        <col width="20%" />
        <col width="40%" />
      </colgroup>
      <thead>
        <tr>
          <th>U_CODE</th>
          <th>SELECT</th>
          <th>RADIO</th>
          <th>CHECKBOX</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ searchCode }}</td>
          <td>{{ selectValue }}</td>
          <td>{{ radioValue }}</td>
          <td>{{ checkValues }}</td>
        </tr>
      </tbody>
    </table>

    <iui-datepicker-old />
    <iui-datepicker-old />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  beforeCreate() {
    console.log('getStoreNm', this.$store.getters.getStoreNm);
  },
  created() {
    this.searchCode = 'YESNO';
    this.getCode('YESNO');
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchCode: '',
      dCodeList: [],
      message: '',
      isLoad: false,
      isExist: false,
      selectValue: '',
      radioValue: '',
      checkValues: [],
    };
  },
  mounted() {
    this.sheet = IBSheet.create({
      id: this.sheetId,
      el: this.elId,
      options: {
        Cfg: {CanEdit: 0},
        Def: {Row: {NoColor: 1, CanFocus: 0}},
        Cols: [
          {Header: '코드', Name: 'code', RelWidth: 1},
          {Header: '코드명', Name: 'codeNm', RelWidth: 1},
        ],
      },
    });
  },
  methods: {
    initCode() {
      this.$store.dispatch('code2/init');
      this.sheet.removeAll();
      this.searchCode = '';
      this.selectValue = '';
      this.radioValue = '';
      this.checkValues = [];
      this.dCodeList = [];
    },
    async getCode(code) {
      let promise1 = await $getCode(this, code);
      //alert(JSON.stringify(promise1));
      this.sheet.loadSearchData(promise1);
      this.dCodeList = promise1;
      this.message = 'message';
      this.isLoad = true;
      this.isExist = true;
    },
    setChangeValue(key, e) {
      if (key == 'searchCode') {
        this.searchCode = e.target.value == '' ? 'YESNO' : e.target.value;
        this.getCode(e.target.value == '' ? 'YESNO' : e.target.value);
      } else if (key == 'searchCodeBtn') {
        this.searchCode = this.searchCode == '' ? 'YESNO' : this.searchCode;
        this.getCode(this.searchCode);
      } else if (key == 'checkValues') {
        e.target.checked
          ? this.checkValues.push(e.target.value)
          : this.checkValues.splice(this.checkValues.indexOf(e.target.value), 1);
      }
    },
  },
};
</script>

<style scoped></style>

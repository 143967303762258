/* FOD > 발주해제 > 상세 TAB */
<script>
import {reactive, watch, computed , onMounted, getCurrentInstance} from '@vue/composition-api';
import {numToKr} from 'vue-number-to-kor';
import HistoryList from '@/view/common/components/HistoryList.vue';

export default {
  components: {HistoryList},
  props: {
    contractKey: {
      type: Object,
      default: undefined,
    },
    otisPage: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const vm = instance.proxy;    
    const state = reactive({
      loading: false,

      // 1. 계약 대상자_OTIS
      memberInfo1: {},

      // 1. 계약 대상자_업체
      memberInfo2: {},

      // 2. 계약 정보
      contractInfo: {},

      //개별 알림재송부  lgksja 20230111
      isCanResendNoti : computed(() => {
        return  (
          //console.log("tate.memberInfo.signDt",state.memberInfo.signDt),
          ( $isAdmin() || state.contractInfo.contEmpId == vm.$root.userInfo.empNo ) &&
          props.otisPage && 
          //state.memberInfo2.signDt != undefined && 
          (state.memberInfo2.signDt =='' || state.memberInfo2.signDt ==null) && 
          state.memberInfo2.tradeType !='OTIS' &&
          (state.contractInfo.ctrtSttusCd =='10' || state.contractInfo.ctrtSttusCd =='20')
        )
      }),
      isCanUrlCopy : computed(() => {
        
        return  (
          //console.log("state.memberInfo.urlLink",state.memberInfo.urlLink),
          ( $isAdmin() || state.contractInfo.contEmpId == vm.$root.userInfo.empNo ) &&
          props.otisPage && 
          state.memberInfo2.urlLink != undefined && 
          state.memberInfo2.urlLink !='' && 
          state.memberInfo2.tradeType !='OTIS'
        )
      }),
    });

    watch(
      () => props.contractKey,
      newValue => {
        // 초기화
        state.memberInfo1 = {}; // 계약 대상자_OTIS
        state.memberInfo2 = {}; // 계약 대상자_업체
        state.contractInfo = {}; // 계약 정보

        // contractKey가 변경된 경우 처리
        if (newValue === undefined) {
          return;
        }

        method.getData();
      }
    );

    onMounted(() => {
      if (props.contractKey) {
        method.getData();
      }
    });

    const method = {
      async getData() {
        state.loading = true;

        const param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
        };

        try {
          const response = await axios.post('/fod/ordercancel/selectOrderCancelView', param);

          state.contractInfo = response.data;

          // 1. 계약 대상자
          const memberList = state.contractInfo.memberList;
          const member1 = Array.from(memberList).filter(m => m.tradeType == 'OTIS')[0]; // OTIS
          const member2 = Array.from(memberList).filter(m => m.tradeType == 'CCPY')[0]; // 업체

          state.memberInfo1 = member1; // OTIS
          state.memberInfo2 = member2; // 업체

          // 2. 계약 정보
          state.contractInfo.contTotAmtKor = numToKr(state.contractInfo.contTotAmt); // 총금액
          state.contractInfo.contSupplyAmtKor = numToKr(state.contractInfo.contSupplyAmt); // 공급금액
          state.contractInfo.contVatAmtKor = numToKr(state.contractInfo.contVatAmt); // 부가세액
          state.contractInfo.contBaseAmtKor = numToKr(state.contractInfo.contBaseAmt); // 기본금액
          state.contractInfo.contOptionAmtKor = numToKr(state.contractInfo.contOptionAmt); // 옵션금액
          state.contractInfo.contTravelAmtKor = numToKr(state.contractInfo.contTravelAmt); // 출장금액
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
        }
      },
      //개별 알림재송부  lgksja 20230111
      async resendNoti() {
        if (!(await vm.$confirm(state.memberInfo2.tradeName + '에게 알림재송부 하시겠습니까?'))) return;

        const param = {
          ctrtNo: props.contractKey.ctrtNo,
          ctrtOdr: props.contractKey.ctrtOdr,
          ctrtDivCd: props.contractKey.ctrtDivCd,
          tradeNo: state.memberInfo2.tradeNo,
          tradeSfx: state.memberInfo2.tradeSfx,
        };
        //console.log("state.memberInfo",state.contractInfo);
        state.loading = true;
        try {
          await axios.post('/contract/reSendNoti', param);
          await vm.$alert('알림재송부가 완료되었습니다.');
          state.loading = false;
          //state.grid1.removeAll();
          await method.getData();
        } catch (ex) {
          console.log(ex);
          state.loading = false;
          await vm.$alert('알림재송부 중 오류가 발생하였습니다.');
        } finally {
          state.loading = false;
        }
        
      },
      onCopy() {
        vm.$alert(
          '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
        );
      },
      onError(e) {
        vm.$alert('클립보드 복사에 실패하였습니다.');
        console.log(e);
      },
    };

    return {
      state,
      ...method,
    };
  },
};
</script>

<template>
  <iui-tab-group :loading="state.loading">
    <iui-tab title="계약 대상자">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="6" width="80px">OTIS</i-col-header>
                <i-col-header width="100px">사업자번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo1.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.repntName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.address" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명일시</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.signDt" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>방법 / IP</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo1.signMthNm" disable />
                  <iui-textbox v-model="state.memberInfo1.accesIp" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header rowspan="6" width="80px">업체</i-col-header>
                <i-col-header width="100px">사업자(주민)번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.businessNo" disable />
                  <iui-textbox v-if="otisPage" v-model="state.memberInfo2.displayTradeNo" width="90px" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>상호(이름)</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.tradeName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>대표자</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.repntName" disable />
                  <iui-button
                    color="blue"
                    v-if="state.isCanUrlCopy"
                    v-clipboard:copy="state.memberInfo2.urlLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >URL복사</iui-button
                  >
                  <iui-button iui-button color="blue" v-if="state.isCanResendNoti" @click="resendNoti">알림재송부</iui-button>
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>주소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.address" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명일시</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.signDt" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>방법 / IP</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.memberInfo2.signMthNm" disable />
                  <iui-textbox v-model="state.memberInfo2.accesIp" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="계약 정보">
      <iui-container type="css-flex">
        <i-row>
          <i-col>
            <iui-container type="table" header-width="200px">
              <i-row>
                <i-col-header colspan="2">계약번호</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtNo" disable />
                </i-col>
                <i-col-header colspan="2">차수</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.ctrtOdr" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">프로젝트</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.projectNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.projectName" disable />
                </i-col>
                <i-col-header colspan="2">항번</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.mfgNo" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">설치장소</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.instAddress" disable />
                </i-col>
                <i-col-header colspan="2">기종</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.modelType" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">고객</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.custTradeNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.custTradeName" disable />
                </i-col>
                <i-col-header colspan="2">설치팀</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.instTeamName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">업체</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.tradeNo" width="90px" disable />
                  <iui-textbox v-model="state.contractInfo.tradeName" disable />
                </i-col>
                <i-col-header colspan="2">SV</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.svEmpName" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">발주일자</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.contDate" disable />
                </i-col>
                <i-col-header colspan="2">작업요청일자</i-col-header>
                <i-col>
                  <iui-datepicker v-model="state.contractInfo.workReqstDate" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2">작업내용</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.workDesc" disable />
                </i-col>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
              </i-row>
              <i-row>
                <i-col-header rowspan="3">계약금액</i-col-header>
                <i-col-header>총금액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contTotAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contTotAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2">기본금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contBaseAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contBaseAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>공급금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contSupplyAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contSupplyAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2">옵션금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contOptionAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contOptionAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>부가세액</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.contVatAmt" type="number" prefix="\" disable width="160px" />
                  <iui-textbox v-model="state.contractInfo.contVatAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
                <i-col-header colspan="2">출장금액</i-col-header>
                <i-col>
                  <iui-textbox
                    v-model="state.contractInfo.contTravelAmt"
                    type="number"
                    prefix="\"
                    disable
                    width="160px"
                  />
                  <iui-textbox v-model="state.contractInfo.contTravelAmtKor" prefix="(일금" suffix="원 정)" disable />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header colspan="2"></i-col-header>
                <i-col> </i-col>
                <i-col-header colspan="2">출장율</i-col-header>
                <i-col>
                  <iui-textbox v-model="state.contractInfo.travelRate" suffix="%" width="100px" disable />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
      </iui-container>
    </iui-tab>
    <iui-tab title="진행 이력">
      <history-list :document-key="contractKey" />
    </iui-tab>
  </iui-tab-group>
</template>

<style></style>

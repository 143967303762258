<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>자격면허정보</template>
          <iui-container type="ibsheet" :id="elId" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-spacer />
    <i-row height="100px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>장애인정보</template>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>장애유형</i-col-header>
              <i-col>
                <iui-select-old
                  name="handicapKind"
                  width="120px"
                  p-code="0190"
                  :value="manageInfo.handicapKind"
                  :defaultCd="'S'"
                  @change="setValue('handicapKind', $event)"
                />
              </i-col>
              <i-col-header>장애등급</i-col-header>
              <i-col>
                <iui-text
                  name="handicapGrade"
                  :value="manageInfo.handicapGrade"
                  @change="setValue('handicapGrade', $event)"
                />
              </i-col>
              <i-col-header>장애판정일</i-col-header>
              <i-col>
                <iui-datepicker-old
                  name="handicapDate"
                  :value="manageInfo.handicapDate"
                  @change="setValue('handicapDate', $event)"
                />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row height="120px">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>외국인정보</template>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>여권번호</i-col-header>
              <i-col>
                <iui-text name="passportNo" :value="manageInfo.passportNo" @change="setValue('passportNo', $event)" />
              </i-col>
              <i-col-header>국적</i-col-header>
              <i-col>
                <iui-select-old
                  name="nationCd"
                  width="100%"
                  p-code="0030"
                  :value="manageInfo.nationCd"
                  :defaultCd="'S'"
                  @change="setValue('nationCd', $event)"
                />
              </i-col>
              <i-col-header>체류자격</i-col-header>
              <i-col>
                <iui-select-old
                  name="visaKind"
                  width="100%"
                  p-code="0245"
                  :value="manageInfo.visaKind"
                  :defaultCd="'S'"
                  @change="setValue('visaKind', $event)"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col colspan="2" />
              <i-col-header>입국일자</i-col-header>
              <i-col>
                <iui-datepicker-old
                  name="entryDate"
                  :value="manageInfo.entryDate"
                  @change="setValue('entryDate', $event)"
                />
              </i-col>
              <i-col-header>체류기간</i-col-header>
              <i-col>
                <iui-text
                  name="stayDuration"
                  :value="manageInfo.stayDuration"
                  @change="setValue('stayDuration', $event)"
                />
              </i-col>
            </i-row>
          </iui-container>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['keyInfo', 'manageInfo']);
    $mapMutations(this, ['setManageInfo']);
  },
  created() {
    this.addEvent([
      {name: 'Manage_search', func: this.onSearch},
      {name: 'Manage_add', func: this.onAdd},
      {name: 'Manage_save', func: this.onSave},
      {name: 'Manage_delete', func: this.onDelete},
    ]);
  },
  data: function() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      focusKeyCode: '',
    };
  },
  mounted: function() {
    const me = this;
    const OPT = {
      Cfg: {
        ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
        FitWidth: true,
      },
      Cols: [
        {
          Header: '선택',
          Type: 'Bool',
          Name: 'cbx',
          Width: '60',
          CanEdit: true,
        },
        {
          Header: '순번',
          Name: 'skillSeq',
          Type: 'Text',
          Width: 60,
          CanEdit: false,
          Align: 'center',
        },
        {
          Header: '기술분야',
          Name: 'wpart',
          Type: 'Text',
          RelWidth: 1.8,
          CanEdit: true,
          Align: 'center',
        },
        {
          Header: '기술등급',
          Name: 'wpartGrade',
          Type: 'Text',
          RelWidth: 1.3,
          CanEdit: true,
          Align: 'center',
        },
        {
          Header: '자격등급',
          Name: 'wcertGrade',
          Type: 'Text',
          RelWidth: 1.3,
          CanEdit: true,
          Align: 'center',
        },
        {
          Header: '기술종목',
          Name: 'wpartDetail',
          Type: 'Text',
          RelWidth: 1.8,
          CanEdit: true,
          Align: 'center',
        },
        {
          Header: '자격증번호',
          Name: 'wcertNo',
          Type: 'Text',
          RelWidth: 1.3,
          CanEdit: true,
          Align: 'center',
        },
        {
          Header: '취득일',
          Name: 'wcertDate',
          Extend: this.$ibsheetHelper.preset.date,
          CanEdit: true,
        },
        {Name: 'pgmCd', Visible: 0},
        {Name: 'empNo', Visible: 0},
      ],

      Events: {
        onSearchFinish: me.grid_searchFinish,
      },
    };

    me.sheet = IBSheet.create({
      id: me.sheetId,
      el: me.elId,
      options: OPT,
    });

    me.$ibsheetHelper.bindDefaultDeleteEvent(me.sheet); // 삭제 이벤트

    me.onSearch();
  },
  methods: {
    onSearch() {
      this.focusKeyCode = '';
      this.onSearchProc();
    },
    async onSearchProc() {
      let response = await axios.post('/worker/selectManageInfos', this.keyInfo);
      this.sheet.loadSearchData(response.data.workSkillList);
      this.setManageInfo(response.data.workerInfo);
    },
    grid_searchFinish() {
      let focusRow = {};
      for (let row of this.sheet.getDataRows()) {
        if (row.skillSeq == this.focusKeyCode) {
          focusRow = row;
          break;
        }
      }
      setTimeout(() => this.sheet.focus(focusRow), 100);
    },
    setValue(key, e) {
      let payload = {};
      payload[key] = key == 'handicapDate' || key == 'entryDate' ? e : e.target.value;
      this.setManageInfo(payload);
    },
    onAdd() {
      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.manageInfo.pgmCd, 1);
      this.sheet.setValue(addRow, 'empNo', this.manageInfo.empNo, 1);
    },
    async onSave() {
      if (await this.$confirm('저장하시겠습니까?')) {
        let response = await axios
          .post('/worker/saveManageInfos', {
            workerSkillList: this.sheet.getSaveJson().data,
            ...this.manageInfo,
          })
          .catch(e => console.error('저장을 실패하였습니다. : ', e));

        if (response.status == 200) {
          this.focusKeyCode = response.data;
          this.onSearchProc();
        } else {
          this.$alert('저장을 실패하였습니다.');
        }
      }
    },
    onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert('선택된 자격면허정보가 없습니다');
        return;
      }
      checkedRows.forEach(row => this.sheet.deleteRow(row));
    },
  },
};
</script>

<style scoped></style>

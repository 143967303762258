const state = {
  searchInfo: {
    pgmCd: '',
    frmNm: '',
    searchSe: 'empName',
    searchNm: 'TEST',
  },

  tabIndex: 0,
  empNo: '',
};

const getters = {
  searchInfo: state => state.searchInfo,
  tabIndex: state => state.tabIndex,
  empNo: state => state.empNo,
};

const actions = {};

const mutations = {
  setSearchInfo(state, payload) {
    for (let key in state.searchInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.searchInfo[key] = payload[key];
      }
    }
  },
  setTabIndex(state, index) {
    state.tabIndex = index;
  },
  setEmpNo(state, payload) {
    state.empNo = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<script>
import {defineComponent, reactive, computed, provide, useSlots} from '@vue/composition-api';
import {BUTTON_TYPE} from '@/components/Iui/const.js';

export default defineComponent({
  name: 'iui-content-box-header-box',
  props: {},
  provide() {
    return {
      defaultButtonType: BUTTON_TYPE.SMALL,
    };
  },
  computed: {
    // headerBoxVisible() {
    //   return this.headerLeftVisible || this.headerCenterVisible || this.headerRightVisible;
    // },
    headerLeftVisible() {
      return this.$slots['header-left'] !== undefined;
    },
    headerCenterVisible() {
      return this.$slots['header-center'] !== undefined;
    },
    headerRightVisible() {
      return this.$slots['header-right'] !== undefined;
    },
  },
  // setup(props, context) {
  //   provide('defaultButtonType', BUTTON_TYPE.SMALL);

  //   const state = reactive({
  //     headerLeftVisible: computed(() => context.slots['header-left']()),
  //     headerCenterVisible: computed(() => context.slots['header-center']()),
  //     headerRightVisible: computed(() => context.slots['header-right']()),
  //   });

  //   return {
  //     state,
  //   };
  // },
});
</script>

<template>
  <div class="header-box">
    <div v-if="headerLeftVisible" class="header-left">
      <slot name="header-left" />
    </div>
    <div class="header-center">
      <slot name="header-center" />
    </div>
    <div v-if="headerRightVisible" class="header-right">
      <slot name="header-right" />
    </div>
  </div>
</template>

<style></style>

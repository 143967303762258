<!--
Name : iui-datapicker
Description :
old1 : https://www.npmjs.com/package/vue2-slot-calendar
old2 : https://www.npmjs.com/package/vuejs-datepicker
new : https://www.npmjs.com/package/vue2-datepicker
-->
<template>
  <div :class="rootClass" :style="rootStyle">
    <label v-if="label" :id="labelId" :for="datePickerId" class="label" :style="labelStyle">{{ label }}</label>
    <span v-if="prefix" :id="prefixId" class="prefix" :style="prefixStyle">{{ prefix }}</span>
    <date-picker
      ref="datePicker"
      v-model="vdata"
      :class="{
        datepicker: true,
      }"
      :type="type"
      :format="vformat"
      :value-type="vvalueType"
      :editable="!readonly"
      :clearable="!readonly"
      :popup-style="{visibility: readonly ? 'hidden' : ''}"
      :disabled="disabled || !enable"
      :lang="lang"
      :input-class="datePickerInputClass"
      :input-attr="datePickerInputAttr"
      :placeholder="isValid ? '' : errorMessage"
      @input="onInput"
      @blur="onBlur"
      style="border:0px;"
      autocomplete="off"
    ></date-picker>
    <span v-if="suffix" v-bind:id="suffixId" class="suffix" :style="suffixStyle">{{ suffix }}</span>
  </div>
</template>

<script>
import IuiBaseMixin from '@/components/Iui/mixins/IuiBaseMixin';
import IuiLayoutMixin from '@/components/Iui/mixins/IuiLayoutMixin';
import IuiLabelPrefixSuffixMixin from '@/components/Iui/mixins/IuiLabelPrefixSuffixMixin';
import {IuiValidatorMixin} from '@/plugins/IuiValidator';
import rootStore from '@/store';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'iui-datepicker-old',
  mixins: [IuiBaseMixin, IuiLayoutMixin, IuiLabelPrefixSuffixMixin, IuiValidatorMixin],
  components: {DatePicker},
  props: {
    // TODO: etc
    name: {
      type: String,
    },
    value: {
      type: [String, Date, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'date', // date|datetime|year|month|time|week
    },
    format: {
      type: String,
      default: '',
    },
    valueType: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // bindArray: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data: function() {
    return {
      lang: {
        // months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        //20230105 lgksja 날짜 아래로 변경
        days:["일", "월", "화", "수", "목", "금", "토", "일"],
        daysShort: ["일", "월", "화", "수", "목", "금", "토", "일"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토", "일"],
        months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        monthsShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        yearFormat: "YYYY년",
        monthFormat: "M월",
        monthBeforeYear: false,
      },
      vdata: '',
      vformat: 'YYYY-MM-DD',
      vvalueType: 'date',
    };
  },
  computed: {
    datePickerId: {
      get: function() {
        return this.getId('datepicker');
      },
    },

    // class
    rootClass: {
      get() {
        let obj = {
          'iui-datepicker': true,
          'iui-layout-debug': this.layoutDebug,
        };

        return obj;
      },
    },
    datePickerInputClass: {
      get() {
        let obj = {
          'mx-input': true,
          required: this.required,
          'is-valid-fail': !this.isValid,
        };

        return obj;
      },
    },

    // style
    rootStyle: {
      get() {
        let obj = {
          width: undefined,
          height: undefined,
          flex: undefined,
        };

        if (this.width !== undefined) {
          obj.width = this.width;
          obj.flex = `0 0 this.width`;
        }
        if (this.height !== undefined) {
          obj.height = this.height;
        }

        return obj;
      },
    },
    datePickerInputStyle: {
      get() {
        let obj = {
          width: undefined,
        };

        obj.width = undefined;

        // switch (this.type) {
        //   case 'date': {
        //     obj.width = '90px';
        //     break;
        //   }
        //   case 'month': {
        //     obj.width = '90px';
        //     break;
        //   }
        //   case 'year': {
        //     obj.width = '90px';
        //     break;
        //   }
        //   default: {
        //     break;
        //   }
        // }

        return obj;
      },
    },
    datePickerInputAttr: {
      get() {
        return {id: this.datePickerId, style: {...this.datePickerInputStyle}};
      },
    },
  },
  created: function() {
    const me = this;

    if (me.type == 'date') me.vformat = 'YYYY-MM-DD';
    if (me.type == 'month') me.vformat = 'YYYY-MM';
    if (me.type == 'year') me.vformat = 'YYYY';
    me.vformat = me.format == '' ? me.vformat : me.format;

    if (me.type == 'date') me.vvalueType = 'YYYYMMDD';
    if (me.type == 'month') me.vvalueType = 'YYYYMM';
    if (me.type == 'year') me.vvalueType = 'YYYY';
    me.vvalueType = me.valueType == '' ? me.vvalueType : me.valueType;

    me.vdata = me.value;

    me.validatorTargetDataProp = 'vdata';
    me.validationTriggerEventName = ['change'];
  },
  mounted: function() {
    const me = this;
    // let el = me.$el.querySelector('._target_ > .mx-datepicker > .mx-input-wrapper > input');
    let el = me.$refs.datePicker.$refs.input;
    el.addEventListener('keyup', function() {
      let v = String(this.value).replace(/[^0-9]/g, '');
      if (me.type == 'date' && v.length >= 8) {
        v = v.substr(0, 4) + '-' + v.substr(4, 2) + '-' + v.substr(6, 2);
      } else if (me.type == 'month' && v.length >= 6) {
        v = v.substr(0, 4) + '-' + v.substr(4, 2);
      } else if (me.type == 'year' && v.length >= 4) {
        v = v.substr(0, 4);
      }
      me.onInput(v);
    });
  },
  methods: {
    setBindArray(value) {
      const me = this;
      if (typeof value != 'undefined') {
        me.bindArray.forEach(function(o) {
          let payload = {};
          payload[me.name] = value.replace(/[^0-9]/g, '');
          rootStore.commit(rootStore.getters.currentUrl + '/' + o, payload);
        });
      }
    },
    onInput: function(v) {
      v = v == null ? '' : v;
      this.vdata = v;
      this.$emit('change', v.replace(/[^0-9]/g, ''));
      this.$emit('update:value', v.replace(/[^0-9]/g, ''));
      this.setBindArray(v);
    },
    onBlur: function(e) {
      if (e.target.value == '') {
        this.onInput(e.target.value);
      }
    },
  },
  watch: {
    value(newValue) {
      this.vdata = String(newValue);
    },
  },
};
</script>

<style lang="scss">
// @import '../Iui/Iui.scss';

// .iui-datepicker-old {
//   @include iui-element-box('', '', true, true, true);

//   // * {
//   //   font-family: '맑은 고딕' !important;
//   //   font-size: 12px !important;
//   // }

//   .datepicker {
//     @include iui-element-box-element;
//     min-width: $iui-datepicker-old-datepicker-min-width;
//   }
// }
</style>

<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <IntegrationList @click="onClickListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from '@/view/contract/oContract/store/oContract.js';
import IntegrationList from '../components/IntegrationList.vue';
export default {
  name: 'OContractIntegrationList',
  components: {
    // eslint-disable-next-line
    IntegrationList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'contractList', 'contractInfo']);
    $mapMutations(this, [
      'setSearchInfo',
      'setContractList',
      'setContractInfo',
      'setItemList',
      'setSafetyPackageList',
      'setMaintPlanList',
      'setMemberList',
      'setPaymentList',
      'setStamptaxList',
      'setGuaranteeList',
      'setEtcDocList',
      'resetAllValues',
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      contentBoxLoading: false,
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'Contract_SearchDetail', func: this.onSearchDetail});
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    this.onSearch();
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'OContractIntegrationList_Search'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },
    async onPrint() {
      this.$store.dispatch('mdi/doPrint');
    },

    onClickListRow(row) {
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;
      this.onSearchDetail();
    },
    async onSearchDetail() {
      this.contentBoxLoading = true;
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
      };
      let response = await axios.post('/ocontract/selectAllContractInfo', param);
      this.contentBoxLoading = false;
      this.setContractInfo(response.data.contractInfo);
      this.setItemList(response.data.itemList);
      this.setSafetyPackageList(response.data.safetyPackageList);
      this.setMaintPlanList(response.data.maintPlanList);
      this.setMemberList(response.data.memberList);
      this.setPaymentList(response.data.paymentList);
      this.setStamptaxList(response.data.stamptaxList);
      this.setGuaranteeList(response.data.guaranteeList);
      this.setEtcDocList(response.data.etcDocList);
    },
    onResetValues() {
      console.log('onResetValues');
      this.resetAllValues();
    },
  },
};
</script>

<style scoped></style>

<template>
  <pmis-tab-box>
    <template v-slot:title>사용자목록</template>
    <pmis-content-box>
      <template v-slot:header-left>
        <iui-select-old
          name="searchSe"
          :items="searchSeList"
          :value="'empName'"
          @change="setSearchInfo({searchSe: $event.target.value})"
        ></iui-select-old>
        <iui-text @change="setSearchInfo({searchNm: $event.target.value})" @enter="onSearch" width="150px" />
        <iui-button value="검색" @click="onSearch" />
      </template>
      <iui-container type="jqgrid" :id="elId" />
    </pmis-content-box>
  </pmis-tab-box>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchSe: 'empName',
      searchNm: '',
      searchSeList: [
        {value: 'empName', text: '성명'},
        {value: 'empNo', text: '사번'},
        {value: 'userid', text: '아이디'},
      ],
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
    $mapMutations(this, ['setSearchInfo']);
  },

  created() {
    this.addEvent([
      {
        name: 'userList_selectLoginUserList',
        func: this.selectLoginUserList,
      },
    ]);
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    // eslint-disable-next-line
    formatterUTCDate(cellvalue, options, rowObject) {
      var dt = new Date(cellvalue);
      if (isNaN(dt)) return '';
      var ymdhms =
        (dt.getFullYear() * 10000 + (dt.getMonth() + 1) * 100 + dt.getDate()).toString() +
        (1000000 + dt.getHours() * 10000 + dt.getMinutes() * 100 + dt.getSeconds()).toString().substring(1);

      return (
        ymdhms.substring(0, 4) +
        '-' +
        ymdhms.substring(4, 6) +
        '-' +
        ymdhms.substring(6, 8) +
        ' ' +
        ymdhms.substring(8, 10) +
        ':' +
        ymdhms.substring(10, 12) +
        ':' +
        ymdhms.substring(12, 14)
      );
    },
    initSheet() {
      var me = this;
      const OPT = {
        Cols: [
          {label: 'No', name: 'rowNumber', align: 'center', width: 30},
          {
            label: '아이디',
            name: 'userid',
            align: 'center',
            width: 80,
          },
          {
            label: '사번',
            name: 'empNo',
            align: 'center',
            width: 80,
          },
          {
            label: '성명',
            name: 'empName',
            Type: 'Text',
            width: 120,
          },
          {
            label: '최종접속시간',
            name: 'lgnDttm',
            align: 'center',
            formatter: me.formatterUTCDate,
          },
        ],
      };
      var $grid = $('#' + me.elId);
      $grid.jqGrid({
        height: 200,
        width: 800,
        autowidth: true,
        shrinkToFit: true,
        selectionMode: 'singlerow',
        colModel: OPT.Cols,
        onSelectRow: function(rowid) {
          var $grid = $('#' + me.elId);
          var rowdata = $grid.getRowData(rowid);
          me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
        },
      });
      $gridAutoResize($grid);
    },
    onSearch() {
      this.selectLoginUserList({
        searchSe: this.searchInfo.searchSe,
        searchNm: this.searchInfo.searchNm,
        pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      });
    },
    selectLoginUserList(param) {
      const me = this;
      var $grid = $('#' + me.elId);

      if (param == null || param.searchNm == '') return;

      var sparam = {
        pgmCd: this.userInfo.pgmCd,
        empName: param.searchSe == 'empName' ? param.searchNm : '',
        empNo: param.searchSe == 'empNo' ? param.searchNm : '',
        userid: param.searchSe == 'userid' ? param.searchNm : '',
      };

      $grid.jqGrid('clearGridData');
      axios.post('/loginLog/selectLoginUserList', sparam).then(response => {
        if (response.status == 200) {
          var data = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i]['rowNumber'] = i + 1;
          }
          $grid.jqGrid('setGridParam', {data: data}).trigger('reloadGrid');
        }
      });
    },
  },
};
</script>

<style scoped></style>

const name = '/SYS301';
const state = {
  upperDetailInfo: {
    pgmCd: '',
    cmnUCd: '',
    cmnUNm: '',
    cmnDCd: '',
    cmnDNm: '',
    etc: '',
  },
};

const getters = {
  upperDetailInfo: state => state.upperDetailInfo,
};

const actions = {};

const mutations = {
  setUpperDetailInfo(state, payload) {
    for (let key in state.upperDetailInfo) {
      if (payload && payload.hasOwnProperty(key)) {
        state.upperDetailInfo[key] = payload[key];
      } else {
        state.upperDetailInfo[key] = '';
      }
    }
  },
};

export default {
  name,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

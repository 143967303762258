<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-select-old :items="selSearchItems" defaultCd="S" :value.sync="searchSe"> </iui-select-old>
      <iui-text type="text" :value.sync="searchNm" @enter="selectDeptUserList" />
      <iui-button value="검색" @click="selectDeptUserList"> </iui-button>
    </template>
    <template v-slot:header-right>
      조직 내의 정렬순서를 지정합니다
      <iui-button value="저장" @click="onBlnDepNoSeqUpdate" />
    </template>

    <iui-container type="ibsheet" :id="elId" />
  </pmis-content-box>
</template>

<script>
export default {
  data: function() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      searchSe: '',
      searchNm: '',
      selSearchItems: [
        {text: '사용자ID', value: 'empNo'},
        {text: '이름', value: 'name'},
      ],
    };
  },
  beforeCreate() {
    $mapGetters(this, ['searchInfo', 'deptInfo']);
  },
  created() {
    this.addEvent([{name: 'workerNm_selectDeptUserList', func: this.selectDeptUserList}]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/deptInfo`],
      value => {
        if (value) {
          this.selectDeptUserList();
        }
      },
      {deep: true}
    );
  },
  mounted() {
    this.initSheet();
  },
  methods: {
    initSheet: function() {
      const OPT = {
        Cols: [
          {
            Header: '아이디(사번)',
            Name: 'empNo',
            Type: 'Text',
            Align: 'Center',
            Width: 100,
            CanEdit: false,
          },
          {
            Header: '이름',
            Name: 'name',
            Type: '',
            Align: 'Center',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '부서명',
            Name: 'fldrNm',
            Type: 'Text',
            Align: 'Center',
            RelWidth: 1,
            CanEdit: false,
          },
          {
            Header: '정렬순서',
            Name: 'blnDepNoSeq',
            Type: 'Text',
            Align: 'Center',
            Width: 80,
            CanEdit: true,
          },
          {Name: 'pgmCd', Visible: false},
          {Name: 'fldrCd', Visible: false},
          {Name: 'fldrNo', Visible: false},
        ],
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
        },
      };

      if (this.sheet) this.sheet.dispose();
      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });
    },

    selectDeptUserList() {
      axios
        .post('/deptRegist/selectDeptUserList', {
          pgmCd: this.searchInfo.pgmCd,
          fldrNo: this.deptInfo.fldrNo,
          searchSe: this.searchSe,
          searchNm: this.searchNm,
        })
        .then(response => {
          if (response.status == 200) {
            this.sheet.loadSearchData(response.data);
          }
        });
    },

    onBlnDepNoSeqUpdate() {
      axios
        .post('/deptRegist/updateBlnDepNoSeq', {
          workerList: this.sheet.getSaveJson().data,
        })
        .then(response => {
          if (response.status == 200) {
            this.selectDeptUserList();
          }
        });
    },
  },

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style></style>

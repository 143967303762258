<script>
import {defineComponent, reactive} from '@vue/composition-api';
import moment from 'moment';

// prettier-ignore
const gridOptions = {
  Cfg: {
    CanEdit: 0,
  },
  /*
  LeftCols: [
    { Name: 'SEQ'                , Header: ' '               , Type: 'Int'  , Width: 40 , },
    { Name: 'contDate'           , Header: '체결 일자'       , Type: 'Date' , Width: 100, Align: 'Center', Format: 'yyyy-MM-dd', },
    { Name: 'rceptNo'            , Header: '인지세 납부 번호', Type: 'Text' , Width: 160, Align: 'Left'  , },
  ],
  Cols: [
    { Name: 'projectNo'          , Header: '프로젝트 번호'   , Type: 'Text' , Width: 150, Align: 'Center', },
    { Name: 'ctrtNo'             , Header: '전자계약 번호'   , Type: 'Text' , Width: 150, Align: 'Center', },
    { Name: 'ctrtOdr'            , Header: '차수'            , Type: 'Text' , Width: 50 , Align: 'Center', Visible: 0, },
    { Name: 'projectName'        , Header: '현장명'          , Type: 'Text' , Width: 200, Align: 'Left'  , MinWidth: 200, RelWidth: 1 },
    { Name: 'contTotAmt'         , Header: '계약 금액'       , Type: 'Float', Width: 200, Align: 'Right' , },
    { Name: 'stampTaxAmt'        , Header: '인지세 금액(1/N)', Type: 'Float', Width: 200, Align: 'Right' , },
    { Name: 'statusNm'           , Header: '발급실패'        , Type: 'Text' , Width: 150, Align: 'Center', },
  ],
  */
  LeftCols: [
    { Name: 'SEQ'                , Header: ' '               , Type: 'Int'  , Width: 40 , },
    {Name: 'signDt'           , Header: '계약확정일'       , Type: 'Text' , Width: 90, Align: 'Center', Format: 'yyyy-MM-dd', },
    {Name: 'contType', Header: '계약구분', Type: 'Text' ,Width: 80, Align: 'Center'},
    {Name: 'contType', Header: '계약구분', Type: 'Text' ,Width: 80, Align: 'Center'},
    {Name: 'signType', Header: '전자/서면', Type: 'Text' ,Width: 80, Align: 'Center'},
    {Name: 'combinedCtrtNo', Header: '계약번호-차수', Type: 'Text' ,Width: 120, Align: 'Center'},
    {Name: 'uniqueId', Header: '인지세납부번호', Type: 'Text' ,Width: 130, Align: 'Left'},
    
  ],
  Cols: [
    {Name: 'contractName', Header: '현장명', Type: 'Text' ,Width: 170, Align: 'Left', MinWidth: 200, RelWidth: 1 },
    {Name: 'ccNm', Header: 'CCT', Type: 'Text' ,Width: 120, Align: 'Left'},
    {Name: 'branchNm', Header: '지사', Type: 'Text' ,Width: 120, Align: 'Left'}, 
    {Name: 'prevIssueAmount',   Header: '이전 계약금액(VAT포함)', Type: 'Float', Width: 140, Align: 'Right' , },
    {Name: 'contractAmount',   Header: '계약금액(VAT포함)', Type: 'Float', Width: 140, Align: 'Right' ,FormulaRow:'인지세액(1/N) 합계' },
    {Name: 'issueAmount',  Header: '인지세액(1/N)', Type: 'Float', Width: 110, Align: 'Right' , FormulaRow:'Sum'},
    {Name: 'statusNm', Header: '발급실패', Type: 'Text' ,Width: 80, Align: 'Center'},
    {Name: 'period', Header: 'PERIOD', Type: 'Text' ,Width: 70, Align: 'Center'},
  ],
  Events: {},
};

export default defineComponent({
  setup(props, context) {
    // const instance = getCurrentInstance();
    // const vm = instance.proxy;

    const state = reactive({
      searchData: {
        ctrtNo: undefined,
        projectName: undefined,
        contMonth: moment().format('YYYYMM'),
      },
      grid: {
        options: gridOptions,
        object: undefined,
      },
      list: undefined,
      loading: false,
    });

    state.grid.options.Events = {
      ...state.grid.options.Events,
      onDataLoad() {
        state.loading = false; // 로딩 상태 완료로 변경
      },
    };

    const method = {
      search_click() {
        method.getList();
      },
      excel_click() {
        state.grid.object.down2Excel();
      },
      grid_created() {
        method.getList();
      },

      async getList() {
        if (state.loading) {
          return;
        }

        state.loading = true;

        state.grid.object.removeAll();

        const data = {
          /*
          ctrtNo: state.searchData.ctrtNo,
          projectName: state.searchData.projectName,
          */
          contDateFrom: moment(state.searchData.contMonth)
            .startOf('month')
            .format('YYYYMMDD'),
          contDateTo: moment(state.searchData.contMonth)
            .endOf('month')
            .format('YYYYMMDD'),
          period: state.searchData.contMonth,
          ctrtDivCd: 'NE',
        };

        try {
          const response = await axios.post('/ne/stamptax/targetList', data);

          state.list = response.data;
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        }
      },
    };

    return {
      state,
      ...method,
    };
  },
});
</script>

<template>
  <otis-page @search-click="search_click" @excel-click="excel_click">
    <iui-container type="css-flex" theme="bullet">
      <i-row height="70px" ref="searchBox">
        <i-col>
          <iui-container type="table" theme="bullet">
            <i-row>
              <!--
              <i-col-header>납부 번호</i-col-header>
              <i-col>
                <iui-textbox v-model="state.searchData.rceptNo" placeholder="납부 번호" @keyup.enter="getList" />
              </i-col>
              <i-col-header>계약 번호</i-col-header>
              <i-col>
                <iui-textbox v-model="state.searchData.ctrtNo" placeholder="계약 번호" @keyup.enter="getList" />
              </i-col>
              <i-col-header>문서명</i-col-header>
              <i-col>
                <iui-textbox v-model="state.searchData.projectName" placeholder="문서명" @keyup.enter="getList" />
              </i-col>
              -->
              <i-col-header>계약월</i-col-header>
              <i-col>
                <iui-datepicker v-model="state.searchData.contMonth" type="month" />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <iui-content-box no-border-box>
            <template #title>
              목록
            </template>
            <!-- <template #title-right v-if="otisPage">
            <iui-button :enable="state.canSend" @click="btnSend_click">송부</iui-button>
            <iui-button :enable="state.canRejectManage" @click="btnReject_click">반송/회수 관리</iui-button>
            <iui-button :enable="state.canRecovery" @click="btnRecovery_click">회수</iui-button>
          </template> -->

            <iui-container
              type="ibsheet"
              :loading="state.loading"
              :ibsheet.sync="state.grid.object"
              :ibsheet-options="state.grid.options"
              :ibsheet-data="state.list"
              @ibsheet-created="grid_created"
            />
          </iui-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </otis-page>
</template>

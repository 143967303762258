<template>
  <iui-container type="css-flex">
    <i-row height="250px">
      <pmis-content-box>
        <template v-slot:title>기본정보</template>
        <iui-container type="css-flex">
          <i-row>
            <i-col width="220px" style="border: 1px solid #ccc">
              <pmis-image-file
                :company-code="companyInfo.pgmCd"
                :file-number.sync="companyInfo.lgFlNo"
                :allowAdd="!isNew"
                :allowDelete="!isNew"
                :saveButtonVisible="!isNew"
                @save-complete="image_save_complete"
                @save-error="image_save_error"
                @delete-complete="image_delete_complete"
                @delete-error="image_delete_error"
              />
            </i-col>
            <i-spacer />
            <i-col>
              <iui-container type="table" theme="bullet" header-width="120px">
                <i-row>
                  <i-col-header width="120px" required>회사코드</i-col-header>
                  <i-col>
                    <iui-text
                      name="pgmCd"
                      type="code"
                      :value="companyInfo.pgmCd"
                      :enable="isNew"
                      max-length="6"
                      @change="setChangeCompanyInfo('pgmCd', $event)"
                    />
                  </i-col>
                  <i-col width="120px"></i-col>
                  <i-col></i-col>
                </i-row>
                <i-row>
                  <i-col-header required>회사명</i-col-header>
                  <i-col colspan="3">
                    <iui-text
                      name="frmNm"
                      :value="companyInfo.frmNm"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('frmNm', $event)"
                      max-length="50"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header rowspan="2" required>주소</i-col-header>
                  <i-col colspan="3">
                    <iui-searchbox
                      :type="'addr'"
                      :idValue="companyInfo.postNo"
                      :nameValue="companyInfo.adr1"
                      :callback="getAddr"
                      required
                      error-message="필수항목"
                    ></iui-searchbox>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col colspan="3">
                    <iui-text
                      name="adr2"
                      :value="companyInfo.adr2"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('adr2', $event)"
                      max-length="50"
                    ></iui-text>
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>사업자등록번호</i-col-header>
                  <i-col>
                    <iui-text
                      name="bsnNo"
                      :value="companyInfo.bsnNo"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('bsnNo', $event)"
                      max-length="10"
                    />
                  </i-col>
                  <i-col-header required>법인번호(주민번호)</i-col-header>
                  <i-col>
                    <iui-text
                      name="regiNo"
                      :value="companyInfo.regiNo"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('regiNo', $event)"
                      max-length="13"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>대표자명</i-col-header>
                  <i-col>
                    <iui-text
                      name="rpsvNm"
                      :value="companyInfo.rpsvNm"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('rpsvNm', $event)"
                      max-length="25"
                    />
                  </i-col>
                  <i-col-header required>대표전화번호</i-col-header>
                  <i-col>
                    <iui-text
                      name="rpsvPhone"
                      :value="companyInfo.rpsvPhone"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('rpsvPhone', $event)"
                      max-length="20"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header required>담당자명</i-col-header>
                  <i-col>
                    <iui-text
                      name="icprNm"
                      :value="companyInfo.icprNm"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('icprNm', $event)"
                      max-length="25"
                    />
                  </i-col>
                  <i-col-header required>담당자전화번호</i-col-header>
                  <i-col>
                    <iui-text
                      name="icprPhone"
                      :value="companyInfo.icprPhone"
                      required
                      error-message="필수항목"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('icprPhone', $event)"
                      max-length="20"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>담당자팩스번호</i-col-header>
                  <i-col>
                    <iui-text
                      name="icprFaxno"
                      :value="companyInfo.icprFaxno"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('icprFaxno', $event)"
                      max-length="20"
                    />
                  </i-col>
                  <i-col-header required>담당자이메일</i-col-header>
                  <i-col>
                    <iui-text
                      name="icprMail"
                      :value="companyInfo.icprMail"
                      :enable="!companyInfo.closeAt"
                      required
                      error-message="필수항목"
                      @change="setChangeCompanyInfo('icprMail', $event)"
                      max-length="50"
                    />
                  </i-col>
                </i-row>
                <i-row>
                  <i-col-header>업태</i-col-header>
                  <i-col>
                    <iui-text
                      name="bcoNm"
                      :value="companyInfo.bcoNm"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('bcoNm', $event)"
                      max-length="250"
                    />
                  </i-col>
                  <i-col-header>업종</i-col-header>
                  <i-col>
                    <iui-text
                      name="itemNm"
                      :value="companyInfo.itemNm"
                      :enable="!companyInfo.closeAt"
                      @change="setChangeCompanyInfo('itemNm', $event)"
                      max-length="25"
                    />
                  </i-col>
                </i-row>
              </iui-container>
            </i-col>
          </i-row>
        </iui-container>
      </pmis-content-box>
    </i-row>
    <i-spacer />
    <i-row height="220px">
      <pmis-content-box>
        <template v-slot:title>보유면허정보</template>
        <template v-slot:title-right>
          <iui-button class="btn" value="신규" @click="onAddLicense()" />
          <iui-button class="btn" value="저장" @click="onSaveLicense()" />
          <iui-button class="btn" value="삭제" @click="onDeleteLicense()" />
        </template>
        <iui-container type="ibsheet" :id="elId" />
      </pmis-content-box>
    </i-row>
    <i-spacer />
    <i-row height="110px">
      <pmis-content-box>
        <template v-slot:title>관리정보</template>
        <iui-container type="table" theme="bullet" header-width="230px">
          <i-row>
            <i-col-header required>외국인 체류자격에 의한 고용보험 산출</i-col-header>
            <i-col>
              <iui-radio-group-old
                name="femployInsurDs"
                :value="companyInfo.femployInsurDs"
                :items="femployInsurDsItems"
                :enable="!companyInfo.closeAt"
                @change="setChangeCompanyInfo('femployInsurDs', $event)"
              />
            </i-col>
            <i-col-header required>일 노무비 소수점 관리</i-col-header>
            <i-col>
              <iui-radio-group-old
                name="decimalDs"
                :value="companyInfo.decimalDs"
                :items="decimalDsItems"
                :enable="!companyInfo.closeAt"
                @change="setChangeCompanyInfo('decimalDs', $event)"
              />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header required>소득세 산출방식</i-col-header>
            <i-col>
              <iui-radio-group-old
                name="taxDs"
                :value="companyInfo.taxDs"
                :items="taxDsItems"
                :enable="!companyInfo.closeAt"
                @change="setChangeCompanyInfo('taxDs', $event)"
              />
            </i-col>
          </i-row>
        </iui-container>
      </pmis-content-box>
    </i-row>
    <i-row>
      <pmis-content-box>
        <template v-slot:title>시스템사용정보</template>
        <iui-container type="table" theme="bullet" header-width="120px">
          <i-row>
            <i-col-header required>계약일자</i-col-header>
            <i-col>
              <iui-datepicker-old
                name="useStrdate"
                :value="companyInfo.useStrdate"
                required
                error-message="필수항목"
                :enable="!companyInfo.closeAt"
                @change="setChangeCompanyInfo('useStrdate', $event)"
              />
            </i-col>
            <i-col-header>종료일자</i-col-header>
            <i-col>
              <iui-datepicker-old
                name="useEnddate"
                :value="companyInfo.useEnddate"
                :enable="companyInfo.closeAt"
                @change="setChangeCompanyInfo('useEnddate', $event)"
              />
            </i-col>
          </i-row>
        </iui-container>
      </pmis-content-box>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['pgmCd']);
  },
  created() {
    this.addEvent([{name: 'CompanyInfo_search', func: this.onSearch}]);
    this.addEvent([{name: 'CompanyInfo_add', func: this.onAdd}]);
    this.addEvent([{name: 'CompanyInfo_save', func: this.onSave}]);
    this.addEvent([{name: 'CompanyInfo_delete', func: this.onDelete}]);
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      radioFrmGb: [
        {label: '법인', value: '1'},
        {label: '개인', value: '2'},
      ],
      femployInsurDsItems: [
        {label: '아니오', value: ''},
        {label: '예', value: '000001'},
      ],
      decimalDsItems: [
        {label: '버림', value: '000001'},
        {label: '올림', value: '000002'},
      ],
      taxDsItems: [
        {label: '일단위', value: '000001'},
        {label: '월단위', value: '000002'},
      ],
      companyInfo: {
        pgmCd: '',
        frmNm: '',
        bsnNo: '',
        subWClass: '',
        frmGb: '',
        regiNo: '',
        rpsvNm: '',
        rpsvPhone: '',
        icprNm: '',
        icprPhone: '',
        icprMail: '',
        icprFaxno: '',
        postNo: '',
        adr1: '',
        adr2: '',
        bcoNm: '',
        itemNm: '',
        femployInsurDs: '',
        decimalDs: '',
        taxDs: '',
        useStrdate: '',
        useEnddate: '',
        lgFlNo: '',
        rgprId: '',
        rgsDttm: '',
        closeAt: '',
      },
      isNew: false,
      isDelete: false,
      focusKeyCode: 0,
    };
  },
  async mounted() {
    const me = this;
    const OPT = {
      Cfg: {
        SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
        CanEdit: 1, // 1: 전체 편집 가능 (default)
        ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
      },
      Cols: [
        {
          Header: '선택',
          Name: 'cbx',
          Type: 'Bool',
          BoolIcon: 0,
          Align: 'center',
          Width: 50,
        },
        {
          Header: '구분',
          Name: 'conSpDs',
          Type: 'Enum',
          Width: 80,
          EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', '0215'),
          Enum: await $getCodeIBSheetEnum(this, 'VALUE', '0215'),
        },
        {
          Header: '업종',
          Name: 'subWClass',
          Type: 'Enum',
          Width: 200,
          EnumKeys: await $getCodeIBSheetEnum(this, 'KEY', '0200'),
          Enum: await $getCodeIBSheetEnum(this, 'VALUE', '0200'),
        },
        {
          Header: '등록번호',
          Name: 'licenseNo',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '발급기관',
          Name: 'licenseOrg',
          Type: 'Text',
          RelWidth: 1,
        },
        {
          Header: '취득일자',
          Name: 'licenseDate',
          Extend: this.$ibsheetHelper.preset.date,
          CanEdit: true,
        },
        {
          Header: '갱신일자',
          Name: 'licenseChgDate',
          Extend: this.$ibsheetHelper.preset.date,
          CanEdit: true,
        },
        {Name: 'pgmCd', Visible: false},
        {Name: 'licenseSeq', Visible: false},
      ],
      Events: {
        onSearchFinish: me.sheet_searchFinish,
      },
    };

    me.sheet = IBSheet.create({
      id: me.sheetId,
      el: me.elId,
      options: OPT,
    });

    me.$ibsheetHelper.bindDefaultDeleteEvent(me.sheet); // 삭제 이벤트
  },
  methods: {
    initCompanyInfo() {
      for (let key in this.companyInfo) {
        this.companyInfo[key] = '';
      }
    },
    setCompanyInfo(data) {
      for (let key in this.companyInfo) {
        if (data && data.hasOwnProperty(key)) {
          this.companyInfo[key] = data[key];
        }
      }
    },
    getAddr(rtnValue) {
      let zipObject = {
        postNo: rtnValue.zipNo,
        adr1: `${rtnValue.roadAddrPart1} ${rtnValue.roadAddrPart2}`,
        adr2: rtnValue.addrDetail,
      };
      this.setCompanyInfo(zipObject);
    },
    setChangeCompanyInfo(key, e) {
      this.companyInfo[key] = key == 'useStrdate' || key == 'useEnddate' ? e : e.target.value;
    },
    async onSearch() {
      this.isNew = false;

      let response = await axios.post('/companyRegist/selectCompanyInfo', {pgmCd: this.pgmCd});
      this.setCompanyInfo(response.data);

      this.focusKeyCode = 0;
      this.onSearchLicenseList();
    },
    onSearchLicenseList() {
      axios
        .post('/companyRegist/selectCompanyLicenseList', {pgmCd: this.pgmCd})
        .then(response => this.sheet.loadSearchData(response.data));
    },
    onAdd() {
      this.isNew = true;
      this.initCompanyInfo();
      this.setCompanyInfo({frmGb: '000001', closeAt: false});
      this.sheet.removeAll();
    },
    async onSave() {
      let message = '저장하시겠습니까?';
      if (this.companyInfo.closeAt) {
        if (this.companyInfo.useEnddate == '') {
          message = '사용종료일 제거 시 종료해제가 됩니다.<br/>' + message;
        }
      }

      let validResponse = await this.$validator.validate(this);
      if (!validResponse.isValid) {
        this.$alert('입력되지 않은 필수항목이 존재합니다.<br/>다시 한 번 확인하시기 바랍니다.');
        return;
      }

      if (await this.$confirm(message)) {
        let response = await axios
          .post('/companyRegist/saveCompanyInfo', {...this.companyInfo, isNew: this.isNew})
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          let isDuplicate = response.data.isDuplicate;
          if (isDuplicate) {
            this.$alert('회사코드가 중복되었습니다.');
            return;
          } else {
            this.callEvent({
              name: 'CompanyList_setFocusKeyCode',
              param: {pgmCd: response.data.focusKeyCode, isDelete: false},
            });
          }
        } else {
          this.$alert('저장을 실패하였습니다.');
        }
      }
    },
    onDelete() {
      if (this.companyInfo.closeAt) {
        this.$alert('이미 사용종료된 회사입니다.');
        return;
      }

      this.companyInfo.closeAt = true;
      this.companyInfo.useEnddate = $_getCurrentDate();

      this.$confirm(
        `사용종료 하시겠습니까? [회사명 : ${this.companyInfo.frmNm}]`,
        () => {
          axios
            .post('/companyRegist/deleteCompanyInfo', this.companyInfo)
            .then(response => {
              if (response.status == 200) {
                this.callEvent({name: 'CompanyList_setFocusKeyCode', param: {pgmCd: response.data, isDelete: true}});
              } else {
                this.$alert('삭제를 실패하였습니다.');
              }
            })
            .catch(ex => console.log('저장을 실패하였습니다. : ' + ex));
        },
        () => {
          this.companyInfo.closeAt = false;
          this.companyInfo.useEnddate = '';
        }
      );
    },
    onAddLicense() {
      if (this.isNew) {
        this.$alert('회사 기본정보 먼저 저장한 후 입력할 수 있습니다.');
        return;
      }
      let addRow = this.sheet.addRow();
      this.sheet.setValue(addRow, 'pgmCd', this.companyInfo.pgmCd, 1);
    },
    onSaveLicense() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert('저장할 보유면허정보가 존재하지 않습니다.');
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }
      axios
        .post('/companyRegist/saveLicenseList', {licenseList: this.sheet.getSaveJson().data})
        .then(response => {
          if (response.status == 200) {
            this.onSearchLicenseList(this.companyInfo.pgmCd);
            this.focusKeyCode = response.data;
          } else {
            this.$alert('저장을 실패하였습니다.');
          }
        })
        .catch(ex => console.log('저장을 실패하였습니다. : ', ex));
    },
    onDeleteLicense() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');
      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      checkedRows.forEach(row => this.sheet.deleteRow(row));
    },
    sheet_searchFinish() {
      if (0 < this.focusKeyCode) {
        let focusRow = {};
        for (let row of this.sheet.getDataRows()) {
          if (row.licenseSeq == this.focusKeyCode) {
            focusRow = row;
            break;
          }
          setTimeout(() => this.sheet.focus(focusRow), 100);
        }
      }
    },
    image_save_complete() {
      // this.$alert('파일이 저장되었습니다.');
      this.onSaveCompanyLogoFileNo('update');
    },
    image_save_error() {
      this.$alert('파일 저장을 실패했습니다.');
    },
    image_delete_complete() {
      // this.$alert('파일을 삭제했습니다.');
      this.onSaveCompanyLogoFileNo('delete');
    },
    image_delete_error() {
      this.$alert('파일 삭제를 실패했습니다.');
    },
    async onSaveCompanyLogoFileNo(type) {
      let errorMessage = type == 'update' ? '파일 저장을 실패했습니다.' : '파일 삭제를 실패했습니다.';
      let pgmCd = this.companyInfo.pgmCd;
      let lgFlNo = type == 'delete' ? 0 : this.companyInfo.lgFlNo;

      let response = await axios
        .post('/companyRegist/saveCompanyLogoFileNo', {pgmCd, lgFlNo})
        .catch(e => console.error(errorMessage, e));

      if (response.status == 200) {
        this.callEvent({name: 'CompanyList_setFocusKeyCode', param: {pgmCd: response.data}});
      } else {
        this.$alert(errorMessage);
      }
    },
  },
};
</script>

<style scoped></style>

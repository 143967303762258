<template>
  <pmis-page
    :search-project-visible="false"
    :search-company-visible="true"
    :search-company-code="userInfo.pgmCd"
    :search-company-name="userInfo.frmNm"
    @print-click="onPrint"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col width="390px">
          <pmis-tab-box>
            <template v-slot:title>사원목록</template>
            <WorkerList @emitWorkerInfo="onEmitWorkerInfo" />
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box v-if="userInfo.frmGb != '000001'">
            <template v-slot:title>사원정보</template>
            <WorkerInfo />
          </pmis-tab-box>
          <iui-tab-old v-else :comps="tabComps" :keepAlive="true" :isAuto="false" @click="setTabIndex"></iui-tab-old>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from './store/worker.js';
import WorkerInfo from './components/WorkerInfo.vue';
import WorkerList from './components/WorkerList.vue';
export default {
  components: {WorkerList, WorkerInfo},
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['detailInfo', 'status', 'dupEmpNoStatus', 'fileFlag', 'imageSave']);
    $mapMutations(this, [
      'setKeyInfo',
      'setStatus',
      'initDetailInfo',
      'setDetailInfo',
      'setFocusKeyCode',
      'initFocusKeyCode',
      'setSearchInfo',
      'initFileFlag',
      'setImageSave',
    ]);
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
    this.setSearchInfo({pgmCd: this.userInfo.pgmCd, frmNm: this.userInfo.frmNm});
  },
  data: function() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        {name: '신규', class: 'b', callback: this.onAdd},
        {name: '저장', class: 'b', callback: this.onSave},
        {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      frmNm: JSON.parse(sessionStorage.getItem('userInfo')).frmNm,
      tabComps: [
        {tabNm: '사원정보', compNm: 'WorkerInfo', path: '@/view/user/Worker/components/WorkerInfo.vue'},
        {tabNm: '관리정보', compNm: 'ManageInfo', path: '@/view/user/Worker/components/ManageInfo.vue'},
      ],
      tabIndex: 0,
      inputPrjSaveList: {},
    };
  },

  watch: {
    fileFlag: {
      handler(val) {
        let fileComplete = true;
        for (let key in val) {
          if (val[key]) {
            fileComplete = false;
          }
        }
        if (fileComplete) {
          this.onSaveProc();
          this.initFileFlag();
        }
      },
      deep: true,
    },
  },

  methods: {
    setTabIndex(index) {
      this.tabIndex = index;

      if (this.tabIndex == 1 && this.status.isNew) {
        this.$alert('신규 입력인 경우 사원 정보를 먼저 입력해야합니다.');
        return;
      } else {
        this.callEvent({name: 'setActiveTab', param: this.tabIndex});
        this.onSearch();
      }
    },
    onEmitWorkerInfo(row) {
      this.setKeyInfo(row);
      this.initFocusKeyCode();
      this.setStatus({isNew: false});
      this.onSearch();
    },
    onSearch() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'WorkerInfo_search'});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Manage_search'});
      }
    },
    onReSearch() {
      this.callEvent({name: 'selectWorkerList'});
    },
    onAdd() {
      this.setStatus({isNew: true, isChangedLaborDs: false, isEnableLaborDs: true});
      if (this.tabIndex == 0) {
        this.initDetailInfo();
        this.callEvent({name: 'InputPjt_initSheet'});
        this.setDetailInfo({pgmCd: this.pgmCd, cud: 1});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Manage_add'});
      }
    },
    async onSave() {
      if (this.tabIndex == 0) {
        if (this.detailInfo.cud == 1 && this.dupEmpNoStatus != 1) {
          this.$alert('중복확인을 해주세요.');
          return;
        }

        let data = await this.$validator.validate(this);
        if (!data.isValid) {
          this.$alert('입력되지 않은 필수항목이 존재합니다.<br/>다시 한 번 확인하시기 바랍니다.');
        }

        this.callEvent({name: 'inputPrj_getSaveJson', param: data => (this.inputPrjSaveList = data)});
        // if (this.detailInfo.pwd != this.detailInfo.pwd2) {
        //   this.$alert('비밀번호가 일치하지 않습니다.');
        //   return;
        // }

        if (await this.$confirm('저장하시겠습니까?')) {
          this.setImageSave(true);
        }
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Manage_save'});
      }
    },
    async onSaveProc() {
      this.setImageSave(false);
      let response = await axios
        .post('/worker/saveWorkerInfo', {...this.detailInfo, inputPrjSaveList: this.inputPrjSaveList})
        .catch(e => console.error('저장을 실패하였습니다. : ', e));

      if (response.status == 200) {
        this.setFocusKeyCode(response.data);
        this.onReSearch();
      } else {
        this.$alert('저장을 실패하였습니다.');
      }
    },
    onDelete() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'WorkerList_delete'});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Manage_delete'});
      }
    },
    onPrint() {
      alert(this.detailInfo.blnDepNm);
    },
  },
};
</script>

<style scoped></style>

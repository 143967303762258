<script>
import {defineComponent} from '@vue/composition-api';
import Viewer from './components/Viewer.vue';

export default defineComponent({
  components: {
    Viewer,
  },
  setup() {},
});
</script>

<template>
  <div style="box-sizing: border-box; width: 100%; height: 100%; padding: 10px;">
    <viewer />
  </div>
</template>

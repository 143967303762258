<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>인지세</template>
          <template #title-left>
            (전체 납부해야할 인지세 금액)&nbsp;
            <iui-button @click="onControlButton('stamptaxGuideModal')">인지세안내</iui-button>
          </template>
          <template #title-right>
            OTIS인지세는 CR팀에서 일괄 납부합니다. 고객 및 OSEP 인지세 발급 여부 확인 필요
            <iui-button @click="onControlButton(stamptaxModalId)" v-if="isShowButton">인지세 등록</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId1" style="width:99.9%" />
          <iui-modal-old
            name="stamptaxGuideModal"
            :title="'인지세안내'"
            :btns="modalBtns1"
            width="600px"
            height="430px"
          >
            <StamptaxGuide />
          </iui-modal-old>
          <iui-modal-old :name="stamptaxModalId" :title="'인지세정보'" :btns="modalBtns2" width="800px" height="300px">
            <Stamptax />
          </iui-modal-old>
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>보증보험</template>
          <template v-slot:title-left>OTIS담당자는 증권첨부는 OKSS 에서 진행해주시기 바랍니다.</template>
          <template #title-right>
            <iui-button @click="onControlButton(guaranteeModalId)" v-if="!isOfflineComplete && isShowButton"
              >보증보험 등록</iui-button
            >
          </template>
          <iui-container type="jqgrid" :id="sheetId2" style="width:99.9%" />
          <iui-modal-old :name="guaranteeModalId" :title="'보증정보'" :btns="modalBtns3" width="800px" height="350px">
            <Guarantee />
          </iui-modal-old>
        </pmis-content-box>
      </i-col>
    </i-row>
    <!-- <i-row>
			<i-col>
				<pmis-content-box :loading="listBoxLoading">
          <template v-slot:title>부속서류</template>
          <template v-slot:title-left>종류를 정확하게 선택해주세요.</template>
          <template #title-right>
            <iui-button @click="onControlButton(etcDocModalId)" v-if="!isOfflineComplete">부속서류 등록</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId3" style="width:99.9%"/>
          <iui-modal-old :name="etcDocModalId" :title="'부속서류'" :btns="modalBtns4" width="800px" height="350px">
            <EtcDoc />
          </iui-modal-old>
				</pmis-content-box>
			</i-col>
		</i-row> -->
  </iui-container>
</template>

<script>
import StamptaxGuide from '../popup/StamptaxGuide.vue';
import Stamptax from '../popup/Stamptax.vue';
import Guarantee from '../popup/Guarantee.vue';
// import EtcDoc from '../popup/EtcDoc.vue';
export default {
  components: {
    StamptaxGuide,
    Stamptax,
    Guarantee,
    // EtcDoc,
  },
  beforeCreate() {
    $mapGetters(this, [
      'contractInfo',
      'stamptaxList',
      'guaranteeList',
      'etcDocList',
      'isProgressPage',
      'isCompletePage',
      'isOfflineComplete',
      'modalStamptax',
      'modalGuarantee',
      'modalEtcDoc',
      'isContEmp',
    ]);
    $mapMutations(this, ['setModalStamptax', 'setModalGuarantee', 'setModalEtcDoc']);
  },
  created() {
    this.addEvent({name: 'ContractTabEtcDocuments_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      sheetId2: 'sheet2' + this._uid,
      sheetId3: 'sheet3' + this._uid,

      stamptaxModalId: 'stamptaxModal' + this._uid,
      guaranteeModalId: 'guaranteeModal' + this._uid,
      etcDocModalId: 'etcDocModal' + this._uid,

      listBoxLoading: false,
      modalNm: '',
      modalBtns1: [],
      modalBtns2: [
        {name: '저장', callback: this.onPopupStamptaxSave},
        {name: '삭제', callback: this.onPopupStamptaxDelete},
      ],
      modalBtns3: [
        {name: '저장', callback: this.onPopupGuaranteeSave},
        {name: '삭제', callback: this.onPopupGuaranteeDelete},
      ],
      modalBtns4: [
        {name: '저장', callback: this.onPopupEtcDocSave},
        {name: '삭제', callback: this.onPopupEtcDocDelete},
      ],

      stamptax: {
        stmptaxSn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        saveMode: '',
        readonly: false,
      },
      guarantee: {
        grntySn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        saveMode: '',
        readonly: false,
      },
      etcDoc: {
        etcdocsn: 0,
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        saveMode: '',
        readonly: false,
      },
    };
  },
  mounted() {
    let attachFileImagePath = require(`@/assets/img/old/icon_zip.gif`);
    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 60,
      width: 800,
      colModel: [
        {name: 'stmptaxSn', label: '인지세순번', width: 0, hidden: true},
        {name: 'ctrtNo', label: '계약번호', width: 0, hidden: true},
        {name: 'ctrtOdr', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, hidden: true},
        {name: 'entryEmpId', label: '담당자ID', width: 0, hidden: true},
        {name: 'rpaYn', label: 'RPA여부', width: 0, hidden: true},
        {name: 'insttNm', label: '기관', width: 100, align: 'left'},
        {
          name: 'stmptaxPayDe',
          label: '납부일자',
          width: 50,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {
          name: 'stmptaxAm',
          label: '인지세액',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {name: 'rceptNo', label: '접수번호', width: 100, align: 'left'},
        {name: 'businessNo', label: '사업자번호', width: 50, align: 'center'},
        {
          name: 'atchFile',
          label: '첨부파일',
          width: 50,
          align: 'center',
          formatter: function(val) {
            return val > 0 ? "<img src='" + attachFileImagePath + "' />" : '';
          },
        },
        {name: 'fileNo', label: '첨부파일', width: 0, hidden: true},
        {name: 'pgmCd', label: 'pgmCd', width: 0, hidden: true},
        {name: 'grpNo', label: 'grpNo', width: 0, hidden: true},
        {name: 'lclFlNm', label: 'lclFlNm', width: 0, hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId1).jqGrid('getRowData', rowId);

        if (rowData.rpaYn == 'N') {
          if (iCol == $('#' + me.sheetId1).jqGrid('getGridParam').iColByName['atchFile']) {
            if (rowData.fileNo !== undefined && rowData.fileNo > 0 && rowData.lclFlNm != '') {
              me.onFileDownload(rowData.pgmCd, rowData.fileNo, rowData.grpNo, rowData.lclFlNm);
            }
          } else {
            me.onStamptaxView(rowData);
          }
        } else {
          me.$alert('RPA로 발급받은 인지세정보는 상세정보를 조회할 수 없습니다.');
        }
      },
    });

    $('#' + me.sheetId2).jqGrid({
      height: 60,
      width: 800,
      colModel: [
        {name: 'grntySn', label: '보증순번', width: 0, hidden: true},
        {name: 'checkContNo', label: '계약번호', width: 0, hidden: true},
        {name: 'checkContSeq', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, hidden: true},
        {name: 'entryEmpId', label: '담당자ID', width: 0, hidden: true},
        {name: 'grntyKndCd', label: '종류', width: 50, hidden: true},
        {name: 'scdYn', label: 'scdYn', width: 50, hidden: true},
        {name: 'grntyKndNm', label: '종류', width: 50, align: 'center'},
        {name: 'issuInsttNm', label: '보증사', width: 100, align: 'left'},
        {
          name: 'grntyBgnDe',
          label: '보증시작일',
          width: 50,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {
          name: 'grntyEndDe',
          label: '보증종료일',
          width: 50,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {name: 'grntyNo', label: '증권번호', width: 100, align: 'left'},
        {
          name: 'issuDe',
          label: '발행일자',
          width: 50,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        },
        {
          name: 'grntyAm',
          label: '보증금액',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'atchFile',
          label: '첨부파일',
          width: 50,
          align: 'center',
          formatter: function(val) {
            return val > 0 ? "<img src='" + attachFileImagePath + "' />" : '';
          },
        },
        {name: 'fileNo', label: '첨부파일', width: 0, hidden: true},
        {name: 'pgmCd', label: 'pgmCd', width: 0, hidden: true},
        {name: 'grpNo', label: 'grpNo', width: 0, hidden: true},
        {name: 'lclFlNm', label: 'lclFlNm', width: 0, hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId2).jqGrid('getRowData', rowId);

        if (rowData.scdYn == 'Y') {
          if (iCol == $('#' + me.sheetId2).jqGrid('getGridParam').iColByName['atchFile']) {
            if (rowData.fileNo !== undefined && rowData.fileNo > 0 && rowData.lclFlNm != '') {
              me.onFileDownload(rowData.pgmCd, rowData.fileNo, rowData.grpNo, rowData.lclFlNm);
            }
          } else {
            me.onGuaranteeView(rowData);
          }
        } else {
          me.$alert('OKSS에서 입력한 보증서 정보는 상세정보를 조회할 수 없습니다.');
        }
      },
    });

    $('#' + me.sheetId3).jqGrid({
      height: 60,
      width: 800,
      colModel: [
        {name: 'etcdocsn', label: '기타문서순번', width: 0, hidden: true},
        {name: 'checkContNo', label: '계약번호', width: 0, hidden: true},
        {name: 'checkContSeq', label: '계약차수', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: '계약구분코드', width: 0, hidden: true},
        {name: 'entryEmpId', label: '담당자ID', width: 0, hidden: true},
        {name: 'dockndcd', label: '종류', width: 50, hidden: true},
        {name: 'dockndNm', label: '종류', width: 50, align: 'center'},
        {name: 'docrmcn', label: '첨부 부연설명', width: 100, align: 'left'},
        {
          name: 'atchFile',
          label: '첨부파일',
          width: 50,
          align: 'center',
          formatter: function(val) {
            return val > 0 ? "<img src='" + attachFileImagePath + "' />" : '';
          },
        },
        {name: 'fileNo', label: '첨부파일', width: 0, hidden: true},
        {name: 'pgmCd', label: 'pgmCd', width: 0, hidden: true},
        {name: 'grpNo', label: 'grpNo', width: 0, hidden: true},
        {name: 'lclFlNm', label: 'lclFlNm', width: 0, hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId3).jqGrid('getRowData', rowId);

        if (iCol == $('#' + me.sheetId3).jqGrid('getGridParam').iColByName['atchFile']) {
          if (rowData.fileNo !== undefined && rowData.fileNo > 0 && rowData.lclFlNm != '') {
            me.onFileDownload(rowData.pgmCd, rowData.fileNo, rowData.grpNo, rowData.lclFlNm);
          }
        } else {
          me.onEtcDocView(rowData);
        }
      },
    });

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      $grid = $('#' + this.sheetId2);
      pobj = $grid.closest('.ui-jqgrid').parent();
      newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      $grid = $('#' + this.sheetId3);
      pobj = $grid.closest('.ui-jqgrid').parent();
      newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);
    },
    onLoad() {
      console.log('ContractTabEtcDocuments_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      this.stamptax.stmptaxSn = 0;
      this.stamptax.ctrtNo = '';
      this.stamptax.ctrtOdr = '';
      this.stamptax.ctrtDivCd = '';
      this.stamptax.saveMode = '';
      this.stamptax.readonly = false;

      this.guarantee.grntySn = 0;
      this.guarantee.ctrtNo = '';
      this.guarantee.ctrtOdr = '';
      this.guarantee.ctrtDivCd = '';
      this.guarantee.saveMode = '';
      this.guarantee.readonly = false;

      this.etcDoc.etcdocsn = 0;
      this.etcDoc.ctrtNo = '';
      this.etcDoc.ctrtOdr = '';
      this.etcDoc.ctrtDivCd = '';
      this.etcDoc.saveMode = '';
      this.etcDoc.readonly = false;

      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.stamptaxList})
        .trigger('reloadGrid');

      $('#' + this.sheetId2)
        .clearGridData(true)
        .setGridParam({data: this.guaranteeList})
        .trigger('reloadGrid');

      $('#' + this.sheetId3)
        .clearGridData(true)
        .setGridParam({data: this.etcDocList})
        .trigger('reloadGrid');
    },
    async onControlButton(type) {
      const me = this;
      if (type == 'stamptaxGuideModal') {
        me.onOpenPopup('stamptaxGuideModal');
      } else if (type == this.stamptaxModalId) {
        me.stamptax.stmptaxSn = 0;
        me.stamptax.ctrtNo = me.contractInfo.ctrtNo;
        me.stamptax.ctrtOdr = me.contractInfo.ctrtOdr;
        me.stamptax.ctrtDivCd = me.contractInfo.ctrtDivCd;
        me.stamptax.saveMode = 'I';
        me.stamptax.readonly = false;

        let paramStamptax = {};
        paramStamptax.stmptaxSn = 0;
        paramStamptax.ctrtNo = me.contractInfo.ctrtNo;
        paramStamptax.ctrtOdr = me.contractInfo.ctrtOdr;
        paramStamptax.ctrtDivCd = me.contractInfo.ctrtDivCd;
        paramStamptax.saveMode = 'I';
        paramStamptax.readonly = false;
        this.setModalStamptax(paramStamptax);

        me.onOpenPopup(this.stamptaxModalId);
      } else if (type == this.guaranteeModalId) {
        me.guarantee.grntySn = 0;
        me.guarantee.ctrtNo = me.contractInfo.ctrtNo;
        me.guarantee.ctrtOdr = me.contractInfo.ctrtOdr;
        me.guarantee.ctrtDivCd = me.contractInfo.ctrtDivCd;
        me.guarantee.saveMode = 'I';
        me.guarantee.readonly = false;

        let paramGuarantee = {};
        paramGuarantee.grntySn = 0;
        paramGuarantee.ctrtNo = me.contractInfo.ctrtNo;
        paramGuarantee.ctrtOdr = me.contractInfo.ctrtOdr;
        paramGuarantee.ctrtDivCd = me.contractInfo.ctrtDivCd;
        paramGuarantee.saveMode = 'I';
        paramGuarantee.readonly = false;
        this.setModalGuarantee(paramGuarantee);

        me.onOpenPopup(this.guaranteeModalId);
      } else if (type == this.etcDocModalId) {
        me.etcDoc.etcdocsn = 0;
        me.etcDoc.ctrtNo = me.contractInfo.ctrtNo;
        me.etcDoc.ctrtOdr = me.contractInfo.ctrtOdr;
        me.etcDoc.ctrtDivCd = me.contractInfo.ctrtDivCd;
        me.etcDoc.saveMode = 'I';
        me.etcDoc.readonly = false;

        let paramModalEtcDoc = {};
        paramModalEtcDoc.etcdocsn = 0;
        paramModalEtcDoc.ctrtNo = me.contractInfo.ctrtNo;
        paramModalEtcDoc.ctrtOdr = me.contractInfo.ctrtOdr;
        paramModalEtcDoc.ctrtDivCd = me.contractInfo.ctrtDivCd;
        paramModalEtcDoc.saveMode = 'I';
        paramModalEtcDoc.readonly = false;
        this.setModalEtcDoc(paramModalEtcDoc);

        me.onOpenPopup(this.etcDocModalId);
      }
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onPopupConfirm() {
      if (this.modalNm == 'stamptaxGuideModal') {
        this.$modal.hide(this.modalNm);
        console.log('stamptaxGuideModal');
      }
    },
    onPopupStamptaxSave() {
      this.callEvent({
        name: 'Stamptax_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    onPopupStamptaxDelete() {
      this.callEvent({
        name: 'Stamptax_Delete',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    onPopupGuaranteeSave() {
      this.callEvent({
        name: 'Guarantee_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    onPopupGuaranteeDelete() {
      this.callEvent({
        name: 'Guarantee_Delete',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    onPopupEtcDocSave() {
      this.callEvent({
        name: 'EtcDoc_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    onPopupEtcDocDelete() {
      this.callEvent({
        name: 'EtcDoc_Delete',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.callEvent({name: 'Contract_SearchDetail'});
        },
      });
    },
    async onFileDownload(pgmCd, fileNo, grpNo, lclFlNm) {
      var url = '/file/download?pgmCd=' + pgmCd + '&flNo=' + fileNo + '&grpNo=' + grpNo;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, lclFlNm);
        } else {
          link.setAttribute('download', lclFlNm);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('다운로드 받을 파일이 존재하지 않습니다.');
      }
    },
    onStamptaxView(rowData) {
      this.stamptax.stmptaxSn = rowData.stmptaxSn;
      this.stamptax.ctrtNo = rowData.ctrtNo;
      this.stamptax.ctrtOdr = rowData.ctrtOdr;
      this.stamptax.ctrtDivCd = rowData.ctrtDivCd;
      this.stamptax.saveMode = 'U';
      this.stamptax.readonly = this.userInfo.empNo != rowData.entryEmpId;

      let paramStamptax = {};
      paramStamptax.stmptaxSn = rowData.stmptaxSn;
      paramStamptax.ctrtNo = rowData.ctrtNo;
      paramStamptax.ctrtOdr = rowData.ctrtOdr;
      paramStamptax.ctrtDivCd = rowData.ctrtDivCd;
      paramStamptax.saveMode = 'U';
      paramStamptax.readonly = this.userInfo.empNo != rowData.entryEmpId;
      this.setModalStamptax(paramStamptax);

      this.onOpenPopup(this.stamptaxModalId);
    },
    onGuaranteeView(rowData) {
      this.guarantee.grntySn = rowData.grntySn;
      this.guarantee.ctrtNo = rowData.ctrtNo;
      this.guarantee.ctrtOdr = rowData.ctrtOdr;
      this.guarantee.ctrtDivCd = rowData.ctrtDivCd;
      this.guarantee.saveMode = 'U';
      this.guarantee.readonly = this.userInfo.empNo != rowData.entryEmpId;

      let paramGuarantee = {};
      paramGuarantee.grntySn = rowData.grntySn;
      paramGuarantee.ctrtNo = rowData.ctrtNo;
      paramGuarantee.ctrtOdr = rowData.ctrtOdr;
      paramGuarantee.ctrtDivCd = rowData.ctrtDivCd;
      paramGuarantee.saveMode = 'U';
      paramGuarantee.readonly = this.userInfo.empNo != rowData.entryEmpId;
      this.setModalGuarantee(paramGuarantee);

      this.onOpenPopup(this.guaranteeModalId);
    },
    onEtcDocView(rowData) {
      this.etcDoc.etcdocsn = rowData.etcdocsn;
      this.etcDoc.ctrtNo = rowData.ctrtNo;
      this.etcDoc.ctrtOdr = rowData.ctrtOdr;
      this.etcDoc.ctrtDivCd = rowData.ctrtDivCd;
      this.etcDoc.saveMode = 'U';
      this.etcDoc.readonly = this.userInfo.empNo != rowData.entryEmpId;

      let paramModalEtcDoc = {};
      paramModalEtcDoc.etcdocsn = rowData.etcdocsn;
      paramModalEtcDoc.ctrtNo = rowData.ctrtNo;
      paramModalEtcDoc.ctrtOdr = rowData.ctrtOdr;
      paramModalEtcDoc.ctrtDivCd = rowData.ctrtDivCd;
      paramModalEtcDoc.saveMode = 'U';
      paramModalEtcDoc.readonly = this.userInfo.empNo != rowData.entryEmpId;
      this.setModalGuarantee(paramModalEtcDoc);

      this.onOpenPopup(this.etcDocModalId);
    },
  },
  computed: {
    isShowButton() {
      return (this.userInfo.userType == 'U' && ($isAdmin() || this.isContEmp)) || this.userInfo.userType != 'U';
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

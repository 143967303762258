<script>
import {defineComponent, reactive, nextTick, computed, watch, onMounted} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {PrefixProps, usePrefix} from '@/components/Iui/composables/usePrefix';
import {SuffixProps, useSuffix} from '@/components/Iui/composables/useSuffix';

export default defineComponent({
  name: 'IuiRadio',
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...PrefixProps,
    ...SuffixProps,

    modelValue: {
      // type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      type: undefined,
      default: undefined,
      required: true,
    },
    groupName: {
      type: String,
      default: undefined,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: 'on',
    },
  },
  setup(props, context) {
    const Base = useBase(props, context);
    const Layout = useLayout(props, context, 'iui-radio');
    const Label = useLabel(props, context, Base);
    const Prefix = usePrefix(props, context, Base);
    const Suffix = useSuffix(props, context, Base);

    const radioId = computed(() => Base.getId('radio'));
    const radioClass = computed(() => {
      return {
        radio: true,
      };
    });
    const radioStyle = computed(() => {
      return {};
    });

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue !== oldValue && state.modelValue !== newValue) {
          state.modelValue = newValue;

          const newChecked = state.modelValue === props.value;

          if (state.checked !== newChecked) {
            // console.log(Base.state.$iuiId, 'watch -> modelValue', 'emit -> update:checked', newChecked);

            state.checked = newChecked;
            context.emit('update:checked', newChecked);
          }
        }
      }
    );

    watch(
      () => props.checked,
      (newValue, oldValue) => {
        if (newValue !== oldValue && state.checked !== newValue) {
          state.checked = newValue;

          if (state.checked && state.modelValue !== props.value) {
            // console.log(Base.state.$iuiId, 'watch -> checked', 'emit -> update:model-value', props.value);

            state.modelValue = props.value;
            context.emit('update:model-value', props.value);
          } else if (newValue === false && oldValue === true) {
            // console.log(Base.state.$iuiId, 'watch -> checked', 'emit -> update:model-value', undefined);

            state.modelValue = undefined;
            context.emit('update:model-value', undefined);
          }
        }
      }
    );

    onMounted(() => {
      if (state.checked !== (state.modelValue === props.value)) {
        state.checked = state.modelValue === props.value;
        context.emit('update:checked', state.checked);
      }
    });

    const state = reactive({
      modelValue: props.modelValue,
      groupName: props.groupName === undefined ? Base.state.id : props.groupName,
      checked: props.checked,
    });

    // method
    const method = {
      async radio_change(e) {
        state.checked = e.target.checked;
        context.emit('update:checked', e.target.checked);
        if (state.checked) {
          state.modelValue = props.value;
          context.emit('update:model-value', props.value);
        }
        // 같은 group-name 의 radio 의 update:* 이벤트가 모두 emit된 뒤 change 이벤트를 emit하기 위해 nextTick 이후 처리
        await nextTick();
        context.emit('change', e);
      },
    };

    return {
      Base,
      Layout,
      Label,
      Prefix,
      Suffix,
      radioId,
      radioClass,
      radioStyle,
      state,
      ...method,
    };
  },
  // data() {
  //   return {
  //     internalModelValue: undefined,
  //     internalGroupName: undefined,
  //     internalChecked: false,
  //   };
  // },
  // watch: {
  //   modelValue(newValue, oldValue) {
  //     if (newValue !== oldValue && this.internalModelValue !== newValue) {
  //       // console.log(
  //       //   this.$el,
  //       //   this.$iuiId,
  //       //   'watch -> modelValue',
  //       //   'internalModelValue:',
  //       //   this.internalModelValue,
  //       //   'oldValue -> newValue:',
  //       //   oldValue,
  //       //   newValue
  //       // );
  //       this.internalModelValue = newValue;
  //       const newChecked = this.internalModelValue === this.value;
  //       if (this.internalChecked !== newChecked) {
  //         // console.log(this.$el, this.$iuiId, 'watch -> modelValue', 'emit -> update:checked', newChecked);
  //         this.internalChecked = newChecked;
  //         this.$emit('update:checked', newChecked);
  //       }
  //     }
  //   },
  //   checked(newValue, oldValue) {
  //     if (newValue !== oldValue && this.internalChecked !== newValue) {
  //       // console.log(
  //       //   this.$el,
  //       //   this.$iuiId,
  //       //   'watch -> checked',
  //       //   'internalChecked:',
  //       //   this.internalChecked,
  //       //   'oldValue -> newValue:',
  //       //   oldValue,
  //       //   newValue
  //       // );
  //       this.internalChecked = newValue;
  //       if (this.internalChecked && this.internalModelValue !== this.value) {
  //         // console.log(this.$el, this.$iuiId, 'watch -> checked', 'emit -> update:modelValue', this.value);
  //         this.internalModelValue = this.value;
  //         this.$emit('update:model-value', this.value);
  //       } else if (newValue === false && oldValue === true) {
  //         // console.log(this.$el, this.$iuiId, 'watch -> checked', 'emit -> update:modelValue', undefined);
  //         this.internalModelValue = undefined;
  //         this.$emit('update:model-value', undefined);
  //       }
  //     }
  //   },
  // },
  // created() {
  //   this.internalGroupName = this.groupName || this.$iuiId;
  //   this.internalChecked = this.checked || this.internalChecked;
  // },
  // mounted() {
  //   // TODO: 브라우저 호환성 확인
  //   // this.$refs.radio.attributes['data-iui-radio'] = this;
  //   Object.defineProperty(this.$refs.radio, 'IuiRadio', {
  //     value: this,
  //   });

  //   // internalChecked가 true이고 internalModelValue가 value props와 다를 경우
  //   if (this.internalChecked && this.internalModelValue !== this.value) {
  //     // console.log(this.$el, this.$iuiId, 'mounted emit -> update:modelValue', this.value);

  //     this.internalModelValue = this.value;
  //     this.$emit('update:model-value', this.value);
  //   }
  // },
  // methods: {
  //   async radio_change(e) {
  //     // console.log(this.$el, this.$iuiId, 'radio_change emit -> update:checked', e.target.checked);

  //     this.internalChecked = e.target.checked;
  //     this.$emit('update:checked', e.target.checked);

  //     if (this.internalChecked) {
  //       this.internalModelValue = this.value;
  //       this.$emit('update:model-value', this.value);
  //     }

  //     // 같은 group-name 의 radio 의 update:* 이벤트가 모두 emit된 뒤 change 이벤트를 emit하기 위해 nextTick 이후 처리
  //     await this.$nextTick();

  //     this.$emit('change', e);
  //   },
  // },
});
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style">
    <label
      v-if="Prefix.state.has"
      :id="Prefix.state.id"
      ref="prefix"
      :for="radioId"
      class="prefix"
      :style="Prefix.state.style"
    >
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </label>
    <input
      :id="radioId"
      ref="radio"
      :name="state.groupName"
      type="radio"
      :class="radioClass"
      :style="radioStyle"
      :readonly="Base.state.readonly"
      :disabled="!Base.state.enable"
      :checked="state.checked"
      :value="value"
      @change="radio_change"
    />
    <label
      v-if="Suffix.state.has"
      :id="Suffix.state.id"
      ref="suffix"
      :for="radioId"
      class="suffix"
      :style="Suffix.state.style"
    >
      <slot name="suffix">
        {{ suffix }}
      </slot>
    </label>
    <label
      v-if="Label.state.has"
      :id="Label.state.id"
      ref="label"
      :for="radioId"
      class="label"
      :style="Label.state.style"
    >
      <span></span>
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<style></style>

import {numToKr} from 'vue-number-to-kor';

const state = () => ({
    searchInfo: {
      pgmCd: '',
      ctrtNo: '',
    },
    contractList: [],
    contractInfo: {
      checkContNo: '',
      checkSeq: '',
      tradeName1: '',
      mskTradeName1: '',
      checkContDate: '',
      checkContStartDate: '',
      checkContFinDate: '',
      checkContTypeA3: '',
      checkContOpts: '',
      supplyTotalAmt: 0,
      supplyTotalAmtKor: '',
      taxTotalAmt: 0,
      taxTotalAmtKor: '',
      sumTotalAmt: 0,
      sumTotalAmtKor: '',
      ckeckContTotalAmt: 0,
      ckeckContTotalAmtKor: '',
      maintPlanAmt: 0,
      maintPlanAmtKor: '',
      mainName: '',
      checkAddr12: '',
      suFlagA7: '',
      suFlagA7Yn: '',
      giroAddress: '',
      email: '',
      mphone: '',
      eserviceFlag: '',
      mbaMonterm: '',
      payMonthlyYn: '',
      payPrepayYn: '',
      payQuarterYn: '',
      payHalfyearYn: '',
      payYearlyYn: '',
      billPrtDay: '',
      amtAdjustFlagDesc: '',
      mediCourt974: '',
      checkCntAz: '',
      contractType: '',
      contEmpId: '',
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      ctrtSttusCd: '',
      rtrvlResnCn: '',
      rtrvlDt: '',
      rtrvlDtStr: '',
      beforeCheckContStartDate: '',
      beforeCheckContFinDate: '',
      frstSendngDt: '',
      scdSendngDt: '',
      ofldcRcverNm: '',
      ofldcRcverMoblphonNo: '',
      ofldcRcverEmail: '',
      signType: 'ON',
      docCat1: '',
      docNm: '',
      docGb1: '',
      docGb2: '',
      docGb3: '',
      histSn: '',
      ctrtOrginlCn: '',
      ctrtOrginlHashcd: '',
      ctrtOrginlFileNo: '',
      signDt: '',
      retrnResnCn: '',
      agreSn: 0,
      agreSttusCd: '',
      checkContGubun2: '',
      payMthDocCat1: '',
    },
    contractItemList: [],
    safetyPackageList: [],
    maintPlanList: [],
    memberList: [],
    paymentList: [],
    stamptaxList: [],
    guaranteeList: [],
    etcDocList: [],

    contractHistoryList: [], //20221202 ITSR62600

    isProgressPage: false,
    isCompletePage: false,

    modalStamptax: {
      stmptaxSn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },

    modalGuarantee: {
      grntySn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },

    modalEtcDoc: {
      etcdocsn: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      saveMode: '', 
      readonly : false,
    },

  });
  const getters = {
    searchInfo: state => state.searchInfo,
    contractList: state => state.contractList,
    contractInfo: state => state.contractInfo,
    contractItemList: state => state.contractItemList,
    contractHistoryList: state => state.contractHistoryList, //20221202 ITSR62600
    safetyPackageList: state => state.safetyPackageList,
    maintPlanList: state => state.maintPlanList,
    memberList: state => state.memberList,
    paymentList: state => state.paymentList,
    stamptaxList: state => state.stamptaxList,
    guaranteeList: state => state.guaranteeList,
    etcDocList: state => state.etcDocList,
    isProgressPage: state => state.isProgressPage,
    isCompletePage: state => state.isCompletePage,
    isOfflineComplete: state => (state.contractInfo.signType == "OFF"),
    modalStamptax : state => state.modalStamptax,
    modalGuarantee : state => state.modalGuarantee,
    modalEtcDoc : state => state.modalEtcDoc,
    isContEmp : state => (state.contractInfo.contEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo),
  };
  const mutations = {
    setSearchInfo(state, payload) {
      for (let key in state.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.searchInfo[key] = payload[key];
        }
      }
    },
    setContractList(state, payload) {
      state.contractList = payload;
    },
    setContractInfo(state, payload) {
      for (let key in state.contractInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.contractInfo[key] = payload[key];
        }
      }

      let CI = state.contractInfo;

      CI.supplyTotalAmtKor      = numToKr(CI.supplyTotalAmt);
      CI.taxTotalAmtKor         = numToKr(CI.taxTotalAmt);
      CI.sumTotalAmtKor         = numToKr(CI.sumTotalAmt);
      CI.ckeckContTotalAmtKor   = numToKr(CI.ckeckContTotalAmt);
      CI.maintPlanAmtKor        = numToKr(CI.maintPlanAmt);

    },
    setItemList(state, payload){
      state.contractItemList = payload;
    },
    setHistoryList(state, payload){ //20221202 ITSR62600
      state.contractHistoryList = payload;
    },
    setSafetyPackageList(state, payload){
      state.safetyPackageList = payload;
    },
    setMaintPlanList(state, payload){
      state.maintPlanList = payload;
    },
    setMemberList(state, payload){
      state.memberList = payload;
    },
    setPaymentList(state, payload){
      state.paymentList = payload;
    },
    setStamptaxList(state, payload){
      state.stamptaxList = payload;
    },
    setGuaranteeList(state, payload){
      state.guaranteeList = payload;
    },
    setEtcDocList(state, payload){
      state.etcDocList = payload;
    },
    setProgressPage(state, payload){
      state.isProgressPage = payload;
    },
    setCompletePage(state, payload){
      state.isCompletePage = payload;
    },
    setModalStamptax(state, payload) {
      for (let key in state.modalStamptax) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalStamptax[key] = payload[key];
        }
      }
    },
    setModalGuarantee(state, payload) {
      for (let key in state.modalGuarantee) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalGuarantee[key] = payload[key];
        }
      }
    },
    setModalEtcDoc(state, payload) {
      for (let key in state.modalEtcDoc) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalEtcDoc[key] = payload[key];
        }
      }
    },
    resetAllValues(state) {
      state.contractInfo.checkContNo	= '';
      state.contractInfo.checkSeq	= '';
      state.contractInfo.tradeName1	= '';
      state.contractInfo.mskTradeName1	= '';
      state.contractInfo.checkContDate	= '';
      state.contractInfo.checkContStartDate	= '';
      state.contractInfo.checkContFinDate	= '';
      state.contractInfo.checkContTypeA3	= '';
      state.contractInfo.checkContOpts	= '';
      state.contractInfo.supplyTotalAmt	= 0;
      state.contractInfo.supplyTotalAmtKor	= '';
      state.contractInfo.taxTotalAmt	= 0;
      state.contractInfo.taxTotalAmtKor	= '';
      state.contractInfo.sumTotalAmt	= 0;
      state.contractInfo.sumTotalAmtKor	= '';
      state.contractInfo.ckeckContTotalAmt	= 0;
      state.contractInfo.ckeckContTotalAmtKor	= '';
      state.contractInfo.maintPlanAmt	= 0;
      state.contractInfo.maintPlanAmtKor	= '';
      state.contractInfo.mainName	= '';
      state.contractInfo.checkAddr12	= '';
      state.contractInfo.suFlagA7	= '';
      state.contractInfo.suFlagA7Yn	= '';
      state.contractInfo.giroAddress	= '';
      state.contractInfo.email	= '';
      state.contractInfo.mphone	= '';
      state.contractInfo.eserviceFlag	= '';
      state.contractInfo.mbaMonterm	= '';
      state.contractInfo.payMonthlyYn = '';
      state.contractInfo.payPrepayYn = '';
      state.contractInfo.payQuarterYn = '';
      state.contractInfo.payHalfyearYn = '';
      state.contractInfo.payYearlyYn = '';
      state.contractInfo.billPrtDay	= '';
      state.contractInfo.amtAdjustFlagDesc	= '';
      state.contractInfo.mediCourt974	= '';
      state.contractInfo.checkCntAz	= '';
      state.contractInfo.contractType	= '';
      state.contractInfo.contEmpId	= '';
      state.contractInfo.ctrtNo	= '';
      state.contractInfo.ctrtOdr	= '';
      state.contractInfo.ctrtDivCd	= '';
      state.contractInfo.ctrtSttusCd	= '';
      state.contractInfo.rtrvlResnCn	= '';
      state.contractInfo.rtrvlDt	= '';
      state.contractInfo.rtrvlDtStr	= '';
      state.contractInfo.beforeCheckContStartDate	= '';
      state.contractInfo.beforeCheckContFinDate	= '';
      state.contractInfo.frstSendngDt	= '';
      state.contractInfo.scdSendngDt	= '';
      state.contractInfo.ofldcRcverNm	= '';
      state.contractInfo.ofldcRcverMoblphonNo	= '';
      state.contractInfo.ofldcRcverEmail	= '';
      state.contractInfo.signType	= 'ON';
      state.contractInfo.docCat1	= '';
      state.contractInfo.docNm	= '';
      state.contractInfo.docGb1	= '';
      state.contractInfo.docGb2	= '';
      state.contractInfo.docGb3	= '';
      state.contractInfo.histSn	= '';
      state.contractInfo.ctrtOrginlCn	= '';
      state.contractInfo.ctrtOrginlHashcd	= '';
      state.contractInfo.ctrtOrginlFileNo	= '';
      state.contractInfo.signDt	= '';
      state.contractInfo.retrnResnCn	= '';
      state.contractInfo.agreSn	= 0;
      state.contractInfo.agreSttusCd = '';
      state.contractInfo.checkContGubun2 = '';
      state.contractInfo.payMthDocCat1 = '';

      state.contractItemList = [];
      state.contractHistoryList = []; //20221202 ITSR62600
      state.safetyPackageList = [];
      state.maintPlanList = [];
      state.memberList = [];
      state.paymentList = [];
      state.stamptaxList = [];
      state.guaranteeList = [];
      state.etcDocList = [];

      state.modalStamptax.stmptaxSn	= 0;
      state.modalStamptax.ctrtNo	= '';
      state.modalStamptax.ctrtOdr	= '';
      state.modalStamptax.ctrtDivCd	= '';
      state.modalStamptax.saveMode	= '';
      state.modalStamptax.readonly	= false;

      state.modalGuarantee.grntySn	= 0;
      state.modalGuarantee.ctrtNo	= '';
      state.modalGuarantee.ctrtOdr	= '';
      state.modalGuarantee.ctrtDivCd	= '';
      state.modalGuarantee.saveMode	= '';
      state.modalGuarantee.readonly	= false;

      state.modalEtcDoc.etcdocsn	= 0;
      state.modalEtcDoc.ctrtNo	= '';
      state.modalEtcDoc.ctrtOdr	= '';
      state.modalEtcDoc.ctrtDivCd	= '';
      state.modalEtcDoc.saveMode	= '';
      state.modalEtcDoc.readonly	= false;
    }
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  
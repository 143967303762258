<template>
  <pmis-content-box :loading="listBoxLoading">
    <template v-slot:title>견적상세내용</template>
    <template #title-right>(단위: 원) VAT별도</template>
    <iui-container type="jqgrid" :id="sheetId1" style="height:150px; width:99.9%;" />
  </pmis-content-box>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['estimateInfo', 'estimateItemList']);
  },
  created() {
    this.addEvent({name: 'EstimateItems_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 200,
      width: 800,
      colModel: [
        {name: 'inputItemName', label: '품명', width: 100, align: 'left'},
        {name: 'itemsSize', label: '기종', width: 100, align: 'left'},
        {name: 'mainHogi', label: '호기번호', width: 100, align: 'left'},
        {name: 'placeHogiName', label: '호기명', width: 150, align: 'left'},
        {name: 'itemNo', label: 'itemNo', width: 0, hidden: true},
        {name: 'modelCode', label: 'modelCode', width: 0, hidden: true},
        {name: 'itemQty', label: '수량', width: 100, template: 'number'},
        {name: 'itemUnit', label: '단위', width: 100, align: 'left'},
        {
          name: 'adjPrice',
          label: '단가',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'totalPrice',
          label: '금액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {name: 'mainNo', label: 'mainNo', width: 0, hidden: true},
        {name: 'mainHogi', label: 'mainHogi', width: 0, hidden: true},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, true);
      var newHeight = pobj.height();
      $grid.jqGrid('setGridHeight', newHeight - 40, true);
    },
    onLoad() {
      console.log('EstimateItems_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.estimateItemList})
        .trigger('reloadGrid');
      this.onResize();
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

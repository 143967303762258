import { render, staticRenderFns } from "./EmpList.vue?vue&type=template&id=5fd2ac92&scoped=true&"
import script from "./EmpList.vue?vue&type=script&lang=js&"
export * from "./EmpList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd2ac92",
  null
  
)

export default component.exports
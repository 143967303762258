<template>
  <div class="contents">
    <div class="join_step01">
      <span class="blind">계약내용 조회 샘플</span>
    </div>

    <div class="btn_area">
      <button type="button" class="btn_default" @click="onSign">전자서명</button>
      <button type="button" class="btn_default" @click="onLogin">로그인</button>
    </div>
  </div>
  <!-- contents end -->
</template>

<script>
import {mapMutations} from 'vuex';
export default {
  name: 'ContractView',
	data() {
		return {
      isBuyagnt : false,
      isPersonal : false,
		};
	},
	mounted() {
		this.checkAccessDeviceType();
	},
	methods: {
    ...mapMutations('login', ['setPage']),
		async checkAccessDeviceType() {
			console.log("###############");
      console.log(window.params);
      
      let param = {
          encParam: window.params.encParam
      };

      let response = await axios.post('/urlLink/decAndParseParam', param);
      console.log(response);
      console.log("###############");

      this.isBuyagnt = eval(response.data.isBuyagnt);
      this.isPersonal = eval(response.data.isPersonal);

      console.log("this.isBuyagnt", this.isBuyagnt);
      console.log("this.isPersonal", this.isPersonal);
		},
		onSign() {
      this.$alert('[법인/개인/자연인]에 따른 전자서명 로직 수행');
      
      if (this.isPersonal) {
        console.log('자연인입니다.');
      } else {
        console.log('사업자입니다.');
      }

      if (this.isBuyagnt) {
        console.log('대리인입니다.');
      } else {
        console.log('본인입니다.');
      }

    },
    
		onLogin() {
			this.setPage('Login');
		},
	},
};
</script>

<style scoped>
</style>
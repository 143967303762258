/**
 * Validator
 */
class Validator {
  constructor(vPath, id, validationGroup, targetComponent, targetDataNameProp, rules, customValidator, errorMessage) {
    this.VPath = vPath;
    this.id = id;
    this.validationGroup = validationGroup;
    this.targetComponent = targetComponent;
    this.targetDataNameProp = targetDataNameProp.split('.');
    this.rules = rules;
    this.customValidator = [];
    if (typeof customValidator !== undefined && typeof customValidator === 'function') {
      this.addCustomValidator(customValidator);
    }
    this.errorMessage = errorMessage;
    this.isValid = true;
  }

  addCustomValidator(customValidator) {
    this.customValidator.push(customValidator);
  }

  removeCustomValidator(customValidator) {
    this.customValidator.filter(element => {
      return element !== customValidator;
    });
  }

  validate() {
    let searchObject = this.targetComponent;
    let rule = '';
    let valid = true;

    this.targetDataNameProp.forEach(element => {
      if (searchObject !== null) {
        if (searchObject.hasOwnProperty(element)) {
          searchObject = searchObject[element];
        } else {
          searchObject = null;
        }
      }
    });

    let value = searchObject;

    // console.log(`value: ${value}`);

    if (value === null) {
      rule = 'defaultMessage';
      valid = false;
    } else {
      if (this.rules.required && value.length == 0) {
        // console.log('validate > required');
        rule = 'required';
        valid &= false;
      }

      if (this.rules.min !== undefined && !Number.isNaN(this.rules.min)) {
        console.log('validate > min');
        console.log(
          `min: ${this.rules.min}, Number min: ${Number(this.rules.min)}, value: ${value}, Number(value): ${Number(
            value
          )}`
        );
        console.log(`${this.rules.min > value}`);
        if (this.rules.min > Number(value)) {
          console.log('validate > min2');
          rule = 'min';
          valid &= false;
        }
      }

      if (this.rules.max !== undefined && !Number.isNaN(this.rules.max)) {
        // console.log('validate > max');
        if (this.max > value) {
          rule = 'max';
          valid &= false;
        }
      }

      if (typeof this.customValidator !== undefined) {
        // if (typeof this.customValidator === 'function') {
        //   valid &= this.customValidator.call(this);
        // }
        if (Array.isArray(this.customValidator)) {
          this.customValidator.forEach(validator => {
            if (typeof validator === 'function') {
              valid &= validator.call(this);
            }
          });
        }
      }
    }

    this.isValid = Boolean(valid);
    const errMsg = this.errorMessage.getErrorMessage(this.isValid ? 'success' : rule);

    this.targetComponent.$emit('validated', {
      VPath: this.VPath,
      id: this.id,
      value: value,
      isValid: this.isValid,
      errorMessage: errMsg,
    });

    return this.isValid;
  }
}

export default Validator;

const state = () => ({
    searchInfo: {
        pgmCd: '',
        frmNm: '',
        name: '',
    },
    focusKeyCode: '',
    keyInfo: {
        pgmCd: '',
        empNo: '',
    },
    detailInfo: {
        pgmCd: '', // 회사코드
        empNo: '', // 아이디(사번)
        name: '', // 성명
        regiNo: '', // 주민번호
        regiNo1: '', // 주민번호1
        regiNo2: '', // 주민번호2
        tlno: '', // 전화번호
        mobileTlno: '', // 휴대폰번호
        rpsvFaxno: '', // 팩스번호
        postNo: '', // 우편번호
        adr1: '', // 주소
        adr2: '', // 상세주소
        email1: '', // 이메일1
        email2: '', // 이메일2
        phoFlNo: 0, // 사진파일번호
        laborDs: '', // 근로자구분
        laborDsOrg: '', //근로자구분(변경 이전)
        laborLoc: '', // 근무처
        blnDepNo: '', // 소속
        blnDepNm: '', //소속명
        dtsNo: '', // 직책
        wbusiness: '', // 담당업무
        rollNo: '', // 직급
        useDs: '', // 시스템사용여부
        useMst: '', // 시스템관리자여부
        useSms: '', // SMS수신동의여부
        pwd: '', // 비밀번호
        pwd2: '', // 비밀번호확인
        sgntFlNo: 0, // 사인파일번호
        labtClose: '', // 근무종료
        priceLGb: '', // 노무단가구분
        priceLAmt: '', // 노무단가
        jkdNo: '', // 노무직종
        pensionDs: '', // 국민연금적용제외여부
        healthInsurDs: '', // 건강보험적용제외여부
        employInsurDs: '', // 고용보험적용제외여부
        healthInsurMDs: '', // 장기요양30%경감대상여부
        bankCode: '', // 은행코드
        depositNo: '', // 계좌번호
        depositor: '', // 예금주
        handicapKind: '', // 장애유형
        handicapGrade: '', // 장애등급
        handicapDate: '', // 장애판정일
        nationCd: '', // 국적
        passportNo: '', // 여권번호
        visaKind: '', // 체류자격
        entryDate: '', // 입국일자
        stayDuration: '', // 체류기간
        cud: 0,
    },
    status: {
        isNew: false, // 신규여부
        isChangedLaborDs: false, // 근로자구분 변경 여부
        isEnableLaborDs: true, // 근로자구분 활성화 여부
        isPrjModal: false, // 투입현장정보 > 모달구분(현장)
        isSubcodeModal: false, // 투입현장정보 > 모달구분(작업반)
    },
    manageInfo: {
        pgmCd: '', // 회사코드
        empNo: '', // 근로자번호
        nationCd: '', // 국적코드
        handicapDs: '', // 장애인여부
        handicapKind: '', // 장애유형
        handicapGrade: '', // 장애등급
        handicapDate: '', // 장애판정일
        foreignerDs: '', // 외국인여부
        passportNo: '', // 여권번호
        visaKind: '', // 체류자격
        entryDate: '', // 입국일자
        stayDuration: '', // 체류기간
    },
    subConInfo: {
        prjCd: '', //현장코드
        prjNm: '', //현장명
        subCode: '', //작업반코드
        subCodeName: '', //작업반명
    },

    //ID 중복확인 0:확인필요, 1: 중복없음, 2: 중복있음
    dupEmpNoStatus: 0,

    //파일 업로드 여부 체크를 위한 obj
    fileFlag: {
        phoFl: false,
        sgntFl: false,
    },

    imageSave: false,
});

const getters = {
    searchInfo: state => state.searchInfo,
    focusKeyCode: state => state.focusKeyCode,
    keyInfo: state => state.keyInfo,
    detailInfo: state => state.detailInfo,
    status: state => state.status,
    manageInfo: state => state.manageInfo,
    subConInfo: state => state.subConInfo,
    dupEmpNoStatus: state => state.dupEmpNoStatus,
    fileFlag: state => state.fileFlag,
    imageSave: state => state.imageSave,
};

const actions = {};

const mutations = {
    setSearchInfo(state, payload) {
        for (let key in state.searchInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.searchInfo[key] = payload[key];
            }
        }
    },
    setFocusKeyCode(state, payload) {
        state.focusKeyCode = payload;
    },
    initFocusKeyCode(state) {
        state.focusKeyCode = '';
    },
    setKeyInfo(state, payload) {
        for (let key in state.keyInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.keyInfo[key] = payload[key];
            }
        }
    },
    setDetailInfo(state, payload) {
        for (let key in state.detailInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.detailInfo[key] = payload[key];
            }
        }

        state.detailInfo.regiNo = state.detailInfo.regiNo1 + state.detailInfo.regiNo2;

        let laborDs = state.detailInfo.laborDs;
        let laborDsOrg = state.detailInfo.laborDsOrg;
        state.status.isChangedLaborDs = laborDs != laborDsOrg ? true : false;
        state.status.isPrjModal = laborDs != '000004' ? true : false;
        state.status.isSubcodeModal = laborDs == '000004' ? true : false;
    },
    initDetailInfo(state) {
        for (let key in state.detailInfo) {
            state.detailInfo[key] = '';
        }
    },
    setStatus(state, payload) {
        for (let key in state.status) {
            if (payload && payload.hasOwnProperty(key)) {
                state.status[key] = payload[key];
            }
        }
    },
    setManageInfo(state, payload) {
        for (let key in state.manageInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.manageInfo[key] = payload[key];
            }
        }
    },
    setSubConInfo(state, payload) {
        for (let key in state.subConInfo) {
            if (payload && payload.hasOwnProperty(key)) {
                state.subConInfo[key] = payload[key];
            }
        }
    },
    initManageInfo(state) {
        for (let key in state.manageInfo) {
            state.manageInfo[key] = '';
        }
    },

    setDupEmpNoStatus(state, payload) {
        state.dupEmpNoStatus = payload;
    },

    initFileFlag(state) {
        for (let key in state.fileFlag) {
            state.fileFlag[key] = false;
        }
    },
    setFileFlag(state, payload) {
        for (let key in state.fileFlag) {
            if (payload && payload.hasOwnProperty(key)) {
                state.fileFlag[key] = payload[key];
            }
        }
    },

    setImageSave(state, payload) {
        state.imageSave = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
<template>
  <div class="box_w">
    <table :id="elId" style="width:100%; height:100%;"></table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['searchInfo']);
  },

  created() {
    this.addEvent([
      {name: 'UserGroupList_selectUserGroupRList', func: this.selectUserGroupRList},
      {name: 'UserGroupList_getSaveJson', func: this.getSaveJson},
    ]);
  },

  mounted() {
    this.initSheet();
    if (this.grpAuthInfo && this.grpAuthInfo.grpAuth) {
      this.selectGrpAuthUserList();
    }
  },
  methods: {
    initSheet() {
      const me = this;
      const OPT = {
        //각 컬럼에 대한 정의 (컬럼의 이름,유형(Type),포맷(Format)등을 설정)
        //컬럼의 "Type"과 "Name" 속성은 반드시 설정되어야 합니다.
        Cols: [
          {
            label: '선택',
            edittype: 'checkbox',
            name: 'auth',
            width: 60,
            editable: true,
            hidden: false,
          },
          {
            label: '그룹명',
            name: 'grpNm',
            edittype: 'text',
            width: 200,
            hidden: false,
            editable: false,
          },
          {
            label: '비고',
            name: 'rmk',
            edittype: 'text',
            hidden: false,
            editable: false,
          },
          {
            name: 'grpAuth',
            edittype: 'text',
            hidden: true,
            editable: false,
          },
          {
            name: 'pgmCd',
            edittype: 'text',
            hidden: true,
            editable: false,
          },
        ]
      };

			var $grid = $("#"+me.elId);
			$grid.jqGrid({
				height:200,
				width:800,
				autowidth:true,
				shrinkToFit:true,
				selectionMode : 'singlerow', 
				colModel: OPT.Cols,
				editable: true,
				onSelectRow: function(rowid) {
          var $grid = $("#"+me.elId);
          // eslint-disable-next-line
					var rowdata = $grid.getRowData(rowid);
					//me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
				}
			});
      $gridAutoResize( $grid );
    },

    selectUserGroupRList(empNo) {
			const me = this;
			var $grid = $("#"+me.elId);

      $grid.jqGrid( 'clearGridData' );
			axios.post('/userAuth/selectUserGroupRList', {pgmCd: this.searchInfo.pgmCd, empNo: empNo}).then(response => {
				if (response.status == 200) {
					var data = response.data;
					$grid.jqGrid( 'setGridParam', { data:data }).trigger('reloadGrid');
				}
			});

    },

    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson().data);
      }
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box>
    <template v-slot:header-left v-if="isEnableSearchbox">
      <iui-select-old
        v-if="type != 'prj' && type != 'prjEst'"
        :id="selectId"
        :items="searchSe"
        :value="searchInfo.searchSe"
        @change="onChange('searchSe', $event)"
      />
      <iui-text :id="textId" :value="searchInfo.searchNm" @change="onChange('searchNm', $event)" @enter="onSearch" />
      <iui-checkbox-group-old
        :items="checkboxItem"
        v-if="type == 'prj'"
        :checkedValues="[searchInfo.endCntrInc]"
        @change="searchInfo.endCntrInc = $event.target.checked ? 'Y' : 'N'"
      ></iui-checkbox-group-old>
      <iui-button value="검색" @click="onSearch" />
    </template>
    <CustomerCodeList v-if="type == 'customer'" :isPopup="true" :uid="uid" :paramObj="paramObj" />
    <EmpCodeList v-else-if="type == 'emp'" :isPopup="true" :uid="uid" :paramObj="paramObj" />
    <DeptCodeList v-else-if="type == 'dept'" :isPopup="true" :uid="uid" :paramObj="paramObj" />

    <!--
    <ContractInfo
      v-if="type == 'prj'"
      :isPopup="true"
      :eventNms="{selectCntrctInfoList: 'selectCntrctInfoListOnSearch', onFocusedRowData: 'onFocusedRowDataOnContract'}"
      :uid="uid"
    />
    <PrjEstCdPopup
      v-if="type == 'prjEst'"
      :eventNms="{selectPrjEstMList: 'selectPrjEstMListOnSearch', onFocusedRowData: 'onFocusedRowDataOnPrjEst'}"
      :uid="uid"
    />
    <MaterialCodeList
      v-else-if="type == 'matCode'"
      :isPopup="true"
      :uid="uid"
      :paramObj="paramObj"
    />
    <EqutCodeList
      v-else-if="type == 'equt'"
      :isPopup="true"
      :uid="uid"
      :checkboxYn="'N'"
      :eventNms="{onFocusedRowData: 'onFocusedRowDataOnEqut'}"
    />
    <WorkerList
      v-else-if="type == 'worker'"
      :isPopup="true"
      :uid="uid"
      :isExtend="isExtend"
      :eventNms="{onFocusedRowData: 'onFocusedRowDataOnWorker'}"
    />
    <WorkGroupList v-else-if="type == 'workGroup'" :uid="uid" const-page complete-page popup-page />
    <AccountGb v-else-if="type == 'accountGb'" :uid="uid" />
    <CompanyCode v-else-if="type == 'companyCode'" :uid="uid" :isPopup="true" />
    <DataCl
      v-else-if="type == 'dataCl'"
      :uid="uid"
      :isPopup="true"
      :isBasePage="paramObj.isBasePage"
    />
-->
    <!--   <CommonCode v-else-if="type == 'commonCode'" :uid="uid" :paramObj="paramObj" /> -->
    <UserList v-else-if="type == 'user'" :uid="uid" />
  </pmis-content-box>
  <!-- <div class="box_w">
    <div class="search_box">
    </div>
    <div
      class="popcon"
      :style="`width:100%;height:calc(100% - ${type == 'customer'? '66':'47'}px)`"
    >
    </div>
  </div>-->
</template>

<script>
import CustomerCodeList from '@/view/common/popup/CustomerList.vue';
import EmpCodeList from '@/view/common/popup/EmpList.vue';
import DeptCodeList from '@/view/common/popup/DeptList.vue';

// import ContractInfo from '@/view/bsnsManage/cntrctManage/CntrctInfoRegist/components/ContractInfo';
// import PrjEstCdPopup from '@/view/estmtManage/estmtPrjManage/estmtPrjRegist/popup/prjEstCdPopup';
// import MaterialCodeList from '@/view/sysManage/stdCodeManage/matCodeRegist/components/DetailList';
// import EqutCodeList from '@/view/sysManage/stdCodeManage/equtCodeRegist/components/EqutCodeList';
// import WorkerList from '@/view/user/Worker/components/WorkerList';
// // import WorkGroupList from '@/view/Resource/WorkGroup/Contract/components/ContractListSearchPopup';
// import WorkGroupList from '@/view/subBidding/contractManage/list/components/ContractList.vue';
// import AccountGb from '@/view/common/AccountGb';
//import CommonCode from '@/view/sysManage/stdCodeManage/cmmnCodeRegist/components/CommonCodePop.vue';
// import CompanyCode from '@/view/sysManage/userManage/companyRegist/components/CompanyList.vue';
// import DataCl from '@/view/sysManage/baseSysManage/DataClassificationRegist/components/DataClassificationList.vue';
import UserList from '@/view/user/user/components/UserPopup.vue';
export default {
  components: {
    // ContractInfo,
    // PrjEstCdPopup,
    // MaterialCodeList,
    // WorkGroupList,
    // EqutCodeList,
    // WorkerList,
    // AccountGb,
    // CommonCode,
    // CompanyCode,
    // DataCl,
    CustomerCodeList,
    EmpCodeList,
    DeptCodeList,
    UserList,
  },

  props: {
    type: {
      type: String,
    },
    uid: {
      type: Number,
    },
    isExtend: {
      type: Boolean,
      default: false,
    },
    paramObj: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      selectId: 'searchSe' + this._uid,
      textId: 'searchNm' + this._uid,
      checkboxItem: [{label: '종료계약포함', value: 'checkValues', isChecked: false}],
      searchInfo: {
        searchSe: '',
        searchNm: '',
        endCntrInc: '',
      },
      isEnableSearchbox: true,
    };
  },

  beforeCreate: function() {
    $mapGetters(this, {
      sheetData: 'sheetData',
    });
  },

  created() {
    this.setSearchData();
    this.isEnableSearchbox = this.type != 'dataCl' ? true : false;
  },

  mounted() {
    // this.setHeight();
    if (this.type != 'commonCode') {
      setTimeout(() => this.onSearch(), 10);
    }
  },

  methods: {
    onSearch() {
      let name;
      let param;
      switch (this.type) {
        case 'customer':
          name = 'CustomerList_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'emp':
          name = 'EmpList_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'dept':
          name = 'DeptList_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        /*
        case 'prj':
          name = 'selectCntrctInfoListOnSearch';
          param = {
            searchSe: this.type == 'prj' ? '' : this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
            endCntrInc: this.searchInfo.endCntrInc, //document.getElementsByName('endCntrInc')[0].checked ? 'Y' : 'N',
          };
          break;
        case 'prjEst':
          name = 'selectPrjEstMListOnSearch';
          param = {
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'matCode':
          name = 'MatCode_DetailList_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'workGroup':
          name = 'Sub_ContractList_search_popup';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            // pgmCd: JSON.parse(sessionStorage.getItem("userInfo")).pgmCd,
            // prjCd: this.sheetData.prjCd
          };
          break;
        case 'equt':
          name = 'selectEqutCodeList';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'worker':
          name = 'selectWorkerList';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
            pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
          };
          break;
        case 'accountGb':
          name = 'AccountGbFilter';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'commonCode':
          name = 'CommonCode_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'companyCode':
          name = 'CompanyCode_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;
        case 'user':
          name = 'User_search';
          param = {
            searchSe: this.searchInfo.searchSe,
            searchNm: this.searchInfo.searchNm,
          };
          break;*/
        default:
          break;
      }

      this.callEvent({name: name, param: param});
    },
    setSearchData() {
      this.searchSe = [];
      switch (this.type) {
        case 'customer':
          this.searchSe.push(
            {value: 'tradeName', text: '회사명'},
            {value: 'businessNo', text: '사업자번호'},
            {value: 'tradeNo', text: '거래선코드'}
          );
          break;
        case 'emp':
          this.searchSe.push(
            {value: 'empName', text: '이름'},
            {value: 'empId', text: '사번'},
            {value: 'teamNoDesc', text: '부서명'},
            {value: 'teamNo', text: '부서코드'}
          );
          break;
        case 'dept':
          this.searchSe.push({value: 'teamNoDesc', text: '부서명'}, {value: 'teamNo', text: '부서코드'});
          break;
        /*
        case 'prj':
          this.searchSe.push({value: 'prjCd', text: '현장코드'}, {value: 'prjNm', text: '현장명'});
          break;
        case 'matCode':
          this.searchSe.push({value: 'matName', text: '자재명'}, {value: 'matCode', text: '자재코드'});
          break;
        case 'workGroup':
          this.searchSe.push({value: 'subCodeName', text: '작업반명'}, {value: 'subCode', text: '작업반 계약코드'});
          break;
        case 'equt':
          this.searchSe.push({value: 'equipCode', text: '장비코드'}, {value: 'itemName', text: '장비명'});
          break;
        case 'worker':
          this.searchSe.push({value: 'empNo', text: 'ID'}, {value: 'name', text: '근로자'});
          break;
        case 'accountGb':
          this.searchSe.push({value: 'accountGb', text: '거래구분코드'}, {value: 'accountGbName', text: '거래구분명'});
          break;
        case 'commonCode':
          this.searchSe.push({value: 'code', text: '코드'}, {value: 'codeNm', text: '코드명'});
          break;
        case 'companyCode':
          this.searchSe.push({value: 'pgmCd', text: '회사코드'}, {value: 'frmNm', text: '회사명'});
          break;
        case 'user':
          this.searchSe.push({value: 'name', text: '성명'}, {value: 'depNm', text: '부서명'});
          break;
        */
        default:
          break;
      }
      if (this.searchSe.length > 0) {
        this.searchInfo.searchSe = this.searchSe[0].value;
      }
    },

    setHeight() {
      let base = this.$el.parentNode.offsetHeight;
      let minus = 0;

      //팝업 header
      let styles = getComputedStyle(this.$el.parentNode.querySelector('.poph'));
      let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.parentNode.querySelector('.poph').offsetHeight + margin;

      //팝업 버튼박스
      styles = getComputedStyle(this.$el.parentNode.querySelector('.popbtn'));
      margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.parentNode.querySelector('.popbtn').offsetHeight + margin;

      //팝업 검색박스
      styles = getComputedStyle(this.$el.querySelector('.search_box'));
      margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
      minus += this.$el.querySelector('.search_box').offsetHeight + margin;

      //팝업 보더값
      styles = getComputedStyle(this.$el);
      let boader = parseFloat(styles['borderTop']) + parseFloat(styles['borderBottom']);

      let height = Math.ceil(base - minus - boader);
      this.$el.querySelector('.popcon').style.height = `${height}px`;
    },
    onChange: function(key, event) {
      if (key == 'searchSe' && !event.target.value) {
        this.searchInfo.searchSe = '';
        this.searchInfo.searchNm = '';
      } else {
        this.searchInfo[key] = event.target.value;
      }
    },
  },
};
</script>

<style scoped></style>

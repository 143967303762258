<template>
  <!--pmis-page-->
  <pmis-content-box v-if="true">
    <template v-slot:header-right>
      <iui-button @click="onReqRealName">오픈뱅킹 실명확인</iui-button>
    </template>
    <iui-container type="css-flex" flex-direction="row">
      <i-col>
        <i-row>
          <iui-container type="table" theme="bullet" header-width="100px">
            <i-row>
              <i-col-header>Access Token</i-col-header>
              <i-col>
                <iui-text name="accessToken" :value.sync="inputData.accessToken" />
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header>
                은행거래고유번호
              </i-col-header>
              <i-col>
                <iui-text type="text" id="bank_tran_id" :value.sync="inputData.bankTranId" />
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header>
                요청일시
              </i-col-header>
              <i-col>
                <iui-text type="text" id="tran_dtime" :value.sync="inputData.currentDt" />
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header>
                계좌번호
              </i-col-header>
              <i-col>
                <iui-text type="text" id="account_num" :value.sync="inputData.accountNum" />
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header>
                개설기관.표준코드
              </i-col-header>
              <i-col>
                <iui-select-old
                  name="bank_code_std"
                  :items="openBankCd"
                  :value.sync="inputData.bankCodeStd"
                ></iui-select-old>
                <!--iui-text @change="setListSearchInfo({searchNm : $event.target.value}) " width="150px" /-->
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header style="width:10%">
                예금주실명번호<br />
                구분코드
              </i-col-header>
              <i-col style="width:30%">
                <iui-select-old
                  name="account_holder_info_type"
                  :items="accountHolderTypeCd"
                  :value.sync="inputData.accountHolderInfoType"
                ></iui-select-old>
                <!--<iui-text @change="setListSearchInfo({searchNm : $event.target.value}) " width="150px" />-->
              </i-col>
            </i-row>
            <i-row height="30px">
              <i-col-header style="width:10%">
                예금주실명번호
              </i-col-header>
              <i-col style="width:50%">
                <iui-text type="text" name="account_holder_info" :value.sync="inputData.accountHolderInfo"></iui-text>
              </i-col>
            </i-row>
          </iui-container>
        </i-row>
        <i-spacer />
        <i-row>
          <pmis-content-box>
            <template v-slot:title>계좌실명조회결과</template>
            <p>결과코드: {{ resposeData.rsp_code }}</p>
            <p>결과메시지: {{ resposeData.rsp_message }}</p>
            <p>예금주명: {{ resposeData.account_holder_name }}</p>
          </pmis-content-box>
        </i-row>
      </i-col>
    </iui-container>
  </pmis-content-box>
  <!--/pmis-page-->
</template>

<script>
import VueCookies from 'vue-cookies';
// eslint-disable-next-line
import store from '@/store/index.js';

/*
1. 토큰경과일수를 체크하여 85일이 경과했을 경우 자동갱신한다.
2. 계좌신명조회 시 매회 트랜젝션ID를 조회하여 처리한다.
*/
export default {
  props: {
    cmsData: {
      type: Object,
    },
  },
  beforeCreate() {
    //$mapGetters(this, {detailInfoDtls: 'detailInfoDtls', isFormula: 'isFormula', detailInfo: 'detailInfo'});
    //this.onBeforeRealName(); //호출이되지않는다. 20.11.25
  },
  created() {
    this.addEvent({name: 'OpenBankingRealNamePop_popConfirm', func: this.onPopConfirm});
    if (this.cmsData) {
      this.setForm(this.cmsData);
    }
    this.onBeforeRealName();
  },
  data() {
    return {
      pgmCd: JSON.parse(sessionStorage.getItem('userInfo')).pgmCd,
      inputData: {
        inputDivCd: '',
        clientUseCode: '',
        clientId: '',
        clientSecret: '',
        accessToken: '',
        refreshToken: '',
        expiresIn: '',
        tknIssuDt: '',
        scope: '',
        bankTranId: '',
        currentDt: '',
        bankCodeStd: '097',
        accountNum: '',
        accountHolderInfoType: ' ',
        accountHolderInfo: '',
      },
      openBankCd: [
        {value: '', text: '- 선택 -'},
        {value: '002', text: 'KDB산업은행'},
        {value: '003', text: 'IBK기업은행'},
        {value: '004', text: 'KB국민은행'},
        {value: '007', text: '수협중앙회'},
        {value: '011', text: 'NH농협은행'},
        {value: '020', text: '우리은행'},
        {value: '023', text: 'SC제일은행'},
        {value: '027', text: '한국씨티은행'},
        {value: '031', text: '대구은행'},
        {value: '032', text: '부산은행'},
        {value: '034', text: '광주은행'},
        {value: '035', text: '제주은행'},
        {value: '037', text: '전북은행'},
        {value: '039', text: '경남은행'},
        {value: '045', text: '새마을금고'},
        {value: '050', text: '상호저축은행'},
        {value: '081', text: 'KEB하나은행'},
        {value: '088', text: '신한은행'},
        {value: '089', text: '케이뱅크'},
        {value: '090', text: '카카오은행'},
        {value: '097', text: '오픈은행'},
      ],
      accountHolderTypeCd: [
        {value: '', text: '- 선택 -'},
        {value: ' ', text: '생년월일(6자리)'},
        {value: '1', text: '주민등록번호'},
        {value: '2', text: '외국인등록번호'},
        {value: '3', text: '국내거소신고번호'},
        {value: '4', text: '조합주민번호'},
        {value: '5', text: '여권번호'},
        {value: '6', text: '사업자등록번호'},
        {value: 'E', text: '기타(외국인투자등록증번호 등)'},
      ],
      resposeData: {
        account_holder_info: '',
        account_holder_info_type: '',
        account_holder_name: '',
        account_num: '',
        account_seq: '',
        account_type: '',
        api_tran_dtm: '',
        api_tran_id: '',
        bank_code_std: '',
        bank_code_sub: '',
        bank_code_tran: '',
        bank_name: '',
        bank_rsp_code: '',
        bank_rsp_message: '',
        bank_tran_date: '',
        bank_tran_id: '',
        rsp_code: '',
        rsp_message: '',
        savings_bank_name: '',
      },
    };
  },
  mounted() {},
  methods: {
    setForm(param) {
      this.inputData.clientUseCode = param.clientUseCode;
      this.inputData.clientId = param.clientId;
      this.inputData.clientSecret = param.clientSecret;
      this.inputData.scope = param.scope;
    },
    onPopConfirm(param) {
      param(this.resposeData);
    },
    //계좌실명확인
    onBeforeRealName() {
      var me = this;
      axios.post('/cms/selectCmsBase', {inputDivCd: 'CMS'}).then(response => {
        //console.log('cms 조회결과 Header 1====>', response.headers);
        this.inputData.clientId = response.data.cmsData.clientId;
        this.inputData.clientSecret = response.data.cmsData.clientSecret;
        this.inputData.bankTranId = response.data.cmsData.bankTranId; //일별유일트랜잭션ID
        this.inputData.accessToken = response.data.cmsData.accessToken;
        this.inputData.tknIssuDt = response.data.cmsData.tknIssuDt;
        this.inputData.clientUseCode = response.data.cmsData.clientUseCode;
        this.inputData.scope = response.data.cmsData.scope;
        this.inputData.currentDt = response.data.cmsData.currentDt;

        //response.data.cmsData.issueTokenYn = 'Y'; //테스트

        //토큰사용일경과시 토큰재발급
        if (response.data.cmsData.issueTokenYn == 'Y') {
          var qs = require('qs'); //querystring
          var data = qs.stringify({
            client_id: this.inputData.clientId,
            client_secret: this.inputData.clientSecret,
            scope: 'oob',
            grant_type: 'client_credentials',
          });
          var config = {
            method: 'post',
            //url: '/openbanking/oauth/2.0/token', //프록시서버로 크로스도메인처리
            url: 'https://testapi.openbanking.or.kr/oauth/2.0/token',
            data: data,
          };
          axios(config)
            .then(function(response) {
              me.inputData.accessToken = response.data.access_token;
              me.inputData.expiresIn = response.data.expires_in;
              me.inputData.scope = response.data.scope;

              axios.post('/cms/updateCmsToken', me.inputData);
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      });
    },
    //계좌실명확인
    async onReqRealName() {
      if (!this.inputData.accountNum) {
        this.$alert('계좌번호를 입력하세요.');
        return;
      }
      if (!this.inputData.bankCodeStd) {
        this.$alert('개설기관.표준코드를 선택하세요.');
        return;
      }
      if (!this.inputData.accountHolderInfoType) {
        this.$alert('예금주실명번호 구분코드를 선택하세요.');
        return;
      }
      if (!this.inputData.accountHolderInfo) {
        this.$alert('예금주실명번호를 입력하세요.');
        return;
      }

      const response = await axios.post('/cms/selectCmsBase', {inputDivCd: 'CMS'});
      this.inputData.clientId = response.data.cmsData.clientId;
      this.inputData.clientSecret = response.data.cmsData.clientSecret;
      this.inputData.bankTranId = response.data.cmsData.bankTranId; //일별유일트랜잭션ID
      this.inputData.accessToken = response.data.cmsData.accessToken;
      this.inputData.tknIssuDt = response.data.cmsData.tknIssuDt;
      this.inputData.clientUseCode = response.data.cmsData.clientUseCode;
      this.inputData.scope = response.data.cmsData.scope;
      this.inputData.currentDt = response.data.cmsData.currentDt;

      axios
        .post(
          'https://testapi.openbanking.or.kr/v2.0/inquiry/real_name',
          {
            bank_tran_id: this.inputData.bankTranId,
            tran_dtime: this.inputData.currentDt, //"요청전문 포맷 에러 [tran_dtime 이 날짜시간 형식(yyyyMMddHHmmss) 에 맞지 않습니다.]"
            bank_code_std: this.inputData.bankCodeStd,
            account_num: this.inputData.accountNum,
            account_holder_info_type: this.inputData.accountHolderInfoType,
            account_holder_info: this.inputData.accountHolderInfo,
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.inputData.accessToken,
              'Content-Type': 'application/json; charset=UTF-8',
            },
          }
        )
        .then(response => {
          console.log('response=>>>', response);

          this.resposeData = response.data;

          console.log('[기존]실명==' + this.cmsData.userAuthKey);
          let authorization = '';
          if (sessionStorage.getItem('id') && sessionStorage.getItem('isLogin')) {
            authorization = VueCookies.get(/*sessionStorage.getItem('id') + */ 'SCD' + '_accessToken');
          }
          console.log('[현존]실명==' + authorization);
          // if(this.cmsData.userAuthKey !== authorization ){
          //   axios.defaults.headers.common.Authorization = this.OrgAuthToken;
          //   VueCookies.set(store.getters['login/accessTokenKey'], this.cmsData.userAuthKey, store.getters['login/refreshTokenExpires']);
          // }
          console.log('[저장]실명==' + axios.defaults.headers.common.Authorization);
        })
        .catch(ex => {
          console.log('ERR!!!!! : ', ex);
        });
    },
  },
};
</script>

<style></style>

<script>
import {defineComponent, reactive, ref, computed, onMounted,onUnmounted , watch, nextTick} from '@vue/composition-api';
import moment from 'moment';

const CHART = {
  XAXIS: {
    labels: {
      allowOverlap: true,
      rotation: 0,
      style: {
        fontSize: '12px',
      },
      overflow: 'allow',
    },
    lineColor: '#000000',
    lineWidth: 1,
  },
  YAXIS_COLUMN: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    lineWidth: 0,
    min: 0,
    max: 150,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  YAXIS_LINE: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    min: -150,
    max: 100,
    lineWidth: 0,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  SERIES_COLUMN: {
    type: 'column',
    color: '#0033a0',
    dataLabels: {
      enabled: true,
      align: 'center',
      color: '#000000',
      // format: '{point.y:.0f}%',
      shadow: false,
      style: {
        fontSize: '12px',
        // textOutline: '1px contrast',
      },
      verticalAlign: 'top',
      y: -22,
    },
    // minPointLength: 3,
    shadow: false,
    yAxis: 0,
  },
  SERIES_LINE: {
    type: 'line',
    color: '#f65275',
    dataLabels: {
      enabled: true,
      align: 'center',
      format: '{point.y:.0f}%',
      style: {
        fontSize: '12px',
      },
    },
    tooltip: {
      valueSuffix: '%',
    },
    yAxis: 1,
  },
};

export default defineComponent({
  setup(props, context) {
    const divList = [];
    const parentTeamList = [];
    let teamList = [];
    const useRateTemplate = {
      period: undefined,
      onlineCount: 0,
      offlineCount: 0,
      totalCount: 0,
      onlineRate: 0,
      offlineRate: 0,
    };

    // prettier-ignore
    // eslint-disable-next-line no-constant-condition
    if (true) {
      divList.push({divCd: 'OEK', pageList: [1], teamNoList: ['OEK'], teamNo: 'OEK', teamName: 'OEK'});
    }

    const startDate = '20220901';
    let fromDate = moment()
      .startOf('month')
      .format('YYYYMMDD');
    let toDate = moment(startDate)
      .endOf('month')
      .add(6, 'month')
      .format('YYYYMMDD');

    if (toDate < fromDate) {
      toDate = moment(fromDate)
        .endOf('month')
        .format('YYYYMMDD');
      fromDate = moment(toDate)
        .startOf('month')
        .add(-6, 'month')
        .format('YYYYMMDD');
    } else {
      fromDate = startDate;
    }

    const checkBoxList = {
      direct: [
        {text: '완료', value: '완료'},
        {text: '송부', value: '송부'},
      ],

      contFlag: [],
      period: [],
        
      
    };

    const state = reactive({
      searchData: {
        divCd: 'NPP',
        fromDate: fromDate,
        toDate: toDate,
        fromPeriod: undefined,
        toPeriod: undefined,
        useRateBaseDate: 'CONT_DATE',
        direct:'',
        contFlag:undefined,
        period: '',
        parentTeamNo : undefined
      },

      codeList: {
        columnSizeList: [
          {key: '2', value: '2개'},
          {key: '3', value: '3개'},
          {key: '4', value: '4개'},
          {key: '5', value: '5개'},
          {key: '6', value: '6개'},
        ],
        useRateBaseDate: [
          // {key: 'ENTRY_DATE', value: '수주등록일'},
          {key: 'CONT_DATE', value: '계약일'},
        ],
      },

      periodList: undefined,
      areaList: undefined,
      // parentTeamList: {all: undefined, ne: undefined, mod: undefined},
      // teamList: {all: undefined, ne: undefined, mod: undefined},
      // seriesByPeriod: {all: undefined, ne: undefined, mod: undefined},
      // seriesByParentTeam: {all: undefined, ne: undefined, mod: undefined},
      // seriesByTeam: {all: undefined, ne: undefined, mod: undefined},

      nppPage1ColumnSize: 4,

      nppPage1ColumnWidth: computed(() => {
        const width = 99 / state.nppPage1ColumnSize;

        return `${width}%`;
      }),

   

      nppTeamList : [],

      nppPage1TeamList : [],

      nppPage1ParentTeamList : {
        NPP : [],
        SVC_T : []
      },
      

      loading: false,
      loadingMessage: undefined,


      multiValueDirect: ['완료','송부'],
      multiValueContFlag:[],
      multiValuePeriod:[],

      dataCall:true,
      initData : [],
    });    

    const privateMethod = {
      inputNumberFormat(val){
            return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      getChartId(team, page) {
        return `divChart_${team.divCd}_1_${team.teamNo}_${page}`;
      },
      calcurateUseRate(useRate) {
        useRate.totalCount = useRate.onlineCount + useRate.offlineCount;
        useRate.onlineRate = Math.round((useRate.onlineCount / useRate.totalCount) * 100);
        useRate.offlineRate = Math.round((useRate.offlineCount / useRate.totalCount) * 100);
      },
      columnDataLabelsFormatter(data, team) {
        return privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].onlineCount}`)
              + ` / ` 
              + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].totalCount}`);
      },
      tooltipFormatter(data, team) {
        return (
          `<b><i>${team.useRateList[data.point.x].tooltipPeriod}</i></b>` +
          `<hr size="1" />` +
          `<b>${data.series.name}: ${data.point.y}</b>%` +
          `<hr size="1" />` +
          `전자계약: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].onlineCount}`) + `건</b><br />` +
          `서면계약: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].offlineCount}`) + `건</b><br />` +
          `<b>전체계약</b>: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].totalCount}`) + `건</b>`
        );
      },
      createChart(teamList, page) {
        teamList.forEach(team => {
          const id = privateMethod.getChartId(team, page);

          createIBChart(id, id, {
            width: '100%',
            height: '300px',
          });
        });
      },
      resizeChart(teamList, page) {
        teamList.forEach(team => {
          const id = privateMethod.getChartId(team, page);
          const parentElement = document.getElementById(id).parentElement;
          const width = parentElement.offsetWidth - 2;
          const height = width * 0.8;

          window[id].setSize(width, height);
        });

      },
    };

    const method = {
      async search_click() {
        
        parentTeamList.splice(0);
        teamList.splice(0);
        try {
          if(state.dataCall == true){
            
            state.searchData.period = '';
            state.multiValuePeriod.splice(0);
            state.loadingMessage = '데이터를 가져오고 있습니다.';
            state.loading = true;
            const response = await axios.post('/statistics/useRate', state.searchData);
            state.initData = response.data;
          }else{
            state.dataCall = true;
          }
          
          // contFlag
          let t_contFlag=[];
          state.initData
            .reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.contFlag) === -1) {
                a.push(c.contFlag);
              }
              return a;
            }, [])
            .sort()
            .forEach(contFlag => {
              t_contFlag.push({text: contFlag, value: contFlag});
            });
          
            const aaa = t_contFlag.reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.value) === -1) {
                a.push(c.value);
              }
              return a;
            }, [])
            .sort();

          if(checkBoxList.contFlag.length == 0)
          {
            state.multiValueContFlag.push(...aaa);
            checkBoxList.contFlag = t_contFlag;              
          }
          
          //팀넣기
          teamList=[];
          const useRateList = [];
          if(state.multiValuePeriod.length > 0){
            useRateList.push(...state.initData.filter(useRate => state.multiValueDirect.includes(useRate.direct))
                                              .filter(useRate => state.multiValueContFlag.includes(useRate.contFlag))
                                              .filter(useRate => state.multiValuePeriod.includes(useRate.period)));
          }else{
            useRateList.push(...state.initData.filter(useRate => state.multiValueDirect.includes(useRate.direct))
                                              .filter(useRate => state.multiValueContFlag.includes(useRate.contFlag)));
          }
         
          const periodList = [];
          const areaList = [];
          const areaUseRateList = [];

          state.loadingMessage = '데이터를 분석하고 있습니다... (1/5)';
          await nextTick();
                    
          //parentTeamList
          useRateList
            .reduce((a, c) => {              
              if (a.length === 0 || a.findIndex(e => e.parentTeamNo === c.parentTeamNo) === -1) {
                a.push({'parentTeamNo':c.parentTeamNo,'parentTeamName':c.parentTeamName});
              }
              return a;
            }, [])
            .sort()
            .forEach(parentTeam => {              
              parentTeamList.push({
                divCd: 'OEK'
                , pageList: []
                , parentTeamNo: parentTeam['parentTeamNo']
                , parentTeamName: parentTeam['parentTeamName']                
                , teamNo: parentTeam['parentTeamNo']
                , teamName: parentTeam['parentTeamName']
              });
              areaList.push({key: parentTeam['parentTeamNo'], value: parentTeam['parentTeamName']});
            });

          // team
          useRateList
            .reduce((a, c) => {
              if (a.length === 0 || a.findIndex(e => e.teamNo === c.teamNo) === -1) {
                a.push({'parentTeamNo':c.parentTeamNo,'parentTeamName':c.parentTeamName,'teamNo':c.teamNo,'teamName':c.teamName});
              }
              return a;
            }, [])
            .sort()
            .forEach(team => {              
              teamList.push({
                divCd: 'OEK'
                , pageList: [1]
                , parentTeamNo: team['parentTeamNo']
                , parentTeamName: team['parentTeamName']
                , teamNoList: [team['teamNo']]
                , teamNo: team['teamNo']
                , teamName: team['teamName']
              });
            });
            
          method.page1TeamListSet();
         
          // period
          let t_preiod=[];
          useRateList
            .reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.period) === -1) {
                a.push(c.period);
              }

              return a;
            }, [])
            .sort()
            .forEach(period => {
              let displayPeriod = `${period.substring(2, 4)}P${period.substring(4, 6)}`;
              const tooltipPeriod = displayPeriod;
              if (period.substring(4, 6) !== '01') {
                displayPeriod = `P${period.substring(4, 6)}`;
              }

              periodList.push({
                period: period,
                displayPeriod: displayPeriod,
                tooltipPeriod: tooltipPeriod,
                useRate: undefined,
              });

              t_preiod.push({text: period, value: period});
            });

          if (periodList.length > 0) {
            periodList[0].displayPeriod = `${periodList[0].period.substring(2, 4)}P${periodList[0].period.substring(
              4,
              6
            )}`;
          }

          if(checkBoxList.period.length == 0)
          {
            checkBoxList.period = t_preiod;
          }

          

          state.loadingMessage = '데이터를 분석하고 있습니다... (2/5)';
          await nextTick();

          // 기간/팀 별
          teamList.forEach(team => {
            team.useRateList = [];

            periodList.forEach(period => {
              const useRateListByPeriodAndTeam = useRateList.filter(
                useRate => team.teamNoList.includes(useRate.teamNo) && useRate.period === period.period.toString()
              );
              
              const useRate = useRateListByPeriodAndTeam.reduce(
                (a, c) => {
                  a.onlineCount += c[`neContractOnlineCount`];
                  a.offlineCount += c[`neContractOfflineCount`];

                  return a;
                },
                {
                  ...useRateTemplate,
                  period: period.period.toString(),
                  displayPeriod: period.displayPeriod,
                  tooltipPeriod: period.tooltipPeriod,
                }
              );

              privateMethod.calcurateUseRate(useRate);

              team.useRateList.push(useRate);
            });
          });
         

          state.loadingMessage = '데이터를 분석하고 있습니다... (3/5)';
          await nextTick();

          // 기간/상위 팀 별          
          parentTeamList.forEach(parentTeam => {
            parentTeam.useRateList = [];

            periodList.forEach(period => {
              parentTeam.useRateList.push({
                ...useRateTemplate,
                period: period.period.toString(),
                displayPeriod: period.displayPeriod,
                tooltipPeriod: period.tooltipPeriod,
              });
            });
            
            const teamListByParentTeam = teamList.filter(team => team.parentTeamNo === parentTeam.teamNo);
            teamListByParentTeam.forEach(team => {
              periodList.forEach(period => {
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const t = team.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                parentTeam.useRateList[p].onlineCount += team.useRateList[t].onlineCount;
                parentTeam.useRateList[p].offlineCount += team.useRateList[t].offlineCount;

                privateMethod.calcurateUseRate(parentTeam.useRateList[p]);
              });
            });
          });

          state.loadingMessage = '데이터를 분석하고 있습니다... (4/5)';
          await nextTick();

          // 사업부 별
          divList.forEach(div => {
            div.useRateList = [];

            periodList.forEach(period => {
              div.useRateList.push({
                ...useRateTemplate,
                period: period.period.toString(),
                displayPeriod: period.displayPeriod,
                tooltipPeriod: period.tooltipPeriod,
              });
            });

            const parentTeamByDiv = parentTeamList.filter(parentTeam => parentTeam.divCd === div.divCd);

            parentTeamByDiv.forEach(parentTeam => {
              periodList.forEach(period => {
                const d = div.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                div.useRateList[d].onlineCount += parentTeam.useRateList[p].onlineCount;
                div.useRateList[d].offlineCount += parentTeam.useRateList[p].offlineCount;

                privateMethod.calcurateUseRate(div.useRateList[d]);
              });
            });
          });

          state.periodList = periodList;
          state.areaList = areaList;

          state.loadingMessage = '데이터를 분석하고 있습니다... (5/5)';
          await nextTick();
         

          state.nppPage1ParentTeamList.forEach((team,index) => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);

            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: `${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                  series: {
                      //depth: 25,
                      colorByPoint: true
                  },
                },
                legend: {//범례
                  enabled: false,                 
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter(this, team);
                  },
                },
                series: [
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                      dataLabels: {
                        ...CHART.SERIES_COLUMN.dataLabels,
                        formatter: function() {
                          return privateMethod.columnDataLabelsFormatter(this, team);
                        },
                      },
                    },
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });

         
          state.nppPage1TeamList.forEach((team,index) => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);

            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}_SUB`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}_SUB`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: `${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {//범례
                  enabled: false,                 
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter(this, team);
                  },
                },
                series: [
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                      dataLabels: {
                        ...CHART.SERIES_COLUMN.dataLabels,
                        formatter: function() {
                          return privateMethod.columnDataLabelsFormatter(this, team);
                        },
                      },
                    },
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
          state.loadingMessage = undefined;
        }
      },

      async nppPage1ColumnSize_change(e) {

        await nextTick();
        state.loadingMessage = '데이터를 필터링중 입니다.';
        state.loading = true;

        setTimeout(function(){          
          privateMethod.resizeChart(state.nppPage1ParentTeamList, 'NPP');    
          privateMethod.resizeChart(state.nppPage1TeamList, 'NPP_SUB');

          state.loading = false;
        },100); 
        
      },


      async page1TeamListSet() {
        
        state.nppPage1ParentTeamList = [
          ...divList.filter(div => div.divCd === 'OEK' && div.pageList.includes(1)),
          ...parentTeamList.filter(parentTeam => parentTeam.divCd === 'OEK'),
        ];
        
        state.nppPage1TeamList = [
          ...parentTeamList.filter(parentTeam => parentTeam.divCd === 'OEK'),
          ...teamList.filter(team => team.divCd === 'OEK' && team.pageList.includes(1)),
        ];

        state.nppTeamList = [
          ...teamList.filter(team => team.divCd === 'OEK')
        ];    
       

        await nextTick();
        privateMethod.createChart(state.nppPage1ParentTeamList, 'NPP');
        privateMethod.createChart(state.nppPage1TeamList, 'NPP_SUB');
        
       
      },
    };

    
    watch(
      state.multiValueDirect,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
       
        if(state.multiValueDirect.length == 0){
          await nextTick();
          if(state.searchData.direct == '완료'){
            state.multiValueDirect.push('송부');
          }else{
            state.multiValueDirect.push('완료');
          }          
          
        }else{
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);
        }
        state.searchData.direct = state.multiValueDirect.join();
      },
      //{deep :true}
    );

    watch(
      state.multiValueContFlag,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        if(state.searchData.contFlag == undefined){
          state.searchData.contFlag = state.multiValueContFlag.join();
          return;
        }
        if(state.multiValueContFlag.length == 0){
          await nextTick();
          
                
          checkBoxList.contFlag.forEach(contFlag => {
            if(state.searchData.contFlag != contFlag){
              state.multiValueContFlag.push(contFlag);
            }
          });
        }else{
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);
        }
        state.searchData.contFlag = state.multiValueContFlag.join();
      }
    );

    watch(
      state.multiValuePeriod,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        //state.searchData.period = state.multiValuePeriod.join();
        //if(state.searchData.period !=''){
          //console.log("aaaaaa");
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);         
        //}        
      }
    );
    
       

    onMounted(async () => {
      await nextTick();
      method.search_click();
    });

    return {
      checkBoxList,
      state,
      ...method,
    };
  },
});
</script>
<template>
  <otis-page :loading="state.loading" :loading-message="state.loadingMessage" @search-click="search_click">
    
      

    <iui-container type="css-flex" theme="bullet" >
      <i-row height="40px" >
        <i-col width="400px">
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>기간</i-col-header>
              <i-col >
                <iui-select
                  v-model="state.searchData.useRateBaseDate"
                  :items="state.codeList.useRateBaseDate"
                  item-key-alias="key"
                  item-text-alias="value"
                  item-value-alias="key"
                  placeholder="선택"
                />
                <iui-datepicker v-model="state.searchData.fromDate" suffix="~" />&nbsp;
                <iui-datepicker v-model="state.searchData.toDate" />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
        <i-col>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col-header>사업부</i-col-header>
              <i-col>
                <iui-select
                  v-model="state.searchData.parentTeamNo"
                  :items="state.areaList"
                  item-key-alias="key"
                  item-text-alias="value"
                  item-value-alias="key"
                  placeholder="전체"
                  @change="nppPage1ColumnSize_change"
                />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row height="70px" ref="searchBox">
        <i-col>
          <iui-container type="table" theme="bullet">
            <i-row>
              <i-col width="120px">
                <iui-checkbox-group v-model="state.multiValueDirect" :items="checkBoxList.direct" item-direction="column" />
              </i-col>
              <i-col-header width="40px">|</i-col-header>
              <i-col >
                <iui-checkbox-group v-model="state.multiValueContFlag" :items="checkBoxList.contFlag" item-direction="column" />
              </i-col>             
            </i-row>
            <i-row>            
              <i-col width="120px">
                <span style="font-weight: bold;">OEK Period</span>
              </i-col>
              <i-col-header width="40px">|</i-col-header>  
              <i-col>               
                <iui-checkbox-group v-model="state.multiValuePeriod" :items="checkBoxList.period" item-direction="column" />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-row >
        <i-col>
          <iui-content-box>
            <template #title-right>
              <iui-select
                v-model="state.nppPage1ColumnSize"
                :items="state.codeList.columnSizeList"
                item-key-alias="key"
                item-text-alias="value"
                item-value-alias="key"
                label=" 한 줄 표시:"
                @change="nppPage1ColumnSize_change"
              />
            </template>
            <iui-container type="css-flex" no-border>
              <i-row style="flex-wrap: wrap;" v-show="state.searchData.parentTeamNo == undefined || state.searchData.parentTeamNo == ''">
                <i-col v-for="team in state.nppPage1ParentTeamList" :key="team.teamNo" :width="state.nppPage1ColumnWidth">
                  <div :id="`divChart_${team.divCd}_1_${team.teamNo}_NPP`" ></div>
                </i-col>
              </i-row>
              <i-row style="flex-wrap: wrap;" v-show="state.searchData.parentTeamNo != undefined && state.searchData.parentTeamNo != ''">
                <i-col v-for="team in state.nppPage1TeamList" :key="team.teamNo" :width="state.nppPage1ColumnWidth" v-show="state.searchData.parentTeamNo == team.parentTeamNo">
                  <div :id="`divChart_${team.divCd}_1_${team.teamNo}_NPP_SUB`" ></div>
                </i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-col>
      </i-row>
    </iui-container>


  </otis-page>
</template>

<style scoped>
/* .iui-container,
.iui-container .row,
.iui-container .col {
  border: 1px solid gray;
  padding: 5px;
} */
</style>

<template>
  <pmis-content-box>
    <template v-slot:title>문서관리정보</template>
    <iui-container type="table" theme="bullet">
      <i-row>
        <i-col-header>슬로건</i-col-header>
        <i-col colspan="5">
          <iui-text
            max-length="66"
            :value="companyInfo.slogan"
            @change="setCompanyInfo({slogan: $event.target.value})"
            :enable="enable"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="200px">
        <i-spacer />
        <i-col width="220px" style="border: 1px solid #ccc">
          <pmis-image-file
            title="관인파일"
            :allowAdd="enable"
            :allowDelete="enable"
            :saveButtonVisible="enable"
            :company-code="companyInfo.pgmCd || pgmCd"
            :file-number.sync="lgFlNo"
            :save.sync="imageSave1"
            @save-complete="setLgFlNo"
          />
        </i-col>
        <i-spacer />
        <i-col width="220px" style="border: 1px solid #ccc">
          <pmis-image-file
            title="회사로고"
            :allowAdd="enable"
            :allowDelete="enable"
            :saveButtonVisible="enable"
            :company-code="companyInfo.pgmCd || pgmCd"
            :file-number.sync="djFlNo"
            :save.sync="imageSave2"
            @save-complete="setDjFlNo"
          />
        </i-col>
        <i-spacer />
        <i-col width="220px" style="border: 1px solid #ccc">
          <pmis-image-file
            title="회사심볼"
            :allowAdd="enable"
            :allowDelete="enable"
            :saveButtonVisible="enable"
            :company-code="companyInfo.pgmCd || pgmCd"
            :file-number.sync="smFlNo"
            :save.sync="imageSave3"
            @save-complete="setSmFlNo"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row height="150px">
        <i-col colspan="6">
          <pmis-content-box>
            <template v-slot:title>발신명의</template>
            <template v-slot:title-right>
              <iui-button class="btn" value="신규" @click="onAdd" v-show="enable" />
              <iui-button class="btn" value="저장" @click="onSave" v-if="companyInfo.pgmCd" v-show="enable" />
              <iui-button class="btn" value="삭제" @click="onDelete" v-show="enable" />
            </template>
            <iui-container type="ibsheet" :id="elId" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    imageSave: {
      type: Boolean,
      default: false,
    },
    pgmCd: {
      type: String,
    },
  },

  watch: {
    companyInfo: {
      handler(val) {
        if (val.lgFlNo) this.lgFlNo = val.lgFlNo;
        if (val.djFlNo) this.djFlNo = val.djFlNo;
        if (val.smFlNo) this.smFlNo = val.smFlNo;
      },
      deep: true,
    },
    imageSave: {
      handler(val) {
        this.imageSave1 = val;
      },
    },
    imageSave1: {
      handler(val) {
        if (!val) {
          this.setFileFlag({lgFl: true});
          this.imageSave2 = true;
        }
      },
    },
    imageSave2: {
      handler(val) {
        if (!val) {
          this.setFileFlag({djFl: true});
          this.imageSave3 = true;
        }
      },
    },
    imageSave3: {
      handler(val) {
        if (!val) this.setFileFlag({smFl: true});
      },
    },
  },

  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      sheetId: 'sheet' + this._uid,
      lgFlNo: 0,
      djFlNo: 0,
      smFlNo: 0,
      imageSave1: false,
      imageSave2: false,
      imageSave3: false,
      enable: ['regist', 'base', 'customer'].indexOf(this.$store.getters['mdi/currentUrls'].para) == -1,
    };
  },

  beforeCreate() {
    $mapGetters(this, ['companyInfo']);
    $mapMutations(this, ['setCompanyInfo', 'setFileFlag']);
  },

  created() {
    this.addEvent([{name: 'docManageInfo_getSaveJson', func: this.getSaveJson}]);

    this.unwatch = this.$store.watch(
      (state, getters) => getters[`${this.$store.getters.getStoreNm}/docnmList`],
      value => {
        if (value && value.length) {
          let load = setInterval(() => {
            if (this.sheet) {
              clearInterval(load);
              this.sheet.loadSearchData(value);
            }
          }, 10);
        } else {
          if (this.sheet) {
            this.sheet.removeAll();
          }
        }
      }
    );
  },

  mounted() {
    this.initSheet();
  },

  methods: {
    initSheet() {
      const OPT = {
        Cfg: {
          SearchMode: 2, // 2 : Lazy Load 모드 (트리나 그룹 기능 사용시 적합) (default)
          CanEdit: 1, // 1: 전체 편집 가능 (default)
          ReqStatusName: 'status', // 서버로 전송될 상태 명 (default:STATUS)
          IgnoreFocused: 1,
        },
        Cols: [
          {
            Header: '선택',
            Name: 'cbx',
            Type: 'Bool',
            BoolIcon: 0,
            Align: 'center',
            Width: 50,
            CanEdit: true,
            Visible: this.enable,
          },
          {
            Header: '순번',
            Name: 'seq',
            Type: 'Text',
            Width: 50,
            CanEdit: false,
          },
          {
            Header: '발신명의',
            Name: 'docNm',
            Type: 'Text',
            RelWidth: 1,
            CanEdit: this.enable,
          },
        ],
      };

      this.sheet = IBSheet.create({
        id: this.sheetId, // 시트 객체 ID
        el: this.elId, // 시트를 생성할 DIV객체 ID
        options: OPT, // 초기화 구문 변수
      });

      // this.sheet.bind('onClick', function (e) {
      // 	if (e.row.Kind == 'Data') {

      // 	}
      // });
    },

    onAdd() {
      this.sheet.addRow();
    },

    async onSave() {
      if (this.sheet.getTotalRowCount() == 0) {
        this.$alert('저장할 발신명의가 존재하지 않습니다.');
        return;
      }
      if (!this.sheet.hasChangedData()) {
        this.$alert('수정된 내용이 없습니다.');
        return;
      }

      let saveJson = this.sheet.getSaveJson().data;
      saveJson.forEach(el => {
        if (!el.pgmCd) el.pgmCd = this.companyInfo.pgmCd;
      });

      if (await this.$confirm('저장하시겠습니까?')) {
        axios
          .post('/companyRegist/saveDocnmList', {docnmList: $_statusToCud(saveJson)})
          .then(response => {
            if (response.status == 200) {
              this.onSearchDocnmList(this.companyInfo.pgmCd);
              this.$alert('저장을 완료하였습니다.');
            } else {
              this.$alert('저장을 실패하였습니다.');
            }
          })
          .catch(ex => {
            console.log('저장을 실패하였습니다. : ', ex);
          });
      }
    },
    onDelete() {
      let checkedRows = this.sheet.getRowsByChecked('cbx');

      if (0 == checkedRows.length) {
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      checkedRows.forEach(row => {
        if (row.pgmCd) {
          this.sheet.deleteRow(row);
        } else {
          this.sheet.removeRow(row);
        }
      });
    },
    setLgFlNo() {
      if (this.lgFlNo) {
        this.setCompanyInfo({lgFlNo: this.lgFlNo});
      }
    },
    setDjFlNo() {
      if (this.djFlNo) {
        this.setCompanyInfo({djFlNo: this.djFlNo});
      }
    },
    setSmFlNo() {
      if (this.smFlNo) {
        this.setCompanyInfo({smFlNo: this.smFlNo});
      }
    },
    getSaveJson(callback) {
      if (typeof callback == 'function') callback(this.sheet.getSaveJson().data);
    },

    onSearchDocnmList(pgmCd) {
      axios.post('/companyRegist/selectDocnmList', {pgmCd: pgmCd}).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
  }, //methods

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  },
};
</script>

<style></style>

<script>
import {defineComponent, reactive, nextTick, computed, watch, onMounted} from '@vue/composition-api';
import {BaseProps, useBase} from '@/components/Iui/composables/useBase';
import {LayoutProps, useLayout} from '@/components/Iui/composables/useLayout';
import {LabelProps, useLabel} from '@/components/Iui/composables/useLabel';
import {PrefixProps, usePrefix} from '@/components/Iui/composables/usePrefix';
import {SuffixProps, useSuffix} from '@/components/Iui/composables/useSuffix';

export default defineComponent({
  name: 'IuiCheckbox',
  model: {
    prop: 'model-value',
    event: 'update:model-value',
  },
  props: {
    ...BaseProps,
    ...LayoutProps,
    ...LabelProps,
    ...PrefixProps,
    ...SuffixProps,

    modelValue: {
      type: Array,
      default: () => [],
      required: true,
    },
    groupName: {
      type: String,
      default: undefined,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: 'on',
    },
  },
  emits: ['change', 'update:checked', 'update:model-value'],
  setup(props, context) {
    const Base = useBase(props, context, 'iui-checkbox');
    const Layout = useLayout(props, context, 'iui-checkbox');
    const Label = useLabel(props, context, Base);
    const Prefix = usePrefix(props, context, Base);
    const Suffix = useSuffix(props, context, Base);

    watch(
      () => props.modelValue,
      newValue => {
        state.modelValue = newValue;
        const newChecked = state.modelValue.indexOf(props.value) !== -1;

        if (state.checked !== newChecked) {
          // console.log(Base.state.$iuiId, 'watch -> modelValue', 'emit -> update:checked', newChecked);

          state.checked = newChecked;
          context.emit('update:checked', newChecked);
        }
      }
    );

    watch(
      () => props.checked,
      (newValue, oldValue) => {
        if (newValue !== oldValue && state.checked !== newValue) {
          state.checked = newValue;

          const index = state.modelValue.indexOf(props.value);

          if (state.checked && index === -1) {
            // console.log(Base.state.$iuiId, 'watch -> checked', 'emit -> update:model-value', props.value);

            state.modelValue.push(props.value);
            context.emit('update:model-value', state.modelValue);
          } else if (newValue === false && oldValue === true && index !== -1) {
            // console.log(Base.state.$iuiId, 'watch -> checked', 'emit -> update:model-value', undefined);

            state.modelValue.splice(state.modelValue.indexOf(props.value), 1);
            context.emit('update:model-value', state.modelValue);
          }
        }
      }
    );

    onMounted(() => {
      const index = state.modelValue.indexOf(props.value);

      if (state.checked !== (index !== -1)) {
        state.checked = index !== -1;
        context.emit('update:checked', state.checked);
      }

      // if (state.checked && state.modelValue !== props.value) {
      //   state.modelValue = [props.value];
      //   context.emit('update:model-value', state.modelValue);
      // }
    });

    const state = reactive({
      modelValue: props.modelValue,
      groupName: props.groupName === undefined ? Base.state.id : props.groupName,
      checked: props.checked,

      id: computed(() => Base.getId('checkbox')),
      class: computed(() => {
        return {
          checkbox: true,
        };
      }),
      style: computed(() => {
        return {};
      }),
    });

    // method
    const method = {
      async checkbox_change(e) {
        state.checked = e.target.checked;
        context.emit('update:checked', e.target.checked);

        const index = state.modelValue.indexOf(props.value);

        if (state.checked && index === -1) {
          state.modelValue.push(props.value);
          context.emit('update:model-value', state.modelValue);
        } else if (state.checked === false && index !== -1) {
          state.modelValue.splice(state.modelValue.indexOf(props.value), 1);
          context.emit('update:model-value', state.modelValue);
        }

        // 같은 group-name 의 checkbox 의 update:* 이벤트가 모두 emit된 뒤 change 이벤트를 emit하기 위해 nextTick 이후 처리
        await nextTick();
        context.emit('change', e);
      },
    };

    return {
      Base,
      Layout,
      Label,
      Prefix,
      Suffix,
      state,
      ...method,
    };
  },
});
</script>

<template>
  <div v-show="Base.state.visible" :class="Layout.state.class" :style="Layout.state.style">
    <label
      v-if="Prefix.state.has"
      :id="Prefix.state.id"
      ref="prefix"
      :for="state.id"
      class="prefix"
      :style="Prefix.state.style"
    >
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </label>
    <input
      :id="state.id"
      ref="checkbox"
      :name="state.groupName"
      type="checkbox"
      :class="state.class"
      :style="state.style"
      :readonly="Base.state.readonly"
      :disabled="!Base.state.enable"
      :checked="state.checked"
      :value="value"
      @change="checkbox_change"
    />
    <label
      v-if="Suffix.state.has"
      :id="Suffix.state.id"
      ref="suffix"
      :for="state.id"
      class="suffix"
      :style="Suffix.state.style"
    >
      <slot name="suffix">
        {{ suffix }}
      </slot>
    </label>
    <label
      v-if="Label.state.has"
      :id="Label.state.id"
      ref="label"
      :for="state.id"
      class="label"
      :style="Label.state.style"
    >
      <span></span>
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<style></style>

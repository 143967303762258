<template>
  <pmis-page
    ref="page"
    @search-click="onSearch"
    @search-project-changed="onPrjChange"
    @save-click="onSave"
    @new-click="onNew"
  >
    <iui-container type="css-flex">
      <i-row>
        <i-col>
          <page1 />
        </i-col>
      </i-row>
      <i-row>
        <i-col>
          <page2 />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import page1 from './components/page1';
import page2 from './components/page2';
import store from './store/testpage.js';
export default {
  components: {
    page1,
    page2,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, {searchInfo: 'searchInfo', detailInfo: 'detailInfo'});
    $mapMutations(this, ['setSearchInfo', 'initDetailInfo']);
  },
  created() {},
  methods: {
    onSearch() {
      this.callEvent({
        name: 'selectSample2',
      });
    },

    testb(data) {
      alert(`index data ::: ${data}`);
    },
    onPrjChange(e) {
      console.log(e);
      this.setSearchInfo(e);
    },
    onSave() {
      axios.post('/sample2/insertSample2', this.detailInfo).then(response => {
        if (response.status == 200) {
          this.$alert('입력완료', () => {});
        }
      });

      //    let response = await axios.post('/sample2/insertSample2', detailInfo);
    },
    onNew() {
      this.initDetailInfo();
    },
  },
};
</script>

<style></style>

<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col>
        <pmis-content-box>
          <template v-slot:title>비밀번호 변경</template>
          <iui-container type="table">
            <colgroup>
              <col width="150px" />
              <col />
            </colgroup>
            <i-row>
              <i-col-header>아이디(거래선번호)</i-col-header>
              <i-col>
                <iui-text name="userId" :value="searchData.userId" :readonly="true"></iui-text>
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>기존 비밀번호</i-col-header>
              <i-col>
                <input type="password" style="width:100%" ref="oldPwd" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>변경할 비밀번호</i-col-header>
              <i-col>
                <input type="password" style="width:100%" ref="newPwd" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>비밀번호 확인</i-col-header>
              <i-col>
                <input type="password" style="width:100%" ref="newPwdConfirm" />
              </i-col>
            </i-row>
          </iui-container>

          <i-row v-if="!identifyComplete" style="justify-content: center; margin-top:20px;">
            <iui-button style="padding: 0 10px 0 10px;" @click="onChangePwd">비밀번호 변경</iui-button>
          </i-row>
        </pmis-content-box>
      </i-col>
      <i-col v-if="identifyComplete">
        <ResetUserPw
          :userId="searchData.searchId"
          :identifyComplete="identifyComplete"
          :fromIdFind="searchData.fromIdFind"
        />
      </i-col>
    </i-row>
  </iui-container>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      searchData: {
        userId: '',
      },
    };
  },
  mounted() {
    this.searchData.userId = this.userInfo.tradeNo;
  },
  methods: {
    async onChangePwd() {
      // param값 set
      let oldPwd = this.$refs.oldPwd.value;
      let newPwd = this.$refs.newPwd.value;
      let newPwdConfirm = this.$refs.newPwdConfirm.value;

      // 영문/숫자/특문 정규식
      var engExp = /[a-zA-z]/gi;
      var numberExp = /[0-9]/gi;
      var specialExp = /[{}[\]/?.,;:|)*~`!^\-_+┼<>@#$%&'"\\(=]/gi;

      if (oldPwd == '') {
        this.$alert('기존 비밀번호를 입력해주세요.');
        return;
      }

      if (newPwd == '') {
        this.$alert('사용하실 새 비밀번호를 입력해주세요.');
        return;
      }
      if (newPwdConfirm == '') {
        this.$alert('새 비밀번호 확인을 입력해주세요.');
        return;
      }
      if (newPwd != newPwdConfirm) {
        this.$alert('입력하신 비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해주세요.');
        return;
      }
      var isEngCase = false;
      var isNumberCase = false;
      var isSpecialCase = false;

      // 새비밀번호 <-> 새비밀번호확인 문자열이 일치하기 때문에 하나만 검사
      if (engExp.test(newPwd)) {
        isEngCase = true;
      }
      if (numberExp.test(newPwd)) {
        isNumberCase = true;
      }
      if (specialExp.test(newPwd)) {
        isSpecialCase = true;
      }

      if (!(isEngCase && isNumberCase)) {
        this.$alert('영어와 숫자를 포함하여 비밀번호를 입력해주세요.');
        return;
      }

      if (isSpecialCase) {
        if (newPwd.length < 8) {
          this.$alert('특수문자 포함시 비밀번호는 8자 이상 16자 이하로 입력해주세요.');
          return;
        }
      } else {
        if (newPwd.length < 10) {
          this.$alert('특수문자 미포함시 비밀번호는 10자 이상 16자 이하로 입력해주세요.');
          return;
        }
      }

      const response = await axios.post('/summary/checkAndChangePw', {oldPwd: oldPwd, newPwd: newPwd});
      console.log(response);
      if (response.data == 'OK') {
        await this.$alert('비밀번호를 변경하였습니다.');

        this.$modal.hide('pwEdit');
      } else {
        this.$alert(response.data);
      }
    },
  },
};
</script>

<style></style>

<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <i-row>
        <i-col-header>파일 첨부</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-show="!fileUpDown_files.lclFlNm">추가</iui-button>
          <iui-button @click="fileUpDown_delete = true" v-show="fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >삭제</iui-button
          >
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.svrFlPth"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            accept="application/pdf"
            v-model="fileUpDown_files"
            :file-number.sync="attach.fileNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
            @delete-start="file_delete_start"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
export default {
  components: {
    FileUpDown,
  },
  beforeCreate() {
    $mapGetters(this, ['modalAttach']);
  },
  created() {
    this.addEvent({name: 'AttachFile_Save', func: this.onSave});
    this.addEvent({name: 'AttachFile_Delete', func: this.onDelete});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      callback: '',

      readonly: false,

      attach: {
        cnfrmnNo: '',
        photoSn: 0,
        fileNo: 0,
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,
    };
  },
  mounted() {
    this.attach.cnfrmnNo = this.modalAttach.cnfrmnNo;
    this.attach.photoSn = this.modalAttach.photoSn;
    this.attach.fileNo = this.modalAttach.fileNo;
    this.readonly = this.modalAttach.readonly;
  },
  methods: {
    async onSave(callback) {
      // if (this.readonly) {
      //   this.$alert('읽기모드로 조회 중입니다.');
      //   return;
      // }

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.$alert('파일을 첨부해야 합니다.');
        return;
      }

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.attach,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          let response = await axios.post('/confdoc/constcmpl/updateAttachFile', param);
          console.log(response);
          this.listBoxLoading = false;
          callback(this.attach.fileNo);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onDelete(callback) {
      console.log('callback', callback);
      // if (this.readonly) {
      //   this.$alert('읽기모드로 조회 중입니다.');
      //   return;
      // }

      //if (!(await this.$confirm('삭제하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;

        this.attach.fileNo = 0;

        let param = {
          ...this.attach,
          pgmCd: this.userInfo.pgmCd,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        let response = await axios.post('/confdoc/constcmpl/deleteAttachFile', param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(this.attach.fileNo);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.attach.fileNo = 0;
      } else {
        this.attach.fileNo = this.fileUpDown_files.flNo;
      }

      let param = {
        ...this.attach,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      try {
        let response = await axios.post('/confdoc/constcmpl/updateAttachFile', param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(this.attach.fileNo);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    file_save_error() {
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      this.listBoxLoading = false;
      console.log('파일 삭제가 완료되었습니다.');
      //20211208 파일삭제 백그라운드 리로드
      this.fileUpDown_files.lclFlNm = '';
      console.log('this.modalAttach.attachFileModalId', this.modalAttach);
      $('#fileDelete_' + this.modalAttach.attachFileModalId).trigger('click'); //onDeleteFile 호출
    },
    file_delete_error() {
      this.listBoxLoading = false;
      console.log('파일 삭제를 실패했습니다.');
    },
    file_delete_start() {
      this.listBoxLoading = true;
      console.log('파일 삭제 시작');
    },
  },
};
</script>

<style></style>

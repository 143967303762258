<template>
  <!-- <div class="contents" style="height:90%">
  <div class="contents"> -->
  <iui-container type="css-flex" theme="bullet">
    <i-row v-if="isOContract">
      <i-col>
        <OContractView />
      </i-col>
    </i-row>
    <i-row v-if="isOffcialDocument">
      <i-col>
        <OffcialDocumentView />
      </i-col>
    </i-row>
    <i-row v-if="isTContract">
      <i-col>
        <TContractView />
      </i-col>
    </i-row>
    <i-row v-if="isChangeContract">
      <i-col>
        <ChangeContractView />
      </i-col>
    </i-row>
    <i-row v-if="isEstimate">
      <i-col>
        <EstimateView />
      </i-col>
    </i-row>
    <i-row v-if="isCms">
      <i-col>
        <CmsView />
      </i-col>
    </i-row>
  </iui-container>
  <!-- <div class="btn_area">
      <button type="button" class="btn_default" @click="onLogin">로그인</button>
    </div>
  </div> -->
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import LinkViewStore from './store/LinkView.js';
import OContractStore from './oContract/store/oContract.js';
import TContractStore from './tContract/store/tContract.js';
import EstimateStore from './tContract/store/estimate.js';

import CmsStore from './oContract/store/cms.js';

import OContractView from '@/view/contract/components/linkview/OContractView.vue';
import OffcialDocumentView from '@/view/contract/components/linkview/OffcialDocumentView.vue';
import TContractView from '@/view/contract/components/linkview/TContractView.vue';
import ChangeContractView from '@/view/contract/components/linkview/ChangeContractView.vue';
import EstimateView from '@/view/contract/components/linkview/EstimateView.vue';

import CmsView from '@/view/contract/components/linkview/CmsView.vue';

export default {
  name: 'LinkView',
  components: {
    // eslint-disable-next-line
    OContractView,
    // eslint-disable-next-line
    OffcialDocumentView,
    // eslint-disable-next-line
    TContractView,
    // eslint-disable-next-line
    ChangeContractView,
    // eslint-disable-next-line
    EstimateView,
    // eslint-disable-next-line
    CmsView,
  },
  beforeCreate() {
    this.$store.dispatch('setStoreNm', 'LinkViewStore');
    $init(this, LinkViewStore);
    this.$store.dispatch('setStoreNm', 'OContractStore');
    $init(this, OContractStore);
    this.$store.dispatch('setStoreNm', 'TContractStore');
    $init(this, TContractStore);
    this.$store.dispatch('setStoreNm', 'EstimateStore');
    $init(this, EstimateStore);
    this.$store.dispatch('setStoreNm', 'CmsStore');
    $init(this, CmsStore);
  },
  data() {
    return {};
  },
  mounted() {
    this.checkAccessDeviceType();
  },
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapMutations('LinkViewStore', {
      setDecParams: 'setDecParams',
    }),
    async checkAccessDeviceType() {
      console.log('###############');
      console.log(window.params);

      let param = {
        encParam: window.params.encParam,
      };

      let response = await axios.post('/urlLink/decAndParseParam', param);
      console.log(response);
      console.log('###############');

      this.setDecParams(response.data);

      console.log('decParams', this.decParams);

      // TODO : 대리인 동의 테스트 후 주석 또는 삭제
      // this.decParams.isBuyagnt = true;

      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      if (isIE) {
        this.$alert('현재 인터넷 익스플로어(IE)로 접속하셨습니다.<br/>크롬(Chrome)브라우저 사용을 권고 드립니다.');
      }

      if (this.isOContract) {
        console.log('isOContract');
        this.callEvent({name: 'OContract_Search', param: undefined});
      } else if (this.isOffcialDocument) {
        console.log('isOffcialDocument');
        this.callEvent({name: 'OffcialDocument_Search', param: undefined});
      } else if (this.isTContract) {
        console.log('isTContract');
        this.callEvent({name: 'TContract_Search', param: undefined});
      } else if (this.isChangeContract) {
        console.log('isChangeContract');
        this.callEvent({name: 'ChangeContract_Search', param: undefined});
      } else if (this.isEstimate) {
        console.log('isEstimate');
        this.callEvent({name: 'Estimate_Search', param: undefined});
      } else if (this.isCms) {
        console.log('isCms');
        this.callEvent({name: 'Cms_Search', param: undefined}); 
      }
    },

    onLogin() {
      this.setPage('Login');
    },
  },
  computed: {
    ...mapGetters('LinkViewStore', {
      decParams: 'decParams',
    }),
    isOContract() {
      return window.params.shortUrlLinkType == 'OC_V';
    },
    isOffcialDocument() {
      return window.params.shortUrlLinkType == 'OD_V';
    },
    isTContract() {
      return window.params.shortUrlLinkType == 'TC_V';
    },
    isChangeContract() {
      return window.params.shortUrlLinkType == 'TCA_V';
    },
    isEstimate() {
      return window.params.shortUrlLinkType == 'EST_V';
    },
    isCms() {
      return window.params.shortUrlLinkType == 'CMS_V';
    },
  },
};
</script>

<style scoped></style>

<template>
  <div style="width: 100%;">
    <ul class="tbl_taps">
      <li
        v-for="(c, i) in comps"
        :key="i"
        @click="tabClick(i)"
        class="cursor_pointer"
        :class="[activetab === i ? 'tbl_selected' : '']"
      >
        <a href="#">{{ c.tabNm }}</a>
      </li>
    </ul>
    <div
      v-if="lazeLoad"
      :class="{[mode == 1 ? 'tab_h' : 'tab_cont']: true, grid_box_line: true}"
      style="width: 100%; height: calc(100% - 38px); overflow-y: auto;"
    >
      <keep-alive v-if="keepAlive">
        <component :is="comp"></component>
      </keep-alive>
      <component :is="comp" v-else></component>
    </div>
    <div
      v-else
      :class="{[mode == 1 ? 'tab_h' : 'tab_cont']: true, grid_box_line: true}"
      style="width: 100%; height: calc(100% - 38px); overflow-y: auto;"
    >
      <div v-for="(c, i) in comps" :key="i" :class="{show: activetab === i}">
        <component :is="c.name"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comps: {
      type: Array,
    },
    lazeLoad: {
      type: Boolean,
      default: true,
    },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '1',
    },
    isAuto: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      activetab: 0,
      comp: '',
      activeCls: this.mode == '1' ? 'on ' : 'open',
    };
  },
  watch: {
    activetab: function(active) {
      this.comp = this.comps[active].compNm;
      // this.$emit('click', active);
    },
  },
  created: function() {
    const me = this;
    me.comp = me.comps[0].compNm;
    for (let c of me.comps) {
      me.addComponent(c.compNm, c.path);
    }

    me.addEvent([
      {name: 'currentIndex', func: me.currentIndex},
      {name: 'setActiveTab', func: me.setActiveTab},
    ]);
  },
  mounted() {},
  methods: {
    currentIndex(param) {
      let index = Array.from(this.$el.querySelectorAll('.tab > a')).findIndex(a => a.classList.contains('on'));

      if (typeof param == 'function') {
        param(index);
      }
    },
    tabClick(index) {
      this.$emit('click', index);
      if (this.isAuto) {
        this.setActiveTab(index);
      }
    },
    setActiveTab(index) {
      this.activetab = index;
    },
  },
};
</script>

<style scoped>
.tab_container {
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}
</style>

<template>
  <iui-container type="table" theme="none">
    <i-row>
      <i-col>
        <pmis-content-box :loading="listBoxLoading">
          <iui-container type="jqgrid" :id="sheetId1" style="height:150px; width:99.9%;" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['partList']);
  },
  created() {
    this.addEvent({name: 'Part_Parts_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'itemNo', label: '품목번호', width: 100, align: 'center'},
        {name: 'itemNm', label: '품명', width: 100, align: 'left'},
        {name: 'stnd', label: '규격', width: 100, align: 'left'},
        {name: 'unit', label: '단위', width: 50, align: 'left'},
        {
          name: 'qty',
          label: '수량',
          width: 50,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'cost',
          label: '단가',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {
          name: 'sumAmt',
          label: '금액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          summaryType: 'sum',
          index: 'sumAmt',
        },
      ],
    };

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 120,
      // width:800,
      colModel: OPT.Cols,
      selectionMode: 'singlerow',
      rownumbers: true,
      // footerrow: true,
      // userDataOnFooter : true,
      // loadComplete : function(){
      //   var sumAmtSum = $("#"+me.sheetId1).jqGrid('getCol','sumAmt', false, 'sum');
      //   $("#"+me.sheetId1).jqGrid('footerData', 'set', { sumAmt:sumAmtSum });
      //   $('table.ui-jqgrid-ftable td:eq(0)').text("");
      //   $('table.ui-jqgrid-ftable td:eq(1)').text("합계");
      // },
    });

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      /*
      var $grid = $("#"+this.sheetId1);
      console.log('$grid.id', $grid);
      var pobj = $grid.closest(".ui-jqgrid").parent();
      var newWidth = pobj.width();
      $grid.jqGrid("setGridWidth", newWidth, true);
      console.log("this.sheetId1aa",this.sheetId1);
      var bottomRightObj = $grid.closest(".outer-content-box").parent().parent().parent().parent().parent().parent();
      console.log('Parts bottomRightObj.height()', bottomRightObj.height());
      $grid.jqGrid("setGridHeight", bottomRightObj.height()-100, false);
      */
      //20211110 lgksja 아래걸로 사이즈 조절 변경
      /*
      var $grid2 = $("table .ui-jqgrid-btable").eq(1);
      var pobj2 = $grid2.closest(".ui-jqgrid").parent();
      var _height = pobj2.height();
      //console.log("pobj2.height()",pobj2.height());
      var $grid = $("#"+this.sheetId1);
      var pobj = $grid.closest(".ui-jqgrid").parent();
      var newWidth2 = pobj.width();
      $grid.jqGrid().setGridWidth(newWidth2);
      var newWidth = pobj.width();
      $grid.jqGrid("setGridWidth", newWidth-20, true);
      $grid.jqGrid("setGridHeight", _height-44, false);
      setTimeout(() => {
        $grid.jqGrid().setGridWidth(newWidth);
      }, 100);
      setTimeout(() => {
        $grid.jqGrid("setGridHeight", _height-44, false);
      }, 1000);
      */
      $('#gbox_' + this.sheetId1)
        .parent()
        .css('height', '100%');
      var $grid = $('#' + this.sheetId1);
      /*      
      var pobj = $grid.closest(".ui-jqgrid").parent();
      var newWidth = pobj.width();      
      $grid.jqGrid("setGridWidth", newWidth, true);
      */
      $gridWidthResize($grid);
      var bottomLeftObj = $grid
        .parents('.pmis-page')
        .find('.spacer')
        .eq(0)
        .next();
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 77, false);
    },
    onLoad() {
      console.log('Part_Parts_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      //this.onResize();
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.partList})
        .trigger('reloadGrid');
      setTimeout(() => {
        this.onResize();
      }, 100);
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

/**
 * IuiBase
 * @module components/Iui/IuiLayoutMixin
 * @vue-prop {string} id
 * @vue-prop {Object} css
 * @vue-prop {Array} event
 * @vue-data {string} $_id
 */
export default {
  props: {
    // id: {
    //   type: String,
    // },
    name: {
      type: String,
      default: undefined,
    },

    // enable, readonly, visible
    enable: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },

    // TODO: 제거 css, event, bindArray
    css: {
      type: Object,
      default: undefined,
      validator(value) {
        return value === undefined;
      },
    },
    event: {
      type: Array,
      default() {
        return [];
      },
      // validator(value) {
      //   return value.length === 0;
      // },
    },
    bindArray: {
      type: Array,
      default() {
        return [];
      },
      // validator(value) {
      //   return value.length === 0;
      // },
    },
  },
  data: function() {
    return {
      $_id: null,
    };
  },
  /**
   * created 라이프사이클 이벤트
   */
  created() {
    this.$_id = this.id || this.generateNewId();
  },
  mounted() {
    /**
     * 제거될 속성 사용 시 경고 문구 출력
     */
    let hasWarning = false;

    if (this.isEditable !== undefined && !this.isEditable) {
      hasWarning = true;
      console.error(`[${
        this.$vnode.componentOptions.tag
      }] isEditable 속성은 더 이상 사용되지 않습니다. enable, readonly를 사용하십시오. 아래 isEditable을 사용 중인 DOMElement가 출력됩니다.
enable - 컨트롤 비활성화
readonly - 컨트롤 읽기전용
`);
    }
    if (this.$attrs.hasOwnProperty('disabled')) {
      hasWarning = true;
      console.error(`[${
        this.$vnode.componentOptions.tag
      }] disabled 속성은 더 이상 사용되지 않습니다. enable, readonly를 사용하십시오. 아래 disabled을 사용 중인 DOMElement가 출력됩니다.
enable - 컨트롤 비활성화
readonly - 컨트롤 읽기전용
`);
    }

    if (hasWarning) {
      console.log(this.$el);
    }
  },
  methods: {
    /**
     * 새로운 ID를 생성합니다.
     */
    generateNewId() {
      return `iui_${Math.random()
        .toString(16)
        .substr(2)}`;
    },

    /**
     * suffixID 해당하는 ID를 가져옵니다.
     * @param {string} suffixID
     */
    getId(suffixID) {
      return typeof suffixID === 'string' && suffixID.length > 0 ? `${this.$_id}_${suffixID}` : `${this.$_id}`;
    },
  },
};

<template>
  <!--pmis-page :search-company-visible="false" :search-project-visible="false"-->
  <pmis-content-box>
    <iui-container type="css-flex">
      <i-row height="40%">
        <i-col>
          <pmis-content-box>
            <template v-slot:title> ★ 전자 계약서 테스트 (JSON Parameter Type))★ </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header width="30%">계약번호</i-col-header>
                <i-col>
                  <iui-text name="checkContNo" :value.sync="inputData.checkContNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>계약차수</i-col-header>
                <i-col>
                  <iui-text name="checkSeq" :value.sync="inputData.checkSeq" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>리포트관리번호</i-col-header>
                <i-col>
                  <iui-text name="manageCd" :value.sync="inputData.manageCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>조회속성(읽기/쓰기(서명))</i-col-header>
                <i-col>
                  <iui-text name="signable" :value.sync="inputData.signable" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명방식구분(10:공동,20:모바일인증서,30:전자서명)</i-col-header>
                <i-col>
                  <iui-text name="signMthCd" :value.sync="inputData.signMthCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명자구분코드(10:고객,20:대리인,30:OSSEP)</i-col-header>
                <i-col>
                  <iui-text name="signerSeCd" :value.sync="inputData.signerSeCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>거래처코드(서명할거래처코드)</i-col-header>
                <i-col>
                  <iui-text name="tradeNo" :value.sync="inputData.tradeNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>*OKSS 서브타이틀</i-col-header>
                <i-col>
                  <iui-text name="subTitle" :value.sync="inputData.subTitle" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>*OKSS 발주/견적서 사각직인출력여부</i-col-header>
                <i-col>
                  <iui-text name="useStemp" :value.sync="inputData.useStemp" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-button @click="onReportView" style="button small blue"> 계약서 보기 </iui-button>
                  <iui-button @click="onMakeOzPdf" style="button small blue"> 계약서 pdf 생성 </iui-button>
                </i-col>
              </i-row>
            </iui-container>
            <!--div style="width:100%; height:10%">
              <iui-button @click="onReportView"> 계약서 보기 </iui-button>
              &nbsp;
              <iui-button @click="onMakeOzPdf"> 계약서 pdf 생성 </iui-button>
            </div-->
          </pmis-content-box>
        </i-col>
      </i-row>
      <i-row height="40%">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>★ 작업/공사확인서 테스트 (Query Type) ★ </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header width="20%"><div style="color:red;font-weight:bold ;">★ 확인서번호</div></i-col-header>
                <i-col>
                  <iui-text name="cnfrmnNo" :value.sync="inputQry.cnfrmnNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header
                  ><div style="color:red;font-weight:bold ;">★ 서명가능여부(보기:N,서명:Y))</div></i-col-header
                >
                <i-col>
                  <iui-text name="signable" :value.sync="inputQry.signable" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header><div style="color:red;font-weight:bold ;">★ UserId</div></i-col-header>
                <i-col>
                  <iui-text name="userId" :value.sync="this.userInfo.empNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-button @click="onReportViewQueryType" style="button small blue">
                    작업/공사 확인서 보기
                  </iui-button>
                  <iui-button @click="onMakeOzPdfConfdoc" style="button small blue"> 확인서 pdf 생성 </iui-button>
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>

      <i-row height="20%">
        <i-col>
          <pmis-content-box>
            <template v-slot:title>★ 2차 쿼리방식 계약서 테스트 (Query Type) ★ </template>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col-header width="20%"
                  ><div style="color:red;font-weight:bold ;">★ 리포트 관리코드</div></i-col-header
                >
                <i-col>
                  <iui-text name="manageCd" :value.sync="inputQry2.manageCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header width="30%">계약번호</i-col-header>
                <i-col>
                  <iui-text name="ctrtNo" :value.sync="inputQry2.ctrtNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>계약차수</i-col-header>
                <i-col>
                  <iui-text name="ctrtOdr" :value.sync="inputQry2.ctrtOdr" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>거래처코드(서명할거래처코드)</i-col-header>
                <i-col>
                  <iui-text name="tradeNo" :value.sync="inputQry2.tradeNo" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>조회속성(읽기/쓰기(서명))</i-col-header>
                <i-col>
                  <iui-text name="signable" :value.sync="inputQry2.signable" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명방식구분(10:공동,20:모바일인증서,30:전자서명,40:인감스캔)</i-col-header>
                <i-col>
                  <iui-text name="signMthCd" :value.sync="inputQry2.signMthCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>서명자구분코드(10:고객,20:대리인,30:OSSEP,40:3PI)</i-col-header>
                <i-col>
                  <iui-text name="signerSeCd" :value.sync="inputQry2.signerSeCd" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>*OKSS 서브타이틀</i-col-header>
                <i-col>
                  <iui-text name="subTitle" :value.sync="inputQry2.subTitle" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>*PDF Path1</i-col-header>
                <i-col>
                  <iui-text name="pdfPath1" :value.sync="inputQry2.pdfPath1" max-length="500" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header>*PDF Path2</i-col-header>
                <i-col>
                  <iui-text name="pdfPath2" :value.sync="inputQry2.pdfPath2" max-length="500" />
                </i-col>
              </i-row>
              <i-row>
                <i-col-header><div style="color:red;font-weight:bold ;">★ UserId</div></i-col-header>
                <i-col>
                  <iui-text name="userId" :value.sync="inputQry2.userId" max-length="100" />
                </i-col>
              </i-row>
              <i-row>
                <i-col colspan="2">
                  <iui-button @click="onReportViewQueryType2" style="button small blue">
                    Oz View로 보기
                  </iui-button>
                  <iui-button @click="onMakeOzPdfQryType2" style="button small blue"> 2차 pdf 생성 </iui-button>
                </i-col>
              </i-row>
            </iui-container>
          </pmis-content-box>
        </i-col>
      </i-row>

      <i-row>
        <i-col>
          <pmis-content-box no-border-box>
            <iui-modal-old
              name="openOZViewer"
              title="오즈뷰어"
              width="900px"
              height="650px"
              resize="both"
              :btns="modalOZViewerBtns"
            >
              <eFormViewer :OZParam="OZParam" />
            </iui-modal-old>
            <iui-modal-old
              name="openOZQryViewer"
              title="오즈뷰어(Qry)"
              width="900px"
              height="650px"
              resize="both"
              :btns="modalOZDBViewerBtns"
            >
              <eFormQryViewer :OZParam="OZParam" />
            </iui-modal-old>
            <iui-modal-old
              name="openOZSVGQryViewer"
              title="오즈뷰어(SVGQry)"
              width="900px"
              height="650px"
              resize="both"
              :btns="modalOZSVGViewerBtns"
            >
              <eFormSVGQryViewer :OZParam="OZParam" />
            </iui-modal-old>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
// modal 팝업창 오픈 테스트.
import eFormViewer from '@/view/common/popup/iOzViewer.vue'; //<== modal iframe 팝업 추가.
import eFormQryViewer from '@/view/common/popup/iOzQryViewer.vue'; //<== modal iframe 팝업 추가.
import eFormSVGQryViewer from '@/view/common/popup/iOzSVGQryViewer.vue'; //<== modal iframe 팝업 추가.
//import {mapGetters, mapActions} from 'vuex'; //userInfo  확인안될때사용.
export default {
  components: {
    eFormViewer,
    eFormQryViewer,
    eFormSVGQryViewer,
  },

  props: {
    param: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      modalOZViewerBtns: [{name: '서명', callback: this.onPopConfirmOZViewer}],
      modalOZDBViewerBtns: [{name: '서명', callback: this.onPopConfirmOZQryViewer}],
      modalOZSVGViewerBtns: [{name: '서명', callback: this.onPopOZSVGCallback}],
      OZParam: {
        reportname: '',
        seqNo: '',
        sgnCustomer: '',
        imgIdCard: '',
        rdoAgreeYn: '',
        txtBigo: '',
      },
      signData: {
        seqNo: '',
        sgnCustomer: '',
        imgIdCard: '',
        rdoAgreeYn: '',
        txtBigo: '',
      },
      inputData: {
        checkContNo: 'J2021030155',
        checkSeq: '00',
        manageCd: '060',
        reportName: '',
        signable: 'Y',
        signMthCd: '30',
        signerSeCd: '10',
        tradeNo: '1257938',
        useStemp: '',
      },
      inputQry: {
        manageCd: 'W10',
        signable: 'Y',
        rqestNo: 'R2020087656',
        cnfrmnNo: 'C2021070001',
        ctrtNo: '10',
        ctrtOdr: '1257938',
      },
      inputQry2: {
        manageCd: 'NE_CT1',
        signable: 'N',
        cnfrmnNo: '',
        ctrtNo: 'D2204-0006',
        ctrtOdr: '',
        tradeNo: '',
        signMthCd: '30',
        signerSeCd: '30',
        subTitle: '테스트 견적서',
        userId: 'T00480',
        pdfPath1: '',
        pdfPath2: '',
      },

      title: 'eForm Test',
      docNo1: '',
      tempdocNo2: '',
      tl: '',
      draftDate: dateFormat($_getCurrentDate()),
      docKwo: '',
      rplTlmtDt: '',
      docSmr: '',
      fbsNm: '',
      sndNmn: '',
      sndNmnList: [],
      iframe: {},
      lineObject: {},
      empObject: {},
      distObject: {},
      rmk: '',
      isModify: false,
    };
  },

  beforeCreate() {
    // $mapGetters(this, {
    // 	searchInfo: 'searchInfo',
    // 	grpDoc: 'grpDoc',
    // 	grpDocPop: 'grpDocPop',
    // });
    // $mapMutations(this, ['setGrpDocPop']);
  },

  created() {
    //this._id = 'Document';
    //window.addEventListener('resize', this.resize);
    //this.addEvent([{name: 'onDraft', func: this.onDraft}, {name: 'onTempSave', func: this.onTempSave}]);
  },
  mounted() {
    //this.ozInit();
    //this.resize();
  },
  methods: {
    resize() {
      //       let mainHeight = document.getElementsByClassName('popup_contents')[0].offsetHeight;
      //       let tabInfo = document.getElementsByClassName('popup_contents')[0].getElementsByClassName('title-box')[0].offsetHeight;
      //       let infoHeight = document.getElementsByClassName('popup_contents')[0].getElementsByClassName('iui-container')[0].offsetHeight;
      //document.getElementById('OZViewerDiv').style.height = mainHeight - tabInfo - infoHeight - 125 + 'px';
      //document.getElementById('OZViewerDiv').style.height = '80%';
    },

    setFormValue() {
      //if (this.form.nowSncSs == 0) {
      //  OZViewer.ReBind(0, 'data', this.ozParamStr, ';OZ;');
      //}
    },
    cmdValidationCheck() {
      this.cmdSetTitle();
    },
    cmdSetTitle() {
      let strConvertedTitle = this.form.tl;
      if (/[\\/:*?"<>\\|]{1}?/.test(strConvertedTitle)) {
        strConvertedTitle = strConvertedTitle
          .replace(/\\/g, '￦')
          .replace(/\//g, '／')
          .replace(/:/g, '：')
          .replace(/\*/g, '＊')
          .replace(/\?/g, '？')
          .replace(/"/g, '˝')
          .replace(/</g, '＜')
          .replace(/>/g, '＞')
          .replace(/\|/g, '｜');
      }
      this.form.fileTitle = strConvertedTitle;
    },

    /////////////////////////////////////////////////
    // OZ 팝업처리
    /////////////////////////////////////////////////
    /**
     * [Ozviewer 팝업함수.]
     *
     * 폼에서 사용될 필수파라미터.
     * paramData = JSON Object
     * {
     *   reportname : "" //경로구분자를 포함한 URL (필수) 예) reportname : "/sample/inputsample.ozr"
     *   OZFormParam : { //오즈 디자이너의 데이터트리에 나오는(설정한) OZFormParam
     *                  aaaa : ''
     *                  bbbb : ''
     *                  }
     *   ....       : 기타파라미터
     *   FormData  : {}
     *   Form List(Child) Data :{}
     * }
     *
     */

    onOZViewerPop() {
      this.OZParam.reportname = '/sample/inputsample.ozr'; //필수파라미터.

      //OZ 입력 Form 파라미터
      this.OZParam.seqNo = this.signData.seqNo;
      this.OZParam.sgnCustomer = this.signData.sgnCustomer;
      this.OZParam.imgIdCard = this.signData.imgIdCard;
      this.OZParam.rdoAgreeYn = this.signData.rdoAgreeYn;
      this.OZParam.txtBigo = this.signData.txtBigo;

      console.log('this.OZParam==>>>>', this.OZParam);

      this.$modal.show('openOZViewer');
    },
    /**
     * Ozviewer callback 함수
     */

    onPopConfirmOZViewer() {
      //const me = this;
      this.callEvent({
        name: 'eFormViewerPop_popConfirm',
        param: data => {
          console.log('eForm pop data >>>>>', data);

          //console.log('pop data signerSeCd>>>>>', data["signerSeCd"]);
          //console.log('pop data signedFormId>>>>>', data["signedFormId"]);
          this.$modal.hide('openOZViewer'); //modal 팝업닫기.

          /**
           * 리포트에서 전달하는 데이터.
           * data.checkContNo
           * data.checkSeq
           * data.signerSeCd
           * data.tradeNo
           * data.signData 삭제됨.. (리포트에서 조건에 맞는 사인데이터를 담아서 처리) 2020.12.30. 해당기능이 eForm 에서 지원되지않아 폼ID로 변경.
           * data.signedFormId  <==(필수사용) 입력폼ID를 리턴한다. 해당필드 사용 방법) data[data.signedFormId]
           */
          console.log('data.signedFormId===>' + data[data.signedFormId]);

          //전달한 파라미터 그대로 전달(oz 입력데이터포함).
          if (data) {
            //저장로직구현!!!
            //this.onSaveSignData(data);
          }
        },
      });
    },
    /**
     * Ozviewer callback 함수
     */

    onPopConfirmOZQryViewer() {
      //const me = this;
      this.callEvent({
        name: 'eFormQryViewerPop_popConfirm',
        param: data => {
          console.log('eFormQryViewerPop_popConfirm data >>>>>', data);

          //console.log('pop data signerSeCd>>>>>', data["signerSeCd"]);
          //console.log('pop data signedFormId>>>>>', data["signedFormId"]);
          this.$modal.hide('openOZViewer'); //modal 팝업닫기.

          /**
           * 리포트에서 전달하는 데이터.
           * data.checkContNo
           * data.checkSeq
           * data.signerSeCd
           * data.tradeNo
           * data.signData 삭제됨.. (리포트에서 조건에 맞는 사인데이터를 담아서 처리) 2020.12.30. 해당기능이 eForm 에서 지원되지않아 폼ID로 변경.
           * data.signedFormId  <==(필수사용) 입력폼ID를 리턴한다. 해당필드 사용 방법) data[data.signedFormId]
           */
          console.log('data.signedFormId===>' + data[data.signedFormId]);

          //전달한 파라미터 그대로 전달(oz 입력데이터포함).
          if (data) {
            //저장로직구현!!!
            //this.onSaveSignData(data);
          }
        },
      });
    },
    /**
     * OZSVGViewer Callback
     */
    onPopOZSVGCallback() {
      //const me = this;
      this.callEvent({
        name: 'eForm_callOZSVGViewerEvent',
        param: data => {
          console.log('eForm_callOZSVGViewerEvent', data);

          this.$modal.hide('openOZViewer'); //modal 팝업닫기.

          /**
           * 리포트에서 전달하는 데이터.
           * data.checkContNo
           * data.checkSeq
           * data.signerSeCd
           * data.tradeNo
           * data.signData 삭제됨.. (리포트에서 조건에 맞는 사인데이터를 담아서 처리) 2020.12.30. 해당기능이 eForm 에서 지원되지않아 폼ID로 변경.
           * data.signedFormId  <==(필수사용) 입력폼ID를 리턴한다. 해당필드 사용 방법) data[data.signedFormId]
           */
          console.log('data.signedFormId===>' + data[data.signedFormId]);

          //전달한 파라미터 그대로 전달(oz 입력데이터포함).
          if (data) {
            //저장로직구현!!!
            //this.onSaveSignData(data);
          }
        },
      });
    },
    //테스트용
    onOZViewerWindowPop() {
      // eslint-disable-next-line
      var myWindow = window.open(
        'http://localhost:8101/js/call.do?jspname=OzViewer',
        'myWindow',
        'width=800,height=700'
      );
    },
    //테스트용
    onOZViewerIframePop() {},
    /**
     * 저장함수
     */
    onSaveSignData(data) {
      //console.log('onSaveSignData args:: >>>', data);
      //console.log('onSaveSignData args:: >>> sgnCustomer'+ data.sgnCustomer);
      //console.log('onSaveSignData args:: >>> imgIdCard'+ data.imgIdCard);
      //console.log('onSaveSignData args:: >>> rdoAgreeYn'+ data.rdoAgreeYn);
      //console.log('onSaveSignData args:: >>> txtBigo'+ data.txtBigo);

      let param = {
        seqNo: this.signData.seqNo, //<==
        sign1Cn: data.sgnCustomer,
        img1Cn: data.imgIdCard,
        cnts1Cn: data.rdoAgreeYn,
        cnts2Cn: data.txtBigo,
      };

      console.log('onSaveSignData insert:: >>>', param);

      // eslint-disable-next-line
      let me = this;
      //OZ viewer 팝업 입력데이터 저장.
      // eslint-disable-next-line
      axios
        .post('/oz/insertOzTest', param)
        .then(response => {
          // 메시지 처리.
          this.$alert('서명이 완료되었습니다.');

          // 재조회 처리.
          this.onSearchSignData();
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    /**
     * 조회함수
     */
    onSearchSignData() {
      let param = {seqNo: '2'};
      axios
        .post('/oz/selectOzTestByKey', param)
        .then(response => {
          console.log('조회결과 >>>', response);
          // this.signData = {
          //   ...response.data
          // };

          this.signData.seqNo = response.data.seqNo;
          this.signData.sgnCustomer = response.data.sign1Cn;
          this.signData.imgIdCard = response.data.img1Cn;
          this.signData.rdoAgreeYn = response.data.cnts1Cn;
          this.signData.txtBigo = response.data.cnts2Cn;

          console.log('조회결과2 >>>', this.signData);
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    /**
     * 계약서 오즈뷰어 호출
     */
    onReportView() {
      /**
       * 계약서조회후 오즈뷰어 팝업시킴
       */
      let param = {
        checkContNo: this.inputData.checkContNo, //필수(*):계약서,발주서,견적서,변경합의서
        checkSeq: this.inputData.checkSeq, //필수(*):계약서,발주서,견적서,변경합의서
        manageCd: this.inputData.manageCd, //필수(*):공통
        //, 'reportName' : this.inputData.reportName   //필요없음. (테스트용)
        reqstdocNo: this.inputData.checkContNo, //필수(*) : cms, view, elite 신청서일 경우
        reqstdocOdr: this.inputData.checkSeq, //필수(*) : cms, view, elite 신청서일 경우
      };

      //alert("this.inputData.manageCd="+this.inputData.manageCd);

      //default : Ocontract
      let reqUri = '/ozrpt/selectOzOContract';
      //자동연장공문
      if ('OFC|OFD'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzOCtrtOfficialDocument';
      }
      //Tcontract
      else if ('090|0A0|0B0'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzTContract';
      }
      //견적서
      else if ('C10|C30|C70'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzTCtrtEstimate';
      }
      //발주서
      else if ('C20|C50|C80'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzTCtrtOrder';
      }
      //변경합의서
      else if ('A10|A20|A30|A40|A50|A60|A70|A80|A90|AA0|AB0|AC0'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzTCtrtChange';
      }
      //view, elite, cms
      else if ('R01|R02|R03'.indexOf(this.inputData.manageCd) > -1) {
        reqUri = '/ozrpt/selectOzEtcFormService';
      }

      //alert(reqUri);

      //uri : O계약 : /ozrpt/selectOzOContract
      //      T계약 : /ozrpt/selectOzTContract
      axios
        .post(reqUri, param)
        .then(response => {
          console.log('조회결과 response.data.jsonData >>>', response.data.jsonData);
          console.log('조회결과 response.data.reportPathName >>>', response.data.reportPathName);

          this.OZParam = Object.assign({}, response.data.jsonData);

          this.inputData.reportName = response.data.reportPathName; //필요없음.(테스트용)
          this.OZParam.reportname = response.data.reportPathName; //필수(*)리포트경로

          /**** 오즈파라미터설정  ******/
          this.OZParam.OZFormParam = {
            signable: this.inputData.signable, //필수(*):서명가능여부(Y/N)
            signMthCd: this.inputData.signMthCd, //필수(*):서명방식(10:공동,20:모바일,30:전자서명)
            signerSeCd: this.inputData.signerSeCd, //필수(*):서명자구분(10:고객,20:대리인,30:OSSEP)
            tradeNo: this.inputData.tradeNo, //[사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
            subTitle: this.inputData.subTitle, //OKSS 타이틀 제공.
            useStemp: this.inputData.useStemp, //OKSS 견적/발주서 사각직인.
          };
          this.$modal.show('openOZViewer');
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    aaaa() {
      this.$modal.show('openOZViewer');
    },
    onMakeOzPdf() {
      let param = {
        checkContNo: this.inputData.checkContNo,
        checkSeq: this.inputData.checkSeq,
        manageCd: this.inputData.manageCd,
        signable: this.inputData.signable, //필수(*):서명가능여부(Y/N)
        signMthCd: this.inputData.signMthCd, //필수(*):서명방식(10:공동,20:모바일,30:전자서명)
        signerSeCd: this.inputData.signerSeCd, //필수(*):서명자구분(10:고객,20:대리인,30:OSSEP)
        //       , tradeNo : this.inputData.tradeNo      //[사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
        //       , subTitle : this.inputData.subTitle //OKSS 타이틀 제공.
        //       , useStemp : this.inputData.useStemp //OKSS 견적/발주서 사각직인.
        reqstdocNo: this.inputData.checkContNo, //필수(*) : cms, view, elite 신청서일 경우
        reqstdocOdr: this.inputData.checkSeq, //필수(*) : cms, view, elite 신청서일 경우
      };
      axios
        .post('/ozrpt/serverBindingTest', param)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);
          this.$alert('pdf 파일이 생성되었습니다.\n' + JSON.stringify(response.data));
        })
        .catch(function(error) {
          this.$alert('pdf------');
          console.log('========error====================');
          console.log(error);
        });
    },

    onMakeOzPdfConfdoc() {
      let param = {
        //  'checkContNo': this.inputQry.checkContNo  //필수(*):계약서,발주서,견적서,변경합의서
        //, 'checkSeq' : this.inputQry.checkSeq       //필수(*):계약서,발주서,견적서,변경합의서
        cnfrmnNo: this.inputQry.cnfrmnNo, //필수(*):공통
      };
      axios
        .post('/ozrpt/serverBindingConfdocTest', param)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);
          this.$alert('pdf 파일이 생성되었습니다.\n' + JSON.stringify(response.data));
        })
        .catch(function(error) {
          this.$alert('pdf------');
          console.log('========error====================');
          console.log(error);
        });
    },

    onMakeOzPdfQryType() {
      let param = {
        //  'checkContNo': this.inputQry.checkContNo  //필수(*):계약서,발주서,견적서,변경합의서
        //, 'checkSeq' : this.inputQry.checkSeq       //필수(*):계약서,발주서,견적서,변경합의서
        manageCd: this.inputQry.manageCd, //필수(*):공통
      };

      console.log('onMakeOzPdfQryType', param);

      axios
        .post('/ozrpt/serverBindingQryTypeTest', param)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);
          this.$alert('pdf 파일이 생성되었습니다.\n' + JSON.stringify(response.data));
        })
        .catch(function(error) {
          this.$alert('pdf------');
          console.log('========error====================');
          console.log(error);
        });
    },
    /**
     * 작업/공사 완료 확인서 오즈뷰어 호출
     * Query Type 21.06.23
     */
    onReportViewQueryType() {
      let param = {
        //  'checkContNo': this.inputQry.checkContNo  //필수(*):계약서,발주서,견적서,변경합의서
        //, 'checkSeq' : this.inputQry.checkSeq       //필수(*):계약서,발주서,견적서,변경합의서
        cnfrmnNo: this.inputQry.cnfrmnNo, //필수(*):공통
      };
      //let reqUri = "/ozrpt/selectOzReportFileName";
      let reqUri = '/ozrpt/selectOzCntrwkFileName';
      axios
        .post(reqUri, param)
        .then(response => {
          console.log('QeuryType Result::', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          /**** 오즈파라미터설정  ******/
          this.OZParam.OZFormParam = {
            signable: this.inputQry.signable, //필수(*):서명가능여부(Y/N)
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //계약서번호
            cntrwkOdr: response.data.cntrwkOdr, //계약차수
            userId: this.userInfo.empNo,
            //
          };

          console.log('QeuryType OZParam::', this.OZParam);

          this.$modal.show('openOZQryViewer');
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },

    /**
     * 작업/공사 완료 확인서 오즈뷰어 호출
     * Query Type 21.06.23
     */
    onReportViewQueryType2() {
      let param = {
        //  'checkContNo': this.inputQry.checkContNo  //필수(*):계약서,발주서,견적서,변경합의서
        //, 'checkSeq' : this.inputQry.checkSeq       //필수(*):계약서,발주서,견적서,변경합의서
        manageCd: this.inputQry.manageCd, //필수(*):공통
      };
      //let reqUri = "/ozrpt/selectOzReportFileName";
      let reqUri = '/ozrpt/selectOzQryReportFileName';

      axios
        .post(reqUri, this.inputQry2)
        .then(response => {
          console.log('QeuryType Result::', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          /**** 오즈파라미터설정  ******/
          // this.OZParam.OZFormParam = {
          //   signable: this.inputQry.signable, //필수(*):서명가능여부(Y/N)
          //   manageCd: response.data.manageCd, //확인서번호
          //   userId: this.userInfo.empNo,
          //   //
          // };

          this.OZParam.OZFormParam = this.inputQry2;

          console.log('QeuryType OZParam::', this.OZParam);

          // this.$modal.show('openOZSVGQryViewer');

          this.$modal.show('openOZQryViewer');
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },

    onMakeOzPdfQryType2() {
      console.log('onMakeOzPdfQryType', this.inputQry2);

      axios
        .post('/ozrpt/serverBindingQryTypeTest', this.inputQry2)
        .then(response => {
          console.log('조회결과 response >>>', response);
          console.log('조회결과 response.data >>>', response.data);
          this.$alert('pdf 파일이 생성되었습니다.\n' + JSON.stringify(response.data));
        })
        .catch(function(error) {
          this.$alert('pdf------');
          console.log('========error====================');
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>

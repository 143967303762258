<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row height="330px">
        <i-col>
          <EstimateList otis-page progress-page @click="onClickListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-content-box :loading="contentBoxLoading">
            <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from '@/view/contract/tContract/store/estimate.js';
import EstimateList from '../../components/EstimateList.vue';
export default {
  name: 'EstimateProgress',
  components: {
    // eslint-disable-next-line
    EstimateList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'estimateList']);
    $mapMutations(this, [
      'setSearchInfo',
      'setEstimateList',
      'setHistoryList', //20221202 ITSR62600
      'setEstimateInfo',
      'setEstimateItemList',
      'setEstimateMemberList',
      'resetAllValues',
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '계약대상자',
          compNm: 'EstimateProgress_Members',
          path: '@/view/contract/tContract/components/tab/EstimateMembers.vue',
        },
        {
          tabNm: '견적조건/특기사항',
          compNm: 'EstimateProgress_Condition',
          path: '@/view/contract/tContract/components/tab/EstimateCondition.vue',
        },
        {
          tabNm: '견적상세내용',
          compNm: 'EstimateProgress_Items',
          path: '@/view/contract/tContract/components/tab/EstimateItems.vue',
        },
        {
          tabNm: '진행 이력',
          compNm: 'OContractProgress_History',
          path: '@/view/contract/components/tab/HistoryList.vue', //20221202 ITSR62600
        },
      ],
      tabIndex: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      contentBoxLoading: false,
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'Estimate_SearchDetail', func: this.onSearchDetail});
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    this.onSearch();
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'EstimateList_Search'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },
    async onPrint() {
      this.$store.dispatch('mdi/doPrint');
    },

    onClickListRow(row) {
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;
      this.onSearchDetail();
    },
    async onSearchDetail() {
      this.contentBoxLoading = true;
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        isProgressPage: true,
        isCompletePage: false,
        isCustomerPage: false,
      };
      let response = await axios.post('/estimate/selectAllContractInfo', param);
      this.contentBoxLoading = false;
      this.setEstimateInfo(response.data.estimateInfo);
      this.setEstimateItemList(response.data.estimateItemList);
      this.setHistoryList(response.data.historyList); //20221202 ITSR62600
      this.setEstimateMemberList(response.data.estimateMemberList);

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'EstimateMembers_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'EstimateCondition_Load', param: undefined});
      } else if (this.tabIndex == 2) {
        this.callEvent({name: 'EstimateItems_Load', param: undefined});
      } else if (this.tabIndex == 3) {
        this.callEvent({name: 'OContractTabHistoryList_Load', param: undefined}); //20221202 ITSR62600
      }
    },
    onResetValues() {
      console.log('onResetValues');
      this.resetAllValues();
      this.onCallEvent();
    },
  },
};
</script>

<style scoped></style>

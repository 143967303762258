import {ref} from '@vue/composition-api';

export function useDialog(refName) {
  const showWithPrefix = `${refName}_show`;
  const hideWithPrefix = `${refName}_hide`;

  const dialogRef = {
    [refName]: ref(null),
  };

  const method = {
    show: options => {
      return dialogRef[refName].value.show(options);
    },
    hide: result => {
      return dialogRef[refName].value.hide(result);
    },
    progressCount : fr => {
      var col = fr.Col;
      var rows = fr.Sheet.getDataRows();
      var count = 0;
      for(var i = 0; i < rows.length; i++){
        if( rows[i][col]!= ""){
          count++;
        }
      }
      if(count == '0'){
        return "";
      }else{
        return count + "건";
      }
      
    },
  };

  const methodWithPrefix = {
    [showWithPrefix]: options => {
      return dialogRef[refName].value.show(options);
    },
    [hideWithPrefix]: result => {
      return dialogRef[refName].value.hide(result);
    },
  };

  return {dialogRef, ...method, method, methodWithPrefix};
}

export default useDialog;
